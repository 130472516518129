var _a, _b, _c;
import * as React from 'react';
import { useNavigate } from 'react-router';
import { routes } from '@lean-body/src/route';
import { css, StyleSheet } from 'aphrodite';
import { MediaBreakPointUp } from '@lean-body/components/styles';
import { PageLoading, CourseVolumeHeader, CourseDays, CourseSettingDialog } from '@lean-body/components/organisms';
var VolumeContainerWidths = {
    sm: 335,
    md: 600,
    xl: 800,
};
export var CourseVolumePage = function (_a) {
    var config = _a.config, apiClient = _a.apiClient, volumeID = _a.volumeID, tracker = _a.tracker;
    var _b = React.useState(null), course = _b[0], setCourse = _b[1];
    var _c = React.useState(null), volume = _c[0], setVolume = _c[1];
    var _d = React.useState(true), isLoading = _d[0], setIsLoading = _d[1];
    var _e = React.useState(false), isModalOpen = _e[0], setIsModalOpen = _e[1];
    var _f = React.useState([]), courseSchedules = _f[0], setCourseSchedules = _f[1];
    var navigate = useNavigate();
    React.useEffect(function () {
        Promise.all([
            apiClient.fetchMyCourse().then(function (v) { return setCourse(v); }),
            apiClient.fetchCourseVolume(volumeID).then(function (v) { return setVolume(v); }),
            apiClient.fetchCourseSchedules().then(function (v) { return setCourseSchedules(v); }),
        ]).then(function () { return setIsLoading(false); });
    }, []);
    var onClickChangeCourse = function () {
        navigate(routes.CHANGE_COURSE.compile());
    };
    var onClickSetting = function () {
        setIsModalOpen(true);
        tracker.trackClickEvent({ category: 'Open Modal', label: 'courseSetting' });
    };
    var onClickCancel = function () {
        setIsModalOpen(false);
        tracker.trackClickEvent({ category: 'Close Modal', label: 'courseSetting' });
    };
    var onClickCourse = function () {
        tracker.trackClickEvent({ category: 'Watch Course Clicks', label: "courseID: ".concat(course.id) });
        navigate(routes.COURSE.compile({ id: String(course.id) }));
    };
    var updateCourseSchedules = function (courseSchedules) {
        setCourseSchedules(courseSchedules);
    };
    var onSubmit = function () {
        apiClient.patchCourseSchedules(courseSchedules).then(function () { return setIsModalOpen(false); });
        tracker.trackClickEvent({ category: 'submit', label: 'courseSetting' });
    };
    var onClickLesson = function (lesson) {
        tracker.trackClickEvent({
            category: 'Lesson Clicks',
            label: "lessonID: ".concat(lesson.id),
        });
    };
    var onClickResetCourseConfirm = function () {
        return apiClient.resetCourse().then(function () { return navigate(routes.TOP.compile()); });
    };
    return (React.createElement("div", { className: css(styles.container) }, isLoading ? (React.createElement(PageLoading, null)) : (React.createElement(React.Fragment, null,
        React.createElement(CourseVolumeHeader, { volume: volume, courseThumbnailSrc: course.thumbnailURL(config), onClickSetting: onClickSetting, onClickCourse: onClickCourse }, isModalOpen && (React.createElement(CourseSettingDialog, { courseID: course.id, courseSchedules: courseSchedules, classObjectForTip: styles.tip, onSubmit: onSubmit, onClickCancel: onClickCancel, onClickChangeCourse: onClickChangeCourse, onClickOutside: onClickCancel, updateCourseSchedules: updateCourseSchedules, onClickResetCourseConfirm: onClickResetCourseConfirm, tracker: tracker }))),
        React.createElement(CourseDays, { days: volume.days, apiClient: apiClient, classObject: styles.days, onClickLesson: onClickLesson })))));
};
var styles = StyleSheet.create({
    container: {
        position: 'relative',
    },
    days: (_a = {
            margin: '0 20px 100px 20px'
        },
        _a[MediaBreakPointUp.MD] = {
            margin: '0 auto 100px auto',
            width: VolumeContainerWidths.md,
        },
        _a[MediaBreakPointUp.XL] = {
            width: VolumeContainerWidths.xl,
        },
        _a),
    attendanceButtonWrapper: (_b = {
            marginTop: 53,
            position: 'fixed',
            bottom: 74,
            left: 0,
            right: 0
        },
        _b[MediaBreakPointUp.MD] = {
            left: 70,
        },
        _b[MediaBreakPointUp.XL] = {
            bottom: 14,
            left: 255,
        },
        _b),
    tip: (_c = {
            zIndex: 1
        },
        _c[MediaBreakPointUp.MD] = {
            position: 'absolute',
            top: 186,
        },
        _c[MediaBreakPointUp.XL] = {
            position: 'absolute',
            top: 186,
        },
        _c),
});
