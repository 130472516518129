import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var SeriesIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 33,
            height: 32,
        },
    });
    var iconColor = color || Colors.PrimaryDark;
    return (React.createElement("svg", { className: css(styles.icon, classObject), width: "33", height: "32", viewBox: "0 0 33 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { stroke: iconColor, d: "M27.7636 26.3999H5.73643C5.19668 26.3999 4.75 25.9594 4.75 25.4065V10.3933C4.75 9.84974 5.18738 9.3999 5.73643 9.3999H27.7636C28.3033 9.3999 28.75 9.84037 28.75 10.3933V25.4065C28.75 25.9501 28.3033 26.3999 27.7636 26.3999Z", strokeWidth: "2.0809", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { stroke: iconColor, d: "M19.9595 16.4997L15.3295 13.8297C14.6395 13.4297 13.7695 13.9297 13.7695 14.7297V20.0797C13.7695 20.8797 14.6395 21.3797 15.3295 20.9797L19.9595 18.3097C20.6495 17.8997 20.6495 16.8997 19.9595 16.4997Z", strokeWidth: "1.8728", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { fill: iconColor, d: "M5.75 6.7999C5.75 6.0267 6.3768 5.3999 7.15 5.3999H26.35C27.1232 5.3999 27.75 6.0267 27.75 6.7999H5.75Z" }),
        React.createElement("path", { fill: iconColor, d: "M7.75 4.2C7.75 3.53726 8.28726 3 8.95 3H24.55C25.2127 3 25.75 3.53726 25.75 4.2H7.75Z" })));
};
