var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
export var SectionHead = function (props) {
    var labelClass = [css(styles.label, props.classObject), props.noUnderline && css(styles.withNoBorder)].join(' ');
    return React.createElement("div", { className: labelClass }, props.children);
};
var styles = StyleSheet.create({
    label: (_a = {
            borderBottom: "1px solid ".concat(Colors.Gray),
            fontSize: 20,
            fontWeight: 600,
            letterSpacing: 1,
            lineHeight: 1.7,
            marginBottom: 10,
            paddingBottom: 10
        },
        _a[MediaBreakPointUp.SM] = {
            fontSize: 18,
            paddingBottom: 12,
            marginBottom: 18,
        },
        _a),
    withNoBorder: (_b = {
            borderBottomStyle: 'none',
            fontWeight: 600,
            marginBottom: 12,
            paddingBottom: 0
        },
        _b[MediaBreakPointUp.SM] = {
            marginBottom: 10,
            paddingBottom: 0,
        },
        _b),
});
