import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { BadgeHeader } from '@lean-body/components/atoms/badge_header';
import { CertificateList } from '@lean-body/components/molecules/certificate_list';
export var CertificateListSection = function (props) {
    var styles = StyleSheet.create({
        container: {
            margin: '10px 0px 40px',
        },
    });
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(BadgeHeader, { badges: props.badges, header: props.header, subHeader: props.subHeader, headerRef: props.headerRef }),
        React.createElement(CertificateList, { badges: props.badges, onClick: props.onClick })));
};
