import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { BadgeImage } from '@lean-body/components/atoms/badge_img';
import { MediaBreakPointUp } from '@lean-body/components/styles';
var badgeWidth = 78;
export var BadgeList = function (props) {
    var _a;
    var styles = StyleSheet.create({
        container: (_a = {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                gridGap: '20px 5px',
                '::before': {
                    content: "''",
                    display: 'block',
                    width: badgeWidth,
                    height: 0,
                    order: 1,
                },
                '::after': {
                    content: "''",
                    display: 'block',
                    width: badgeWidth,
                }
            },
            _a[MediaBreakPointUp.MD] = {
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, 15%)',
                '::before': {
                    content: 'none',
                },
                '::after': {
                    content: 'none',
                },
            },
            _a[MediaBreakPointUp.LG] = {
                gridGap: '20px 10px',
                gridTemplateColumns: 'repeat(auto-fit, 10%)',
            },
            _a),
        item: {
            margin: 0,
            width: badgeWidth,
        },
    });
    return (React.createElement("div", { className: css(styles.container) }, props.badges.map(function (badge, i) {
        return (React.createElement(BadgeImage, { key: i, badge: badge, classObject: styles.item, onClick: badge.isReceived && props.onClick }));
    })));
};
