var _a;
import * as React from 'react';
import { Link } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
var styles = StyleSheet.create({
    container: (_a = {
            fontSize: 12
        },
        _a[MediaBreakPointUp.SM] = {
            fontSize: 14,
        },
        _a.color = Colors.PrimaryDark,
        _a.textDecoration = 'none',
        _a.letterSpacing = '0.02em',
        _a.cursor = 'pointer',
        _a.fontWeight = 600,
        _a),
});
export var MoreLink = function (_a) {
    var to = _a.to, onClick = _a.onClick;
    return to ? (React.createElement(Link, { to: to, className: css(styles.container) }, "\u3059\u3079\u3066\u898B\u308B")) : (React.createElement("div", { onClick: onClick, className: css(styles.container) }, "\u3059\u3079\u3066\u898B\u308B"));
};
