var _a;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
export var FormLabel = function (props) {
    var containerClass = [props.className || '', css(styles.container)].join(' ');
    return React.createElement("div", { className: containerClass }, props.children);
};
var styles = StyleSheet.create({
    container: (_a = {
            fontSize: '.75rem',
            color: Colors.GrayDarkest
        },
        _a[MediaBreakPointUp.MD] = {
            fontSize: '.875rem',
        },
        _a),
});
