import * as React from 'react';
import { ITEM_WIDTH_OVER_XL, ITEM_WIDTH_SP, VerticalListArrows, } from '@lean-body/components/molecules/vertical_list_arrows';
import { ProgramComponent } from '../molecules/program_component';
export var ProgramComponentCarousel = function (_a) {
    var programs = _a.programs, classObject = _a.classObject, itemMarginRight = _a.itemMarginRight, itemsPadding = _a.itemsPadding, addFavoriteProgram = _a.addFavoriteProgram, unFavoriteProgram = _a.unFavoriteProgram, isMin = _a.isMin;
    var addFavorite = function (program) {
        program.isFavorited = true;
        addFavoriteProgram(program.id);
    };
    var unFavorite = function (program) {
        program.isFavorited = false;
        unFavoriteProgram(program.id);
    };
    return (React.createElement(VerticalListArrows, { classObject: classObject, itemsPadding: itemsPadding, itemMarginRight: itemMarginRight, itemSize: isMin ? ITEM_WIDTH_SP : ITEM_WIDTH_OVER_XL, items: programs.map(function (v) { return (React.createElement(ProgramComponent, { program: v, addFavoriteProgram: function () { return addFavorite(v); }, unFavoriteProgram: function () { return unFavorite(v); }, isMin: isMin })); }) }));
};
