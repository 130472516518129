import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '../../styles/variables';
export var HeartIcon = function (props) {
    var filled = props.filled, classObject = props.classObject, color = props.color, onClick = props.onClick;
    var styles = StyleSheet.create({
        container: {
            width: 23,
            height: 20,
            position: 'relative',
            display: 'block',
        },
        icon: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
        },
    });
    var iconColor = color || (filled ? Colors.Accent : Colors.GrayDarkest);
    return (React.createElement("span", { className: css(styles.container, classObject), onClick: onClick }, filled ? (React.createElement("svg", { className: css(styles.icon), viewBox: "0 0 23 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: iconColor, stroke: iconColor, d: "M13.3648 3.32653L11.5004 5.19093L9.63601 3.32653C7.7716 1.46212 4.69533 1.46212 2.83092 3.32653C0.966515 5.19093 0.966515 8.2672 2.83092 10.1316L4.69533 11.996L11.5004 18.8011L18.3055 11.996L20.1699 10.1316C22.0343 8.2672 22.0343 5.19093 20.1699 3.32653C18.3055 1.46212 15.2292 1.46212 13.3648 3.32653Z", strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }))) : (React.createElement("svg", { className: css(styles.icon), viewBox: "0 0 23 19", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { stroke: iconColor, d: "M13.3648 2.33019L11.5004 4.1946L9.63601 2.33019C7.7716 0.465783 4.69533 0.465783 2.83092 2.33019C0.966515 4.1946 0.966515 7.27087 2.83092 9.13527L4.69533 10.9997L11.5004 17.8048L18.3055 10.9997L20.1699 9.13527C22.0343 7.27087 22.0343 4.1946 20.1699 2.33019C18.3055 0.465783 15.2292 0.465783 13.3648 2.33019Z", strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })))));
};
