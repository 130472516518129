import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors } from '@lean-body/components/styles/variables';
var PopularKeyword = function (_a) {
    var keyword = _a.keyword, onClick = _a.onClick, className = _a.className;
    var wrapperClass = [className || '', css(styles.keyword)].join(' ');
    var handleOnClick = function () { return onClick(keyword); };
    return (React.createElement("span", { onClick: handleOnClick, className: wrapperClass, "data-test": "searchKeyword" }, keyword));
};
var styles = StyleSheet.create({
    keyword: {
        height: 27,
        lineHeight: '27px',
        padding: '0 14px',
        fontSize: 12,
        color: Colors.GrayDarkest,
        backgroundColor: Colors.BackgroundLightGray,
        borderRadius: 4,
        cursor: 'pointer',
        transition: 'all 0.3s',
        ':hover': {
            color: Colors.Primary,
            borderColor: Colors.Primary,
        },
        ':focus': {
            outline: 'none',
        },
    },
});
export default PopularKeyword;
