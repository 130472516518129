import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { WarningTriangleIcon } from '../atoms/icons/warning_triangle_icon';
export var GoalMatchingAssessmentFormMessage = function (_a) {
    var _b;
    var isInputTypeDate = _a.isInputTypeDate, messageTitle = _a.messageTitle, messageBody = _a.messageBody;
    var styles = StyleSheet.create({
        messageBox: (_b = {
                backgroundColor: Colors.YellowLight,
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                width: '100%',
                minHeight: 62,
                marginTop: isInputTypeDate ? 20 : 50,
                padding: 16,
                borderRadius: 10
            },
            _b[MediaBreakPointUp.MD] = {
                width: 343,
                margin: "".concat(isInputTypeDate ? 40 : 70, "px auto 0"),
            },
            _b),
        message: {
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
        },
        messageTitle: {
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: '140%',
            letterSpacing: 0.56,
        },
        messageDescription: {
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '160%',
            letterSpacing: 0.48,
            whiteSpace: 'pre-wrap',
        },
    });
    return (React.createElement("div", { className: css(styles.messageBox) },
        React.createElement(WarningTriangleIcon, null),
        React.createElement("div", { className: css(styles.message) },
            React.createElement("div", { className: css(styles.messageTitle) }, messageTitle),
            React.createElement("div", { className: css(styles.messageDescription) }, messageBody))));
};
