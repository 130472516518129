var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { observer } from 'mobx-react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
export var NotFoundPage = observer(/** @class */ (function (_super) {
    __extends(NotFoundPage, _super);
    function NotFoundPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(NotFoundPage.prototype, "styles", {
        get: function () {
            var _a;
            return StyleSheet.create({
                head: {
                    fontWeight: 'bold',
                    letterSpacing: 4,
                    fontSize: 45,
                    marginBottom: 12,
                    lineHeight: '1.25',
                },
                container: {
                    minHeight: 500,
                    marginTop: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexWrap: 'wrap',
                    height: '75vh',
                },
                subcaption: (_a = {
                        letterSpacing: 1,
                        fontSize: 13,
                        width: '100%',
                        textAlign: 'center',
                        color: Colors.GrayDarkest
                    },
                    _a[MediaBreakPointUp.SM] = {
                        fontSize: 15,
                    },
                    _a),
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NotFoundPage.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement("div", { className: css(this.styles.container) },
                React.createElement("div", { className: css(this.styles.head) }, "404"),
                React.createElement("div", { className: css(this.styles.subcaption) }, "\u304A\u63A2\u3057\u306E\u30B3\u30F3\u30C6\u30F3\u30C4\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3067\u3057\u305F\u3002")));
        }
    });
    return NotFoundPage;
}(React.Component)));
var NotFoundPageVM = /** @class */ (function () {
    function NotFoundPageVM(accountService) {
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "authed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.accountService = accountService;
        this.authed = this.accountService.authed;
    }
    return NotFoundPageVM;
}());
export { NotFoundPageVM };
