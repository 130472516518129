import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var Eye = function (_a) {
    var classObject = _a.classObject, onClick = _a.onClick, close = _a.close;
    var styles = StyleSheet.create({
        icon: {
            width: 20,
            height: 20,
        },
    });
    var color = '#6F7172';
    return (React.createElement("span", { onClick: onClick }, close ? (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: color, fillRule: "evenodd", clipRule: "evenodd", d: "M2.90462 9.86943C2.82272 10.0413 2.81242 10.1313 2.81242 10.1497C2.81242 10.1655 2.82125 10.2483 2.90006 10.4076C2.97532 10.5597 3.0971 10.7464 3.2719 10.958C3.62128 11.3809 4.14592 11.859 4.81372 12.309C6.15123 13.2102 7.98927 13.9497 9.99992 13.9497C12.0106 13.9497 13.8486 13.2102 15.1861 12.309C15.8539 11.859 16.3786 11.3809 16.7279 10.958C16.9027 10.7464 17.0245 10.5597 17.0998 10.4076C17.1786 10.2483 17.1874 10.1655 17.1874 10.1497C17.1874 10.1313 17.1771 10.0413 17.0952 9.86943C17.0174 9.70608 16.8927 9.50587 16.7158 9.27935C16.3624 8.82669 15.8344 8.31174 15.1659 7.82618C13.8252 6.85237 11.9941 6.04966 9.99992 6.04966C8.0057 6.04966 6.17468 6.85237 4.83395 7.82618C4.16544 8.31174 3.6374 8.82669 3.28401 9.27935C3.10716 9.50587 2.98244 9.70608 2.90462 9.86943ZM4.01121 6.69344C5.52516 5.59382 7.63789 4.64966 9.99992 4.64966C12.3619 4.64966 14.4747 5.59382 15.9886 6.69344C16.7475 7.24461 17.3745 7.84802 17.8194 8.41782C18.0416 8.70253 18.2268 8.98958 18.3591 9.26731C18.4874 9.53649 18.5874 9.84149 18.5874 10.1497C18.5874 10.4604 18.4859 10.7631 18.3546 11.0285C18.2197 11.3011 18.0316 11.5782 17.8072 11.8497C17.3584 12.393 16.7279 12.9583 15.9684 13.47C14.4512 14.4923 12.3455 15.3497 9.99992 15.3497C7.65431 15.3497 5.54861 14.4923 4.03143 13.47C3.27189 12.9583 2.64145 12.393 2.19259 11.8497C1.96827 11.5782 1.78014 11.3011 1.64526 11.0285C1.51394 10.7631 1.41242 10.4604 1.41242 10.1497C1.41242 9.84149 1.51248 9.53649 1.64071 9.26731C1.77302 8.98958 1.95821 8.70253 2.18048 8.41782C2.62533 7.84802 3.25237 7.24461 4.01121 6.69344Z" }),
        React.createElement("path", { fill: color, fillRule: "evenodd", clipRule: "evenodd", d: "M9.99988 7.60618C8.67785 7.60618 7.60612 8.6779 7.60612 9.99992C7.60612 11.322 8.67785 12.3937 9.99988 12.3937C11.3219 12.3937 12.3936 11.322 12.3936 9.99992C12.3936 8.6779 11.3219 7.60618 9.99988 7.60618ZM6.20612 9.99992C6.20612 7.90469 7.90465 6.20618 9.99988 6.20618C12.0951 6.20618 13.7936 7.90469 13.7936 9.99992C13.7936 12.0952 12.0951 13.7937 9.99988 13.7937C7.90465 13.7937 6.20612 12.0952 6.20612 9.99992Z" }),
        React.createElement("path", { fill: "white", fillRule: "evenodd", clipRule: "evenodd", d: "M3.11612 3.11612C3.60427 2.62796 4.39573 2.62796 4.88388 3.11612L16.8839 15.1161C17.372 15.6043 17.372 16.3957 16.8839 16.8839C16.3957 17.372 15.6043 17.372 15.1161 16.8839L3.11612 4.88388C2.62796 4.39573 2.62796 3.60427 3.11612 3.11612Z" }),
        React.createElement("path", { fill: color, fillRule: "evenodd", clipRule: "evenodd", d: "M3.50503 3.50507C3.7784 3.23171 4.22161 3.23171 4.49498 3.50507L16.495 15.5051C16.7683 15.7784 16.7683 16.2217 16.495 16.495C16.2216 16.7684 15.7784 16.7684 15.505 16.495L3.50503 4.49502C3.23166 4.22166 3.23166 3.77844 3.50503 3.50507Z" }))) : (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { stroke: color, d: "M17.8874 10.1497C17.8874 11.456 14.3562 14.6497 9.99992 14.6497C5.64367 14.6497 2.11243 11.456 2.11243 10.1497C2.11243 8.84348 5.64367 5.34973 9.99992 5.34973C14.3562 5.34973 17.8874 8.84348 17.8874 10.1497Z", strokeWidth: "1.47", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { stroke: color, d: "M9.99989 13.0936C11.7085 13.0936 13.0936 11.7085 13.0936 9.99988C13.0936 8.29124 11.7085 6.90613 9.99989 6.90613C8.29125 6.90613 6.90613 8.29124 6.90613 9.99988C6.90613 11.7085 8.29125 13.0936 9.99989 13.0936Z", strokeWidth: "1.47", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })))));
};
