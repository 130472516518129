/* eslint-disable */
export var BASE_ENV_PROD = 'production';
export var BASE_ENV_DEV = 'development';
export var BASE_ENV_STG = 'staging';
export function LoadAppConfigFromEnv() {
    var lpUrl = process.env.LP_URL || '';
    return {
        cdnBaseURL: process.env.CDN_BASE_URL,
        cdnBaseForMovie: process.env.CDN_BASE_FOR_MOVIE,
        cdnUserBaseURL: process.env.CDN_USER_BASE_URL,
        apiBaseURL: process.env.API_BASE_URL,
        stripeAPIKey: process.env.STRIPE_API_KEY,
        fbAppId: process.env.FB_APP_ID,
        fbPixelId: process.env.FB_PIXEL_ID,
        bqCloudFunctionsEndpoint: process.env.BQ_CF_ENDPOINT,
        bqCfAuthToken: process.env.CLOUD_FUNCTIONS_TOKEN,
        gaId: process.env.GA_ID,
        gaGeneralId: process.env.GA_GENERAL_ID,
        gtmId: process.env.GTM_ID,
        lpGAId: process.env.LP_GA_ID,
        lineCallBackUrl: process.env.LINE_CALLBACK_URL,
        lineChannelId: process.env.LINE_CHANNEL_ID,
        lpUrl: lpUrl,
        lpDomain: lpUrl.replace('https://', ''),
        awsLambdaEndpoint: process.env.AWS_LAMBDA_ENDPOINT,
        signInWithAppleClientId: process.env.SIGN_IN_WITH_APPLE_CLIENT_ID,
        signInWithAppleRedirectUri: process.env.SIGN_IN_WITH_APPLE_REDIRECT_URI,
        amazonPayPublicKeyId: process.env.AMAZONPAY_PUBLIK_KEY_ID,
        amazonPayMerchantId: process.env.AMAZONPAY_MERCHANT_ID,
        isProduction: process.env.BASE_ENV === BASE_ENV_PROD,
        isProdOrStg: process.env.BASE_ENV === BASE_ENV_PROD || process.env.BASE_ENV === BASE_ENV_STG,
    };
}
