import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '../../styles/variables';
export var ChatIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject;
    var styles = StyleSheet.create({
        container: {
            display: 'block',
            position: 'relative',
            width: 32,
            height: 32,
        },
    });
    var iconColor = color || Colors.GrayDarkest;
    return (React.createElement("span", { className: css(styles.container, classObject) },
        React.createElement("svg", { viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M25.7189 23.0432C27.651 20.4486 28.4324 17.2557 27.9179 14.0532C27.4642 11.2248 25.9956 8.65288 23.7843 6.81091C21.6073 4.9986 18.8559 4 16.0358 4C15.0729 4 14.1055 4.11687 13.1602 4.34624C9.43177 5.25327 6.43106 7.77725 4.92807 11.271C3.42683 14.7614 3.66646 18.6467 5.58706 21.9312C5.68045 22.0908 5.68926 22.1999 5.62054 22.3638C5.17387 23.4261 4.58888 24.8285 4.02239 26.2597C3.95367 26.4341 3.84619 26.7742 3.90257 27.0917C3.99684 27.6193 4.43206 27.9734 4.98621 27.9734C5.15977 27.9734 5.33597 27.9385 5.50865 27.8696C6.67246 27.4065 8.05388 26.8553 9.4723 26.271C9.57097 26.23 9.6529 26.2108 9.72955 26.2108C9.82382 26.2108 9.91633 26.2396 10.0282 26.3033C11.8933 27.3717 13.8853 27.8766 16.3054 27.8897C16.3829 27.8836 16.4798 27.8766 16.5926 27.8696C16.9001 27.8505 17.3203 27.8234 17.737 27.7641C20.9606 27.3045 23.7957 25.6282 25.7207 23.0423L25.7189 23.0432ZM10.7039 24.0601C10.4123 23.8726 10.1348 23.7819 9.85465 23.7819C9.65202 23.7819 9.44675 23.829 9.22826 23.925C8.71816 24.1491 8.19748 24.354 7.64597 24.5712C7.46272 24.6436 7.27595 24.7169 7.08477 24.7927C7.16318 24.6017 7.23807 24.416 7.31207 24.2337C7.53408 23.6851 7.74464 23.1671 7.9693 22.6577C8.18955 22.1589 8.14638 21.7036 7.83451 21.2257C6.04518 18.4889 5.74123 15.2079 7.00019 12.2226C8.26356 9.22675 10.8476 7.12488 14.0879 6.45682C14.7319 6.32426 15.3891 6.2571 16.0428 6.2571C18.2973 6.2571 20.5069 7.05598 22.2654 8.50548C24.0485 9.97504 25.2458 12.0237 25.637 14.2738C26.0969 16.9173 25.4775 19.5503 23.8926 21.6862C22.3138 23.8133 19.9748 25.18 17.3062 25.5332C16.8446 25.5942 16.3829 25.6256 15.9345 25.6256C14.0817 25.6256 12.3224 25.098 10.7039 24.0601Z", fill: iconColor }),
            React.createElement("path", { d: "M11.1239 14.6035C10.7742 14.6035 10.4429 14.7431 10.1918 14.9977C9.94162 15.2515 9.80771 15.5821 9.81564 15.9274C9.8315 16.6269 10.4182 17.1955 11.1239 17.1955H11.1416C11.8446 17.1859 12.4358 16.5894 12.4331 15.8926C12.4296 15.1957 11.8323 14.6061 11.1239 14.6035Z", fill: iconColor }),
            React.createElement("path", { d: "M16.0311 14.6035H16.0117C15.306 14.6035 14.7192 15.1713 14.7034 15.8699C14.6875 16.5659 15.2681 17.1729 15.9711 17.1955C15.9852 17.1955 15.9985 17.1955 16.0126 17.1955C16.7174 17.1955 17.3164 16.606 17.3209 15.9074C17.3261 15.2114 16.735 14.614 16.0311 14.6035Z", fill: iconColor }),
            React.createElement("path", { d: "M20.9004 17.1955H20.9066C21.6097 17.192 22.2061 16.6007 22.2087 15.9039C22.2114 15.2079 21.6193 14.6123 20.9154 14.6035H20.8996C20.1921 14.6035 19.6054 15.173 19.5913 15.8734C19.5842 16.2196 19.719 16.5493 19.9692 16.8031C20.2203 17.056 20.5507 17.1955 20.8996 17.1955H20.9004Z", fill: iconColor }))));
};
