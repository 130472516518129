var Style = /** @class */ (function () {
    function Style(data) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "thumbnailSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "iconThumbnailSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.name = data.name;
        this.thumbnailSrc = data.thumbnailSrc;
        this.iconThumbnailSrc = data.iconThumbnailSrc;
        this.description = data.description;
    }
    Object.defineProperty(Style.prototype, "thumbnailURL", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (config) {
            return config.cdnBaseURL + this.thumbnailSrc;
        }
    });
    Object.defineProperty(Style.prototype, "iconThumbnailURL", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (config) {
            return config.cdnBaseURL + this.iconThumbnailSrc;
        }
    });
    return Style;
}());
export { Style };
