import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../styles';
export var MealTaskBadge = function (_a) {
    var classObject = _a.classObject;
    return (React.createElement("div", { className: css(classObject, styles.container) },
        "\u8A18\u9332\u3057\u3088\u3046\uFF01",
        React.createElement("svg", { className: css(styles.arrow), xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 9 8" },
            React.createElement("path", { d: "M5.36603 7.18018C4.98112 7.84684 4.01887 7.84684 3.63397 7.18018L0.602886 1.93018C0.217986 1.26351 0.699111 0.430176 1.46891 0.430176L7.53109 0.430177C8.30089 0.430177 8.78202 1.26351 8.39711 1.93018L5.36603 7.18018Z" }))));
};
var styles = StyleSheet.create({
    container: {
        position: 'absolute',
        height: 21,
        padding: '5px 3px 5px 8px',
        borderRadius: 15,
        backgroundColor: Colors.Accent,
        color: Colors.White,
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 700,
        lineHeight: '100%',
        letterSpacing: 0.48,
    },
    arrow: {
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translate(-50%, -2px)',
        width: 9,
        height: 8,
        fill: Colors.Accent,
    },
});
