var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MediaBreakPointUp } from '@lean-body/components/styles/';
var arrowImgPath = 'assets/images/icons/arrow_right.png';
export var SliderNextArrow = function (props) {
    var className = props.className, onClick = props.onClick;
    var arrowClass = [className || '', css(styles.nextArrow)].join(' ');
    if (isDisabledArrow(className)) {
        return null;
    }
    return React.createElement("img", { src: arrowImgPath, className: arrowClass, onClick: onClick });
};
var isDisabledArrow = function (className) {
    return className.search('slick-disabled') !== -1;
};
var styles = StyleSheet.create({
    nextArrow: (_a = {
            position: 'absolute',
            top: 98,
            right: 20
        },
        _a[MediaBreakPointUp.MD] = {
            left: '90%',
        },
        _a),
});
