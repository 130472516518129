import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors } from '@lean-body/components/styles';
export var GoalMatchingAssessmentFormDailyGoals = function (_a) {
    var config = _a.config, dailyGoals = _a.dailyGoals;
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.header) }, "1\u65E5\u306E\u76EE\u6A19\u76EE\u5B89"),
        React.createElement("div", { className: css(styles.body) }, dailyGoals.map(function (v, i) { return (React.createElement("div", { className: css(styles.row), key: i },
            React.createElement("img", { className: css(styles.icon), src: v.iconURL(config) }),
            React.createElement("div", { className: css(styles.text) },
                React.createElement("div", { className: css(styles.title) }, v.name),
                React.createElement("div", { className: css(styles.value, v.unit && styles.valueWithUnit) },
                    React.createElement("div", { className: css(styles.number, v.unit && styles.numberWithUnit) }, v.value),
                    React.createElement("div", { className: css(styles.unit) }, v.unit))),
            v.details.length > 0 && (React.createElement("div", { className: css(styles.details) }, v.details.map(function (detail, i) { return (React.createElement("div", { className: css(styles.detail), key: i },
                "\u2514 ",
                detail.name,
                React.createElement("div", { className: css(styles.detailNumber) }, detail.value),
                detail.unit)); }))))); }))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '14px 20px 24px',
        marginTop: 32,
        borderRadius: 14,
        boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.12)",
    },
    header: {
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: 0.56,
        width: '100%',
        height: 32,
        borderBottom: "0.5px solid ".concat(Colors.Gray),
        paddingBottom: 12,
        marginBottom: 12,
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        gap: 14,
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    icon: {
        width: 32,
        height: 32,
    },
    text: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        marginLeft: 10,
        width: 'calc(100% - 42px)',
    },
    title: {
        color: Colors.GrayDarkest,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
        height: 19,
    },
    value: {
        display: 'flex',
        alignItems: 'center',
        height: 19,
        lineHeight: '160%',
    },
    valueWithUnit: {
        height: 32,
    },
    number: {
        fontSize: 12,
        fontWeight: 'bold',
        letterSpacing: 0.8,
    },
    numberWithUnit: {
        fontSize: 20,
        letterSpacing: 0.48,
    },
    unit: {
        fontSize: 12,
        fontWeight: 500,
        letterSpacing: 0.48,
        marginTop: 3,
        marginLeft: 2,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        marginLeft: 52,
    },
    detail: {
        display: 'flex',
        fontSize: 11,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.44,
    },
    detailNumber: {
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 0.56,
        marginLeft: 2,
        marginRight: 1,
    },
});
