import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var CustomWorkoutIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 16,
            height: 17,
        },
    });
    var iconColor = color || Colors.Gray;
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 16 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_30272_6747)" },
            React.createElement("path", { d: "M10.5928 0.501028C10.9894 0.581168 11.2795 0.824926 11.5463 1.11084C11.7663 1.34667 11.9996 1.56956 12.2426 1.81373C12.4171 1.63801 12.5841 1.46855 12.7523 1.30034C13.2353 0.818248 13.7876 0.819917 14.2731 1.3041C14.5052 1.53575 14.7386 1.76657 14.9686 2.00031C15.3982 2.43732 15.399 3.00915 14.9711 3.4445C14.7933 3.62565 14.6113 3.80262 14.4213 3.9917C14.7929 4.36235 15.151 4.72465 15.5159 5.08069C15.7409 5.30023 15.9171 5.54274 15.9738 5.86038C16.0431 6.24772 15.9738 6.61252 15.7096 6.8976C15.2784 7.36342 14.8162 7.80001 14.3662 8.24788C14.3407 8.27292 14.3078 8.29045 14.2948 8.29963C12.2626 6.26692 10.2359 4.24005 8.19531 2.19899C8.25292 2.13388 8.32723 2.0408 8.4103 1.9569C8.69585 1.66765 8.99099 1.38799 9.26944 1.09289C9.53536 0.810735 9.82633 0.578246 10.2175 0.50061H10.5924L10.5928 0.501028Z", fill: iconColor }),
            React.createElement("path", { d: "M0 10.7176C0.0755603 10.3194 0.318105 10.0293 0.602813 9.75968C0.901297 9.4771 1.18517 9.17908 1.47781 8.89024C1.55462 8.81428 1.6402 8.74708 1.71117 8.6853C3.74671 10.7201 5.77014 12.7432 7.81068 14.7834C7.76309 14.841 7.70673 14.9199 7.63911 14.9884C7.2659 15.3657 6.89436 15.7451 6.51322 16.1149C5.98429 16.6283 5.22076 16.6296 4.69017 16.1187C4.37916 15.8194 4.08109 15.5068 3.75965 15.1829C3.57304 15.3703 3.39479 15.5489 3.21737 15.7288C3.06374 15.8845 2.88674 15.9947 2.66799 16.0348C2.35448 16.0924 2.07353 16.0189 1.84434 15.8031C1.54126 15.5181 1.24403 15.2255 0.960994 14.9208C0.607822 14.5405 0.624938 13.9745 0.990633 13.5935C1.17766 13.3985 1.37678 13.2149 1.60472 12.992C1.50035 12.9039 1.40434 12.8325 1.31959 12.7495C1.07413 12.5095 0.839095 12.2578 0.588202 12.0236C0.308921 11.7632 0.0809873 11.476 0.00041746 11.0924V10.7176H0Z", fill: iconColor }),
            React.createElement("path", { d: "M9.53514 5.04851C10.1801 5.69464 10.8334 6.3487 11.4763 6.99232C9.81943 8.64896 8.15334 10.3148 6.50187 11.9656C5.86023 11.3245 5.20399 10.6683 4.55859 10.0234C6.20506 8.37724 7.87114 6.71141 9.53472 5.0481L9.53514 5.04851Z", fill: iconColor })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_30272_6747" },
                React.createElement("rect", { width: "16", height: "16", fill: Colors.White, transform: "translate(0 0.500977)" })))));
};
