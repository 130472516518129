import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
import { CloseIcon } from '../atoms/icons/close_icon';
import { Link } from 'react-router-dom';
export var FixedFooterBanner = function (props) {
    var imgSrc = props.imgSrc, link = props.link, hideFixedBanner = props.hideFixedBanner;
    var onClickClose = function (e) {
        hideFixedBanner();
        e.preventDefault();
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(Link, { to: link, className: css(styles.imgContainer) },
            React.createElement("div", { className: css(styles.closeContainer) },
                React.createElement(CloseIcon, { size: 9, color: Colors.GrayDarkestB, onClick: onClickClose })),
            React.createElement("img", { className: css(styles.img), src: imgSrc }))));
};
var styles = StyleSheet.create({
    container: {
        position: 'absolute',
        padding: '0 12px',
        top: -100,
        height: 87,
        display: 'flex',
        alignItems: 'flex-end',
    },
    imgContainer: {
        position: 'relative',
        display: 'block',
    },
    img: {
        borderRadius: 6,
        width: '100%',
    },
    closeContainer: {
        position: 'absolute',
        backgroundColor: Colors.White,
        borderRadius: '50%',
        width: 21,
        height: 21,
        padding: 6,
        right: -6,
        top: -6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
