import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var PencilIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 21,
            height: 20,
        },
    });
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 21 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.8366 2.69576C13.4953 2.03705 14.3887 1.66699 15.3203 1.66699C16.2518 1.66699 17.1452 2.03705 17.8039 2.69576C18.4626 3.35446 18.8327 4.24786 18.8327 5.17941C18.8327 6.11096 18.4626 7.00435 17.8039 7.66306L7.3362 18.1308C7.2063 18.2607 7.03011 18.3337 6.8464 18.3337H2.8587C2.47614 18.3337 2.16602 18.0235 2.16602 17.641V13.6533C2.16602 13.4696 2.23899 13.2934 2.3689 13.1635L12.8366 2.69576ZM15.3203 3.05236C14.7561 3.05236 14.2151 3.27646 13.8162 3.67536L3.55139 13.9402V16.9483H6.55948L16.8243 6.68346C17.2232 6.28456 17.4473 5.74354 17.4473 5.17941C17.4473 4.61528 17.2232 4.07426 16.8243 3.67536C16.4254 3.27646 15.8844 3.05236 15.3203 3.05236Z", fill: color || Colors.PrimaryDarkB }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.3548 3.5218C12.6065 3.27008 13.0147 3.27008 13.2664 3.5218L16.9772 7.23263C17.2289 7.48435 17.2289 7.89248 16.9772 8.14421C16.7255 8.39594 16.3174 8.39594 16.0656 8.14421L12.3548 4.43339C12.1031 4.18166 12.1031 3.77353 12.3548 3.5218Z", fill: color || Colors.PrimaryDarkB })));
};
