import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '../../styles/variables';
export var DonutsIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 10,
            height: 10,
        },
    });
    var color = props.color || Colors.Accent;
    var innerColor = Colors.White;
    return (React.createElement("svg", { className: css(styles.icon), viewBox: "0 0 10 10", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("circle", { fill: innerColor, cx: "5", cy: "5", r: "3.5" }),
        React.createElement("circle", { cx: "5", cy: "5", r: "3.5", fill: "url(#paint0_linear_18398_30536)" }),
        React.createElement("circle", { stroke: color, cx: "5", cy: "5", r: "3.5", strokeWidth: "3" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "paint0_linear_18398_30536", x1: "5", y1: "0", x2: "5", y2: "10", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "white" }),
                React.createElement("stop", { offset: "1", stopColor: innerColor, stopOpacity: "0" })))));
};
