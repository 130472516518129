var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import { MediaBreakPointUp, Colors } from '../../styles/variables';
var SearchTitle = function (_a) {
    var linkUrl = _a.linkUrl, linkLabel = _a.linkLabel, onClickLink = _a.onClickLink, classObject = _a.classObject, children = _a.children;
    var link;
    if (linkUrl) {
        link = (React.createElement(Link, { to: linkUrl, className: css(styles.moreLink) }, linkLabel ? linkLabel : '一覧で見る'));
    }
    else if (onClickLink) {
        link = (React.createElement("span", { onClick: onClickLink, className: css(styles.moreLink) }, linkLabel ? linkLabel : '一覧で見る'));
    }
    return (React.createElement("div", null,
        React.createElement("div", { className: css(styles.wrapper, classObject) },
            children,
            link)));
};
var styles = StyleSheet.create({
    wrapper: (_a = {
            display: 'flex',
            justifyContent: 'space-between',
            color: Colors.Black,
            fontSize: 16,
            fontWeight: 'bold',
            marginTop: 24,
            marginBottom: 8,
            lineHeight: '1.25'
        },
        _a[MediaBreakPointUp.MD] = {
            fontSize: 16,
        },
        _a),
    moreLink: {
        cursor: 'pointer',
        color: Colors.Primary,
        fontSize: 12,
    },
});
export default SearchTitle;
