import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Dialog as DialogComponent } from '@lean-body/components/molecules';
import { Colors } from '@lean-body/components/styles';
import { ButtonType, Button } from '../atoms';
export var FavoriteMessageDialog = function (_a) {
    var close = _a.close, classObject = _a.classObject;
    return (React.createElement(DialogComponent, { onClickCancel: close, classObject: styles.dialog, content: React.createElement("div", { className: css(styles.container, classObject) },
            React.createElement("img", { className: css(styles.headImg), src: "/assets/images/dialog/favorite_dialog.jpg" }),
            React.createElement("div", { className: css(styles.content) },
                React.createElement("div", { className: css(styles.title) }, "\u30EC\u30C3\u30B9\u30F3\u3092\u304A\u6C17\u306B\u5165\u308A\u3057\u3066\u307F\u307E\u305B\u3093\u304B\uFF1F"),
                React.createElement("div", { className: css(styles.description) },
                    React.createElement("p", null, "\u304A\u75B2\u308C\u3055\u307E\u3067\u3057\u305F\uFF01\u30EC\u30C3\u30B9\u30F3\u304C\u826F\u3051\u308C\u3070\u304A\u6C17\u306B\u5165\u308A\u306B\u8FFD\u52A0\u3057\u307E\u3057\u3087\u3046\u3002\u3044\u3064\u3067\u3082\u597D\u304D\u306A\u30EC\u30C3\u30B9\u30F3\u3092\u898B\u8FD4\u3059\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002")),
                React.createElement(Button, { buttonType: ButtonType.pink, className: css(styles.button), onClick: close, isRadius: true },
                    React.createElement("div", { className: css(styles.buttonText) }, "OK")))) }));
};
var styles = StyleSheet.create({
    container: {
        borderRadius: 8,
        overflow: 'hidden',
        backgroundColor: Colors.White,
    },
    dialog: {
        top: '50%',
        maxWidth: 327,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px 18px 20px',
    },
    headImg: {
        width: '100%',
    },
    title: {
        fontWeight: 600,
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
    },
    description: {
        fontSize: 14,
        margin: '8px 0 16px',
        lineHeight: '21px',
        fontWeight: 400,
        whiteSpace: 'pre-wrap',
    },
    button: {
        display: 'block',
        width: '100%',
        minHeight: 48,
    },
    buttonText: {
        fontSize: 16,
        fontWeight: 'bold',
    },
});
