import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '@lean-body/components/styles';
export var CameraIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 32,
            height: 26,
        },
    });
    var color = props.color || Colors.White;
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), width: "39", height: "34", viewBox: "0 0 39 34", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M32.8188 4.82372H28.6918L27.7539 2.43868C26.7355 0.803979 24.9133 -0.133961 23.0106 3.03332e-05H15.9894C14.0867 -0.133961 12.2645 0.777181 11.2461 2.41188L10.3082 4.79693H6.18124C3.07264 4.82372 0.5 7.36956 0.5 10.505V27.5755C0.5 30.7109 3.07264 33.2567 6.20804 33.2567H32.8188C35.9542 33.2567 38.5 30.7109 38.5268 27.5755V10.505C38.5268 7.36956 35.9542 4.82372 32.8188 4.82372ZM19.5 28.1918C14.4619 28.1918 10.3886 24.1185 10.3886 19.0804C10.3886 14.0423 14.4619 9.969 19.5 9.969C24.5381 9.969 28.6114 14.0423 28.6114 19.0804C28.6114 24.1185 24.5381 28.1918 19.5 28.1918Z", fill: color }),
        React.createElement("path", { d: "M19.4992 25.1368C22.8589 25.1368 25.5824 22.4253 25.5824 19.0804C25.5824 15.7355 22.8589 13.024 19.4992 13.024C16.1396 13.024 13.416 15.7355 13.416 19.0804C13.416 22.4253 16.1396 25.1368 19.4992 25.1368Z", fill: color })));
};
