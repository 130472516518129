var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { LiveLessonCard } from '@lean-body/components/organisms';
import { MediaBreakPointUp } from '@lean-body/components/styles';
export var LiveLessonList = function (props) {
    var liveLessons = props.liveLessons, apiClient = props.apiClient, tracker = props.tracker, classObject = props.classObject;
    var onClickAttendance = function (id, isAttendance) {
        if (!isAttendance) {
            apiClient.attendLiveLesson(id);
        }
        else {
            apiClient.absentLiveLesson(id);
        }
    };
    return (React.createElement("div", { className: css(styles.container, classObject) }, liveLessons.map(function (live, i) { return (React.createElement(LiveLessonCard, { key: i, liveLesson: live, tracker: tracker, onClickAttendance: onClickAttendance, classObject: styles.card })); })));
};
var styles = StyleSheet.create({
    container: (_a = {},
        _a[MediaBreakPointUp.XL] = {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, 32.5%)',
            justifyContent: 'space-between',
        },
        _a),
    card: (_b = {
            marginBottom: 24
        },
        _b[MediaBreakPointUp.XL] = {
            maxWidth: 335,
        },
        _b),
});
