var _a, _b, _c;
import * as React from 'react';
import { useState, useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MediaBreakPointUp } from '@lean-body/components/styles';
import { useSmMediaQuery } from '@lean-body/src/util';
import { LessonRankingCard } from '../molecules/lesson_ranking_card';
import { LoadingIndicator } from '../atoms';
var RANKING_LIMIT_COUNT = 50;
export var MainRankingPage = function (_a) {
    var apiClient = _a.apiClient;
    var isSM = useSmMediaQuery();
    var _b = useState([]), rankings = _b[0], setRankings = _b[1];
    useEffect(function () {
        apiClient.fetchDailyLessonRanking(RANKING_LIMIT_COUNT).then(function (v) {
            setRankings(v);
        });
    }, []);
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.title) }, "\u30C7\u30A4\u30EA\u30FC\u30E9\u30F3\u30AD\u30F3\u30B0"),
        rankings.length ? (React.createElement(React.Fragment, null, rankings.map(function (v, i) { return (React.createElement(LessonRankingCard, { ranking: v, imgClassObject: styles.rankingCardImg, addFavoriteLesson: apiClient.addFavoriteLesson, unFavoriteLesson: apiClient.unFavoriteLesson, isBig: !isSM, key: i })); }))) : (React.createElement(LoadingIndicator, { size: 50 }))));
};
var styles = StyleSheet.create({
    container: (_a = {
            width: '100%',
            padding: '0 20px 20px'
        },
        _a[MediaBreakPointUp.MD] = {
            maxWidth: 600,
            padding: 0,
            margin: '0 auto 40px',
        },
        _a[MediaBreakPointUp.XL] = {
            maxWidth: 1024,
        },
        _a),
    title: (_b = {
            textAlign: 'center',
            fontSize: 16,
            fontWeight: 'bold',
            lineHeight: '160%',
            letterSpacing: 1.28,
            height: 26,
            margin: '16px 0 24px'
        },
        _b[MediaBreakPointUp.MD] = {
            margin: '24px 0 28px',
        },
        _b[MediaBreakPointUp.XL] = {
            margin: '40px 0',
        },
        _b),
    rankingCardImg: (_c = {},
        _c[MediaBreakPointUp.MD] = {
            maxWidth: 300,
        },
        _c[MediaBreakPointUp.XL] = {
            maxWidth: 335,
        },
        _c),
});
