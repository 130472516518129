var _a;
import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { MealMenuInfo } from '../molecules/meal_menu_info';
import { MenuNutrientsSummary } from '../molecules/menu_nutrients_summary';
import { Input } from '../atoms';
import { MENU_AMOUNT_PERCENT_FULL } from '../organisms/record_meal';
import { MediaBreakPointUp } from '../styles';
export var RecordMealMenuDetailContent = function (_a) {
    var menu = _a.menu, amount = _a.amount, onAmountInputChange = _a.onAmountInputChange;
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(MealMenuInfo, { menu: menu, classObject: styles.mealMenuInfo }),
        React.createElement("div", { className: css(styles.sectionTitle) }, "\u98DF\u3079\u305F\u91CF\u3092\u5909\u66F4"),
        React.createElement(Input, { type: "number", value: amount, placeholder: MENU_AMOUNT_PERCENT_FULL.toString(), hideSpinner: true, unitLabel: "%", onChange: function (e) { return onAmountInputChange(e.target.value); } }),
        React.createElement("div", { className: css(styles.sectionTitle) }, "\u30E1\u30CB\u30E5\u30FC\u8A73\u7D30"),
        React.createElement("div", { className: css(styles.menuDescription) }, menu.description),
        React.createElement(MenuNutrientsSummary, { menu: menu })));
};
var styles = StyleSheet.create({
    container: (_a = {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '0 24px',
            margin: '0 auto'
        },
        _a[MediaBreakPointUp.MD] = {
            width: 375,
        },
        _a),
    sectionTitle: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '160%',
        letterSpacing: 1.28,
        marginTop: 24,
        marginBottom: 8,
    },
    mealMenuInfo: {
        padding: '12px 0',
    },
    menuDescription: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: 0.48,
        marginBottom: 8,
        whiteSpace: 'pre-line',
    },
});
