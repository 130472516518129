import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { CourseFeatureThumbnail } from '@lean-body/components/atoms';
export var CourseGenre = function (_a) {
    var genre = _a.genre, config = _a.config, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(CourseFeatureThumbnail, { src: genre.iconThumbnailURL(config) }),
        React.createElement("div", { className: css(styles.detail) },
            React.createElement("div", { className: css(styles.name) }, genre.name),
            React.createElement("div", { className: css(styles.description) }, genre.description))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        gap: 10,
        alignItems: 'center',
        letterSpacing: '0.04em',
        color: Colors.Black,
    },
    detail: {
        width: 247,
    },
    name: {
        fontWeight: 500,
        fontSize: 14,
    },
    description: {
        fontWeight: 400,
        fontSize: 12,
    },
});
