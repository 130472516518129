import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CourseVolumeItem } from '.';
export var CourseVolumes = function (_a) {
    var volumes = _a.volumes, classObject = _a.classObject, onClickVolume = _a.onClickVolume;
    return (React.createElement("div", { className: css(styles.container, classObject) }, volumes.map(function (volume, i) { return (React.createElement(CourseVolumeItem, { key: i, volume: volume, onClickVolume: onClickVolume, classObject: styles.volume })); })));
};
var styles = StyleSheet.create({
    container: {},
    volume: {
        marginTop: 16,
        cursor: 'pointer',
    },
});
