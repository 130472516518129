import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { CircleCheckIcon } from './icons/circle_check_icon';
export var StepIndicator = function (_a) {
    var classObject = _a.classObject, activeIdx = _a.activeIdx, captions = _a.captions;
    var progressStyles = StyleSheet.create({
        progressDone: {
            height: 2,
            width: "".concat((activeIdx / (captions.length - 1)) * 100, "%"),
            backgroundColor: Colors.PrimaryDark,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
        },
    });
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.progressAll) }),
        React.createElement("div", { className: css(progressStyles.progressDone) }),
        React.createElement("ul", { className: css(styles.dotPoints) }, captions.map(function (v, i) { return (React.createElement("li", { className: css(styles.dotPoint), key: i },
            i < activeIdx ? (React.createElement(CircleCheckIcon, { classObject: styles.checkIcon, filled: true, color: Colors.PrimaryDark })) : (React.createElement("div", { className: css(styles.dot, i > activeIdx && styles.dotGray) })),
            React.createElement("span", { className: css(styles.dotText, i > activeIdx && styles.dotTextGray) }, v))); }))));
};
var styles = StyleSheet.create({
    container: {
        marginBottom: 23,
        width: 240,
        position: 'relative',
    },
    progressAll: {
        height: 2,
        width: '100%',
        backgroundColor: Colors.Gray,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    dotPoints: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    dotPoint: {
        position: 'relative',
        height: 24,
        width: 24,
        backgroundColor: Colors.White,
        padding: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dot: {
        width: 20,
        height: 20,
        backgroundColor: Colors.PrimaryDark,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 100,
        overflow: 'hidden',
        ':after': {
            display: 'block',
            content: '""',
            width: 10,
            height: 10,
            borderRadius: 100,
            backgroundColor: Colors.White,
        },
    },
    dotGray: {
        backgroundColor: Colors.Gray,
    },
    dotText: {
        fontSize: 12,
        fontWeight: 500,
        position: 'absolute',
        bottom: -23,
        color: Colors.PrimaryDark,
        width: 60,
        textAlign: 'center',
    },
    dotTextGray: {
        color: Colors.GrayDarkest,
    },
    checkIcon: {
        width: 20,
        height: 20,
    },
});
