var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
export var CourseRewardTitle = function (_a) {
    var day = _a.day, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", null, "\u304A\u75B2\u308C\u3055\u307E\u3067\u3057\u305F\uFF01"),
        React.createElement("div", { className: css(styles.day) },
            "DAY",
            day,
            "\u5B8C\u4E86")));
};
var styles = StyleSheet.create({
    container: (_a = {
            fontWeight: 700,
            fontSize: 22,
            letterSpacing: '0.04em',
            color: Colors.White
        },
        _a[MediaBreakPointUp.MD] = {
            fontSize: 30,
        },
        _a),
    day: (_b = {
            fontSize: 20
        },
        _b[MediaBreakPointUp.MD] = {
            fontSize: 22,
        },
        _b),
});
