import axios from 'axios';
export var LineAuthType = {
    signIn: 'sign_in',
};
var LINEClient = /** @class */ (function () {
    function LINEClient(config) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "axios", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "channelId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "loginState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "oauthCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "subWindowName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'line_oauth_window'
        });
        this.config = config;
        this.axios = axios.create();
        this.axios.interceptors.request.use(function (req) {
            var header = {
                'Content-Type': 'application/x-www-form-urlencoded',
            };
            req.headers = Object.assign({}, req.headers, header);
            return req;
        });
        this.axios.interceptors.response.use(function (res) {
            return res;
        }, function (error) {
            throw error;
        });
    }
    /*------ commands ------*/
    /**
     * LINEの認証画面に飛ばす。コールバック後に別関数でトークンが取得できる
     */
    Object.defineProperty(LINEClient.prototype, "openLINEAuth", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (type) {
            this.genLoginState();
            if (!this.redirectURI)
                return;
            if (type == 'sign_in') {
                window.location.href = this.redirectURI;
            }
        }
    });
    /*------ utils ------*/
    Object.defineProperty(LINEClient.prototype, "setCode", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (code) {
            this.oauthCode = code;
        }
    });
    Object.defineProperty(LINEClient.prototype, "redirectURI", {
        get: function () {
            return "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=".concat(this.config.lineChannelId, "&redirect_uri=").concat(this.config.lineCallBackUrl, "&state=").concat(this.loginState, "&bot_prompt=normal&scope=openid%20email%20profile");
        },
        enumerable: false,
        configurable: true
    });
    /**
     * login前にgetしたstateとcallback後に返ってきたstateが同じか検証
     * @param state
     */
    Object.defineProperty(LINEClient.prototype, "validateState", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (state) {
            return this.loginState === state;
        }
    });
    Object.defineProperty(LINEClient.prototype, "genLoginState", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var r = Math.random() * 10000000;
            this.loginState = String(r);
        }
    });
    return LINEClient;
}());
export { LINEClient };
