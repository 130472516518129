import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '../../styles/variables';
export var CloseIcon = function (props) {
    var size = props.size;
    var styles = StyleSheet.create({
        container: {
            width: size || 12,
            height: size || 12,
            position: 'relative',
        },
        icon: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            cursor: 'pointer',
        },
    });
    var color = props.color || Colors.Gray;
    return (React.createElement("span", { onClick: props.onClick, className: css(styles.container, props.containerClassObject) },
        React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fill: color, d: "M1.22879 11.4794L0.520664 10.7713C0.369414 10.62 0.369414 10.3657 0.520664 10.2144L10.2144 0.520664C10.3657 0.369414 10.62 0.369414 10.7713 0.520664L11.4794 1.22879C11.6307 1.38004 11.6307 1.63441 11.4794 1.78566L1.78566 11.4794C1.62754 11.6307 1.38004 11.6307 1.22879 11.4794Z" }),
            React.createElement("path", { fill: color, d: "M11.4176 10.8332L10.8333 11.4176C10.6476 11.6032 10.3451 11.6032 10.1526 11.4176L0.575742 1.84074C0.390117 1.65511 0.390117 1.35262 0.575742 1.16012L1.16012 0.575742C1.34575 0.390117 1.64826 0.390117 1.84076 0.575742L11.4176 10.1526C11.6101 10.3451 11.6101 10.6476 11.4176 10.8332Z" }))));
};
