import { StyleSheet } from 'aphrodite';
// 上部に表示される固定ヘッダー分の高さを取得
// NOTE
// useSmMediaQueryとMobileHeaderHeightを使用すれば同様な値を取得可能だが、
// 固定ヘッダーの非表示は厳密にはmain.tsxのisHeaderDisplayableで行なっているためこの関数の使用を推奨
export var fixedHeaderHeight = function () {
    var fixedHeader = document.getElementById('fixedHeader');
    return fixedHeader ? fixedHeader.clientHeight : 0;
};
export var drawerWidth = {
    xs: 256,
    md: 72,
    xl: 256,
};
export var settingMenuWidth = {
    xl: 375,
};
export var utils = StyleSheet.create({
    imgFluid: {
        maxWidth: '100%',
        height: 'auto',
    },
    whiteSpaceNoWrap: {
        whiteSpace: 'nowrap',
    },
    ellipsedLabel: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});
export default utils;
