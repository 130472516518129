import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import { useSmMediaQuery } from '@lean-body/src/util/media_query_hooks';
export var AnnouncementBanner = function (_a) {
    var pcBannerImgPath = _a.pcBannerImgPath, spBannerImgPath = _a.spBannerImgPath, linkPath = _a.linkPath, dataTest = _a.dataTest;
    var isSM = useSmMediaQuery();
    var imgPath = isSM ? spBannerImgPath : pcBannerImgPath;
    return (React.createElement(Link, { to: linkPath, "data-test": dataTest },
        React.createElement("img", { src: imgPath, className: css(styles.announcementImg) })));
};
var styles = StyleSheet.create({
    announcementImg: {
        width: '100%',
        borderRadius: 4,
    },
});
