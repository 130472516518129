var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useSmMediaQuery } from '@lean-body/src/util';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { CloseIcon, Button, ButtonType } from '@lean-body/components/atoms';
import { Dialog, TimeSelectForm, CourseWeekdaysScheduleEditor, CourseSettingMenu, TimeSelectFormat, } from '@lean-body/components/molecules';
export var CourseSettingDialogType = {
    firstDialog: 'firstDialog',
    weekdaysEditor: 'weekdaysEditor',
    timeEditor: 'timeEditor',
    warningReset: 'warningReset',
};
var CLOSE_ICON_SIZE = 15;
export var CourseSettingDialog = function (_a) {
    var courseID = _a.courseID, courseSchedules = _a.courseSchedules, classObject = _a.classObject, classObjectForTip = _a.classObjectForTip, defaultOpenDialog = _a.defaultOpenDialog, onClickOutside = _a.onClickOutside, onSubmit = _a.onSubmit, onClickCancel = _a.onClickCancel, onClickChangeCourse = _a.onClickChangeCourse, updateCourseSchedules = _a.updateCourseSchedules, onClickResetCourseConfirm = _a.onClickResetCourseConfirm, tracker = _a.tracker;
    var ref = React.useRef(null);
    var isSM = useSmMediaQuery();
    var _b = React.useState(defaultOpenDialog !== null && defaultOpenDialog !== void 0 ? defaultOpenDialog : CourseSettingDialogType.firstDialog), opendDialog = _b[0], setOpendDialog = _b[1];
    React.useEffect(function () {
        var handleClickOutside = function (event) {
            if (opendDialog === CourseSettingDialogType.firstDialog) {
                if (ref.current && !ref.current.contains(event.target)) {
                    onClickOutside();
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return function () {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, opendDialog]);
    var onClickDay = function (clickedDay) {
        var newCourseSchedules = courseSchedules.map(function (courseSchedule) {
            if (clickedDay.dayOfWeek === courseSchedule.dayOfWeek) {
                clickedDay.isSelected = !clickedDay.isSelected;
                courseSchedule = clickedDay;
            }
            return courseSchedule;
        });
        updateCourseSchedules(newCourseSchedules);
        tracker.trackClickEvent({
            category: 'Select Course Schedule WeekDay',
            label: "clickedDay: ".concat(clickedDay.dayOfWeekToKanji),
        });
    };
    var onChangeTime = function (courseSchedule, time) {
        courseSchedule.changeTime(time);
        tracker.trackClickEvent({
            category: 'Select Course Schedule Time',
            label: "time: ".concat(time),
        });
    };
    var onClickResetCourse = function () {
        setOpendDialog(CourseSettingDialogType.warningReset);
        tracker.trackClickEvent({
            category: 'Button Clicks',
            label: "Select Reset Course, CourseID: ".concat(courseID),
        });
    };
    var handleClickResetCourseConfirm = function () {
        onClickResetCourseConfirm().then(function () { return setOpendDialog(CourseSettingDialogType.firstDialog); });
        tracker.trackClickEvent({
            category: 'Button Clicks',
            label: "Confirm Reset Course, CourseID: ".concat(courseID),
        });
    };
    var onClickCancelResetCourse = function () {
        setOpendDialog(CourseSettingDialogType.firstDialog);
        tracker.trackClickEvent({
            category: 'Button Clicks',
            label: "Cancel Reset Course, CourseID: ".concat(courseID),
        });
    };
    var handleCancel = function () {
        setOpendDialog(CourseSettingDialogType.firstDialog);
        onClickCancel();
    };
    return (React.createElement(React.Fragment, null,
        !isSM && (React.createElement("div", { ref: ref, className: css(styles.courseSettingMenuTipWrapper, classObjectForTip) },
            React.createElement(CourseSettingMenu, { isSM: false, onClickEditSchedule: function () { return setOpendDialog(CourseSettingDialogType.weekdaysEditor); }, onClickChangeCourse: onClickChangeCourse, onClickResetCourse: onClickResetCourse, classObject: styles.courseSettingMenuTipContainer }))),
        isSM && opendDialog === CourseSettingDialogType.firstDialog && (React.createElement(Dialog, { classObject: classObject, onClickCancel: onClickCancel, isBottom: isSM, borderRadius: 8, content: React.createElement("div", { className: css(styles.content) },
                React.createElement(CloseIcon, { color: Colors.Black, size: CLOSE_ICON_SIZE, containerClassObject: styles.closeIconContainer, onClick: handleCancel }),
                React.createElement("div", { className: css(styles.headerTitle) }, "\u30B3\u30FC\u30B9\u8A2D\u5B9A"),
                React.createElement(CourseSettingMenu, { isSM: true, classObject: styles.courseSettingMenuContainer, onClickEditSchedule: function () { return setOpendDialog(CourseSettingDialogType.weekdaysEditor); }, onClickChangeCourse: onClickChangeCourse, onClickResetCourse: onClickResetCourse })) })),
        opendDialog !== CourseSettingDialogType.firstDialog && (React.createElement(Dialog, { classObject: [styles.dialog, classObject], onClickCancel: onClickCancel, isBottom: isSM, borderRadius: 8, content: React.createElement("div", { className: css(styles.content) },
                opendDialog === CourseSettingDialogType.weekdaysEditor && (React.createElement(React.Fragment, null,
                    React.createElement(CloseIcon, { color: Colors.Black, size: CLOSE_ICON_SIZE, containerClassObject: styles.closeIconContainer, onClick: handleCancel }),
                    React.createElement("div", { className: css(styles.headerTitle) }, "\u30B9\u30B1\u30B8\u30E5\u30FC\u30EB\u8A2D\u5B9A"),
                    React.createElement(CourseWeekdaysScheduleEditor, { courseSchedules: courseSchedules, onClickDay: onClickDay, classObject: styles.courseWeekdaysScheduleEditorContainer }),
                    React.createElement(Button, { className: css(styles.scheduleButton), isRadius: true, buttonType: ButtonType.primary, onClick: function () { return setOpendDialog(CourseSettingDialogType.timeEditor); } }, "\u6642\u523B\u8A2D\u5B9A\u306B\u9032\u3080"))),
                opendDialog === CourseSettingDialogType.timeEditor && (React.createElement(React.Fragment, null,
                    React.createElement(CloseIcon, { color: Colors.Black, size: CLOSE_ICON_SIZE, containerClassObject: styles.closeIconContainer, onClick: handleCancel }),
                    React.createElement("div", { className: css(styles.headerTitle) }, "\u6642\u523B\u8A2D\u5B9A"),
                    React.createElement("div", { className: css(styles.submit), onClick: onSubmit }, "\u5B8C\u4E86"),
                    React.createElement("div", { className: css(styles.dayOfWeekTimeSettingWapper) }, courseSchedules.map(function (day, i) {
                        if (!day.isSelected) {
                            return null;
                        }
                        return (React.createElement("div", { key: i, className: css(styles.dayOfWeekTimeItem) },
                            React.createElement(TimeSelectForm, { containerClassObject: styles.timeSelect, currentValue: day.time, setCurrentValue: function (v) { return onChangeTime(day, v); }, label: "".concat(day.dayOfWeekToKanji, "\u66DC\u65E5"), format: TimeSelectFormat.hhmm })));
                    })))),
                opendDialog === CourseSettingDialogType.warningReset && (React.createElement("div", { className: css(styles.resetWarnBox) },
                    React.createElement("div", { className: css(styles.resetWarn) }, "\u30B3\u30FC\u30B9\u3092\u59CB\u3081\u304B\u3089\u518D\u958B\u3057\u307E\u3059\u3002\u30B3\u30FC\u30B9\u306E\u9032\u6357\u306F\u5931\u308F\u308C\u307E\u3059"),
                    React.createElement(Button, { className: css(styles.resetButton), isRadius: true, buttonType: ButtonType.alert, onClick: handleClickResetCourseConfirm }, "\u30B3\u30FC\u30B9\u3092\u30EA\u30BB\u30C3\u30C8"),
                    React.createElement("div", { className: css(styles.backButton), onClick: onClickCancelResetCourse }, "\u30AD\u30E3\u30F3\u30BB\u30EB")))) }))));
};
var styles = StyleSheet.create({
    dialog: (_a = {},
        _a[MediaBreakPointUp.MD] = {
            top: '30%',
            width: 375,
            transform: 'translateX(-50%)',
        },
        _a),
    content: {
        margin: '11px 20px',
    },
    closeIconContainer: {
        position: 'absolute',
        top: 15,
        left: 20,
        cursor: 'pointer',
    },
    headerTitle: {
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        borderBottom: "1px solid ".concat(Colors.Gray),
        paddingBottom: 10,
        marginBottom: 10,
    },
    submit: {
        position: 'absolute',
        top: 10,
        right: 18,
        cursor: 'pointer',
    },
    courseSettingMenuContainer: {
        margin: 8,
    },
    courseWeekdaysScheduleEditorContainer: {
        marginTop: 40,
    },
    scheduleButton: {
        display: 'block',
        width: '100%',
        marginTop: 54,
    },
    dayOfWeekTimeSettingWapper: {
        margin: '20px 4px 30px',
        overflow: 'auto',
        maxHeight: 300,
    },
    dayOfWeekTimeItem: {
        paddingTop: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    timeSelect: {
        width: '100%',
    },
    validateWarning: {
        fontWeight: 400,
        fontSize: 14,
        textAlign: 'center',
        letterSpacing: '0.04em',
        color: Colors.Accent,
        margin: 12,
    },
    resetWarnBox: {
        margin: '25px 0',
    },
    resetWarn: {
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'center',
        letterSpacing: '0.04em',
        color: Colors.GrayDarkest,
        margin: '14px 0 20px 0',
    },
    resetButton: {
        display: 'block',
        width: '100%',
        fontSize: 16,
    },
    backButton: {
        marginTop: 20,
        color: Colors.GrayDarkest,
        fontWeight: 700,
        fontSize: 16,
        textAlign: 'center',
        cursor: 'pointer',
    },
    courseSettingMenuTipWrapper: {
        width: 233,
        borderRadius: 8,
        background: Colors.White,
        filter: 'drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1))',
    },
    courseSettingMenuTipContainer: {
        margin: '8px 28px',
    },
});
