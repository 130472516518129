import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { DayIcon, DayIconTimeOfDay } from '../atoms/icons/day_icon';
var MORNING_START_TIME = 400;
var AFTERNOON_START_TIME = 1100;
var NIGHT_START_TIME = 1900;
export var CourseTimeInfo = function (_a) {
    var time = _a.time, classObject = _a.classObject;
    var timeInNumber = Number(time.replace(':', ''));
    return (React.createElement("div", { className: css(styles.container, classObject) }, timeInNumber >= MORNING_START_TIME && timeInNumber < AFTERNOON_START_TIME ? (React.createElement(React.Fragment, null,
        React.createElement(DayIcon, { timeOfDay: DayIconTimeOfDay.morning, classObject: styles.infoIcon }),
        React.createElement("span", { className: css(styles.infoText) }, "\u671D\u306B\u904B\u52D5\u3057\u3066\u57FA\u790E\u4EE3\u8B1D\u304C\u4E0A\u3052\u308B\u3068\u30011\u65E5\u306E\u6D88\u8CBB\u30AB\u30ED\u30EA\u30FC\u3092\u5897\u3084\u3059\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002"))) : timeInNumber >= AFTERNOON_START_TIME && timeInNumber < NIGHT_START_TIME ? (React.createElement(React.Fragment, null,
        React.createElement(DayIcon, { timeOfDay: DayIconTimeOfDay.afternoon, classObject: styles.infoIcon }),
        React.createElement("span", { className: css(styles.infoText) }, "\u663C\u301C\u5915\u65B9\u306F\u4F53\u304C\u6E29\u304B\u304F\u306A\u3063\u3066\u3044\u308B\u305F\u3081\u3001\u8102\u80AA\u71C3\u713C\u306B\u9069\u3057\u3066\u3044\u307E\u3059\u3002"))) : (React.createElement(React.Fragment, null,
        React.createElement(DayIcon, { timeOfDay: DayIconTimeOfDay.night, classObject: styles.infoIcon }),
        React.createElement("span", { className: css(styles.infoText) }, "\u591C\u306B\u4F53\u3092\u307B\u3050\u3059\u3068\u30EA\u30E9\u30C3\u30AF\u30B9\u52B9\u679C\u304C\u3042\u308B\u305F\u3081\u3001\u8CEA\u306E\u826F\u3044\u7761\u7720\u306B\u3064\u306A\u304C\u308A\u307E\u3059\u3002")))));
};
var styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.PrimaryLighter,
        borderRadius: 10,
        padding: 16,
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    infoIcon: {
        minWidth: 30,
    },
    infoText: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: 0.48,
    },
});
