var _a;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors, MediaBreakPointUp } from '../styles';
import { Link } from 'react-router-dom';
import { routes } from '@lean-body/src/route';
import { ShadowHeartIcon } from '@lean-body/components/atoms/icons/shadow_heart_icon';
import { HeartIcon } from '../atoms/icons/heart_icon';
export var PlaylistComponentSquare = function (_a) {
    var classObject = _a.classObject, playlist = _a.playlist, onClickPlaylist = _a.onClickPlaylist, addFavoriteProgram = _a.addFavoriteProgram, unFavoriteProgram = _a.unFavoriteProgram, isMin = _a.isMin, isLazyload = _a.isLazyload;
    var _b = React.useState(playlist.isFavorited), isFavorited = _b[0], setIsFavorited = _b[1];
    var onClick = function (playlist) {
        if (!onClickPlaylist) {
            return null;
        }
        onClickPlaylist(playlist);
    };
    var clickFavorite = function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (isFavorited) {
            unFavoriteProgram(playlist.id);
            setIsFavorited(false);
        }
        else {
            addFavoriteProgram(playlist.id);
            setIsFavorited(true);
        }
    };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(Link, { className: css(styles.item, isMin && styles.itemMin), to: routes.PLAYLIST.compile({ id: playlist.id.toString() }), onClick: function () { return onClick(playlist); } },
            React.createElement("div", { className: css(styles.headImg), "data-test": "playlistCard" },
                React.createElement(ShadowHeartIcon, { color: Colors.White, filled: isFavorited, classObject: [styles.favoriteIconTopRight], onClick: clickFavorite }),
                isLazyload ? (React.createElement("img", { className: "swiper-lazy ".concat(css(styles.img)), "data-src": playlist.thumbnailURL })) : (React.createElement("img", { className: css(styles.img), src: playlist.thumbnailURL })),
                React.createElement("div", { className: css(styles.labelBottomRight, isMin && styles.labelBottomRightMin) }, "".concat(playlist.lessonCount, "LESSON"))),
            React.createElement("div", { className: css(styles.itemDescription) },
                React.createElement("div", { className: css(styles.itemName, isMin && styles.itemNameMin) }, playlist.name),
                React.createElement("div", { className: css(styles.itemInfo, isMin && styles.itemInfoMin) },
                    React.createElement(HeartIcon, { classObject: [styles.favoriteIcon], color: Colors.Black }),
                    playlist.favoriteCount.toLocaleString(),
                    "\u30FB",
                    playlist.difficulty.name)))));
};
var styles = StyleSheet.create({
    container: {
        borderRadius: 4,
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px 0',
        overflow: 'hidden',
        color: Colors.Black,
        cursor: 'pointer',
        width: '100%',
    },
    itemMin: {
        gap: '5px 0',
    },
    itemDescription: (_a = {
            fontSize: 14,
            flex: 1
        },
        _a[MediaBreakPointUp.MD] = {
            fontSize: 12,
        },
        _a),
    headImg: {
        position: 'relative',
        flex: 1,
    },
    itemName: {
        fontWeight: 'bold',
        overflow: 'hidden',
    },
    itemNameMin: {
        fontSize: 12,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
        height: 36,
    },
    itemInfo: {
        display: 'flex',
        alignItems: 'center',
        margin: '2px 0',
    },
    itemInfoMin: {
        fontSize: 12,
    },
    favoriteIconTopRight: {
        width: 37,
        height: 33,
        position: 'absolute',
        top: 4,
        right: 4,
    },
    favoriteIcon: {
        width: 12,
        height: 11,
        marginRight: 4,
    },
    img: {
        borderRadius: 4,
        width: '100%',
        display: 'block',
    },
    labelBottomRight: {
        fontSize: 12,
        fontWeight: 'bold',
        letterSpacing: '0.04em',
        lineHeight: 1,
        borderRadius: 4,
        padding: '3px 4px',
        color: Colors.White,
        backgroundColor: Colors.Black,
        position: 'absolute',
        bottom: 6,
        right: 6,
    },
    labelBottomRightMin: {
        fontSize: 10,
    },
});
