var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles/';
var cancelIconPath = '/assets/images/icons/cancel.png';
// TODO: モーダルのキャンセルボタン用にCSSが書かれているので名前をButtonHideModalなどにするかもう少し汎用性の高いコンポーネントにする
export var ButtonCancel = function (props) {
    return (React.createElement("button", { className: css(styles.button), onClick: props.onClick, "data-test": "closeDialog" },
        React.createElement("div", { className: css(styles.activeInner) },
            React.createElement("img", { className: css(styles.icon), src: cancelIconPath }))));
};
var styles = StyleSheet.create({
    button: (_a = {
            position: 'absolute',
            width: 50,
            cursor: 'pointer',
            height: 50,
            top: '10%',
            float: 'right',
            right: '5%',
            borderRadius: '50%',
            backgroundColor: '#333333',
            border: 'none',
            boxShadow: '0 2px 10px rgba(20,20,20,0.09)',
            outline: 'none'
        },
        _a[MediaBreakPointUp.SM] = {
            right: '10%',
        },
        _a),
    inner: {
        position: 'absolute',
        top: -18,
        left: -20,
        height: '175%',
        width: '175%',
        color: Colors.Primary,
    },
    activeInner: {
        position: 'absolute',
        top: -6,
        left: -7,
        height: '125%',
        width: '125%',
    },
    icon: {
        top: '35%',
        left: '2%',
        position: 'relative',
        width: '31%',
        height: 'auto',
    },
});
