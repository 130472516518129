var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { bind } from 'bind-decorator';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { FavoriteStatus } from '@lean-body/src/interfaces';
import { Lesson, } from '@lean-body/src/domain';
import { LessonType, routes, generateLessonQueryParams, COURSE_DAY_LESSON_ID_QUERY_PARAM_KEY, } from '@lean-body/src/route';
import { MediaBreakPointUp, Sizes } from '@lean-body/components/styles';
import { HeroHeader } from '@lean-body/src/view/atoms';
import { VideoVM, Video, LessonMeta, ReceivedBadgeOrUserSeasonRankDialog, TwitterFormDialog, FavoriteMessageDialog, SkipCourseDayDialog, } from '@lean-body/src/view/molecules';
import { FIXED_FOOTER_HEIGHT, PageLoading } from '@lean-body/src/view/organisms';
import { LocalStorage } from '@lean-body/src/infra';
import { UserSeasonAggregate } from '@lean-body/src/domain/user_season_aggregate';
import { useXlMediaQuery, useQuery } from '@lean-body/src/util';
import { LessonPlaylistBox, SHADOW_WIDTH } from '../molecules/lesson_playlist_box';
import { useNavigate } from 'react-router';
export var VIDEO_WIDTH_OVER_MD = 600;
export var LessonPage = observer(function (_a) {
    var _b, _c, _d;
    var vm = _a.vm;
    var isXL = useXlMediaQuery();
    if (!vm.isFirstViewLoaded) {
        return React.createElement(PageLoading, null);
    }
    var styles = StyleSheet.create({
        container: (_b = {
                height: "calc(100vh - ".concat(FIXED_FOOTER_HEIGHT, "px - ").concat(Sizes.MobileHeaderHeight, "px)"),
                margin: '0 auto'
            },
            _b[MediaBreakPointUp.MD] = {
                maxWidth: VIDEO_WIDTH_OVER_MD + SHADOW_WIDTH * 2,
                minHeight: '100vh',
            },
            _b[MediaBreakPointUp.XL] = {
                height: 'unset',
                maxWidth: 1024,
                display: 'flex',
                marginTop: 32,
                paddingBottom: 120,
            },
            _b),
        lessonContentContainer: (_c = {
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            },
            _c[MediaBreakPointUp.XL] = {
                maxWidth: VIDEO_WIDTH_OVER_MD,
                marginRight: 32,
            },
            _c),
        heroHeader: (_d = {},
            _d[MediaBreakPointUp.MD] = {
                padding: "0 ".concat(SHADOW_WIDTH, "px"),
            },
            _d[MediaBreakPointUp.XL] = {
                padding: 0,
            },
            _d),
        favoriteMessageDialog: {
            flexDirection: 'column',
        },
    });
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.lessonContentContainer) },
            React.createElement("div", { className: css(styles.heroHeader) },
                React.createElement(HeroHeader, { heroURL: vm.lesson.heroURL, videoVisible: vm.videoVM.playStarted }, vm.showVideo && (React.createElement(Video, { vm: vm.videoVM, src: vm.lesson.video.hlsURL, playButtonVisible: vm.videoVM.playButtonVisible, onClickPlayButton: vm.onClickVideoPlayButton })))),
            React.createElement(LessonMeta, { lesson: vm.lesson, currentLessonIndex: vm.currentLessonIndex, program: vm.program, nextLesson: vm.nextLesson, programLessons: vm.programLessons, isFavorite: vm.lesson.isFavorite, onClickFavLesson: vm.onClickFavLesson, onClickTwitterShareIfAuthed: vm.onClickTwitterShareIfAuthed, addFavoriteLesson: vm.apiClient.addFavoriteLesson, unFavoriteLesson: vm.apiClient.unFavoriteLesson, twitterService: vm.twitterService, onClickLesson: vm.onClickProgramLesson, onClickShowCourseDaySkipDialog: vm.onClickShowCourseDaySkipDialog, displayLessonPlaylistBox: !isXL, courseDay: vm.courseDay })),
        isXL && (React.createElement(LessonPlaylistBox, { program: vm.program, courseDay: vm.courseDay, currentLessonIndex: vm.currentLessonIndex, nextLesson: vm.nextLesson, programLessons: vm.programLessons, addFavoriteLesson: vm.apiClient.addFavoriteLesson, unFavoriteLesson: vm.apiClient.unFavoriteLesson, onClickLesson: vm.onClickProgramLesson, onClickShowCourseDaySkipDialog: vm.onClickShowCourseDaySkipDialog, isShowOtherLessons: true, isSkippableCourseDay: vm.lesson.isSkippableCourseDay })),
        (vm.isReceivedBadge || vm.userSeasonAggregate) && (React.createElement(ReceivedBadgeOrUserSeasonRankDialog, { badges: vm.badges, userSeasonAggregate: vm.userSeasonAggregate, onClickCancel: vm.onCloseReceivedBadgeOrUserSeasonRankDialog, twitterService: vm.twitterService, onClickTwitterShareIfAuthed: vm.onClickTwitterShareIfAuthed, updateDisplayedUserBadges: vm.apiClient.updateDisplayedUserBadges })),
        vm.isShowTwitterFormDialog && (React.createElement(TwitterFormDialog, { imageURL: vm.twitterService.tweetImgSrc, centerImageWidth: vm.twitterService.tweetCenterImageWidth, defaultText: vm.twitterService.tweetDefaultText, onClickCancel: vm.onClickTwitterPostCancel, submit: vm.onClickTwitterPost })),
        vm.isLessonFavoriteDialogOpen && (React.createElement(FavoriteMessageDialog, { close: vm.onClickCancelFavoriteDialog, classObject: styles.favoriteMessageDialog })),
        vm.isSkipCourseDayDialogOpen && (React.createElement(SkipCourseDayDialog, { skip: vm.onClickSkipCourseDay, close: vm.onClickCancelSkipCourseDay }))));
});
export var LessonPageState;
(function (LessonPageState) {
    LessonPageState[LessonPageState["Loading"] = 0] = "Loading";
    LessonPageState[LessonPageState["Ready"] = 1] = "Ready";
    LessonPageState[LessonPageState["Playing"] = 2] = "Playing";
    LessonPageState[LessonPageState["Quit"] = 3] = "Quit";
    LessonPageState[LessonPageState["Result"] = 4] = "Result";
    LessonPageState[LessonPageState["Finished"] = 5] = "Finished";
})(LessonPageState || (LessonPageState = {}));
var LessonPageViewModel = /** @class */ (function () {
    function LessonPageViewModel(slug, lessonType, lessonService, signedCookieService, router, tracker, apiClient, config, twitterService) {
        Object.defineProperty(this, "router", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "navigate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "slug", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "signedCookieService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "localStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "query", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "videoVM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "lessonHistory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: LessonPageState.Loading
        });
        Object.defineProperty(this, "lesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "programLessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "currentLessonIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "prevLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "nextLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "favoritePrograms", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "isFavorited", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "favoriteLessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "isFirstViewLoaded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        // 未表示のバッジ
        Object.defineProperty(this, "badges", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        // Videoコンポーネントのマウント/アンマウントを制御し初期処理を行うためのフラグ
        Object.defineProperty(this, "showVideo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        // 表示しているチャレンジプログラム
        Object.defineProperty(this, "program", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "session", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "width", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "favoriteRequesting", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "userSeasonAggregate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "twitterService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isShowTwitterFormDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "courseDay", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "courseLessonID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "isLessonFavoriteDialogOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isSkipCourseDayDialogOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isAutoPlay", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        makeObservable(this, {
            videoVM: observable.ref,
            lessonHistory: observable.ref,
            state: observable,
            lesson: observable.ref,
            currentLessonIndex: observable.ref,
            nextLesson: observable.ref,
            programLessons: observable,
            favoritePrograms: observable.ref,
            isFavorited: observable,
            favoriteLessons: observable.ref,
            isFirstViewLoaded: observable,
            badges: observable,
            showVideo: observable,
            userSeasonAggregate: observable,
            isShowTwitterFormDialog: observable,
            courseLessonID: observable,
            isLessonFavoriteDialogOpen: observable,
            isSkipCourseDayDialogOpen: observable,
            isAutoPlay: observable,
        });
        this.slug = slug;
        this.lessonType = lessonType;
        this.lessonService = lessonService;
        this.signedCookieService = signedCookieService;
        this.config = config;
        this.tracker = tracker;
        this.router = router;
        this.navigate = useNavigate();
        this.query = useQuery();
        this.localStorage = new LocalStorage();
        this.apiClient = apiClient;
        this.twitterService = twitterService;
        this.initialize();
    }
    /*---------- 初期処理  ----------*/
    // 動画ページの初期化
    Object.defineProperty(LessonPageViewModel.prototype, "initialize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.load()];
                        case 1:
                            _a.sent();
                            if (this.twitterService.showFormDialog) {
                                this.isShowTwitterFormDialog = true;
                                this.twitterService.showFormDialog = false;
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // ページの表示に必要なデータの取得
    Object.defineProperty(LessonPageViewModel.prototype, "load", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var lesson, _a, profile;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // // コース関連のページからの繊維の場合はIDクエリパラメータに設定されているので取得
                            this.courseLessonID = Number(this.query.get(COURSE_DAY_LESSON_ID_QUERY_PARAM_KEY));
                            return [4 /*yield*/, this.apiClient.fetchLessonWithSlug(this.slug, this.courseLessonID).then(function (lesson) { return lesson; })];
                        case 1:
                            lesson = _b.sent();
                            this.program = lesson.programs[0];
                            this.programLessons = this.program.lessons;
                            if (!this.isCourseLessonPage) return [3 /*break*/, 3];
                            _a = this;
                            return [4 /*yield*/, this.apiClient.fetchCourseDayBy(this.courseLessonID).then(function (courseDay) { return courseDay; })];
                        case 2:
                            _a.courseDay = _b.sent();
                            _b.label = 3;
                        case 3:
                            this.videoVM = new VideoVM(this.router, this.navigate, this.apiClient, this.lessonService, this.signedCookieService, true, this.reload, lesson, this.program, this.playPrevVideo, this.playNextVideo, this.courseLessonID, null, this.onCompleteLesson);
                            this.setLessonData(lesson);
                            // ファーストビューに必要なコンテンツの初期処理が完了したタイミングでページをレンダリングを開始
                            this.isFirstViewLoaded = true;
                            // ユーザが獲得した未表示のバッジを取得
                            this.apiClient.fetchUndisplayedBadges().then(function (badges) {
                                _this.badges = badges;
                            });
                            this.loadUserSeasonAggregateRank();
                            return [4 /*yield*/, this.apiClient.fetchMypageProfile()];
                        case 4:
                            profile = _b.sent();
                            this.isAutoPlay = profile.isAutoPlayNextVideo;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(LessonPageViewModel.prototype, "isReceivedBadge", {
        get: function () {
            return this.badges.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LessonPageViewModel.prototype, "loadUserSeasonAggregateRank", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var userSeasonAggregateData = this.localStorage.loadUserSeasonAggregateRank;
            if (userSeasonAggregateData) {
                this.userSeasonAggregate = new UserSeasonAggregate(userSeasonAggregateData, this.config);
            }
        }
    });
    /*---------- Event Handlers  ----------*/
    Object.defineProperty(LessonPageViewModel.prototype, "onClickFavLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (this.favoriteRequesting)
                                return [2 /*return*/];
                            this.favoriteRequesting = true;
                            if (!this.isFavorited) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.apiClient.unFavoriteLesson(this.lesson.id)];
                        case 1:
                            _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, this.apiClient.addFavoriteLesson(this.lesson.id)];
                        case 3:
                            _b.sent();
                            _b.label = 4;
                        case 4:
                            this.isFavorited = !this.isFavorited;
                            this.favoriteRequesting = false;
                            this.tracker.trackFavoriteLesson(this.lesson, this.isFavorited ? FavoriteStatus.Add : FavoriteStatus.Remove);
                            _a = this;
                            return [4 /*yield*/, this.updateProgramLesson(this.lesson.id, this.isFavorited)];
                        case 5:
                            _a.lesson = _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(LessonPageViewModel.prototype, "onClickTwitterShareIfAuthed", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.isShowTwitterFormDialog = true;
        }
    });
    Object.defineProperty(LessonPageViewModel.prototype, "onClickTwitterPost", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (text, file) {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    this.twitterService.postTweet(text, file).finally(function () {
                        _this.isShowTwitterFormDialog = false;
                    });
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(LessonPageViewModel.prototype, "onClickTwitterPostCancel", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.isShowTwitterFormDialog = false;
        }
    });
    // レッスンページ上部の再生ボタンのクリックイベントのハンドリング
    Object.defineProperty(LessonPageViewModel.prototype, "onClickVideoPlayButton", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: 
                        // 動画を再生する
                        return [4 /*yield*/, this.videoVM.playLessonVideo(this.program, this.lesson, this.courseLessonID)
                            // セッションで初再生 & /recommendレッスンをクリック(= ページ経由)してたら発火
                        ];
                        case 1:
                            // 動画を再生する
                            _a.sent();
                            // セッションで初再生 & /recommendレッスンをクリック(= ページ経由)してたら発火
                            if (this.tracker.playHistory.length === 0 && this.tracker.recommendClickedLesson) {
                                this.tracker.trackFirstMoviePlayEvent();
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // レッスンページの他のレッスンのクリックイベントのハンドリング
    Object.defineProperty(LessonPageViewModel.prototype, "onClickProgramLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lesson) {
            var url = routes.LESSON.compile({ slug: lesson.slug, type: LessonType.normal });
            var params = lesson.courseLessonID ? generateLessonQueryParams(lesson.courseLessonID) : '';
            this.navigate("".concat(url).concat(params));
        }
    });
    // バッジの取得情報をリセットする
    Object.defineProperty(LessonPageViewModel.prototype, "onCloseReceivedBadgeOrUserSeasonRankDialog", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.badges = [];
            this.userSeasonAggregate = null;
        }
    });
    Object.defineProperty(LessonPageViewModel.prototype, "onClickProgramTop", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var p = this.lesson.programs.find(function (p) { return !p.isChallenge; });
            this.router.push(routes.PROGRAM, { slug: p.slug });
        }
    });
    // ページの表示データを再取得
    Object.defineProperty(LessonPageViewModel.prototype, "reload", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // ビデオプレーヤーの状態を完全に破棄する
                            this.showVideo = false;
                            this.videoVM = new VideoVM(this.router, this.navigate, this.apiClient, this.lessonService, this.signedCookieService, true, this.reload, this.lesson, this.program, this.playPrevVideo, this.playNextVideo, this.courseLessonID, null, this.onCompleteLesson);
                            this.videoVM.setLessonVideo(this.program, this.lesson);
                            this.videoVM.setPrevLesson(this.prevLesson);
                            this.videoVM.setNextLesson(this.nextLesson);
                            return [4 /*yield*/, this.load()];
                        case 1:
                            _a.sent();
                            this.showVideo = true;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(LessonPageViewModel.prototype, "onCompleteLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            if (this.isCourseLessonPage) {
                this.apiClient.fetchCourseDayBy(this.courseLessonID).then(function (courseDay) {
                    if (!!courseDay && courseDay.isDisplayCourseReward) {
                        _this.showVideo = false;
                        return _this.navigate(routes.COURSE_REWARD.compile({ courseDayID: String(courseDay.id) }));
                    }
                });
            }
            if (!this.isAutoPlay && !this.localStorage.loadHideFavoriteMessageDialog) {
                this.isLessonFavoriteDialogOpen = true;
                this.tracker.trackClickEvent({
                    category: 'Opened Favorite Dialog',
                });
            }
        }
    });
    Object.defineProperty(LessonPageViewModel.prototype, "onClickCancelFavoriteDialog", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.localStorage.saveHideFavoriteMessageDialog(true);
            this.isLessonFavoriteDialogOpen = false;
            this.tracker.trackClickEvent({
                category: 'Closed Favorite Dialog',
            });
        }
    });
    Object.defineProperty(LessonPageViewModel.prototype, "updateProgramLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lessonId, isFavorite) {
            return __awaiter(this, void 0, void 0, function () {
                var sameLessonIndexInProgram, fetchedLesson;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            sameLessonIndexInProgram = this.programLessons.findIndex(function (v) { return lessonId === v.id; });
                            return [4 /*yield*/, this.apiClient.fetchLessonWithSlug(this.programLessons[sameLessonIndexInProgram].slug, this.courseLessonID)];
                        case 1:
                            fetchedLesson = _a.sent();
                            fetchedLesson.isFavorite = isFavorite;
                            this.programLessons[sameLessonIndexInProgram] = fetchedLesson;
                            return [2 /*return*/, fetchedLesson];
                    }
                });
            });
        }
    });
    Object.defineProperty(LessonPageViewModel.prototype, "playPrevVideo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var url = routes.LESSON.compile({ slug: this.prevLesson.slug, type: LessonType.normal });
            var params = generateLessonQueryParams(this.prevLesson.courseLessonID);
            this.navigate("".concat(url).concat(params));
        }
    });
    Object.defineProperty(LessonPageViewModel.prototype, "playNextVideo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.nextLesson) {
                return;
            }
            var url = routes.LESSON.compile({ slug: this.nextLesson.slug, type: LessonType.normal });
            var params = generateLessonQueryParams(this.nextLesson.courseLessonID);
            this.navigate("".concat(url).concat(params));
        }
    });
    Object.defineProperty(LessonPageViewModel.prototype, "setLessonData", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lesson) {
            var _a;
            this.lesson = lesson;
            this.isFavorited = lesson.isFavorite;
            this.currentLessonIndex = this.program.lessonIndex(lesson.id);
            this.lesson.orderIndex = (_a = lesson.programsLessons[0]) === null || _a === void 0 ? void 0 : _a.orderIndex;
            this.prevLesson = this.program.prevLesson(this.currentLessonIndex);
            this.nextLesson = this.program.nextLesson(this.currentLessonIndex);
            this.videoVM.setIsFirstLesson(this.program.isFirstLesson(this.currentLessonIndex));
            this.videoVM.setIsLastLesson(this.program.isLastLesson(this.currentLessonIndex));
            this.videoVM.setPrevLesson(this.prevLesson);
            this.videoVM.setNextLesson(this.nextLesson);
        }
    });
    Object.defineProperty(LessonPageViewModel.prototype, "isCourseLessonPage", {
        get: function () {
            return !!this.courseLessonID;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LessonPageViewModel.prototype, "onClickShowCourseDaySkipDialog", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            e.stopPropagation();
            e.preventDefault();
            this.isSkipCourseDayDialogOpen = true;
        }
    });
    Object.defineProperty(LessonPageViewModel.prototype, "onClickSkipCourseDay", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var nextCourseDay, firstCourseDaysLesson, url, params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.apiClient.skipCourseDay(this.courseDay.id).then(function (nextCourseDay) { return nextCourseDay; })];
                        case 1:
                            nextCourseDay = _a.sent();
                            firstCourseDaysLesson = nextCourseDay.courseDaysLessons[0];
                            url = routes.LESSON.compile({ type: LessonType.normal, slug: firstCourseDaysLesson.lesson.slug });
                            params = generateLessonQueryParams(firstCourseDaysLesson.id);
                            this.navigate("".concat(url).concat(params));
                            this.isSkipCourseDayDialogOpen = false;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(LessonPageViewModel.prototype, "onClickCancelSkipCourseDay", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.isSkipCourseDayDialogOpen = false;
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], LessonPageViewModel.prototype, "onClickFavLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], LessonPageViewModel.prototype, "onClickTwitterShareIfAuthed", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, File]),
        __metadata("design:returntype", Promise)
    ], LessonPageViewModel.prototype, "onClickTwitterPost", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], LessonPageViewModel.prototype, "onClickTwitterPostCancel", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], LessonPageViewModel.prototype, "onClickVideoPlayButton", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Lesson]),
        __metadata("design:returntype", void 0)
    ], LessonPageViewModel.prototype, "onClickProgramLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], LessonPageViewModel.prototype, "onCloseReceivedBadgeOrUserSeasonRankDialog", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], LessonPageViewModel.prototype, "onClickProgramTop", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], LessonPageViewModel.prototype, "reload", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], LessonPageViewModel.prototype, "onCompleteLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], LessonPageViewModel.prototype, "onClickCancelFavoriteDialog", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], LessonPageViewModel.prototype, "playPrevVideo", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], LessonPageViewModel.prototype, "playNextVideo", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Lesson]),
        __metadata("design:returntype", void 0)
    ], LessonPageViewModel.prototype, "setLessonData", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], LessonPageViewModel.prototype, "onClickShowCourseDaySkipDialog", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], LessonPageViewModel.prototype, "onClickSkipCourseDay", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], LessonPageViewModel.prototype, "onClickCancelSkipCourseDay", null);
    return LessonPageViewModel;
}());
export { LessonPageViewModel };
