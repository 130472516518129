var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import * as routes from '@lean-body/src/route';
import { css, StyleSheet } from 'aphrodite';
import { useXlMediaQuery } from '@lean-body/src/util/media_query_hooks';
import { useQuery, useNowUnixtime, isAndroidApp, exceedDay } from '@lean-body/src/util';
import { MediaBreakPointUp, MediaBreakPointDown, Colors, drawerWidth } from '@lean-body/components/styles';
import { PageLoading } from '@lean-body/components/organisms';
import { LiveLessonList } from '@lean-body/components/organisms/live_lesson_list';
import { LiveScheduleBanner } from '../organisms/live_schedule_banner';
import { LiveNowInSchedule } from '../organisms/live_now_in_schedule';
import * as dayjs from 'dayjs';
import { LocalStorage, SessionStorage } from '@lean-body/src/infra';
import bind from 'bind-decorator';
import { UnderbarTabs } from '../molecules/underbar_tabs';
import { LiveLessonNotificationPermissionBanner } from '../molecules/live_lesson_notification_permission_banner';
import { SettingItemKeys } from '../organisms/setting_menu';
export var LiveScheduleTabs = {
    scheduled: '配信予定',
    rebroadcast: '見逃し配信中',
};
export var LIVE_CALENDAR_POSITION_TOP = 48;
export var LIVE_CALENDAR_MARGIN_LEFT = 80;
export var LIVE_CALENDAR_WIDTH = 375;
var TAB_QUERY_PARAMS = 'tab';
export var LiveSchedulePage = function (_a) {
    var _b, _c, _d, _e, _f;
    var vm = _a.vm;
    var navigate = useNavigate();
    var query = useQuery();
    var nowUnixTime = useNowUnixtime();
    var isXL = useXlMediaQuery();
    var _g = React.useState([]), liveLessons = _g[0], setLiveLessons = _g[1];
    var _h = React.useState(true), isLoading = _h[0], setIsLoading = _h[1];
    var _j = React.useState(null), nextLiveLesson = _j[0], setNextLiveLesson = _j[1];
    var selectedTab = query.get(TAB_QUERY_PARAMS);
    var _k = React.useState(LiveScheduleTabs[selectedTab] || LiveScheduleTabs.scheduled), tab = _k[0], setTab = _k[1];
    var tabKey = React.useMemo(function () {
        return Object.keys(LiveScheduleTabs).find(function (key) { return LiveScheduleTabs[key] === tab; });
    }, [tab]);
    var localStorage = new LocalStorage();
    var sessionStorage = new SessionStorage();
    var _l = React.useState(), showAndroidNotificationPermissionBanner = _l[0], setShowAndroidNotificationPermissionBanner = _l[1];
    useEffect(function () {
        setTab(LiveScheduleTabs[selectedTab] || LiveScheduleTabs.scheduled);
    }, [selectedTab]);
    useEffect(function () {
        if (tab) {
            navigate("".concat(routes.LIVE_SCHEDULE.compile(), "?").concat(TAB_QUERY_PARAMS, "=").concat(tabKey), { replace: true });
        }
        else {
            navigate(routes.LIVE_SCHEDULE.compile(), { replace: true });
        }
    }, [tab]);
    var now = new Date();
    var currentYear = now.getFullYear();
    var currentMonth = now.getMonth();
    var currentDay = now.getDate();
    var startAtFrom = new Date(currentYear, currentMonth, currentDay, 0, 0, 0);
    var startAtTo = new Date(2100, 0); // 未来のライブレッスンを全て取得したいので、遠い日付を設定。ライブレッスンの数が多くなってきたら全て取得しないようにする。
    useEffect(function () {
        Promise.all([
            requestLiveLessons(LiveScheduleTabs.scheduled),
            !nextLiveLesson && vm.apiClient.fetchNextLiveLesson(),
        ]).then(function (_a) {
            var liveLesson = _a[1];
            liveLesson && setNextLiveLesson(liveLesson);
            setIsLoading(false);
        });
        loadNotificationPermissionBanner();
    }, []);
    /*
      以下の条件でライブレッスンプッシュ通知許諾を求めるバナーを表示する
        1. 端末の通知設定がOFFになっている、もしくはLIVE配信通知を許可していない
        2. バナーを表示したことない、もしくは閉じた後1日が経過
    */
    var loadNotificationPermissionBanner = function () {
        var _a;
        if (isAndroidApp()) {
            var hasNotificationPermission = sessionStorage.loadAndroidDeviceNotificationPermission();
            var useLiveLessonNotifications = (_a = sessionStorage.loadAndroidNotificationSettings()) === null || _a === void 0 ? void 0 : _a.live;
            if (hasNotificationPermission && useLiveLessonNotifications) {
                return;
            }
            var dismissedTimestamp = localStorage.loadAndroidNotificationPermissionRequestUIDismissals().liveLessonBanner;
            if (dismissedTimestamp === false || exceedDay(dismissedTimestamp)) {
                setShowAndroidNotificationPermissionBanner(true);
            }
        }
    };
    var requestLiveLessons = function (targetTab) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = targetTab;
                    switch (_a) {
                        case LiveScheduleTabs.scheduled: return [3 /*break*/, 1];
                        case LiveScheduleTabs.rebroadcast: return [3 /*break*/, 3];
                    }
                    return [3 /*break*/, 5];
                case 1: return [4 /*yield*/, vm.apiClient
                        .fetchLiveLessonsByStartsAt(startAtFrom.getTime() / 1000, startAtTo.getTime() / 1000)
                        .then(function (resLiveLessons) {
                        setLiveLessons(resLiveLessons.filter(function (v) { return v.isWatchable || v.startsAtDayjs.isSame(dayjs(), 'month'); }));
                    })];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, vm.apiClient.fetchRebroadcastLiveLessons().then(function (resLiveLessons) {
                        setLiveLessons(resLiveLessons.filter(function (v) { return v.isWatchable; }));
                    })];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var setTabHandler = function (newTab) {
        setTab(newTab);
        requestLiveLessons(newTab);
    };
    var onClickNotificationPermissionBanner = function () {
        navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.notification));
    };
    var onCloseNotificationPermissionBanner = function () {
        var dismissalSettings = localStorage.loadAndroidNotificationPermissionRequestUIDismissals();
        // 1日後再度表示させるために、現在のtimestampを記録する
        dismissalSettings.liveLessonBanner = Date.now();
        localStorage.setAndroidNotificationPermissionRequestUIDismissals(dismissalSettings);
        setShowAndroidNotificationPermissionBanner(false);
    };
    var styles = StyleSheet.create({
        container: (_b = {
                paddingBottom: 40,
                paddingTop: nextLiveLesson && nextLiveLesson.isBeingHeldIn(nowUnixTime) ? 125 : 45
            },
            _b[MediaBreakPointUp.MD] = {
                paddingTop: nextLiveLesson && nextLiveLesson.isBeingHeldIn(nowUnixTime) ? 145 : 65,
            },
            _b[MediaBreakPointUp.XL] = {
                paddingTop: 65,
            },
            _b),
        sectionTitle: {
            fontSize: 28,
            fontWeight: 'bold',
            marginBottom: 24,
        },
        liveList: (_c = {},
            _c[MediaBreakPointUp.MD] = {
                maxWidth: 375,
                margin: '0 auto',
            },
            _c[MediaBreakPointUp.XL] = {
                minHeight: 560,
                maxWidth: 1042,
            },
            _c[MediaBreakPointDown.SM] = {
                margin: '0 20px',
            },
            _c.marginBottom = 40,
            _c),
        liveNow: {
            margin: '28px 0',
            borderRadius: 4,
        },
        header: (_d = {
                position: 'fixed',
                paddingBottom: 8,
                backgroundColor: Colors.White,
                top: 0
            },
            _d[MediaBreakPointDown.MD] = {
                padding: '5px 0px',
                top: 50,
                width: '100%',
            },
            _d[MediaBreakPointUp.MD] = {
                width: "calc(100% - ".concat(drawerWidth.md, "px)"),
            },
            _d[MediaBreakPointUp.XL] = {
                width: "calc(100% - ".concat(drawerWidth.xl, "px)"),
            },
            _d),
        underbarTabs: {
            paddingTop: 5,
        },
        banner: (_e = {
                marginBottom: 16
            },
            _e[MediaBreakPointUp.XL] = {
                borderRadius: 4,
            },
            _e),
        notificationPermissionBanner: {
            height: 40,
        },
        broadcasting: (_f = {},
            _f[MediaBreakPointUp.XL] = {
                position: 'fixed',
                left: 297,
                bottom: 32,
                borderRadius: 10,
                border: "1px solid ".concat(Colors.Gray),
                background: "linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%), #FFF",
                boxShadow: '0px 15px 30px 0px rgba(0, 180, 199, 0.08)',
            },
            _f),
    });
    if (isLoading) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.liveList) }, tab === LiveScheduleTabs.scheduled ? (React.createElement(React.Fragment, null,
            React.createElement(LiveScheduleBanner, { getBanners: vm.getLiveLessonBanners, classObject: styles.banner }),
            React.createElement(LiveLessonList, { liveLessons: liveLessons, apiClient: vm.apiClient, tracker: vm.tracker }))) : (React.createElement(LiveLessonList, { liveLessons: liveLessons, apiClient: vm.apiClient, tracker: vm.tracker }))),
        React.createElement("div", { className: css(styles.header) },
            showAndroidNotificationPermissionBanner && (React.createElement(LiveLessonNotificationPermissionBanner, { onClick: onClickNotificationPermissionBanner, onClose: onCloseNotificationPermissionBanner, classObject: styles.notificationPermissionBanner })),
            !isXL && nextLiveLesson && React.createElement(LiveNowInSchedule, { nextLiveLesson: nextLiveLesson }),
            React.createElement(UnderbarTabs, { setTab: setTabHandler, currentTab: tab, tabs: LiveScheduleTabs, classObject: styles.underbarTabs })),
        isXL && nextLiveLesson && (React.createElement(LiveNowInSchedule, { nextLiveLesson: nextLiveLesson, classObject: styles.broadcasting }))));
};
var LiveSchedulePageVM = /** @class */ (function () {
    function LiveSchedulePageVM(apiClient, tracker) {
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.apiClient = apiClient;
        this.tracker = tracker;
    }
    Object.defineProperty(LiveSchedulePageVM.prototype, "getLiveLessonBanners", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.apiClient.getLiveLessonBanners();
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], LiveSchedulePageVM.prototype, "getLiveLessonBanners", null);
    return LiveSchedulePageVM;
}());
export { LiveSchedulePageVM };
