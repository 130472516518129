import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var CourseThumbnail = function (_a) {
    var course = _a.course, classObject = _a.classObject, nameClassObject = _a.nameClassObject, config = _a.config;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.name, nameClassObject) }, course.nameWithNewLine),
        React.createElement("img", { src: course.thumbnailURL(config), alt: "course image", className: css(styles.image) })));
};
var styles = StyleSheet.create({
    container: {
        background: Colors.PrimaryLighter,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 'auto',
        height: 173,
    },
    name: {
        fontWeight: 'bold',
        fontSize: 18,
        letterSpacing: '0.04em',
        color: Colors.Black,
        width: '100%',
        marginLeft: 15,
        textAlign: 'left',
        whiteSpace: 'pre-wrap',
    },
    image: {
        height: '100%',
    },
});
