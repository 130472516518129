var _a, _b, _c, _d, _e;
import * as React from 'react';
import { useNavigate } from 'react-router';
import { StyleSheet } from 'aphrodite';
import { DailyTasksPageTaskName, routes } from '@lean-body/src/route';
import { TASK_DATE_QUERY } from '@lean-body/src/domain';
import { MediaBreakPointUp, Sizes, drawerWidth } from '../styles';
import { WorkoutTask } from '../organisms/workout_task';
import { StepTask } from '../organisms/step_task';
import { WeightTask } from '../organisms/weight_task';
import { MealTask } from '../organisms/meal_task';
import { getFormattedToFormat, sameDay } from '@lean-body/src/util';
export var DailyTasksPage = function (_a) {
    var taskName = _a.taskName, apiClient = _a.apiClient, profile = _a.profile, setProfile = _a.setProfile, homeDay = _a.homeDay;
    var navigate = useNavigate();
    var onClickBackHome = function (date) {
        var now = new Date();
        if (sameDay(date, now)) {
            navigate(routes.TOP.compile());
        }
        else {
            navigate("".concat(routes.TOP.compile(), "?").concat(TASK_DATE_QUERY, "=").concat(getFormattedToFormat(date)));
        }
    };
    return (React.createElement(React.Fragment, null,
        taskName === DailyTasksPageTaskName.workout && (React.createElement(WorkoutTask, { apiClient: apiClient, containerClassObject: styles.container, headerClassObject: styles.header, arrowIconClassObject: styles.arrowIcon, onClickBackHome: onClickBackHome })),
        taskName === DailyTasksPageTaskName.steps && (React.createElement(StepTask, { apiClient: apiClient, containerClassObject: styles.container, headerClassObject: styles.header, arrowIconClassObject: styles.arrowIcon, onClickBackHome: onClickBackHome })),
        taskName === DailyTasksPageTaskName.weight && (React.createElement(WeightTask, { apiClient: apiClient, profile: profile, setProfile: setProfile, containerClassObject: [styles.container, styles.weightContainer], headerClassObject: styles.header, arrowIconClassObject: styles.arrowIcon, onClickBackHome: onClickBackHome })),
        taskName === DailyTasksPageTaskName.meal && (React.createElement(MealTask, { apiClient: apiClient, containerClassObject: [styles.container, styles.mealContainer], headerClassObject: styles.header, arrowIconClassObject: styles.arrowIcon, homeDay: homeDay, onClickBackHome: onClickBackHome }))));
};
var styles = StyleSheet.create({
    container: (_a = {
            width: '100%',
            padding: '0 24px 60px'
        },
        _a[MediaBreakPointUp.MD] = {
            margin: 'auto',
            width: 480,
            minHeight: '100vh',
        },
        _a),
    weightContainer: (_b = {
            paddingLeft: 0,
            paddingRight: 0
        },
        _b[MediaBreakPointUp.MD] = {
            paddingLeft: 24,
            paddingRight: 24,
        },
        _b),
    mealContainer: (_c = {},
        _c[MediaBreakPointUp.MD] = {
            width: 600,
        },
        _c),
    header: (_d = {
            textAlign: 'center',
            fontSize: 16,
            fontWeight: 700,
            lineHeight: '160%',
            letterSpacing: 1.28,
            padding: '8px 0'
        },
        _d[MediaBreakPointUp.MD] = {
            paddingTop: 22,
        },
        _d[MediaBreakPointUp.XL] = {
            paddingTop: 28,
        },
        _d),
    arrowIcon: (_e = {
            position: 'absolute',
            top: "calc(".concat(Sizes.MobileHeaderHeight, "px + 5px)"),
            left: 16
        },
        _e[MediaBreakPointUp.MD] = {
            top: 19,
            left: "calc(".concat(drawerWidth.md, "px + 16px)"),
        },
        _e[MediaBreakPointUp.XL] = {
            top: 25,
            left: "calc(".concat(drawerWidth.xl, "px + 16px)"),
        },
        _e),
});
