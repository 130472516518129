import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import SearchTitle from './search_title';
import { Colors } from '../../styles';
import { RadioWavesIcon } from '../../atoms/icons/radio_waves_icon';
import { DIFFICULTIES } from '../../pages/main_search';
export var SearchDifficulty = function (_a) {
    var onClickDifficulty = _a.onClickDifficulty;
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(SearchTitle, null, "\u96E3\u6613\u5EA6"),
        React.createElement("div", { className: css(styles.items) }, Object.values(DIFFICULTIES).map(function (v) {
            return (React.createElement("div", { className: css(styles.item), onClick: function () { return onClickDifficulty(v.id); }, key: v.id },
                React.createElement("div", { className: css(styles.itemText) },
                    React.createElement(RadioWavesIcon, { classObject: styles.radioWaves, quantity: v.id, fillColor: Colors.PrimaryDark, emptyColor: Colors.BlueLighterB }),
                    v.name)));
        }))));
};
var styles = StyleSheet.create({
    container: {},
    items: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    item: {
        cursor: 'pointer',
        width: '100%',
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 0',
        ':not(:last-of-type)': {
            marginRight: 8,
        },
    },
    itemText: {
        fontSize: 14,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    radioWaves: {},
});
