import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var DailyTasksProgress = function (_a) {
    var dailyTasks = _a.dailyTasks, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        dailyTasks.completedTasksCount,
        " ",
        React.createElement("span", { className: css(styles.totalCount) },
            "/ ",
            dailyTasks.totalTasksCount)));
};
var styles = StyleSheet.create({
    container: {
        fontSize: 12,
        fontWeight: 700,
        lineHeight: '160%',
        letterSpacing: 0.48,
    },
    totalCount: {
        color: Colors.GrayDarkest,
    },
});
