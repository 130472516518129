import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '../../styles/variables';
export var BellOffIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 26,
            height: 29,
        },
    });
    var iconColor = color || Colors.GrayDarkest;
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 27 30", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_2459_1059)" },
            React.createElement("path", { d: "M3.20634 23.7465C2.98624 23.7302 2.81098 23.7261 2.6398 23.7057C1.45782 23.5467 0.830143 22.2786 1.44967 21.2551C1.8817 20.5415 2.34634 19.8483 2.81506 19.1633C3.7851 17.7402 4.24567 16.1785 4.22529 14.4536C4.21306 13.422 4.16823 12.374 4.32718 11.3628C4.9467 7.41566 7.21693 4.85492 11.0196 3.66426C11.3009 3.57863 11.3946 3.47669 11.3865 3.18718C11.3742 2.81204 11.3742 2.42466 11.4639 2.06583C11.7085 1.04235 12.6622 0.410322 13.7301 0.512262C14.7123 0.60197 15.5153 1.4338 15.5845 2.45321C15.6049 2.75903 15.5845 3.06893 15.609 3.37475C15.6131 3.4563 15.6987 3.58271 15.768 3.59902C17.6102 4.06794 19.1509 5.02618 20.4266 6.43296C20.4388 6.44519 20.4592 6.45335 20.5366 6.49412C20.5896 6.42073 20.6426 6.32286 20.7201 6.24947C22.008 4.95686 23.3041 3.66834 24.588 2.37165C24.9018 2.05768 25.2646 1.91904 25.6966 2.03321C26.4017 2.21671 26.7033 3.05262 26.2958 3.6561C26.2224 3.76212 26.1327 3.85591 26.0431 3.94561C18.1727 11.8154 10.2982 19.6893 2.43193 27.5672C2.10587 27.8934 1.73905 28.0688 1.28256 27.9465C0.479624 27.7303 0.239152 26.7599 0.838295 26.1401C1.53933 25.4224 2.2526 24.7211 2.96179 24.0116C3.03515 23.9382 3.10444 23.8566 3.21041 23.7506L3.20634 23.7465Z", fill: iconColor }),
            React.createElement("path", { d: "M22.5908 10.5798C22.6438 11.2771 22.7253 12.0111 22.7579 12.7491C22.8028 13.74 22.762 14.7349 22.8394 15.7217C22.9291 16.8349 23.3408 17.8584 23.9399 18.8003C24.4412 19.5873 24.967 20.358 25.4683 21.1409C26.0878 22.1073 25.6884 23.3061 24.6369 23.6364C24.4494 23.6934 24.2456 23.722 24.0499 23.722C19.2079 23.7261 14.3699 23.722 9.52787 23.722C9.47896 23.722 9.43005 23.7179 9.4545 23.722C13.8034 19.3712 18.1849 14.9877 22.5867 10.5798H22.5908Z", fill: iconColor }),
            React.createElement("path", { d: "M9.79285 25.6467H17.1864C17.1864 27.0208 16.6565 28.1177 15.5193 28.8558C14.1906 29.7161 12.7967 29.7202 11.4639 28.8558C10.3268 28.1177 9.79285 27.0249 9.79285 25.6467Z", fill: iconColor })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_2459_1059" },
                React.createElement("rect", { width: "25.9792", height: "29", fill: "white", transform: "translate(0.5 0.5)" })))));
};
