var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { FormError } from '../../errors';
import * as msg from '@lean-body/src/messages';
import { ButtonType } from '@lean-body/components/atoms';
import { StripeCardForm } from './stripe_card_form';
import { Colors } from '@lean-body/components/styles';
import { ArrowDirection, ArrowIcon } from '../atoms/icons/arrow_icon';
import { CCFormButton } from './cc_form_button';
import { CCFormAmazonPay } from './cc_form_amazon_pay';
import { useRefHooks } from '@lean-body/src/util';
export var CCFormSections = {
    amazonPay: 'amazonPay',
    stripe: 'stripe',
};
export var CCFormSubmitType = {
    firstPlan: 'firstPlan',
    secondaryPlan: 'secondaryPlan',
};
export var CCForm = function (_a) {
    var submitLabel = _a.submitLabel, alertPresenter = _a.alertPresenter, onSubmit = _a.onSubmit, config = _a.config, children = _a.children, basePlan = _a.basePlan, secondaryBasePlan = _a.secondaryBasePlan, onSubmitSecondaryPlan = _a.onSubmitSecondaryPlan, disableCCForm = _a.disableCCForm, campaign = _a.campaign, disableSecondaryPlan = _a.disableSecondaryPlan, onSubmitAmazonPay = _a.onSubmitAmazonPay, signedInAmazonPay = _a.signedInAmazonPay;
    var _b = React.useState(signedInAmazonPay ? CCFormSections.amazonPay : CCFormSections.stripe), section = _b[0], setSection = _b[1];
    var selectedAmazonPaySection = section === CCFormSections.amazonPay;
    var hiddenInput = useRefHooks();
    var _c = React.useState(false), filledAllForm = _c[0], setFilledAllForm = _c[1];
    var _d = React.useState(false), loading = _d[0], setLoading = _d[1];
    var _e = React.useState(false), openAnotherPlan = _e[0], setOpenAnotherPlan = _e[1];
    var buttonType = selectedAmazonPaySection || filledAllForm ? ButtonType.primary : ButtonType.transparentPrimary;
    var buttonDisabled;
    if (disableCCForm || loading) {
        buttonDisabled = true;
    }
    else if (selectedAmazonPaySection) {
        buttonDisabled = false;
    }
    else {
        buttonDisabled = !filledAllForm;
    }
    var amazonPayPlan = basePlan === null || basePlan === void 0 ? void 0 : basePlan.amazonPayPlan;
    var secondaryPlan = secondaryBasePlan === null || secondaryBasePlan === void 0 ? void 0 : secondaryBasePlan.plan;
    React.useEffect(function () {
        if (signedInAmazonPay && amazonPayPlan) {
            setSection(CCFormSections.amazonPay);
        }
        else {
            setSection(CCFormSections.stripe);
        }
    }, [basePlan]);
    var beforeSubmit = function (isLoading) {
        setLoading(isLoading);
        return true;
    };
    var showErrorFromStripeInput = function (errors) {
        if (errors.length > 0) {
            var err = new FormError(void 0, errors.join('\n'));
            alertPresenter.showError(err, msg.INVALID_INPUT);
            setLoading(false);
            return;
        }
    };
    var afterSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setLoading(false);
            return [2 /*return*/];
        });
    }); };
    var onClickBasePlan = function (e) {
        // Stripeの送信は StripeCardForm で handleSubmit()を実行している
        // どちらのプランを選択したか StripeCardForm で判定できるように、value をセットしている
        hiddenInput.element.value = CCFormSubmitType.firstPlan;
        // AmazonPayでログインしているユーザーで、AmazonPayの支払いを実行した際に、
        // stripe の form submit が実行されないように preventDefault()
        if (signedInAmazonPay && selectedAmazonPaySection) {
            setLoading(true);
            e.preventDefault();
            onSubmitAmazonPay(basePlan.amazonPayPlan.id);
        }
    };
    var onClickSecondaryPlan = function (e) {
        // Stripeの送信は StripeCardForm で handleSubmit()を実行している
        // どちらのプランを選択したか StripeCardForm で判定できるように、value をセットしている
        hiddenInput.element.value = CCFormSubmitType.secondaryPlan;
        // AmazonPayでログインしているユーザーで、AmazonPayの支払いを実行した際に、
        // stripe の form submit が実行されないように preventDefault()
        if (signedInAmazonPay && selectedAmazonPaySection) {
            setLoading(true);
            e.preventDefault();
            onSubmitAmazonPay(secondaryBasePlan.amazonPayPlan.id);
        }
        // "他のプランを表示する"が閉じないように実行
        e.stopPropagation();
    };
    return (React.createElement(React.Fragment, null,
        amazonPayPlan && signedInAmazonPay && (React.createElement(CCFormAmazonPay, { classObject: styles.amazonPayFormAbobe, openSection: selectedAmazonPaySection, onClickSection: function () { return setSection(CCFormSections.amazonPay); } })),
        React.createElement(StripeCardForm, { stripeAPIKey: config.stripeAPIKey, showErrorFromStripeInput: showErrorFromStripeInput, afterSubmit: afterSubmit, beforeSubmit: beforeSubmit, changeFilledAllForm: setFilledAllForm, openSection: section === CCFormSections.stripe, onClickSection: function () { return setSection(CCFormSections.stripe); }, hideSectionTitle: !!amazonPayPlan, hiddenInputRef: hiddenInput.ref, hiddenInputElement: hiddenInput.element, onSubmitFirstPlan: onSubmit, onSubmitSecondaryPlan: onSubmitSecondaryPlan },
            amazonPayPlan && !signedInAmazonPay && (React.createElement(CCFormAmazonPay, { classObject: styles.amazonPayFormBelow, openSection: selectedAmazonPaySection, onClickSection: function () { return setSection(CCFormSections.amazonPay); } })),
            children,
            React.createElement(CCFormButton, { buttonType: buttonType, disabled: buttonDisabled, loading: loading, submitLabel: submitLabel, basePlan: basePlan, campaign: campaign, config: config, selectedSection: section, signedInAmazonPay: signedInAmazonPay, onClick: onClickBasePlan }),
            !disableSecondaryPlan && (secondaryPlan === null || secondaryPlan === void 0 ? void 0 : secondaryPlan.isStandardPlan()) && (React.createElement("div", { className: css(styles.anotherPlan, openAnotherPlan && styles.anotherPlanOpened), onClick: function () { return setOpenAnotherPlan(!openAnotherPlan); }, "data-test": "openAnotherPlan" },
                React.createElement("div", { className: css(styles.displayAnotherPlan) },
                    "\u4ED6\u306E\u30D7\u30E9\u30F3\u3092\u8868\u793A\u3059\u308B",
                    React.createElement(ArrowIcon, { classObject: styles.arrowIcon, direction: openAnotherPlan ? ArrowDirection.top : ArrowDirection.bottom })),
                React.createElement(CCFormButton, { buttonType: buttonType, disabled: buttonDisabled, loading: loading, submitLabel: submitLabel, basePlan: secondaryBasePlan, onClick: onClickSecondaryPlan, dataTest: "submitSecondaryPlanInCCForm", config: config, selectedSection: section, signedInAmazonPay: signedInAmazonPay }))))));
};
var styles = StyleSheet.create({
    amazonPayFormAbobe: {
        marginBottom: 10,
    },
    amazonPayFormBelow: {
        marginTop: 10,
    },
    anotherPlan: {
        maxHeight: 51,
        transition: 'all .2s',
        overflow: 'hidden',
    },
    anotherPlanOpened: {
        maxHeight: 500,
    },
    displayAnotherPlan: {
        fontSize: 14,
        fontWeight: 'bold',
        color: Colors.PrimaryDark,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '30px 0',
    },
    arrowIcon: {
        marginLeft: 10,
        width: 14,
        height: 8,
    },
});
