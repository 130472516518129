import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { LayoutConsts } from '../constants';
import { Equip } from '@lean-body/components/molecules/equip';
import { Colors } from '@lean-body/components/styles';
export var EquipSection = function (props) {
    var equips = function () {
        if (props.equips.length == 0) {
            return React.createElement("div", { className: css(styles.emptyState) }, "\u30A2\u30A4\u30C6\u30E0\u306F\u5FC5\u8981\u3042\u308A\u307E\u305B\u3093\u3002");
        }
        return props.equips.map(function (e) {
            return React.createElement(Equip, { key: e.id, equip: e, className: css(styles.item) });
        });
    };
    return (React.createElement("div", { className: css(styles.container, props.classObject) },
        props.title && React.createElement("div", { className: css(styles.title) }, props.title),
        React.createElement("div", { className: css(styles.cols) }, equips())));
};
var itemMarginBottom = '1rem';
var styles = StyleSheet.create({
    container: {
        marginBottom: LayoutConsts.SectionSpacing,
    },
    cols: {
        display: 'flex',
        flexFlow: 'row wrap',
    },
    item: {
        marginRight: '1rem',
        marginBottom: itemMarginBottom,
    },
    emptyState: {
        color: Colors.GrayDarkest,
        fontSize: 14,
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        marginTop: 10,
        marginBottom: 14,
    },
});
