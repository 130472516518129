var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c, _d, _e;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp, Sizes } from '@lean-body/components/styles';
import { routes } from '@lean-body/src/route';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonType, ProgressDots } from '@lean-body/components/atoms';
import { PageLoading } from '@lean-body/components/organisms';
import { HIDE_HEADER_QUERY_PARAMS } from '@lean-body/components/pages/main';
var useState = React.useState, useEffect = React.useEffect;
export var MatchingAssessmentSection = function (_a) {
    var tracker = _a.tracker, matchingAssesments = _a.matchingAssesments, questionIndex = _a.questionIndex, startQuestionIndex = _a.startQuestionIndex, setQuestionIndex = _a.setQuestionIndex, selectedChoices = _a.selectedChoices, finish = _a.finish, startFromIntroPage = _a.startFromIntroPage;
    var navigate = useNavigate();
    var currentMatchingAssessment = matchingAssesments[questionIndex];
    var choices = currentMatchingAssessment.matchingAssessmentChoices;
    var _b = useState([]), currentValues = _b[0], setCurrentValues = _b[1];
    var _c = useState(false), nextButtonClickable = _c[0], setNextButtonClickable = _c[1];
    var isChoiceButtonMin = choices.length >= 6; // 選択肢が6つ以上の場合ボタンの表示を小さくする
    useEffect(function () {
        setNextButtonClickable(currentValues.length !== 0);
    }, [currentValues, questionIndex]);
    var onClickChoice = function (choice) {
        tracker.trackClickEvent({ category: 'Select Assessment Choice', label: "choiceID: ".concat(choice.choices) });
        var choices = [];
        if (currentValues.includes(choice)) {
            choices = currentValues.filter(function (v) { return v.id !== choice.id; });
        }
        else {
            // 複数選択できない質問、もしくはクリックした選択肢が unique の場合は choice.id で上書き
            if (!currentMatchingAssessment.isMultiple || choice.selectedUnique) {
                choices = [choice];
            }
            else {
                // クリックした選択肢を追加しつつ unique の選択肢が既に選択されている場合は外す
                var uniqueChoiceIds_1 = currentValues.filter(function (v) { return v.selectedUnique; }).map(function (v) { return v.id; });
                choices = __spreadArray(__spreadArray([], currentValues.filter(function (v) { return !uniqueChoiceIds_1.includes(v.id); }), true), [choice], false);
            }
        }
        setCurrentValues(choices);
    };
    var onClickNext = function () {
        tracker.trackClickEvent({
            category: 'Button Clicks',
            label: "Next Assessment, Current MatchingAssessment: ".concat(currentMatchingAssessment.question),
        });
        selectedChoices[questionIndex] = currentValues;
        tracker.trackClickMatchingAssessmentNext(questionIndex);
        if (questionIndex === Object.keys(matchingAssesments).length - 1) {
            finish(selectedChoices);
        }
        else {
            var nextQuestionIndex = questionIndex + 1;
            setQuestionIndex(nextQuestionIndex);
            if (selectedChoices.length > nextQuestionIndex) {
                setCurrentValues(selectedChoices[nextQuestionIndex]);
            }
            else {
                setCurrentValues([]);
            }
        }
    };
    var onClickBack = function () {
        tracker.trackClickEvent({
            category: 'Button Clicks',
            label: "Back Assessment, Current MatchingAssessment: ".concat(currentMatchingAssessment.question),
        });
        if (questionIndex === startQuestionIndex) {
            if (startFromIntroPage) {
                navigate("".concat(routes.MATCHING_ASSESSMENT_INTRO.compile(), "?").concat(HIDE_HEADER_QUERY_PARAMS, "=true"));
            }
            else {
                navigate(routes.TOP.compile());
            }
            window.scrollTo(0, 0);
        }
        else {
            var prevQuestionIndex = questionIndex - 1;
            setCurrentValues(selectedChoices[prevQuestionIndex]);
            setQuestionIndex(prevQuestionIndex);
        }
    };
    if (!choices.length) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.content) },
            React.createElement(ProgressDots, { length: matchingAssesments.length, currentIndex: questionIndex, classObject: styles.headContainer }),
            React.createElement("div", { className: css(styles.title) }, currentMatchingAssessment.question),
            React.createElement("div", { className: css(styles.description) }, currentMatchingAssessment.description),
            React.createElement("div", { className: css(styles.choiceContainer) }, choices.map(function (choice) { return (React.createElement("div", { className: css(styles.button, isChoiceButtonMin && styles.buttonMin, isChoiceButtonMin && choice.description && styles.largeHeightButton, currentValues.includes(choice) && styles.selectedButton), onClick: function () { return onClickChoice(choice); }, key: choice.id, "data-test": "matchingAssessmentChoice" },
                React.createElement("div", { className: css(styles.choiceName) }, choice.choices),
                choice.description && (React.createElement("div", { className: css(styles.choiceDescription, isChoiceButtonMin && styles.largeMarginTop, currentValues.includes(choice) && styles.selectedButton) }, choice.description)))); }))),
        React.createElement("div", { className: css(styles.footerButtonBox) },
            React.createElement(Button, { className: css(styles.nextButton), onClick: onClickNext, buttonType: nextButtonClickable ? ButtonType.primary : ButtonType.transparentPrimary, disabled: !nextButtonClickable, dataTest: "matchingAssessmentNext" }, "\u6B21\u3078"),
            React.createElement("div", { className: css(styles.backButton), onClick: onClickBack }, "\u3082\u3069\u308B"))));
};
var styles = StyleSheet.create({
    container: {
        position: 'relative',
        height: '100vh',
        maxWidth: 500,
        paddingTop: Sizes.MobileHeaderHeight,
        margin: '0 auto',
    },
    content: (_a = {
            position: 'relative',
            height: 'calc(100vh - 160px)',
            paddingLeft: 36,
            paddingRight: 36
        },
        _a[MediaBreakPointUp.MD] = {
            paddingLeft: 0,
            paddingRight: 0,
        },
        _a),
    headContainer: {
        marginTop: 30,
    },
    questionIndex: (_b = {
            fontSize: 18,
            fontWeight: 'bold',
            marginLeft: 12,
            width: 50
        },
        _b[MediaBreakPointUp.MD] = {
            fontSize: 32,
            marginLeft: 17,
            width: 100,
        },
        _b),
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingTop: 20,
        paddingBottom: 14,
        whiteSpace: 'pre-wrap',
    },
    description: (_c = {
            fontSize: 14,
            fontWeight: 500,
            paddingBottom: 30,
            color: Colors.GrayDarkest,
            whiteSpace: 'pre-wrap'
        },
        _c[MediaBreakPointUp.MD] = {
            paddingBottom: 40,
        },
        _c),
    choiceContainer: (_d = {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            paddingBottom: 180
        },
        _d[MediaBreakPointUp.MD] = {
            maxWidth: 500,
        },
        _d),
    button: {
        height: 60,
        width: '100%',
        padding: 10,
        marginBottom: 8,
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: Colors.White,
        border: "1px ".concat(Colors.Gray, " solid"),
        cursor: 'pointer',
    },
    buttonMin: {
        width: 'calc(50% - 4px)',
    },
    largeHeightButton: {
        height: 100,
    },
    selectedButton: {
        color: Colors.White,
        backgroundColor: Colors.PrimaryDark,
        borderColor: Colors.PrimaryDark,
    },
    choiceName: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '140%',
    },
    choiceDescription: {
        fontSize: 10,
        fontWeight: 400,
        color: Colors.GrayDarkest,
        lineHeight: '140%',
        marginTop: 2,
    },
    largeMarginTop: {
        marginTop: 4,
    },
    footerButtonBox: (_e = {
            position: 'fixed',
            bottom: 0,
            maxWidth: 500,
            width: '100%',
            height: 144,
            padding: '14px 36px 30px',
            backgroundColor: Colors.White
        },
        _e[MediaBreakPointUp.MD] = {
            height: 160,
            padding: '20px 0 40px',
        },
        _e),
    nextButton: {
        width: '100%',
        height: 60,
        fontSize: 20,
        borderRadius: 30,
        marginBottom: 20,
    },
    backButton: {
        color: Colors.Black,
        cursor: 'pointer',
    },
});
