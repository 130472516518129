import * as React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../../lotties/completed.json';
export var CompletedAnimationIcon = function (props) {
    var options = {
        loop: true,
        animationData: animationData,
    };
    return (React.createElement("div", { className: props.className },
        React.createElement(Lottie, { options: options })));
};
