import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var CircleBackslashIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 33,
            height: 33,
        },
    });
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 33 33", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { id: "Vector", d: "M15.6557 30.9993C15.6391 30.9993 15.6225 30.9977 15.6066 30.994C15.5817 30.9887 15.5561 30.9819 15.5304 30.9751C15.4859 30.9638 15.4474 30.9532 15.4172 30.951C13.0374 30.7834 10.8342 30.0883 8.86877 28.8853C4.98244 26.5065 2.58905 23.041 1.75427 18.5852C1.68181 18.1988 1.63426 17.8064 1.58822 17.4268C1.56708 17.2502 1.54595 17.0743 1.5218 16.8985C1.52029 16.8879 1.51953 16.8781 1.51953 16.8683V15.137C1.51953 15.1242 1.52029 15.1114 1.52255 15.0985L1.53991 15.0012C1.55274 14.9295 1.56482 14.8638 1.57463 14.7982C1.60407 14.5944 1.62973 14.3876 1.65464 14.1868C1.70822 13.7552 1.76408 13.3099 1.85842 12.8752C2.64792 9.25187 4.52127 6.29949 7.42639 4.1003C10.1458 2.04299 13.1483 1 16.3524 1C17.4302 1 18.5457 1.11924 19.6703 1.3532C23.2163 2.09356 26.1561 3.95313 28.4076 6.88136C30.8002 9.99223 31.8215 13.5657 31.4426 17.5015C31.1384 20.6622 29.9073 23.4832 27.7827 25.8869C25.4625 28.5118 22.5438 30.1532 19.1073 30.7653C18.7208 30.834 18.3276 30.8823 17.9472 30.9298C17.7706 30.9517 17.594 30.9736 17.4181 30.997C17.4083 30.9985 17.3977 30.9993 17.3879 30.9993H15.6565H15.6557ZM9.11709 25.5458C11.2154 27.1963 13.848 28.1027 16.5486 28.1035C19.7556 28.1035 22.7853 26.8348 25.0791 24.5315C27.2913 22.3097 28.5495 19.392 28.6227 16.3151C28.6899 13.4835 27.7834 10.7507 26.0671 8.60132L9.11709 25.5466V25.5458ZM16.495 3.90182C13.2412 3.90182 10.1866 5.19612 7.89511 7.54625C4.99376 10.5213 4.40352 13.814 4.41937 16.0532C4.43824 18.7837 5.3719 21.4501 6.98561 23.3979L23.9243 6.46326C21.8524 4.81047 19.2213 3.90182 16.495 3.90182Z", fill: "#C5CBD1" })));
};
