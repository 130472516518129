import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var WorkoutStepsIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 18,
            height: 18,
            padding: '1.5px 5px',
        },
    });
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 11 19", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_29463_3474)" },
            React.createElement("path", { d: "M3.07928 7.55823C2.91045 7.70695 2.70845 7.82911 2.57881 8.00916C2.07181 8.7161 2.00096 8.83666 1.50803 9.55528C1.5015 9.56484 1.4819 9.59352 1.45376 9.63442C1.18895 10.02 0.676929 10.1018 0.316149 9.81607L0.305095 9.80757C-0.0315663 9.54094 -0.0999034 9.03584 0.152843 8.67998C0.243792 8.55198 0.314642 8.45213 0.325194 8.43672C0.876413 7.62462 0.891487 7.55186 1.44572 6.74348C1.50602 6.65531 1.57938 6.57724 1.66279 6.51297C2.43108 5.92341 3.22902 5.3737 3.96766 4.74537C5.07513 3.80367 6.57402 4.5701 6.95942 5.74336C7.01068 5.89951 7.06595 6.0546 7.11871 6.21022C7.53627 7.43182 8.42314 8.0814 9.55473 8.41229C9.65422 8.4415 9.75572 8.46381 9.85822 8.4824C10.2803 8.55888 10.5878 8.94661 10.5878 9.39913C10.5878 9.8814 10.1813 10.2511 9.73009 10.1794C8.38797 9.96531 7.23378 9.31149 6.27253 8.14619C6.22178 8.43938 6.17405 8.70123 6.13084 8.96413C5.99818 9.76614 5.87156 10.5692 5.73187 11.3702C5.70926 11.4987 5.74594 11.5624 5.83186 11.6347C6.31675 12.0442 6.79913 12.4568 7.2785 12.8733C7.34281 12.929 7.40462 13.0066 7.43226 13.0873C7.99403 14.7226 8.17944 15.385 8.73669 17.0224C8.74172 17.0373 8.78945 17.1664 8.85126 17.3321C9.01707 17.7793 8.80302 18.2833 8.37692 18.4496C7.9689 18.6089 7.51617 18.3853 7.36995 17.9524L7.36744 17.9445C6.94033 16.6799 6.51473 15.4142 6.0831 14.1512C6.05094 14.0571 5.99316 13.9589 5.9213 13.8957C5.07362 13.15 4.22393 12.4053 3.3677 11.6702C2.82653 11.206 2.64062 10.6138 2.75468 9.90264C2.86975 9.18455 2.99084 8.46806 3.10792 7.7505C3.11697 7.69473 3.11395 7.63631 3.11847 7.57948L3.07928 7.5577V7.55823Z", fill: "#C5CBD1" }),
            React.createElement("path", { d: "M3.06603 12.3804C3.55243 12.8085 4.02225 13.2222 4.51166 13.653C4.18405 14.2542 4.01572 14.9325 3.56951 15.47C2.82936 16.3607 2.5128 16.7845 1.78973 17.6911C1.78973 17.6911 1.78923 17.6922 1.78873 17.6927C1.51639 18.0337 1.032 18.0736 0.713425 17.7809C0.709405 17.7772 0.705385 17.7735 0.701365 17.7697C0.364704 17.4612 0.327019 16.9231 0.616446 16.5651C0.814925 16.3198 1.0124 16.076 1.20987 15.8322C1.60934 15.3393 2.01183 14.8485 2.40728 14.3519C2.46959 14.2738 2.51632 14.1745 2.54647 14.0768C2.70123 13.5807 2.84846 13.082 2.9992 12.5843C3.01729 12.5238 3.03839 12.4648 3.06603 12.3809V12.3804Z", fill: "#C5CBD1" }),
            React.createElement("path", { d: "M6.26749 0.500981C7.15336 0.498857 7.87191 1.25465 7.87442 2.18997C7.87693 3.12211 7.15487 3.888 6.27352 3.888C5.39318 3.888 4.66559 3.1237 4.66358 2.19635C4.66157 1.2674 5.38464 0.503106 6.26749 0.500981Z", fill: "#C5CBD1" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_29463_3474" },
                React.createElement("rect", { width: "10.5882", height: "18", fill: "white", transform: "translate(0 0.500977)" })))));
};
