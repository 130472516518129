var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CourseVolumeHeaderDetail, CourseHeaderThumbnail } from '@lean-body/components/molecules';
import { MediaBreakPointUp, Colors } from '@lean-body/components/styles';
export var CourseVolumeHeader = function (_a) {
    var volume = _a.volume, courseThumbnailSrc = _a.courseThumbnailSrc, classObject = _a.classObject, onClickSetting = _a.onClickSetting, onClickCourse = _a.onClickCourse, children = _a.children;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.content) },
            React.createElement(CourseVolumeHeaderDetail, { volume: volume, onClickSetting: onClickSetting, onClickCourse: onClickCourse }),
            React.createElement(CourseHeaderThumbnail, { courseThumbnailSrc: courseThumbnailSrc, imgSize: 173 }),
            children)));
};
var styles = StyleSheet.create({
    container: (_a = {
            backgroundColor: Colors.PrimaryLighter,
            height: 173,
            paddingLeft: 20
        },
        _a[MediaBreakPointUp.MD] = {
            paddingLeft: 0,
        },
        _a),
    content: (_b = {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            margin: 'auto'
        },
        _b[MediaBreakPointUp.MD] = {
            width: 600,
        },
        _b),
});
