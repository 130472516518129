import * as React from 'react';
import * as dayjs from 'dayjs';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { Select } from '@lean-body/components/atoms';
import { getFormattedToHyphenFormat } from '@lean-body/src/util';
import { RequiredBadge } from '../atoms/required_badge';
var useState = React.useState;
export var DateSelectForm = function (_a) {
    var containerClassObject = _a.containerClassObject, label = _a.label, currentValue = _a.currentValue, required = _a.required, hasValidationError = _a.hasValidationError, validationErrorMessage = _a.validationErrorMessage, setCurrentValue = _a.setCurrentValue;
    var value = dayjs(currentValue);
    var _b = useState(currentValue ? "".concat(value.year()) : ''), year = _b[0], setYear = _b[1];
    var _c = useState(currentValue ? "".concat(value.month() + 1) : ''), month = _c[0], setMonth = _c[1];
    var _d = useState(currentValue ? "".concat(value.date()) : ''), day = _d[0], setDay = _d[1];
    var changeYear = function (event) {
        setYear(event.target.value);
        setDate(event.target.value, month, day);
    };
    var changeMonth = function (event) {
        setMonth(event.target.value);
        setDate(year, event.target.value, day);
    };
    var changeDay = function (event) {
        setDay(event.target.value);
        setDate(year, month, event.target.value);
    };
    var setDate = function (y, m, d) {
        if (y || m || d) {
            setCurrentValue(getFormattedToHyphenFormat(y, m, d));
        }
        else {
            setCurrentValue('');
        }
    };
    var yearsList = function () {
        var yearsList = [{ value: '', name: '　' }];
        var YearLower = 1900;
        for (var year_1 = dayjs().year(); year_1 >= YearLower; year_1--) {
            yearsList.push({ value: "".concat(year_1), name: "".concat(year_1) });
        }
        return yearsList;
    };
    var monthList = function () {
        var monthList = [{ value: '', name: '　' }];
        for (var month_1 = 1; month_1 <= 12; month_1++) {
            monthList.push({ value: "".concat(month_1), name: "".concat(month_1) });
        }
        return monthList;
    };
    var dayList = function () {
        var dayList = [{ value: '', name: '　' }];
        for (var day_1 = 1; day_1 <= 31; day_1++) {
            dayList.push({ value: "".concat(day_1), name: "".concat(day_1) });
        }
        return dayList;
    };
    return (React.createElement("div", { className: css(containerClassObject) },
        label && (React.createElement("div", { className: css(styles.label) },
            label,
            required && React.createElement(RequiredBadge, null))),
        React.createElement("div", { className: css(styles.selectContainer) },
            React.createElement(Select, { selectOptions: yearsList(), defaultValue: "".concat(year), containerClassObject: styles.select, onChange: changeYear, displayIcon: true, placeholder: "\u5E74", placeholderValue: "", hasValidationError: hasValidationError }),
            React.createElement(Select, { selectOptions: monthList(), defaultValue: "".concat(month), containerClassObject: styles.select, onChange: changeMonth, displayIcon: true, placeholder: "\u6708", placeholderValue: "", hasValidationError: hasValidationError }),
            React.createElement(Select, { selectOptions: dayList(), defaultValue: "".concat(day), containerClassObject: styles.select, onChange: changeDay, displayIcon: true, placeholder: "\u65E5", placeholderValue: "", hasValidationError: hasValidationError })),
        validationErrorMessage && React.createElement("div", { className: css(styles.validationErrorMessage) }, validationErrorMessage)));
};
var styles = StyleSheet.create({
    selectContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    select: {
        width: 'calc((100% - 16px) / 3)',
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        fontSize: 14,
        color: Colors.GrayDarkest,
        marginBottom: 8,
    },
    validationErrorMessage: {
        marginTop: 4,
        color: Colors.Alert,
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: 0.48,
    },
});
