var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { VerticalListArrow } from '../atoms/vertical_list_arrow';
import { useCss, useXlMediaQuery } from '@lean-body/src/util';
import { MediaBreakPointUp } from '../styles';
export var ITEM_WIDTH_SP = 152;
export var ITEM_WIDTH_OVER_XL = 310;
var ITEM_MARGIN_RIGHT = 12;
var ITEMS_PADDING_RIGHT_AND_LEFT_SP = 48;
var ITEMS_PADDING_RIGHT_AND_LEFT_MD = 90;
var DEFAULT_SLIDE_PER_GROUP = 1;
export var VerticalListArrows = function (_a) {
    var items = _a.items, itemsPadding = _a.itemsPadding, itemSize = _a.itemSize, itemMarginRight = _a.itemMarginRight, slidePerGroup = _a.slidePerGroup, isPageCount = _a.isPageCount, classObject = _a.classObject, pageCountClassObject = _a.pageCountClassObject;
    useCss('/assets/styles/swiper.css');
    var isXL = useXlMediaQuery();
    var _b = React.useState(false), visible = _b[0], setVisible = _b[1];
    var navigationRight = 'swiper-navigation-right';
    var navigationLeft = 'swiper-navigation-left';
    var itemWidth = itemSize || (isXL ? ITEM_WIDTH_OVER_XL : ITEM_WIDTH_SP);
    var itemWidthWithPadding;
    if (Number.isInteger(itemsPadding)) {
        itemWidthWithPadding = itemWidth + itemsPadding;
    }
    else {
        itemWidthWithPadding = itemWidth + (isXL ? ITEMS_PADDING_RIGHT_AND_LEFT_MD : ITEMS_PADDING_RIGHT_AND_LEFT_SP);
    }
    var swiperModules = [Autoplay];
    if (isXL) {
        swiperModules.push(Navigation);
    }
    if (isPageCount) {
        swiperModules.push(Pagination);
    }
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.swipe), onMouseEnter: function () { return setVisible(true); }, onMouseLeave: function () { return setVisible(false); } },
            React.createElement(Swiper, { modules: swiperModules, spaceBetween: itemMarginRight || ITEM_MARGIN_RIGHT, loop: false, width: itemWidthWithPadding, slidesPerGroup: slidePerGroup || DEFAULT_SLIDE_PER_GROUP, pagination: isPageCount && {
                    el: '.lesson-carousel-page-count',
                    type: 'fraction',
                }, navigation: {
                    prevEl: ".".concat(navigationLeft),
                    nextEl: ".".concat(navigationRight),
                    disabledClass: 'swiper-button-disabled',
                }, className: css(classObject) },
                items.map(function (v, i) { return (React.createElement(SwiperSlide, { className: css(styles.swipeSlide), key: i }, v)); }),
                isXL && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: navigationLeft },
                        React.createElement(VerticalListArrow, { classObject: styles.navigation, visible: visible, direction: "prev" })),
                    React.createElement("div", { className: navigationRight },
                        React.createElement(VerticalListArrow, { classObject: styles.navigation, visible: visible, direction: "next" }))))),
            isPageCount && React.createElement("div", { className: "lesson-carousel-page-count ".concat(css(styles.pageCount, pageCountClassObject)) }))));
};
var styles = StyleSheet.create({
    container: {
        position: 'relative',
    },
    swipe: {
        width: '100%',
    },
    swipeSlide: {
        cursor: 'pointer',
    },
    navigation: {
        zIndex: 1,
    },
    pageCount: (_a = {
            fontSize: 12,
            fontWeight: 'bold',
            marginTop: 12,
            paddingLeft: 20,
            paddingRight: 20
        },
        _a[MediaBreakPointUp.MD] = {
            paddingLeft: 34,
            paddingRight: 34,
        },
        _a),
});
