var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import videojs from 'video.js';
import { css, StyleSheet } from 'aphrodite';
import { ZIndex } from '../constants';
import VjsVideoSideSuper from './components/VjsVideoSideSuper';
import VjsResult from './components/VjsResult';
import VjsVideoController from './components/VjsVideoController';
import VjsFullScreenButton from './components/VjsFullScreenButton';
import VjsAutoPlayButton from './components/VjsAutoPlayButton';
var VJSComponent = videojs.getComponent('Component');
// 動画プレイヤー内の左上のタイトル領域
VJSComponent.registerComponent('VjsVideoSideSuper', VjsVideoSideSuper);
// 動画視聴完了後のUI
VJSComponent.registerComponent('VjsResult', VjsResult);
// 動画の再生状態のオリジナルコントローラー
VJSComponent.registerComponent('VjsVideoController', VjsVideoController);
// 動画プレイヤー内の「全画面」ボタン
VJSComponent.registerComponent('VjsFullScreenButton', VjsFullScreenButton);
// 動画プレイヤー内の「自動再生切り替え」ボタン
VJSComponent.registerComponent('VjsAutoPlayButton', VjsAutoPlayButton);
var VJSCompomnent_Z_INDEX = 0; // 動画プレイヤー内のコンポーネントの z-index. 被らないので全部0で統一する
export var LBPlayer = function (_a) {
    var videoOptions = _a.videoOptions, resultOptions = _a.resultOptions, operationOptions = _a.operationOptions, getVideoRef = _a.getVideoRef, getPlayer = _a.getPlayer;
    var _b = React.useState(null), ref = _b[0], setRef = _b[1];
    var _c = React.useState(null), player = _c[0], setPlayer = _c[1];
    var _d = React.useState(false), renderedResult = _d[0], setRenderedResult = _d[1];
    var _e = React.useState(false), renderedExitFullScreen = _e[0], setRenderedExitFullScreen = _e[1];
    var _f = React.useState(false), renderedFullScreenButton = _f[0], setRenderedFullScreenButton = _f[1];
    var _g = React.useState(false), renderedVideoController = _g[0], setRenderedVideoController = _g[1];
    var _h = React.useState(false), renderedAutoPlayButton = _h[0], setRenderedAutoPlayButton = _h[1];
    React.useEffect(function () {
        if (!ref) {
            return;
        }
        setPlayer(videojs(ref, __assign({}, videoOptions)));
        hookChildren();
        if (getVideoRef) {
            getVideoRef(ref);
        }
        getPlayer(player);
        if (!player) {
            return;
        }
        setEventToComponent();
    }, [ref, player]);
    var hookResult = function () {
        if (!player) {
            return;
        }
        if (!resultOptions) {
            return;
        }
        if (renderedResult) {
            return;
        }
        // 視聴後のセクション
        player.addChild('VjsResult', {
            resultOptions: resultOptions,
            onClickExit: operationOptions.onClickExitInnerFullScreen,
        }, VJSCompomnent_Z_INDEX);
        setRenderedResult(true);
    };
    var hookExitFullscreen = function () {
        if (!operationOptions.showBackButton) {
            return;
        }
        if (!player) {
            return;
        }
        if (renderedExitFullScreen) {
            return;
        }
        // 左上タイトル領域
        player.addChild('VjsVideoSideSuper', {
            onClickExit: operationOptions.onClickExitInnerFullScreen,
        }, VJSCompomnent_Z_INDEX);
        setRenderedExitFullScreen(true);
    };
    var hookFullscreenButton = function () {
        if (!player) {
            return;
        }
        if (renderedFullScreenButton) {
            return;
        }
        player.addChild('VjsFullScreenButton', {}, 0);
        setRenderedFullScreenButton(true);
    };
    var hookVideoController = function () {
        if (!player) {
            return;
        }
        if (renderedVideoController) {
            return;
        }
        player.addChild('VjsVideoController', {
            videoJsOptions: videoOptions,
            player: player,
            isFirstLesson: operationOptions.isFirstLesson,
            isLastLesson: operationOptions.isLastLesson,
            onClickPrev: operationOptions.onClickPrev,
            onClickNext: operationOptions.onClickNext,
            isAlwaysShowStepButtons: operationOptions.isAlwaysShowStepButtons,
        }, 6);
        setRenderedVideoController(true);
    };
    var hookAutoPlayButton = function () {
        if (!operationOptions.showAutoplayToggle) {
            return;
        }
        if (!player) {
            return;
        }
        if (renderedAutoPlayButton) {
            return;
        }
        player.addChild('VjsAutoPlayButton', {
            apiClient: operationOptions.apiClient,
        }, VJSCompomnent_Z_INDEX);
        setRenderedAutoPlayButton(true);
    };
    var hookChildren = function () {
        hookResult();
        hookExitFullscreen();
        hookFullscreenButton();
        hookVideoController();
        hookAutoPlayButton();
    };
    var setEventToComponent = function () {
        var ControlBar = player.getChild('ControlBar');
        // プログレスバーの表示
        ControlBar.on('showProgressBar', function (event) {
            var _a, _b, _c;
            event.target.classList.add('vjs-show-progress-bar');
            (_a = document.getElementsByClassName('vjs-fullscreen-control')[0]) === null || _a === void 0 ? void 0 : _a.classList.add('show-fullscreen-control');
            (_b = document.getElementsByClassName('vjs-video-side-super')[0]) === null || _b === void 0 ? void 0 : _b.classList.add('show-video-side-super');
            (_c = document.getElementsByClassName('vjs-auto-play-button')[0]) === null || _c === void 0 ? void 0 : _c.classList.add('show-auto-play-button');
        });
        // プログレスバーの非表示
        ControlBar.on('hideProgressBar', function (event) {
            var _a, _b, _c;
            event.target.classList.remove('vjs-show-progress-bar');
            (_a = document.getElementsByClassName('vjs-fullscreen-control')[0]) === null || _a === void 0 ? void 0 : _a.classList.remove('show-fullscreen-control');
            (_b = document.getElementsByClassName('vjs-video-side-super')[0]) === null || _b === void 0 ? void 0 : _b.classList.remove('show-video-side-super');
            (_c = document.getElementsByClassName('vjs-auto-play-button')[0]) === null || _c === void 0 ? void 0 : _c.classList.remove('show-auto-play-button');
        });
    };
    return (React.createElement("div", { "data-vjs-player": true },
        React.createElement("video", { ref: function (node) { return setRef(node); }, className: ['video-js', css(styles.container)].join(' ') })));
};
var styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        position: 'relative',
        zIndex: ZIndex.VideoPlayer,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
