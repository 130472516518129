import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MediaBreakPointUp } from '@lean-body/components/styles';
import { Certificate } from '@lean-body/components/atoms/certificate';
export var CertificateList = function (props) {
    var _a;
    var styles = StyleSheet.create({
        container: (_a = {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                gridGap: '20px 1px',
                '::before': {
                    content: "''",
                    display: 'block',
                    width: 150,
                    height: 0,
                    order: 1,
                },
                '::after': {
                    content: "''",
                    display: 'block',
                    width: 150,
                }
            },
            _a[MediaBreakPointUp.MD] = {
                display: 'grid',
                gridGap: '32px 8px',
                gridTemplateColumns: 'repeat(auto-fit, 32%)',
                '::before': {
                    content: 'none',
                },
                '::after': {
                    content: 'none',
                },
            },
            _a[MediaBreakPointUp.LG] = {
                gridGap: '20px 8px',
                gridTemplateColumns: 'repeat(auto-fit, 19%)',
            },
            _a),
    });
    return (React.createElement("div", { className: css(styles.container) }, props.badges.map(function (badge, i) {
        return React.createElement(Certificate, { key: i, badge: badge, onClick: props.onClick });
    })));
};
