import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var ProgressDots = function (_a) {
    var length = _a.length, currentIndex = _a.currentIndex, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) }, Array.from({ length: length }, function (_, i) { return (React.createElement("div", { key: i, className: css(styles.progressDot, i === currentIndex && styles.currentProgressDot) })); })));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0 5px',
    },
    progressDot: {
        width: 5,
        height: 5,
        backgroundColor: Colors.PrimaryDark,
        borderRadius: 10,
        opacity: 0.5,
    },
    currentProgressDot: {
        opacity: 1,
    },
});
