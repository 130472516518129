import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Button, ButtonType } from '@lean-body/components/atoms';
import { getFormattedToSlashFormat } from '@lean-body/src/util';
var souceToken = 'NoPaymentRequiredPlan'; // 決済不要プランではsouceTokenは固定でAPIに送信する
export var RegistrationWithNoPaymentRequiredPlanForm = function (_a) {
    var plan = _a.plan, children = _a.children, onSubmit = _a.onSubmit;
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.title) }, "\u30D7\u30E9\u30F3\u767B\u9332"),
        React.createElement("div", { className: css(styles.annotation) },
            React.createElement("p", null,
                "\u30D7\u30E9\u30F3\u540D: ",
                plan.name),
            React.createElement("p", null,
                "\u6709\u52B9\u671F\u9650\u7D42\u4E86\u65E5: ",
                getFormattedToSlashFormat(plan.periodEndFromNow)),
            React.createElement("p", null, "\u3053\u3061\u3089\u306E\u30D7\u30E9\u30F3\u306F\u7121\u6599\u3067\u6709\u52B9\u671F\u9650\u7D42\u4E86\u65E5\u307E\u3067\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u3059")),
        React.createElement("div", { "data-test": "submitButtonForNonePaymentPlan" },
            React.createElement(Button, { buttonType: ButtonType.primary, fullWidth: true, className: css(styles.button), onClick: function () {
                    onSubmit(souceToken);
                } }, "\u3053\u306E\u30D7\u30E9\u30F3\u3067\u767B\u9332\u3059\u308B")),
        children));
};
var styles = StyleSheet.create({
    container: {},
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    annotation: {
        fontWeight: 500,
        fontSize: 14,
        marginBottom: 30,
    },
    button: {
        fontSize: 16,
    },
});
