import { getNearestFiveMinTime } from '@lean-body/src/util';
import { CourseVolume, Style, Goal, Lesson } from '.';
var Course = /** @class */ (function () {
    function Course(data, config) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nameWithNewLine", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "recommendText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "minTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "maxTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "selected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "goals", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "genres", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "volumes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "currentCourseDayLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "thumbnailSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.name = data.name;
        this.nameWithNewLine = data.nameWithNewLine;
        this.description = data.description;
        this.recommendText = data.recommendText;
        this.thumbnailSrc = data.thumbnailSrc;
        this.minTime = data.minTime;
        this.maxTime = data.maxTime;
        this.selected = data.selected;
        this.goals = data.goals.map(function (v) { return new Goal(v); });
        this.genres = data.styles.map(function (v) { return new Style(v); });
        this.volumes = data.volumes.map(function (v) { return new CourseVolume(v, config); });
        this.lessons = data.sampleLessons ? data.sampleLessons.map(function (v) { return new Lesson(v, config); }) : [];
        this.currentCourseDayLesson = data.currentCourseDayLesson ? new Lesson(data.currentCourseDayLesson, config) : null;
    }
    Object.defineProperty(Course, "getDefaultTime", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (padHours) {
            if (padHours === void 0) { padHours = true; }
            var nearestFiveMin = getNearestFiveMinTime();
            var hour = nearestFiveMin.getHours().toString();
            if (padHours) {
                hour = hour.padStart(2, '0');
            }
            var minute = nearestFiveMin.getMinutes().toString().padStart(2, '0');
            return "".concat(hour, ":").concat(minute);
        }
    });
    Object.defineProperty(Course.prototype, "currentVolume", {
        get: function () {
            return this.volumes.find(function (volume) { return !volume.isCompleted; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Course.prototype, "thumbnailURL", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (config) {
            return config.cdnBaseURL + this.thumbnailSrc;
        }
    });
    Object.defineProperty(Course, "sampleCourse", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (config) {
            return new Course({
                id: 1,
                name: 'ダイエットコース',
                goals: [],
                styles: [],
                volumes: [],
                sampleLessons: [],
            }, config);
        }
    });
    return Course;
}());
export { Course };
