import { Program, Playlist } from './';
import { getCurrentUnixTimeInSeconds } from '@lean-body/src/util';
var UserChallenge = /** @class */ (function () {
    function UserChallenge(userChallengeData, config) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "programId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "program", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playlist", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "challengeStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "start", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "end", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = userChallengeData.id;
        this.userId = userChallengeData.userId;
        this.programId = userChallengeData.programId;
        this.program = new Program(userChallengeData.program, config);
        this.challengeStatus = userChallengeData.challengeStatus;
        this.start = userChallengeData.start;
        this.end = userChallengeData.end;
        if (userChallengeData.playlist) {
            this.playlist = new Playlist(userChallengeData.playlist, config);
        }
    }
    // チャレンジ期間が終了しているかどうかを判定
    Object.defineProperty(UserChallenge.prototype, "isChallengeExpired", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var currentUnixTime = getCurrentUnixTimeInSeconds();
            if (this.end < currentUnixTime) {
                return true;
            }
            return false;
        }
    });
    return UserChallenge;
}());
export { UserChallenge };
