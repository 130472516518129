import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var LiveLessonLabel = function (_a) {
    var labelText = _a.labelText, textColor = _a.textColor, backgroundColor = _a.backgroundColor, classObject = _a.classObject;
    var styles = StyleSheet.create({
        label: {
            display: 'flex',
            alignItems: 'center',
            height: 19,
            padding: '0px 6px 1px 6px',
            flexShrink: 0,
            borderRadius: 4,
            color: textColor,
            background: backgroundColor,
            fontSize: 10,
            fontWeight: 700,
            letterSpacing: 0.3,
        },
    });
    return React.createElement("div", { className: css(styles.label, classObject) }, labelText);
};
