var _a;
import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { MediaBreakPointUp } from '../styles';
import { css, StyleSheet } from 'aphrodite';
import { useNavigate } from 'react-router';
import { useSmMediaQuery } from '@lean-body/src/util/media_query_hooks';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
export var BannerSection = function (props) {
    var banners = props.banners, tracker = props.tracker, autoplayDelay = props.autoplayDelay, classObject = props.classObject;
    var isSM = useSmMediaQuery();
    var navigate = useNavigate();
    var clickSwiperBanner = function (banner) {
        if (banner.linkUrl) {
            window.open(banner.linkUrl);
        }
        else {
            navigate(banner.makeLinkPath);
        }
        tracker.trackClickEvent({ category: 'Banner Clicks', label: "BannerID: ".concat(banner.id) });
        tracker.trackHomeBannerClick(banner);
    };
    var loopable = banners.length >= 4;
    var swiperModules = [Autoplay];
    if (isSM) {
        loopable = banners.length >= 3;
    }
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(Swiper, { modules: swiperModules, className: css(styles.swiper), autoplay: { delay: autoplayDelay }, spaceBetween: 12, loop: loopable, pagination: { clickable: true }, slidesPerView: 'auto' }, banners.map(function (banner, index) {
            return (React.createElement(SwiperSlide, { key: index, className: css(styles.swiperSlide), onClick: function () { return clickSwiperBanner(banner); } },
                React.createElement("div", { className: css(styles.banner) },
                    React.createElement("img", { className: css(styles.img), src: banner.pcImageSrc }))));
        }))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
    },
    swiper: (_a = {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: '10px 20px 12px'
        },
        _a[MediaBreakPointUp.MD] = {
            padding: '16px 24px 0',
        },
        _a[MediaBreakPointUp.XL] = {
            padding: '16px 32px 0',
        },
        _a),
    swiperSlide: {
        maxWidth: 409,
    },
    banner: {
        display: 'block',
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
    },
    img: {
        width: '100%',
        borderRadius: 4,
        display: 'block',
    },
});
