var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c, _d, _e;
import { css, StyleSheet } from 'aphrodite';
import bind from 'bind-decorator';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import * as msg from '@lean-body/src/messages';
import { routes } from '@lean-body/src/route';
import { FacebookButton, Button, LineButton, AppleButton, ButtonType, Input, Logo } from '@lean-body/components/atoms';
import { FooterComponent } from '@lean-body/components/organisms/footer';
import { TermsOfServiceMode } from '@lean-body/components/atoms';
import { LocalStorage } from '@lean-body/src/infra';
import { LineAuthType } from '@lean-body/src/infra/line_client';
import { NoticeSection } from '../atoms/notice_section';
import { isAndroidApp, useSmMediaQuery } from '@lean-body/src/util';
import { FORM_PASSWORD_PLACEHOLDER } from '@lean-body/components/molecules/input_form_group/password_vm';
import { WebViewCallbackHandlers } from '@lean-body/src/interfaces';
var PlatFormName = {
    facebook: 'Facebook',
    line: 'LINE',
    apple: 'Apple',
};
export var SignInPage = observer(function (_a) {
    var vm = _a.vm;
    var isSM = useSmMediaQuery();
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(Logo, { forHeader: isSM, classObject: styles.header }),
        React.createElement("div", { className: css(styles.contentWithFooter) },
            React.createElement("div", { className: css(styles.content) },
                React.createElement("div", { className: css(styles.title) }, "\u30ED\u30B0\u30A4\u30F3"),
                vm.loggedInPlatform && (React.createElement(React.Fragment, null,
                    React.createElement(NoticeSection, { classObject: styles.loggedInSection },
                        "\u524D\u56DE\u306F",
                        React.createElement("span", { className: css(styles.loggedInPlatform) }, vm.loggedInPlatform),
                        "\u3067\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u3044\u307E\u3059"),
                    !isAndroidApp() && (React.createElement(AppleButton, { onClick: vm.onClickAppleSignIn, classObject: styles.snsButton, text: "Apple\u3067\u30ED\u30B0\u30A4\u30F3" })),
                    React.createElement(LineButton, { onClick: vm.submitLINESignIn, classObject: styles.snsButton }, msg.BTN_SIGNIN_LINE),
                    React.createElement(FacebookButton, { onClick: vm.submitFBSignIn, loading: vm.isFBSignInButtonLoading, disabled: vm.isFBSignInButtonDisabled }, msg.BTN_SIGNIN_FB))),
                React.createElement("form", null,
                    React.createElement(Input, { type: "text", label: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9", value: vm.email, onChange: vm.onEmailChanged, labelClassObject: styles.inputLabel, placeholder: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u5165\u529B" }),
                    React.createElement(Input, { type: "password", label: "\u30D1\u30B9\u30EF\u30FC\u30C9", value: vm.password, onChange: vm.onPasswordChanged, labelClassObject: styles.inputLabel, placeholder: FORM_PASSWORD_PLACEHOLDER, enablePwVisible: true }),
                    React.createElement(Link, { to: routes.PASSWORD_RESET_REQUEST.compile(), className: css(styles.resetPasswordRequest) }, "\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u304A\u5FD8\u308C\u3067\u3059\u304B\uFF1F"),
                    React.createElement(Button, { buttonType: vm.email && vm.password ? ButtonType.primary : ButtonType.transparentPrimary, className: css(styles.button), onClick: vm.submit, loading: vm.isSignInButtonLoading, disabled: vm.isSignInButtonDisabled, dataTest: "submit", fullWidth: true, isRadius: true }, "\u30ED\u30B0\u30A4\u30F3")),
                !vm.loggedInPlatform && (React.createElement(React.Fragment, null,
                    !isAndroidApp() && (React.createElement(AppleButton, { onClick: vm.onClickAppleSignIn, classObject: styles.snsButton, text: "Apple\u3067\u30ED\u30B0\u30A4\u30F3" })),
                    React.createElement(LineButton, { onClick: vm.submitLINESignIn, classObject: styles.snsButton }, msg.BTN_SIGNIN_LINE),
                    React.createElement(FacebookButton, { onClick: vm.submitFBSignIn, loading: vm.isFBSignInButtonLoading, disabled: vm.isFBSignInButtonDisabled }, msg.BTN_SIGNIN_FB))),
                React.createElement("div", { className: css(styles.devider) }, "\u307E\u3060\u30A2\u30AB\u30A6\u30F3\u30C8\u3092\u304A\u6301\u3061\u3067\u306A\u3044\u65B9\u306F\u3053\u3061\u3089"),
                React.createElement(Button, { onClick: vm.onClickSignup, className: css(styles.signupButton), isRadius: true, fullWidth: true }, "\u65B0\u898F\u767B\u9332")),
            React.createElement(FooterComponent, { termsOfServiceMode: vm.termsOfServiceMode }))));
});
var SignInPageStatus;
(function (SignInPageStatus) {
    SignInPageStatus[SignInPageStatus["initial"] = 0] = "initial";
    SignInPageStatus[SignInPageStatus["inValid"] = 1] = "inValid";
    SignInPageStatus[SignInPageStatus["loading"] = 2] = "loading";
    SignInPageStatus[SignInPageStatus["fbLoading"] = 3] = "fbLoading";
    SignInPageStatus[SignInPageStatus["failed"] = 4] = "failed";
    SignInPageStatus[SignInPageStatus["finished"] = 5] = "finished";
})(SignInPageStatus || (SignInPageStatus = {}));
var SignInPageVM = /** @class */ (function () {
    function SignInPageVM(accountService, abTestService, alertPresenter, router, config, tagClient, lineClient, tracker) {
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: SignInPageStatus.initial
        });
        Object.defineProperty(this, "email", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "password", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "termsOfServiceMode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: TermsOfServiceMode.normal
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "abTestServie", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "router", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "localStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lineClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            status: observable,
            email: observable,
            password: observable,
            termsOfServiceMode: observable,
        });
        this.accountService = accountService;
        this.abTestServie = abTestService;
        this.router = router;
        this.config = config;
        this.alertPresenter = alertPresenter;
        this.lineClient = lineClient;
        this.tracker = tracker;
        this.localStorage = new LocalStorage();
        if (this.localStorage.lpParamsHasMegalosCampaignCode) {
            this.termsOfServiceMode = TermsOfServiceMode.megalos;
        }
        tagClient.embedAppleScript();
    }
    Object.defineProperty(SignInPageVM.prototype, "isSignInButtonLoading", {
        get: function () {
            return this.status === SignInPageStatus.loading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SignInPageVM.prototype, "isFBSignInButtonLoading", {
        get: function () {
            return this.status === SignInPageStatus.fbLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SignInPageVM.prototype, "isSignInButtonDisabled", {
        get: function () {
            if (this.isSignInButtonLoading) {
                return true;
            }
            if (this.isFBSignInButtonLoading) {
                return true;
            }
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SignInPageVM.prototype, "isFBSignInButtonDisabled", {
        get: function () {
            if (this.isSignInButtonLoading) {
                return true;
            }
            if (this.isFBSignInButtonLoading) {
                return true;
            }
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SignInPageVM.prototype, "loggedInPlatform", {
        get: function () {
            return PlatFormName[this.localStorage.loadLoggedInPlatform];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SignInPageVM.prototype, "onEmailChanged", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            this.email = event.target.value;
        }
    });
    Object.defineProperty(SignInPageVM.prototype, "onPasswordChanged", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            this.password = event.target.value;
        }
    });
    Object.defineProperty(SignInPageVM.prototype, "onClickSignup", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            event.preventDefault();
            this.router.push(routes.SIGNUP);
        }
    });
    Object.defineProperty(SignInPageVM.prototype, "submit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            var _this = this;
            event.preventDefault();
            this.status = SignInPageStatus.loading;
            this.accountService
                .signIn({
                email: this.email,
                password: this.password,
            }, this.tracker)
                .then(function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.status = SignInPageStatus.finished;
                            this.alertPresenter.showMessage(msg.SIGNIN_SUCCEEDED, 'signin_result');
                            return [4 /*yield*/, this.authorizationAndRedirect()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); })
                .catch(function (err) {
                _this.status = SignInPageStatus.failed;
                _this.alertPresenter.showError(err, msg.SIGNIN_FAILED, 'signin_result');
            });
        }
    });
    Object.defineProperty(SignInPageVM.prototype, "submitFBSignIn", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            return __awaiter(this, void 0, void 0, function () {
                var result, token, err_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            event.preventDefault();
                            this.status = SignInPageStatus.fbLoading;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 7, , 8]);
                            if (!isAndroidApp()) return [3 /*break*/, 4];
                            return [4 /*yield*/, window.flutter_inappwebview.callHandler(WebViewCallbackHandlers.fbLogin)];
                        case 2:
                            result = _a.sent();
                            token = result['access_token']['token'];
                            return [4 /*yield*/, this.accountService.fbSigInWithAndroid(token, this.tracker)];
                        case 3:
                            _a.sent();
                            return [3 /*break*/, 6];
                        case 4: return [4 /*yield*/, this.accountService.fbSignIn(this.tracker)];
                        case 5:
                            _a.sent();
                            _a.label = 6;
                        case 6: return [3 /*break*/, 8];
                        case 7:
                            err_1 = _a.sent();
                            this.status = SignInPageStatus.failed;
                            this.alertPresenter.showError(err_1, msg.FB_SIGNIN_FAILED, 'signin_result');
                            return [3 /*break*/, 8];
                        case 8:
                            this.status = SignInPageStatus.finished;
                            this.alertPresenter.showMessage(msg.FB_SIGNIN_SUCCEEDED, 'signin_result');
                            return [4 /*yield*/, this.authorizationAndRedirect()];
                        case 9:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(SignInPageVM.prototype, "submitLINESignIn", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            event.preventDefault();
            this.lineClient.openLINEAuth(LineAuthType.signIn);
        }
    });
    Object.defineProperty(SignInPageVM.prototype, "authorizationAndRedirect", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.router
                                .authorizationWithForbiddenRedirect()
                                .then(function () {
                                _this.router.push(routes.TOP);
                            })
                                .catch(function (e) {
                                if (!e.isForbiddenError)
                                    throw e;
                            })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(SignInPageVM.prototype, "onClickAppleSignIn", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            return __awaiter(this, void 0, void 0, function () {
                var clientId, redirectUri;
                return __generator(this, function (_a) {
                    event.preventDefault();
                    try {
                        clientId = this.config.signInWithAppleClientId;
                        redirectUri = this.config.signInWithAppleRedirectUri;
                        this.accountService.signInWithApple(clientId, redirectUri);
                    }
                    catch (err) {
                        this.alertPresenter.showError(err, msg.APPLE_SIGNIN_FAILED, 'signin_result');
                    }
                    return [2 /*return*/];
                });
            });
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SignInPageVM.prototype, "onEmailChanged", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SignInPageVM.prototype, "onPasswordChanged", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SignInPageVM.prototype, "onClickSignup", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SignInPageVM.prototype, "submit", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Promise)
    ], SignInPageVM.prototype, "submitFBSignIn", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SignInPageVM.prototype, "submitLINESignIn", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Promise)
    ], SignInPageVM.prototype, "onClickAppleSignIn", null);
    return SignInPageVM;
}());
export { SignInPageVM };
var styles = StyleSheet.create({
    container: (_a = {},
        _a[MediaBreakPointUp.MD] = {
            paddingTop: 80,
        },
        _a),
    header: (_b = {},
        _b[MediaBreakPointUp.MD] = {
            width: 271,
            margin: '0 auto 18px',
        },
        _b),
    contentWithFooter: (_c = {
            padding: '40px 24px 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 'calc(100vh - 50px)'
        },
        _c[MediaBreakPointUp.MD] = {
            padding: 0,
            minHeight: 'calc(100vh - 146px)',
        },
        _c),
    content: (_d = {
            width: '100%'
        },
        _d[MediaBreakPointUp.MD] = {
            borderRadius: 10,
            maxWidth: 375,
            margin: '0 auto 40px',
            padding: '40px 0',
        },
        _d),
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 30,
    },
    facebookButtonWrapper: {
        marginBottom: 24,
    },
    signupButton: (_e = {
            marginBottom: 50
        },
        _e[MediaBreakPointUp.MD] = {
            marginBottom: 0,
        },
        _e),
    snsButton: {
        marginBottom: 14,
    },
    devider: {
        fontSize: 12,
        fontWeight: 500,
        margin: '30px 0 14px',
        textAlign: 'center',
    },
    button: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 14,
    },
    formWrapper: {},
    loggedInSection: {
        marginBottom: 20,
    },
    loggedInPlatform: {
        color: Colors.Accent,
    },
    inputLabel: {
        color: Colors.Black,
        fontWeight: 'bold',
        marginTop: 14,
    },
    resetPasswordRequest: {
        display: 'block',
        fontSize: 12,
        fontWeight: 'bold',
        color: Colors.PrimaryDark,
        margin: '8px 0 30px',
        textDecoration: 'underline',
    },
});
