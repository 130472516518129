var _a;
import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { selectOptions } from '@lean-body/components/organisms';
import { Dialog } from '@lean-body/components/molecules';
import { CloseIcon } from '../atoms/icons/close_icon';
export var SearchSortDialog = function (_a) {
    var currentValue = _a.currentValue, setCurrentValue = _a.setCurrentValue, onClickSubmit = _a.onClickSubmit, onClickCancel = _a.onClickCancel;
    var onClick = function (v) {
        if (currentValue !== v)
            setCurrentValue(v);
    };
    var CLOSE_ICON_SIZE = 15;
    return (React.createElement(Dialog, { onClickCancel: onClickCancel, classObject: styles.dialog, content: React.createElement(React.Fragment, null,
            React.createElement(CloseIcon, { color: Colors.Black, size: CLOSE_ICON_SIZE, containerClassObject: styles.closeIconContainer, onClick: onClickCancel }),
            React.createElement("div", { className: css(styles.title) }, "\u4E26\u3073\u66FF\u3048"),
            React.createElement("div", { className: css(styles.submit), onClick: onClickSubmit }, "\u5B8C\u4E86"),
            React.createElement("div", { className: css(styles.buttonContainer) }, selectOptions.map(function (_a, index) {
                var name = _a.name, value = _a.value;
                return (React.createElement("div", { className: css(styles.radioButton, value === currentValue ? styles.radioButtonOn : styles.radioButtonOff), onClick: function () { return onClick(value); }, key: index }, name));
            }))) }));
};
var styles = StyleSheet.create({
    dialog: (_a = {
            position: 'relative',
            backgroundColor: Colors.White,
            padding: '12px 16px 24px',
            top: '35%',
            left: 'none',
            transform: 'none',
            width: '100%',
            height: '65%',
            margin: 'auto',
            borderRadius: '8px 8px 0 0'
        },
        _a[MediaBreakPointUp.MD] = {
            width: 375,
            height: 228,
            borderRadius: '8px',
        },
        _a),
    closeIconContainer: {
        position: 'absolute',
        top: 15,
        left: 20,
    },
    title: {
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        borderBottom: "1px solid ".concat(Colors.Gray),
        paddingBottom: 10,
        marginBottom: 37,
    },
    submit: {
        position: 'absolute',
        top: 10,
        right: 18,
        cursor: 'pointer',
    },
    buttonContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '16px 4px',
    },
    radioButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
        fontSize: 12,
        height: 30,
        width: 'calc((100% - 4px) / 2)',
        cursor: 'pointer',
    },
    radioButtonOn: {
        fontWeight: 'bold',
        color: Colors.White,
        backgroundColor: Colors.PrimaryDark,
        border: "1px solid ".concat(Colors.PrimaryDark),
    },
    radioButtonOff: {
        fontWeight: 500,
        color: Colors.Black,
        border: "1px solid ".concat(Colors.Gray),
    },
});
