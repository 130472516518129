import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { BadgeList } from '@lean-body/components/molecules/badge_list';
import { BadgeHeader } from '@lean-body/components/atoms/badge_header';
export var BadgeListSection = function (props) {
    var styles = StyleSheet.create({
        container: {
            margin: '10px 0px 30px',
        },
    });
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(BadgeHeader, { badges: props.badges, header: props.header, subHeader: props.subHeader, headerRef: props.headerRef }),
        React.createElement(BadgeList, { badges: props.badges, onClick: props.onClick })));
};
