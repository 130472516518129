var _a, _b, _c;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { useNavigate } from 'react-router';
import { Button, ButtonType } from '../atoms';
import { routes } from '@lean-body/src/route';
import { WebViewCallbackHandlers } from '@lean-body/src/interfaces';
export var GoalMatchingAssessmentEnableNotifications = function (_a) {
    var classObject = _a.classObject;
    var navigate = useNavigate();
    var onClickSkipNotificationPermissionButton = function () {
        navigate(routes.TOP.compile());
    };
    var onClickShowAllowNotificationPopup = function () {
        window.flutter_inappwebview
            .callHandler(WebViewCallbackHandlers.requestDeviceNotificationPermission)
            .then(function (_a) {
            var result = _a.result;
            if (result) {
                navigate(routes.TOP.compile());
            }
        });
    };
    return (React.createElement("div", { className: css(styles.result, classObject) },
        React.createElement("div", { className: css(styles.resultContent) },
            React.createElement("div", { className: css(styles.notificationDescription) },
                "\u6B21\u306E\u753B\u9762\u3067",
                React.createElement("br", null),
                "\u901A\u77E5\u3092\u30AA\u30F3\u306B\u3057\u3066\u4E09\u65E5\u574A\u4E3B\u3092\u514B\u670D\uFF01",
                React.createElement("br", null),
                "\u30EC\u30C3\u30B9\u30F3\u6642\u9593\u306B",
                React.createElement("br", null),
                "\u901A\u77E5\u3092\u53D7\u3051\u53D6\u308A\u307E\u3057\u3087\u3046\u3002"),
            React.createElement("img", { className: css(styles.notificationPopupGuideImg), src: "/assets/images/android_notification_permission_guide.png" })),
        React.createElement("div", { className: css(styles.notificationPermissionButtons) },
            React.createElement(Button, { className: css(styles.notificationSkipButton), onClick: onClickSkipNotificationPermissionButton, buttonType: ButtonType.secondary, isRadius: true }, "\u3042\u3068\u3067"),
            React.createElement(Button, { className: css(styles.notificationPopupButton), onClick: onClickShowAllowNotificationPopup, buttonType: ButtonType.primary, isRadius: true }, "\u901A\u77E5\u3092\u53D7\u3051\u53D6\u308B"))));
};
var styles = StyleSheet.create({
    result: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100%',
        background: "repeating-linear-gradient(-45deg, ".concat(Colors.BackgroundBlue, ", ").concat(Colors.BackgroundBlue, " 3px, ").concat(Colors.CyanLightestB, " 0, ").concat(Colors.CyanLightestB, " 10px)"),
    },
    resultContent: (_a = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 21,
            marginTop: 80
        },
        _a[MediaBreakPointUp.MD] = {
            width: 343,
        },
        _a),
    notificationDescription: {
        textAlign: 'center',
        fontWeight: 700,
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
    notificationPopupGuideImg: (_b = {
            width: '100%',
            marginBottom: 50
        },
        _b[MediaBreakPointUp.SM] = {
            width: 343,
        },
        _b),
    notificationPermissionButtons: (_c = {
            display: 'flex',
            alignItems: 'flex-end',
            gap: 16,
            position: 'fixed',
            width: 'calc(100% - 32px)',
            bottom: 0,
            margin: 16
        },
        _c[MediaBreakPointUp.MD] = {
            width: 343,
        },
        _c),
    notificationSkipButton: {
        width: '33%',
        paddingLeft: 0,
        paddingRight: 0,
    },
    notificationPopupButton: {
        flex: 1,
    },
});
