var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c, _d;
import * as React from 'react';
import { Link } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { CanvasWithCenterImage } from '@lean-body/src/view/atoms/canvas_with_center_image';
import { UserSeasonAggregateRank } from '@lean-body/src/domain/user_season_aggregate';
import { TwitterEllipseButton } from '@lean-body/src/view/molecules';
import { useXlMediaQuery } from '@lean-body/src/util';
export var CenterImageWidthForRank = function (rank, isXL) {
    if (rank.isBronze) {
        return isXL ? 90 : 80;
    }
    else if (rank.isDiamond || rank.isSapphire) {
        return isXL ? 105 : 95;
    }
    return 70;
};
export var CenterImageWidthForBadge = function (badge, isXL) {
    return badge.isChallenge ? 130 : isXL ? 120 : 100;
};
export var BadgeDialog = function (_a) {
    var imgWidth = _a.imgWidth, imgHeight = _a.imgHeight, title = _a.title, description = _a.description, linkTo = _a.linkTo, linkText = _a.linkText, rankOrBadge = _a.rankOrBadge, twitterService = _a.twitterService, onClickTwitterShareIfAuthed = _a.onClickTwitterShareIfAuthed, sharedTiming = _a.sharedTiming;
    var isXL = useXlMediaQuery();
    var canvas = React.useRef();
    var rank;
    var badge;
    if (rankOrBadge instanceof UserSeasonAggregateRank) {
        rank = rankOrBadge;
    }
    else {
        badge = rankOrBadge;
    }
    var imgSrc = rank ? rank.thumbnailSrc : badge.completeImageURL;
    var centerImageWidth = rank ? CenterImageWidthForRank(rank, isXL) : CenterImageWidthForBadge(badge, isXL);
    var defaultTweetText;
    if (rank) {
        defaultTweetText = "LEAN BODY\u3067\u30E9\u30F3\u30AF\u304C".concat(rank.nameJp, "\u306B\u30A2\u30C3\u30D7\u3057\u307E\u3057\u305F\uFF01 #\u30EA\u30FC\u30F3\u30DC\u30C7\u30A3 #LEANBODY");
    }
    else if (badge.isChallenge) {
        defaultTweetText = "LEAN BODY\u3067\u300E".concat(badge.name, "\u300F\u3092\u5168\u3066\u30AF\u30EA\u30A2\u3057\u307E\u3057\u305F\uFF01 #\u30EA\u30FC\u30F3\u30DC\u30C7\u30A3 #LEANBODY");
    }
    else {
        defaultTweetText = "LEAN BODY\u3067\u300E".concat(badge.name, "\u300F\u3092\u9054\u6210\u3057\u3066\u30D0\u30C3\u30B8\u3092GET\u3057\u307E\u3057\u305F\uFF01 #\u30EA\u30FC\u30F3\u30DC\u30C7\u30A3 #LEANBODY");
    }
    var onClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, twitterService.onClickShareForBadgeOrRank(imgSrc, centerImageWidth, defaultTweetText, sharedTiming, rank, badge)];
                case 1:
                    _a.sent();
                    if (twitterService.isAuthorized)
                        onClickTwitterShareIfAuthed();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(CanvasWithCenterImage, { ref: canvas, imgSrc: imgSrc, width: imgWidth, height: imgHeight, centerImageWidth: centerImageWidth, classObject: styles.canvas }),
        React.createElement("div", { className: css(styles.itemsColumn) },
            React.createElement("div", { className: css(styles.textContainer) },
                React.createElement("div", { className: css(styles.title) }, title),
                description && React.createElement("div", { className: css(styles.description) }, description)),
            React.createElement(TwitterEllipseButton, { classObject: styles.shareButton, text: 'ポストする', onClick: onClick }),
            linkText && linkTo && (React.createElement(Link, { className: css(styles.link), to: linkTo }, linkText)))));
};
var styles = StyleSheet.create({
    container: {
        textAlign: 'center',
        display: 'block',
        borderRadius: 8,
        overflow: 'hidden',
    },
    itemsColumn: {
        padding: '16px 18px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
    },
    textContainer: {
        marginBottom: 16,
    },
    title: (_a = {
            whiteSpace: 'pre-wrap',
            fontSize: 20,
            fontWeight: 'bold'
        },
        _a[MediaBreakPointUp.XL] = {
            fontSize: 24,
        },
        _a),
    description: (_b = {
            fontSize: 14,
            fontWeight: 400,
            marginTop: 8,
            whiteSpace: 'pre-wrap'
        },
        _b[MediaBreakPointUp.XL] = {
            fontSize: 16,
        },
        _b),
    descriptionBold: {
        fontWeight: 'bold',
    },
    link: (_c = {
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
            color: Colors.PrimaryDarkB,
            marginTop: 10,
            padding: '16px 0',
            display: 'block'
        },
        _c[MediaBreakPointUp.XL] = {
            fontSize: 20,
            padding: '11px 0',
        },
        _c),
    canvas: {
        borderRadius: '8px 8px 0 0',
    },
    shareButton: (_d = {
            width: '100%'
        },
        _d[MediaBreakPointUp.XL] = {
            width: 320,
        },
        _d),
});
