import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var CourseWeekdaysScheduleEditor = function (_a) {
    var courseSchedules = _a.courseSchedules, classObject = _a.classObject, onClickDay = _a.onClickDay;
    var classNameForDay = function (day) {
        return css(styles.dayOfWeek, day.isSelected ? styles.dayOfWeekActive : styles.dayOfWeekInactive);
    };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.scheduleWarning) }, "\u9078\u629E\u3057\u305F\u66DC\u65E5\u306B\u901A\u77E5\u3057\u307E\u3059\u3002\u90313\u65E5\u4EE5\u4E0A\u306E\u904B\u52D5\u304C\u304A\u3059\u3059\u3081\u3067\u3059\u3002"),
        React.createElement("div", { className: css(styles.dayOfWeekWapper) }, courseSchedules.map(function (day, i) { return (React.createElement("div", { key: i, className: classNameForDay(day), onClick: function () { return onClickDay(day); }, "data-test": "scheduleWeekDay" }, day.dayOfWeekToKanji)); }))));
};
var styles = StyleSheet.create({
    container: {},
    scheduleWarning: {
        fontWeight: 400,
        fontSize: 14,
        textAlign: 'center',
        letterSpacing: '0.04em',
        color: Colors.Accent,
        margin: '0 12px 0 12px',
    },
    dayOfWeekWapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 47,
    },
    dayOfWeek: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        cursor: 'pointer',
    },
    dayOfWeekInactive: {
        border: "1px solid ".concat(Colors.Gray),
        color: Colors.GrayDarkest,
    },
    dayOfWeekActive: {
        backgroundColor: Colors.PrimaryDark,
        color: Colors.White,
    },
});
