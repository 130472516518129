import * as React from 'react';
import { StyleSheet } from 'aphrodite';
import { ITEM_WIDTH_SP, VerticalListArrows } from '@lean-body/components/molecules/vertical_list_arrows';
import { MerchandiseComponent } from '../molecules/merchandise_component';
export var MerchandiseCarousel = function (_a) {
    var merchandises = _a.merchandises, classObject = _a.classObject, itemMarginRight = _a.itemMarginRight, itemsPadding = _a.itemsPadding;
    return (React.createElement(VerticalListArrows, { classObject: classObject, itemsPadding: itemsPadding, itemMarginRight: itemMarginRight, itemSize: ITEM_WIDTH_SP, items: merchandises.map(function (v) { return (React.createElement(MerchandiseComponent, { merchandise: v, descriptionClassObject: styles.description })); }) }));
};
var styles = StyleSheet.create({
    description: {
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
        overflow: 'hidden',
    },
});
