import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var StepsIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 15,
            height: 15,
        },
    });
    var color = props.color || Colors.Text;
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 20", fill: "none" },
        React.createElement("path", { d: "M4.19788 0.787838C7.44217 1.91902 6.52692 6.30769 6.26871 9.05027C6.25081 9.21034 6.17156 9.27437 6.02583 9.31172C5.00831 9.56784 3.9908 9.82929 2.97839 10.0961C2.35459 10.2321 1.61574 6.57714 1.52115 5.94752C1.10698 3.92527 1.75124 0.755824 4.19788 0.787838Z", stroke: color, strokeWidth: "1.3", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M10.5332 18.8227C10.4181 18.7827 10.3005 18.748 10.1881 18.7026C9.33927 18.3772 8.85096 17.4301 9.07083 16.5016C9.22166 15.8533 9.39807 15.2104 9.56169 14.5647C9.60004 14.4153 9.67162 14.3727 9.8199 14.4153C10.8246 14.6981 11.8319 14.9729 12.8392 15.2531C13.0565 15.3144 13.077 15.3544 13.0207 15.5732C12.8597 16.2002 12.7114 16.8298 12.535 17.4514C12.3305 18.1797 11.8498 18.6119 11.1468 18.7827C11.1212 18.788 11.0982 18.8067 11.0752 18.82H10.5357L10.5332 18.8227Z", stroke: color, strokeWidth: "1.3", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M9.70965 8.3542C9.69687 7.99136 9.75056 7.28704 9.96276 6.6014C10.1724 5.92109 10.4843 5.30481 11.0033 4.82192C11.9058 3.98154 12.8977 4.09092 13.7337 4.89129C14.3601 5.49156 14.6899 6.25457 14.8075 7.12163C14.9839 8.42356 14.7972 9.69614 14.447 10.942C14.2143 11.7718 13.8999 12.5748 13.6289 13.3912C13.5701 13.5699 13.4678 13.6019 13.3042 13.5566C12.2892 13.2871 11.2743 13.0203 10.2568 12.7562C10.1136 12.7189 10.042 12.6548 10.0216 12.4894C9.85282 11.2328 9.73778 9.9736 9.71221 8.3542H9.70965Z", stroke: color, strokeWidth: "1.3", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M7.27578 13.4921C7.26555 14.9194 5.81342 15.8291 4.6195 15.1382C4.16954 14.8767 3.87042 14.4739 3.73748 13.9483C3.58408 13.3427 3.42813 12.7371 3.27474 12.1315C3.21849 11.91 3.2415 11.87 3.4537 11.8087C4.45332 11.5259 5.45294 11.2431 6.45256 10.9629C6.63919 10.9096 6.6801 10.9309 6.72867 11.1257C6.89996 11.798 7.07125 12.4703 7.23743 13.1426C7.26555 13.2546 7.26299 13.3747 7.27578 13.4894V13.4921Z", stroke: color, strokeWidth: "1.3", strokeMiterlimit: "10" })));
};
