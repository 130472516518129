import * as React from 'react';
import { FormControl, Select as MuiSelect, InputBase, MenuItem } from '@mui/material';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { createStyles } from '@mui/material/styles';
import { styled } from '@mui/system';
import { ArrowIcon, ArrowDirection } from '@lean-body/components/atoms/icons/arrow_icon';
import { RequiredBadge } from './required_badge';
var useState = React.useState, useCallback = React.useCallback, useEffect = React.useEffect;
var BootstrapInput = styled(InputBase)(function () {
    return createStyles({
        root: {
            width: '100%',
        },
        input: {
            padding: 14,
            border: "1px solid ".concat(Colors.Gray),
            borderRadius: 4,
            backgroundColor: Colors.White,
            '&:focus': {
                borderRadius: 4,
                borderColor: Colors.Primary,
                boxShadow: "0 0 10px -5px ".concat(Colors.Primary),
                backgroundColor: Colors.White,
            },
        },
    });
});
export var Select = function (_a) {
    var selectOptions = _a.selectOptions, defaultValue = _a.defaultValue, containerClassObject = _a.containerClassObject, selectClassObject = _a.selectClassObject, listClassObject = _a.listClassObject, listItemClassObject = _a.listItemClassObject, onChange = _a.onChange, label = _a.label, displayIcon = _a.displayIcon, unitLabel = _a.unitLabel, placeholder = _a.placeholder, placeholderValue = _a.placeholderValue, required = _a.required, hasValidationError = _a.hasValidationError, validationErrorMessage = _a.validationErrorMessage;
    var _b = useState(defaultValue), value = _b[0], setValue = _b[1];
    var _c = useState(getSetDisplayPlaceholder()), displayPlaceholder = _c[0], setDisplayPlaceholder = _c[1];
    useEffect(function () {
        setValue(defaultValue);
        setDisplayPlaceholder(getSetDisplayPlaceholder());
    }, [defaultValue]);
    var onChangeSelect = useCallback(function (e) {
        var newValue = e.target.value;
        onChange(e, newValue);
        setValue(newValue);
        if (newValue == placeholderValue) {
            setDisplayPlaceholder(true);
        }
        else {
            setDisplayPlaceholder(false);
        }
    }, [onChange]);
    function getSetDisplayPlaceholder() {
        return !defaultValue || defaultValue == placeholderValue;
    }
    var name = "".concat(Math.random());
    return (React.createElement(FormControl, { className: css(styles.container, containerClassObject) },
        label && (React.createElement("div", { className: css(styles.label) },
            label,
            required && React.createElement(RequiredBadge, null))),
        React.createElement(MuiSelect, { IconComponent: displayIcon ? SelectArrowIcon : noneIcon, labelId: "select-label-id", value: value, label: label, required: required, onChange: onChangeSelect, input: React.createElement(BootstrapInput, null), inputProps: { name: name, id: "select-".concat(name) }, MenuProps: {
                classes: {
                    paper: css(listClassObject),
                },
            }, classes: {
                root: displayPlaceholder ? css(styles.root, styles.placeholder) : css(styles.root),
                select: css(styles.select, selectClassObject, hasValidationError && styles.validationError),
            }, displayEmpty: true }, selectOptions.map(function (data, i) {
            if (!!placeholder && data.value == placeholderValue) {
                return (React.createElement(MenuItem, { key: i, value: data.value, className: css(styles.placeholder, listItemClassObject) }, placeholder));
            }
            else {
                return (React.createElement(MenuItem, { key: i, value: data.value, className: css(listItemClassObject) }, data.name));
            }
        })),
        unitLabel && React.createElement("div", { className: css(styles.unitLabel) }, unitLabel),
        validationErrorMessage && React.createElement("div", { className: css(styles.validationErrorMessage) }, validationErrorMessage)));
};
var SelectArrowIcon = function () {
    return React.createElement(ArrowIcon, { classObject: styles.arrowIcon, color: Colors.GrayDarkestB, direction: ArrowDirection.bottom });
};
var noneIcon = function () {
    return React.createElement("div", null);
};
var styles = StyleSheet.create({
    arrowIcon: {
        width: 12,
        position: 'absolute',
        right: 16,
        transition: '0s',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        fontSize: 14,
        color: Colors.GrayDarkest,
        marginBottom: 8,
    },
    root: {
        width: '100%',
    },
    placeholder: {
        color: Colors.GrayDarkest,
    },
    select: {
        padding: 12,
        border: "1px solid ".concat(Colors.Gray),
        ':focus': {
            boxShadow: "0 0 10px -5px ".concat(Colors.Primary),
            borderColor: Colors.Primary,
        },
    },
    unitLabel: {
        color: Colors.GrayDarkest,
        position: 'absolute',
        right: 12,
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
    validationError: {
        ':focus': {
            border: "1px solid ".concat(Colors.Alert),
        },
        border: "1px solid ".concat(Colors.Alert),
    },
    validationErrorMessage: {
        marginTop: 4,
        color: Colors.Alert,
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: 0.48,
    },
});
