import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var MealScoreHighIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 20,
            height: 20,
        },
    });
    var iconColor = color || Colors.BackgroundGrayD;
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 20 21", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M9.37557 0.5C9.79203 0.5 10.209 0.5 10.6255 0.5C10.9532 0.542402 11.2836 0.57067 11.6082 0.629301C14.6648 1.18158 17.0201 2.78396 18.6128 5.45425C19.9661 7.72303 20.3142 10.1761 19.7229 12.7521C18.9359 16.1825 16.936 18.5869 13.6404 19.8485C11.961 20.4913 10.2158 20.6384 8.43618 20.3777C6.94048 20.1584 5.55228 19.6501 4.2982 18.8015C1.92678 17.1965 0.625734 14.9162 0.15813 12.1224C0.0918508 11.724 0.052188 11.3225 0 10.9231C0 10.5834 0 10.2441 0 9.90439C0.0109595 9.85414 0.0281815 9.80388 0.0323565 9.7531C0.164914 8.05492 0.682097 6.48395 1.62461 5.0674C3.22783 2.6578 5.45051 1.17477 8.29841 0.652858C8.65485 0.587423 9.01652 0.550254 9.37557 0.5ZM10.007 16.3637C10.1438 16.3637 10.281 16.3731 10.4162 16.3616C10.591 16.3469 10.7659 16.3239 10.9376 16.2899C12.6765 15.9428 13.9952 15.0021 14.883 13.4615C15.0735 13.1311 15.0317 12.7673 14.7958 12.4956C14.5651 12.2292 14.2228 12.1475 13.8731 12.2632C13.4911 12.3894 13.1096 12.5208 12.7208 12.6244C11.7689 12.8783 10.8008 13.062 9.81395 13.0238C8.55309 12.9752 7.32928 12.7071 6.142 12.2742C5.76729 12.1376 5.42703 12.2245 5.18905 12.5098C4.96099 12.7836 4.92498 13.1479 5.11964 13.4772C6.21976 15.3376 7.86682 16.2773 10.0065 16.3642L10.007 16.3637ZM11.6718 8.45015C11.6755 9.14482 12.2443 9.70808 12.9306 9.69709C13.6127 9.6861 14.168 9.12335 14.1685 8.44283C14.1696 7.75026 13.6075 7.19065 12.9129 7.19222C12.2156 7.19431 11.6677 7.74973 11.6713 8.45068L11.6718 8.45015ZM7.08034 7.1917C6.38729 7.19274 5.8247 7.75863 5.83148 8.44858C5.83827 9.13121 6.39616 9.69134 7.07565 9.69657C7.764 9.70233 8.32711 9.13906 8.32816 8.44335C8.3292 7.74345 7.7781 7.19065 7.07982 7.1917H7.08034Z", fill: iconColor })));
};
