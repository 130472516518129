var _a, _b, _c;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CourseSelectItem } from '@lean-body/components/molecules';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
export var CourseSelectList = function (_a) {
    var courses = _a.courses, config = _a.config, classObject = _a.classObject, selectedCourseID = _a.selectedCourseID, topCourseRecommendMessage = _a.topCourseRecommendMessage, onClickCourse = _a.onClickCourse, onClickSelect = _a.onClickSelect;
    var handleClickCourse = function (id) {
        onClickCourse(id);
    };
    var handleClickSelect = function (id) {
        onClickSelect(id);
    };
    return (React.createElement("section", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.courses, topCourseRecommendMessage && styles.coursesWithRecommend) }, courses.map(function (course, i) { return (React.createElement(React.Fragment, null,
            topCourseRecommendMessage && i === 0 && (React.createElement("div", { className: css(styles.courseSelectRecommend) },
                "\uFF3C",
                topCourseRecommendMessage,
                "\uFF0F")),
            React.createElement(CourseSelectItem, { key: i, course: course, config: config, isActive: course.id === selectedCourseID, onClickCourse: handleClickCourse, onClickSelect: handleClickSelect, classObject: styles.courseBox, dataTest: "matchingAssessmentChoice" }))); }))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    courses: (_a = {
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: 335,
            gap: 30
        },
        _a[MediaBreakPointUp.MD] = {
            width: 700,
        },
        _a),
    coursesWithRecommend: {
        paddingTop: 30,
    },
    courseBox: (_b = {
            cursor: 'pointer'
        },
        _b[MediaBreakPointUp.MD] = {
            width: 335,
        },
        _b),
    buttonsContainer: {
        width: '100%',
    },
    courseSelectRecommend: (_c = {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            color: Colors.PrimaryDarkB,
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: '140%',
            letterSpacing: 0.56,
            textAlign: 'center'
        },
        _c[MediaBreakPointUp.MD] = {
            width: 'calc(50% - 15px)',
        },
        _c),
});
