import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
var DEFAULT_HEIGHT = 300;
export var ImageBlurSquare = function (_a) {
    var src = _a.src, height = _a.height;
    var styles = StyleSheet.create({
        box: {
            position: 'absolute',
            height: height || DEFAULT_HEIGHT,
            top: 0,
            right: 0,
            left: 0,
            overflow: 'hidden',
        },
        blur: {
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            filter: 'blur(160px)',
            transform: 'scale(1.1)',
            ':before': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                backgroundImage: "url(".concat(src, ")"),
                backgroundPosition: 'center',
                backgroundSize: '100%',
            },
        },
    });
    return (React.createElement("div", { className: css(styles.box) },
        React.createElement("div", { className: css(styles.blur) })));
};
