import * as React from 'react';
import { ITEM_WIDTH_OVER_XL, ITEM_WIDTH_SP, VerticalListArrows, } from '@lean-body/components/molecules/vertical_list_arrows';
import { PlaylistComponentSquare } from '../molecules/playlist_component_square';
import { PlaylistComponentLandscapeImg } from '../molecules';
export var PlaylistComponentCarousel = function (_a) {
    var playlists = _a.playlists, classObject = _a.classObject, itemMarginRight = _a.itemMarginRight, itemsPadding = _a.itemsPadding, addFavoriteProgram = _a.addFavoriteProgram, unFavoriteProgram = _a.unFavoriteProgram, isMin = _a.isMin, isLandscapeImg = _a.isLandscapeImg;
    var addFavorite = function (playlist) {
        playlist.isFavorited = true;
        addFavoriteProgram(playlist.id);
    };
    var unFavorite = function (playlist) {
        playlist.isFavorited = false;
        unFavoriteProgram(playlist.id);
    };
    return (React.createElement(VerticalListArrows, { classObject: classObject, itemsPadding: itemsPadding, itemMarginRight: itemMarginRight, itemSize: isMin ? ITEM_WIDTH_SP : ITEM_WIDTH_OVER_XL, items: playlists.map(function (v) { return (React.createElement(React.Fragment, null, isLandscapeImg ? (React.createElement(PlaylistComponentLandscapeImg, { playlist: v, addFavoriteProgram: function () { return addFavorite(v); }, unFavoriteProgram: function () { return unFavorite(v); } })) : (React.createElement(PlaylistComponentSquare, { playlist: v, addFavoriteProgram: function () { return addFavorite(v); }, unFavoriteProgram: function () { return unFavorite(v); }, isMin: isMin })))); }) }));
};
