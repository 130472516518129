var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { useState } from 'react';
import { Button, ButtonType } from '@lean-body/components/atoms/button';
export var RemoveQueuedPlanModal = function (props) {
    var useModal = function () {
        var _a = useState(false), isShowing = _a[0], setIsShowing = _a[1];
        function toggle() {
            setIsShowing(!isShowing);
        }
        return { isShowing: isShowing, toggle: toggle };
    };
    var _a = useModal(), isShowing = _a.isShowing, toggle = _a.toggle;
    var handleOnClick = function (event) {
        props.onClick(event);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, __assign({}, props, { className: props.className, buttonType: ButtonType.whiteWithBlack, onClick: toggle }), "\u30D7\u30E9\u30F3\u5909\u66F4\u3092\u53D6\u308A\u6D88\u3059"),
        React.createElement(Modal, { isShowing: isShowing, hide: toggle, onClick: handleOnClick })));
};
var Modal = function (props) {
    var styles = StyleSheet.create({
        overlay: {
            zIndex: 1040,
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            top: '0',
            left: '0',
            opacity: 0.7,
            background: '#000000',
        },
        wrapper: {
            zIndex: 1050,
            position: 'fixed',
            top: '25%',
            left: '0',
            width: '100%',
            height: '100%',
            overflowX: 'hidden',
            overflowY: 'auto',
            outline: '0',
        },
        modal: {
            zIndex: 100,
            background: '#ffffff',
            position: 'relative',
            margin: '1.75rem auto',
            borderRadius: '3px',
            width: '90%',
            maxWidth: '500px',
            padding: '2rem',
        },
        header: {
            display: 'flex',
            justifyContent: 'center',
        },
        closeButton: {
            position: 'absolute',
            right: '1.4rem',
            fontSize: '1.4rem',
            fontWeight: 700,
            lineHeight: 1,
            color: '#000000',
            background: '#ffffff',
            opacity: 0.3,
            cursor: 'pointer',
            border: 'none',
        },
        buttonWrapper: {
            marginTop: '2.5rem',
            display: 'flex',
            justifyContent: 'center',
        },
        button: {
            background: 'blue',
        },
    });
    var handleOnClick = function (event) {
        props.onClick && props.onClick(event);
        props.hide(event);
    };
    return props.isShowing ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.overlay) }),
        React.createElement("div", { className: css(styles.wrapper), "aria-modal": true, "aria-hidden": true, tabIndex: -1, role: "dialog" },
            React.createElement("div", { className: css(styles.modal) },
                React.createElement("div", { className: css(styles.header) },
                    React.createElement("span", null, "\u30D7\u30E9\u30F3\u5909\u66F4\u3092\u53D6\u308A\u6D88\u3057\u307E\u3059\u304B\uFF1F"),
                    React.createElement("button", { type: "button", className: css(styles.closeButton), "data-dismiss": "modal", "aria-label": "Close", onClick: props.hide },
                        React.createElement("span", { "aria-hidden": "true" }, "\u00D7"))),
                React.createElement("div", { className: css(styles.buttonWrapper) },
                    React.createElement(Button, { buttonType: ButtonType.primary, onClick: handleOnClick }, "OK")))))) : null;
};
