var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { LoadingIndicator } from '@lean-body/components/atoms';
import { LessonComponentCarousel } from './lesson_component_carousel';
import { CourseThumbnail, CourseDescription, CourseFeatures, CourseGoal, CourseGenre, } from '@lean-body/components/molecules';
export var CourseDetail = function (_a) {
    var course = _a.course, classObject = _a.classObject, apiClient = _a.apiClient, config = _a.config;
    return (React.createElement("section", { className: css(styles.container, classObject) }, !course ? (React.createElement(LoadingIndicator, { size: 50 })) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.header) },
            React.createElement(CourseThumbnail, { course: course, config: config, classObject: styles.thumbnail, nameClassObject: styles.thumbnailName })),
        React.createElement(CourseDescription, { course: course, classObject: styles.contentBox }),
        React.createElement("div", { className: css(styles.border) }),
        React.createElement(CourseFeatures, { label: "\u52B9\u679C", classObject: styles.contentBox },
            React.createElement("div", { className: css(styles.goals) }, course.goals.map(function (goal, i) { return (React.createElement(CourseGoal, { key: i, goal: goal, config: config })); }))),
        React.createElement("div", { className: css(styles.border) }),
        React.createElement(CourseFeatures, { label: "\u4E3B\u306A\u30B8\u30E3\u30F3\u30EB", classObject: styles.contentBox }, course.genres.map(function (genre, i) { return (React.createElement(CourseGenre, { key: i, genre: genre, config: config, classObject: styles.genre })); })),
        React.createElement("div", { className: css(styles.border) }),
        React.createElement(CourseFeatures, { label: "1\u65E5\u306E\u76EE\u5B89\u904B\u52D5\u6642\u9593", classObject: styles.contentBox },
            course.minTime == course.maxTime ? (React.createElement("div", { className: css(styles.time) },
                course.minTime,
                "\u5206")) : (React.createElement("div", { className: css(styles.time) },
                course.minTime,
                "-",
                course.maxTime,
                "\u5206")),
            React.createElement("div", { className: css(styles.timeDescription) }, "\u203B5\u5206\u307B\u3069\u524D\u5F8C\u3059\u308B\u65E5\u3082\u3042\u308A\u307E\u3059\u3002")),
        React.createElement("div", { className: css(styles.border) }),
        React.createElement(CourseFeatures, { label: "\u3053\u3093\u306A\u30EC\u30C3\u30B9\u30F3\u304C\u542B\u307E\u308C\u307E\u3059", classObject: styles.contentBox },
            React.createElement("div", { className: css(styles.lessons) },
                React.createElement(LessonComponentCarousel, { lessons: course.lessons, addFavoriteLesson: apiClient.addFavoriteLesson, unFavoriteLesson: apiClient.unFavoriteLesson, isMin: true, disableHistoryPush: true })))))));
};
var styles = StyleSheet.create({
    container: (_a = {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        },
        _a[MediaBreakPointUp.MD] = {
            paddingTop: 0,
        },
        _a),
    header: (_b = {
            width: '100%',
            margin: '0 auto',
            backgroundColor: Colors.PrimaryLighter
        },
        _b[MediaBreakPointUp.MD] = {
            width: 375,
        },
        _b),
    thumbnail: {
        width: 375,
        paddingLeft: 20,
    },
    thumbnailName: {
        fontSize: 22,
        margin: 'auto',
    },
    contentBox: {
        width: 335,
        margin: '22px auto',
    },
    border: {
        background: Colors.BorderLightGray,
        height: 1,
        width: 343,
        margin: '0 auto',
    },
    goals: {
        display: 'flex',
        gap: 10,
        justifyContent: 'center',
    },
    genre: {
        marginBottom: 10,
    },
    time: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: '0.56px',
        marginBottom: 15,
    },
    timeDescription: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: '0.48px',
    },
    lessons: {
        position: 'relative',
        zIndex: 0, // LessonComponentCarouselのコンポーネント内でz-index:1が使われており、このコンポーネントで使われているposition: fixedのボタンの上に表示されてしまうのでこうしています。
    },
});
