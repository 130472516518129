import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import Lottie from 'react-lottie';
import * as animationData from '../../../lotties/crown.json';
export var CourseCompleteCrownIcon = function (_a) {
    var animate = _a.animate, loopAnimation = _a.loopAnimation, classObject = _a.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 65,
            height: 65,
        },
    });
    var animationOptions = {
        loop: loopAnimation,
        animationData: animationData,
    };
    return (React.createElement("div", { className: css(styles.icon, classObject) }, animate ? (React.createElement(Lottie, { options: animationOptions })) : (React.createElement("svg", { fill: "none", viewBox: "0 0 65 65", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" },
        React.createElement("clipPath", { id: "a" },
            React.createElement("path", { d: "m9.5 18h46v31h-46z" })),
        React.createElement("circle", { cx: "32.5", cy: "33", r: "30.75", stroke: "#f0cd66", strokeWidth: "3.5" }),
        React.createElement("g", { clipPath: "url(#a)" },
            React.createElement("path", { d: "m32.6155 47.0191c-.5122 0-1.0181.0252-1.5303 0-1.6238-.1073-3.2477-.2145-4.8716-.3659-2.0049-.1893-3.9723-.6057-5.8773-1.268-.9556-.3344-1.8925-.7192-2.6794-1.4068-.7245-.6309-.5247-1.2807.1749-1.7664 1.1492-.7949 2.4296-1.3059 3.7786-1.6276 1.4366-.347 2.8856-.6435 4.3408-.9021.7995-.1388 1.6177-.1641 2.4359-.1956 1.83-.0757 3.66-.1893 5.49-.164 1.5489.0252 3.0979.2082 4.6468.3469 2.0674.183 4.0973.5804 6.0522 1.287.8619.3091 1.6926.7444 2.4795 1.2175 1.0244.6183.9931 1.3564.0063 2.0377-1.2804.8832-2.7232 1.3878-4.2159 1.7033-1.6052.3406-3.229.6308-4.8592.8138-1.78.2018-3.5788.2649-5.3713.3911 0-.0315 0-.0631-.0063-.1009z", fill: "#f2be5a" }),
            React.createElement("path", { d: "m32.9912 49.0001c-4.4095-.0568-8.3131-.347-12.1168-1.4383-1.6738-.4795-3.3165-1.0725-4.6156-2.3153-.8682-.8264-1.2866-1.8105-.8182-3.0091.2811-.7255.8432-1.2428 1.4615-1.6781 1.5302-1.0724 3.2666-1.678 5.0591-2.0692 1.4053-.3091 2.8231-.5362 4.2471-.7318 1.0805-.1514 2.1798-.2334 3.2728-.2712 1.8487-.0631 3.71-.164 5.5525-.0757 1.8799.0946 3.7537.3785 5.6274.6119 1.6301.2019 3.2228.6056 4.7717 1.1671 1.193.4353 2.3547.9526 3.2916 1.8231 1.3116 1.2239 1.5364 2.7821.0562 4.2267-.9806.9589-2.2048 1.5014-3.4664 1.943-2.8231.9842-5.7524 1.4131-8.7129 1.6339-1.3553.101-2.7231.1325-3.6038.1767zm-.381-1.9809c0 .0316 0 .0631.0062.101 1.7926-.1262 3.5914-.183 5.3714-.3912 1.6301-.1892 3.254-.4731 4.8592-.8138 1.4927-.3217 2.9355-.8138 4.2159-1.7033.9868-.6876 1.0243-1.4194 0-2.0376-.7995-.4795-1.6489-.9084-2.5233-1.2302-1.9362-.7128-3.9536-1.0913-6.0084-1.2806-1.549-.1388-3.0979-.3217-4.6469-.347-1.83-.0252-3.66.0884-5.49.1641-.8119.0315-1.6364.0567-2.4358.1955-1.4553.2524-2.9043.5489-4.3408.9021-1.3304.3218-2.5983.8138-3.735 1.615-.8619.6056-.9681 1.2365-.1124 1.8421.7807.5551 1.6676.9967 2.567 1.3311 1.8924.7065 3.8661 1.0977 5.871 1.2806 1.6364.1514 3.279.2587 4.9154.3659.4934.0316.9868 0 1.4803 0z", fill: "#fce79b" }),
            React.createElement("path", { d: "m9.50039 25.318c.20611-1.0346.63081-1.9367 1.46771-2.6117 1.2429-1.0031 3.0355-1.104 4.4533-.2019 1.2928.8201 1.9986 2.5171 1.6488 4.0185-.0687.3028-.0312.4794.2561.6561 1.0181.6119 1.9799 1.3122 3.0167 1.8799 2.1735 1.1797 4.5157.694 6.1021-1.2238.9306-1.1229 1.8363-2.2774 2.7669-3.4003.1811-.2208.1561-.3659.025-.593-.7995-1.4068-.762-2.8262.1311-4.1447.9369-1.3816 2.311-1.9241 3.9161-1.5897 1.5989.328 2.5983 1.3878 2.9668 2.9902.2186.9589.0437 1.8862-.4497 2.7505-.075.1325-.1187.4037-.0375.4984.9931 1.2554 1.9549 2.536 3.0292 3.7094 1.6489 1.8105 3.8661 2.1449 5.9959.9462 1.1055-.6245 2.1548-1.3563 3.2478-2.0502-.2311-.8391-.2311-1.6529.0312-2.4666.5122-1.5772 2.1798-2.719 3.8162-2.5992 1.7925.1325 3.2478 1.4258 3.5351 3.1354.3685 2.1701-.8994 4.0627-3.0354 4.5042-.3123.0631-.3998.2145-.4497.4984-.5746 3.5202-1.1555 7.0403-1.7301 10.5542-.1249.7696-.2561 1.5392-.3997 2.4098-.3623-1.5456-1.4116-2.3278-2.6295-2.9839-1.8862-1.0157-3.9223-1.5393-6.0084-1.8673-1.5552-.2461-3.1167-.4795-4.6781-.6246-1.2741-.1135-2.5608-.082-3.8474-.1072-.5246-.0126-1.0493-.0316-1.5739 0-1.6239.1072-3.2541.2082-4.8717.3659-2.2672.2208-4.497.6624-6.6455 1.4509-1.2179.4479-2.4046.9905-3.3477 1.9178-.4122.4038-.8245.858-.8744 1.5077-.0063.0821-.075.1514-.1374.2713-.6121-3.722-1.2242-7.3999-1.83-11.0777-.1062-.6435-.1999-1.287-.3311-1.9178-.0249-.1325-.1811-.3091-.306-.3344-1.7738-.3848-2.82933-1.4698-3.18534-3.2615 0-.0252-.03123-.0504-.04372-.0757 0-.3028 0-.6056 0-.9084z", fill: "#f0cd66" }))))));
};
