import * as React from 'react';
import { useState, useEffect } from 'react';
import * as dayjs from 'dayjs';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { SelectWithoutOutline } from '@lean-body/components/atoms';
import { getFormattedToHyphenFormat } from '@lean-body/src/util';
export var DateSelectFormWithoutOutline = function (_a) {
    var currentValue = _a.currentValue, setCurrentValue = _a.setCurrentValue;
    var value = dayjs(currentValue);
    var _b = useState(''), year = _b[0], setYear = _b[1];
    var _c = useState(''), month = _c[0], setMonth = _c[1];
    var _d = useState(''), day = _d[0], setDay = _d[1];
    useEffect(function () {
        setYear(currentValue ? "".concat(value.year()) : '');
        setMonth(currentValue ? "".concat(value.month() + 1) : '');
        setDay(currentValue ? "".concat(value.date()) : '');
    }, [currentValue]);
    var changeYear = function (event) {
        setYear(event.target.value);
        setDate(event.target.value, month, day);
    };
    var changeMonth = function (event) {
        setMonth(event.target.value);
        setDate(year, event.target.value, day);
    };
    var changeDay = function (event) {
        setDay(event.target.value);
        setDate(year, month, event.target.value);
    };
    var setDate = function (y, m, d) {
        if (y && m && d) {
            setCurrentValue(getFormattedToHyphenFormat(y, m, d));
        }
        else {
            setCurrentValue('');
        }
    };
    var yearsList = function () {
        var yearsList = [{ value: '', name: '　' }];
        var YearLower = 1900;
        for (var year_1 = dayjs().year(); year_1 >= YearLower; year_1--) {
            yearsList.push({ value: "".concat(year_1), name: "".concat(year_1) });
        }
        return yearsList;
    };
    var monthList = function () {
        var monthList = [{ value: '', name: '　' }];
        for (var month_1 = 1; month_1 <= 12; month_1++) {
            monthList.push({ value: "".concat(month_1), name: "".concat(month_1) });
        }
        return monthList;
    };
    var dayList = function () {
        var dayList = [{ value: '', name: '　' }];
        for (var day_1 = 1; day_1 <= 31; day_1++) {
            dayList.push({ value: "".concat(day_1), name: "".concat(day_1) });
        }
        return dayList;
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(SelectWithoutOutline, { selectOptions: yearsList(), value: year, containerClassObject: styles.year, onChange: changeYear }),
        React.createElement("div", { className: css(styles.unitLabel) }, "\u5E74"),
        React.createElement(SelectWithoutOutline, { selectOptions: monthList(), value: month, containerClassObject: styles.month, onChange: changeMonth }),
        React.createElement("div", { className: css(styles.unitLabel) }, "\u6708"),
        React.createElement(SelectWithoutOutline, { selectOptions: dayList(), value: day, containerClassObject: styles.day, onChange: changeDay }),
        React.createElement("div", { className: css(styles.unitLabel) }, "\u65E5")));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    year: {
        width: 90,
    },
    month: {
        width: 45,
    },
    day: {
        width: 45,
    },
    unitLabel: {
        color: Colors.Black,
        fontSize: 32,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: 2.56,
        paddingBottom: 22,
    },
});
