import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { VerticalListArrows } from '@lean-body/components/molecules/vertical_list_arrows';
import { LessonRankingCard } from '@lean-body/components/molecules/lesson_ranking_card';
import { separateArrayBySpecifiedNumber } from '@lean-body/src/util';
import { LIST_ITEM_MARGIN_RIGHT } from '../pages/main_home';
var RANKING_VERTICAL_COUNT = 3;
export var LessonRanking = function (_a) {
    var rankings = _a.rankings, classObject = _a.classObject, itemsPadding = _a.itemsPadding, addFavoriteLesson = _a.addFavoriteLesson, unFavoriteLesson = _a.unFavoriteLesson, itemSize = _a.itemSize, isBig = _a.isBig;
    return (React.createElement(VerticalListArrows, { itemMarginRight: LIST_ITEM_MARGIN_RIGHT, classObject: [styles.container, classObject], itemsPadding: itemsPadding, itemSize: itemSize, items: separateArrayBySpecifiedNumber(rankings, RANKING_VERTICAL_COUNT).map(function (rowRankingList) { return (React.createElement("div", { className: css(styles.itemContainer) }, rowRankingList.map(function (v, i) {
            return (React.createElement(LessonRankingCard, { ranking: v, classObject: styles.item, key: i, addFavoriteLesson: addFavoriteLesson, unFavoriteLesson: unFavoriteLesson, isBig: isBig }));
        }))); }) }));
};
var styles = StyleSheet.create({
    container: {},
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'scroll',
        '::-webkit-scrollbar': {
            display: 'none',
        },
    },
    item: {
        marginBottom: 10,
        ':nth-of-type(3n)': {
            marginBottom: 0,
        },
    },
});
