var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import * as dayjs from 'dayjs';
var useState = React.useState, useEffect = React.useEffect;
import { css, StyleSheet } from 'aphrodite';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getFormattedToFormat, weeksFromToday } from '@lean-body/src/util';
import { Colors } from '../styles';
import { HomeCalendarDay } from '../atoms';
var CURRENT_WEEK_NUMBER = 0;
export var HomeCalendarHeader = function (_a) {
    var apiClient = _a.apiClient, classObject = _a.classObject, tracker = _a.tracker, selectedDate = _a.selectedDate, onClickDay = _a.onClickDay;
    var today = new Date();
    var _b = useState(new Map()), dayCompletedMap = _b[0], setDayCompletedMap = _b[1];
    var targetWeekNumber = weeksFromToday(selectedDate);
    var numOfTargetWeeksDefault = [];
    // 対象週の次の週まで取得しておきたいので targetWeekNumber + 1 までループさせる
    for (var i = 0; i <= targetWeekNumber + 1; i++) {
        numOfTargetWeeksDefault.push(CURRENT_WEEK_NUMBER - i);
    }
    var _c = useState(numOfTargetWeeksDefault), numOfTargetWeeks = _c[0], setNumOfTargetWeeks = _c[1];
    useEffect(function () {
        numOfTargetWeeks.forEach(function (v) {
            var date = getDays(v).keys().next().value;
            fetchHomeCalendar(date);
        });
    }, []);
    var fetchHomeCalendar = function (date) {
        apiClient.fetchHomeCalendar(getFormattedToFormat(date)).then(function (fetchHomeCalendar) {
            fetchHomeCalendar.forEach(function (v) {
                var date = new Date(v.date);
                dayCompletedMap.set(getFormattedToFormat(date), { taskCompleted: v.dailyTaskCompleted });
            });
            setDayCompletedMap(new Map(dayCompletedMap));
        });
    };
    var getDays = function (numOfTargetWeeks) {
        var days = new Map();
        for (var i = 6; i >= 0; i--) {
            var day = dayjs(today)
                .add(numOfTargetWeeks, 'w')
                .add(i - today.getDay(), 'd')
                .toDate();
            days.set(day, { dayLocal: dayjs(day).format('ddd') });
        }
        return days;
    };
    var onClick = function (date) {
        onClickDay(date);
        tracker.trackClickEvent({ category: 'Calendar Clicks', label: getFormattedToFormat(date) });
    };
    var onReachEnd = function () {
        var addWeekNumber = CURRENT_WEEK_NUMBER - numOfTargetWeeks.length;
        setNumOfTargetWeeks(__spreadArray(__spreadArray([], numOfTargetWeeks, true), [addWeekNumber], false));
        var date = getDays(addWeekNumber).keys().next().value;
        fetchHomeCalendar(date);
    };
    var handleSlideChange = function (swiper) {
        var currentIndex = swiper.activeIndex;
        var direction = swiper.previousIndex > swiper.activeIndex ? 'Slide Left' : 'Slide Right';
        tracker.trackSlideEvent({ category: 'Calendar slides', label: direction, value: currentIndex });
    };
    var taskCompleted = function (date) {
        var dateString = getFormattedToFormat(date);
        if (dayCompletedMap.get(dateString)) {
            return dayCompletedMap.get(dateString).taskCompleted;
        }
        else {
            return false;
        }
    };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(Swiper, { initialSlide: targetWeekNumber, onSlideChange: handleSlideChange, onReachEnd: onReachEnd, pagination: { clickable: true }, slidesPerView: 'auto', threshold: 4, dir: "rtl" }, numOfTargetWeeks.map(function (v, i) { return (React.createElement(SwiperSlide, { key: i },
            React.createElement("div", { className: css(styles.swiperSlideContainer) }, Array.from(getDays(v).entries()).map(function (_a) {
                var date = _a[0], value = _a[1];
                return (React.createElement("div", { key: date.toDateString() },
                    React.createElement(HomeCalendarDay, { classObject: !dayjs(date).isAfter(today) && styles.day, date: date, dayOfWeek: value.dayLocal, selectedDate: selectedDate, taskCompleted: taskCompleted(date), onClick: !dayjs(date).isAfter(today) && onClick })));
            })))); }))));
};
var styles = StyleSheet.create({
    container: {
        borderBottom: "1px solid ".concat(Colors.BorderLightGray),
        padding: '4px 0',
    },
    swiperSlideContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    day: {
        cursor: 'pointer',
    },
});
