import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
import { ClockIcon } from '../atoms/icons/clock_icon';
import { routes } from '@lean-body/src/route';
import { HeartIcon } from '../atoms/icons/heart_icon';
import { ShadowHeartIcon } from '../atoms/icons/shadow_heart_icon';
import { Link } from 'react-router-dom';
export var ProgramComponent = function (_a) {
    var program = _a.program, addFavoriteProgram = _a.addFavoriteProgram, unFavoriteProgram = _a.unFavoriteProgram, onClick = _a.onClick, isVertical = _a.isVertical, isMin = _a.isMin;
    var _b = React.useState(program.isFavorited), isFavorited = _b[0], setIsFavorited = _b[1];
    var favoriteCount = program.programAggregate.totalFavoriteCount.toLocaleString();
    var clickFavorite = function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (isFavorited) {
            unFavoriteProgram(program.id);
            setIsFavorited(false);
        }
        else {
            addFavoriteProgram(program.id);
            setIsFavorited(true);
        }
    };
    return (React.createElement(Link, { className: css(styles.item, isMin && styles.itemMin, isVertical && styles.itemVertical), to: routes.PROGRAM.compile({ slug: program.slug }), onClick: function () { return onClick && onClick(program); } },
        React.createElement("div", { className: css(styles.headImg, isVertical && (isMin ? styles.headImgVerticalMin : styles.headImgVertical)) },
            React.createElement(ShadowHeartIcon, { color: Colors.White, filled: isFavorited, classObject: [styles.favoriteIconTopRight, isMin && styles.favoriteIconTopRightMin], onClick: clickFavorite }),
            React.createElement("div", { className: css(styles.labelBottomRight, isMin && styles.labelBottomRightMin) }, "".concat(program.lessonCount, "LESSON")),
            React.createElement("img", { className: css(styles.itemImg), src: program.thumbnailURL, loading: "lazy" })),
        React.createElement("div", { className: css(styles.itemDescription) },
            React.createElement("div", { className: css(styles.itemName, isVertical && styles.itemNameLarge, isMin && styles.itemNameMin) }, program.name),
            React.createElement("div", { className: css(styles.itemInfo, isVertical && styles.itemInfoLarge, isMin && styles.itemInfoMin) },
                React.createElement(HeartIcon, { classObject: [styles.favoriteIcon, isVertical && styles.favoriteIconLarge], color: Colors.Black }),
                favoriteCount,
                "\u30FB",
                program.difficulty.name,
                !isMin && (React.createElement(React.Fragment, null,
                    "\u30FB",
                    React.createElement(ClockIcon, { classObject: [styles.clockIcon, isVertical && styles.clockIconLarge] }), "".concat(program.lessonLength, "min")))),
            isMin && isVertical && (React.createElement("div", { className: css(styles.itemInfo) },
                React.createElement(ClockIcon, { classObject: styles.clockIcon }), "".concat(program.lessonLength, "min"))),
            !isMin && (React.createElement("div", { className: css(styles.itemTags) }, program.tags.map(function (v, i) { return (React.createElement("div", { key: i, className: css(styles.itemTag) },
                "#",
                v.name)); }))),
            !isMin && isVertical && React.createElement("div", { className: css(styles.desc) }, program.description))));
};
var styles = StyleSheet.create({
    item: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '8px 0',
        overflow: 'hidden',
        color: Colors.Black,
        cursor: 'pointer',
    },
    itemMin: {
        gap: '5px 0',
    },
    itemVertical: {
        flexDirection: 'row',
        gap: '0 10px',
    },
    headImg: {
        position: 'relative',
        flex: 1,
    },
    headImgVertical: {
        minWidth: 300,
        maxWidth: 335,
    },
    headImgVerticalMin: {
        minWidth: 160,
        maxWidth: 184,
    },
    labelTopLeft: {
        fontSize: 12,
        fontWeight: 'bold',
        letterSpacing: '0.04em',
        lineHeight: 1,
        padding: '7px 12px',
        borderRadius: 100,
        backgroundColor: Colors.White,
        position: 'absolute',
        top: 10,
        left: 10,
    },
    labelBottomRight: {
        fontSize: 12,
        fontWeight: 'bold',
        letterSpacing: '0.04em',
        lineHeight: 1,
        borderRadius: 4,
        padding: '3px 4px',
        color: Colors.White,
        backgroundColor: Colors.Black,
        position: 'absolute',
        bottom: 6,
        right: 6,
    },
    labelBottomRightMin: {
        fontSize: 10,
    },
    favoriteIconTopRight: {
        width: 37,
        height: 33,
        position: 'absolute',
        top: 4,
        right: 4,
    },
    favoriteIconTopRightMin: {
        width: 34,
        height: 30,
        top: 3,
        right: 3,
    },
    itemDescription: {
        flex: 1,
    },
    itemImg: {
        aspectRatio: '16/9',
        width: '100%',
        borderRadius: 4,
        display: 'block',
    },
    itemName: {
        fontSize: 14,
        fontWeight: 'bold',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '1',
        overflow: 'hidden',
    },
    itemNameMin: {
        fontSize: 12,
        '-webkit-line-clamp': '2',
        height: 36,
    },
    itemNameLarge: {
        fontSize: 16,
    },
    itemInfo: {
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
        margin: '2px 0',
    },
    itemInfoMin: {
        fontSize: 12,
    },
    itemInfoLarge: {
        fontSize: 16,
    },
    favoriteIcon: {
        width: 12,
        height: 11,
        marginRight: 4,
    },
    favoriteIconLarge: {
        width: 15,
        height: 14,
    },
    clockIcon: {
        width: 11,
        height: 13,
        marginRight: 4,
    },
    clockIconLarge: {
        width: 13,
        height: 15,
    },
    itemTags: {
        display: 'flex',
        flexWrap: 'wrap',
        height: 18,
        overflow: 'hidden',
    },
    itemTag: {
        marginRight: 6,
        fontSize: 12,
        color: Colors.GrayDarkest,
    },
    desc: {
        fontSize: 12,
        marginTop: 6,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
        overflow: 'hidden',
    },
});
