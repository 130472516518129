import * as React from 'react';
import { css } from 'aphrodite';
import { RecordMealMenuHistory } from './record_meal_menu_history';
import { RecordMealMenuSearch } from './record_meal_menu_search';
export var RecordMealAnalysisMenuAdd = function (_a) {
    var apiClient = _a.apiClient, config = _a.config, mealType = _a.mealType, changeCheckedAnalysisMenuMap = _a.changeCheckedAnalysisMenuMap, setChangeCheckedAnalysisMenuMap = _a.setChangeCheckedAnalysisMenuMap, setIsChangedAnalysisMenu = _a.setIsChangedAnalysisMenu, onClickMenu = _a.onClickMenu, classObject = _a.classObject;
    var _b = React.useState(false), isInputSearch = _b[0], setIsInputSearch = _b[1];
    var onClickSelectMenu = function (menu) {
        changeCheckedAnalysisMenuMap.set(menu.menuKey, menu);
        setChangeCheckedAnalysisMenuMap(new Map(changeCheckedAnalysisMenuMap));
        setIsChangedAnalysisMenu(true);
    };
    var onClickDeselectMenu = function (menuKey) {
        changeCheckedAnalysisMenuMap.delete(menuKey);
        setChangeCheckedAnalysisMenuMap(new Map(changeCheckedAnalysisMenuMap));
        setIsChangedAnalysisMenu(true);
    };
    return (React.createElement("div", { className: css(classObject) },
        React.createElement(RecordMealMenuSearch, { apiClient: apiClient, config: config, selectedMenuMap: changeCheckedAnalysisMenuMap, onClickMenu: onClickMenu, onClickSelectMenu: onClickSelectMenu, onClickDeselectMenu: onClickDeselectMenu, setIsInputSearch: setIsInputSearch }),
        !isInputSearch && (React.createElement(RecordMealMenuHistory, { apiClient: apiClient, defaultMealType: mealType, selectedMenuMap: changeCheckedAnalysisMenuMap, onClickMenu: onClickMenu, onClickSelectMenu: onClickSelectMenu, onClickDeselectMenu: onClickDeselectMenu }))));
};
