import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
export var Sheet = function (_a) {
    var children = _a.children, classObject = _a.classObject;
    return React.createElement("div", { className: css(styles.container, classObject) }, children);
};
var styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.White,
        boxShadow: '0px 0px 30px 0px #00000029',
        borderRadius: '14px 14px 0px 0px',
        padding: '16px 14px 10px',
    },
});
