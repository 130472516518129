import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Button, ButtonType, CheckBox, CloseButtonLabel } from '../atoms';
import { Dialog } from '../molecules';
import { Colors } from '../styles';
export var MealTaskNotificationRequestDialog = function (_a) {
    var onClickSuccess = _a.onClickSuccess, onClickCancel = _a.onClickCancel, classObject = _a.classObject;
    var _b = React.useState(false), isDontShowAgain = _b[0], setIsDontShowAgain = _b[1];
    var onCancel = function () {
        onClickCancel(isDontShowAgain);
    };
    var onSuccess = function () {
        onClickSuccess(isDontShowAgain);
    };
    var onChangeDontShowAgain = function () {
        setIsDontShowAgain(!isDontShowAgain);
    };
    return (React.createElement(Dialog, { onClickCancel: onCancel, closeButtonLabel: CloseButtonLabel.Close, classObject: [styles.dialog, classObject], content: React.createElement(React.Fragment, null,
            React.createElement("img", { className: css(styles.dialogImg), src: "/assets/images/dialog/meal_task_notification_permission_dialog.jpg" }),
            React.createElement("div", { className: css(styles.info) },
                React.createElement("div", { className: css(styles.title) }, "\u8A18\u9332\u3092\u5FD8\u308C\u306A\u3044\u3088\u3046\u306B\u901A\u77E5\u3092\u8A31\u53EF\u3057\u307E\u3057\u3087\u3046\uFF01"),
                React.createElement("div", { className: css(styles.description) },
                    "\u8A18\u9332\u3082\u308C\u3092\u901A\u77E5\u3067\u304A\u77E5\u3089\u305B\u3057\u307E\u3059\u3002",
                    React.createElement("br", null),
                    "\u98DF\u4E8B\u8A18\u9332\u306E\u7D99\u7D9A\u3067\u30C0\u30A4\u30A8\u30C3\u30C8\u306E\u52B9\u679C\u304C2\u500D\u306B\u306A\u308B\u3068\u8A00\u308F\u308C\u3066\u3044\u307E\u3059\u3088\uFF01")),
            React.createElement("div", { className: css(styles.options) },
                React.createElement(CheckBox, { onChange: onChangeDontShowAgain, labelClassObject: styles.checkboxLabel }, "\u4ECA\u5F8C\u8868\u793A\u3057\u306A\u3044")),
            React.createElement("div", { className: css(styles.buttonContainer) },
                React.createElement(Button, { className: css(styles.button, styles.rejectButton), isRadius: true, buttonType: ButtonType.secondary, onClick: onCancel }, "\u8A31\u53EF\u3057\u306A\u3044"),
                React.createElement(Button, { className: css(styles.button, styles.allowButton), isRadius: true, buttonType: ButtonType.primary, onClick: onSuccess }, "\u8A31\u53EF\u3059\u308B"))) }));
};
var styles = StyleSheet.create({
    dialog: {
        maxWidth: 327,
        backgroundColor: Colors.White,
    },
    info: {
        padding: '16px 20px 16px 20px',
        textAlign: 'center',
    },
    options: {
        padding: '0 20px 16px 20px',
    },
    checkboxLabel: {
        color: Colors.Black,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
    },
    title: {
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '140%',
        letterSpacing: 1.6,
        marginBottom: 8,
    },
    description: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
    dialogImg: {
        width: '100%',
        height: 184,
        borderRadius: '8px 8px 0 0',
    },
    buttonContainer: {
        padding: '0 20px 20px 20px',
    },
    button: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '160%',
        letterSpacing: 1.28,
        paddingLeft: 0,
        paddingRight: 0,
        height: 48,
    },
    allowButton: {
        width: 161,
        padding: 0,
    },
    rejectButton: {
        width: 116,
        padding: 0,
        marginRight: 10,
    },
});
