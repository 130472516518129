import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors } from '@lean-body/components/styles/variables';
export var CalomealErrorMessage = function (_a) {
    var config = _a.config, message = _a.message;
    return (React.createElement(React.Fragment, null, !config.isProduction && message != '' && React.createElement("div", { className: css(styles.message) }, message)));
};
var styles = StyleSheet.create({
    message: {
        color: Colors.Red,
        textAlign: 'center',
        fontSize: 14,
        padding: '0 10px 10px',
    },
});
