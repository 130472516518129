import { observable, makeObservable } from 'mobx';
export var FORM_PASSWORD_PLACEHOLDER = '6桁以上のパスワードを入力';
var PasswordVM = /** @class */ (function () {
    function PasswordVM() {
        Object.defineProperty(this, "passwordStr", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "passwordValid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        Object.defineProperty(this, "isSamePassword", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        makeObservable(this, {
            passwordStr: observable,
            passwordValid: observable,
            isSamePassword: observable,
        });
    }
    Object.defineProperty(PasswordVM.prototype, "onPasswordChanged", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (password) {
            this.passwordStr = password;
            this.passwordValid = password.length >= 6;
        }
    });
    Object.defineProperty(PasswordVM.prototype, "isPasswordInvalid", {
        get: function () {
            return !this.passwordValid;
        },
        enumerable: false,
        configurable: true
    });
    return PasswordVM;
}());
export { PasswordVM };
