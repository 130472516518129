import { Lesson, WatchedLessonRanking, Playlist, UserProfile, DailyGoal, Course } from '@lean-body/src/domain';
export var KIND_USER_NAME = 'user_name';
export var KIND_ACTIVITY_LEVEL = 'activity_level';
export var KIND_GENDER = 'gender';
export var KIND_BIRTHDAY = 'birthday';
export var KIND_HEIGHT = 'height';
export var KIND_WEIGHT = 'weight';
export var KIND_WEIGHT_GOAL = 'weight_goal';
export var KIND_WEIGHT_GOAL_PACE = 'weight_goal_pace';
export var KIND_WEIGHT_GOAL_APPROACH = 'weight_goal_approach';
export var KIND_COURSE_DAYS_OF_WEEK = 'course_days_of_week';
export var KIND_COURSE_TIME = 'course_time';
var MatchingAssessment = /** @class */ (function () {
    function MatchingAssessment(data, config) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "question", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isMultiple", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "kind", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "matchingAssessmentTextField", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "matchingAssessmentChoices", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.question = data.question;
        this.description = data.description;
        this.isMultiple = data.isMultiple;
        this.kind = data.kind;
        if (data.matchingAssessmentTextField) {
            this.matchingAssessmentTextField = new MatchingAssessmentTextField(data.matchingAssessmentTextField);
        }
        if (data.matchingAssessmentChoices) {
            this.matchingAssessmentChoices = data.matchingAssessmentChoices.map(function (v) {
                return new MatchingAssessmentChoice(v, config);
            });
        }
    }
    Object.defineProperty(MatchingAssessment.prototype, "isKindUserName", {
        get: function () {
            return this.kind === KIND_USER_NAME;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MatchingAssessment.prototype, "isKindBirthday", {
        get: function () {
            return this.kind === KIND_BIRTHDAY;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MatchingAssessment.prototype, "isKindHeight", {
        get: function () {
            return this.kind === KIND_HEIGHT;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MatchingAssessment.prototype, "isKindWeight", {
        get: function () {
            return this.kind === KIND_WEIGHT;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MatchingAssessment.prototype, "isKindWeightGoal", {
        get: function () {
            return this.kind === KIND_WEIGHT_GOAL;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MatchingAssessment.prototype, "isKindWeightGoalPace", {
        get: function () {
            return this.kind === KIND_WEIGHT_GOAL_PACE;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MatchingAssessment.prototype, "isKindWeightGoalApproach", {
        get: function () {
            return this.kind === KIND_WEIGHT_GOAL_APPROACH;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MatchingAssessment.prototype, "isKindActivityLevel", {
        get: function () {
            return this.kind === KIND_ACTIVITY_LEVEL;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MatchingAssessment.prototype, "isKindGender", {
        get: function () {
            return this.kind === KIND_GENDER;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MatchingAssessment.prototype, "isKindCourseDaysOfWeek", {
        get: function () {
            return this.kind == KIND_COURSE_DAYS_OF_WEEK;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MatchingAssessment.prototype, "isKindCourseTime", {
        get: function () {
            return this.kind == KIND_COURSE_TIME;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MatchingAssessment.prototype, "hasDefaultChoice", {
        get: function () {
            return !!this.matchingAssessmentChoices.find(function (choice) {
                return choice.isDefaultSelected;
            });
        },
        enumerable: false,
        configurable: true
    });
    return MatchingAssessment;
}());
export { MatchingAssessment };
export var INPUT_TYPE_TEXT = 'text';
export var INPUT_TYPE_DATE = 'date';
export var INPUT_TYPE_NUMBER = 'number';
export var INPUT_TYPE_TIME = 'time';
var MatchingAssessmentTextField = /** @class */ (function () {
    function MatchingAssessmentTextField(data) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "matchingAssessmentId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "inputType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "unit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "placeholder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "defaultValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.matchingAssessmentId = data.matchingAssessmentId;
        this.inputType = data.inputType;
        this.unit = data.unit;
        this.placeholder = data.placeholder;
        this.defaultValue = data.defaultValue;
    }
    Object.defineProperty(MatchingAssessmentTextField.prototype, "isInputTypeText", {
        get: function () {
            return this.inputType === INPUT_TYPE_TEXT;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MatchingAssessmentTextField.prototype, "isInputTypeDate", {
        get: function () {
            return this.inputType === INPUT_TYPE_DATE;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MatchingAssessmentTextField.prototype, "isInputTypeNumber", {
        get: function () {
            return this.inputType === INPUT_TYPE_NUMBER;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MatchingAssessmentTextField.prototype, "isInputTypeTime", {
        get: function () {
            return this.inputType === INPUT_TYPE_TIME;
        },
        enumerable: false,
        configurable: true
    });
    return MatchingAssessmentTextField;
}());
export { MatchingAssessmentTextField };
var MatchingAssessmentChoice = /** @class */ (function () {
    function MatchingAssessmentChoice(data, config) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "choices", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isRecommended", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isDefaultSelected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "iconSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "worryId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "interestId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "difficultyId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "selectedUnique", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nextAssessmentId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isLastQuestion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "goalDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dailyGoals", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.config = config;
        this.id = data.id;
        this.choices = data.choices;
        this.description = data.description;
        this.isRecommended = data.isRecommended;
        this.isDefaultSelected = data.isDefaultSelected;
        this.iconSrc = data.iconSrc;
        this.worryId = data.worryId;
        this.interestId = data.interestId;
        this.difficultyId = data.difficultyId;
        this.selectedUnique = data.selectedUnique;
        this.nextAssessmentId = data.nextAssessmentId;
        this.orderIndex = data.orderIndex;
        this.isLastQuestion = data.isLastQuestion;
    }
    Object.defineProperty(MatchingAssessmentChoice.prototype, "iconImageURL", {
        get: function () {
            return this.config.cdnBaseURL + this.iconSrc;
        },
        enumerable: false,
        configurable: true
    });
    return MatchingAssessmentChoice;
}());
export { MatchingAssessmentChoice };
var MatchingAssessmentResponse = /** @class */ (function () {
    function MatchingAssessmentResponse(data, config) {
        Object.defineProperty(this, "userProfile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessThanTenMinutesLessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "betweenTenAndThirtyMinutesLessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "overThirtyMinutesLessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "rankingLessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "recommendedPlaylists", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.userProfile = new UserProfile(data.userProfile, config);
        this.lessThanTenMinutesLessons = data.lessThanTenMinutesLessons.map(function (v) { return new Lesson(v, config); });
        this.betweenTenAndThirtyMinutesLessons = data.betweenTenAndThirtyMinutesLessons.map(function (v) { return new Lesson(v, config); });
        this.overThirtyMinutesLessons = data.overThirtyMinutesLessons.map(function (v) { return new Lesson(v, config); });
        this.rankingLessons = data.rankingLessons.map(function (v) { return new WatchedLessonRanking(v, config); });
        this.recommendedPlaylists = data.recommendedPlaylists.map(function (v) { return new Playlist(v, config); });
    }
    return MatchingAssessmentResponse;
}());
export { MatchingAssessmentResponse };
var GoalMatchingAssessmentResponse = /** @class */ (function () {
    function GoalMatchingAssessmentResponse(data, config) {
        Object.defineProperty(this, "userName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "goalDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "currentWweight", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "weightGoal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dailyGoals", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "courses", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.userName = data.userName;
        this.goalDate = data.goalDate;
        this.currentWweight = data.currentWweight;
        this.weightGoal = data.weightGoal;
        this.dailyGoals = data.dailyGoals.map(function (v) { return new DailyGoal(v); });
        this.courses = data.courses.map(function (v) { return new Course(v, config); });
    }
    return GoalMatchingAssessmentResponse;
}());
export { GoalMatchingAssessmentResponse };
var GoalMatchingAssessmentAnswerResponse = /** @class */ (function () {
    function GoalMatchingAssessmentAnswerResponse(data) {
        Object.defineProperty(this, "matchingAssessmentId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "choiceIds", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "textInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.matchingAssessmentId = data.matchingAssessmentId;
        this.choiceIds = data.choiceIds;
        this.textInput = data.textInput;
    }
    Object.defineProperty(GoalMatchingAssessmentAnswerResponse.prototype, "toParam", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                matchingAssessmentID: this.matchingAssessmentId,
                choiceIds: this.choiceIds,
                textInput: this.textInput,
            };
        }
    });
    Object.defineProperty(GoalMatchingAssessmentAnswerResponse, "createEmptyParam", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (assessment) {
            return { matchingAssessmentID: assessment.id, choiceIds: [], textInput: '' };
        }
    });
    return GoalMatchingAssessmentAnswerResponse;
}());
export { GoalMatchingAssessmentAnswerResponse };
var WeightGoalApproach = /** @class */ (function () {
    function WeightGoalApproach(data) {
        Object.defineProperty(this, "choiceId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dailyGoals", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.choiceId = data.choiceId;
        this.dailyGoals = data.dailyGoals.map(function (v) { return new DailyGoal(v); });
    }
    return WeightGoalApproach;
}());
export { WeightGoalApproach };
