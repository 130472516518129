import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var ThreePointLeaderIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 20,
            height: 20,
        },
    });
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), fill: "none", viewBox: "0 0 16 5", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipRule: "evenodd", fill: "#3b434b", fillRule: "evenodd" },
            React.createElement("path", { d: "m.439453 2.41666c0-.92047.746187-1.66666 1.666667-1.66666.92047 0 1.66666.74619 1.66666 1.66666s-.74619 1.66666-1.66666 1.66666c-.92048 0-1.666667-.74619-1.666667-1.66666z" }),
            React.createElement("path", { d: "m6.27344 2.41666c0-.92047.74619-1.66666 1.66666-1.66666s1.66666.74619 1.66666 1.66666-.74619 1.66666-1.66666 1.66666-1.66666-.74619-1.66666-1.66666z" }),
            React.createElement("path", { d: "m12.1074 2.41666c0-.92047.7462-1.66666 1.6667-1.66666s1.6666.74619 1.6666 1.66666-.7461 1.66666-1.6666 1.66666-1.6667-.74619-1.6667-1.66666z" }))));
};
