var Activity = /** @class */ (function () {
    function Activity(data) {
        Object.defineProperty(this, "kcal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "watchDuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "watchedCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "weight", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "kcalDiff", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "watchDurationDiff", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "watchedCountDiff", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "weightDiff", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonCalories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "steps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "stepCalories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "stepsGoal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "stepsDiff", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "stepsSynced", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.kcal = Math.round(data.totalCal);
        this.watchDuration = Math.round(data.totalWatchDuration / 60);
        this.watchedCount = data.watchedCount;
        this.weight = Math.round(data.weight * 10) / 10;
        this.kcalDiff = Math.round(data.totalCalDiff);
        this.watchDurationDiff = Math.floor(data.totalWatchDurationDiff / 60);
        this.watchedCountDiff = data.watchedCountDiff;
        this.weightDiff = Math.round(data.weightDiff * 10) / 10;
        this.lessonCalories = Math.round(data.lessonCalories);
        this.steps = data.steps;
        this.stepCalories = Math.round(data.stepCalories);
        this.stepsGoal = data.stepsGoal;
        this.stepsDiff = data.stepsDiff;
        this.stepsSynced = data.stepsSynced;
    }
    return Activity;
}());
export { Activity };
