import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var CheckIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 16,
            height: 17,
        },
    });
    var color = props.color || Colors.Black;
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), width: "16", height: "17", viewBox: "0 0 16 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M3 8.89853L6.6 12.4985L13.2 6.49854", stroke: color, strokeWidth: "1.8", strokeLinecap: "round", strokeLinejoin: "round" })));
};
