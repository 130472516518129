import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../styles';
import { CirclePlusIcon } from '../atoms/icons/circle_plus_icon';
import { ArrowDirection, ArrowIcon, CircleCheckIcon } from '../atoms';
import { MealMenuInfo } from './meal_menu_info';
export var MealMenuAnalysisItem = function (_a) {
    var menuIndex = _a.menuIndex, menu = _a.menu, analysisMenuSize = _a.analysisMenuSize, isSelected = _a.isSelected, onClick = _a.onClick, onClickSelect = _a.onClickSelect, onClickDeselect = _a.onClickDeselect, onClickChange = _a.onClickChange;
    return (React.createElement("div", { className: css(styles.container) },
        analysisMenuSize > menuIndex && React.createElement("div", { className: css(styles.title) }, menuIndex + 1),
        React.createElement("div", { className: css(styles.box), onClick: function () { return onClick(menuIndex, menu); } },
            React.createElement(MealMenuInfo, { menu: menu, amount: menu.amountPercentage, classObject: styles.mealMenuInfo }),
            React.createElement("div", { className: css(styles.iconContainer) },
                isSelected ? (React.createElement(CircleCheckIcon, { color: Colors.ProgressGreen, filled: true, onClick: function (e) {
                        e.stopPropagation();
                        onClickDeselect(menuIndex);
                    }, classObject: styles.selectIcon })) : (React.createElement(CirclePlusIcon, { onClick: function (e) {
                        e.stopPropagation();
                        onClickSelect(menuIndex, menu);
                    }, classObject: styles.selectIcon })),
                React.createElement("div", { className: css(styles.arrowIconBox), onClick: function (e) {
                        e.stopPropagation();
                        onClickChange(menuIndex);
                    } },
                    React.createElement(ArrowIcon, { direction: ArrowDirection.right, color: Colors.GrayDarkest, classObject: styles.arrowIcon }))))));
};
var styles = StyleSheet.create({
    container: {
        padding: '0 10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        borderRadius: 10,
    },
    title: {
        color: Colors.GrayDarkest,
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '100%',
        width: '100%',
        padding: '8px 0 5px',
        borderBottom: "1px solid ".concat(Colors.BorderLightGray),
    },
    box: {
        display: 'flex',
        width: '100%',
        padding: '10px 0',
        cursor: 'pointer',
    },
    mealMenuInfo: {
        flex: 1,
        gap: 6,
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 14,
    },
    selectIcon: {
        width: 35,
        height: 35,
    },
    arrowIconBox: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    arrowIcon: {
        width: 20,
        height: 20,
    },
});
