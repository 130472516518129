var _a, _b;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles/';
import { Button, ButtonType } from '@lean-body/components/atoms';
import { TwitterLogoIcon } from '@lean-body/components/atoms/icons/twitter_logo_icon';
export var TwitterEllipseButton = function (_a) {
    var text = _a.text, onClick = _a.onClick, classObject = _a.classObject;
    return (React.createElement(Button, { className: css(styles.button, classObject), buttonType: ButtonType.twitter, onClick: onClick, isRadius: true },
        React.createElement("div", { className: css(styles.buttonInner) },
            React.createElement(TwitterLogoIcon, { color: Colors.White, classObject: styles.twitterLogo }),
            React.createElement("div", { className: css(styles.label) }, text))));
};
var styles = StyleSheet.create({
    button: (_a = {
            textAlign: 'center',
            height: 48
        },
        _a[MediaBreakPointUp.XL] = {
            height: 60,
        },
        _a),
    buttonInner: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap',
    },
    twitterLogo: {
        marginRight: 6,
    },
    label: (_b = {
            fontSize: 16,
            fontWeight: 'bold'
        },
        _b[MediaBreakPointUp.XL] = {
            fontSize: 20,
        },
        _b),
});
