/*
 * 画像URLとImageSizeを受け取って
 * URLに対応するImageSizeのサフィックスをつけて返す。
 * 管理画面からアップロードする画像全てに対して使える。
 *
 * ## 実装意図:
 * 画像の元データが大きく、オリジナルサイズで画像をロードさせるとサイト全体のロードが著しく遅くなるため
 * 管理画面でアップした画像をLambdaで自動圧縮し、オリジナルサイズをサフィックス付きで保存している。
 *
 */
export var ImageSize;
(function (ImageSize) {
    ImageSize["Original"] = "original";
})(ImageSize || (ImageSize = {}));
export var getResizedImageURL = function (path, size) {
    var withSuffixPath = path;
    var reg = /(.*)(?:\.([^.]+$))/;
    var match = withSuffixPath.match(reg);
    switch (size) {
        case ImageSize.Original:
            withSuffixPath = "".concat(match[1]).concat(ImageSrcSuffix.Original, ".").concat(match[2]);
            break;
    }
    return withSuffixPath;
};
/*---------------
= Private constants =
----------------*/
var ImageSrcSuffix = {
    Original: '_original',
};
