export var BTN_SIGNUP_FB = 'Facebookで登録';
export var BTN_SIGNUP_LINE = 'LINEで登録';
export var BTN_SIGNIN_FB = 'facebookでログイン';
export var BTN_SIGNIN_LINE = 'LINEでログイン';
export var BTN_SIGNUP_PAGE_SIGNIN = 'ログインはこちら';
export var BTN_SIGNIN_PAGE_REGISTER = '会 員 登 録';
export var BTN_SIGNOUT = 'ログアウト';
export var BTN_UNSUBSCRIBE_PROCEDURE = '退会手続き';
export var BTN_DORMANT = '休眠会員になる';
export var BTN_TO_PROGRAM_TOP = 'プログラムページへ';
export var BTN_FIND_LESSON_FROM_PROGRAM = 'プログラムからレッスンを探す';
