import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var PlaylistLandscapeImg = function (_a) {
    var thumbnailURL = _a.thumbnailURL;
    return (React.createElement("div", { className: css(styles.itemImgContainer) },
        React.createElement("div", { className: css(styles.itemImgBox) },
            React.createElement("img", { className: css(styles.itemImg), src: thumbnailURL }))));
};
var styles = StyleSheet.create({
    itemImgContainer: {
        display: 'flex',
        justifyContent: 'center',
        ':before': {
            content: '""',
            display: 'block',
            paddingTop: '56.25%',
            width: '100%',
        },
    },
    itemImgBox: {
        height: '100%',
        padding: '10px 0',
        position: 'absolute',
    },
    itemImg: {
        borderRadius: 4,
        height: '100%',
    },
});
