import * as React from 'react';
import { css, StyleSheet } from 'aphrodite-jss'; // aphrodite-jssでしか出来ないcssを使用しているので、変更しないように
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { AnnouncementLabel } from '@lean-body/components/atoms/announcement_label';
export var AnnouncementFullContent = function (_a) {
    var _b, _c, _d;
    var announcement = _a.announcement, me = _a.me;
    var styles = StyleSheet.create({
        aspectRatio: (_b = {
                paddingTop: '56.25%',
                position: 'relative',
                width: '100%',
                overFlow: 'hidde'
            },
            _b[MediaBreakPointUp.SM] = {
                marginTop: 40,
            },
            _b),
        thumbnail: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            top: 0,
        },
        content: (_c = {
                margin: '40px 24px'
            },
            _c[MediaBreakPointUp.SM] = {
                margin: '40px 0',
            },
            _c),
        title: {
            margin: '16px 0 24px',
            fontWeight: 'bold',
            fontSize: 18,
        },
        date: {
            fontSize: 14,
        },
        newLabel: {
            fontSize: 12,
        },
        description: {
            fontSize: 14,
            whiteSpace: 'pre-line',
            lineHeight: '180%',
            '& img': {
                width: '100%',
                marginBottom: 24,
            },
            '& a': {
                fontSize: 16,
                fontWeight: 'bold',
                display: 'block  !important',
                padding: '8px 0',
                textAlign: 'center',
                backgroundColor: Colors.White,
                border: "1px solid ".concat(Colors.Primary),
                color: Colors.Primary,
                borderRadius: '60px',
                margin: '0 auto 24px',
                maxWidth: 328,
            },
            '& h2': {
                fontSize: 14,
                fontWeight: 'bold',
                marginBottom: 24,
            },
            '& iframe': (_d = {
                    display: 'block',
                    margin: '0 auto'
                },
                _d[MediaBreakPointUp.XS] = {
                    width: 327,
                    height: 184,
                },
                _d[MediaBreakPointUp.XL] = {
                    width: 600,
                    height: 338,
                },
                _d),
        },
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.aspectRatio) },
            React.createElement("img", { className: css(styles.thumbnail), src: announcement.thumbnailSrc })),
        React.createElement("div", { className: css(styles.content) },
            React.createElement(AnnouncementLabel, { announcement: announcement, me: me, dateLabelClass: styles.date, newLabelClass: styles.newLabel }),
            React.createElement("div", { className: css(styles.title) }, announcement.title),
            React.createElement("div", { className: css(styles.description), dangerouslySetInnerHTML: { __html: announcement.body } }))));
};
