import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var CanvasWithCenterImageAspectRatio = {
    RATIO_1_1: '1:1',
    RATIO_16_9: '16:9',
};
var SCALE = 4; // 解像度を上げるため150pxの4倍で作成して、propsの大きさに縮小してる
export var CanvasWithCenterImage = React.forwardRef(function (_a, ref) {
    var imgSrc = _a.imgSrc, width = _a.width, height = _a.height, centerImageWidth = _a.centerImageWidth, aspectRatio = _a.aspectRatio, classObject = _a.classObject, onLoad = _a.onLoad;
    var canvasRef = ref;
    React.useEffect(function () {
        var canvas = canvasRef.current;
        // canvasの大きさは固定して、propsで受け取った大きさに拡大/縮小してる
        var canvasWidth = aspectRatio === CanvasWithCenterImageAspectRatio.RATIO_1_1 ? 320 : 320;
        var canvasHeight = aspectRatio === CanvasWithCenterImageAspectRatio.RATIO_1_1 ? 320 : 180;
        canvas.width = canvasWidth * SCALE;
        canvas.height = canvasHeight * SCALE;
        canvas.style.width = "".concat(width, "px");
        canvas.style.height = "".concat(height, "px");
        var ctx = canvas.getContext('2d');
        ctx.scale(SCALE, SCALE);
        ctx.fillStyle = Colors.BackgroundBlue;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        var image = new Image();
        image.crossOrigin = 'anonymous';
        image.onload = function () {
            // canvasの中央にURLの画像を入れる
            var aspectRate = centerImageWidth / image.width;
            var centerImageHeight = aspectRate * image.height;
            ctx.drawImage(image, centerImageWidth > canvasWidth ? 0 : (canvasWidth - centerImageWidth) / 2, centerImageHeight > canvasHeight ? 0 : (canvasHeight - centerImageHeight) / 2, centerImageWidth > canvasWidth ? canvasWidth : centerImageWidth, centerImageHeight > canvasHeight ? canvasHeight : centerImageHeight);
            if (onLoad)
                onLoad();
        };
        image.src = imgSrc; // srcのプロパティを設定する前にonloadを設定しないとonloadが実行されない可能性があるので注意
    }, []);
    return React.createElement("canvas", { ref: ref, className: css(styles.canvas, classObject) });
});
var styles = StyleSheet.create({
    canvas: {},
});
