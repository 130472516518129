var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SharedTiming } from '@lean-body/src/interfaces';
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Button } from '../atoms';
import { HeartIcon } from '../atoms/icons/heart_icon';
import { ShareIcon } from '../atoms/icons/share_icon';
import { Colors } from '../styles';
import { isAndroidApp } from '@lean-body/src/util';
export var LessonMetaUserAction = function (_a) {
    var classObject = _a.classObject, lesson = _a.lesson, program = _a.program, playlist = _a.playlist, isFavorite = _a.isFavorite, onClickFav = _a.onClickFav, twitterService = _a.twitterService, onClickTwitterShareIfAuthed = _a.onClickTwitterShareIfAuthed, onClickProgramPlayButton = _a.onClickProgramPlayButton;
    var onClickShareButton = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, twitterService.onClickShare(SharedTiming.share_button, lesson, program, playlist)];
                case 1:
                    _a.sent();
                    if (twitterService.isAuthorized)
                        onClickTwitterShareIfAuthed();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        onClickFav && (React.createElement("div", { className: css(styles.left) },
            React.createElement(HeartIcon, { classObject: styles.actionFavoriteIcon, color: isFavorite ? Colors.PrimaryDark : Colors.Black, filled: isFavorite, onClick: onClickFav }),
            !isAndroidApp() && (React.createElement(ShareIcon, { classObject: styles.shareIcon, color: Colors.Black, onClick: onClickShareButton })))),
        onClickProgramPlayButton && program.videoSrc && (React.createElement(Button, { className: css(styles.button), onClick: onClickProgramPlayButton, isRadius: true },
            React.createElement("div", { className: css(styles.buttonInner) },
                React.createElement("img", { className: css(styles.intorduceIcon), src: "/assets/images/icons/program_introduce.svg" }),
                React.createElement("p", null, "\u7D39\u4ECB\u52D5\u753B\u3092\u898B\u308B"))))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    left: {
        display: 'flex',
        alignItems: 'center',
        gap: 17,
    },
    actionFavoriteIcon: {
        width: 26,
        height: 22,
        margin: '5px 3px',
        cursor: 'pointer',
    },
    shareIcon: {
        width: 21,
        height: 21,
        margin: 5,
        cursor: 'pointer',
    },
    button: {
        fontSize: 12,
        padding: '5px 30px',
    },
    buttonInner: {
        display: 'flex',
        alignItems: 'center',
    },
    intorduceIcon: {
        display: 'block',
        marginRight: 6,
    },
});
