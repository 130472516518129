var Equipment = /** @class */ (function () {
    function Equipment(equipData, config) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "thumbnailSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "linkTo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = equipData.id;
        this.name = equipData.name;
        this.thumbnailSrc = equipData.thumbnailSrc;
        this.linkTo = equipData.linkTo;
        this.config = config;
    }
    Object.defineProperty(Equipment.prototype, "thumbnailURL", {
        get: function () {
            return this.config.cdnBaseURL + this.thumbnailSrc;
        },
        enumerable: false,
        configurable: true
    });
    return Equipment;
}());
export { Equipment };
