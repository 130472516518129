var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Dialog } from '@lean-body/components/molecules';
import { Button, ButtonType, Input, Textarea } from '@lean-body/components/atoms';
import { CloseButtonLabel } from '@lean-body/components/atoms/dialog_close_button';
export var FormDialogType;
(function (FormDialogType) {
    FormDialogType[FormDialogType["inputText"] = 0] = "inputText";
    FormDialogType[FormDialogType["textarea"] = 1] = "textarea";
})(FormDialogType || (FormDialogType = {}));
export var FormDialog = function (_a) {
    var submit = _a.submit, close = _a.close, checkWarning = _a.checkWarning, title = _a.title, value = _a.value, placeholder = _a.placeholder, description = _a.description, type = _a.type, inputClassObject = _a.inputClassObject;
    var _b = React.useState(value), tmpText = _b[0], setTmpText = _b[1];
    var _c = React.useState(null), warning = _c[0], setWarning = _c[1];
    var onChange = function (event) {
        setTmpText(event.target.value);
    };
    var onClickSubmit = function () {
        var warningResult = checkWarning(tmpText);
        if (warningResult) {
            return setWarning(warningResult);
        }
        submit(tmpText);
        close();
    };
    var form = function () {
        switch (type) {
            case FormDialogType.textarea:
                return (React.createElement(Textarea, { className: css(styles.textarea, inputClassObject), onChange: onChange, value: tmpText, placeholder: placeholder }));
            default:
                return (React.createElement(Input, { classObject: [styles.input, inputClassObject], onChange: onChange, value: tmpText, placeholder: placeholder }));
        }
    };
    return (React.createElement(Dialog, { closeButtonLabel: CloseButtonLabel.Cancel, onClickCancel: close, classObject: styles.dialog, isCloseButton: true, content: React.createElement("div", { className: css(styles.container) },
            React.createElement("div", { className: css(styles.title) }, title),
            form(),
            React.createElement("div", { className: css(styles.description) }, description),
            React.createElement(Button, { buttonType: ButtonType.primary, isRadius: true, className: css(styles.button), onClick: onClickSubmit }, "\u4FDD\u5B58"),
            React.createElement("div", { className: css(styles.warning) }, warning)) }));
};
var styles = StyleSheet.create({
    container: (_a = {
            padding: 24,
            maxWidth: 420,
            margin: '0 auto'
        },
        _a[MediaBreakPointUp.XL] = {
            padding: 42,
        },
        _a),
    dialog: {
        backgroundColor: Colors.White,
    },
    title: {
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 40,
    },
    input: {
        fontSize: 14,
        padding: 16,
        backgroundColor: Colors.BackgroundLightGray,
        borderRadius: 4,
        width: '100%',
        border: 'none',
    },
    textarea: (_b = {
            height: '10rem',
            width: '100%',
            fontSize: 14,
            padding: 16,
            backgroundColor: Colors.BackgroundLightGray,
            borderRadius: 4,
            border: 'none',
            '::placeholder': {
                color: Colors.GrayDarkest,
            }
        },
        _b[MediaBreakPointUp.XL] = {
            height: 200,
        },
        _b),
    description: {
        color: Colors.GrayDarkest,
        fontSize: 12,
        margin: '16px 16px 40px',
        whiteSpace: 'pre-wrap',
    },
    button: {
        display: 'block',
        fontSize: 16,
        width: '100%',
        maxWidth: 300,
        margin: '0 auto',
    },
    warning: {
        color: Colors.Accent,
        fontSize: 12,
        marginTop: 8,
        textAlign: 'center',
        minHeight: 18,
    },
});
