import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { ArrowDirection, ArrowIcon, CloseIcon } from '../atoms';
export var LiveLessonNotificationPermissionBanner = function (_a) {
    var onClose = _a.onClose, onClick = _a.onClick, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject), onClick: onClick },
        React.createElement(CloseIcon, { color: Colors.White, onClick: function (e) {
                e.stopPropagation();
                onClose();
            } }),
        "\u901A\u77E5\u30AA\u30F3\u3067LIVE\u3092\u898B\u9003\u3055\u306A\u3044\u3088\u3046\u306B\u3057\u307E\u3057\u3087\u3046!",
        React.createElement(ArrowIcon, { direction: ArrowDirection.right, color: Colors.White })));
};
var styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.PrimaryDark,
        padding: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: Colors.White,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: '160%',
        letterSpacing: 0.48,
    },
});
