var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SharedTiming } from '@lean-body/src/interfaces';
var TwitterService = /** @class */ (function () {
    function TwitterService(apiClient, alertPresenter, localStorage, tracker, navigate) {
        var _this = this;
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "localStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "navigate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isAuthorized", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "tweetImgSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tweetCenterImageWidth", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tweetDefaultText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "shareClickParams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "showFormDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "loadTwitterAuthorized", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () { return __awaiter(_this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (this.isAuthorized)
                                return [2 /*return*/];
                            // oauth認証済みかどうかをAPIで取得
                            _a = this;
                            return [4 /*yield*/, this.apiClient.confirmTwitterAuthorization()];
                        case 1:
                            // oauth認証済みかどうかをAPIで取得
                            _a.isAuthorized = _b.sent();
                            return [2 /*return*/];
                    }
                });
            }); }
        });
        Object.defineProperty(this, "loadTwitterAuthorizationURL", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.apiClient.twitterAuthorization()];
                        case 1: 
                        // oauth認証URLをAPIで取得
                        return [2 /*return*/, _a.sent()];
                    }
                });
            }); }
        });
        // アクティビティページでバッジダイアログを開いたログをBQへ送信
        Object.defineProperty(this, "sendBqBadgeOpenDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (badge) {
                // BQログ作成
                _this.shareClickParams = { sharedTiming: SharedTiming.collection };
                _this.prepareBadge(badge);
                _this.tracker.trackTwitterDialogOpen(_this.shareClickParams);
            }
        });
        // アクティビティページでランクダイアログを開いたログをBQへ送信
        Object.defineProperty(this, "sendBqRankOpenDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (rank) {
                // BQログ作成
                _this.shareClickParams = { sharedTiming: SharedTiming.collection };
                _this.prepareRank(rank);
                _this.tracker.trackTwitterDialogOpen(_this.shareClickParams);
            }
        });
        // Twitterシェアボタンのクリックハンドラ（レッスン・プログラム・プレイリスト用）
        Object.defineProperty(this, "onClickShare", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (sharedTiming, lesson, program, playlist) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.loadTwitterAuthorized()
                            // BQログ作成
                        ];
                        case 1:
                            _a.sent();
                            // BQログ作成
                            this.shareClickParams = { sharedTiming: sharedTiming };
                            // ツイートコンテンツの設定
                            if (lesson)
                                this.prepareLesson(lesson);
                            else if (program)
                                this.prepareProgram(program);
                            else if (playlist)
                                this.preparePlaylist(playlist);
                            // BQログ送信
                            this.tracker.trackTwitterShareClick(this.shareClickParams, this.isAuthorized);
                            // まだ認証されていなかったらTwitterの認証ページへリダイレクトする
                            this.redirectToTwitterAuthIfNeeded();
                            return [2 /*return*/];
                    }
                });
            }); }
        });
        // Twitterシェアボタンのクリックハンドラ（バッジ・修了証・ランク用）
        Object.defineProperty(this, "onClickShareForBadgeOrRank", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (imgSrc, centerImageWidth, defaultText, sharedTiming, rank, badge) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.loadTwitterAuthorized()
                            // BQログ作成
                        ];
                        case 1:
                            _a.sent();
                            // BQログ作成
                            this.shareClickParams = { sharedTiming: sharedTiming };
                            // ツイートコンテンツの設定
                            if (rank)
                                this.prepareRank(rank);
                            else if (badge)
                                this.prepareBadge(badge);
                            this.tweetImgSrc = imgSrc;
                            this.tweetCenterImageWidth = centerImageWidth;
                            this.tweetDefaultText = defaultText;
                            // BQログ送信
                            this.tracker.trackTwitterShareClick(this.shareClickParams, this.isAuthorized);
                            // まだ認証されていなかったらTwitterの認証ページへリダイレクトする
                            this.redirectToTwitterAuthIfNeeded();
                            return [2 /*return*/];
                    }
                });
            }); }
        });
        // Twitterシェアボタンのクリックハンドラ（Reward用）
        Object.defineProperty(this, "onClickShareReward", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (reward, sharedTiming) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.loadTwitterAuthorized()];
                        case 1:
                            _a.sent();
                            this.shareClickParams = { sharedTiming: sharedTiming };
                            // まだ認証されていなかったらTwitterの認証ページへリダイレクトする
                            this.redirectToTwitterAuthIfNeeded(); // 認証済みであれば呼び出し元でコールバックが実行される
                            return [2 /*return*/];
                    }
                });
            }); }
        });
        // Oauth認証後コールバック処理
        Object.defineProperty(this, "onTwitterAuthCallback", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var storageData = _this.localStorage.loadTwitterRedirectData;
                var p = new URLSearchParams(location.search);
                if (!p.get('denied')) {
                    // ストレージからデータ取得
                    _this.tweetImgSrc = storageData.tweetImgSrc;
                    _this.tweetCenterImageWidth = storageData.tweetCenterImageWidth;
                    _this.tweetDefaultText = storageData.tweetDefaultText;
                    _this.shareClickParams = _this.localStorage.loadBqSnsShareClickParams;
                    // リダイレクト後にフォームを表示する
                    _this.showFormDialog = true;
                }
                // リダイレクト実行
                _this.navigate(storageData.redirectURL);
                // ストレージのデータ削除
                _this.localStorage.resetTwitterData();
            }
        });
        // ツイート実行処理
        Object.defineProperty(this, "postTweet", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (text, file) { return __awaiter(_this, void 0, void 0, function () {
                var params;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = {
                                text: text,
                            };
                            return [4 /*yield*/, this.apiClient
                                    .postTweet(params, file)
                                    .then(function () {
                                    // BQログ送信
                                    _this.tracker.trackTwitterPostTweetClick(_this.shareClickParams);
                                    _this.alertPresenter.showMessage('ツイートを投稿しました');
                                })
                                    .catch(function (err) {
                                    _this.alertPresenter.showMessage('ツイートの投稿に失敗しました');
                                    // 再度認証情報をサーバから取得させる
                                    _this.isAuthorized = false;
                                    throw err;
                                })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); }
        });
        Object.defineProperty(this, "redirectToTwitterAuthIfNeeded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () { return __awaiter(_this, void 0, void 0, function () {
                var twitterRedirectData, url;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (this.isAuthorized)
                                return [2 /*return*/];
                            twitterRedirectData = {
                                tweetImgSrc: this.tweetImgSrc,
                                tweetCenterImageWidth: this.tweetCenterImageWidth,
                                tweetDefaultText: this.tweetDefaultText,
                                redirectURL: window.location.pathname,
                            };
                            this.localStorage.saveBqSnsShareClickParams(this.shareClickParams);
                            this.localStorage.saveTwitterRedirectData(twitterRedirectData);
                            return [4 /*yield*/, this.loadTwitterAuthorizationURL()];
                        case 1:
                            url = _a.sent();
                            window.location.href = url;
                            return [2 /*return*/];
                    }
                });
            }); }
        });
        Object.defineProperty(this, "prepareLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (lesson) {
                _this.tweetImgSrc = lesson.thumbnailURL;
                _this.tweetCenterImageWidth = 0;
                _this.tweetDefaultText = "LEAN BODY\u306E\u300E".concat(lesson.name, "\u300F #\u30EA\u30FC\u30F3\u30DC\u30C7\u30A3 #LEANBODY");
                _this.shareClickParams.sharedLessonId = lesson.id.toString();
            }
        });
        Object.defineProperty(this, "prepareProgram", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (program) {
                _this.tweetImgSrc = program.thumbnailURL;
                _this.tweetCenterImageWidth = 0;
                _this.tweetDefaultText = "LEAN BODY\u306E\u300E".concat(program.name, "\u300F #\u30EA\u30FC\u30F3\u30DC\u30C7\u30A3 #LEANBODY");
                _this.shareClickParams.sharedProgramId = program.id.toString();
                _this.shareClickParams.sharedProgramIsChallenge = !!program.isChallenge;
                _this.shareClickParams.sharedProgramIsPlaylist = !!program.isPlaylist;
            }
        });
        Object.defineProperty(this, "preparePlaylist", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (playlist) {
                _this.tweetImgSrc = playlist.thumbnailURL;
                _this.tweetCenterImageWidth = 130;
                _this.tweetDefaultText = "LEAN BODY\u306E\u300E".concat(playlist.name, "\u300F #\u30EA\u30FC\u30F3\u30DC\u30C7\u30A3 #LEANBODY");
                _this.shareClickParams.sharedProgramId = playlist.id.toString();
                _this.shareClickParams.sharedProgramIsChallenge = !!playlist.isChallenge;
                _this.shareClickParams.sharedProgramIsPlaylist = true;
            }
        });
        Object.defineProperty(this, "prepareRank", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (rank) {
                _this.shareClickParams.sharedRank = rank.nameJp;
            }
        });
        Object.defineProperty(this, "prepareBadge", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (badge) {
                _this.shareClickParams.sharedBadgeId = badge.id.toString();
                _this.shareClickParams.sharedBadgeType = badge.type;
            }
        });
        this.apiClient = apiClient;
        this.alertPresenter = alertPresenter;
        this.localStorage = localStorage;
        this.tracker = tracker;
        this.navigate = navigate;
    }
    return TwitterService;
}());
export { TwitterService };
