var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
export var LiveLessonThumbnail = function (_a) {
    var src = _a.src, isWatchable = _a.isWatchable, isArchiveTranscodingComplete = _a.isArchiveTranscodingComplete, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("img", { className: css(styles.img), src: src }),
        !isWatchable ? (React.createElement("div", { className: css(styles.overlay) },
            React.createElement("div", { className: css(styles.text) },
                "\u3053\u306ELIVE\u30EC\u30C3\u30B9\u30F3\u306F\u7D42\u4E86\u3057\u307E\u3057\u305F",
                React.createElement("br", null),
                "\u3054\u53C2\u52A0\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3057\u305F\uFF01"))) : (isArchiveTranscodingComplete !== null &&
            !isArchiveTranscodingComplete && (React.createElement("div", { className: css(styles.overlay) },
            React.createElement("div", { className: css(styles.text) },
                "\u52D5\u753B\u3092\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u4E2D\u306E\u305F\u3081\u4E00\u6642\u7684\u306B\u898B\u9003\u3057\u914D\u4FE1\u3092\u3054\u89A7\u3044\u305F\u3060\u3051\u307E\u305B\u3093\u3002",
                React.createElement("br", null),
                "\u5B8C\u4E86\u5F8C\u3001\u898B\u9003\u3057\u914D\u4FE1\u3092\u3054\u89A7\u3044\u305F\u3060\u3051\u307E\u3059\u3002"))))));
};
var styles = StyleSheet.create({
    container: {
        position: 'relative',
        flex: 1,
    },
    img: {
        aspectRatio: '16/9',
        width: '100%',
        borderRadius: 4,
        display: 'block',
    },
    text: (_a = {
            color: Colors.White,
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: 0.56
        },
        _a[MediaBreakPointUp.MD] = {
            letterSpacing: 1.6,
            fontSize: 20,
        },
        _a),
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(30, 32, 32, 0.6)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
