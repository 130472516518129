var Merchandise = /** @class */ (function () {
    function Merchandise(data, config) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "merchandiseCompany", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "title", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "imageSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "url", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "startAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "endAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.merchandiseCompany = data.merchandiseCompany;
        this.title = data.title;
        this.description = data.description;
        this.imageSrc = config.cdnBaseURL + data.imageSrc;
        this.url = data.url;
        this.startAt = data.startAt;
        this.endAt = data.endAt;
    }
    Object.defineProperty(Merchandise.prototype, "companyName", {
        get: function () {
            return this.merchandiseCompany.name;
        },
        enumerable: false,
        configurable: true
    });
    return Merchandise;
}());
export { Merchandise };
