import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { DailyTasksPageTaskName } from '@lean-body/src/route';
import { DailyTasksListItem } from './daily_tasks_list_item';
import { isAndroidApp } from '@lean-body/src/util';
export var DailyTasksList = function (_a) {
    var dailyTasks = _a.dailyTasks, onClickAndroidTaskItem = _a.onClickAndroidTaskItem, onClickTaskItem = _a.onClickTaskItem, onClickSubtask = _a.onClickSubtask, hasAndroidStepccountPermission = _a.hasAndroidStepccountPermission, showMealTaskBadge = _a.showMealTaskBadge, classObject = _a.classObject;
    return (React.createElement("div", { className: css(classObject, styles.container) },
        React.createElement(DailyTasksListItem, { showTaskBadge: showMealTaskBadge, name: DailyTasksPageTaskName.meal, mealTask: dailyTasks.meal, onClick: onClickTaskItem, onClickSubtask: onClickSubtask }),
        React.createElement(DailyTasksListItem, { name: DailyTasksPageTaskName.workout, task: dailyTasks.workout, onClick: onClickTaskItem }),
        isAndroidApp() ? (React.createElement(DailyTasksListItem, { name: DailyTasksPageTaskName.steps, task: dailyTasks.steps, onClick: onClickAndroidTaskItem, shouldStepPermission: !hasAndroidStepccountPermission })) : (dailyTasks.stepsSynced && (React.createElement(DailyTasksListItem, { name: DailyTasksPageTaskName.steps, task: dailyTasks.steps, onClick: onClickTaskItem }))),
        React.createElement(DailyTasksListItem, { name: DailyTasksPageTaskName.weight, task: dailyTasks.weight, onClick: onClickTaskItem })));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
});
