import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { PlusIcon } from '@lean-body/components/atoms/icons/plus_icon';
import { Link } from 'react-router-dom';
import { routes } from '@lean-body/src/route';
export var ORIGINAL_PLAYLIST_ITEM_WIDTH = 165;
export var OriginalPlaylistSectionCard = function (_a) {
    var playlist = _a.playlist, onClickCreate = _a.onClickCreate;
    if (!playlist)
        return (React.createElement("div", { className: css(styles.playlist) },
            React.createElement(Link, { to: routes.NEW_PLAYLIST.compile(), onClick: onClickCreate },
                React.createElement("div", { className: css(styles.imgBox, styles.createPlaylist) },
                    React.createElement(PlusIcon, { classObject: styles.plusIcon }),
                    React.createElement("div", null,
                        "\u65B0\u898F\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8",
                        React.createElement("br", null),
                        "\u4F5C\u6210")),
                React.createElement("div", { className: css(styles.description) }, "\u81EA\u5206\u3060\u3051\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u4F5C\u6210\u3057\u3066\u307F\u3088\u3046\u3002"))));
    return (React.createElement("div", { className: css(styles.playlist) },
        React.createElement(Link, { to: routes.ORIGINAL_PLAYLIST.compile({ id: playlist.id.toString() }) },
            React.createElement("img", { className: css(styles.imgBox), src: playlist.thumbnailURL }),
            React.createElement("div", { className: css(styles.description) },
                React.createElement("div", { className: css(styles.infoLength) },
                    playlist.lessons.length,
                    "\u30EC\u30C3\u30B9\u30F3\u30FB",
                    playlist.totalDuration),
                React.createElement("div", { className: css(styles.calorieRange) },
                    playlist.calorieRange,
                    "kcal")))));
};
var styles = StyleSheet.create({
    playlist: {
        overflow: 'hidden',
        width: ORIGINAL_PLAYLIST_ITEM_WIDTH,
        minWidth: ORIGINAL_PLAYLIST_ITEM_WIDTH,
        ':last-child': {
            marginRight: 0,
        },
    },
    imgBox: {
        display: 'flex',
        width: ORIGINAL_PLAYLIST_ITEM_WIDTH,
        height: ORIGINAL_PLAYLIST_ITEM_WIDTH,
        borderRadius: 4,
    },
    createPlaylist: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontWeight: 'bold',
        color: Colors.Black,
        backgroundColor: Colors.White,
        border: "1.5px solid ".concat(Colors.BorderLightGray),
    },
    plusIcon: {
        width: 34,
        height: 34,
        marginBottom: 17,
    },
    description: {
        color: Colors.Black,
        fontSize: 10,
        padding: '18px 16px 15px',
        backgroundColor: Colors.White,
    },
    infoLength: {
        fontWeight: 'bold',
        color: Colors.GrayDarkest,
        marginBottom: 4,
    },
    calorieRange: {
        letterSpacing: '0.04em',
    },
});
