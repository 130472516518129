import * as React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite';
import { useXlMediaQuery } from '@lean-body/src/util/media_query_hooks';
import { Colors } from '@lean-body/components/styles';
export var LiveScheduleBanner = function (props) {
    var classObject = props.classObject, getBanners = props.getBanners, title = props.title;
    var isXL = useXlMediaQuery();
    var _a = React.useState(null), banner = _a[0], setBanner = _a[1];
    useEffect(function () {
        getBanners().then(function (v) { return setBanner(v[0]); });
    }, []);
    if (!banner)
        return null;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        title && React.createElement("div", { className: css(styles.title) }, title),
        React.createElement(Link, { to: banner.makeLinkPath },
            React.createElement("img", { className: css(styles.banner), src: isXL ? banner.pcImageSrc : banner.spImageSrc }))));
};
var styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.White,
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 12,
    },
    banner: {
        width: '100%',
        borderRadius: 4,
    },
});
