import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
export var VerticalListArrow = function (props) {
    var _a, _b;
    var onClick = props.onClick, direction = props.direction, visible = props.visible, classObject = props.classObject, className = props.className;
    var styles = StyleSheet.create({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: Colors.White,
            opacity: visible ? 0.8 : 0,
            height: '100%',
            cursor: 'pointer',
            transition: 'all .3s ease',
            width: 40,
            position: 'absolute',
            top: 0,
            bottom: 0,
            ':hover': {
                width: 48,
                opacity: 1,
            },
        },
        positionRight: (_a = {
                right: 0
            },
            _a[MediaBreakPointUp.XL] = {
                right: -2, // PCで少しズレてるように見えるので2px調整
            },
            _a),
        positionLeft: (_b = {
                left: 0
            },
            _b[MediaBreakPointUp.XL] = {
                left: -2, // PCで少しズレてるように見えるので2px調整
            },
            _b),
        icon: {
            userSelect: 'none',
            width: "100%",
        },
    });
    var iconURL = direction === 'prev' ? '/assets/images/icons/left_arrow.svg' : '/assets/images/icons/right_arrow.svg';
    var position = direction === 'prev' ? styles.positionLeft : styles.positionRight;
    return (React.createElement("div", { className: css(styles.container, position, classObject) + " ".concat(className), onClick: onClick },
        React.createElement("img", { src: iconURL, className: css(styles.icon) })));
};
