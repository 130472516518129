import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '@lean-body/components/styles';
export var SelectMark = function (props) {
    var styles = StyleSheet.create({
        container: {
            borderRadius: '50%',
            background: Colors.GrayLighter,
            width: 26,
            height: 26,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        checked: {
            background: Colors.Primary,
        },
        checkMark: {
            width: props.markSize || 16,
            height: props.markSize || 16,
            marginBottom: 2,
            position: 'relative',
        },
    });
    var checkClass = function () {
        return [css(styles.container), props.className, props.isChecked ? css(styles.checked) : ''].join(' ');
    };
    return (React.createElement("div", { className: checkClass() },
        React.createElement("svg", { className: css(styles.checkMark), viewBox: "0 0 13 13", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fill: Colors.White, fillRule: "evenodd", clipRule: "evenodd", d: "M4.30257 12.0311C4.45878 12.1873 4.71205 12.1873 4.86826 12.0311L4.91427 11.9851L5.13162 12.2024C5.28782 12.3586 5.54109 12.3586 5.6973 12.2024L12.2027 5.69703C12.3589 5.54082 12.3589 5.28756 12.2027 5.13135L11.3542 4.28282C11.1979 4.12661 10.9447 4.12661 10.7885 4.28282L5.0856 9.98569L2.03983 6.93992C1.88362 6.78371 1.63035 6.78371 1.47414 6.93992L0.625616 7.78845C0.469406 7.94466 0.469406 8.19792 0.625616 8.35413L4.30257 12.0311Z" }))));
};
