var _a;
import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { CloseIcon } from '../atoms';
import { Dialog } from '../molecules';
import { MealHeader } from '../atoms/meal_header';
import { useSmMediaQuery } from '@lean-body/src/util';
import { RecordMealMenuDetailContent } from '../molecules/record_meal_menu_detail_content';
export var RecordMealMenuDetail = function (_a) {
    var menu = _a.menu, amount = _a.amount, onAmountInputChange = _a.onAmountInputChange, onCloseMenuDetail = _a.onCloseMenuDetail, onClickSaveAmount = _a.onClickSaveAmount;
    var isSM = useSmMediaQuery();
    return (React.createElement(React.Fragment, null, isSM ? (React.createElement(React.Fragment, null,
        React.createElement(MealHeader, { onClickBack: onCloseMenuDetail },
            React.createElement("div", { className: css(styles.saveButton), onClick: onClickSaveAmount }, "\u8A18\u9332")),
        React.createElement(RecordMealMenuDetailContent, { menu: menu, amount: amount, onAmountInputChange: onAmountInputChange }))) : (React.createElement(Dialog, { classObject: styles.dialog, onClickCancel: onCloseMenuDetail, content: React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.dialogHeader) },
                React.createElement(CloseIcon, { classObject: styles.closeButton, color: Colors.Black, onClick: onCloseMenuDetail }),
                React.createElement("div", { className: css(styles.saveButton), onClick: onClickSaveAmount }, "\u8A18\u9332")),
            React.createElement(RecordMealMenuDetailContent, { menu: menu, amount: amount, onAmountInputChange: onAmountInputChange })) }))));
};
var styles = StyleSheet.create({
    saveButton: {
        color: Colors.PrimaryDarkB,
        cursor: 'pointer',
        position: 'absolute',
        right: 16,
    },
    dialog: (_a = {},
        _a[MediaBreakPointUp.MD] = {
            width: 582,
            height: 556,
        },
        _a.backgroundColor = Colors.White,
        _a),
    dialogHeader: {
        width: '100%',
        height: 44,
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: 375,
        padding: '0 24px',
        margin: '0 auto',
    },
    closeButton: {
        width: 16,
        height: 16,
        position: 'absolute',
        left: 16,
    },
});
