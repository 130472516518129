import { css, StyleSheet } from 'aphrodite/no-important';
import * as React from 'react';
import { Colors } from '@lean-body/components/styles';
export var Textarea = function (props) {
    var onChange = function (event) {
        props.onChange && props.onChange(event);
    };
    return (React.createElement("textarea", { onChange: onChange, name: props.name, className: css(styles.container) + ' ' + props.className, value: props.value, placeholder: props.placeholder }));
};
var styles = StyleSheet.create({
    container: {
        border: "1px solid ".concat(Colors.Gray),
        borderRadius: 4,
        padding: '.5rem',
        outline: 'none',
        lineHeight: '1.5rem',
        fontSize: '1rem',
    },
});
