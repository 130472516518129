import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { DailyTasksPageTaskName, MealSubtaskPageName } from '@lean-body/src/route';
import { Task } from '@lean-body/src/domain';
import { Colors } from '../styles';
import { Button, ButtonType, CircleCheckIcon, ProgressBar } from '../atoms';
import { DailyTaskSubtask } from '../molecules/daily_tasks_subtask';
import { MealLabel } from '@lean-body/src/domain/meal';
import { MealTaskAnimationIcon } from '../atoms/meal_task_icon';
import { MealTaskBadge } from '../atoms/meal_task_badge';
var DailyTaskInfo = {
    meal: {
        label: '食事を記録する',
        unit: 'kcal',
    },
    workout: {
        label: 'ワークアウト',
        imageSrc: 'assets/images/icons/task_workout.svg',
        unit: 'kcal',
    },
    steps: {
        label: 'ウォーキング',
        imageSrc: 'assets/images/icons/task_steps.svg',
        unit: '歩',
    },
    weight: {
        label: '体重を記録する',
        imageSrc: 'assets/images/icons/task_weight.svg',
        unit: 'kg',
    },
};
export var DailyTasksListItem = function (_a) {
    var task = _a.task, mealTask = _a.mealTask, name = _a.name, onClick = _a.onClick, onClickSubtask = _a.onClickSubtask, shouldStepPermission = _a.shouldStepPermission, showTaskBadge = _a.showTaskBadge, classObject = _a.classObject;
    var current = 0;
    var goal = 0;
    var isComplete = false;
    if (name === DailyTasksPageTaskName.meal && mealTask) {
        current = mealTask.currentTotalCal;
        goal = mealTask.referenceCalMin;
        isComplete = mealTask.isComplete;
    }
    else if (name === DailyTasksPageTaskName.workout) {
        current = Task.roundTaskCalories(task.current);
        goal = Task.roundTaskCalories(task.goal);
        isComplete = task.isComplete;
    }
    else if (name === DailyTasksPageTaskName.steps) {
        current = task.current;
        goal = task.goal;
        isComplete = task.isComplete;
    }
    else if (name === DailyTasksPageTaskName.weight) {
        current = Math.round(task.current * 10) / 10;
        goal = null;
        isComplete = task.isComplete;
    }
    return (React.createElement("div", { className: css(classObject, styles.container, name === DailyTasksPageTaskName.meal && styles.hasSubtask), onClick: function () { return onClick(name); } },
        showTaskBadge && React.createElement(MealTaskBadge, { classObject: styles.taskBadge }),
        React.createElement("div", { className: css(styles.taskContainer) },
            React.createElement(CircleCheckIcon, { color: isComplete ? Colors.ProgressGreen : Colors.BackgroundGrayD, classObject: styles.checkIcon, filled: true }),
            DailyTaskInfo[name].imageSrc && React.createElement("img", { src: DailyTaskInfo[name].imageSrc, className: css(styles.image) }),
            name === DailyTasksPageTaskName.meal && React.createElement(MealTaskAnimationIcon, { classObject: styles.mealTaskAnimationIcon }),
            React.createElement("div", { className: css(styles.info) },
                React.createElement("div", { className: css(styles.taskName) }, DailyTaskInfo[name].label),
                React.createElement("div", { className: css(styles.taskProgress, shouldStepPermission && styles.hideProgress) },
                    (name === DailyTasksPageTaskName.meal ||
                        name === DailyTasksPageTaskName.workout ||
                        name === DailyTasksPageTaskName.steps) && (React.createElement(React.Fragment, null,
                        current.toLocaleString(),
                        React.createElement("span", { className: css(styles.taskProgressGoal) }, " / ".concat(goal === null || goal === void 0 ? void 0 : goal.toLocaleString()).concat(DailyTaskInfo[name].unit)))),
                    name === DailyTasksPageTaskName.weight && (React.createElement(React.Fragment, null, current ? "".concat(current.toLocaleString()).concat(DailyTaskInfo[name].unit) : ''))),
                goal !== null && (React.createElement(ProgressBar, { max: goal, current: current, color: Colors.ProgressGreen, trackColor: Colors.ProgressLightGreen, classObject: styles.progressBar, blurEffect: shouldStepPermission })),
                shouldStepPermission && (React.createElement(Button, { isRadius: true, className: css(styles.permissionButton), buttonType: ButtonType.primary }, "\u6B69\u6570\u3092\u53D6\u5F97\u3059\u308B")))),
        name === DailyTasksPageTaskName.meal && (React.createElement("div", { className: css(styles.mealSubtaskList) },
            React.createElement(DailyTaskSubtask, { label: MealLabel.breakfast, isComplete: mealTask === null || mealTask === void 0 ? void 0 : mealTask.hasEatenBreakfast, taskName: name, subTaskName: MealSubtaskPageName.breakfast, onClick: onClickSubtask, classObject: styles.mealSubtask }),
            React.createElement(DailyTaskSubtask, { label: MealLabel.lunch, isComplete: mealTask === null || mealTask === void 0 ? void 0 : mealTask.hasEatenLunch, taskName: name, subTaskName: MealSubtaskPageName.lunch, onClick: onClickSubtask, classObject: styles.mealSubtask }),
            React.createElement(DailyTaskSubtask, { label: MealLabel.dinner, isComplete: mealTask === null || mealTask === void 0 ? void 0 : mealTask.hasEatenDinner, taskName: name, subTaskName: MealSubtaskPageName.dinner, onClick: onClickSubtask, classObject: styles.mealSubtask }),
            React.createElement(DailyTaskSubtask, { label: MealLabel.snacking, isComplete: mealTask === null || mealTask === void 0 ? void 0 : mealTask.hasEatenSnacking, taskName: name, subTaskName: MealSubtaskPageName.snacking, onClick: onClickSubtask, classObject: styles.mealSubtask })))));
};
var styles = StyleSheet.create({
    container: {
        cursor: 'pointer',
        padding: '24px 16px',
        borderRadius: 14,
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        position: 'relative',
    },
    hasSubtask: {
        paddingBottom: 16,
    },
    taskContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 14,
    },
    checkIcon: {
        width: 24,
        height: 24,
    },
    image: {
        width: 54,
        height: 54,
    },
    info: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        position: 'relative',
    },
    taskName: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
    taskProgress: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
    },
    hideProgress: {
        visibility: 'hidden',
    },
    taskProgressGoal: {
        color: Colors.GrayDarkest,
    },
    progressBar: {
        width: 173,
    },
    permissionButton: {
        position: 'absolute',
        padding: 8,
        fontSize: 11,
        width: 106,
        height: 30,
        bottom: 0,
    },
    mealSubtaskList: {
        display: 'flex',
        gap: 8,
    },
    mealSubtask: {
        flex: 1,
    },
    mealTaskAnimationIcon: {
        width: 54,
        height: 54,
    },
    taskBadge: {
        top: 0,
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
});
