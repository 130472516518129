var _a, _b, _c;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { InitialGuideHowToDetail } from '../molecules';
import { InitialGuideQA } from '../molecules/initial_guide_qa';
export var InitialGuideSeriesDetail = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u30B7\u30EA\u30FC\u30BA\u3068\u306F\uFF1F"),
            React.createElement("div", { className: css(styles.sectionTitleBorder) }),
            React.createElement("div", { className: css(styles.topImgBox) },
                React.createElement("img", { className: css(styles.topImg), src: "/assets/images/initial_guide/series/series_top.png" })),
            React.createElement("div", { className: css(styles.description) },
                "\u3059\u3079\u3066\u306E\u30EC\u30C3\u30B9\u30F3\u306F\u4F01\u753B\u5316\u3055\u308C\u305F\u30B7\u30EA\u30FC\u30BA\u306E\u4E2D\u306B\u5165\u3063\u3066\u3044\u307E\u3059\u3002",
                React.createElement("br", null),
                "\u597D\u304D\u306A\u30EC\u30C3\u30B9\u30F3\u306B\u51FA\u4F1A\u3063\u305F\u3089\u3001\u30B7\u30EA\u30FC\u30BA\u3082\u30C1\u30A7\u30C3\u30AF\u3057\u3066\u307F\u305F\u308A\u3001\u30B7\u30EA\u30FC\u30BA\u304B\u3089\u597D\u304D\u306A\u30EC\u30C3\u30B9\u30F3\u3092\u898B\u3064\u3051\u3066\u307F\u305F\u308A\u3057\u307E\u3057\u3087\u3046\uFF01")),
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.howToSection) },
                React.createElement("div", { className: css(styles.sectionTitle) }, "\u30B7\u30EA\u30FC\u30BA\u306E\u697D\u3057\u307F\u65B9"),
                React.createElement("div", { className: css(styles.sectionTitleBorder) }),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/series/check_series.png", classObject: styles.howToDetail, imgClassObject: styles.checkSeriesImg, title: "\u597D\u304D\u306A\u30EC\u30C3\u30B9\u30F3\u306E\u30B7\u30EA\u30FC\u30BA\u3092\u30C1\u30A7\u30C3\u30AF" }, "\u30EC\u30C3\u30B9\u30F3\u30BF\u30A4\u30C8\u30EB\u306E\u4E0A\u306B\u3042\u308B\u9752\u3044\u6587\u5B57\u3092\u9078\u629E\u3059\u308B\u3068\u30B7\u30EA\u30FC\u30BA\u306B\u79FB\u52D5\u3057\u307E\u3059"),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/series/favorite_series.png", classObject: styles.howToDetail, imgClassObject: styles.faviroriteSeriesImg, title: "\u597D\u304D\u306A\u30B7\u30EA\u30FC\u30BA\u3092\u304A\u6C17\u306B\u5165\u308A\u3057\u3088\u3046" }, "\u597D\u304D\u306A\u30B7\u30EA\u30FC\u30BA\u304C\u898B\u3064\u304B\u3063\u305F\u3089\u30CF\u30FC\u30C8\u30DE\u30FC\u30AF\u3092\u62BC\u3057\u3066\u304A\u6C17\u306B\u5165\u308A\u3057\u307E\u3057\u3087\u3046\uFF01"),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/series/take_series_lesson.png", imgClassObject: styles.takeSeriesLessonImg, title: "\u540C\u3058\u30B7\u30EA\u30FC\u30BA\u306E\u30EC\u30C3\u30B9\u30F3\u3092\u53D7\u3051\u3066\u307F\u3088\u3046" }, "\u30B7\u30EA\u30FC\u30BA\u5185\u306E\u4ED6\u306E\u30EC\u30C3\u30B9\u30F3\u3082\u30C1\u30A7\u30C3\u30AF\u3057\u3066\u307F\u307E\u3057\u3087\u3046\u3002\u304A\u6C17\u306B\u5165\u308A\u304C\u898B\u3064\u304B\u308B\u304B\u3082\uFF01"))),
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u3088\u304F\u3042\u308B\u8CEA\u554F"),
            React.createElement("div", { className: css(styles.sectionTitleBorder) }),
            React.createElement(InitialGuideQA, { question: "\u304A\u6C17\u306B\u5165\u308A\u3057\u305F\u30B7\u30EA\u30FC\u30BA\u306F\u3069\u3053\u3067\u78BA\u8A8D\u3067\u304D\u307E\u3059\u304B\uFF1F" }, "MY\u30EC\u30C3\u30B9\u30F3\u306E\u201C\u304A\u6C17\u306B\u5165\u308A\u306E\u30EC\u30C3\u30B9\u30F3\u201D\u304B\u3089\u78BA\u8A8D\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002"))));
};
var styles = StyleSheet.create({
    section: (_a = {
            marginBottom: 40,
            paddingTop: 30,
            paddingLeft: 24,
            paddingRight: 24
        },
        _a[MediaBreakPointUp.MD] = {
            marginBottom: 0,
            paddingLeft: 40,
            paddingRight: 40,
        },
        _a),
    sectionTitle: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: '1.44px',
        height: 25,
    },
    sectionTitleBorder: {
        border: "3px solid ".concat(Colors.PrimaryDark),
        borderRadius: 3,
        width: 60,
        margin: '12px auto 20px',
    },
    topImgBox: {
        textAlign: 'center',
        marginTop: 35,
        marginBottom: 35,
    },
    topImg: {
        height: 116,
    },
    description: (_b = {
            backgroundColor: Colors.CyanLightest,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '160%',
            letterSpacing: '0.56px',
            padding: 24,
            marginBottom: 40,
            borderRadius: 10
        },
        _b[MediaBreakPointUp.MD] = {
            marginBottom: 10,
        },
        _b),
    howToSection: (_c = {},
        _c[MediaBreakPointUp.MD] = {
            marginLeft: 60,
            marginRight: 60,
        },
        _c),
    howToDetail: {
        marginBottom: 20,
    },
    checkSeriesImg: {
        height: 192,
    },
    faviroriteSeriesImg: {
        height: 228,
    },
    takeSeriesLessonImg: {
        height: 208,
    },
});
