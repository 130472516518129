var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MealHeader } from '../atoms/meal_header';
import { useQuery } from '@lean-body/src/util';
import * as dayjs from 'dayjs';
import { Colors, MediaBreakPointUp } from '../styles';
import { HintIcon } from '../atoms/icons/hint_icon';
import { ArrowDirection, ArrowIconWithMargin } from '../atoms';
import { ZENDESK_NUTRIENTS_JUDGEMENT } from '../constants';
import { NutrientFoodTable } from '../organisms/nutrient_food_table';
import { NutrientItems } from '../organisms/nutrient_items';
import { GrayBoxTabs, TabTypes } from '../molecules/gray_box_tabs';
import { PageLoading } from '../organisms';
export var MealNutrientTabs = {
    all: '全体',
    breakfast: '朝食',
    lunch: '昼食',
    dinner: '夕食',
    snacking: '間食',
};
export var NutrientPage = function (_a) {
    var config = _a.config, apiClient = _a.apiClient;
    var query = useQuery();
    var date = dayjs(query.get('date') || new Date());
    var _b = React.useState(MealNutrientTabs.all), tab = _b[0], setTab = _b[1];
    var _c = React.useState([]), nutrients = _c[0], setNutrients = _c[1];
    var _d = React.useState(true), isEntered = _d[0], setIsEntered = _d[1];
    React.useEffect(function () {
        var tabKey = Object.entries(MealNutrientTabs).find(function (_a) {
            var key = _a[0], value = _a[1];
            if (value === tab) {
                return key;
            }
        })[0];
        apiClient.fetchMealNutrients(date.toDate(), tabKey).then(function (v) {
            setNutrients(v.nutrients);
            setIsEntered(v.isEntered);
        });
    }, [tab]);
    if (!nutrients.length) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", null,
        React.createElement(MealHeader, null, date.format('M月D日(ddd) 全体 栄養バランス')),
        React.createElement("div", { className: css(styles.container) },
            React.createElement(GrayBoxTabs, { tab: tab, setTab: setTab, tabType: TabTypes.mealNutrient, classObject: styles.tabs }),
            React.createElement(NutrientItems, { nutrients: nutrients, isSnackingType: tab === MealNutrientTabs.snacking, isEntered: isEntered, isShowMessage: true }),
            React.createElement("a", { href: ZENDESK_NUTRIENTS_JUDGEMENT, className: css(styles.judgementLink), target: "_blank" },
                React.createElement(HintIcon, { classObject: styles.hintIcon }),
                React.createElement("p", null, "\u6804\u990A\u7D20\u306E\u5224\u5B9A\u57FA\u6E96\u306B\u3064\u3044\u3066"),
                React.createElement(ArrowIconWithMargin, { classObject: styles.arrow, direction: ArrowDirection.right, color: Colors.PrimaryDark })),
            React.createElement("div", { className: css(styles.nutrientFoodMessageTitle) }, "\u3053\u3093\u306A\u98DF\u3079\u7269\u306B\u542B\u307E\u308C\u3066\u3044\u307E\u3059"),
            React.createElement("div", { className: css(styles.nutrientFoodMessage) }, "\u4EE5\u4E0B\u306E\u98DF\u3079\u7269\u3092\u53C2\u8003\u306B\u8DB3\u308A\u306A\u3044\u6804\u990A\u7D20\u3092\u88DC\u3044\u3001\u98DF\u4E8B\u306E\u30D0\u30E9\u30F3\u30B9\u3092\u6574\u3048\u307E\u3057\u3087\u3046\uFF01"),
            React.createElement(NutrientFoodTable, { config: config }))));
};
var styles = StyleSheet.create({
    container: {
        maxWidth: 600,
        margin: '0 auto',
        padding: '0 16px',
    },
    tabs: (_a = {
            margin: '8px 0 24px'
        },
        _a[MediaBreakPointUp.XL] = {
            marginTop: 0,
        },
        _a),
    judgementLink: {
        fontSize: 12,
        fontWeight: 'bold',
        color: Colors.PrimaryDark,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        margin: '14px 0 36px',
    },
    hintIcon: {
        marginRight: 4,
    },
    arrow: {
        width: 22,
        height: 22,
    },
    nutrientFoodMessageTitle: {
        fontWeight: 'bold',
    },
    nutrientFoodMessage: {
        fontSize: 12,
        margin: '8px 0 12px',
    },
});
