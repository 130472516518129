import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var CourseRewardVolumeInfo = function (_a) {
    var courseName = _a.courseName, progressText = _a.progressText, volume = _a.volume, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.title) },
            React.createElement("span", { className: css(styles.courseName) }, courseName),
            React.createElement("br", null),
            "Vol.",
            React.createElement("span", { className: css(styles.volumeNumber) }, volume.volumeNumber),
            " ",
            volume.title),
        React.createElement("div", { className: css(styles.daysCount) }, progressText)));
};
var styles = StyleSheet.create({
    container: {},
    courseName: {
        fontWeight: 700,
        fontSize: 12,
        letterSpacing: '0.04em',
        color: Colors.Black,
        lineHeight: '160%',
    },
    title: {
        fontWeight: 700,
        fontSize: 16,
        letterSpacing: '0.04em',
        color: Colors.Black,
    },
    daysCount: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '160%',
        letterSpacing: '0.04em',
        color: Colors.GrayDarkest,
    },
    volumeNumber: {
        fontSize: 14,
    },
});
