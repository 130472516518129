var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { APIClient, LocalStorage } from '@lean-body/src/infra';
import { FBPixelClient, GAClient, CloudFunctionClient, TagClient, LambdaFunctionClient, AdlocateClient, } from '@lean-body/src/infra';
import { FBClient } from '@lean-body/src/infra/fb_client';
import { LINEClient } from '@lean-body/src/infra/line_client';
import { AppleClient } from '@lean-body/src/infra/apple_client';
import { Memoize } from '@lean-body/src/util';
import { AccountService } from './account';
import { ABTestService } from './ab_test';
import { AuthState, MeRepository, Tracker, AnnouncementService } from './index';
import { LessonService } from './lesson';
import { UserInvitationService } from './user_invitation';
import { routes } from '../route';
import { SignedCookieService } from './signed_cookie';
import { TwitterService } from './twitter';
// ドメインサービスをfactoryするクラス。外部からサービスを参照するときはこのクラス経由で参照する。
var DomainFactory = /** @class */ (function () {
    function DomainFactory(config, alertPresenter) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "fbClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lineClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "appleClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tagClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "fbPixelClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "adlocateClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "gaClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "localStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cloudFunctionClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lambdaFunctionClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "navigate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        if (config.isProdOrStg) {
            // stg or prodのみ有効な機能
            this.fbClient = new FBClient(config.fbAppId);
            this.fbPixelClient = new FBPixelClient(config.fbPixelId);
        }
        this.lineClient = new LINEClient(config);
        this.appleClient = new AppleClient();
        this.adlocateClient = new AdlocateClient(config);
        this.gaClient = new GAClient(config);
        this.localStorage = new LocalStorage();
        this.cloudFunctionClient = new CloudFunctionClient(config.bqCloudFunctionsEndpoint, config.bqCfAuthToken, this.localStorage);
        this.lambdaFunctionClient = new LambdaFunctionClient(config.awsLambdaEndpoint);
        this.config = config;
        this.apiClient = this.buildApiClient();
        this.alertPresenter = alertPresenter;
    }
    Object.defineProperty(DomainFactory.prototype, "initializeNavigate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (navigate) {
            this.navigate = navigate;
        }
    });
    Object.defineProperty(DomainFactory.prototype, "buildApiClient", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            var unauthorizedCallback = function () {
                _this.AuthState().setUnAuthed();
                _this.localStorage.saveLoginState(false);
                _this.navigate(routes.SIGNIN.compile());
            };
            var maintenanceCallback = function () {
                _this.navigate(routes.MAINTENANCE.compile());
            };
            return new APIClient(this.config, this.localStorage, unauthorizedCallback, maintenanceCallback);
        }
    });
    // ユーザーIDの必要なClientを初期化
    Object.defineProperty(DomainFactory.prototype, "buildWithUserID", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userID) {
            this.tagClient = new TagClient(this.config, userID);
            this.initializeTracker(userID, this.tagClient);
        }
    });
    Object.defineProperty(DomainFactory.prototype, "LessonService", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return new LessonService(this.apiClient, this.Tracker(), this.MeRepo(), this.config);
        }
    });
    Object.defineProperty(DomainFactory.prototype, "AccountService", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return new AccountService(this.AuthState(), this.apiClient, this.fbClient, this.lineClient, this.appleClient, this.localStorage, this.navigate, this.config);
        }
    });
    Object.defineProperty(DomainFactory.prototype, "ABTestService", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return new ABTestService(this.tagClient, this.localStorage);
        }
    });
    Object.defineProperty(DomainFactory.prototype, "Tracker", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.tracker;
        }
    });
    Object.defineProperty(DomainFactory.prototype, "AuthState", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return new AuthState();
        }
    });
    Object.defineProperty(DomainFactory.prototype, "MeRepo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return new MeRepository(this.apiClient, this.config);
        }
    });
    Object.defineProperty(DomainFactory.prototype, "AnnouncementService", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return new AnnouncementService(this.apiClient, this.config);
        }
    });
    Object.defineProperty(DomainFactory.prototype, "SignedCookieService", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return new SignedCookieService(this.apiClient);
        }
    });
    Object.defineProperty(DomainFactory.prototype, "TwitterService", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return new TwitterService(this.apiClient, this.alertPresenter, this.localStorage, this.Tracker(), this.navigate);
        }
    });
    Object.defineProperty(DomainFactory.prototype, "UserInvitationService", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return new UserInvitationService();
        }
    });
    Object.defineProperty(DomainFactory.prototype, "initializeTracker", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userId, tagClient) {
            this.tracker = new Tracker(this.localStorage, this.fbPixelClient, this.cloudFunctionClient, this.gaClient, this.lambdaFunctionClient, this.config);
            this.Tracker().setUserID(userId);
            this.Tracker().setTagClient(tagClient);
        }
    });
    __decorate([
        Memoize,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], DomainFactory.prototype, "LessonService", null);
    __decorate([
        Memoize,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], DomainFactory.prototype, "AccountService", null);
    __decorate([
        Memoize,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], DomainFactory.prototype, "ABTestService", null);
    __decorate([
        Memoize,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], DomainFactory.prototype, "Tracker", null);
    __decorate([
        Memoize,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], DomainFactory.prototype, "AuthState", null);
    __decorate([
        Memoize,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], DomainFactory.prototype, "MeRepo", null);
    __decorate([
        Memoize,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], DomainFactory.prototype, "AnnouncementService", null);
    __decorate([
        Memoize,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], DomainFactory.prototype, "SignedCookieService", null);
    __decorate([
        Memoize,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", TwitterService)
    ], DomainFactory.prototype, "TwitterService", null);
    __decorate([
        Memoize,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], DomainFactory.prototype, "UserInvitationService", null);
    return DomainFactory;
}());
export { DomainFactory };
