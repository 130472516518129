import * as React from 'react';
import { useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Button } from '@lean-body/components/atoms';
import { LocalStorage } from '@lean-body/src/infra';
import { Colors } from '../styles';
import { isiOSWeb } from '@lean-body/src/util';
var iOS_STORE_LINK = 'https://apps.apple.com/jp/app/lean-body-%E3%83%AA%E3%83%BC%E3%83%B3%E3%83%9C%E3%83%87%E3%82%A3/id1494415297';
var ANDROID_STORE_LINK = 'https://play.google.com/store/apps/details?id=jp.lean_body.app';
export var storeLink = function () {
    return isiOSWeb() ? iOS_STORE_LINK : ANDROID_STORE_LINK;
};
export var StoreBanner = function (props) {
    var localStorage = new LocalStorage();
    var _a = useState(localStorage.loadHideAppstore), hidden = _a[0], setHidden = _a[1];
    var iOSIconSrc = '/assets/images/icons/ios_app.png';
    var onClickHideButton = function () {
        setHidden(true);
        props.onClickHideButton();
    };
    return (React.createElement(React.Fragment, null, hidden ? null : (React.createElement("div", { className: css(styles.wrapper, props.classObject) },
        React.createElement("div", { className: css(styles.container) },
            React.createElement("div", { className: css(styles.iOSIconWrapper) },
                React.createElement("img", { className: css(styles.iOSIcon), src: iOSIconSrc })),
            React.createElement("div", { className: css(styles.title) },
                React.createElement("p", null, "\u30A2\u30D7\u30EA\u3092\u4F7F\u3063\u3066\u3082\u3063\u3068\u4FBF\u5229\u306B\u3001\u5FEB\u9069\u306B")),
            React.createElement("div", { className: css(styles.sentence) },
                React.createElement("p", null, "\u30D5\u30A3\u30C3\u30C8\u30CD\u30B9\u3092\u3082\u3063\u3068\u8EAB\u8FD1\u306B\u3057\u307E\u305B\u3093\u304B\uFF1F\u30A2\u30D7\u30EA\u3060\u3051\u306E\u6A5F\u80FD\u3067\u5FEB\u9069\u306B\u904B\u52D5\u3092\u697D\u3057\u307F\u307E\u3057\u3087\u3046\u3002")),
            React.createElement("div", { className: css(styles.buttons) },
                React.createElement(Button, { className: css(styles.closeButton), onClick: onClickHideButton }, "\u4ECA\u306F\u3057\u306A\u3044"),
                React.createElement("a", { href: storeLink(), target: "_blank" },
                    React.createElement(Button, { className: css(styles.downloadButton) }, "\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9"))))))));
};
var styles = StyleSheet.create({
    wrapper: {
        width: '101%',
    },
    container: {
        padding: '33px 25px 21px 25px',
        background: "linear-gradient(90deg, ".concat(Colors.PrimaryDark, " 0%, ").concat(Colors.PrimaryLight, " 100%)"),
        position: 'relative',
    },
    title: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '140%',
        letterSpacing: '0.04em',
        color: Colors.White,
    },
    sentence: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '160%',
        color: Colors.White,
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 14,
    },
    closeButton: {
        display: 'flex',
        alignItems: 'center',
        padding: 15,
        height: 40,
        borderRadius: 20,
        fontWeight: 700,
        fontSize: 14,
        background: 'transparent',
        color: Colors.White,
        border: "1px solid ".concat(Colors.White),
    },
    downloadButton: {
        display: 'flex',
        alignItems: 'center',
        padding: 15,
        color: Colors.PrimaryDark,
        height: 40,
        borderRadius: 20,
        fontWeight: 700,
        fontSize: 14,
        border: '0px solid transparent',
    },
    iOSIconWrapper: {
        position: 'absolute',
        top: -25,
        right: '45%',
    },
    iOSIcon: {
        width: 48,
        height: 48,
        borderRadius: '50%',
        border: "4px solid ".concat(Colors.PrimaryDark),
    },
});
