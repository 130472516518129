import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
var ViewCount = function (_a) {
    var viewCount = _a.viewCount, className = _a.className;
    var viewCountClass = [css(styles.wrapper), className].join(' ');
    var viewCountString = !viewCount ? null : viewCount > 99 ? '99+' : viewCount.toString();
    // 0やundefinedは表示しない
    if (!viewCount)
        return null;
    return (React.createElement("div", { className: viewCountClass },
        React.createElement("img", { src: "/assets/images/icons/view_count.svg", className: css(styles.icon) }),
        React.createElement("span", { className: css(styles.viewCount) }, viewCountString)));
};
var styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 17,
        lineHeight: 17,
        padding: '4px 6px',
        backgroundColor: 'rgba(0,0,0,0.4)',
        borderRadius: 8,
    },
    icon: {
        width: 9,
    },
    viewCount: {
        marginLeft: 4,
        fontSize: 10,
        fontWeight: 'bold',
        color: Colors.White,
    },
});
export default ViewCount;
