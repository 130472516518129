var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { FAVORITE_LIMIT_PER_PAGE, FAVORITE_PAGE_INIT, HISTORY_LIMIT_PER_PAGE, HISTORY_PAGE_INIT, SEARCH_LIMIT_PER_PAGE, SEARCH_PAGE_INIT, } from '@lean-body/src/domain';
import { Dialog } from '@lean-body/components/molecules';
import { SearchInputNoButton } from '@lean-body/components/molecules/search_input_no_button';
import { SelectLessonCard } from '@lean-body/components/molecules/select_lesson_card';
import { CloseButtonLabel } from '@lean-body/components/atoms/dialog_close_button';
import { Tabs, Tab } from '@mui/material';
import SearchKeyword from './search/search_keyword';
import { PaginationNextButton } from '../atoms/pagination_next_button';
var TAB_KEYS;
(function (TAB_KEYS) {
    TAB_KEYS[TAB_KEYS["search"] = 0] = "search";
    TAB_KEYS[TAB_KEYS["favorite"] = 1] = "favorite";
    TAB_KEYS[TAB_KEYS["history"] = 2] = "history";
})(TAB_KEYS || (TAB_KEYS = {}));
var TABS_PARAMS = [
    { key: TAB_KEYS.favorite, text: 'お気に入り', pageInit: FAVORITE_PAGE_INIT },
    { key: TAB_KEYS.history, text: '視聴履歴', pageInit: HISTORY_PAGE_INIT },
    { key: TAB_KEYS.search, text: '検索', pageInit: SEARCH_PAGE_INIT },
];
var SELECT_LIMIT = 42;
export var SelectLessonDialog = function (_a) {
    var alreadySelectedLessons = _a.alreadySelectedLessons, submit = _a.submit, closeDialog = _a.closeDialog, fetchFavoriteLessons = _a.fetchFavoriteLessons, searchLessons = _a.searchLessons, fetchLessonHistory = _a.fetchLessonHistory, clearSearchedLessons = _a.clearSearchedLessons, getSearchKeywords = _a.getSearchKeywords;
    var initTab = TABS_PARAMS[0].key;
    var _b = React.useState([]), fetchedLessons = _b[0], setFetchedLesson = _b[1];
    var _c = React.useState(initTab), selectedTab = _c[0], setSelectedTab = _c[1];
    var _d = React.useState([]), selectedLessons = _d[0], setSelectedLessons = _d[1];
    var _e = React.useState(''), searchedKey = _e[0], setSearchedKey = _e[1];
    var _f = React.useState(null), keywords = _f[0], setKeywords = _f[1];
    var _g = React.useState(TABS_PARAMS[0].pageInit), page = _g[0], setPage = _g[1];
    var _h = React.useState(false), isLastPage = _h[0], setIsLastPage = _h[1];
    var isSelected = function (lesson) {
        return selectedLessons.map(function (v) { return v.id; }).includes(lesson.id);
    };
    var selectLesson = function (lesson) {
        if (selectedLessons.length > SELECT_LIMIT)
            return;
        if (isSelected(lesson)) {
            setSelectedLessons(selectedLessons.filter(function (v) { return v.id !== lesson.id; }));
        }
        else {
            setSelectedLessons(__spreadArray(__spreadArray([], selectedLessons, true), [lesson], false));
        }
    };
    var alreadySelectedLessonIds = alreadySelectedLessons.map(function (v) { return v.id; });
    var setLessons = function (lessons) {
        setFetchedLesson(lessons.filter(function (v) { return !alreadySelectedLessonIds.includes(v.id); }));
    };
    var requestLessons = function (key, page, currentLessons) { return __awaiter(void 0, void 0, void 0, function () {
        var lessons, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = key;
                    switch (_a) {
                        case TAB_KEYS.search: return [3 /*break*/, 1];
                        case TAB_KEYS.favorite: return [3 /*break*/, 4];
                        case TAB_KEYS.history: return [3 /*break*/, 6];
                    }
                    return [3 /*break*/, 8];
                case 1:
                    getSearchKeywords().then(function (v) { return setKeywords(v); });
                    if (!(searchedKey !== '')) return [3 /*break*/, 3];
                    return [4 /*yield*/, searchLessons(page, searchedKey)];
                case 2:
                    // 空文字では検索しないようにする
                    lessons = _b.sent();
                    setIsLastPage(lessons.length % SEARCH_LIMIT_PER_PAGE !== 0);
                    _b.label = 3;
                case 3: return [3 /*break*/, 8];
                case 4: return [4 /*yield*/, fetchFavoriteLessons(page)];
                case 5:
                    lessons = _b.sent();
                    setIsLastPage(lessons.length % FAVORITE_LIMIT_PER_PAGE !== 0);
                    return [3 /*break*/, 8];
                case 6: return [4 /*yield*/, fetchLessonHistory(page)];
                case 7:
                    lessons = _b.sent();
                    setIsLastPage(lessons.length % HISTORY_LIMIT_PER_PAGE !== 0);
                    return [3 /*break*/, 8];
                case 8:
                    setPage(page);
                    if (lessons)
                        setLessons(currentLessons.concat(lessons));
                    return [2 /*return*/];
            }
        });
    }); };
    var handleChange = function (_, key) {
        setSelectedTab(key);
        setFetchedLesson([]); // reset fetchedLessons
        var pageInit = TABS_PARAMS.find(function (v) { return v.key === key; }).pageInit;
        requestLessons(key, pageInit, []);
    };
    var search = function (key) {
        setPage(SEARCH_PAGE_INIT);
        searchLessons(SEARCH_PAGE_INIT, key).then(function (lessons) {
            setLessons(lessons);
            setIsLastPage(lessons.length % SEARCH_LIMIT_PER_PAGE !== 0);
        });
        setSearchedKey(key);
    };
    var submitAndClose = function () {
        submit(selectedLessons);
        closeDialog();
    };
    React.useEffect(function () {
        handleChange(null, initTab); // 最初にアクティブになってるタブをfetch
        return clearSearchedLessons; // 検索結果のみ閉じる際にキャッシュを消す
    }, []);
    return (React.createElement(Dialog, { closeButtonLabel: CloseButtonLabel.Cancel, onClickCancel: closeDialog, classObject: styles.dialogContainer, content: React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.header) },
                React.createElement("div", { className: css(styles.headerTitle) }, "\u30EC\u30C3\u30B9\u30F3\u3092\u9078\u629E"),
                React.createElement("div", { onClick: submitAndClose, className: css(styles.submit, !!selectedLessons.length && styles.submitActive) },
                    "\u8FFD\u52A0",
                    !!selectedLessons.length && "(".concat(selectedLessons.length, ")"))),
            React.createElement("div", { className: css(styles.content) },
                React.createElement(Tabs, { value: selectedTab, onChange: handleChange, variant: "fullWidth", classes: {
                        root: css(styles.tabs),
                        flexContainer: css(styles.tabFlexContainer),
                        indicator: css(styles.tabIndicator),
                    } }, TABS_PARAMS.map(function (v, i) { return (React.createElement(Tab, { label: v.text, key: i, value: v.key, classes: {
                        root: css(styles.tab),
                        selected: css(styles.selectedTab),
                    } })); })),
                selectedTab === TAB_KEYS.search && (React.createElement(React.Fragment, null,
                    React.createElement(SearchInputNoButton, { classObject: styles.search, onChange: search, inputValueMaster: searchedKey, setInputValueMaster: setSearchedKey, clear: function () { return setFetchedLesson([]); } }),
                    !searchedKey && !fetchedLessons.length && keywords && (React.createElement(SearchKeyword, { title: "\u4EBA\u6C17\u306E\u30AD\u30FC\u30EF\u30FC\u30C9", keywords: keywords, onClickKeyword: search, keywordClassObject: styles.keyword })))),
                React.createElement("div", { className: css(styles.lessonsContainer) },
                    React.createElement("div", { className: css(styles.lessons) },
                        fetchedLessons.map(function (v, i) { return (React.createElement(SelectLessonCard, { lesson: v, key: i, onClick: function () { return selectLesson(v); }, isSelected: isSelected(v) })); }),
                        React.createElement(PaginationNextButton, { classObject: styles.nextButton, getNextPage: function () { return requestLessons(selectedTab, page + 1, fetchedLessons); }, isAvailable: !!fetchedLessons.length && !isLastPage }))))) }));
};
var styles = StyleSheet.create({
    dialogContainer: {
        top: 48,
        overflow: 'hidden',
        transform: 'translateX(-50%)',
    },
    header: {
        padding: '22px 24px 15px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        backgroundColor: Colors.White,
        borderBottom: "1px solid ".concat(Colors.BorderLightGrayB),
    },
    headerTitle: {
        position: 'absolute',
        top: 20,
        left: '50%',
        transform: 'translate(-50%, 0)',
    },
    submit: {
        marginLeft: 'auto',
        fontSize: 14,
        color: Colors.Gray,
    },
    submitActive: {
        cursor: 'pointer',
        color: Colors.Black,
    },
    content: (_a = {
            padding: '16px 14px 24px'
        },
        _a[MediaBreakPointUp.XL] = {
            padding: '24px 110px 40px',
        },
        _a),
    tabIndicator: {
        backgroundColor: "".concat(Colors.PrimaryDark),
    },
    tabs: {
        color: "".concat(Colors.GrayDarkest),
        margin: '0 20px 24px',
        minHeight: 'unset',
    },
    tabFlexContainer: {
        borderBottom: "1px solid ".concat(Colors.BorderLightGray),
    },
    tab: {
        padding: '7px 0',
        minHeight: 'unset',
        minWidth: 'unset',
    },
    selectedTab: {
        color: "".concat(Colors.PrimaryDark),
        fontWeight: 'normal',
    },
    lessonsContainer: {
        maxHeight: '55vh',
        overflow: 'scroll',
    },
    lessons: {
        margin: '0 10px',
    },
    search: {
        marginBottom: 24,
    },
    keyword: {
        marginRight: 6,
    },
    nextButton: (_b = {
            margin: '24px 0 0'
        },
        _b[MediaBreakPointUp.MD] = {
            margin: '24px 0 0',
        },
        _b),
});
