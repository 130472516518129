var _a, _b, _c, _d, _e;
/* eslint-disable @typescript-eslint/no-namespace */
import { css, StyleSheet } from 'aphrodite/no-important';
import * as React from 'react';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { LoadingIndicator } from './loading_indicator';
export var ButtonType;
(function (ButtonType) {
    ButtonType[ButtonType["disabled"] = 0] = "disabled";
    ButtonType[ButtonType["primary"] = 1] = "primary";
    ButtonType[ButtonType["secondary"] = 2] = "secondary";
    ButtonType[ButtonType["lightPrimary"] = 3] = "lightPrimary";
    ButtonType[ButtonType["transparentPrimary"] = 4] = "transparentPrimary";
    ButtonType[ButtonType["disabledForever"] = 5] = "disabledForever";
    ButtonType[ButtonType["pink"] = 6] = "pink";
    ButtonType[ButtonType["whiteWithBlack"] = 7] = "whiteWithBlack";
    ButtonType[ButtonType["borderGray"] = 8] = "borderGray";
    ButtonType[ButtonType["twitter"] = 9] = "twitter";
    ButtonType[ButtonType["transparentBackgroundPrimary"] = 10] = "transparentBackgroundPrimary";
    ButtonType[ButtonType["alert"] = 11] = "alert";
    ButtonType[ButtonType["alertOutline"] = 12] = "alertOutline";
})(ButtonType || (ButtonType = {}));
export var Button = function (props) {
    var label = function () {
        var isWhite = props.buttonType === ButtonType.primary;
        if (!props.loading) {
            return props.children;
        }
        return React.createElement(LoadingIndicator, { isWhite: isWhite, className: css(styles.loadingIndicator) });
    };
    var onClick = function (event) {
        props.onClick && props.onClick(event);
    };
    var buttonTypeClass = function () {
        switch (props.buttonType) {
            case ButtonType.primary:
                return css(styles.primaryContainer);
            case ButtonType.disabled:
                return css(styles.grayContainer);
            case ButtonType.secondary:
                return css(styles.whiteContainer);
            case ButtonType.lightPrimary:
                return css(styles.bluelightContainer);
            case ButtonType.transparentPrimary:
                return css(styles.blueTransparentContainer);
            case ButtonType.alert:
                return css(styles.alert);
            case ButtonType.alertOutline:
                return css(styles.alertOutline);
            case ButtonType.disabledForever:
                return css(styles.grayLightContainer);
            case ButtonType.pink:
                return css(styles.pink);
            case ButtonType.whiteWithBlack:
                return css(styles.whiteWithBlack);
            case ButtonType.borderGray:
                return css(styles.borderGray);
            case ButtonType.twitter:
                return css(styles.twitter);
            case ButtonType.transparentBackgroundPrimary:
                return css(styles.transparentBackgroundContainer);
        }
    };
    var containerClass = [
        props.className || '',
        css(styles.container),
        props.isRadius && css(styles.radiousContainer),
        buttonTypeClass(),
        props.hasArrow ? css(styles.arrow) : '',
        props.fullWidth ? css(styles.fullWidth) : '',
    ].join(' ');
    return (React.createElement("button", { className: containerClass, disabled: props.disabled, onClick: onClick, "data-test": props.dataTest, id: props.id }, label()));
};
export var ButtonLayout;
(function (ButtonLayout) {
    ButtonLayout.LabelSizeRem = 0.875;
    ButtonLayout.LabelSizeRemLG = 1;
    ButtonLayout.Padding = '1rem 2rem';
    ButtonLayout.IndicatorSizeRem = 1.2;
    ButtonLayout.IndicatorSizeRemLG = 1.3;
    ButtonLayout.IndicatorVMargin = (ButtonLayout.LabelSizeRem - ButtonLayout.IndicatorSizeRem) / 2;
    ButtonLayout.IndicatorVMarginLG = (ButtonLayout.LabelSizeRemLG - ButtonLayout.IndicatorSizeRemLG) / 2;
})(ButtonLayout || (ButtonLayout = {}));
var styles = StyleSheet.create({
    container: (_a = {
            fontWeight: 600,
            position: 'relative',
            letterSpacing: 1,
            lineHeight: 1,
            textAlign: 'center',
            padding: ButtonLayout.Padding,
            border: "1px solid ".concat(Colors.Gray),
            color: Colors.Black,
            backgroundColor: Colors.White,
            transition: 'all .6s ease',
            borderRadius: '4px',
            cursor: 'pointer',
            outline: 'none',
            fontSize: "".concat(ButtonLayout.LabelSizeRem, "rem")
        },
        _a[MediaBreakPointUp.LG] = {
            fontSize: "".concat(ButtonLayout.LabelSizeRemLG, "rem"),
        },
        _a[':disabled'] = {
            ':hover': (_b = {},
                _b[MediaBreakPointUp.SM] = {
                    backgroundColor: Colors.White,
                    color: Colors.Black,
                },
                _b.cursor = 'pointer',
                _b),
        },
        _a[':hover'] = (_c = {},
            _c[MediaBreakPointUp.SM] = {
                backgroundColor: '#6bcbd2',
                color: Colors.White,
            },
            _c.cursor = 'pointer',
            _c),
        _a),
    radiousContainer: {
        borderRadius: 100,
    },
    primaryContainer: {
        background: Colors.GradationPrimary,
        backgroundSize: '330%',
        borderColor: Colors.Primary,
        color: Colors.White,
        ':hover': {
            background: Colors.GradationPrimary,
        },
        ':disabled': {
            ':hover': {
                background: Colors.GradationPrimary,
                color: Colors.White,
            },
        },
    },
    bluelightContainer: {
        background: Colors.BlueLighterB,
        backgroundSize: '330%',
        borderColor: Colors.BlueLighterB,
        color: Colors.Black,
        ':hover': {
            background: Colors.BlueLighterB,
            color: Colors.Black,
        },
        ':disabled': {
            ':hover': {
                background: Colors.BlueLighterB,
                color: Colors.Black,
            },
        },
    },
    blueTransparentContainer: {
        background: Colors.GradationPrimaryTransparent,
        backgroundSize: '330%',
        borderColor: 'unset',
        color: Colors.White,
        ':hover': {
            background: Colors.GradationPrimaryTransparent,
            color: Colors.White,
        },
        ':disabled': {
            ':hover': {
                background: Colors.GradationPrimaryTransparent,
                color: Colors.White,
            },
        },
    },
    whiteContainer: {
        backgroundColor: Colors.White,
        backgroundSize: '330%',
        color: Colors.Primary,
        borderColor: Colors.Primary,
        ':hover': {
            backgroundColor: Colors.White,
            color: Colors.Primary,
        },
        ':disabled': {
            ':hover': {
                backgroundColor: Colors.White,
                color: Colors.Primary,
            },
        },
    },
    transparentBackgroundContainer: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        backgroundSize: '330%',
        color: Colors.Primary,
        ':hover': {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            color: Colors.Primary,
        },
        ':disabled': {
            ':hover': {
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                color: Colors.Primary,
            },
        },
    },
    grayContainer: {
        backgroundColor: Colors.GrayDarkest,
        backgroundSize: '330%',
        borderColor: Colors.GrayDarkest,
        color: Colors.White,
        ':hover': {
            backgroundColor: Colors.GrayDarkest,
        },
        ':disabled': {
            ':hover': {
                backgroundColor: Colors.GrayDarkest,
                color: Colors.White,
            },
        },
    },
    grayLightContainer: {
        backgroundColor: Colors.Gray,
        backgroundSize: '330%',
        borderColor: Colors.Gray,
        color: Colors.White,
        ':hover': {
            backgroundColor: Colors.Gray,
        },
        ':disabled': {
            ':hover': {
                backgroundColor: Colors.Gray,
                color: Colors.White,
            },
        },
    },
    alert: {
        background: Colors.Alert,
        backgroundSize: '330%',
        borderColor: Colors.Alert,
        color: Colors.White,
        ':hover': {
            background: Colors.Alert,
        },
        ':disabled': {
            ':hover': {
                background: Colors.Alert,
                color: Colors.White,
            },
        },
    },
    alertOutline: {
        background: Colors.White,
        backgroundSize: '330%',
        borderColor: Colors.Alert,
        color: Colors.Alert,
        ':hover': {
            background: Colors.AlertLight,
            color: Colors.Alert,
        },
        ':disabled': {
            ':hover': {
                background: Colors.White,
                color: Colors.Alert,
            },
        },
    },
    pink: {
        backgroundColor: Colors.Accent,
        backgroundSize: '330%',
        borderColor: Colors.Accent,
        color: Colors.White,
        ':hover': {
            backgroundColor: Colors.Accent,
        },
        ':disabled': {
            ':hover': {
                backgroundColor: Colors.Accent,
                color: Colors.White,
            },
        },
    },
    whiteWithBlack: {
        backgroundColor: Colors.White,
        backgroundSize: '330%',
        borderColor: Colors.Gray,
        color: Colors.Black,
        ':hover': {
            backgroundColor: Colors.White,
            color: Colors.Black,
        },
        ':disabled': {
            ':hover': {
                backgroundColor: Colors.White,
                color: Colors.Black,
            },
        },
    },
    borderGray: {
        backgroundColor: 'transparent',
        backgroundSize: '330%',
        borderColor: Colors.Gray,
        color: Colors.White,
        ':hover': {
            backgroundColor: 'transparent',
            color: Colors.White,
        },
        ':disabled': {
            ':hover': {
                backgroundColor: 'transparent',
                color: Colors.White,
            },
        },
    },
    twitter: (_d = {
            backgroundColor: Colors.StrictBlack,
            backgroundSize: '330%',
            borderColor: Colors.StrictBlack,
            color: Colors.White,
            padding: '16px 22px',
            ':hover': {
                backgroundColor: 'transparent',
                color: Colors.White,
            },
            ':disabled': {
                ':hover': {
                    backgroundColor: 'transparent',
                    color: Colors.White,
                },
            }
        },
        _d[MediaBreakPointUp.XL] = {
            height: 60,
        },
        _d),
    loadingIndicator: (_e = {
            display: 'inline-block',
            marginTop: "".concat(ButtonLayout.IndicatorVMargin, "rem"),
            marginBottom: "".concat(ButtonLayout.IndicatorVMargin, "rem"),
            width: "".concat(ButtonLayout.IndicatorSizeRem, "rem")
        },
        _e[MediaBreakPointUp.LG] = {
            marginTop: "".concat(ButtonLayout.IndicatorVMarginLG, "rem"),
            marginBottom: "".concat(ButtonLayout.IndicatorVMarginLG, "rem"),
            width: "".concat(ButtonLayout.IndicatorSizeRemLG, "rem"),
        },
        _e),
    arrow: {
        ':before': {
            content: "'>'",
            position: 'absolute',
            right: 10,
            top: '47.5%',
            transform: 'translate(-50%, -50%)',
        },
    },
    fullWidth: {
        width: '100%',
    },
});
