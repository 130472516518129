import * as React from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import { useSmMediaQuery } from '@lean-body/src/util/media_query_hooks';
export var VideoPlayButton = function (props) {
    var isSM = useSmMediaQuery();
    var styles = StyleSheet.create({
        container: {
            position: 'relative',
            display: props.visible ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            width: 80,
            height: 80,
            transition: 'opacity 0.3s',
            ':hover': {
                opacity: 1,
            },
            transform: 'translateZ(1000px)',
            transformStyle: 'preserve-3d',
        },
        image: {
            width: '62px',
        },
        caption: {
            fontSize: '0.78em',
            letterSpacing: 2,
            position: 'absolute',
            left: 0,
            transform: 'translate(-50%, 0)',
            top: 76,
            width: 300,
            textShadow: '1px 0 4px #fff',
        },
        svg: {
            position: 'absolute',
            top: 'calc(50% - 40px)',
            right: 'calc(50% - 40px)',
            width: 80,
            height: 80,
            transform: 'rotateY(-180deg) rotateZ(-90deg)',
            transformStyle: 'preserve-3d',
        },
        circle: {
            strokeDasharray: isSM ? 201 : 288,
            strokeDashoffset: 0,
            strokeLinecap: 'round',
            strokeWidth: 5,
            stroke: 'white',
            fill: 'none',
        },
        circleAnimation: {
            animation: 'countdown 10s linear infinite reverse',
        },
        '@keyframes countdown': {
            to: {
                strokeDashoffset: isSM ? -201 : -288,
            },
        },
    });
    var containerClass = [props.className || '', css(styles.container)].join(' ');
    var circleClass;
    if (props.loading && props.loading.enable) {
        circleClass = [css(styles.circle), css(styles.circleAnimation)].join(' ');
    }
    else {
        circleClass = css(styles.circle);
    }
    return (React.createElement("div", { className: containerClass, onClick: props.onClick, "data-test": "playButton" },
        React.createElement("img", { className: css(styles.image), src: "/assets/images/icons/play_button.svg" }),
        props.caption && React.createElement("span", { className: css(styles.caption) }, props.caption),
        React.createElement("svg", { className: css(styles.svg), viewBox: "0 0 100 100", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("circle", { className: circleClass, r: isSM ? '32' : '46', cx: "50", cy: "50" }))));
};
