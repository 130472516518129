import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var VectorDirection = {
    right: 'right',
    left: 'left',
    bottom: 'bottom',
    top: 'top',
};
var VectorTransform = {
    top: 'rotate(0deg)',
    right: 'rotate(90deg)',
    bottom: 'rotate(180deg)',
    left: 'rotate(270deg)',
};
export var VectorIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 11,
            height: 15,
            transform: VectorTransform[props.direction || VectorDirection.top],
        },
    });
    var color = props.color || Colors.Accent;
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 11 15", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: color, d: "M0.482945 7.848L2.62321 7.848C2.88939 7.848 3.10526 8.04463 3.10526 8.28709L3.10526 14.4984C3.10526 14.741 3.32103 14.9375 3.58724 14.9375L7.41275 14.9375C7.67893 14.9375 7.89479 14.741 7.89479 14.4984L7.89479 8.28712C7.89479 8.04466 8.11069 7.84803 8.37687 7.84803L10.5172 7.84803C10.9154 7.84803 11.1418 7.43295 10.9013 7.14382L5.88427 1.11141C5.69137 0.87953 5.30863 0.87953 5.11584 1.11141L0.0986716 7.14379C-0.141811 7.43292 0.084623 7.848 0.482945 7.848Z" })));
};
