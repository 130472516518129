var _a, _b, _c, _d, _e, _f;
import * as React from 'react';
var useState = React.useState, useEffect = React.useEffect;
import { css, StyleSheet } from 'aphrodite';
import { useNavigate } from 'react-router-dom';
import { routes } from '@lean-body/src/route';
import { Course } from '@lean-body/src/domain';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { LoadingIndicator, CompletedAnimationIcon, InProgressAnimationIcon, ProgressDots, } from '@lean-body/components/atoms';
import { MatchingAssessmentButtons, TimeSelectForm, CourseWeekdaysScheduleEditor, CourseScheduleAssessment, TimeSelectFormat, } from '@lean-body/components/molecules';
import { CourseSelectList, CourseDetail } from '.';
var TIME_COMMENT_MORNING = '朝に運動して基礎代謝が上がった状態をつくると、1日の消費カロリーを増やすことができます';
var TIME_COMMENT_NOON = '昼〜夕方は体が温かくなっているため、脂肪燃焼に適しています';
var TIME_COMMENT_NIGHT = '夜に体をほぐすとリラックス効果があるため、質の良い睡眠につながります';
export var MatchingAssessmentCourseSection = function (_a) {
    var apiClient = _a.apiClient, config = _a.config, courses = _a.courses, back = _a.back, tracker = _a.tracker;
    var statuses = {
        creatingCourses: 1,
        completeCreatingCourses: 2,
        selectCourse: 3,
        showCourse: 4,
        editWeekdays: 5,
        editTime: 6,
        completed: 7,
    };
    var navigate = useNavigate();
    var _b = useState(statuses.creatingCourses), status = _b[0], setStatus = _b[1];
    var _c = React.useState([]), courseSchedules = _c[0], setCourseSchedules = _c[1];
    var _d = React.useState(TIME_COMMENT_MORNING), timeComment = _d[0], setTimeComment = _d[1];
    var _e = useState(courses[0]), selectedCourse = _e[0], setSelectedCourse = _e[1];
    var _f = React.useState(Course.getDefaultTime()), selectedTime = _f[0], setSelectedTime = _f[1];
    var _g = React.useState(false), visibleValidationMessage = _g[0], setDisibleValidationMessage = _g[1];
    useEffect(function () {
        apiClient.fetchCourseSchedules().then(function (v) {
            setCourseSchedules(v);
        });
        var timeoutId = setTimeout(function () {
            setStatus(statuses.completeCreatingCourses);
        }, 2000);
        return function () { return clearTimeout(timeoutId); };
    }, []);
    var onClickCourse = function (id) {
        apiClient.fetchCourse(id).then(function (v) {
            handleSelectCourse(id);
            setSelectedCourse(v);
            window.scrollTo({ top: 0 });
            setStatus(statuses.showCourse);
        });
    };
    var handleSelectCourse = function (id) {
        tracker.trackClickEvent({ category: 'Select Course', label: "courseID: ".concat(id) });
        var course = courses.find(function (course) { return course.id === id; });
        setSelectedCourse(course);
    };
    var onClickNext = function () {
        tracker.trackClickEvent({ category: 'Button Clicks', label: "Next Assessment, Current Assessment: ".concat(status) });
        if (status === statuses.editWeekdays) {
            if (!courseSchedules.find(function (v) { return v.isSelected; })) {
                return setDisibleValidationMessage(true);
            }
            else {
                setDisibleValidationMessage(false);
            }
        }
        if (status === statuses.selectCourse) {
            return setStatus(statuses.editWeekdays);
        }
        if (status === statuses.completed) {
            onChangeTime(courseSchedules, selectedTime);
            apiClient
                .patchCourseSchedules(courseSchedules)
                .then(function () {
                return apiClient.selectCourse(selectedCourse.id);
            })
                .then(function () { return navigate(routes.TOP.compile()); });
        }
        else {
            var next = Object.keys(statuses).find(function (key) { return statuses[key] === status + 1; });
            setStatus(statuses[next]);
        }
    };
    var onClickBack = function () {
        tracker.trackClickEvent({ category: 'Button Clicks', label: "Back Assessment, Current Assessment: ".concat(status) });
        if (status <= statuses.selectCourse) {
            return back();
        }
        if (status === statuses.showCourse || status === statuses.editWeekdays) {
            return setStatus(statuses.selectCourse);
        }
        var previous = Object.keys(statuses).find(function (key) { return statuses[key] === status - 1; });
        setStatus(statuses[previous]);
    };
    var onClickDay = function (clickedDay) {
        var newCourseSchedules = courseSchedules.map(function (courseSchedule) {
            if (clickedDay.dayOfWeek === courseSchedule.dayOfWeek) {
                clickedDay.isSelected = !clickedDay.isSelected;
                courseSchedule = clickedDay;
            }
            return courseSchedule;
        });
        setCourseSchedules(newCourseSchedules);
        tracker.trackClickEvent({
            category: 'Select Course Schedule WeekDay',
            label: "clickedDay: ".concat(clickedDay.dayOfWeekToKanji),
        });
    };
    var onChangeTime = function (courseSchedules, time) {
        setSelectedTime(time);
        var hour = Number(time.split(':')[0]);
        if (hour >= 4 && hour < 11) {
            setTimeComment(TIME_COMMENT_MORNING);
        }
        else if (hour >= 11 && hour < 19) {
            setTimeComment(TIME_COMMENT_NOON);
        }
        else {
            setTimeComment(TIME_COMMENT_NIGHT);
        }
        setCourseSchedules(courseSchedules.map(function (courseSchedule) {
            courseSchedule.changeTime(time);
            return courseSchedule;
        }));
        tracker.trackClickEvent({
            category: 'Select Course Schedule Time',
            label: "time: ".concat(time),
        });
    };
    return (React.createElement(React.Fragment, null,
        status >= statuses.editWeekdays && (React.createElement(ProgressDots, { length: 3, currentIndex: 3, classObject: styles.headContainer })),
        status === statuses.creatingCourses ? (React.createElement("div", { className: css(styles.container) },
            React.createElement("div", { className: css(styles.titleBox) },
                React.createElement("div", { className: css(styles.title) }, "\u3042\u306A\u305F\u306B\u304A\u3059\u3059\u3081\u306E\u30B3\u30FC\u30B9\u3092\u4F5C\u6210\u4E2D\u2026"),
                React.createElement(InProgressAnimationIcon, { classObject: styles.inProgressIcon })))) : status === statuses.completeCreatingCourses ? (React.createElement("div", { className: css(styles.container) },
            React.createElement("div", { className: css(styles.titleBox) },
                React.createElement("div", { className: css(styles.title) }, "\u30B3\u30FC\u30B9\u304C\u5B8C\u6210\u3057\u307E\u3057\u305F\uFF01"),
                React.createElement(CompletedAnimationIcon, { className: css(styles.completedIcon) })))) : status === statuses.selectCourse ? (React.createElement("div", { className: css(styles.courseSelectListContainer) },
            React.createElement("div", { className: css(styles.courseSelectListTitle) }, "\u3069\u306E\u30B3\u30FC\u30B9\u306B\u53D6\u308A\u7D44\u307F\u307E\u3059\u304B\uFF1F"),
            React.createElement(CourseSelectList, { courses: courses, config: config, selectedCourseID: selectedCourse.id, onClickCourse: onClickCourse, onClickSelect: handleSelectCourse }))) : status === statuses.showCourse ? (React.createElement(CourseDetail, { course: selectedCourse, apiClient: apiClient, config: config, classObject: styles.courseDetailContainer })) : status === statuses.editWeekdays ? (React.createElement(CourseScheduleAssessment, { title: "\u4F55\u66DC\u65E5\u306B\u30EC\u30C3\u30B9\u30F3\u3092\u53D7\u8B1B\u3057\u307E\u3059\u304B\uFF1F", classObject: styles.editWeekdaysContainer },
            React.createElement(CourseWeekdaysScheduleEditor, { courseSchedules: courseSchedules, onClickDay: onClickDay }),
            visibleValidationMessage && React.createElement("div", { className: css(styles.validateWarning) }, "\u66DC\u65E5\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002"))) : status === statuses.editTime ? (React.createElement(CourseScheduleAssessment, { title: "\u4F55\u6642\u3054\u308D\u30EC\u30C3\u30B9\u30F3\u3092\u53D7\u8B1B\u3059\u308B\u3053\u3068\u304C\u591A\u3044\u3067\u3059\u304B\uFF1F", classObject: styles.editTimeContainer },
            React.createElement("div", { className: css(styles.warning) }, timeComment),
            React.createElement("div", { className: css(styles.dayOfWeekTimeSettingWrapper) },
                React.createElement(TimeSelectForm, { containerClassObject: styles.timeSelect, currentValue: selectedTime, setCurrentValue: function (v) { return onChangeTime(courseSchedules, v); }, label: "", format: TimeSelectFormat.hhmm })))) : status === statuses.completed ? (React.createElement("div", { className: css(styles.scheduleContainer), "data-test": "matchingAssessmentResult" },
            React.createElement("div", { className: css(styles.titleBox) },
                React.createElement("div", { className: css(styles.title) }, "\u8A2D\u5B9A\u304A\u3064\u304B\u308C\u3055\u307E\u3067\u3057\u305F\uFF01")),
            React.createElement("div", { className: css(styles.completedTextWrapper) },
                React.createElement("div", { className: css(styles.completedText) },
                    "\u8A2D\u5B9A\u306F\u3053\u308C\u3067\u7D42\u308F\u308A\u3067\u3059",
                    React.createElement("br", null),
                    "\u30D5\u30A3\u30C3\u30C8\u30CD\u30B9\u3092\u4E00\u7DD2\u306B\u697D\u3057\u307F\u307E\u3057\u3087\u3046\uFF01")))) : (React.createElement(LoadingIndicator, { size: 50 })),
        React.createElement("div", { className: css(styles.container) }, status === statuses.showCourse ? (React.createElement(MatchingAssessmentButtons, { classObject: styles.buttonsContainer, nextButtonText: '選択して次へ', nextButtonClickable: true, onClickNext: function () { return onClickNext(); }, onClickBack: function () { return onClickBack(); } })) : !(status === statuses.creatingCourses) ? (React.createElement(MatchingAssessmentButtons, { classObject: styles.buttonsContainer, nextButtonText: '次へ', nextButtonClickable: true, onClickNext: function () { return onClickNext(); }, onClickBack: function () { return onClickBack(); } })) : (React.createElement(React.Fragment, null)))));
};
var styles = StyleSheet.create({
    container: (_a = {
            paddingTop: 166,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            maxWidth: '100vw',
            margin: '0 auto'
        },
        _a[MediaBreakPointUp.XL] = {
            maxWidth: 800,
        },
        _a),
    titleBox: {
        maxWidth: 265,
        margin: '0 auto',
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        textAlign: 'center',
        letterSpacing: '0.08em',
        color: Colors.Black,
        marginTop: 10,
    },
    inProgressIcon: {
        margin: '40px auto 0px auto',
        width: 94,
    },
    completedIcon: {
        margin: '42px auto 0px auto',
        width: 142,
    },
    courseSelectListTitle: {
        maxWidth: 265,
        margin: '10px auto 30px',
        fontWeight: 700,
        fontSize: 24,
        textAlign: 'center',
        letterSpacing: '0.08em',
        color: Colors.Black,
    },
    courseSelectListContainer: (_b = {
            paddingTop: 73,
            margin: '0 auto'
        },
        _b[MediaBreakPointUp.MD] = {
            maxWidth: 700,
        },
        _b[MediaBreakPointUp.XL] = {
            maxWidth: 800,
        },
        _b),
    buttonsContainer: {
        width: '100%',
    },
    courseDetailContainer: (_c = {
            maxWidth: '100vw',
            margin: '0 auto',
            minHeight: '100vh',
            paddingTop: 50
        },
        _c[MediaBreakPointUp.MD] = {
            maxWidth: 480,
        },
        _c[MediaBreakPointUp.XL] = {
            maxWidth: 800,
        },
        _c),
    headContainer: (_d = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0 5px',
            paddingTop: 80
        },
        _d[MediaBreakPointUp.MD] = {
            paddingTop: 120,
        },
        _d),
    warning: {
        fontWeight: 400,
        fontSize: 14,
        textAlign: 'center',
        letterSpacing: '0.04em',
        color: Colors.Accent,
        margin: '14px 12px 47px 12px',
    },
    dayOfWeekWrapper: {
        display: 'flex',
        justifyContent: 'center',
        gap: 9,
    },
    dayOfWeek: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
    },
    dayOfWeekInactive: {
        border: "1px solid ".concat(Colors.Gray),
        color: Colors.GrayDarkest,
    },
    dayOfWeekActive: {
        backgroundColor: Colors.PrimaryDark,
        color: Colors.White,
    },
    dayOfWeekTimeSettingWrapper: {
        margin: '40px 4px 30px',
        overflow: 'auto',
        maxHeight: 300,
    },
    timeSelect: {
        width: '100%',
    },
    backButton: {
        marginTop: 20,
        color: Colors.Black,
        cursor: 'pointer',
    },
    scheduleContainer: {
        margin: '105px auto',
    },
    editWeekdaysContainer: {
        width: 327,
        margin: '105px auto',
    },
    editTimeContainer: (_e = {
            margin: '105px auto',
            width: 327
        },
        _e[MediaBreakPointUp.MD] = {
            width: 500,
        },
        _e),
    resultContainer: {
        width: 335,
        paddingBottom: 150,
    },
    resultTitleBox: {
        width: 274,
    },
    resultDayOfWeekWrapper: {
        margin: '20px 0',
    },
    choiceContainer: (_f = {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            margin: '20px 0',
            flexDirection: 'row',
            gap: '0 8px',
            alignItems: 'flex-start'
        },
        _f[MediaBreakPointUp.MD] = {
            maxWidth: 500,
        },
        _f),
    selectedChoice: {
        height: 60,
        width: 147,
        padding: 10,
        marginBottom: 8,
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        border: "1px ".concat(Colors.Gray, " solid"),
        color: Colors.White,
        backgroundColor: Colors.PrimaryDark,
        borderColor: Colors.PrimaryDark,
    },
    choiceName: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '140%',
    },
    resultText: {
        fontWeight: 700,
        fontSize: 16,
    },
    completedTextWrapper: {
        marginTop: 20,
    },
    completedText: {
        fontWeight: 400,
        fontSize: 16,
        color: Colors.GrayDarkest,
    },
    validateWarning: {
        fontWeight: 400,
        fontSize: 14,
        textAlign: 'center',
        letterSpacing: '0.04em',
        color: Colors.Accent,
        margin: 12,
    },
});
