var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
export var InputButtonTypeCheckbox = function (props) {
    var classObject = props.classObject, labelContainerclassObject = props.labelContainerclassObject, items = props.items, currentValue = props.currentValue, label = props.label, setCurrentValue = props.setCurrentValue, onClickButton = props.onClickButton;
    var onClick = function (value) {
        var values;
        if (currentValue.includes(value)) {
            values = currentValue.filter(function (v) { return v !== value; });
        }
        else {
            values = __spreadArray(__spreadArray([], currentValue, true), [value], false);
        }
        setCurrentValue(values);
        if (onClickButton) {
            onClickButton(value);
        }
    };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        label && React.createElement("div", { className: css(styles.label) }, label),
        items.map(function (_a, index) {
            var name = _a.name, value = _a.value;
            return (React.createElement("div", { className: css(styles.labelContainer, labelContainerclassObject), key: index },
                React.createElement("div", { className: css(styles.checkbox, currentValue.includes(value) ? styles.checkboxOn : styles.checkboxOff), onClick: function () { return onClick(value); } },
                    React.createElement("div", { className: css(styles.titleText) }, name))));
        })));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    labelContainer: {
        cursor: 'pointer',
        marginBottom: 8,
    },
    label: {
        width: '100%',
        color: Colors.GrayDarkest,
        marginBottom: 8,
    },
    checkbox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
        borderRadius: 4,
    },
    checkboxOn: {
        color: Colors.White,
        backgroundColor: Colors.PrimaryDark,
        border: "1px solid ".concat(Colors.PrimaryDark),
    },
    checkboxOff: {
        color: Colors.GrayDarkest,
        border: "1px solid ".concat(Colors.Gray),
    },
    titleText: {
        fontSize: 12,
        fontWeight: 'bold',
    },
});
