import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '../../styles/variables';
export var BellIcon = function (props) {
    var classObject = props.classObject, color = props.color;
    var styles = StyleSheet.create({
        container: {
            display: 'block',
            position: 'relative',
            width: 24,
            height: 25,
        },
        icon: {
            width: '100%',
            height: '100%',
        },
        iconBadge: {
            position: 'absolute',
            top: -2,
            right: 0.5,
            width: 8,
            height: 8,
            backgroundColor: Colors.Accent,
            borderRadius: 100,
        },
    });
    var iconColor = color || Colors.GrayDarkest;
    return (React.createElement("span", { className: css(styles.container, classObject) },
        React.createElement("svg", { className: css(styles.icon), viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fill: iconColor, fillRule: "evenodd", clipRule: "evenodd", d: "M12.0001 0.349727C6.98152 0.349727 2.91008 4.42117 2.91008 9.43973V16.355L0.557609 19.2774C0.287263 19.6133 0.233699 20.0745 0.41988 20.4634C0.606061 20.8523 0.998926 21.0997 1.43007 21.0997H7.33177C7.83702 23.2091 9.73709 24.7797 12.0001 24.7797C14.2627 24.7797 16.1649 23.216 16.6692 21.0997H22.5701C23.0012 21.0997 23.3941 20.8523 23.5803 20.4634C23.7665 20.0745 23.7129 19.6133 23.4425 19.2774L21.0901 16.355V9.43973C21.0901 4.42117 17.0186 0.349727 12.0001 0.349727ZM14.3038 21.0997H9.69837C10.1146 21.9515 10.99 22.5397 12.0001 22.5397C13.0129 22.5397 13.8888 21.954 14.3038 21.0997ZM15.6822 18.8597H20.2308L19.0976 17.452C18.9374 17.253 18.8501 17.0052 18.8501 16.7497V9.43973C18.8501 5.65829 15.7815 2.58973 12.0001 2.58973C8.21864 2.58973 5.15008 5.65829 5.15008 9.43973V16.7497C5.15008 17.0052 5.06273 17.253 4.90254 17.452L3.76939 18.8597H8.318C8.31869 18.8597 8.31939 18.8597 8.32008 18.8597H15.6801C15.6808 18.8597 15.6815 18.8597 15.6822 18.8597Z" })),
        props.hasBadge && React.createElement("div", { className: css(styles.iconBadge) })));
};
