import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var MealScoreMiddleIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 20,
            height: 20,
        },
    });
    var iconColor = color || Colors.BackgroundGrayD;
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 21 21", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M19.6085 5.45451C18.0108 2.78408 15.6487 1.18161 12.582 0.629307C12.2563 0.570674 11.925 0.542404 11.5962 0.5H10.3424C9.98221 0.550257 9.61942 0.587426 9.26186 0.652865C6.40506 1.17481 4.17542 2.65791 2.56719 5.06764C1.62173 6.48426 1.10293 8.05532 0.969957 9.75359C0.965769 9.80437 0.948494 9.85463 0.9375 9.90489V10.9236C0.989851 11.3231 1.02964 11.7251 1.09612 12.122C1.56571 14.9165 2.87083 17.1969 5.24914 18.8014C6.50714 19.6501 7.89968 20.1589 9.40007 20.3777C11.1847 20.6384 12.9353 20.4913 14.6205 19.8485C17.9265 18.5873 19.9326 16.1828 20.722 12.7517C21.3152 10.1755 20.966 7.72237 19.6085 5.45347V5.45451ZM8.0405 7.19205C8.74096 7.191 9.29379 7.74383 9.29275 8.44376C9.2917 9.13951 8.72683 9.70281 8.03632 9.69705C7.35418 9.69129 6.79455 9.13166 6.78827 8.449C6.78146 7.75901 7.34581 7.1931 8.04103 7.19205H8.0405ZM13.9091 9.69705C13.2207 9.70857 12.65 9.14475 12.6464 8.45005C12.6427 7.74906 13.1924 7.19362 13.8918 7.19152C14.5881 7.18943 15.1519 7.74959 15.1514 8.44219C15.1503 9.12328 14.5938 9.68553 13.9096 9.69653L13.9091 9.69705ZM14.0562 14.5584H7.94837C7.49291 14.5584 7.12384 14.1893 7.12384 13.7338C7.12384 13.2784 7.49291 12.9093 7.94837 12.9093H14.0562C14.5116 12.9093 14.8807 13.2784 14.8807 13.7338C14.8807 14.1893 14.5116 14.5584 14.0562 14.5584Z", fill: iconColor })));
};
