import * as React from 'react';
import { ITEM_WIDTH_OVER_XL, ITEM_WIDTH_SP, VerticalListArrows, } from '@lean-body/components/molecules/vertical_list_arrows';
import { LessonComponent, LiveLessonComponent } from '@lean-body/components/molecules';
import { useXlMediaQuery } from '@lean-body/src/util';
export var LessonSessionComponentCarousel = function (_a) {
    var sessions = _a.sessions, classObject = _a.classObject, itemMarginRight = _a.itemMarginRight, itemsPadding = _a.itemsPadding, addFavoriteLesson = _a.addFavoriteLesson, unFavoriteLesson = _a.unFavoriteLesson, isMin = _a.isMin, disableHistoryPush = _a.disableHistoryPush;
    var isXL = useXlMediaQuery();
    var addFavorite = function (lesson) {
        lesson.isFavorite = true;
        addFavoriteLesson(lesson.id);
    };
    var unFavorite = function (lesson) {
        lesson.isFavorite = false;
        unFavoriteLesson(lesson.id);
    };
    return (React.createElement(VerticalListArrows, { classObject: classObject, itemsPadding: itemsPadding, itemMarginRight: itemMarginRight, itemSize: isMin ? ITEM_WIDTH_SP : ITEM_WIDTH_OVER_XL, items: sessions.map(function (session) { return (React.createElement(React.Fragment, null, session.lesson ? (React.createElement(LessonComponent, { lesson: session.lesson, addFavoriteLesson: function () { return addFavorite(session.lesson); }, unFavoriteLesson: function () { return unFavorite(session.lesson); }, isMin: isMin, disableHistoryPush: disableHistoryPush })) : (React.createElement(LiveLessonComponent, { liveLesson: session.liveLesson, isMin: !isXL, isVertical: false })))); }) }));
};
