import { NUTRIENT_LIST } from './nutrient';
var NutrientFood = /** @class */ (function () {
    function NutrientFood(config) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.config = config;
    }
    Object.defineProperty(NutrientFood.prototype, "imageURL", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (key) {
            return "".concat(this.config.cdnBaseURL, "/meals/nutrients_food/").concat(key, ".png");
        }
    });
    Object.defineProperty(NutrientFood.prototype, "nutrientFoods", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (key) {
            return this[key];
        }
    });
    Object.defineProperty(NutrientFood.prototype, "list", {
        get: function () {
            return NUTRIENT_LIST.filter(function (v) { return !['salt_amount', 'calorie'].includes(v.key); });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NutrientFood.prototype, "protein", {
        get: function () {
            return [
                { name: '肉類', key: 'meat' },
                { name: '魚介類', key: 'fish' },
                { name: '卵', key: 'egg' },
                { name: '納豆', key: 'natto' },
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NutrientFood.prototype, "lipid", {
        get: function () {
            return [
                { name: 'ﾊﾞﾀｰ・油類', key: 'butter_oils' },
                { name: '脂身肉', key: 'fatty_meat' },
                { name: '揚げ物', key: 'fried_food' },
                { name: '洋菓子', key: 'pastry' },
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NutrientFood.prototype, "carbohydrate", {
        get: function () {
            return [
                { name: 'ごはん', key: 'rice' },
                { name: 'パン', key: 'bread' },
                { name: '甘味類', key: 'sweets' },
                { name: 'いも類', key: 'potatoes' },
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NutrientFood.prototype, "calcium", {
        get: function () {
            return [
                { name: '牛乳', key: 'milk' },
                { name: 'チーズ', key: 'cheese' },
                { name: '小魚', key: 'small_fish' },
                { name: '大豆製品', key: 'soybean_products' },
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NutrientFood.prototype, "iron", {
        get: function () {
            return [
                { name: 'レバー', key: 'lever' },
                { name: 'あさり', key: 'clam' },
                { name: '赤身の魚', key: 'red_fleshed_fish' },
                { name: '赤身肉', key: 'lean_meat' },
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NutrientFood.prototype, "fiber", {
        get: function () {
            return [
                { name: 'ごぼう', key: 'burdock' },
                { name: 'だいこん', key: 'radish' },
                { name: 'きくらげ', key: 'wood_ear_mushroom' },
                { name: 'いも類', key: 'potatoes' },
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NutrientFood.prototype, "vitamin_a", {
        get: function () {
            return [
                { name: 'にんじん', key: 'carrot' },
                { name: 'ほうれん草', key: 'spinach' },
                { name: '卵黄', key: 'egg_yolk' },
                { name: 'かぼちゃ', key: 'pumpkin' },
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NutrientFood.prototype, "vitamin_c", {
        get: function () {
            return [
                { name: 'アセロラ', key: 'acerola' },
                { name: 'レモン', key: 'lemon' },
                { name: 'ﾌﾞﾛｯｺﾘｰ', key: 'broccoli' },
                { name: 'パプリカ', key: 'paprika' },
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NutrientFood.prototype, "vitamin_e", {
        get: function () {
            return [
                { name: 'アーモンド', key: 'almond' },
                { name: 'かぼちゃ', key: 'pumpkin' },
                { name: '鮭', key: 'salmon' },
                { name: 'アボカド', key: 'avocado' },
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NutrientFood.prototype, "vitamin_b1", {
        get: function () {
            return [
                { name: '赤身肉', key: 'lean_meat' },
                { name: '鮭', key: 'salmon' },
                { name: '玄米', key: 'brown_rice' },
                { name: '大豆製品', key: 'soybean_products' },
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NutrientFood.prototype, "vitamin_b2", {
        get: function () {
            return [
                { name: 'レバー', key: 'lever' },
                { name: '納豆', key: 'natto' },
                { name: '牛乳', key: 'milk' },
                { name: '卵', key: 'egg' },
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NutrientFood.prototype, "vitamin_d", {
        get: function () {
            return [
                { name: '鮭', key: 'salmon' },
                { name: 'いわし', key: 'sardines' },
                { name: 'まいたけ', key: 'maitake_mushroom' },
                { name: '卵', key: 'egg' },
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NutrientFood.prototype, "cholesterol", {
        get: function () {
            return [
                { name: 'レバー', key: 'lever' },
                { name: 'バター', key: 'butter' },
                { name: '卵', key: 'egg' },
                { name: '脂身肉', key: 'fatty_meat' },
            ];
        },
        enumerable: false,
        configurable: true
    });
    return NutrientFood;
}());
export { NutrientFood };
