import { CourseReward } from '.';
var Reward = /** @class */ (function () {
    function Reward(data, config) {
        Object.defineProperty(this, "course", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.course = new CourseReward(data.course, config);
    }
    Object.defineProperty(Reward.prototype, "lessonForRetake", {
        // リワード画面からユーザーにもう一度受講させるレッスン
        get: function () {
            if (!this.course.day || !this.course.day.courseDaysLessons) {
                return null;
            }
            return this.course.day.courseDaysLessons[0];
        },
        enumerable: false,
        configurable: true
    });
    return Reward;
}());
export { Reward };
