var _a, _b, _c;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Button, ButtonType } from '@lean-body/components/atoms';
export var GoalMatchingAssessmentStartPage = function (_a) {
    var isNewUser = _a.isNewUser, onClickNext = _a.onClickNext;
    return (React.createElement("div", { className: css(styles.container) },
        isNewUser ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.title) },
                "\u3088\u3046\u3053\u305D",
                React.createElement("br", null),
                "LEAN BODY\u3078"),
            React.createElement("div", { className: css(styles.introductionText) },
                "LEAN BODY\u3067\u306F\u3001\u5065\u5EB7\u306B\u95A2\u3059\u308B\u76EE\u6A19\u3084\u304A\u3059\u3059\u3081\u306E\u30EC\u30C3\u30B9\u30F3\u3092\u3042\u306A\u305F\u306B\u5408\u308F\u305B\u3066\u30AB\u30B9\u30BF\u30DE\u30A4\u30BA\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
                React.createElement("br", null),
                React.createElement("br", null),
                "\u6BCE\u65E5\u306E\u5065\u5EB7\u7BA1\u7406\u306B\u5F79\u7ACB\u3066\u307E\u3057\u3087\u3046\uFF01"))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.title) },
                "\u3088\u3046\u3053\u305D",
                React.createElement("br", null),
                "\u4ECA\u65E5\u306E\u30BF\u30B9\u30AF\u3078"),
            React.createElement("div", { className: css(styles.introductionText) },
                "\u4ECA\u65E5\u306E\u30BF\u30B9\u30AF\u3067\u306F\u3001\u5065\u5EB7\u306B\u95A2\u3059\u308B\u6BCE\u65E5\u306E\u30BF\u30B9\u30AF\u30921\u304B\u6240\u3067\u7BA1\u7406\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
                React.createElement("br", null),
                React.createElement("br", null),
                "\u3042\u306A\u305F\u306B\u5408\u3063\u305F\u3001\u4F53\u91CD\u30FB\u6D88\u8CBB\u30AB\u30ED\u30EA\u30FC\u30FB\u6442\u53D6\u30AB\u30ED\u30EA\u30FC\u30FB\u6B69\u6570\u306E\u76EE\u6A19\u3092\u8A2D\u5B9A\u3057\u307E\u3057\u3087\u3046\uFF01"))),
        React.createElement("img", { src: "/assets/images/goal_matching_assessments/introduction.png", className: css(styles.introductionImg) }),
        React.createElement("div", { className: css(styles.footerButtonBox) },
            React.createElement(Button, { className: css(styles.nextButton), onClick: onClickNext, buttonType: ButtonType.primary, dataTest: "matchingAssessmentNext" }, "\u306F\u3058\u3081\u308B"))));
};
var styles = StyleSheet.create({
    container: (_a = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '100%',
            padding: '30px 32px 0',
            background: "repeating-linear-gradient(-45deg, ".concat(Colors.BackgroundBlue, ", ").concat(Colors.BackgroundBlue, " 3px, ").concat(Colors.CyanLightestB, " 0, ").concat(Colors.CyanLightestB, " 10px)")
        },
        _a[MediaBreakPointUp.MD] = {
            paddingBottom: 200,
        },
        _a[MediaBreakPointUp.XL] = {
            paddingBottom: 150,
        },
        _a),
    title: (_b = {
            backgroundColor: Colors.White,
            color: Colors.PrimaryDarkB,
            width: 266,
            height: 80,
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 'bold',
            lineHeight: '140%',
            letterSpacing: 1.6,
            padding: 12,
            margin: '0 auto 24px',
            borderRadius: 50,
            border: "2px solid ".concat(Colors.PrimaryLighterB),
            boxShadow: "0 4px 0 0 ".concat(Colors.PrimaryLighterB)
        },
        _b[MediaBreakPointUp.MD] = {
            marginTop: 173,
        },
        _b[MediaBreakPointUp.XL] = {
            marginTop: 100,
        },
        _b),
    introductionText: {
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: 0.56,
        width: 311,
        marginBottom: 40,
    },
    introductionImg: {
        width: 311,
    },
    footerButtonBox: {
        position: 'fixed',
        textAlign: 'center',
        bottom: 16,
        width: 'calc(100% - 32px)',
    },
    nextButton: (_c = {
            width: '100%',
            height: 60,
            fontSize: 20,
            borderRadius: 30
        },
        _c[MediaBreakPointUp.MD] = {
            width: 343,
        },
        _c),
});
