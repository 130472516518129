import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
export var ReplaceIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 20,
            height: 20,
        },
    });
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.08351 1.84708C1.95313 1.84708 1.84744 1.95277 1.84744 2.08314V5.92151C1.84744 6.05188 1.95313 6.15757 2.08351 6.15757H5.92187C6.05225 6.15757 6.15794 6.05188 6.15794 5.92151V2.08314C6.15794 1.95277 6.05225 1.84708 5.92187 1.84708H2.08351ZM0.400391 2.08314C0.400391 1.15358 1.15395 0.400024 2.08351 0.400024H5.92187C6.85143 0.400024 7.60499 1.15358 7.60499 2.08314V5.92151C7.60499 6.85107 6.85143 7.60462 5.92187 7.60462H2.08351C1.15395 7.60462 0.400391 6.85107 0.400391 5.92151V2.08314Z", fill: "#3B434B" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.5991 13.3626C13.4688 13.3626 13.3631 13.4683 13.3631 13.5986V17.437C13.3631 17.5674 13.4688 17.6731 13.5991 17.6731H17.4375C17.5679 17.6731 17.6736 17.5674 17.6736 17.437V13.5986C17.6736 13.4683 17.5679 13.3626 17.4375 13.3626H13.5991ZM11.916 13.5986C11.916 12.6691 12.6696 11.9155 13.5991 11.9155H17.4375C18.3671 11.9155 19.1206 12.6691 19.1206 13.5986V17.437C19.1206 18.3666 18.3671 19.1201 17.4375 19.1201H13.5991C12.6696 19.1201 11.916 18.3666 11.916 17.437V13.5986Z", fill: "#3B434B" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.11 0.61194C14.3926 0.894495 14.3926 1.35261 14.11 1.63516L12.4664 3.2788H16.4772C17.1781 3.2788 17.8502 3.55722 18.3458 4.05283C18.8414 4.54843 19.1199 5.22062 19.1199 5.92151V8.80028C19.1199 9.19987 18.7959 9.5238 18.3963 9.5238C17.9968 9.5238 17.6728 9.19987 17.6728 8.80028V5.92151C17.6728 5.6044 17.5469 5.30028 17.3226 5.07605C17.0984 4.85182 16.7943 4.72585 16.4772 4.72585H12.4664L14.11 6.36949C14.3926 6.65204 14.3926 7.11015 14.11 7.39271C13.8274 7.67526 13.3693 7.67526 13.0868 7.39271L10.208 4.51393C9.92546 4.23138 9.92546 3.77327 10.208 3.49071L13.0868 0.61194C13.3693 0.329386 13.8274 0.329386 14.11 0.61194Z", fill: "#3B434B" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.12392 9.99597C1.52351 9.99597 1.84744 10.3199 1.84744 10.7195V13.5983C1.84744 13.9154 1.97341 14.2195 2.19764 14.4437C2.42187 14.668 2.72599 14.7939 3.0431 14.7939H7.0539L5.41026 13.1503C5.12771 12.8677 5.12771 12.4096 5.41026 12.1271C5.69282 11.8445 6.15093 11.8445 6.43348 12.1271L9.31226 15.0058C9.59481 15.2884 9.59481 15.7465 9.31226 16.0291L6.43348 18.9078C6.15093 19.1904 5.69282 19.1904 5.41026 18.9078C5.12771 18.6253 5.12771 18.1672 5.41026 17.8846L7.0539 16.241H3.0431C2.34221 16.241 1.67003 15.9626 1.17442 15.4669C0.678818 14.9713 0.400391 14.2992 0.400391 13.5983V10.7195C0.400391 10.3199 0.724324 9.99597 1.12392 9.99597Z", fill: "#3B434B" })));
};
