import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { configure } from 'mobx';
import { LoadSentry } from './middlewares';
import 'url-search-params-polyfill';
import { LoadAppConfigFromEnv } from './config';
import { DomainFactory } from './domain';
import { RouteController } from './route';
import './util';
import { PageProvider, MainContentProvider } from './view/pages/provider';
import { AlertContainerVM } from './view/organisms/alert_container';
import { Root } from './view';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
export var AppContainerComponent = function (_a) {
    var alertContainerVM = _a.alertContainerVM, pageProvider = _a.pageProvider, domainFactory = _a.domainFactory, routeController = _a.routeController;
    var navigate = useNavigate();
    var location = useLocation();
    domainFactory.initializeNavigate(navigate);
    domainFactory.AccountService();
    routeController.initialize(domainFactory, location);
    return React.createElement(Root, { pageProvider: pageProvider, alertContainerVM: alertContainerVM, routeController: routeController });
};
var AppContainer = /** @class */ (function () {
    function AppContainer() {
        Object.defineProperty(this, "alertContainerVM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "routeController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "domainFactory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mainContentFactory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pageProvider", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        var config = LoadAppConfigFromEnv();
        var alertContainerVM = new AlertContainerVM();
        var domainFactory = new DomainFactory(config, alertContainerVM);
        var routeController = new RouteController(domainFactory, alertContainerVM, config);
        var mainContentFactory = new MainContentProvider(domainFactory, alertContainerVM, routeController, config);
        var pageProvider = new PageProvider(mainContentFactory, domainFactory, routeController, alertContainerVM, config);
        this.config = config;
        this.routeController = routeController;
        this.alertContainerVM = alertContainerVM;
        this.domainFactory = domainFactory;
        this.mainContentFactory = mainContentFactory;
        this.pageProvider = pageProvider;
    }
    Object.defineProperty(AppContainer.prototype, "initialize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            LoadSentry(this.config);
            var root = ReactDOM.createRoot(document.getElementById('main'));
            root.render(React.createElement(BrowserRouter, null,
                React.createElement(AppContainerComponent, { alertContainerVM: this.alertContainerVM, pageProvider: this.pageProvider, domainFactory: this.domainFactory, routeController: this.routeController })));
        }
    });
    return AppContainer;
}());
export { AppContainer };
configure({ enforceActions: 'never' });
new AppContainer().initialize();
if (module.hot) {
    module.hot.accept(function (err) {
        if (err) {
            console.error(err);
        }
    });
}
