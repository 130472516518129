import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { SearchBackButton } from '@lean-body/components/atoms/search_back_button';
export var SearchResultNotFound = function (props) {
    var styles = StyleSheet.create({
        contianer: {
            marginTop: 50,
            marginBottom: 100,
            textAlign: 'center',
        },
        sentence: {
            lineHeight: 2,
            color: Colors.Gray,
            fontSize: 15,
            fontWeight: 600,
            letterSpacing: 1,
            marginTop: 10,
            marginBottom: 15,
        },
        icon: {
            display: 'block',
            margin: '0 auto 10px',
            width: 100,
        },
    });
    return (React.createElement("div", { className: css(styles.contianer) },
        React.createElement("img", { src: "/assets/images/search/not_found_icon.png", className: css(styles.icon) }),
        React.createElement("div", { className: css(styles.sentence) },
            "\u691C\u7D22\u7D50\u679C\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3067\u3057\u305F\u3002",
            React.createElement("br", null),
            "\u30AD\u30FC\u30EF\u30FC\u30C9\u3092\u5909\u3048\u3066",
            React.createElement("br", null),
            "\u3082\u3046\u4E00\u5EA6\u691C\u7D22\u3057\u3066\u307F\u3066\u304F\u3060\u3055\u3044\u3002"),
        React.createElement(SearchBackButton, { onClick: props.onClickBack })));
};
