var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { DIFFICULTIES } from '@lean-body/components/pages/main_search';
import { Dialog } from '@lean-body/components/molecules';
import { CloseButtonLabel } from '../atoms/dialog_close_button';
import { CloseIcon } from '../atoms/icons/close_icon';
var CLOSE_ICON_SIZE = 15;
export var SearchConditionDialog = function (_a) {
    var lessonStyles = _a.lessonStyles, durations = _a.durations, currentLessonStyles = _a.currentLessonStyles, currentDifficulties = _a.currentDifficulties, currentDurations = _a.currentDurations, setCurrentLessonStyles = _a.setCurrentLessonStyles, setCurrentDifficulties = _a.setCurrentDifficulties, setCurrentDurations = _a.setCurrentDurations, onClickSubmit = _a.onClickSubmit, onClickCancel = _a.onClickCancel;
    var onClickStyle = function (v) {
        setCurrentLessonStyles(currentLessonStyles.includes(v) ? currentLessonStyles.filter(function (value) { return value !== v; }) : __spreadArray(__spreadArray([], currentLessonStyles, true), [v], false));
    };
    var onClickDifficulty = function (v) {
        setCurrentDifficulties(currentDifficulties.includes(v) ? currentDifficulties.filter(function (value) { return value !== v; }) : __spreadArray(__spreadArray([], currentDifficulties, true), [v], false));
    };
    var onClickDuration = function (v) {
        setCurrentDurations(currentDurations.includes(v) ? currentDurations.filter(function (value) { return value !== v; }) : __spreadArray(__spreadArray([], currentDurations, true), [v], false));
    };
    var onClickAllClear = function () {
        setCurrentLessonStyles([]);
        setCurrentDifficulties([]);
        setCurrentDurations([]);
    };
    return (React.createElement(Dialog, { closeButtonLabel: CloseButtonLabel.Cancel, onClickCancel: onClickCancel, classObject: styles.dialog, content: React.createElement(React.Fragment, null,
            React.createElement(CloseIcon, { color: Colors.Black, size: CLOSE_ICON_SIZE, containerClassObject: styles.closeIconContainer, onClick: onClickCancel }),
            React.createElement("div", { className: css(styles.title) }, "\u7D5E\u308A\u8FBC\u307F"),
            React.createElement("div", { className: css(styles.submit), onClick: onClickSubmit }, "\u5B8C\u4E86"),
            React.createElement("div", { className: css(styles.buttonTilte) }, "\u30B8\u30E3\u30F3\u30EB"),
            React.createElement("div", { className: css(styles.buttonBox) }, lessonStyles.map(function (v, index) { return (React.createElement("div", { className: css(styles.radioButton, currentLessonStyles.includes(v.id) ? styles.radioButtonOn : styles.radioButtonOff), onClick: function () { return onClickStyle(v.id); }, key: index }, v.name)); })),
            React.createElement("div", { className: css(styles.buttonTilte) }, "\u96E3\u6613\u5EA6"),
            React.createElement("div", { className: css(styles.buttonBox) }, Object.values(DIFFICULTIES).map(function (v, index) { return (React.createElement("div", { className: css(styles.radioButton, styles.difficultyButton, currentDifficulties.includes(v.id) ? styles.radioButtonOn : styles.radioButtonOff), onClick: function () { return onClickDifficulty(v.id); }, key: index }, v.name)); })),
            React.createElement("div", { className: css(styles.buttonTilte) }, "\u30EC\u30C3\u30B9\u30F3\u6642\u9593"),
            React.createElement("div", { className: css(styles.buttonBox) }, durations.map(function (v, index) { return (React.createElement("div", { className: css(styles.radioButton, styles.durationButton, currentDurations.includes(v.value) ? styles.radioButtonOn : styles.radioButtonOff), onClick: function () { return onClickDuration(v.value); }, key: index }, v.name)); })),
            React.createElement("div", { className: css(styles.clear), onClick: onClickAllClear }, "\u3059\u3079\u3066\u30AF\u30EA\u30A2")) }));
};
var styles = StyleSheet.create({
    dialog: (_a = {
            backgroundColor: Colors.White,
            padding: '12px 16px 24px',
            top: '35%',
            width: '100%',
            height: '65%',
            transform: 'translateX(-50%)',
            borderRadius: '8px 8px 0 0'
        },
        _a[MediaBreakPointUp.MD] = {
            top: '30%',
            width: 375,
            height: 446,
            borderRadius: '8px',
        },
        _a),
    closeIconContainer: {
        position: 'absolute',
        top: 15,
        left: 20,
    },
    title: {
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        borderBottom: "1px solid ".concat(Colors.Gray),
        paddingBottom: 10,
        marginBottom: 10,
    },
    submit: {
        position: 'absolute',
        top: 10,
        right: 18,
        cursor: 'pointer',
    },
    buttonTilte: {
        marginBottom: 8,
        color: Colors.GrayDarkest,
        fontSize: 12,
        fontWeight: 500,
    },
    buttonBox: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px 4px',
        marginBottom: 16,
    },
    radioButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
        fontSize: 12,
        height: 30,
        padding: '0 12px',
        cursor: 'pointer',
    },
    radioButtonOn: {
        fontWeight: 'bold',
        color: Colors.White,
        backgroundColor: Colors.PrimaryDark,
        border: "1px solid ".concat(Colors.PrimaryDark),
    },
    radioButtonOff: {
        fontWeight: 500,
        color: Colors.Black,
        border: "1px solid ".concat(Colors.Gray),
    },
    difficultyButton: {
        width: 'calc(335px / 3)',
    },
    durationButton: {
        width: 'calc(339px / 2)',
    },
    clear: {
        color: Colors.GrayDarkest,
        fontSize: 14,
        marginTop: 30,
        textAlign: 'center',
        cursor: 'pointer',
    },
});
