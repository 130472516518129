var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, StyleSheet } from 'aphrodite';
import { bind } from 'bind-decorator';
import { computed, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import validator from 'validator';
import { FormError } from '../../errors';
import * as msg from '@lean-body/src/messages';
import { Colors } from '@lean-body/components/styles';
import { Button, ButtonType, Input } from '../atoms';
import { SettingStyles } from '../pages/main_setting';
export var EMailChangeSubsection = observer(function (_a) {
    var classObject = _a.classObject, accountService = _a.accountService, apiClient = _a.apiClient, alertPresenter = _a.alertPresenter, me = _a.me;
    var _b = React.useState(new EMailChangeSubsectionVM(accountService, apiClient, alertPresenter, me)), vm = _b[0], _ = _b[1];
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.label) }, "\u73FE\u5728\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9"),
        React.createElement("div", { className: css(styles.email) }, vm.me.email),
        !(vm === null || vm === void 0 ? void 0 : vm.passwordEnabled) ? (React.createElement("div", { className: css(styles.alertText) }, "\u203B\u30D1\u30B9\u30EF\u30FC\u30C9\u304C\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u307E\u305B\u3093\u3002\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u5909\u66F4\u3059\u308B\u524D\u306B\u300C\u30D1\u30B9\u30EF\u30FC\u30C9\u5909\u66F4\u300D\u304B\u3089\u65B0\u3057\u3044\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044\u3002")) : (React.createElement(React.Fragment, null,
            React.createElement(Input, { type: "email", onChange: vm.onChangeEmail, value: vm.email, placeholder: "\u65B0\u3057\u3044\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u5165\u529B", dataTest: "newEmail", containerClassObject: styles.input, label: "\u65B0\u3057\u3044\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9" }),
            React.createElement(Input, { type: "password", onChange: vm.onChangePassword, value: vm.password, placeholder: "\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u5165\u529B", dataTest: "password", containerClassObject: styles.input, label: "\u30D1\u30B9\u30EF\u30FC\u30C9" }),
            React.createElement(Button, { onClick: vm.onSubmit, loading: vm.status === Status.submitting, disabled: vm.status === Status.submitting, buttonType: vm.email && vm.password ? ButtonType.primary : ButtonType.transparentPrimary, dataTest: "submitNewEmail", className: css(SettingStyles.button) }, "\u5909\u66F4")))));
});
var EMailChangeSubsectionStatus;
(function (EMailChangeSubsectionStatus) {
    EMailChangeSubsectionStatus[EMailChangeSubsectionStatus["preparing"] = 0] = "preparing";
    EMailChangeSubsectionStatus[EMailChangeSubsectionStatus["prepareFailed"] = 1] = "prepareFailed";
    EMailChangeSubsectionStatus[EMailChangeSubsectionStatus["ready"] = 2] = "ready";
    EMailChangeSubsectionStatus[EMailChangeSubsectionStatus["submitting"] = 3] = "submitting";
    EMailChangeSubsectionStatus[EMailChangeSubsectionStatus["finished"] = 4] = "finished";
    EMailChangeSubsectionStatus[EMailChangeSubsectionStatus["failed"] = 5] = "failed";
})(EMailChangeSubsectionStatus || (EMailChangeSubsectionStatus = {}));
var Status = EMailChangeSubsectionStatus;
var EMailChangeSubsectionVM = /** @class */ (function () {
    function EMailChangeSubsectionVM(accountService, apiClient, alertPresenter, me) {
        Object.defineProperty(this, "alertGroup", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'email_change'
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "email", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "password", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "me", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: Status.preparing
        });
        makeObservable(this, {
            email: observable,
            password: observable,
            me: observable,
            status: observable,
            passwordEnabled: computed,
        });
        this.accountService = accountService;
        this.alertPresenter = alertPresenter;
        this.apiClient = apiClient;
        this.me = me;
    }
    Object.defineProperty(EMailChangeSubsectionVM.prototype, "passwordEnabled", {
        get: function () {
            if (!this.me) {
                return true;
            }
            return this.me.passwordEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EMailChangeSubsectionVM.prototype, "onChangeEmail", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            this.email = e.target.value;
        }
    });
    Object.defineProperty(EMailChangeSubsectionVM.prototype, "onChangePassword", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            this.password = e.target.value;
        }
    });
    Object.defineProperty(EMailChangeSubsectionVM.prototype, "validate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var errors = [];
            if (validator.isEmpty(this.email)) {
                errors.push(msg.EMAIL_EMPTY);
            }
            else if (!validator.isEmail(this.email)) {
                errors.push(msg.EMAIL_INVALID);
            }
            validator.isEmpty(this.password) && errors.push(msg.PASSWORD_EMPTY);
            if (errors.length > 0) {
                this.alertPresenter.showError(new FormError('', errors.join('\n')), msg.INVALID_INPUT);
                return false;
            }
            return true;
        }
    });
    Object.defineProperty(EMailChangeSubsectionVM.prototype, "onSubmit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            var _this = this;
            e.preventDefault();
            if (!this.validate()) {
                return;
            }
            this.status = Status.submitting;
            this.apiClient
                .changeEmail({
                email: this.email,
                password: this.password,
            })
                .then(function () {
                _this.accountService.reloadMe();
                _this.status = Status.finished;
                _this.email = '';
                _this.password = '';
                _this.alertPresenter.showMessage(msg.EMAIL_CHANGE_SUCCEEDED, _this.alertGroup);
            })
                .catch(function () {
                _this.status = Status.prepareFailed;
                _this.alertPresenter.showMessage(msg.EMAIL_CHANGE_FAILED, _this.alertGroup);
            });
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EMailChangeSubsectionVM.prototype, "onChangeEmail", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EMailChangeSubsectionVM.prototype, "onChangePassword", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EMailChangeSubsectionVM.prototype, "onSubmit", null);
    return EMailChangeSubsectionVM;
}());
export { EMailChangeSubsectionVM };
var styles = StyleSheet.create({
    container: {},
    label: {
        fontSize: 14,
        color: Colors.GrayDarkest,
        marginBottom: 9,
    },
    email: {
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.Black,
        marginBottom: 40,
    },
    input: {
        marginBottom: 40,
    },
    alertText: {
        fontSize: 14,
    },
});
