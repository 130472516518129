import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
export var CalendarIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 21,
            height: 21,
        },
    });
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 21 21", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.10891 3.98981C2.75316 3.98981 2.41198 4.12657 2.16043 4.37001C1.90888 4.61345 1.76755 4.94362 1.76755 5.2879V17.0456C1.76755 17.3899 1.90888 17.72 2.16043 17.9635C2.41198 18.2069 2.75316 18.3437 3.10891 18.3437H9.98864C10.3662 18.3437 10.6722 18.6399 10.6722 19.0052C10.6722 19.3706 10.3662 19.6667 9.98864 19.6667H3.10891C2.39057 19.6667 1.70164 19.3906 1.1937 18.899C0.685752 18.4075 0.400391 17.7408 0.400391 17.0456V5.2879C0.400391 4.59272 0.685752 3.92603 1.1937 3.43446C1.70164 2.9429 2.39057 2.66675 3.10891 2.66675H15.2585C15.9769 2.66675 16.6658 2.9429 17.1737 3.43446C17.6817 3.92602 17.9671 4.59272 17.9671 5.2879V9.20713C17.9671 9.57248 17.661 9.86866 17.2835 9.86866C16.9059 9.86866 16.5999 9.57248 16.5999 9.20713V5.2879C16.5999 4.94362 16.4586 4.61345 16.207 4.37001C15.9555 4.12657 15.6143 3.98981 15.2585 3.98981H3.10891Z", fill: "#3B434B" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.2677 13.0425C14.4872 13.0425 13.0437 14.4859 13.0437 16.2664C13.0437 18.047 14.4872 19.4904 16.2677 19.4904C18.0483 19.4904 19.4917 18.047 19.4917 16.2664C19.4917 14.4859 18.0483 13.0425 16.2677 13.0425ZM11.7344 16.2664C11.7344 13.7627 13.764 11.7331 16.2677 11.7331C18.7714 11.7331 20.801 13.7627 20.801 16.2664C20.801 18.7701 18.7714 20.7998 16.2677 20.7998C13.764 20.7998 11.7344 18.7701 11.7344 16.2664Z", fill: "#3B434B" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.1508 0.400024C13.6202 0.400024 14.0008 0.720178 14.0008 1.11511V5.35161C14.0008 5.74654 13.6202 6.06669 13.1508 6.06669C12.6813 6.06669 12.3008 5.74654 12.3008 5.35161V1.11511C12.3008 0.720178 12.6813 0.400024 13.1508 0.400024Z", fill: "#3B434B" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.21719 0.400024C5.68663 0.400024 6.06719 0.720178 6.06719 1.11511V5.35161C6.06719 5.74654 5.68663 6.06669 5.21719 6.06669C4.74775 6.06669 4.36719 5.74654 4.36719 5.35161V1.11511C4.36719 0.720178 4.74775 0.400024 5.21719 0.400024Z", fill: "#3B434B" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.400391 9.18331C0.400391 8.71387 0.706441 8.33331 1.08397 8.33331H17.2835C17.661 8.33331 17.9671 8.71387 17.9671 9.18331C17.9671 9.65275 17.661 10.0333 17.2835 10.0333H1.08397C0.706441 10.0333 0.400391 9.65275 0.400391 9.18331Z", fill: "#3B434B" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.3523 13.9998C16.7119 13.9998 17.0034 14.3109 17.0034 14.6947V15.9547L17.7771 16.7803C18.0314 17.0517 18.0314 17.4916 17.7771 17.763C17.5228 18.0343 17.1106 18.0343 16.8563 17.763L15.8919 16.7338C15.7698 16.6035 15.7012 16.4268 15.7012 16.2425V14.6947C15.7012 14.3109 15.9927 13.9998 16.3523 13.9998Z", fill: "#3B434B" })));
};
