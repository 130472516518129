var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import * as React from 'react';
import { useNavigate } from 'react-router';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { underXlMediaQuery, isiOSApp } from '@lean-body/src/util';
import { ProgramCard } from '@lean-body/src/view/molecules';
import { routes } from '@lean-body/src/route';
export var MainBillysStudio = function (props) {
    var apiClient = props.apiClient;
    var navigate = useNavigate();
    var _a = React.useState(null), trainer = _a[0], setTrainer = _a[1];
    var _b = React.useState([]), programs = _b[0], setPrograms = _b[1];
    var isUnderXl = underXlMediaQuery();
    var newReleaseImg = isUnderXl ? imgPath('new_release_sp') : imgPath('new_release');
    var programImg = isUnderXl ? imgPath('program_sp') : imgPath('program');
    // 最もリリース日時の新しいプログラムを取得
    var newReleaseProgram = programs.sort(function (p1, p2) { return p2.publishAt - p1.publishAt; })[0];
    var programsWithoutNewRelease = programs.filter(function (v) { return v !== newReleaseProgram; });
    var programSlugToDescription = new Map([
        ['reiwa_bootcamp2_sparta_edition', { icon: 'new', text: '短期間で引き締めたい人におすすめだ！' }],
        ['reiwa_bootcamp2_sparta_edition_original', { icon: 'muscle', text: 'オレと一緒に大きな声でカウント！！' }],
        ['reiwa_bootcamp_short', { icon: 'clock', text: '１分からできるぞ！今すぐクイック入隊！' }],
        ['reiwa_bootcamp', { icon: 'bikini', text: '部位別にトレーニングしたいならこれだ！' }],
        ['reiwa_bootcamp_original', { icon: 'fire', text: 'オレと一緒に全力で脂肪燃焼しよう！' }],
        ['yasashisugiru_bootcamp', { icon: 'herb', text: '運動に自信がない君でもやり切れるぞ！' }],
        ['yasashisugiru_bootcamp_original', { icon: 'relieved', text: 'オレと一緒にリフレッシュしよう！' }],
        ['boom_boom_boxing', { icon: 'glove', text: '一緒に楽しくストレス発散だ！' }],
        ['billy_blanks_saikyo_training', { icon: 'fisted_hand', text: '短時間で効く！集中トレーニング！' }],
        ['reiwa_billy_radio_exercises', { icon: 'radio', text: '懐かしのラジオ体操でリフレッシュ！' }],
        ['reiwa_billy_5minutes_seated', { icon: 'chair', text: '座ったままでOK！サクッと運動！' }],
        ['reiwa_billy_5minutes_bootcamp', { icon: 'run', text: '時間も場所もとらずに入隊できるぞ！' }],
        ['reiwa_billy_5minutes_floor', { icon: 'bed', text: '寝る前や起きてすぐの5分で運動しよう！' }],
        ['billys-daily-exercise-30days', { icon: 'calendar', text: '日めくりカレンダー感覚で毎日挑戦だ！' }],
    ]);
    var defaultProgramDescription = {
        icon: 'fire',
        text: 'オレと一緒に全力で脂肪燃焼しよう！',
    };
    var newReleaseProgramDescription = programSlugToDescription.get(newReleaseProgram === null || newReleaseProgram === void 0 ? void 0 : newReleaseProgram.slug) || defaultProgramDescription;
    React.useEffect(function () {
        var billysTrainerID = 35;
        apiClient.fetchProgramByInstructorID(billysTrainerID, 0, 0).then(function (programs) {
            programs.sort(function (p1, p2) { return p2.orderIndex - p1.orderIndex; }); // 降順ソート
            setPrograms(programs);
        });
        apiClient.fetchTrainer(billysTrainerID).then(function (trainer) {
            setTrainer(trainer);
        });
    }, []);
    var onClickProgram = function (p) {
        var link = routes.PROGRAM.compile({ slug: p.slug });
        if (isiOSApp()) {
            // webviewはリンク遷移にしないとハンドリングできないので対応
            window.location.href = link;
        }
        else {
            navigate(link);
        }
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.header) }),
        React.createElement("div", { className: css(styles.billysContent) },
            React.createElement("div", { className: css(styles.billysIntroductionSection) },
                React.createElement("div", { className: css(styles.billysMessageWrapper) },
                    React.createElement("div", { className: css(styles.billysMessage) },
                        React.createElement("div", { className: css(styles.billysMessageTitleArea) },
                            React.createElement("img", { className: css(styles.billysMessageTitleImg), src: imgPath('change_your_life') })),
                        React.createElement("div", { className: css(styles.billysMessageTextAbove) },
                            "\u307F\u3093\u306A\u5143\u6C17\u304B\uFF1F",
                            React.createElement("br", null),
                            " \u30D3\u30EA\u30FC\u968A\u9577\u3060\uFF01",
                            React.createElement("br", null),
                            React.createElement("br", null),
                            " \u6642\u9593\u304C\u7121\u3044\u3001\u904B\u52D5\u304C\u82E6\u624B\u3001\u4ECA\u5EA6\u3053\u305D",
                            React.createElement("br", null),
                            " \u30C0\u30A4\u30A8\u30C3\u30C8\u3092\u6210\u529F\u3055\u305B\u305F\u3044\u2026",
                            React.createElement("br", null),
                            " \u305D\u3093\u306A\u541B\u306B\u3074\u3063\u305F\u308A\u306E\u30A8\u30AF\u30B5\u30B5\u30A4\u30BA\u304C",
                            React.createElement("br", null),
                            " \u898B\u3064\u304B\u308B\u3053\u3068\u3001\u9593\u9055\u3044\u306A\u3057\u3002",
                            React.createElement("br", null),
                            React.createElement("br", null),
                            " \u904B\u52D5\u3092\u3057\u306A\u3044\u8A00\u3044\u8A33\u306F\u7121\u7528\u3060\u3002",
                            React.createElement("br", null)),
                        React.createElement("div", { className: css(styles.noMoreExcuse) }, "NO MORE EXCUSE."),
                        React.createElement("div", { className: css(styles.billysMessageTextBelow) }, "\u307F\u3093\u306A\u306E\u5165\u968A\u3092\u5F85\u3063\u3066\u3044\u308B\u305E\u3002"),
                        React.createElement("div", { className: css(styles.billysMessageIconArea) }, trainer && React.createElement("img", { className: css(styles.billysMessageIcon), src: trainer.thumbnailURL })))),
                React.createElement("div", { className: css(styles.youtubeWrapper) },
                    React.createElement("iframe", { className: css(styles.youtube), src: "https://www.youtube.com/embed/HlbrEOCEeVs", title: "YouTube video player", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }))),
            newReleaseProgram && (React.createElement("div", { className: css(styles.newReleaseSection) },
                React.createElement("div", { className: css(styles.textHeaderImgWrapper) },
                    React.createElement("img", { className: css(styles.textHeaderImg), src: newReleaseImg })),
                React.createElement("div", { className: css(styles.programs) },
                    React.createElement("div", null,
                        React.createElement("div", { className: css(styles.programDescription) },
                            React.createElement("div", { className: css(styles.iconBox) },
                                React.createElement("img", { className: css(styles.icon), src: imgPath(newReleaseProgramDescription.icon) })),
                            React.createElement("div", { className: css(styles.programDescriptionTextWrapper) },
                                React.createElement("span", { className: css(styles.programDescriptionText) }, newReleaseProgramDescription.text))),
                        React.createElement(ProgramCard, { classObject: styles.programCard, program: newReleaseProgram, onClick: onClickProgram }))))),
            React.createElement("div", { className: css(styles.programSection) },
                React.createElement("div", { className: css(styles.textHeaderImgWrapper) },
                    React.createElement("img", { className: css(styles.textHeaderImg), src: programImg })),
                React.createElement("div", { className: css(styles.programs) }, programsWithoutNewRelease.map(function (p, i) {
                    var description = programSlugToDescription.get(p === null || p === void 0 ? void 0 : p.slug) || defaultProgramDescription;
                    return (React.createElement("div", { key: i },
                        React.createElement("div", { className: css(styles.programDescription) },
                            React.createElement("div", { className: css(styles.iconBox) },
                                React.createElement("img", { className: css(styles.icon), src: imgPath(description.icon) })),
                            React.createElement("div", { className: css(styles.programDescriptionTextWrapper) },
                                React.createElement("span", { className: css(styles.programDescriptionText) }, description.text))),
                        React.createElement(ProgramCard, { classObject: styles.programCard, program: p, onClick: onClickProgram })));
                }))))));
};
var imgPath = function (path) {
    return "assets/images/billys_studio/".concat(path, ".png");
};
var styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.Ivory,
    },
    header: (_a = {
            width: '100%',
            backgroundSize: 'cover',
            backgroundImage: "url(\"/assets/images/billys_studio/billys_studio_main_visual.jpg\")",
            aspectRatio: '19 / 10',
            marginBottom: 14
        },
        _a[MediaBreakPointUp.XL] = {
            maxWidth: 1012,
            margin: '20px 0 24px 30px',
        },
        _a),
    billysContent: (_b = {
            overflow: 'hidden',
            padding: '0 18px',
            fontFamily: 'sans-serif'
        },
        _b[MediaBreakPointUp.XL] = {
            maxWidth: 1044,
            padding: '20px 0 0 30px',
        },
        _b),
    billysIntroductionSection: (_c = {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: 12,
            marginBottom: 20
        },
        _c[MediaBreakPointUp.MD] = {
            flexDirection: 'row',
            alignItems: 'flex-end',
            gap: 30,
            marginBottom: 45,
        },
        _c),
    billysMessageWrapper: {
        width: 338,
        height: 413,
        padding: '44px 0 30px',
    },
    billysMessage: {
        position: 'relative',
        width: '100%',
        maxWidth: 338,
        height: 343,
        border: "4px solid ".concat(Colors.Black),
        textAlign: 'center',
    },
    billysMessageTitleArea: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: -44,
        left: 0,
        right: 0,
        width: 270,
        height: 75,
        backgroundColor: Colors.Ivory,
        margin: 'auto',
    },
    billysMessageTitleImg: {
        position: 'relative',
        width: 244,
    },
    billysMessageTextAbove: {
        position: 'relative',
        fontSize: 14,
        fontWeight: 'bold',
        margin: '42px 0 3px',
    },
    noMoreExcuse: {
        position: 'relative',
        fontWeight: 'bold',
        height: 38,
        width: 198,
        backgroundColor: Colors.Black,
        color: Colors.White,
        lineHeight: '38px',
        margin: 'auto',
        marginBottom: 3,
    },
    billysMessageTextBelow: {
        position: 'relative',
        fontSize: 14,
        fontWeight: 'bold',
    },
    billysMessageIconArea: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: -30,
        width: 130,
        height: 64,
        backgroundColor: Colors.Ivory,
        margin: 'auto',
    },
    billysMessageIcon: {
        width: 60,
        height: 60,
        borderRadius: '50%',
        border: "3px solid ".concat(Colors.Black),
    },
    youtubeWrapper: (_d = {
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        },
        _d[MediaBreakPointUp.XL] = {
            width: 640,
            marginBottom: 30,
        },
        _d),
    youtube: (_e = {
            width: 335,
            aspectRatio: '16 / 9'
        },
        _e[MediaBreakPointUp.MD] = {
            width: '80%',
        },
        _e[MediaBreakPointUp.XL] = {
            width: '100%',
        },
        _e),
    textHeaderImgWrapper: (_f = {
            width: '100%',
            textAlign: 'center'
        },
        _f[MediaBreakPointUp.LG] = {
            textAlign: 'start',
        },
        _f),
    textHeaderImg: (_g = {
            width: 327
        },
        _g[MediaBreakPointUp.MD] = {
            marginBottom: 20,
        },
        _g),
    newReleaseSection: {
        width: '100%',
        marginBottom: 28,
    },
    programSection: (_h = {
            width: '100%',
            marginBottom: 30
        },
        _h[MediaBreakPointUp.MD] = {
            marginBottom: 60,
        },
        _h),
    programs: (_j = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 15
        },
        _j[MediaBreakPointUp.LG] = {
            alignItems: 'flex-start',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        _j),
    programCard: (_k = {
            marginBottom: 24,
            maxWidth: 328
        },
        _k[MediaBreakPointUp.SM] = {
            minWidth: 240,
        },
        _k[MediaBreakPointUp.LG] = {
            width: 328,
        },
        _k),
    programDescription: {
        display: 'flex',
        marginBottom: 8,
        maxWidth: 327,
    },
    iconBox: {
        width: 36,
        height: 36,
        border: "2px solid ".concat(Colors.Orange),
        borderRadius: 4,
        backgroundColor: Colors.White,
        marginRight: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: 16,
    },
    programDescriptionTextWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    programDescriptionText: {
        fontWeight: 'bold',
        fontSize: 14,
        color: Colors.Orange,
    },
});
