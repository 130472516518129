var _a;
import * as React from 'react';
import { StyleSheet } from 'aphrodite';
import SearchTitle from './search/search_title';
import { OriginalPlaylistSection } from './original_playlist_section';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { LessonSessionComponentCarousel } from '@lean-body/components/organisms';
import { isEmptyArray, useXlMediaQuery } from '@lean-body/src/util';
import { LessonComponentCarousel } from './lesson_component_carousel';
import { ProgramComponentCarousel } from './program_component_carousel';
import { LIST_ITEM_MARGIN_RIGHT } from '../pages/main_home';
import { PlaylistComponentCarousel } from './playlist_component_carousel';
import { MyLessonEmptyState } from '../molecules/my_lesson_empty_state';
import { routes } from '@lean-body/src/route';
import { DETAILS_QUERY_PARAMS, MyLessonDetails } from './my_lessons';
var ITEM_PADDING = 10;
var ITEM_PADDING_XL = 25;
var MORE_LINK_LABEL = 'すべて見る';
export var MyLessonAllContents = function (_a) {
    var lessonHistories = _a.lessonHistories, lessons = _a.lessons, playlists = _a.playlists, seriesPrograms = _a.seriesPrograms, originalPlaylists = _a.originalPlaylists, addFavoriteLesson = _a.addFavoriteLesson, unFavoriteLesson = _a.unFavoriteLesson, addFavoriteProgram = _a.addFavoriteProgram, unFavoriteProgram = _a.unFavoriteProgram, onClickCreateOriginalPlaylist = _a.onClickCreateOriginalPlaylist;
    var isXL = useXlMediaQuery();
    return (React.createElement(React.Fragment, null,
        React.createElement(SearchTitle, { classObject: styles.sectionTitle, linkLabel: MORE_LINK_LABEL, linkUrl: !isEmptyArray(lessonHistories) &&
                "".concat(routes.MY_LESSON.compile(), "?").concat(DETAILS_QUERY_PARAMS, "=").concat(MyLessonDetails.lessonHistory) }, "\u8996\u8074\u5C65\u6B74"),
        isEmptyArray(lessonHistories) ? (React.createElement(MyLessonEmptyState, { classObject: styles.emptyState, topImgSrc: "/assets/images/my_lesson/lesson_history_empty.png", title: "\u8996\u8074\u3057\u305F\u30EC\u30C3\u30B9\u30F3\u304C\u3042\u308A\u307E\u305B\u3093", description: "\u8996\u8074\u3057\u305F\u30EC\u30C3\u30B9\u30F3\u304C\u3053\u3053\u306B\u8868\u793A\u3055\u308C\u307E\u3059" })) : (React.createElement(LessonSessionComponentCarousel, { sessions: lessonHistories, addFavoriteLesson: addFavoriteLesson, unFavoriteLesson: unFavoriteLesson, itemMarginRight: LIST_ITEM_MARGIN_RIGHT, itemsPadding: isXL ? ITEM_PADDING_XL : ITEM_PADDING, isMin: !isXL })),
        React.createElement(SearchTitle, { classObject: styles.sectionTitle, linkLabel: MORE_LINK_LABEL, linkUrl: !isEmptyArray(lessons) && "".concat(routes.MY_LESSON.compile(), "?").concat(DETAILS_QUERY_PARAMS, "=").concat(MyLessonDetails.lesson) }, "\u304A\u6C17\u306B\u5165\u308A\u306E\u30EC\u30C3\u30B9\u30F3"),
        isEmptyArray(lessons) ? (React.createElement(MyLessonEmptyState, { classObject: styles.emptyState, topImgSrc: "/assets/images/my_lesson/favorite_lesson_empty.png", title: "\u304A\u6C17\u306B\u5165\u308A\u3057\u305F\u30EC\u30C3\u30B9\u30F3\u304C\u3042\u308A\u307E\u305B\u3093", description: "\u6C17\u306B\u306A\u308B\u30EC\u30C3\u30B9\u30F3\u3092\u304A\u6C17\u306B\u5165\u308A\u3057\u3066\u3044\u3064\u3067\u3082\u898B\u8FD4\u3057\u307E\u3057\u3087\u3046", buttonText: "\u4EBA\u6C17\u306E\u30EC\u30C3\u30B9\u30F3\u3092\u898B\u308B", linkUrl: routes.RANKING.compile() })) : (React.createElement(LessonComponentCarousel, { lessons: lessons, addFavoriteLesson: addFavoriteLesson, unFavoriteLesson: unFavoriteLesson, itemMarginRight: LIST_ITEM_MARGIN_RIGHT, itemsPadding: isXL ? ITEM_PADDING_XL : ITEM_PADDING, isMin: !isXL })),
        React.createElement(SearchTitle, { classObject: styles.sectionTitle, linkLabel: MORE_LINK_LABEL, linkUrl: !isEmptyArray(seriesPrograms) &&
                "".concat(routes.MY_LESSON.compile(), "?").concat(DETAILS_QUERY_PARAMS, "=").concat(MyLessonDetails.program) }, "\u304A\u6C17\u306B\u5165\u308A\u306E\u30B7\u30EA\u30FC\u30BA"),
        isEmptyArray(seriesPrograms) ? (React.createElement(MyLessonEmptyState, { classObject: styles.emptyState, topImgSrc: "/assets/images/my_lesson/favorite_program_empty.png", title: "\u304A\u6C17\u306B\u5165\u308A\u3057\u305F\u30B7\u30EA\u30FC\u30BA\u304C\u3042\u308A\u307E\u305B\u3093", description: "\u6C17\u306B\u306A\u308B\u30B7\u30EA\u30FC\u30BA\u3092\u304A\u6C17\u306B\u5165\u308A\u3057\u3066\u30B3\u30F3\u30D7\u30EA\u30FC\u30C8\u3057\u307E\u3057\u3087\u3046", buttonText: "\u30B7\u30EA\u30FC\u30BA\u3092\u63A2\u3059", linkUrl: routes.PROGRAMS.compile() })) : (React.createElement(ProgramComponentCarousel, { programs: seriesPrograms, addFavoriteProgram: addFavoriteProgram, unFavoriteProgram: unFavoriteProgram, itemMarginRight: LIST_ITEM_MARGIN_RIGHT, itemsPadding: isXL ? ITEM_PADDING_XL : ITEM_PADDING, isMin: !isXL })),
        React.createElement(SearchTitle, { classObject: styles.sectionTitle, linkLabel: MORE_LINK_LABEL, linkUrl: !isEmptyArray(playlists) &&
                "".concat(routes.MY_LESSON.compile(), "?").concat(DETAILS_QUERY_PARAMS, "=").concat(MyLessonDetails.playlist) }, "\u304A\u6C17\u306B\u5165\u308A\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8"),
        isEmptyArray(playlists) ? (React.createElement(MyLessonEmptyState, { classObject: styles.emptyState, topImgSrc: "/assets/images/my_lesson/favorite_playlist_empty.png", title: "\u304A\u6C17\u306B\u5165\u308A\u3057\u305F\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u304C\u3042\u308A\u307E\u305B\u3093", description: "\u6C17\u306B\u306A\u308B\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u304A\u6C17\u306B\u5165\u308A\u3057\u3066\u3042\u3068\u3067\u30C1\u30E3\u30EC\u30F3\u30B8\u3057\u307E\u3057\u3087\u3046", buttonText: "\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u63A2\u3059", linkUrl: routes.PLAYLISTS.compile() })) : (React.createElement(PlaylistComponentCarousel, { playlists: playlists, addFavoriteProgram: addFavoriteProgram, unFavoriteProgram: unFavoriteProgram, itemMarginRight: LIST_ITEM_MARGIN_RIGHT, itemsPadding: ITEM_PADDING, isMin: true })),
        React.createElement(SearchTitle, { classObject: styles.sectionTitle, linkLabel: 'すべて見る', linkUrl: originalPlaylists.length > 1 &&
                "".concat(routes.MY_LESSON.compile(), "?").concat(DETAILS_QUERY_PARAMS, "=").concat(MyLessonDetails.originalPlaylist) }, "\u30AA\u30EA\u30B8\u30CA\u30EB\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8"),
        React.createElement(OriginalPlaylistSection, { playlists: originalPlaylists, itemsPadding: 0, onClickCreate: onClickCreateOriginalPlaylist })));
};
var styles = StyleSheet.create({
    sectionTitle: (_a = {
            color: Colors.TextDark,
            margin: '24px 20px 12px 0',
            lineHeight: '160%',
            letterSpacing: '0.08em'
        },
        _a[MediaBreakPointUp.MD] = {
            marginRight: 0,
        },
        _a[MediaBreakPointUp.XL] = {
            marginTop: 40,
            marginBottom: 24,
        },
        _a),
    emptyState: {
        marginRight: 20,
    },
});
