var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Dialog as DialogComponent } from '@lean-body/components/molecules';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { ButtonType, Button } from '../atoms';
export var SkipCourseDayDialog = function (_a) {
    var skip = _a.skip, close = _a.close;
    return (React.createElement(DialogComponent, { onClickCancel: close, classObject: styles.dialog, content: React.createElement("div", { className: css(styles.container) },
            React.createElement("div", { className: css(styles.title) }, "\u3053\u306EDAY\u3092\u30B9\u30AD\u30C3\u30D7\u3057\u307E\u3059\u304B\uFF1F"),
            React.createElement("div", { className: css(styles.description) },
                "\u3053\u306EDAY\u3092\u30B9\u30AD\u30C3\u30D7\u3059\u308B\u3068\u3001\u6B21\u306B\u30B9\u30B1\u30B8\u30E5\u30FC\u30EB\u3055\u308C\u3066\u3044\u308B",
                React.createElement("br", null),
                "\u30EC\u30C3\u30B9\u30F3\u304C\u4ECA\u65E5\u306E\u30EC\u30C3\u30B9\u30F3\u3068\u3057\u3066\u518D\u751F\u3055\u308C\u307E\u3059\u3002"),
            React.createElement(Button, { buttonType: ButtonType.primary, className: css(styles.button, styles.skipButton), onClick: skip, isRadius: true },
                React.createElement("div", { className: css(styles.buttonText) }, "\u30B9\u30AD\u30C3\u30D7\u3059\u308B")),
            React.createElement(Button, { buttonType: ButtonType.transparentBackgroundPrimary, className: css(styles.button, styles.closeButton), onClick: close, isRadius: true },
                React.createElement("div", { className: css(styles.buttonText) }, "\u30AD\u30E3\u30F3\u30BB\u30EB"))) }));
};
var styles = StyleSheet.create({
    container: {
        borderRadius: 8,
        overflow: 'hidden',
        backgroundColor: Colors.White,
        padding: '25px 24px 20px',
    },
    dialog: (_a = {
            transform: 'none',
            width: '100%',
            top: 'unset',
            left: 0,
            bottom: 0,
            borderRadius: '14px 14px 0 0'
        },
        _a[MediaBreakPointUp.MD] = {
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            bottom: 'unset',
            maxWidth: 375,
            borderRadius: 14,
        },
        _a),
    title: {
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '140%',
        letterSpacing: '0.56px',
    },
    description: {
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: '0.48px',
        margin: '10px 0 20px',
    },
    button: {
        display: 'block',
        width: '100%',
        minHeight: 48,
    },
    skipButton: {
        marginBottom: 10,
    },
    closeButton: {
        border: 'none',
    },
    buttonText: {
        fontSize: 16,
        fontWeight: 'bold',
    },
});
