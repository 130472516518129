import { ValidateResult } from './';
var Corporation = /** @class */ (function () {
    function Corporation(data) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.name = data.name;
    }
    return Corporation;
}());
export { Corporation };
var CorporationEmployee = /** @class */ (function () {
    function CorporationEmployee(data) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "corporationId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "employeeCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.userId = data.userId;
        this.corporationId = data.corporationId;
        this.name = data.name;
        this.employeeCode = data.employeeCode;
    }
    return CorporationEmployee;
}());
export { CorporationEmployee };
var CorporationEmployeeParams = /** @class */ (function () {
    function CorporationEmployeeParams(name, employeeCode) {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "employeeCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.name = name;
        this.employeeCode = employeeCode;
    }
    Object.defineProperty(CorporationEmployeeParams.prototype, "toJson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return { name: this.name, employeeCode: this.employeeCode };
        }
    });
    // 法人関連のパラメータをアップデートするパラメータのバリデーション
    Object.defineProperty(CorporationEmployeeParams.prototype, "validate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var result = new ValidateResult();
            if (this.employeeCode === '') {
                result.pushErrorMessage('社員コードを入力してください');
            }
            if (this.name === '') {
                result.pushErrorMessage('社員名を入力してください');
            }
            return result;
        }
    });
    return CorporationEmployeeParams;
}());
export { CorporationEmployeeParams };
