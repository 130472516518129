import { Program } from '.';
var Trainer = /** @class */ (function () {
    function Trainer(trainerData, config) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "bio", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "thumbnailSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "instaUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "siteUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "programs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = trainerData.id;
        this.name = trainerData.name;
        this.bio = trainerData.bio;
        this.thumbnailSrc = trainerData.thumbnailSrc;
        this.instaUrl = trainerData.instaUrl;
        this.siteUrl = trainerData.siteUrl;
        if (trainerData.programs) {
            this.programs = trainerData.programs.map(function (program) { return new Program(program, config); });
        }
        this.config = config;
    }
    Object.defineProperty(Trainer.prototype, "thumbnailURL", {
        get: function () {
            return this.config.cdnBaseURL + this.thumbnailSrc;
        },
        enumerable: false,
        configurable: true
    });
    return Trainer;
}());
export { Trainer };
