import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
var SAVE_GOOGLE_BUTTON_HEIGHT = 21; // Googleカレンダーに追加ボタンの高さが21px
export var GoogleCalendar = function (_a) {
    var src = _a.src, width = _a.width, height = _a.height, classObject = _a.classObject;
    var styles = StyleSheet.create({
        container: {
            position: 'relative',
            overflow: 'hidden',
            // Googleカレンダーに保存ボタンを押せないようにしたいので、postionのcssで強引に表示されないようにする
            // 保存ボタンを消すGoogle calendarのオプションはありませんでした
            // 参考: https://support.google.com/calendar/thread/27277186/remove-add-to-google-calendar-on-embedded-website-calendar?hl=en
            height: height - SAVE_GOOGLE_BUTTON_HEIGHT,
        },
        inner: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
        },
    });
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.inner) },
            React.createElement("iframe", { src: src, width: width, height: height, scrolling: "no" }))));
};
