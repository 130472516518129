import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
import { routes } from '@lean-body/src/route';
import { FireIcon } from '../atoms/icons/fire_icon';
import { useNavigate } from 'react-router';
import { SECONDS_OF_ONE_MINUTE } from '@lean-body/src/util';
export var LiveLessonComponent = function (_a) {
    var classObject = _a.classObject, liveLesson = _a.liveLesson, isMin = _a.isMin, isVertical = _a.isVertical;
    var navigate = useNavigate();
    var startsAtDate = new Date(liveLesson.startsAt * 1000);
    var onClick = function () {
        navigate(routes.LIVE_LESSON.compile({ id: liveLesson.id.toString() }));
    };
    return (React.createElement("div", { className: css(styles.item, isMin && styles.itemMin, isVertical && styles.itemVertical, classObject), onClick: onClick },
        React.createElement("div", { className: css(styles.headImg, isVertical && (isMin ? styles.headImgVerticalMin : styles.headImgVertical)) },
            React.createElement("div", { className: css(styles.labelBottomRight, styles.time, isMin && styles.labelBottomRightMin) }, "".concat(Math.round(liveLesson.lessonLength / SECONDS_OF_ONE_MINUTE), ":00")),
            React.createElement("img", { className: css(styles.itemImg), src: isMin ? liveLesson.bannerSpURL : liveLesson.bannerPcURL })),
        React.createElement("div", { className: css(styles.itemDescription) },
            React.createElement("div", { className: css(isVertical && styles.itemNameLarge, isMin && styles.itemNameMin, styles.itemName) },
                "\u3010",
                startsAtDate.getMonth() + 1,
                "\u6708",
                startsAtDate.getDate(),
                "\u65E5\u3011",
                liveLesson.title),
            React.createElement("div", { className: css(styles.itemInfo, isVertical && styles.itemInfoLarge, isMin && styles.itemInfoMin) },
                "LIVE",
                liveLesson.difficulty.name && "\u30FB".concat(liveLesson.difficulty.name),
                !isMin && (React.createElement(React.Fragment, null,
                    "\u30FB",
                    React.createElement(FireIcon, { classObject: [styles.fireicon, isVertical && styles.fireiconLarge], color: Colors.Black }),
                    React.createElement("span", null, "".concat(liveLesson.minCalorie, "-").concat(liveLesson.maxCalorie, "kcal"))))),
            isMin && isVertical && (React.createElement("div", { className: css(styles.itemInfo) },
                React.createElement(FireIcon, { classObject: [styles.fireicon, isVertical && styles.fireiconLarge], color: Colors.Black }),
                React.createElement("span", null, "".concat(liveLesson.minCalorie, "-").concat(liveLesson.maxCalorie, "kcal")))),
            !isMin && isVertical && React.createElement("div", { className: css(styles.desc) }, liveLesson.description))));
};
var styles = StyleSheet.create({
    item: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px 0',
        overflow: 'hidden',
        color: Colors.Black,
        cursor: 'pointer',
    },
    itemMin: {
        gap: '5px 0',
    },
    itemVertical: {
        flexDirection: 'row',
        gap: '0 10px',
    },
    headImg: {
        position: 'relative',
        flex: 1,
    },
    headImgVertical: {
        minWidth: 300,
        maxWidth: 335,
    },
    headImgVerticalMin: {
        minWidth: 160,
        maxWidth: 184,
    },
    labelBottomRight: {
        fontSize: 12,
        fontWeight: 'bold',
        letterSpacing: '0.04em',
        lineHeight: 1,
        borderRadius: 4,
        padding: '3px 4px',
        color: Colors.White,
        position: 'absolute',
        bottom: 6,
        right: 6,
    },
    time: {
        backgroundColor: Colors.Black,
    },
    labelBottomRightMin: {
        fontSize: 10,
    },
    itemDescription: {
        flex: 1,
    },
    itemImg: {
        aspectRatio: '16/9',
        width: '100%',
        borderRadius: 4,
        display: 'block',
    },
    itemName: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: '0.04em',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
    itemNameMin: {
        fontSize: 12,
        '-webkit-line-clamp': '2',
    },
    itemNameLarge: {
        fontSize: 16,
        '-webkit-line-clamp': '1',
    },
    itemInfo: {
        fontSize: 12,
        lineHeight: '160%',
        letterSpacing: '0.04em',
        display: 'flex',
        alignItems: 'center',
        margin: '2px 0',
    },
    itemInfoMin: {
        fontSize: 12,
    },
    itemInfoLarge: {
        fontSize: 16,
    },
    fireicon: {
        width: 10,
        height: 14,
        margin: '0 4px 1px 0',
    },
    fireiconLarge: {
        width: 12,
        height: 16,
    },
    desc: {
        fontSize: 12,
        marginTop: 6,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
        overflow: 'hidden',
    },
});
