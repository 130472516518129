import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '../styles';
export var AppleButton = function (_a) {
    var onClick = _a.onClick, classObject = _a.classObject, text = _a.text;
    return (React.createElement("div", { onClick: onClick, className: css(styles.wrapper, classObject) },
        React.createElement("img", { src: "/assets/images/apple_login/apple_icon.png", className: css(styles.image) }),
        text));
};
var styles = StyleSheet.create({
    wrapper: {
        backgroundColor: Colors.StrictBlack,
        color: Colors.White,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 'bold',
        height: 48,
        borderRadius: 100,
        cursor: 'pointer',
    },
    image: {
        width: 16,
        margin: '0 7px 2px 0',
    },
});
