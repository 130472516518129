import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors } from '@lean-body/components/styles';
var selectedBadgeImgPath = 'assets/images/select_plan/selected_badge_gray.png';
export var NoticeSection = function (props) {
    return (React.createElement("div", { className: css(styles.container, props.classObject) },
        React.createElement("img", { className: css(styles.checkIcon), src: selectedBadgeImgPath, alt: "selected badge" }),
        props.children));
};
var styles = StyleSheet.create({
    container: {
        textAlign: 'center',
        backgroundColor: Colors.BackgroundLightBlueMagenta,
        padding: 16,
        fontSize: 14,
    },
    checkIcon: {
        width: 15,
        height: 15,
        margin: '0 10px 2px 0',
    },
});
