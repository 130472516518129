import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { LoadingIcon } from '../atoms/loading_icon';
import { ZIndex } from '../constants';
export var LoadingOverlay = function (_a) {
    var text = _a.text;
    return (React.createElement("div", { className: css(styles.background) },
        React.createElement(LoadingIcon, { classObject: styles.icon }),
        React.createElement("div", { className: css(styles.text) }, text)));
};
var styles = StyleSheet.create({
    background: {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 12,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: ZIndex.Overlay,
        background: Colors.BackgroundDialog,
    },
    icon: {
        width: 43,
        height: 41,
    },
    text: {
        color: Colors.White,
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
});
