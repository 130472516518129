import { StyleSheet, css } from 'aphrodite';
import * as React from 'react';
import { Button, ButtonType } from '@lean-body/components/atoms';
export var RegistrationWithAndroid = function (_a) {
    var onPressed = _a.onPressed;
    return (React.createElement("div", null,
        React.createElement("div", { className: css(styles.title) },
            React.createElement("p", null, "\u30A2\u30AB\u30A6\u30F3\u30C8\u3092\u4F5C\u6210\u3057\u307E\u3057\u305F\uFF01"),
            React.createElement("p", null, "\u3054\u5229\u7528\u958B\u59CB\u307E\u3067\u3082\u3046\u5C11\u3057\u3067\u3059")),
        React.createElement("div", { className: css(styles.annotation) },
            React.createElement("p", null, "LEAN BODY\u306E\u3054\u5229\u7528\u306B\u306F\u30D7\u30E9\u30F3\u767B\u9332\u304C\u5FC5\u8981\u3067\u3059")),
        React.createElement("div", null,
            React.createElement(Button, { className: css(styles.button), fullWidth: true, buttonType: ButtonType.primary, onClick: onPressed }, "\u30D7\u30E9\u30F3\u767B\u9332\u306B\u9032\u3080")),
        React.createElement("div", { className: css(styles.openWebAnnotation) },
            React.createElement("p", null, "WEB\u7248LEAN BODY\u3092\u958B\u304D\u307E\u3059"))));
};
var styles = StyleSheet.create({
    container: {},
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        marginBottom: 14,
    },
    annotation: {
        fontWeight: 500,
        fontSize: 12,
        marginBottom: 40,
    },
    button: {
        fontSize: 16,
        marginBottom: 14,
        borderRadius: 40,
    },
    openWebAnnotation: {
        fontWeight: 500,
        fontSize: 12,
    },
});
