var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as React from 'react';
import { bind } from 'bind-decorator';
import { LocalStorage } from '@lean-body/src/infra';
export var LPIframePage = function (props) {
    return React.createElement(React.Fragment, null);
};
var LPIframePageVM = /** @class */ (function () {
    function LPIframePageVM(accountService, config) {
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "localStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.accountService = accountService;
        this.config = config;
        this.localStorage = new LocalStorage();
        this.handleMessage();
    }
    Object.defineProperty(LPIframePageVM.prototype, "handleMessage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            window.onmessage = this.onReceiveMessage;
        }
    });
    Object.defineProperty(LPIframePageVM.prototype, "onReceiveMessage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            if (e.origin != this.config.lpUrl) {
                return null;
            }
            this.upsertPayload(e.data);
        }
    });
    /**
     * localStorageに保存
     * @param referrer string
     */
    Object.defineProperty(LPIframePageVM.prototype, "upsertPayload", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (referrer) {
            var lpMessageReferre = this.localStorage.loadLpMessageReferrer;
            if (lpMessageReferre === referrer) {
                return null;
            }
            this.localStorage.saveLpMessageReferrer(referrer);
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [MessageEvent]),
        __metadata("design:returntype", void 0)
    ], LPIframePageVM.prototype, "onReceiveMessage", null);
    return LPIframePageVM;
}());
export { LPIframePageVM };
