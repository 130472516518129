var _a, _b;
import * as React from 'react';
import { useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import { convertUnixToDate } from '@lean-body/src/util';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Button } from '@lean-body/components/atoms';
import { observer } from 'mobx-react';
import { FormBox, FullWidthLayout } from '@lean-body/components/layouts';
var styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.BackgroundLightBlueMagenta,
    },
    formbox: {
        backgroundColor: Colors.White,
        boxShadow: '0 1px 5px -3px rgba(0,0,0,0.45)',
        marginBottom: '3rem',
        paddingTop: '24px !important',
    },
    sentence: (_a = {
            color: Colors.Black,
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 300,
            marginBottom: 0,
            textOverflow: 'ellipsis',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': '3'
        },
        _a[MediaBreakPointUp.MD] = {
            fontSize: 14,
        },
        _a),
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '25px 0px',
    },
    button: (_b = {
            width: '90%',
            height: '55px',
            margin: '0 auto !important',
            color: Colors.White,
            border: '0px'
        },
        _b[MediaBreakPointUp.MD] = {
            margin: '10px',
        },
        _b),
    logoutButton: {
        color: Colors.PrimaryDark,
        border: "1px solid ".concat(Colors.PrimaryDark),
    },
});
var FutureUserPageComponent = function (props) {
    var accountService = props.accountService;
    var _a = React.useState(null), me = _a[0], setMe = _a[1];
    useEffect(function () {
        accountService.fetchMe().then(function (me) {
            setMe(me);
        });
    }, []);
    var onClickSignOut = function () {
        accountService.signOut();
    };
    if (!me)
        return null;
    var planName = me.payment.currentPlan.name;
    var periodStart = convertUnixToDate(me.payment.curPeriodStartTime).toLocaleDateString('ja-JP');
    var logoutButtonClass = [css(styles.button) || '', css(styles.approveButton)].join(' ');
    return (React.createElement("div", null,
        React.createElement(FullWidthLayout, { noBackground: true, className: css(styles.container) },
            React.createElement(FormBox, { className: css(styles.formbox) },
                React.createElement("div", { className: css(styles.sentence) },
                    React.createElement("div", null,
                        "\u3010",
                        planName,
                        "\u3011\u3067\u3054\u767B\u9332\u306E\u304A\u5BA2\u69D8\u306F",
                        periodStart,
                        "\u304B\u3089\u5229\u7528\u53EF\u80FD\u3068\u306A\u308A\u307E\u3059\u3002",
                        React.createElement("br", null),
                        "\u4ECA\u3057\u3070\u3089\u304F\u304A\u5F85\u3061\u304F\u3060\u3055\u3044\u3002"),
                    React.createElement("div", { className: css(styles.buttonContainer) },
                        React.createElement(Button, { onClick: onClickSignOut, className: logoutButtonClass }, "\u30ED\u30B0\u30A2\u30A6\u30C8")))))));
};
export var FutureUserPage = observer(FutureUserPageComponent);
