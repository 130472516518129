import * as uuid from 'uuid';
import { getCurrentUnixTimeInSeconds } from '../util/date';
var UUID_SESSION_ID = 'session_id';
export var AMAZON_PAY_PARAMS = 'amazon_pay_params';
var ANDROID_VERSION = 'android_version';
var ANDROID_HAS_STEPCOUNT_PERMISSION = 'android_has_stepcount_permission';
var ANDROID_DEVICE_NOTIFICATION_PERMISSION = 'android_device_notification_permission';
var ANDROID_NOTIFICATION_SETTINGS = 'android_notification_settings';
var ANDROID_HAS_CAMERA_PERMISSION = 'android_has_camera_permission';
var SessionStorage = /** @class */ (function () {
    function SessionStorage() {
        Object.defineProperty(this, "sessionStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: sessionStorage
        });
        Object.defineProperty(this, "expiresSeconds", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 3600 * 24
        });
    }
    Object.defineProperty(SessionStorage.prototype, "loadSession", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var data = this.sessionStorage.getItem(UUID_SESSION_ID);
            if (data) {
                var parsedData = JSON.parse(data);
                var expires = parsedData.expires;
                if (this.isExpired(expires)) {
                    return null;
                }
                return parsedData;
            }
            return null;
        }
    });
    /**
     * sessionを生成して返す
     */
    Object.defineProperty(SessionStorage.prototype, "setupSession", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var unixTs = getCurrentUnixTimeInSeconds();
            var params = {
                session_id: uuid.v4(),
                expire: unixTs + this.expiresSeconds,
            };
            this.sessionStorage.setItem(UUID_SESSION_ID, JSON.stringify(params));
            return params;
        }
    });
    Object.defineProperty(SessionStorage.prototype, "remove", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (key) {
            this.sessionStorage.removeItem(key);
        }
    });
    Object.defineProperty(SessionStorage.prototype, "loadAmazonPayParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var data = this.sessionStorage.getItem(AMAZON_PAY_PARAMS);
            return JSON.parse(data);
        }
    });
    Object.defineProperty(SessionStorage.prototype, "setAmazonPayParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (planId, campaignCode) {
            var params = {
                planId: planId,
                campaignCode: campaignCode,
                queryParams: location.search,
            };
            this.sessionStorage.setItem(AMAZON_PAY_PARAMS, JSON.stringify(params));
        }
    });
    Object.defineProperty(SessionStorage.prototype, "loadAndroidAppVersion", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var version = this.sessionStorage.getItem(ANDROID_VERSION);
            return version;
        }
    });
    Object.defineProperty(SessionStorage.prototype, "loadAndroidStepcountPermission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var hasPermission = this.sessionStorage.getItem(ANDROID_HAS_STEPCOUNT_PERMISSION);
            return Boolean(parseInt(hasPermission, 10));
        }
    });
    Object.defineProperty(SessionStorage.prototype, "loadAndroidDeviceNotificationPermission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var hasPermission = this.sessionStorage.getItem(ANDROID_DEVICE_NOTIFICATION_PERMISSION);
            return hasPermission === 'true';
        }
    });
    Object.defineProperty(SessionStorage.prototype, "loadAndroidCameraPermission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var hasPermission = this.sessionStorage.getItem(ANDROID_HAS_CAMERA_PERMISSION);
            return hasPermission === 'true';
        }
    });
    Object.defineProperty(SessionStorage.prototype, "loadAndroidNotificationSettings", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var settings = this.sessionStorage.getItem(ANDROID_NOTIFICATION_SETTINGS);
            if (settings === null) {
                return null;
            }
            var parsed = JSON.parse(settings);
            // number（0 or 1）で保存されているので、booleanに変換する
            parsed.news = !!parsed.news;
            parsed.live = !!parsed.live;
            parsed.courseReminder = !!parsed.courseReminder;
            return parsed;
        }
    });
    Object.defineProperty(SessionStorage.prototype, "isExpired", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (expires) {
            var unixTs = getCurrentUnixTimeInSeconds();
            return expires < unixTs;
        }
    });
    return SessionStorage;
}());
export { SessionStorage };
