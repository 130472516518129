import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var RaderIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject, onClick = _a.onClick;
    var styles = StyleSheet.create({
        icon: {
            width: 20,
            height: 20,
        },
    });
    var iconColor = color || Colors.White;
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 16 15", fill: "none", xmlns: "http://www.w3.org/2000/svg", onClick: onClick },
        React.createElement("path", { d: "M15.0051 14.9199H12.3576C11.9826 14.9199 11.6826 14.6199 11.6826 14.2449V1.05244C11.6826 0.677443 11.9826 0.377441 12.3576 0.377441H15.0051C15.3801 0.377441 15.6801 0.677443 15.6801 1.05244V14.2449C15.6801 14.6199 15.3801 14.9199 15.0051 14.9199Z", fill: iconColor }),
        React.createElement("path", { d: "M15.0051 14.9199H12.3576C11.9826 14.9199 11.6826 14.6199 11.6826 14.2449V1.05244C11.6826 0.677443 11.9826 0.377441 12.3576 0.377441H15.0051C15.3801 0.377441 15.6801 0.677443 15.6801 1.05244V14.2449C15.6801 14.6199 15.3801 14.9199 15.0051 14.9199Z", fill: "url(#paint0_linear_18134_12191)" }),
        React.createElement("path", { d: "M3.61965 14.9199H1.03215C0.64215 14.9199 0.327148 14.6049 0.327148 14.2149V8.39494C0.327148 8.00494 0.64215 7.68994 1.03215 7.68994H3.61965C4.00965 7.68994 4.32465 8.00494 4.32465 8.39494V14.2149C4.32465 14.6049 4.00965 14.9199 3.61965 14.9199Z", fill: iconColor }),
        React.createElement("path", { d: "M3.61965 14.9199H1.03215C0.64215 14.9199 0.327148 14.6049 0.327148 14.2149V8.39494C0.327148 8.00494 0.64215 7.68994 1.03215 7.68994H3.61965C4.00965 7.68994 4.32465 8.00494 4.32465 8.39494V14.2149C4.32465 14.6049 4.00965 14.9199 3.61965 14.9199Z", fill: "url(#paint1_linear_18134_12191)" }),
        React.createElement("path", { d: "M9.33488 14.9199H6.67238C6.30488 14.9199 6.00488 14.6199 6.00488 14.2524V4.79494C6.00488 4.42744 6.30488 4.12744 6.67238 4.12744H9.33488C9.70238 4.12744 10.0024 4.42744 10.0024 4.79494V14.2524C10.0024 14.6199 9.70238 14.9199 9.33488 14.9199Z", fill: iconColor }),
        React.createElement("path", { d: "M9.33488 14.9199H6.67238C6.30488 14.9199 6.00488 14.6199 6.00488 14.2524V4.79494C6.00488 4.42744 6.30488 4.12744 6.67238 4.12744H9.33488C9.70238 4.12744 10.0024 4.42744 10.0024 4.79494V14.2524C10.0024 14.6199 9.70238 14.9199 9.33488 14.9199Z", fill: "url(#paint2_linear_18134_12191)" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "paint0_linear_18134_12191", x1: "13.6814", y1: "0.377441", x2: "13.6814", y2: "14.9199", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: iconColor }),
                React.createElement("stop", { offset: "1", stopColor: iconColor, stopOpacity: "0" })),
            React.createElement("linearGradient", { id: "paint1_linear_18134_12191", x1: "2.3259", y1: "7.68994", x2: "2.3259", y2: "14.9199", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: iconColor }),
                React.createElement("stop", { offset: "1", stopColor: iconColor, stopOpacity: "0" })),
            React.createElement("linearGradient", { id: "paint2_linear_18134_12191", x1: "8.00363", y1: "4.12744", x2: "8.00363", y2: "14.9199", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: iconColor }),
                React.createElement("stop", { offset: "1", stopColor: iconColor, stopOpacity: "0" })))));
};
