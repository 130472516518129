import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import Duration from '@lean-body/components/atoms/duration';
import PlaylistPlayButton from '@lean-body/components/atoms/playlist_play_button';
import { MediaBreakPointUp, Colors } from '@lean-body/components/styles/variables';
import ViewCount from '@lean-body/components/atoms/view_count';
import { ButtonFavorite } from '@lean-body/components/atoms/button_favorite';
import { FireIcon } from '@lean-body/components/atoms/icons/fire_icon';
import { useXlMediaQuery } from '@lean-body/src/util/media_query_hooks';
export var PlaylistLessonCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var lesson = _a.lesson, _k = _a.isNextLesson, isNextLesson = _k === void 0 ? false : _k, isChallenge = _a.isChallenge, viewCount = _a.viewCount, isFavorite = _a.isFavorite, onClickPlayButton = _a.onClickPlayButton, onClickLessonFavoriteButton = _a.onClickLessonFavoriteButton, classObject = _a.classObject;
    var isXL = useXlMediaQuery();
    var _l = React.useState(isFavorite), isFavorited = _l[0], setIsFavorited = _l[1];
    var _m = React.useState(false), favoriteRequesting = _m[0], setFavoriteRequesting = _m[1];
    var handleOnClickCard = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (lesson.isDayOff) {
            return;
        }
        onClickPlayButton && onClickPlayButton(lesson);
    };
    var handleOnClickLessonFavoriteButton = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (lesson.isDayOff || favoriteRequesting) {
            return;
        }
        setFavoriteRequesting(true);
        onClickLessonFavoriteButton(lesson, isFavorited).then(function () {
            setFavoriteRequesting(false);
            setIsFavorited(!isFavorited);
        });
    };
    var styles = StyleSheet.create({
        wrapper: (_b = {
                position: 'relative',
                display: 'flex',
                width: '100%',
                height: 104,
                maxWidth: 944,
                cursor: onClickPlayButton ? 'pointer' : 'unset',
                backgroundColor: Colors.White,
                borderRadius: '15px',
                padding: 10
            },
            _b[MediaBreakPointUp.MD] = {
                padding: 16,
                height: 160,
            },
            _b),
        header: (_c = {
                position: 'relative',
                flexBasis: '47%',
                backgroundImage: "url(".concat(lesson.thumbnailURL, ")"),
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                borderRadius: '10px',
                overflow: 'hidden'
            },
            _c[MediaBreakPointUp.MD] = {
                flexBasis: 228,
            },
            _c),
        playButton: {
            position: 'absolute',
            top: 'calc(50% - 15px)',
            left: 'calc(50% - 15px)',
        },
        duration: {
            position: 'absolute',
            right: 0,
            bottom: 0,
        },
        body: (_d = {
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                flexBasis: '50%',
                padding: '8px 0 0 16px',
                transition: 'all 0.2s ease-out',
                ':link': {
                    color: Colors.Black,
                },
                ':visited': {
                    color: Colors.Black,
                },
                ':active': {
                    color: Colors.Black,
                }
            },
            _d[MediaBreakPointUp.MD] = {
                justifyContent: 'flex-start',
                padding: '0 0 0 16px',
                flexBasis: 680,
                ':hover': {
                    color: onClickPlayButton ? Colors.PrimaryDark : 'inherit',
                },
            },
            _d),
        lessonName: (_e = {
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                '-webkit-line-clamp': '3',
                overflow: 'hidden',
                margin: '4px 0 0',
                fontSize: 13,
                fontWeight: 'bold',
                lineHeight: 1.5
            },
            _e[MediaBreakPointUp.MD] = {
                fontSize: 16,
            },
            _e),
        trainerBox: (_f = {
                display: 'flex',
                alignItems: 'center',
                margin: '8px 0 11px',
                fontSize: 11,
                lineHeight: '11px',
                color: Colors.GrayDarkest
            },
            _f[MediaBreakPointUp.MD] = {
                margin: '4px 0 11px',
            },
            _f),
        calIcon: {
            marginRight: 3,
        },
        kcal: {
            marginRight: 20,
        },
        trainer: {
            marginRight: 10,
            display: 'flex',
            alignItems: 'center',
        },
        trainerImg: {
            height: 23,
            width: 23,
            border: "1px solid ".concat(Colors.Gray),
            marginRight: 7,
            borderRadius: 100,
        },
        trainerName: {
            fontSize: 10,
            fontWeight: 'bold',
        },
        isNextLesson: {
            backgroundColor: Colors.BlueLighterB,
            border: "2px solid ".concat(Colors.Primary),
        },
        lessonDescription: {
            fontSize: 12,
            lineHeight: '1.75',
            margin: 0,
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': '3',
            overflow: 'hidden',
        },
        viewCount: (_g = {
                position: 'absolute',
                top: 6,
                right: 6
            },
            _g[MediaBreakPointUp.MD] = {
                top: 8,
                right: 8,
            },
            _g),
        favoriteBtn: (_h = {
                position: 'relative',
                width: 24,
                height: 24
            },
            _h[MediaBreakPointUp.MD] = {
                width: 30,
                height: 30,
            },
            _h),
        favoriteIcon: (_j = {
                fontSize: 12
            },
            _j[MediaBreakPointUp.MD] = {
                fontSize: 14,
            },
            _j),
    });
    var showNextLesson = function () { return isNextLesson && !lesson.isCompleted; };
    var wrapperClass = [css(styles.wrapper, classObject), showNextLesson() && css(styles.isNextLesson)].join(' ');
    var showTrainer = function () {
        if (!isXL) {
            return false;
        }
        if (!isChallenge) {
            return false;
        }
        if (lesson.isDayOff) {
            return false;
        }
        return true;
    };
    return (React.createElement("div", { className: wrapperClass, onClick: handleOnClickCard, "data-test": "playlistLessonCard" },
        React.createElement("div", { className: css(styles.header) },
            onClickLessonFavoriteButton && (React.createElement(ButtonFavorite, { onClick: handleOnClickLessonFavoriteButton, isStopped: !isFavorited, buttonClassName: css(styles.favoriteBtn), iconClassName: css(styles.favoriteIcon) })),
            !lesson.isDayOff && onClickPlayButton && (React.createElement("div", { className: css(styles.playButton) },
                React.createElement(PlaylistPlayButton, { size: "small" }))),
            React.createElement(ViewCount, { className: css(styles.viewCount), viewCount: viewCount }),
            !lesson.isDayOff && React.createElement(Duration, { className: css(styles.duration), duration: lesson.duration })),
        React.createElement("div", { className: css(styles.body) },
            React.createElement("div", { className: css(styles.lessonName) }, lesson.name),
            React.createElement("div", { className: css(styles.trainerBox) },
                React.createElement(FireIcon, { classObject: styles.calIcon }),
                React.createElement("div", { className: css(styles.kcal) },
                    lesson.minCal,
                    " - ",
                    lesson.maxCal,
                    " kcal"),
                showTrainer() &&
                    lesson.trainers.map(function (v, i) {
                        return (React.createElement("div", { className: css(styles.trainer), key: i },
                            React.createElement("img", { className: css(styles.trainerImg), src: v.thumbnailURL }),
                            React.createElement("div", { className: css(styles.trainerName) }, v.name)));
                    })),
            isXL && React.createElement("div", { className: css(styles.lessonDescription) }, lesson.description))));
};
