import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var MinusIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 16,
            height: 2,
        },
    });
    var color = props.color || Colors.Primary;
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 16 2", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: color, d: "M0.709998 1.58355L0.709998 0.57635C0.709998 0.361219 0.890902 0.180315 1.10603 0.180315H14.894C15.1091 0.180315 15.29 0.361219 15.29 0.57635L15.29 1.58355C15.29 1.79868 15.1091 1.97959 14.894 1.97959H1.10603C0.886012 1.9747 0.709998 1.79868 0.709998 1.58355Z" })));
};
