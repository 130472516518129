import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
export var CoachingExplanation = function (_a) {
    var title = _a.title, titleCss = _a.titleCss, children = _a.children;
    return (React.createElement("div", { className: css(styles.note) },
        React.createElement("div", { className: css(styles.noteTitle, titleCss) }, title),
        React.createElement("div", { className: css(styles.noteDescription) }, children)));
};
var styles = StyleSheet.create({
    note: {
        backgroundColor: Colors.CyanLightest,
        color: Colors.GrayDarkest,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        padding: '36px 24px',
    },
    noteTitle: {
        fontSize: 13,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: '0.52px',
    },
    noteDescription: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: '0.48px',
    },
});
