var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
/* eslint-disable */
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import videojs from 'video.js';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { css, StyleSheet } from 'aphrodite';
import { ZIndex } from '../../constants';
import { MediaBreakPointUp } from '../../styles';
import { bind } from 'bind-decorator';
var VideoSideSuper = observer(/** @class */ (function (_super) {
    __extends(VideoSideSuper, _super);
    function VideoSideSuper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(VideoSideSuper.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement("div", { className: css(styles.container) }, this.props.vm.isFullScreen ? (React.createElement("div", { className: css(styles.titleContainer) },
                React.createElement("div", { className: css(styles.title) }, this.props.vm.title),
                React.createElement("div", { className: css(styles.seriesTitle) }, this.props.vm.seriesTitle))) : (React.createElement("div", { className: css(styles.iconContainer), onClick: this.props.onClickExit, "data-test": "exitButton" },
                React.createElement("img", { src: "/assets/images/video/back.svg", className: css(styles.icon) })))));
        }
    });
    return VideoSideSuper;
}(React.Component)));
var VjsVideoSideSuperVM = /** @class */ (function () {
    function VjsVideoSideSuperVM() {
        Object.defineProperty(this, "isFullScreen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "title", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "seriesTitle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            isFullScreen: observable,
            title: observable,
            seriesTitle: observable,
        });
    }
    Object.defineProperty(VjsVideoSideSuperVM.prototype, "setIsFullScreen", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isFullScreen) {
            this.isFullScreen = isFullScreen;
        }
    });
    Object.defineProperty(VjsVideoSideSuperVM.prototype, "setTitle", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (title) {
            this.title = title;
        }
    });
    Object.defineProperty(VjsVideoSideSuperVM.prototype, "setSeriesTitle", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (seriesTitle) {
            this.seriesTitle = seriesTitle;
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], VjsVideoSideSuperVM.prototype, "setIsFullScreen", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], VjsVideoSideSuperVM.prototype, "setTitle", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], VjsVideoSideSuperVM.prototype, "setSeriesTitle", null);
    return VjsVideoSideSuperVM;
}());
var VjsVideoSideSuper = /** @class */ (function (_super) {
    __extends(VjsVideoSideSuper, _super);
    function VjsVideoSideSuper(player, options) {
        var _this = _super.call(this, player, options) || this;
        Object.defineProperty(_this, "videoSideSuperOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "videoSideSuperPlayer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "vm", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        _this.videoSideSuperOptions = options;
        _this.videoSideSuperPlayer = player;
        _this.vm = new VjsVideoSideSuperVM();
        _this.mount = _this.mount.bind(_this);
        _this.videoSideSuperPlayer.ready(function () {
            _this.mount();
        });
        return _this;
    }
    Object.defineProperty(VjsVideoSideSuper.prototype, "mount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var root = ReactDOM.createRoot(this.el());
            root.render(React.createElement("div", { className: "vjs-video-side-super" },
                React.createElement(VideoSideSuper, { vjsComponent: this, vm: this.vm, onClickExit: this.videoSideSuperOptions.onClickExit })));
        }
    });
    return VjsVideoSideSuper;
}(videojs.getComponent('Component')));
export default VjsVideoSideSuper;
var styles = StyleSheet.create({
    container: {
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '60%',
        zIndex: ZIndex.VideoPlayButton,
    },
    titleContainer: {
        marginTop: 16,
        marginLeft: 24,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 14,
        lineHeight: '140%',
        letterSpacing: '0.04em',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    seriesTitle: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '160%',
        letterSpacing: '0.04em',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    iconContainer: (_a = {
            cursor: 'pointer',
            display: 'inline-block',
            marginTop: 14,
            marginLeft: 16,
            textAlign: 'center',
            transition: 'opacity 0.2s',
            ':hover': {
                opacity: 0.8,
            }
        },
        _a[MediaBreakPointUp.MD] = {
            marginLeft: 24,
        },
        _a),
    icon: {
        width: 24,
    },
});
