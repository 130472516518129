import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '@lean-body/components/styles/variables';
export var CloseRoundIcon = function (_a) {
    var color = _a.color, containerClassObject = _a.containerClassObject, classObject = _a.classObject, onClick = _a.onClick, size = _a.size;
    var styles = StyleSheet.create({
        container: {
            width: size || 12,
            height: size || 12,
            position: 'relative',
        },
        icon: {
            width: '100%',
            height: '100%',
            top: 0,
            cursor: 'pointer',
        },
    });
    return (React.createElement("span", { onClick: onClick, className: css(styles.container, containerClassObject) },
        React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("circle", { cx: "9", cy: "9", r: "9", fill: color || Colors.Gray }),
            React.createElement("path", { d: "M5.81201 12.8243L5.3276 12.3399C5.22413 12.2364 5.22413 12.0624 5.3276 11.9589L11.9589 5.3276C12.0624 5.22413 12.2364 5.22413 12.3399 5.3276L12.8243 5.81201C12.9277 5.91548 12.9277 6.08949 12.8243 6.19296L6.19296 12.8243C6.08479 12.9277 5.91548 12.9277 5.81201 12.8243Z", fill: "white" }),
            React.createElement("path", { d: "M5.81201 12.8243L5.3276 12.3399C5.22413 12.2364 5.22413 12.0624 5.3276 11.9589L11.9589 5.3276C12.0624 5.22413 12.2364 5.22413 12.3399 5.3276L12.8243 5.81201C12.9277 5.91548 12.9277 6.08949 12.8243 6.19296L6.19296 12.8243C6.08479 12.9277 5.91548 12.9277 5.81201 12.8243Z", fill: "white" }),
            React.createElement("path", { d: "M12.781 12.3817L12.3812 12.7815C12.2543 12.9085 12.0473 12.9085 11.9157 12.7815L5.36428 6.23013C5.2373 6.10315 5.2373 5.89622 5.36428 5.76453L5.76404 5.36477C5.89103 5.23779 6.09797 5.23779 6.22966 5.36477L12.781 11.9161C12.9127 12.0478 12.9127 12.2547 12.781 12.3817Z", fill: "white" }),
            React.createElement("path", { d: "M12.781 12.3817L12.3812 12.7815C12.2543 12.9085 12.0473 12.9085 11.9157 12.7815L5.36428 6.23013C5.2373 6.10315 5.2373 5.89622 5.36428 5.76453L5.76404 5.36477C5.89103 5.23779 6.09797 5.23779 6.22966 5.36477L12.781 11.9161C12.9127 12.0478 12.9127 12.2547 12.781 12.3817Z", fill: "white" }))));
};
