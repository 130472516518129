var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Input } from '@lean-body/components/atoms';
import { SearchIcon } from '@lean-body/components/atoms/icons/search_icon';
export var InputSearch = function (props) {
    var className = props.className, onChangeKeyword = props.onChangeKeyword, keywordValue = props.keywordValue, submitDisabled = props.submitDisabled, onSubmit = props.onSubmit, onFocus = props.onFocus, onClick = props.onClick, onClickClear = props.onClickClear, autoFocus = props.autoFocus;
    var containerClass = [className || '', css(styles.container)].join(' ');
    var onInputSubmit = function (e) {
        if (submitDisabled)
            return e.preventDefault();
        onSubmit(e);
    };
    var onInputClick = function (e) {
        e.stopPropagation();
        if (onClick)
            onClick();
    };
    return (React.createElement("div", { className: containerClass },
        React.createElement("form", { className: css(styles.inputForm), onSubmit: onInputSubmit, onFocus: onFocus, onClick: onInputClick, "data-test": "submitForm" },
            React.createElement(Input, { type: "text", onChange: function (e) { return onChangeKeyword(e.target.value); }, onClickClearBtn: onClickClear, value: keywordValue, classObject: [styles.input, autoFocus && styles.inputFocus], dataTest: "inputSearch", autoFocus: autoFocus, height: 32 }),
            !keywordValue && (React.createElement("div", { className: css(styles.inputPlaceholder, autoFocus && styles.inputFocusPlaceholder), onClick: onFocus },
                !autoFocus && React.createElement(SearchIcon, { color: Colors.Black, classObject: styles.searchIcon }),
                "\u30AD\u30FC\u30EF\u30FC\u30C9\u3067\u3055\u304C\u3059")))));
};
var styles = StyleSheet.create({
    container: (_a = {
            position: 'relative',
            borderRadius: 16,
            display: 'flex'
        },
        _a[MediaBreakPointUp.SM] = {
            minWidth: 350,
        },
        _a),
    inputForm: {
        width: '100%',
    },
    input: {
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 400,
        color: Colors.Black,
        paddingRight: 0,
        border: 'none',
        borderRadius: 16,
        backgroundColor: Colors.BackgroundLightGray,
    },
    inputFocus: {
        textAlign: 'left',
    },
    inputPlaceholder: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: Colors.GrayDarkest,
        fontSize: 14,
        cursor: 'pointer',
        pointerEvents: 'none',
    },
    inputFocusPlaceholder: {
        left: 12,
        transform: 'translate(0, -50%)',
        cursor: 'unset',
    },
    searchIcon: {
        verticalAlign: 'middle',
        marginRight: 6,
    },
});
