import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { CourseFeatureThumbnail } from '@lean-body/components/atoms';
export var CourseGoal = function (_a) {
    var goal = _a.goal, config = _a.config, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(CourseFeatureThumbnail, { src: goal.thumbnailURL(config) }),
        React.createElement("div", { className: css(styles.name) }, goal.name)));
};
var styles = StyleSheet.create({
    container: {
        letterSpacing: '0.04em',
        color: Colors.Black,
    },
    name: {
        fontWeight: 500,
        fontSize: 12,
        textAlign: 'center',
    },
});
