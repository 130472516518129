import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var ScrollIcon = function (_a) {
    var classObject = _a.classObject, onClick = _a.onClick;
    var styles = StyleSheet.create({
        icon: {
            width: 40,
            height: 40,
        },
    });
    return (React.createElement("svg", { className: css(styles.icon, classObject), onClick: onClick, viewBox: "0 0 60 60", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { filter: "url(#filter0_d_2897_15141)" },
            React.createElement("circle", { cx: "30", cy: "28", r: "20", transform: "rotate(90 30 28)", fill: Colors.White }),
            React.createElement("circle", { cx: "30", cy: "28", r: "20", transform: "rotate(90 30 28)", fill: "url(#paint0_linear_2897_15141)" }),
            React.createElement("path", { d: "M39.8025 25.098L30.8506 33.8987C30.3747 34.3671 29.6152 34.3671 29.1392 33.8987L20.1975 25.098C19.9342 24.8389 19.9342 24.4302 20.1975 24.1711L21.1797 23.2043C21.443 22.9452 21.8582 22.9452 22.1215 23.2043L30 30.9485L37.8785 23.1944C38.1418 22.9352 38.557 22.9352 38.8203 23.1944L39.8025 24.1611C40.0658 24.4203 40.0658 24.8389 39.8025 25.098Z", fill: Colors.Black })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_2897_15141", x: "0", y: "0", width: "60", height: "60", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" },
                React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "2" }),
                React.createElement("feGaussianBlur", { stdDeviation: "5" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2897_15141" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2897_15141", result: "shape" })),
            React.createElement("linearGradient", { id: "paint0_linear_2897_15141", x1: "30", y1: "8", x2: "30", y2: "48", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: Colors.White }),
                React.createElement("stop", { offset: "1", stopColor: Colors.White, stopOpacity: "0" })))));
};
