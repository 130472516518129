import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { ReplaceIcon, CalendarIcon, ArrowIcon, ArrowDirection, StopIcon } from '@lean-body/components/atoms';
export var CourseSettingMenu = function (_a) {
    var isSM = _a.isSM, classObject = _a.classObject, onClickEditSchedule = _a.onClickEditSchedule, onClickChangeCourse = _a.onClickChangeCourse, onClickResetCourse = _a.onClickResetCourse;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.item), onClick: onClickChangeCourse },
            React.createElement("div", { className: css(styles.title) },
                React.createElement(ReplaceIcon, { classObject: styles.Icon }),
                "\u30B3\u30FC\u30B9\u306E\u5909\u66F4"),
            isSM && React.createElement(ArrowIcon, { direction: ArrowDirection.right, color: Colors.TextDark, classObject: styles.arrow })),
        React.createElement("div", { className: css(styles.item), onClick: onClickEditSchedule },
            React.createElement("div", { className: css(styles.title) },
                React.createElement(CalendarIcon, { classObject: styles.Icon }),
                "\u30B9\u30B1\u30B8\u30E5\u30FC\u30EB\u8A2D\u5B9A"),
            isSM && React.createElement(ArrowIcon, { direction: ArrowDirection.right, color: Colors.TextDark, classObject: styles.arrow })),
        React.createElement("div", { className: css(styles.item), onClick: onClickResetCourse },
            React.createElement("div", { className: css(styles.title) },
                React.createElement(StopIcon, { classObject: styles.Icon }),
                React.createElement("span", { className: css(styles.courseResetTitle) }, "\u30B3\u30FC\u30B9\u3092\u30EA\u30BB\u30C3\u30C8")),
            isSM && React.createElement(ArrowIcon, { direction: ArrowDirection.right, color: Colors.TextDark, classObject: styles.arrow }))));
};
var styles = StyleSheet.create({
    container: {},
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        alignItems: 'center',
        height: 60,
    },
    arrow: {
        height: 25,
    },
    Icon: {
        marginRight: 20,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    courseResetTitle: {
        color: Colors.Alert,
    },
});
