import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../styles';
import { MealMenuSearchEmptyStateIcon } from '../atoms/icons/meal_menu_search_empty_state_icon';
export var MealMenuSearchEmptyState = function (_a) {
    var classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(MealMenuSearchEmptyStateIcon, null),
        React.createElement("div", { className: css(styles.title) }, "\u8A72\u5F53\u3059\u308B\u30E1\u30CB\u30E5\u30FC\u304C\u3042\u308A\u307E\u305B\u3093")));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 12,
    },
    title: {
        color: Colors.GrayDarkest,
        marginBottom: 10,
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
});
