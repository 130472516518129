import { MealMenu } from './meal';
var MealImageAnalysis = /** @class */ (function () {
    function MealImageAnalysis(data) {
        var _a;
        Object.defineProperty(this, "position", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "menus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.position = data.position ? new MealImageAnalysisPosition(data.position) : null;
        this.menus = (_a = data.menus) === null || _a === void 0 ? void 0 : _a.map(function (mealMenuData) { return new MealMenu(mealMenuData); });
    }
    return MealImageAnalysis;
}());
export { MealImageAnalysis };
var MealImageAnalysisPosition = /** @class */ (function () {
    function MealImageAnalysisPosition(data) {
        Object.defineProperty(this, "xmin", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "xmax", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "ymin", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "ymax", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.xmin = data.xmin;
        this.xmax = data.xmax;
        this.ymin = data.ymin;
        this.ymax = data.ymax;
    }
    return MealImageAnalysisPosition;
}());
export { MealImageAnalysisPosition };
