var _a;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { ChallengeProgramSectionTitle } from '@lean-body/components/atoms';
import { MediaBreakPointUp } from '@lean-body/components/styles';
export var NextLessonDayHeader = function (props) {
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.subTitle) }, "NEXT LESSON"),
        React.createElement(ChallengeProgramSectionTitle, { title: "DAY ".concat(props.day) })));
};
var styles = StyleSheet.create({
    container: (_a = {},
        _a[MediaBreakPointUp.MD] = {
            marginLeft: '1rem',
        },
        _a),
    title: {
        display: 'flex',
        flexFlow: 'column',
    },
    subTitle: {
        fontSize: '0.8rem',
        fontWeight: 500,
        color: '#979797',
        marginBottom: '2px',
    },
    sectionTitle: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },
});
