import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CourseDayStatusIcon } from '@lean-body/components/atoms';
import { Colors } from '@lean-body/components/styles';
export var CourseRewardDayHeader = function (_a) {
    var day = _a.day, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", null,
            React.createElement("div", { className: css(styles.title) },
                "DAY",
                day.day),
            React.createElement("div", { className: css(styles.info) },
                day.courseDaysLessons.length,
                "\u30EC\u30C3\u30B9\u30F3 / ",
                day.totalDuration,
                "\u5206")),
        React.createElement(CourseDayStatusIcon, { classObject: styles.medalIcon, completed: day.isFirstCompleted, animate: true, loopAnimation: true })));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
    },
    title: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '160%',
        letterSpacing: '0.08em',
        color: Colors.TextDark,
    },
    info: {
        fontWeight: 500,
        fontSize: 14,
        color: Colors.GrayDarkest,
    },
    medalIcon: {
        position: 'absolute',
        right: -10,
    },
});
