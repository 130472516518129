var _a, _b, _c, _d;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Dialog as DialogComponent } from '@lean-body/components/molecules';
import { MediaBreakPointUp, Colors } from '@lean-body/components/styles';
import { DialogPageName } from '@lean-body/src/domain/dialog';
import { ButtonType, Button } from '../atoms';
import { useNavigate } from 'react-router';
export var ReleaseNotificationModal = function (_a) {
    var close = _a.close, dialog = _a.dialog;
    var navigate = useNavigate();
    var linkText;
    if (dialog.buttonLabel) {
        linkText = dialog.buttonLabel;
    }
    else if (dialog.pageName === DialogPageName.activity) {
        linkText = 'アクティビティを見る';
    }
    else {
        linkText = '詳細を見てみる';
    }
    var moveToLink = function () {
        if (dialog.linkPath) {
            close();
            navigate(dialog.linkPath);
        }
        else {
            window.open(dialog.linkUrl, '_blank');
        }
    };
    return (React.createElement(DialogComponent, { onClickCancel: close, isCloseButton: true, classObject: styles.dialog, content: React.createElement("div", { className: css(styles.container) },
            React.createElement("img", { className: css(styles.headImg), src: dialog.thumbnailURL }),
            React.createElement("div", { className: css(styles.content) },
                React.createElement("div", { className: css(styles.title) }, dialog.title),
                React.createElement("div", { className: css(styles.description) },
                    React.createElement("p", null, dialog.description)),
                (dialog.linkPath || dialog.linkUrl) && (React.createElement(Button, { buttonType: ButtonType.pink, className: css(styles.button), onClick: moveToLink, isRadius: true },
                    React.createElement("div", { className: css(styles.buttonText) }, linkText))))) }));
};
var styles = StyleSheet.create({
    container: {
        borderRadius: 8,
        overflow: 'hidden',
        backgroundColor: Colors.White,
    },
    dialog: {
        top: '50%',
    },
    content: (_a = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '16px 18px 20px'
        },
        _a[MediaBreakPointUp.XL] = {
            paddingBottom: 30,
        },
        _a),
    headImg: {
        width: '100%',
    },
    title: (_b = {
            fontWeight: 600,
            textAlign: 'center',
            whiteSpace: 'pre-wrap'
        },
        _b[MediaBreakPointUp.XL] = {
            fontSize: 18,
        },
        _b),
    description: {
        fontSize: 14,
        margin: '8px 0 16px',
        lineHeight: '21px',
        fontWeight: 400,
        whiteSpace: 'pre-wrap',
    },
    button: (_c = {
            display: 'block',
            width: '100%',
            minHeight: 48
        },
        _c[MediaBreakPointUp.XL] = {
            width: 'auto',
            minWidth: 320,
            minHeight: 60,
        },
        _c),
    buttonText: (_d = {
            fontSize: 16,
            fontWeight: 'bold'
        },
        _d[MediaBreakPointUp.XL] = {
            fontSize: 20,
        },
        _d),
});
