import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
export var DailyHistory = function (_a) {
    var date = _a.date, value = _a.value, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.date) }, date.locale('ja').format('M月D日 ddd曜日')),
        React.createElement("div", { className: css(styles.value) },
            " ",
            value)));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 24px',
    },
    date: {
        color: Colors.TextDark,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
    value: {
        color: Colors.GrayDarkest,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
});
