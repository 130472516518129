var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CourseRewardVolumeInfo, CourseVolumeProgress } from '@lean-body/components/molecules';
import { CourseCompleteCrownIcon } from '@lean-body/components/atoms';
import { MediaBreakPointUp, Colors } from '@lean-body/components/styles';
export var CourseRewardVolume = function (_a) {
    var volume = _a.volume, courseName = _a.courseName, classObject = _a.classObject;
    if (volume === null) {
        return;
    }
    var progressText = volume.isFirstCompleted ? '完了！' : "\u6B8B\u308A".concat(volume.restOfDays, "\u65E5\uFF01");
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(CourseRewardVolumeInfo, { courseName: courseName, volume: volume, progressText: progressText }),
        React.createElement("div", null, volume.isFirstCompleted ? (React.createElement(CourseCompleteCrownIcon, { animate: true, loopAnimation: true })) : (React.createElement(CourseVolumeProgress, { totalDays: volume.totalDays, finishedDays: volume.finishedDays, isAnimated: true })))));
};
var styles = StyleSheet.create({
    container: (_a = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: "1px solid ".concat(Colors.BorderLightGray),
            borderRadius: 10,
            padding: '20.5px 23.5px',
            background: Colors.White
        },
        _a[MediaBreakPointUp.MD] = {
            padding: '20.5px 30px',
        },
        _a),
    day: {
        marginTop: 8,
    },
});
