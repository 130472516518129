import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { Tab, Tabs as MauTabs } from '@mui/material';
var TAB_HEIGHT = 30;
export var UnderbarTabs = function (props) {
    var setTab = props.setTab, currentTab = props.currentTab, tabs = props.tabs, classObject = props.classObject, flexContainerClassObject = props.flexContainerClassObject, tabRootClassObject = props.tabRootClassObject;
    var onChangeTab = function (_, v) {
        setTab(v);
    };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(MauTabs, { value: currentTab, onChange: onChangeTab, variant: "scrollable", scrollButtons: "auto", classes: {
                root: css(styles.tabWrapper),
                indicator: css(styles.indicator),
                flexContainer: css(flexContainerClassObject),
            } }, Object.keys(tabs).map(function (key, i) {
            return (React.createElement(Tab, { label: tabs[key], classes: {
                    root: css(styles.tabName, tabRootClassObject),
                    selected: css(styles.selectedTabName),
                }, value: tabs[key], "data-test": key, key: i }));
        }))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    tabWrapper: {
        display: 'inline-block',
        minHeight: TAB_HEIGHT,
        height: TAB_HEIGHT,
        color: Colors.GrayDarkest,
    },
    indicator: {
        backgroundColor: "".concat(Colors.PrimaryDark),
        height: 3,
        transform: 'scaleX(0.32)',
    },
    tabName: {
        position: 'relative',
        fontSize: 14,
        fontWeight: 'bold',
        minHeight: TAB_HEIGHT,
        padding: '0 9px',
        transition: 'color 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms', // material-uiに合わせてる
        minWidth: 0, // material-uiのデフォルトのminWidthが大きすぎるので消す
    },
    selectedTabName: {
        color: Colors.Black,
    },
});
