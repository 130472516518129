var _a, _b;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { DialogCloseButton } from '../atoms/dialog_close_button';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
export var DIALOG_WIDTH = 327;
export var DIALOG_WIDTH_XL = 519;
export var DIALOG_IMAGE_HEIGHT = 184;
export var DIALOG_IMAGE_HEIGHT_XL = 292;
var DEFAULT_BORDER_RADIUS = 8;
export var Dialog = function (_a) {
    var classObject = _a.classObject, onClickCancel = _a.onClickCancel, content = _a.content, closeButtonLabel = _a.closeButtonLabel, disableBackgroundCancel = _a.disableBackgroundCancel, isCloseButton = _a.isCloseButton, isBottom = _a.isBottom, borderRadius = _a.borderRadius, strictSameSize = _a.strictSameSize;
    var onClickBackground = function () {
        if (disableBackgroundCancel)
            return;
        onClickCancel();
    };
    var onClickDialog = function (e) {
        e.stopPropagation();
    };
    if (!borderRadius) {
        borderRadius = DEFAULT_BORDER_RADIUS;
    }
    var additionalStyles = StyleSheet.create({
        borderRadius: {
            borderRadius: isBottom ? "".concat(borderRadius, "px ").concat(borderRadius, "px 0 0") : borderRadius,
        },
    });
    return (React.createElement("div", { className: css(styles.background), onClick: onClickBackground },
        React.createElement("div", { className: css(styles.dialogContent, strictSameSize && styles.dialogContentSameSize, isBottom ? styles.bottom : styles.top, additionalStyles.borderRadius, classObject), "data-test": "dialog", onClick: onClickDialog },
            content,
            isCloseButton && React.createElement(DialogCloseButton, { label: closeButtonLabel, onClick: onClickCancel }))));
};
var styles = StyleSheet.create({
    dialogContent: (_a = {
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, -50%);',
            width: DIALOG_WIDTH,
            backgroundColor: Colors.GrayLight
        },
        _a[MediaBreakPointUp.XL] = {
            width: DIALOG_WIDTH_XL,
        },
        _a),
    dialogContentSameSize: (_b = {},
        _b[MediaBreakPointUp.XL] = {
            width: DIALOG_WIDTH,
        },
        _b),
    top: {
        top: '50%', // FIXME: 下に張り付くダイアログのデザインが多いのでtopから計算しないように上位コンポーネントのCSSをbottomで計算するようにしてここは消す
    },
    bottom: {
        transform: 'translate(-50%, 0)',
        bottom: 0,
        width: '100%',
    },
    background: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
        background: Colors.BackgroundDialog,
    },
});
