import { Lesson } from '.';
var LessonSection = /** @class */ (function () {
    function LessonSection(lessonSectionData, config) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isPublish", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isRandomLessonOrder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = lessonSectionData.id;
        this.name = lessonSectionData.name;
        this.isPublish = lessonSectionData.isPublish;
        this.isRandomLessonOrder = lessonSectionData.isRandomLessonOrder;
        this.orderIndex = lessonSectionData.orderIndex;
        this.lessons = lessonSectionData.lessons.map(function (v) { return new Lesson(v, config); });
    }
    return LessonSection;
}());
export { LessonSection };
