var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
import { StarIcon } from '../atoms/icons/star_icon';
import { MealScoreLowIcon } from '../atoms/icons/meal_score_low_icon';
import { MealScoreMiddleIcon } from '../atoms/icons/meal_score_middle_icon';
import { MealScoreHighIcon } from '../atoms/icons/meal_score_high_icon';
export var MealScoreIcons = function (_a) {
    var score = _a.score, isStar = _a.isStar, isDetailPage = _a.isDetailPage;
    return (React.createElement("div", { className: css(styles.container, isStar && styles.starIconContainer) }, isStar ? (React.createElement(React.Fragment, null, __spreadArray([], Array(5), true).map(function (_, i) {
        return (React.createElement(StarIcon, { classObject: isDetailPage && styles.starIcon, color: i < score && Colors.ProgressGreen, key: i }));
    }))) : (React.createElement(React.Fragment, null,
        React.createElement(MealScoreLowIcon, { classObject: isDetailPage && styles.icon, color: score == 1 && Colors.Orange }),
        React.createElement(MealScoreMiddleIcon, { classObject: isDetailPage && styles.icon, color: score == 2 && Colors.Yellow }),
        React.createElement(MealScoreHighIcon, { classObject: isDetailPage && styles.icon, color: score == 3 && Colors.ProgressGreen })))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
    },
    starIconContainer: {
        gap: 2,
    },
    starIcon: {
        width: 36,
        height: 36,
    },
    icon: {
        width: 30,
        height: 30,
    },
});
