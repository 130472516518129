var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useSmMediaQuery } from '@lean-body/src/util';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { CourseDayHeader, CourseDayLessons, CourseRewardDayHeader } from '@lean-body/components/molecules';
export var CourseDayItem = function (_a) {
    var isReward = _a.isReward, day = _a.day, apiClient = _a.apiClient, classObject = _a.classObject, onClick = _a.onClick;
    var isSM = useSmMediaQuery();
    return (React.createElement("div", { className: css(styles.container, classObject) },
        isReward ? (React.createElement(CourseRewardDayHeader, { day: day, classObject: styles.header })) : (React.createElement(CourseDayHeader, { day: day, classObject: styles.header })),
        React.createElement(CourseDayLessons, { courseLessons: day.courseDaysLessons, apiClient: apiClient, isMin: isSM, isVertical: true, classObject: styles.lessons, disableHistoryPush: isReward, onClick: onClick })));
};
var styles = StyleSheet.create({
    container: (_a = {
            backgroundColor: Colors.White,
            border: "1px solid ".concat(Colors.BorderLightGray),
            borderRadius: 10,
            padding: '12px 10px'
        },
        _a[MediaBreakPointUp.MD] = {
            padding: 20,
        },
        _a),
    header: {
        margin: '0px 12px',
    },
    lessons: (_b = {
            marginTop: 10
        },
        _b[MediaBreakPointUp.MD] = {
            marginTop: 20,
        },
        _b[MediaBreakPointUp.XL] = {
            margin: '20 auto 0 0',
        },
        _b),
});
