var _a;
import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { MediaBreakPointUp } from '@lean-body/components/styles';
import { PlaylistComponentSquare } from '../molecules';
import { useXlMediaQuery } from '@lean-body/src/util/media_query_hooks';
export var PlaylistTileCarousel = function (_a) {
    var playlists = _a.playlists, classObject = _a.classObject, addFavoriteProgram = _a.addFavoriteProgram, unFavoriteProgram = _a.unFavoriteProgram;
    var isPC = useXlMediaQuery();
    var pseudoElementAfterWidth = 170; // PC以外のサイズは疑似要素に１コンポーネント分のwidth（マージンも含めて１７０ｐｘ）を持つblockを入れるだけでタイルが左寄せになってくれます
    if (isPC) {
        var numberOfColumnsPCSize = 6;
        pseudoElementAfterWidth =
            (numberOfColumnsPCSize - (playlists.length % numberOfColumnsPCSize)) * pseudoElementAfterWidth;
    }
    var containerStyle = StyleSheet.create({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            ':after': {
                content: '""',
                display: 'block',
                width: "".concat(pseudoElementAfterWidth, "px"),
                marginBottom: 10,
            },
        },
    });
    return (React.createElement("div", { className: css(containerStyle.container, classObject) }, !!playlists &&
        playlists.map(function (v, i) {
            return (React.createElement(PlaylistComponentSquare, { classObject: styles.playlistComponentWrapper, playlist: v, key: i, addFavoriteProgram: addFavoriteProgram, unFavoriteProgram: unFavoriteProgram }));
        })));
};
var styles = StyleSheet.create({
    playlistComponentWrapper: (_a = {
            maxWidth: '48.5%',
            width: '100%',
            marginBottom: 8
        },
        _a[MediaBreakPointUp.MD] = {
            maxWidth: '163px',
            marginBottom: 10,
        },
        _a),
});
