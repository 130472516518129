import { Program } from '.';
import { Playlist } from './playlist';
import { Lesson } from './lesson';
export var SEARCH_LIMIT_PER_PAGE = 20;
export var SEARCH_PAGE_INIT = 1;
var SearchResult = /** @class */ (function () {
    function SearchResult(searchData, config) {
        Object.defineProperty(this, "lessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "programs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "playlists", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "lessonsCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "programsCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playlistsCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "perPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        if (searchData.lessons) {
            this.lessons = searchData.lessons.map(function (o) { return new Lesson(o, config); });
        }
        if (searchData.programs) {
            this.programs = searchData.programs.map(function (o) { return new Program(o, config); });
        }
        if (searchData.playlists) {
            this.playlists = searchData.playlists.map(function (o) { return new Playlist(o, config); });
        }
        this.lessonsCount = searchData.lessonsCount;
        this.programsCount = searchData.programsCount;
        this.playlistsCount = searchData.playlistsCount;
        this.perPage = searchData.perPage;
    }
    return SearchResult;
}());
export { SearchResult };
