var _a, _b, _c;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { InitialGuideHowToDetail } from '../molecules';
import { InitialGuideQA } from '../molecules/initial_guide_qa';
export var InitialGuideMyLessonDetail = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.sectionTitle) }, "MY\u30EC\u30C3\u30B9\u30F3\u3068\u306F\uFF1F"),
            React.createElement("div", { className: css(styles.sectionTitleBorder) }),
            React.createElement("div", { className: css(styles.topImgBox) },
                React.createElement("img", { className: css(styles.topImg), src: "/assets/images/initial_guide/my_lesson/my_lesson_top.png" })),
            React.createElement("div", { className: css(styles.description) },
                "\u3042\u306A\u305F\u304C\u8208\u5473\u306E\u3042\u308B\u30EC\u30C3\u30B9\u30F3\u3092\u307E\u3068\u3081\u3066\u78BA\u8A8D\u3067\u304D\u308B\u30DA\u30FC\u30B8\u3067\u3059\u3002",
                React.createElement("br", null),
                "\u304A\u6C17\u306B\u5165\u308A\u306E\u30EC\u30C3\u30B9\u30F3\u306F\u3001\u3053\u3053\u304B\u3089\u3059\u3050\u306B\u518D\u751F\u3067\u304D\u307E\u3059\u3002\u30EC\u30C3\u30B9\u30F3\u7BA1\u7406\u306B\u3068\u3066\u3082\u4FBF\u5229\u306A\u306E\u3067\u305C\u3072\u6D3B\u7528\u3057\u3066\u307F\u307E\u3057\u3087\u3046\uFF01")),
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.howToSection) },
                React.createElement("div", { className: css(styles.sectionTitle) }, "MY\u30EC\u30C3\u30B9\u30F3\u3067\u3067\u304D\u308B\u3053\u3068"),
                React.createElement("div", { className: css(styles.sectionTitleBorder) }),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/my_lesson/check_lesson_history.png", classObject: styles.howToDetail, imgClassObject: styles.checkLessonHistoryImg, title: "\u8996\u8074\u5C65\u6B74\u3092\u78BA\u8A8D" }, "\u300C\u3042\u308C\u3001\u3042\u306E\u30EC\u30C3\u30B9\u30F3\u3069\u3053\u884C\u3063\u305F\uFF1F\u300D\u3092\u89E3\u6C7A\uFF01"),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/my_lesson/check_favorite.png", classObject: styles.howToDetail, imgClassObject: styles.checkFavoriteImg, title: "\u304A\u6C17\u306B\u5165\u308A\u3092\u78BA\u8A8D" }, "\u304A\u6C17\u306B\u5165\u308A\u3057\u305F\u30EC\u30C3\u30B9\u30F3/\u30B7\u30EA\u30FC\u30BA/\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u898B\u8FD4\u3059\u3053\u3068\u304C\u3067\u304D\u307E\u3059"),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/my_lesson/check_live_lesson.png", classObject: styles.howToDetail, imgClassObject: styles.checkLiveLessonImg, title: "\u53C2\u52A0\u4E88\u5B9A\u306ELIVE\u30EC\u30C3\u30B9\u30F3\u3092\u78BA\u8A8D" }, "\u53C2\u52A0\u3057\u305F\u3044LIVE\u30EC\u30C3\u30B9\u30F3\u306E\u4E88\u5B9A\u3092\u9003\u3055\u306A\u3044\uFF01"),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/my_lesson/check_challenge_playlist.png", imgClassObject: styles.checkChallengePlaylistImg, title: "\u30C1\u30E3\u30EC\u30F3\u30B8\u4E2D\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u78BA\u8A8D" }, "\u30C1\u30E3\u30EC\u30F3\u30B8\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u7D9A\u304D\u304B\u3089\u518D\u751F\u3059\u308B\u3068\u304D\u306F\u3053\u3053\u304B\u3089\uFF01"))),
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u3088\u304F\u3042\u308B\u8CEA\u554F"),
            React.createElement("div", { className: css(styles.sectionTitleBorder) }),
            React.createElement(InitialGuideQA, { question: "\u53C2\u52A0\u3057\u3066\u3044\u306A\u3044LIVE\u30EC\u30C3\u30B9\u30F3\u304C\u8996\u8074\u5C65\u6B74\u306B\u3042\u308B" }, "\u53C2\u52A0\u524D\u3067\u3082LIVE\u30EC\u30C3\u30B9\u30F3\u30DA\u30FC\u30B8\u3092\u958B\u304F\u3068\u8996\u8074\u5C65\u6B74\u306B\u5165\u308A\u307E\u3059\u3002\u3054\u4E86\u627F\u304F\u3060\u3055\u3044\u3002\u203B\u30A2\u30AF\u30C6\u30A3\u30D3\u30C6\u30A3\u306E\u30EC\u30C3\u30B9\u30F3\u6642\u9593\u306F\u52A0\u7B97\u3055\u308C\u307E\u305B\u3093\u3002"))));
};
var styles = StyleSheet.create({
    section: (_a = {
            marginBottom: 40,
            paddingTop: 30,
            paddingLeft: 24,
            paddingRight: 24
        },
        _a[MediaBreakPointUp.MD] = {
            marginBottom: 0,
            paddingLeft: 40,
            paddingRight: 40,
        },
        _a),
    sectionTitle: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: '1.44px',
        height: 25,
    },
    sectionTitleBorder: {
        border: "3px solid ".concat(Colors.PrimaryDark),
        borderRadius: 3,
        width: 60,
        margin: '12px auto 30px',
    },
    topImgBox: {
        textAlign: 'center',
        marginTop: 35,
        marginBottom: 35,
    },
    topImg: {
        height: 95,
    },
    description: (_b = {
            backgroundColor: Colors.CyanLightest,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '160%',
            letterSpacing: '0.56px',
            padding: 24,
            marginBottom: 40,
            borderRadius: 10
        },
        _b[MediaBreakPointUp.MD] = {
            marginBottom: 10,
        },
        _b),
    howToSection: (_c = {},
        _c[MediaBreakPointUp.MD] = {
            marginLeft: 60,
            marginRight: 60,
        },
        _c),
    howToDetail: {
        marginBottom: 30,
    },
    checkLessonHistoryImg: {
        height: 162,
    },
    checkFavoriteImg: {
        height: 166,
    },
    checkLiveLessonImg: {
        height: 168,
    },
    checkChallengePlaylistImg: {
        height: 180,
    },
});
