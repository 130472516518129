import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles/variables';
import { ZIndex } from '../constants';
import { PlaylistLabelInfo } from '@lean-body/components/atoms/playlist_label_info';
import { ImageBlurSquare } from '@lean-body/components/atoms/image_blur_square';
import { FavoriteWithCount } from '@lean-body/components/molecules';
import { TwitterWithText } from '../molecules/twitter_with_text';
export var PlaylistSummaryHeader = function (_a) {
    var playlist = _a.playlist, totalFavoriteCount = _a.totalFavoriteCount, creator = _a.creator, className = _a.className, isFavorited = _a.isFavorited, numOfLessons = _a.numOfLessons, programTotalMinCal = _a.programTotalMinCal, programTotalMaxCal = _a.programTotalMaxCal, updatedDiff = _a.updatedDiff, isOriginalPlaylist = _a.isOriginalPlaylist, onClickFavorite = _a.onClickFavorite, twitterService = _a.twitterService, onClickTwitterShareIfAuthed = _a.onClickTwitterShareIfAuthed;
    // スクロール量がヘッダーの高さを超えているときtrue
    var _b = React.useState(false), isHeightOver = _b[0], setIsHeightOver = _b[1];
    var playlistName = playlist.name;
    var thumbnailURL = playlist.thumbnailURL;
    var totalDuration = playlist.totalDuration;
    React.useEffect(function () {
        var scrollAction = function () {
            if (196 > window.scrollY) {
                setIsHeightOver(false);
            }
            else {
                setIsHeightOver(true);
            }
        };
        window.addEventListener('scroll', scrollAction, {
            capture: false,
            passive: true,
        });
        scrollAction();
        return function () {
            window.removeEventListener('scroll', scrollAction);
        };
    }, []);
    // スクロールが一定量に満たない場合は表示しない
    if (!isHeightOver) {
        return null;
    }
    var wrapperClass = [className || '', css(styles.wrapper)].join(' ');
    return (React.createElement("div", { className: wrapperClass },
        React.createElement(ImageBlurSquare, { src: thumbnailURL, height: 104 }),
        React.createElement("div", { className: css(styles.container) },
            React.createElement("div", { className: css(styles.imageBox) },
                React.createElement("img", { className: css(styles.image), src: thumbnailURL, alt: "" })),
            React.createElement("div", { className: css(styles.playlistInfo) },
                React.createElement("div", { className: css(styles.playlistTitle) }, playlistName),
                React.createElement("div", { className: css(styles.playlistCreator) },
                    React.createElement("span", { className: css(styles.creatorImgContainer) },
                        React.createElement("img", { src: creator.iconSrc, height: "22" })),
                    React.createElement("span", { className: css(styles.creatorName) }, creator.name)),
                React.createElement(PlaylistLabelInfo, { numOfLessons: numOfLessons, programTotalMinCal: programTotalMinCal, programTotalMaxCal: programTotalMaxCal, totalDuration: totalDuration, updatedDiff: updatedDiff, className: css(styles.labelInfo) })),
            !isOriginalPlaylist && (React.createElement("div", { className: css(styles.userActionRow) },
                React.createElement(FavoriteWithCount, { favCount: totalFavoriteCount, onClick: onClickFavorite, isStoppedFav: !isFavorited, classObject: styles.favoriteWithCount }),
                React.createElement(TwitterWithText, { text: "\u30B7\u30A7\u30A2\u3059\u308B", twitterService: twitterService, onClickTwitterShareIfAuthed: onClickTwitterShareIfAuthed, playlist: playlist }))))));
};
var styles = StyleSheet.create({
    wrapper: {
        position: 'fixed',
        top: 0,
        width: 'calc(100vw - 256px)',
        backgroundColor: Colors.BackgroundLightGray,
        padding: '20px 40px',
        zIndex: ZIndex.PlaylistHeader,
    },
    container: {
        display: 'flex',
        maxWidth: 988,
        paddingRight: 5,
    },
    blur: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.4)',
        backdropFilter: 'blur(200px)',
    },
    imageBox: {
        zIndex: ZIndex.PlaylistHeader + 1,
    },
    image: {
        width: 64,
    },
    playlistInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0 24px',
        zIndex: ZIndex.PlaylistHeader + 1,
    },
    playlistTitle: {
        fontWeight: 'bold',
        fontSize: 14,
        margin: 0,
        lineHeight: '1.75',
    },
    playlistCreator: {
        display: 'flex',
        alignItems: 'center',
    },
    creatorImgContainer: {
        width: 22,
        height: 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.White,
        borderRadius: '15px',
        border: "1px solid ".concat(Colors.BorderLightGrayB),
        overflow: 'hidden',
    },
    creatorName: {
        marginLeft: 8,
        fontSize: 11,
        fontWeight: 'bold',
    },
    userActionRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: 250,
        gap: 15,
        marginTop: 8,
        marginLeft: 'auto',
        zIndex: ZIndex.PlaylistHeader + 1,
    },
    favoriteWithCount: {
        width: 100,
    },
    labelInfo: {
        marginTop: 1,
    },
});
