import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors } from '@lean-body/components/styles';
import { INPUT_TYPE_NUMBER } from '@lean-body/src/domain';
export var InputWithoutOutline = function (_a) {
    var classObject = _a.classObject, type = _a.type, value = _a.value, unitLabel = _a.unitLabel, placeholder = _a.placeholder, width = _a.width, onChange = _a.onChange;
    var styles = StyleSheet.create({
        container: {
            position: 'relative',
            textAlign: 'center',
        },
        inputContainer: {
            borderBottom: "2px solid ".concat(Colors.BorderLightGray),
            paddingBottom: 30,
        },
        input: {
            backgroundColor: Colors.White,
            color: Colors.Black,
            textAlign: 'center',
            height: 45,
            width: width ? width : unitLabel ? 170 : 268,
            fontSize: type === INPUT_TYPE_NUMBER ? 40 : 32,
            fontWeight: 700,
            lineHeight: '140%',
            letterSpacing: 2.56,
            textIndent: 8,
            borderRadius: 4,
            cursor: 'pointer',
            border: 'none',
            outline: 'none',
            transition: 'all 0.2s',
            '-webkit-appearance': 'none',
            ':focus': {
                cursor: 'text',
            },
            ':disabled': {
                cursor: 'default',
            },
            '::placeholder': {
                color: Colors.BackgroundGrayD,
            },
            '::-webkit-input-placeholder': {
                color: Colors.BackgroundGrayD,
            },
            '::-ms-input-placeholder': {
                color: Colors.BackgroundGrayD,
            },
        },
        unitLabel: {
            position: 'absolute',
            color: Colors.TextDark,
            fontSize: 24,
            fontWeight: 700,
            lineHeight: '140%',
            letterSpacing: 1.92,
            bottom: 5,
            marginLeft: 3,
        },
    });
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("span", { className: css(styles.inputContainer) },
            React.createElement("input", { className: css(styles.input), type: type, value: value, onChange: onChange, placeholder: placeholder }),
            unitLabel && React.createElement("span", { className: css(styles.unitLabel) }, unitLabel))));
};
