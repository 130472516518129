import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var VideoCameraIcon = function (props) {
    var classObject = props.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 70,
            height: 44,
        },
    });
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 70 44", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M46.6667 5.5V38.5C46.6667 41.5376 44.055 44 40.8333 44H5.83333C2.61163 44 0 41.5376 0 38.5V5.5C0 2.4624 2.61163 0 5.83333 0H40.8333C44.0538 0 46.6667 2.4624 46.6667 5.5ZM70 7.27604V36.7125C70 39.6344 66.455 41.3405 63.8762 39.6676L50.5556 31.0177V12.9823L63.875 4.32437C66.4635 2.65031 70 4.36562 70 7.27604Z", fill: "#C5CBD1" })));
};
