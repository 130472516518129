import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
export var PaginationNextButton = function (props) {
    var _a;
    var styles = StyleSheet.create({
        moreButton: (_a = {
                padding: '14px 0',
                textAlign: 'center',
                fontSize: 12,
                fontWeight: 'bold',
                color: Colors.GrayDarkestB,
                border: "1px solid  ".concat(Colors.GrayDarkest),
                borderRadius: 4,
                width: 120,
                margin: '32px 24px 40px',
                backgroundColor: Colors.White,
                cursor: 'pointer'
            },
            _a[MediaBreakPointUp.SM] = {
                margin: '32px 40px 40px',
            },
            _a),
    });
    var getNextPage = props.getNextPage, classObject = props.classObject, isAvailable = props.isAvailable;
    return (React.createElement(React.Fragment, null, isAvailable && (React.createElement("div", { className: css(styles.moreButton, classObject), onClick: getNextPage }, "\u3055\u3089\u306B\u8AAD\u307F\u8FBC\u3080"))));
};
