import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var CourseFeatures = function (_a) {
    var children = _a.children, label = _a.label, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.label) }, label),
        children));
};
var styles = StyleSheet.create({
    container: {
        textAlign: 'left',
    },
    label: {
        fontWeight: 700,
        fontSize: 18,
        letterSpacing: '0.08em',
        color: Colors.Black,
        marginBottom: 15,
    },
});
