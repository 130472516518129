// 共通関数
// 配列空チェック
export var isEmptyArray = function (array) {
    return !Array.isArray(array) || !array.length;
};
export var randomString = function (length) {
    var source = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
        result += source[Math.floor(Math.random() * source.length)];
    }
    return result;
};
export var sleep = function (ms) { return new Promise(function (resolve) { return setTimeout(resolve, ms); }); };
export var replaceHalfWidthNumber = function (s) {
    // 全角数字の文字コードから65248個前が半角数字の文字コードとなっているので、65248引く
    var result = s.replace(/[０-９]/g, function (s) { return String.fromCharCode(s.charCodeAt(0) - 65248); });
    return result;
};
// 小数点第二位以下を切り捨てる
export var floorOver2DecimalPoint = function (s) {
    var matchResult = s.match(/\../);
    if (!matchResult)
        return s;
    return s.replace(/\..*/, matchResult[0]);
};
// 桁数の丸め込み
export var floorOverDigits = function (s, digits) {
    var prefix = s.replace(/\..*/, '');
    if (prefix.length > digits) {
        s = s.replace(prefix, prefix.slice(0, digits));
    }
    return s;
};
