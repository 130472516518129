import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CircleProgress } from '../atoms/circle_progress';
import { Colors } from '@lean-body/components/styles';
import { Button } from '../atoms';
export var TaskProgress = function (_a) {
    var current = _a.current, goal = _a.goal, unitLabel = _a.unitLabel, isAnimated = _a.isAnimated, showEditButton = _a.showEditButton, classObject = _a.classObject, onClickButton = _a.onClickButton;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(CircleProgress, { progressMax: goal, progressValue: current, color: Colors.ProgressGreen, trackColor: Colors.ProgressLightGreen, circleBorder: 12, circleDiameter: 223, roundedEdges: true, isAnimated: isAnimated },
            React.createElement("div", { className: css(styles.currentLabel) }, current.toLocaleString()),
            React.createElement("div", { className: css(styles.goalLabel) },
                "/ ",
                goal.toLocaleString(),
                " ",
                unitLabel),
            showEditButton && (React.createElement(Button, { className: css(styles.editButton), isRadius: true, onClick: onClickButton }, "\u7DE8\u96C6")))));
};
var styles = StyleSheet.create({
    container: {
        textAlign: 'center',
    },
    currentLabel: {
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '100%',
        letterSpacing: '1.6px',
        color: Colors.Black,
        marginBottom: 7,
    },
    goalLabel: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: '1.28px',
        color: Colors.GrayDarkest,
    },
    editButton: {
        color: Colors.TextDark,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
        width: 51,
        height: 30,
        marginTop: 14,
        padding: '5px 12px 6px',
        ':hover': {
            backgroundColor: Colors.White,
        },
    },
});
