import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var BadgeHeader = function (props) {
    var numOfReceivedBadges = function () {
        return props.badges.filter(function (badge) {
            return badge.isReceived === true;
        }).length;
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("span", { className: css(styles.header), ref: props.headerRef }, props.header),
        React.createElement("br", null),
        numOfReceivedBadges() >= 1 ? (React.createElement("span", { className: css(styles.subHeader) },
            ' ',
            numOfReceivedBadges(),
            props.subHeader)) : ('')));
};
var styles = StyleSheet.create({
    container: {
        margin: '24px 0px 8px',
    },
    header: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    subHeader: {
        fontSize: 14,
        color: Colors.Gray,
    },
});
