var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MediaBreakPointUp, Colors } from '@lean-body/components/styles';
import { URLIcon } from '../atoms/icons/url_icon';
export var InstaAndSiteLink = function (props) {
    var containerClass = [props.containerClassName, css(styles.links)].join(' ');
    var linkContentClass = [props.linkContentClassName, css(styles.linkContent)].join(' ');
    return (React.createElement("div", { className: containerClass },
        props.trainer.instaUrl && (React.createElement("div", { className: linkContentClass },
            React.createElement("img", { className: css(styles.iconSocialInstagram), src: '/assets/images/icons/icn_instagram_gray.png' }),
            React.createElement("span", { className: css(styles.linkSpan) },
                React.createElement("a", { href: props.trainer.instaUrl, target: "_blank", className: css(styles.link) }, "Instagram")))),
        props.trainer.siteUrl && (React.createElement("div", { className: css(styles.linkContent, styles.siteUrlContainer) },
            React.createElement(URLIcon, null),
            React.createElement("span", { className: css(styles.linkSpan) },
                React.createElement("a", { href: props.trainer.siteUrl, target: "_blank", className: css(styles.link) }, "".concat(props.trainer.siteUrl.substring(0, 23), "...")))))));
};
var styles = StyleSheet.create({
    links: (_a = {
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'center'
        },
        _a[MediaBreakPointUp.SM] = {
            textAlign: 'left',
        },
        _a[MediaBreakPointUp.MD] = {
            justifyContent: 'left',
        },
        _a),
    linkContent: {
        alignItems: 'center',
        display: 'flex',
    },
    siteUrlContainer: {
        marginLeft: 14,
    },
    linkSpan: {
        fontSize: 13,
        marginLeft: 3,
        letterSpacing: '0.04em',
    },
    link: {
        color: Colors.PrimaryDarkB,
    },
    iconSocialInstagram: (_b = {},
        _b[MediaBreakPointUp.SM] = {
            width: 15,
        },
        _b.width = 14,
        _b),
});
