import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors } from '@lean-body/components/styles';
import { LoadingIndicator } from '.';
export var FacebookButton = function (props) {
    var classObject = props.classObject, loading = props.loading, disabled = props.disabled;
    var onClick = function (event) {
        if (disabled)
            return;
        props.onClick && props.onClick(event);
    };
    return (React.createElement("div", { className: css(styles.container, classObject), onClick: onClick }, loading ? (React.createElement(LoadingIndicator, { size: 28, isWhite: true })) : (React.createElement(React.Fragment, null,
        React.createElement("img", { className: css(styles.facebookIcon), src: "/assets/images/icons/facebook_icon.png" }),
        " ",
        props.children))));
};
var styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.Facebook,
        color: Colors.White,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        letterSpacing: '2px',
        fontSize: 16,
        fontWeight: 'bold',
        height: 48,
        borderRadius: 100,
        cursor: 'pointer',
    },
    facebookIcon: {
        margin: '0 7px 1px 0',
        width: 18,
    },
});
