var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c, _d, _e;
import * as React from 'react';
import { useNavigate } from 'react-router';
import { routes, LessonType, generateLessonQueryParams } from '@lean-body/src/route';
import { css, StyleSheet } from 'aphrodite';
import { SharedTiming } from '@lean-body/src/interfaces';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { PageLoading, CourseRewardHeader, CourseReward, CourseLessonReview, } from '@lean-body/components/organisms';
import { TwitterFormDialog, CourseDayRewardButtons } from '@lean-body/src/view/molecules';
import { CourseRewardSectionTitle, CircleCloseButton, ShareButton, CanvasWithCenterImageAspectRatio, } from '@lean-body/components/atoms';
import { CloseButtonLabel } from '../atoms/dialog_close_button';
import { isAndroidApp } from '@lean-body/src/util';
export var CourseRewardPage = function (_a) {
    var courseDayID = _a.courseDayID, twitterService = _a.twitterService, config = _a.config, apiClient = _a.apiClient, alertPresenter = _a.alertPresenter, tracker = _a.tracker;
    var _b = React.useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = React.useState(false), isShowShareDialog = _c[0], setIsShowShareDialog = _c[1];
    var _d = React.useState(false), reviewSelected = _d[0], setReviewSelected = _d[1];
    var _e = React.useState(null), isGood = _e[0], setIsGood = _e[1];
    var _f = React.useState(null), reward = _f[0], setReward = _f[1];
    var _g = React.useState(null), shareContent = _g[0], setShareContent = _g[1];
    var navigate = useNavigate();
    React.useEffect(function () {
        apiClient
            .fetchReward(courseDayID)
            .then(function (v) {
            setReward(v);
            setIsLoading(false);
        })
            .catch(function (err) {
            alertPresenter.showMessage(err.displayMessage);
            navigate(routes.TOP.compile());
        });
    }, []);
    var onClickTwitterPost = function (text, file) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, twitterService.onClickShareReward(reward, SharedTiming.share_button)];
                case 1:
                    _a.sent();
                    if (twitterService.isAuthorized) {
                        return [2 /*return*/, twitterService
                                .postTweet(text, file)
                                .finally(function () {
                                setIsShowShareDialog(false);
                            })
                                .finally(function () {
                                tracker.trackClickEvent({ category: 'onClickTwitterPost' });
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var onClickShareIcon = function () {
        return apiClient
            .fetchCourseDaySnsShareContent(courseDayID)
            .then(function (v) {
            setShareContent(v);
            setIsShowShareDialog(true);
        })
            .finally(function () {
            tracker.trackClickEvent({ category: 'onClickShareButton' });
        });
    };
    var onClickReview = function (v) {
        setReviewSelected(true);
        setIsGood(v);
        tracker.trackClickEvent({ category: 'onClickCourseDayReview' });
    };
    var onClickNext = function () {
        tracker.trackClickEvent({ category: 'onClickNext' });
        if (isGood !== null) {
            // レビューの送信が成功したかどうかはユーザーには関係ないのでエラーハンドリング等はしない。
            apiClient.postCourseDayReview(courseDayID, isGood);
        }
        navigate(routes.ACTIVITY.compile());
    };
    var onClickRetakeLesson = function () {
        var courseLesson = reward.lessonForRetake;
        var url = routes.LESSON.compile({ type: LessonType.normal, slug: courseLesson.lesson.slug });
        var params = generateLessonQueryParams(courseLesson.id);
        navigate("".concat(url).concat(params));
        tracker.trackClickEvent({ category: 'onClickRetakeLesson' });
    };
    var onClickCloseIcon = function () {
        navigate(routes.TOP.compile());
        tracker.trackClickEvent({ category: 'onClickClose' });
    };
    var onClickCancelShare = function () {
        setIsShowShareDialog(false);
        tracker.trackClickEvent({ category: 'onClickCancelShare' });
    };
    var defaultTextTweetText = function () {
        return "".concat(reward.course.courseName, "\u300C").concat(reward.course.volume.title, "\u300DDAY").concat(reward.course.dayNumber, "\u3092\u53D7\u8B1B\u3057\u307E\u3057\u305F\u2728\n#LEANBODY #\u30EA\u30FC\u30F3\u30DC\u30C7\u30A3");
    };
    return (React.createElement("div", { className: css(styles.container) }, isLoading ? (React.createElement(PageLoading, null)) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.backGroundAccent) }),
        React.createElement(CourseRewardHeader, { courseReward: reward.course, config: config, classObject: styles.header }),
        React.createElement("section", { className: css(styles.content) },
            React.createElement("div", { className: css(styles.course) },
                React.createElement(CourseRewardSectionTitle, { title: "\u53D7\u8B1B\u3057\u305F\u30EC\u30C3\u30B9\u30F3", color: Colors.CyanLightestB, classObject: styles.sectionTitle }),
                React.createElement(CourseReward, { courseReward: reward.course, apiClient: apiClient })),
            React.createElement("div", { className: css(styles.reviewWrapper) },
                React.createElement(CourseRewardSectionTitle, { title: "\u30EC\u30C3\u30B9\u30F3\u3092\u8A55\u4FA1\u3059\u308B", color: Colors.Gray, classObject: styles.sectionTitle }),
                React.createElement(CourseLessonReview, { reviewSelected: reviewSelected, isGood: isGood, onClickReview: onClickReview, classObject: styles.reviewContent, tracker: tracker }))),
        React.createElement("div", { className: css(styles.iconsWrapper) },
            React.createElement("div", { className: css(styles.icons) },
                React.createElement(CircleCloseButton, { onClick: onClickCloseIcon }),
                !isAndroidApp() && React.createElement(ShareButton, { onClick: onClickShareIcon }))),
        React.createElement(CourseDayRewardButtons, { onClickNext: onClickNext, onClickBack: onClickRetakeLesson, classObject: styles.buttons }),
        isShowShareDialog && (React.createElement(TwitterFormDialog, { imageURL: shareContent.imageURL(config), imgWidth: 330, imgHeight: 330, centerImageWidth: 330, aspectRatio: CanvasWithCenterImageAspectRatio.RATIO_1_1, defaultText: defaultTextTweetText(), onClickCancel: onClickCancelShare, submit: onClickTwitterPost, closeButtonLabel: CloseButtonLabel.Close, strictSameSize: true }))))));
};
var styles = StyleSheet.create({
    container: {
        position: 'relative',
        backgroundColor: Colors.PrimaryLighter,
        overflow: 'hidden',
    },
    header: (_a = {
            position: 'absolute',
            top: 0,
            width: '100%',
            margin: '0 20px'
        },
        _a[MediaBreakPointUp.MD] = {
            margin: '0 48px',
        },
        _a[MediaBreakPointUp.XL] = {
            margin: '0 192px',
        },
        _a),
    content: (_b = {
            position: 'relative',
            top: -80,
            margin: '0 20px 80px 20px'
        },
        _b[MediaBreakPointUp.MD] = {
            top: -115,
            margin: '0 48px 80px 48px',
        },
        _b[MediaBreakPointUp.XL] = {
            margin: '0 192px 80px 192px',
        },
        _b),
    backGroundAccent: (_c = {
            backgroundColor: Colors.PrimaryLighterB,
            height: 260
        },
        _c[MediaBreakPointUp.MD] = {
            height: 295,
        },
        _c.borderBottomRightRadius = '800px 300px',
        _c.borderBottomLeftRadius = '800px 300px',
        _c.marginLeft = '-200px',
        _c.marginRight = '-200px',
        _c.paddingLeft = '200px',
        _c.paddingRight = '200px',
        _c),
    course: {
        position: 'relative',
    },
    sectionTitle: {
        marginBottom: 8,
    },
    reviewWrapper: {
        position: 'relative',
        margin: '20px auto',
    },
    reviewContent: {
        boxShadow: '0px 15px 30px rgba(0, 180, 199, 0.08)',
    },
    buttons: (_d = {
            position: 'fixed',
            bottom: 60
        },
        _d[MediaBreakPointUp.MD] = {
            bottom: 0,
            left: 40,
        },
        _d[MediaBreakPointUp.XL] = {
            left: 135,
        },
        _d.background = 'linear-gradient(180deg, rgba(240, 250, 251, 0.7) 0%, #F0FAFB 100%)',
        _d.boxhadow = '0px 2px 30px rgba(0, 180, 199, 0.08)',
        _d.margin = '0 auto',
        _d.width = '100%',
        _d),
    iconsWrapper: {
        position: 'relative',
    },
    icons: (_e = {
            position: 'fixed',
            marginTop: 18,
            padding: '0px 23px',
            display: 'flex',
            justifyContent: 'space-between',
            width: '-webkit-fill-available;',
            top: 50
        },
        _e[MediaBreakPointUp.MD] = {
            top: 0,
        },
        _e),
});
