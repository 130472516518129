var _a, _b, _c;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { PageStatus, SearchResultSectionTabs } from '@lean-body/src/view/pages/main_search_result';
import { SearchResultNotFound } from '@lean-body/components/organisms';
import { MoreLoading, SearchLoading } from '@lean-body/components/atoms';
import { PaginationNextButton } from '@lean-body/components/atoms/pagination_next_button';
import { MediaBreakPointUp } from '@lean-body/components/styles';
import { LessonComponent } from '../molecules/lesson_component';
import { useSmMediaQuery } from '@lean-body/src/util';
import { ProgramComponent } from '../molecules/program_component';
import { SearchResultAll } from '../molecules/search_result_all';
import { PlaylistComponentLandscapeImg } from '../molecules';
export var SearchResults = function (props) {
    var searchResults = props.searchResults, sortBy = props.sortBy, page = props.page, lessons = props.lessons, playlists = props.playlists, seriesPrograms = props.seriesPrograms, lessonsCount = props.lessonsCount, seriesProgramsCount = props.seriesProgramsCount, playlistsCount = props.playlistsCount, onClickBack = props.onClickBack, onClickLesson = props.onClickLesson, onClickProgram = props.onClickProgram, onClickPlaylist = props.onClickPlaylist, selectedTab = props.selectedTab, search = props.search, lessonsNextButtonDisplayable = props.lessonsNextButtonDisplayable, playlistsNextButtonDisplayable = props.playlistsNextButtonDisplayable, seriesProgramsNextButtonDisplayable = props.seriesProgramsNextButtonDisplayable, pageStatus = props.pageStatus, isLoading = props.isLoading, addFavoriteLesson = props.addFavoriteLesson, unFavoriteLesson = props.unFavoriteLesson, addFavoriteProgram = props.addFavoriteProgram, unFavoriteProgram = props.unFavoriteProgram;
    var isSM = useSmMediaQuery();
    var allCount = lessonsCount + seriesProgramsCount + playlistsCount;
    var allNextIsAvailable = lessonsNextButtonDisplayable || seriesProgramsNextButtonDisplayable || playlistsNextButtonDisplayable;
    return (React.createElement("div", { className: css(styles.container) }, pageStatus !== PageStatus.SearchDone ? (React.createElement(SearchLoading, null)) : (React.createElement(React.Fragment, null, selectedTab === SearchResultSectionTabs.all ? (
    //////////////////////////
    // すべてタブが選択された場合
    /////////////////////////
    React.createElement(React.Fragment, null, searchResults.length ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.searchCount) },
            "\u691C\u7D22\u7D50\u679C\uFF1A",
            allCount,
            "\u4EF6"),
        React.createElement("div", { className: css(styles.items) },
            React.createElement(SearchResultAll, { searchResults: searchResults, page: page, sortBy: sortBy, isVertical: !isSM, addFavoriteLesson: addFavoriteLesson, unFavoriteLesson: unFavoriteLesson, addFavoriteProgram: addFavoriteProgram, unFavoriteProgram: unFavoriteProgram, onClickLesson: onClickLesson, onClickProgram: onClickProgram, onClickPlaylist: onClickPlaylist })),
        React.createElement(PaginationNextButton, { classObject: styles.paginationButton, getNextPage: function () { return search(true); }, isAvailable: allNextIsAvailable }),
        isLoading && React.createElement(MoreLoading, null))) : (React.createElement(SearchResultNotFound, { onClickBack: onClickBack })))) : selectedTab === SearchResultSectionTabs.lessons ? (
    //////////////////////////
    // レッスンタブが選択された場合
    /////////////////////////
    React.createElement(React.Fragment, null, lessons.length ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.searchCount) },
            "\u691C\u7D22\u7D50\u679C\uFF1A",
            lessonsCount,
            "\u4EF6"),
        React.createElement("div", { className: css(styles.items) }, lessons.map(function (lesson, idx) { return (React.createElement(LessonComponent, { key: idx, lesson: lesson, addFavoriteLesson: addFavoriteLesson, unFavoriteLesson: unFavoriteLesson, isVertical: !isSM, onClick: props.onClickLesson })); })),
        React.createElement(PaginationNextButton, { classObject: styles.paginationButton, getNextPage: function () { return search(true); }, isAvailable: lessonsNextButtonDisplayable }),
        isLoading && React.createElement(MoreLoading, null))) : (React.createElement(SearchResultNotFound, { onClickBack: onClickBack })))) : selectedTab === SearchResultSectionTabs.seriesPrograms ? (
    //////////////////////////
    // シリーズタブが選択された場合
    /////////////////////////
    React.createElement(React.Fragment, null, seriesPrograms.length ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.searchCount) },
            "\u691C\u7D22\u7D50\u679C\uFF1A",
            seriesProgramsCount,
            "\u4EF6"),
        React.createElement("div", { className: css(styles.items) }, seriesPrograms.map(function (program) { return (React.createElement(ProgramComponent, { key: program.id, program: program, onClick: props.onClickProgram, addFavoriteProgram: addFavoriteProgram, unFavoriteProgram: unFavoriteProgram, isVertical: !isSM })); })),
        React.createElement(PaginationNextButton, { classObject: styles.paginationButton, getNextPage: function () { return search(true); }, isAvailable: seriesProgramsNextButtonDisplayable }),
        isLoading && React.createElement(MoreLoading, null))) : (React.createElement(SearchResultNotFound, { onClickBack: onClickBack })))) : (selectedTab === SearchResultSectionTabs.playlists && (
    //////////////////////////
    // プレイリストタブが選択された場合
    /////////////////////////
    React.createElement(React.Fragment, null, playlists.length ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.searchCount) },
            "\u691C\u7D22\u7D50\u679C\uFF1A",
            playlistsCount,
            "\u4EF6"),
        React.createElement("div", { className: css(styles.items) }, playlists.map(function (playlist, idx) { return (React.createElement(PlaylistComponentLandscapeImg, { key: idx, playlist: playlist, addFavoriteProgram: addFavoriteProgram, unFavoriteProgram: unFavoriteProgram, isVertical: !isSM, onClick: props.onClickPlaylist })); })),
        React.createElement(PaginationNextButton, { classObject: styles.paginationButton, getNextPage: function () { return search(true); }, isAvailable: playlistsNextButtonDisplayable }),
        isLoading && React.createElement(MoreLoading, null))) : (React.createElement(SearchResultNotFound, { onClickBack: onClickBack })))))))));
};
var styles = StyleSheet.create({
    container: (_a = {
            marginTop: 16,
            padding: '0 20px'
        },
        _a[MediaBreakPointUp.MD] = {
            padding: '0 48px',
        },
        _a[MediaBreakPointUp.XL] = {
            padding: '0 80px',
        },
        _a),
    searchCount: {
        fontSize: 12,
        fontWeight: 500,
        marginBottom: 12,
    },
    items: (_b = {
            display: 'flex',
            gap: '12px 0',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        _b[MediaBreakPointUp.MD] = {
            gap: '20px 0',
        },
        _b),
    paginationButton: (_c = {
            margin: '20px auto',
            width: 240
        },
        _c[MediaBreakPointUp.SM] = {
            margin: '20px auto',
        },
        _c),
});
