var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { css, StyleSheet } from 'aphrodite';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ZIndex } from '../constants';
import { Alert, AlertVM } from '@lean-body/components/molecules/alert';
import { MediaBreakPointUp } from '@lean-body/components/styles';
export var AlertContainer = observer(/** @class */ (function (_super) {
    __extends(AlertContainer, _super);
    function AlertContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AlertContainer.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement("div", { className: this.containerClass }, this.alerts);
        }
    });
    Object.defineProperty(AlertContainer.prototype, "alerts", {
        get: function () {
            var _this = this;
            return this.props.vm.alertVMs.map(function (vm) {
                return React.createElement(Alert, { key: vm.uuid, vm: vm, className: css(_this.styles.alert) });
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AlertContainer.prototype, "containerClass", {
        get: function () {
            return [this.props.className || '', css(this.styles.container)].join(' ');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AlertContainer.prototype, "styles", {
        get: function () {
            var _a;
            return StyleSheet.create({
                container: (_a = {
                        position: 'fixed',
                        top: 64,
                        right: '1rem',
                        zIndex: ZIndex.Alert,
                        width: 'calc(100% - 2rem)'
                    },
                    _a[MediaBreakPointUp.MD] = {
                        top: 8,
                        width: 'auto',
                        minWidth: '40%',
                    },
                    _a),
                alert: {
                    marginBottom: 10,
                },
            });
        },
        enumerable: false,
        configurable: true
    });
    return AlertContainer;
}(React.Component)));
var AlertContainerVM = /** @class */ (function () {
    function AlertContainerVM() {
        Object.defineProperty(this, "alertVMs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        makeObservable(this, {
            alertVMs: observable,
        });
    }
    Object.defineProperty(AlertContainerVM.prototype, "showMessage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (message, group, isError) {
            var _this = this;
            if (group === void 0) { group = ''; }
            if (isError === void 0) { isError = false; }
            this.removeAlertGroup(group);
            message.split('\n').forEach(function (m) {
                var vm = new AlertVM(m, group, isError);
                _this.addAlert(vm);
                vm.lifeEnd.then(function () {
                    _this.removeAlert(vm);
                });
            });
        }
    });
    Object.defineProperty(AlertContainerVM.prototype, "showError", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (error, fallbackMessage, group) {
            if ((error === null || error === void 0 ? void 0 : error.displayMessage) !== null && (error === null || error === void 0 ? void 0 : error.displayMessage) !== undefined) {
                return this.showMessage(error.displayMessage, group, true);
            }
            this.showMessage(fallbackMessage, group, true);
        }
    });
    Object.defineProperty(AlertContainerVM.prototype, "addAlert", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (vm) {
            this.alertVMs.push(vm);
        }
    });
    Object.defineProperty(AlertContainerVM.prototype, "removeAlert", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (vm) {
            this.alertVMs = this.alertVMs.filter(function (a) {
                return a.uuid !== vm.uuid;
            });
        }
    });
    Object.defineProperty(AlertContainerVM.prototype, "removeAlertGroup", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (group) {
            if (!group || group === '') {
                return;
            }
            this.alertVMs = this.alertVMs.filter(function (a) {
                return a.group !== group;
            });
        }
    });
    return AlertContainerVM;
}());
export { AlertContainerVM };
