var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as React from 'react';
import { bind } from 'bind-decorator';
var Canvas = /** @class */ (function (_super) {
    __extends(Canvas, _super);
    function Canvas() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        Object.defineProperty(_this, "canvas", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        return _this;
    }
    Object.defineProperty(Canvas.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement("canvas", { ref: this.setRef, width: this.props.width * 2, height: this.props.height * 2, className: this.props.className }));
        }
    });
    Object.defineProperty(Canvas.prototype, "setRef", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            if (!e) {
                return;
            }
            this.canvas = e;
        }
    });
    Object.defineProperty(Canvas.prototype, "updateCanvas", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var context = this.canvas.getContext('2d');
            context.scale(2, 2);
            this.props.updateCanvas(this.canvas, context);
        }
    });
    /*------ Lifecycles ------*/
    Object.defineProperty(Canvas.prototype, "componentDidMount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.updateCanvas();
        }
    });
    Object.defineProperty(Canvas.prototype, "componentWillReceiveProps", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (nextProps) {
            if (this.props !== nextProps) {
                this.updateCanvas();
            }
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [HTMLCanvasElement]),
        __metadata("design:returntype", void 0)
    ], Canvas.prototype, "setRef", null);
    return Canvas;
}(React.Component));
export { Canvas };
