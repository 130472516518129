import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var MypageDetailRow = function (_a) {
    var label = _a.label, content = _a.content;
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.label) }, label),
        React.createElement("div", { className: css(styles.content) }, content)));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 14,
        height: 50,
    },
    label: {
        fontWeight: 500,
    },
    content: {
        width: 250,
        color: Colors.GrayDarkest,
        textAlign: 'right',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        paddingLeft: 30,
        flex: 1,
    },
});
