import { CourseVolume, CourseDay } from '.';
var CourseReward = /** @class */ (function () {
    function CourseReward(data, config) {
        Object.defineProperty(this, "courseName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "courseThumbnailSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "volume", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "day", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.courseName = data.name;
        this.courseThumbnailSrc = data.thumbnailSrc;
        this.volume = new CourseVolume(data.volume, config);
        this.day = new CourseDay(data.day, config);
    }
    Object.defineProperty(CourseReward.prototype, "thumbnailURL", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (config) {
            return config.cdnBaseURL + this.courseThumbnailSrc;
        }
    });
    Object.defineProperty(CourseReward.prototype, "dayNumber", {
        get: function () {
            return this.day.day;
        },
        enumerable: false,
        configurable: true
    });
    return CourseReward;
}());
export { CourseReward };
