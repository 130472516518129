import * as React from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Link } from 'react-router-dom';
import { AnnouncementLabel } from '@lean-body/components/atoms/announcement_label';
import { useSmMediaQuery } from '@lean-body/src/util/media_query_hooks';
import { ArrowDirection, ArrowIcon } from '../atoms/icons/arrow_icon';
export var AnnouncementListItem = function (_a) {
    var _b, _c;
    var announcement = _a.announcement, me = _a.me;
    var isSM = useSmMediaQuery();
    var styles = StyleSheet.create({
        announcement: (_b = {
                padding: '16px 24px',
                display: 'flex',
                borderBottom: "1px solid ".concat(Colors.BorderLightGrayB),
                maxWidth: 600
            },
            _b[MediaBreakPointUp.SM] = {
                padding: '16px 2px 16px 0',
                margin: '0 40px',
                alignItems: 'center',
            },
            _b),
        imgContainer: {
            marginRight: 12,
        },
        textContainer: {
            flex: 1,
            marginRight: 20,
        },
        iconContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        imgTag: {
            width: 112,
            height: 60,
            borderRadius: 10,
            overFlow: 'hidden',
            objectFit: 'cover',
        },
        title: (_c = {
                marginTop: 5,
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                '-webkit-line-clamp': 2,
                overflow: 'hidden',
                fontSize: 12,
                color: Colors.Text
            },
            _c[MediaBreakPointUp.SM] = {
                '-webkit-line-clamp': 1,
                fontSize: 14,
            },
            _c),
    });
    return (React.createElement(Link, { className: css(styles.announcement), to: "/announcements/".concat(announcement.id) },
        React.createElement("div", { className: css(styles.imgContainer) },
            React.createElement("img", { className: css(styles.imgTag), src: announcement.thumbnailSrc })),
        React.createElement("div", { className: css(styles.textContainer) },
            React.createElement(AnnouncementLabel, { announcement: announcement, me: me }),
            React.createElement("div", { className: css(styles.title) }, announcement.title)),
        isSM && (React.createElement("div", { className: css(styles.iconContainer) },
            React.createElement(ArrowIcon, { direction: ArrowDirection.right, color: Colors.GrayDarkest })))));
};
