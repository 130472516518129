import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { Button, ButtonType } from '@lean-body/components/atoms/button';
export var ChangeCourseWarn = function (_a) {
    var onClickConfirm = _a.onClickConfirm, onClickBack = _a.onClickBack, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.title) }, "\u30B3\u30FC\u30B9\u3092\u5909\u66F4\u3057\u307E\u3059\u304B\uFF1F"),
        React.createElement("div", { className: css(styles.description) }, "\u73FE\u5728\u8A2D\u5B9A\u4E2D\u306E\u30B3\u30FC\u30B9\u306F\u4E2D\u65AD\u3055\u308C\u307E\u3059\u3002\u30B3\u30FC\u30B9\u306F\u7D99\u7D9A\u3059\u308B\u3053\u3068\u3067\u52B9\u679C\u304C\u8868\u308C\u307E\u3059\u3002"),
        React.createElement(Button, { className: css(styles.button), buttonType: ButtonType.primary, onClick: onClickConfirm }, "\u30B3\u30FC\u30B9\u3092\u5909\u66F4"),
        React.createElement("div", { className: css(styles.backButton), onClick: onClickBack }, "\u30AD\u30E3\u30F3\u30BB\u30EB")));
};
var styles = StyleSheet.create({
    container: {
        textAlign: 'center',
    },
    title: {
        fontWeight: 700,
        fontSize: 14,
        letterSpacing: '0.04em',
    },
    description: {
        marginTop: 10,
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: '0.04em',
        color: Colors.GrayDarkest,
    },
    button: {
        display: 'block',
        width: 300,
        fontSize: 16,
        borderRadius: 24,
        margin: '20px auto',
    },
    backButton: {
        color: Colors.PrimaryDarkB,
        fontWeight: 700,
        fontSize: 16,
        letterSpacing: '0.08em',
        cursor: 'pointer',
    },
});
