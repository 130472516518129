import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../styles';
import { NutrientIcon } from '../atoms/icons/nutrient_icon';
import { toMatrix } from '@lean-body/src/util';
export var MenuNutrientsSummary = function (_a) {
    var menu = _a.menu, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) }, toMatrix(menu.nutrientSummary, 2).map(function (row, idx) { return (React.createElement("div", { key: idx, className: css(styles.row) }, row.map(function (nutrient) { return (React.createElement("div", { key: nutrient.key, className: css(styles.col) },
        React.createElement(NutrientIcon, { iconType: nutrient.key, classObject: styles.icon }),
        React.createElement("div", { className: css(styles.name) }, nutrient.name),
        React.createElement("div", { className: css(styles.value) },
            nutrient.value,
            "g"))); }))); })));
};
var styles = StyleSheet.create({
    container: {
        padding: 16,
        borderRadius: 10,
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        width: '100%',
        height: 78,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    row: {
        display: 'flex',
        gap: 16,
    },
    col: {
        flex: 1,
        display: 'flex',
        gap: 6,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
    },
    icon: {
        width: 18,
        height: 18,
    },
    name: {
        width: 66,
    },
    value: {
        width: 43.5,
    },
});
