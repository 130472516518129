var _a;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors, utils, MediaBreakPointUp } from '@lean-body/components/styles';
import { VideoInfoRow } from './video_info_row';
import { SelectMark } from '@lean-body/components/atoms';
import { routes } from '@lean-body/src/route';
import { useNavigate } from 'react-router';
export var ProgramCard = function (_a) {
    var classObject = _a.classObject, imgClassObject = _a.imgClassObject, program = _a.program, link = _a.link, bodyBottomInsert = _a.bodyBottomInsert, isEllipsis = _a.isEllipsis, isOnlyHeaderImage = _a.isOnlyHeaderImage, onClick = _a.onClick, canSelect = _a.canSelect, onClickCheckButton = _a.onClickCheckButton;
    var _b = React.useState(false), isChecked = _b[0], setIsChecked = _b[1];
    var navigate = useNavigate();
    var goals = program.goals.map(function (g) { return g.name; }).join(' ');
    var onClickHandler = function (event) {
        if (canSelect) {
            onClickCheckButton(event);
            setIsChecked(!isChecked);
        }
        else {
            onClick && onClick(program);
        }
        if (link && !canSelect)
            navigate(routes.PROGRAM.compile({ slug: program.slug }));
    };
    return (React.createElement("div", { key: program.id, className: css(styles.container, link && styles.isLink, classObject), onClick: onClickHandler },
        React.createElement("div", { className: css(styles.header) },
            React.createElement("img", { className: css(styles.img, isOnlyHeaderImage && styles.imgBorderBottom, imgClassObject), src: program.thumbnailURL }),
            canSelect && React.createElement(SelectMark, { className: css(styles.selectMark), isChecked: isChecked })),
        !isOnlyHeaderImage && (React.createElement("div", { className: css(styles.programCardBody) },
            React.createElement("div", { className: css(styles.title, isEllipsis && utils.ellipsedLabel) }, program.name),
            React.createElement("div", { className: css(styles.info) },
                React.createElement(VideoInfoRow, { name: "\u52B9\u679C", value: goals }),
                React.createElement(VideoInfoRow, { name: "\u96E3\u6613\u5EA6", value: program.difficulty.name }),
                React.createElement(VideoInfoRow, { name: "\u30EC\u30C3\u30B9\u30F3\u6642\u9593", value: "".concat(program.lessonLength, "\u5206") })),
            bodyBottomInsert))));
};
var styles = StyleSheet.create({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 4,
        border: "1px solid ".concat(Colors.BorderLightGray),
    },
    isLink: {
        cursor: 'pointer',
    },
    header: {
        position: 'relative',
    },
    img: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        display: 'block',
        width: '100%',
    },
    imgBorderBottom: {
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },
    selectMark: {
        position: 'absolute',
        right: 8,
        top: 8,
    },
    programCardBody: {
        backgroundColor: Colors.White,
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        padding: 16,
        height: '100%',
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },
    title: (_a = {
            fontSize: '16px',
            fontWeight: 'bold',
            lineHeight: '22px',
            color: Colors.Black,
            marginBottom: '4px'
        },
        _a[MediaBreakPointUp.MD] = {
            fontSize: '20px',
        },
        _a),
    info: {
        fontSize: '12px',
        color: Colors.GrayDarkest,
    },
});
