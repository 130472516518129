import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import { routes } from '@lean-body/src/route';
import { Colors } from '../styles';
import * as dayjs from 'dayjs';
import { HorizontalScrollAnimation } from '../atoms/horizontal_scroll_animation';
export var LiveNowInSchedule = function (_a) {
    var nextLiveLesson = _a.nextLiveLesson, classObject = _a.classObject;
    var startsAt = dayjs.unix(nextLiveLesson.startsAt).format('HH:mm');
    var lessonEndAt = dayjs.unix(nextLiveLesson.lessonEndAt).format('HH:mm');
    if (!nextLiveLesson.isBeingHeld())
        return null;
    return (React.createElement(Link, { to: routes.LIVE_LESSON.compile({ id: nextLiveLesson.id.toString() }), className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.imgWrapper) },
            React.createElement("div", { className: css(styles.broadcastingBadge) }, "LIVE"),
            React.createElement("img", { className: css(styles.img), src: nextLiveLesson.trainer.thumbnailURL })),
        React.createElement("div", { className: css(styles.titleAndInfo) },
            React.createElement("div", { className: css(styles.title) }, nextLiveLesson.title),
            React.createElement(HorizontalScrollAnimation, null,
                React.createElement("div", { className: css(styles.info) },
                    React.createElement("div", { className: css(styles.trainer) }, nextLiveLesson.trainer.name),
                    React.createElement("div", { className: css(styles.difficulty) }, nextLiveLesson.difficulty.name),
                    React.createElement("div", null,
                        startsAt,
                        " - ",
                        lessonEndAt))))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        width: 400,
        margin: '0 auto',
        backgroundColor: Colors.White,
        padding: '8px 24px 8px 24px',
        color: Colors.Black,
        gap: 14,
    },
    imgWrapper: {
        width: 68,
        height: 68,
        border: "3px solid ".concat(Colors.PrimaryDark),
        borderRadius: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    },
    img: {
        width: 58,
        height: 58,
        borderRadius: 100,
    },
    titleAndInfo: {
        margin: '0 10px',
    },
    broadcastingBadge: {
        display: 'inline-flex',
        padding: '2px 6px',
        alignItems: 'flex-start',
        gap: 10,
        borderRadius: 8,
        border: "2px solid ".concat(Colors.White),
        background: "linear-gradient(90deg, #65CED9 0%, #5FC2E1 100%)",
        color: Colors.White,
        textAlign: 'center',
        fontSize: 10,
        fontWeight: 400,
        letterSpacing: 0.5,
        position: 'absolute',
        bottom: 0,
    },
    title: {
        fontSize: 14,
        marginBottom: 1,
        fontWeight: 700,
        letterSpacing: 0.56,
        color: Colors.PrimaryDark,
    },
    info: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        color: Colors.GrayDarkest,
        fontSize: 12,
        fontWeight: 400,
        letterSpacing: 0.48,
    },
    trainer: {
        marginRight: 8,
    },
    difficulty: {
        marginRight: 8,
    },
});
