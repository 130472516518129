import * as React from 'react';
import { css } from 'aphrodite';
export var TermsOfServiceMode;
(function (TermsOfServiceMode) {
    TermsOfServiceMode[TermsOfServiceMode["normal"] = 0] = "normal";
    TermsOfServiceMode[TermsOfServiceMode["megalos"] = 1] = "megalos";
})(TermsOfServiceMode || (TermsOfServiceMode = {}));
export var TermsOfService = function (props) {
    var onClick = function (e) {
        e.stopPropagation();
    };
    if (props.mode === TermsOfServiceMode.megalos) {
        return (React.createElement("a", { className: props.className, href: "https://wondernuts.zendesk.com/hc/ja/articles/900001412763-%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84-%E3%83%A1%E3%82%AC%E3%83%AD%E3%82%B9-LEAN-BODY-", target: "_blank", onClick: props.stopPropagation && onClick },
            props.iconSrc && React.createElement("img", { src: props.iconSrc, className: css(props.iconClassObject) }),
            "\u5229\u7528\u898F\u7D04"));
    }
    return (React.createElement("a", { className: props.className, href: "https://wondernuts.zendesk.com/hc/ja/articles/4818329721625-%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84", target: "_blank", onClick: props.stopPropagation && onClick },
        props.iconSrc && React.createElement("img", { src: props.iconSrc, className: css(props.iconClassObject) }),
        "\u5229\u7528\u898F\u7D04"));
};
