import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { ORIGINAL_PLAYLIST_ITEM_WIDTH } from '@lean-body/components/molecules/original_playlist_section_card';
import { VerticalListArrows } from '@lean-body/components/molecules/vertical_list_arrows';
import { OriginalPlaylistSectionCard } from '@lean-body/components/molecules/original_playlist_section_card';
export var OriginalPlaylistSection = function (_a) {
    var playlists = _a.playlists, title = _a.title, classObject = _a.classObject, carouselWithHeaderClassObject = _a.carouselWithHeaderClassObject, itemsPadding = _a.itemsPadding, onClickCreate = _a.onClickCreate;
    if (!playlists)
        return null;
    return (React.createElement("div", { className: css(classObject) },
        title && React.createElement("div", { className: css(styles.title, carouselWithHeaderClassObject) }, title),
        React.createElement(VerticalListArrows, { itemSize: ORIGINAL_PLAYLIST_ITEM_WIDTH, itemsPadding: itemsPadding, classObject: carouselWithHeaderClassObject, items: playlists.map(function (v) { return (React.createElement("div", { className: css(styles.itemContainer) },
                React.createElement(OriginalPlaylistSectionCard, { playlist: v, onClickCreate: onClickCreate }))); }) })));
};
var styles = StyleSheet.create({
    varticalList: {
        position: 'relative',
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    itemContainer: {
        display: 'flex',
        overflowX: 'scroll',
        '::-webkit-scrollbar': {
            display: 'none',
        },
    },
});
