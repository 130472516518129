import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { SelectMark } from '@lean-body/components/atoms/select_mark';
import { useNavigate } from 'react-router';
import { routes } from '@lean-body/src/route';
export var PlaylistCard = function (_a) {
    var classObject = _a.classObject, imgClassObject = _a.imgClassObject, playlist = _a.playlist, link = _a.link, canSelect = _a.canSelect, onClick = _a.onClick, onClickCheckButton = _a.onClickCheckButton;
    var _b = React.useState(false), isChecked = _b[0], setIsChecked = _b[1];
    var navigate = useNavigate();
    var onClickHandler = function (event) {
        if (canSelect) {
            onClickCheckButton(event);
            setIsChecked(!isChecked);
        }
        else {
            onClick && onClick(playlist);
        }
        if (link && !canSelect)
            navigate(routes.PLAYLIST.compile({ id: playlist.id.toString() }));
    };
    return (React.createElement("div", { className: css(styles.container, link && styles.isLink, classObject), onClick: onClickHandler, "data-test": "playlistCard" },
        canSelect && React.createElement(SelectMark, { className: css(styles.selectMark), isChecked: isChecked }),
        React.createElement("img", { className: css(styles.img, imgClassObject), src: playlist.thumbnailURL })));
};
var styles = StyleSheet.create({
    container: {
        borderRadius: 10,
        position: 'relative',
    },
    isLink: {
        cursor: 'pointer',
    },
    img: {
        borderRadius: 10,
        width: '100%',
        display: 'block',
    },
    selectMark: {
        position: 'absolute',
        right: 8,
        top: 8,
    },
});
