import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var QUANTITY_ONE = 1;
export var QUANTITY_TWO = 2;
export var QUANTITY_THREE = 3;
export var RadioWavesIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 25,
            height: 24,
        },
    });
    var quantity = props.quantity, difficultyDegree = props.difficultyDegree, classObject = props.classObject, emptyColor = props.emptyColor, fillColor = props.fillColor;
    var empty = emptyColor || '#E8EAED';
    var fill = fillColor || Colors.GrayDarkest;
    var quantityForWave = quantity || difficultyDegree >= 5 ? QUANTITY_THREE : difficultyDegree >= 2 ? QUANTITY_TWO : QUANTITY_ONE;
    if (quantityForWave === QUANTITY_ONE) {
        return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 25 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fill: empty, d: "M19.5051 19.3579H16.8576C16.4826 19.3579 16.1826 19.0579 16.1826 18.6829V5.49043C16.1826 5.11543 16.4826 4.81543 16.8576 4.81543H19.5051C19.8801 4.81543 20.1801 5.11543 20.1801 5.49043V18.6829C20.1801 19.0579 19.8726 19.3579 19.5051 19.3579Z" }),
            React.createElement("path", { fill: fill, d: "M8.11281 19.3579H5.52531C5.13531 19.3579 4.82031 19.0429 4.82031 18.6529V12.8329C4.82031 12.4429 5.13531 12.1279 5.52531 12.1279H8.11281C8.50281 12.1279 8.81781 12.4429 8.81781 12.8329V18.6529C8.81781 19.0429 8.50281 19.3579 8.11281 19.3579Z" }),
            React.createElement("path", { fill: empty, d: "M13.8349 19.3579H11.1724C10.8049 19.3579 10.5049 19.0579 10.5049 18.6904V9.23293C10.5049 8.86543 10.8049 8.56543 11.1724 8.56543H13.8349C14.2024 8.56543 14.5024 8.86543 14.5024 9.23293V18.6904C14.5024 19.0579 14.2024 19.3579 13.8349 19.3579Z" })));
    }
    if (quantityForWave === QUANTITY_TWO) {
        return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 25 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fill: empty, d: "M19.5051 19.3579H16.8576C16.4826 19.3579 16.1826 19.0579 16.1826 18.6829V5.49043C16.1826 5.11543 16.4826 4.81543 16.8576 4.81543H19.5051C19.8801 4.81543 20.1801 5.11543 20.1801 5.49043V18.6829C20.1801 19.0579 19.8726 19.3579 19.5051 19.3579Z" }),
            React.createElement("path", { fill: fill, d: "M8.11281 19.3579H5.52531C5.13531 19.3579 4.82031 19.0429 4.82031 18.6529V12.8329C4.82031 12.4429 5.13531 12.1279 5.52531 12.1279H8.11281C8.50281 12.1279 8.81781 12.4429 8.81781 12.8329V18.6529C8.81781 19.0429 8.50281 19.3579 8.11281 19.3579Z" }),
            React.createElement("path", { fill: fill, d: "M13.8349 19.3579H11.1724C10.8049 19.3579 10.5049 19.0579 10.5049 18.6904V9.23293C10.5049 8.86543 10.8049 8.56543 11.1724 8.56543H13.8349C14.2024 8.56543 14.5024 8.86543 14.5024 9.23293V18.6904C14.5024 19.0579 14.2024 19.3579 13.8349 19.3579Z" })));
    }
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 25 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: fill, d: "M19.5051 19.3579H16.8576C16.4826 19.3579 16.1826 19.0579 16.1826 18.6829V5.49043C16.1826 5.11543 16.4826 4.81543 16.8576 4.81543H19.5051C19.8801 4.81543 20.1801 5.11543 20.1801 5.49043V18.6829C20.1801 19.0579 19.8726 19.3579 19.5051 19.3579Z" }),
        React.createElement("path", { fill: fill, d: "M8.11281 19.3579H5.52531C5.13531 19.3579 4.82031 19.0429 4.82031 18.6529V12.8329C4.82031 12.4429 5.13531 12.1279 5.52531 12.1279H8.11281C8.50281 12.1279 8.81781 12.4429 8.81781 12.8329V18.6529C8.81781 19.0429 8.50281 19.3579 8.11281 19.3579Z" }),
        React.createElement("path", { fill: fill, d: "M13.8349 19.3579H11.1724C10.8049 19.3579 10.5049 19.0579 10.5049 18.6904V9.23293C10.5049 8.86543 10.8049 8.56543 11.1724 8.56543H13.8349C14.2024 8.56543 14.5024 8.86543 14.5024 9.23293V18.6904C14.5024 19.0579 14.2024 19.3579 13.8349 19.3579Z" })));
};
