var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useSmMediaQuery, useXlMediaQuery } from '@lean-body/src/util';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { CollectionRankImage } from '@lean-body/src/view/atoms/collection_rank_img';
import { ArrowDirection, ArrowIcon } from '@lean-body/components/atoms';
export var RankListSection = function (props) {
    var isSM = useSmMediaQuery();
    var isXL = useXlMediaQuery();
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    // 画面サイズ別のトグル閉じた状態で表示するランクの数
    var smRankToggleLimit = 12;
    var mdRankToggleLimit = 20;
    var xlRankToggleLimit = 32;
    var ranks = function () {
        if (isOpen) {
            return props.rank;
        }
        if (isSM) {
            return props.rank.slice(0, smRankToggleLimit);
        }
        else if (isXL) {
            return props.rank.slice(0, xlRankToggleLimit);
        }
        else {
            return props.rank.slice(0, mdRankToggleLimit);
        }
    };
    var shouldShowToggle = function () {
        if (isSM) {
            return props.rank.length >= smRankToggleLimit;
        }
        else if (isXL) {
            return props.rank.length >= xlRankToggleLimit;
        }
        else {
            return props.rank.length >= mdRankToggleLimit;
        }
    };
    return (React.createElement(React.Fragment, null, props.rank.length !== 0 && (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.listWrapper) }, ranks().map(function (userSeasonAggregate, i) { return (React.createElement("div", { key: i, className: css(styles.listItem) },
            React.createElement(CollectionRankImage, { userSeasonAggregate: userSeasonAggregate }))); })),
        shouldShowToggle() && (React.createElement("div", { className: css(styles.toggle), onClick: function () { return setIsOpen(!isOpen); } },
            React.createElement("div", { className: css(styles.toggleText) }, isOpen ? '閉じる' : 'すべて見る'),
            React.createElement(ArrowIcon, { classObject: styles.arrow, color: Colors.PrimaryDarkB, direction: isOpen ? ArrowDirection.top : ArrowDirection.bottom })))))));
};
var rankImageMinWidth = 90;
var styles = StyleSheet.create({
    container: {
        margin: '16px 0px 30px',
    },
    listWrapper: (_a = {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            gridGap: '16px 20px',
            '::before': {
                content: "''",
                display: 'block',
                width: rankImageMinWidth,
                height: 0,
                order: 1,
            },
            '::after': {
                content: "''",
                display: 'block',
                width: rankImageMinWidth,
            }
        },
        _a[MediaBreakPointUp.MD] = {
            display: 'grid',
            gridGap: '16px 7.5px',
            gridTemplateColumns: 'repeat(auto-fit, 18%)',
        },
        _a[MediaBreakPointUp.LG] = {
            gridGap: '16px 7.5px',
            gridTemplateColumns: 'repeat(auto-fit, 11%)',
        },
        _a),
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: rankImageMinWidth,
    },
    toggle: (_b = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2
        },
        _b[MediaBreakPointUp.MD] = {
            marginTop: 20,
        },
        _b),
    toggleText: {
        color: Colors.PrimaryDarkB,
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 500,
        letterSpacing: 0.48,
    },
    arrow: {
        color: Colors.PrimaryDarkB,
        textAlign: 'center',
        fontSize: 12,
        width: 12,
        height: 10,
        fontWeight: 500,
        letterSpacing: 0.48,
    },
});
