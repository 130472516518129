var _a;
import { css, StyleSheet } from 'aphrodite/no-important';
import * as React from 'react';
import { MediaBreakPointUp } from '@lean-body/components/styles';
export var ContentWidthLayout = function (props) {
    var containerClass = [props.className || '', css(styles.container)].join(' ');
    return (React.createElement("div", { className: containerClass, id: props.id }, props.children));
};
var ContainerSize = {
    xs: 'calc(100% - 1rem * 2)',
    md: 'calc(100% - 1rem * 2)',
    lg: 'calc(100% - 2rem * 2)',
    xl: '1010px',
};
var styles = StyleSheet.create({
    container: (_a = {
            margin: '0 15px'
        },
        _a[MediaBreakPointUp.MD] = {
            margin: '0 auto',
            width: ContainerSize.md,
        },
        _a[MediaBreakPointUp.LG] = {
            width: ContainerSize.lg,
        },
        _a[MediaBreakPointUp.XL] = {
            width: ContainerSize.xl,
        },
        _a),
});
