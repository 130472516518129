import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var ThumbsDownIcon = function (_a) {
    var classObject = _a.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 24,
            height: 24,
        },
    });
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M5.56434 0.615234H2.03631C1.38091 0.615234 0.849609 1.14654 0.849609 1.80194V11.5735C0.849609 12.2289 1.38091 12.7602 2.03631 12.7602H5.56434C6.21974 12.7602 6.75104 12.2289 6.75104 11.5735V1.80194C6.75104 1.14654 6.21974 0.615234 5.56434 0.615234Z", fill: "#FEFEFE" }),
        React.createElement("path", { d: "M21.484 11.6113H21.2755C22.3072 11.6113 23.1518 12.4559 23.1518 13.4876C23.1518 14.5193 22.3072 15.3639 21.2755 15.3639H15.2511C15.9941 16.5345 16.9991 18.4001 16.9991 19.9236C16.9991 22.3397 15.6787 23.6761 14.8128 23.8311C13.9522 23.9861 13.316 23.5264 13.0701 22.7941C12.8189 22.0671 13.2626 20.688 13.0915 20.1962C12.8296 19.4371 11.4986 18.4215 9.69713 16.1122C8.08814 14.0489 8.31799 11.6113 8.31799 11.6113V3.39527C8.31799 2.75381 8.84185 2.22461 9.48866 2.22461H20.6768C21.4252 2.22461 22.0345 2.834 22.0345 3.58237C22.0345 4.33074 21.4198 4.94012 20.6768 4.94012H20.8692C21.7833 4.94012 22.537 5.68849 22.537 6.60792C22.537 7.52735 21.7887 8.27572 20.8692 8.27572H21.484C22.398 8.27572 23.1518 9.02409 23.1518 9.94352C23.1518 10.8629 22.4034 11.6113 21.484 11.6113Z", fill: "#FEFEFE" })));
};
