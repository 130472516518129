import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var StarIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 30,
            height: 30,
        },
    });
    var iconColor = color || Colors.BackgroundGrayD;
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 30 30", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { id: "star" },
            React.createElement("path", { d: "M14.0489 2.92705C14.3483 2.00574 15.6517 2.00574 15.9511 2.92705L18.1432 9.67376C18.2771 10.0858 18.661 10.3647 19.0943 10.3647H26.1882C27.1569 10.3647 27.5597 11.6044 26.7759 12.1738L21.0369 16.3435C20.6864 16.5981 20.5397 17.0495 20.6736 17.4615L22.8657 24.2082C23.1651 25.1295 22.1106 25.8956 21.3269 25.3262L15.5878 21.1565C15.2373 20.9019 14.7627 20.9019 14.4122 21.1565L8.67312 25.3262C7.88941 25.8956 6.83493 25.1295 7.13428 24.2082L9.32642 17.4615C9.46029 17.0495 9.31363 16.5981 8.96315 16.3435L3.22405 12.1738C2.44034 11.6044 2.84311 10.3647 3.81184 10.3647H10.9057C11.339 10.3647 11.7229 10.0858 11.8568 9.67376L14.0489 2.92705Z", fill: iconColor }))));
};
