import { routes } from '@lean-body/src/route';
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ArrowDirection, ArrowIcon } from '../atoms/icons/arrow_icon';
import { Colors } from '../styles';
export var LessonMetaTrainers = function (_a) {
    var classObject = _a.classObject, trainers = _a.trainers;
    return (React.createElement("div", { className: css(classObject) }, trainers.map(function (trainer, i) {
        return (React.createElement(Link, { to: routes.INSTRUCTOR.compile({ id: "".concat(trainer.id) }), className: css(styles.trainer), key: i },
            React.createElement("img", { className: css(styles.trainerImg), src: trainer.thumbnailURL, "data-test": "trainer" }),
            React.createElement("div", { className: css(styles.trainerInfo) },
                "\u30A4\u30F3\u30B9\u30C8\u30E9\u30AF\u30BF\u30FC",
                React.createElement("br", null),
                React.createElement("span", { className: css(styles.trainerName) }, trainer.name)),
            React.createElement("div", { className: css(styles.trainerDetail) },
                "\u8A73\u7D30\u3092\u898B\u308B",
                React.createElement(ArrowIcon, { classObject: styles.arrowIconDetail, color: Colors.GrayDarkest, direction: ArrowDirection.right }))));
    })));
};
var styles = StyleSheet.create({
    trainer: {
        padding: '10px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: Colors.Black,
    },
    trainerImg: {
        width: 36,
        borderRadius: 100,
        border: "1px solid ".concat(Colors.Gray),
        marginRight: 8,
    },
    trainerInfo: {
        flex: 1,
        fontSize: 10,
    },
    trainerName: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    trainerDetail: {
        color: Colors.GrayDarkest,
        fontSize: 12,
        lineHeight: '160%',
        display: 'flex',
        alignItems: 'center',
    },
    arrowIconDetail: {
        marginLeft: 3,
        width: 12,
        height: 7,
    },
});
