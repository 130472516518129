import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var ActivitySectionTitle = function (props) {
    return (React.createElement("div", { className: css(styles.container, props.classObject) },
        React.createElement("span", { className: css(styles.header), ref: props.headerRef }, props.title)));
};
var styles = StyleSheet.create({
    container: {},
    header: {
        fontSize: 20,
        fontWeight: 'bold',
    },
});
