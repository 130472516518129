import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { TrainerImage } from '@lean-body/components/atoms/trainer_img';
import * as routes from '@lean-body/src/route';
import { MediaBreakPointUp } from '@lean-body/components/styles';
export var InstructorContent = function (props) {
    var _a, _b;
    var styles = StyleSheet.create({
        container: (_a = {
                cursor: 'pointer',
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            },
            _a[MediaBreakPointUp.SM] = {
                width: '33.33%',
            },
            _a[MediaBreakPointUp.MD] = {
                marginBottom: 10,
                width: '25%',
            },
            _a[MediaBreakPointUp.LG] = {
                marginBottom: 18,
                width: '20%',
            },
            _a),
        label: (_b = {
                letterSpacing: 1,
                fontSize: 16,
                marginBottom: 16,
                fontWeight: 'bold',
                lineHeight: '1.25'
            },
            _b[MediaBreakPointUp.SM] = {
                fontSize: 18,
            },
            _b),
    });
    var onClickTrainerDetail = function () {
        return props.router.push(routes.INSTRUCTOR, {
            id: props.trainer.id.toString(),
        });
    };
    return (React.createElement("div", { onClick: onClickTrainerDetail, className: css(styles.container) },
        React.createElement(TrainerImage, { trainer: props.trainer }),
        React.createElement("div", { className: css(styles.label) }, props.trainer.name)));
};
