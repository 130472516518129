import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
export var AspectRatioLayout = function (props) {
    var styles = StyleSheet.create({
        layoutWrapper: {
            position: 'relative',
            ':before': {
                paddingTop: "".concat(100 * props.whRatio, "%"),
                content: '""',
                display: 'block',
            },
        },
        layout: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            width: '100%',
        },
    });
    var wrapperClass = [props.className || '', css(styles.layoutWrapper)].join(' ');
    var layoutClass = css(styles.layout);
    return (React.createElement("div", { style: props.style, className: wrapperClass },
        React.createElement("div", { className: layoutClass }, props.children)));
};
