var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { VideoPlayButton } from '../atoms';
import { LBPlayer } from '../lb_player';
import { MediaBreakPointDown, MediaBreakPointUp } from '../styles';
export var LiveArchiveVideo = function (_a) {
    var liveLesson = _a.liveLesson, onInit = _a.onInit, onPlay = _a.onPlay, onTimeupdate = _a.onTimeupdate, signedCookieService = _a.signedCookieService;
    var isInit = React.useRef(false);
    var _b = React.useState(null), lbPlayerCtx = _b[0], setLbPlayerCtx = _b[1];
    var _c = React.useState(true), playButtonVisible = _c[0], setPlayButtonVisible = _c[1];
    React.useEffect(function () {
        if (!lbPlayerCtx) {
            return;
        }
        if (!isInit.current) {
            isInit.current = true;
            // iOS側ではiOS独自の全画面になっていて、全画面から離脱をする時動画が停止される。
            // iOS側で全画面離脱を感知できないので、動画の再生状態に合わせてVjsVideoControllerの表示を調整している。
            lbPlayerCtx.on('pause', function () {
                var VjsVideoController = lbPlayerCtx.getChild('VjsVideoController');
                VjsVideoController.setIsPaused(true);
            });
            lbPlayerCtx.on('play', function () {
                var VjsVideoController = lbPlayerCtx.getChild('VjsVideoController');
                VjsVideoController.setIsPaused(false);
                onPlay(lbPlayerCtx.currentTime());
            });
            lbPlayerCtx.on('timeupdate', function () { return onTimeupdate(lbPlayerCtx.currentTime()); });
            playVideo();
            onInit();
        }
    }, [lbPlayerCtx]);
    var playVideo = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!lbPlayerCtx) {
                        return [2 /*return*/];
                    }
                    // 動画を閲覧するための署名付きCookie取得
                    return [4 /*yield*/, signedCookieService.fetchSignedCookies()];
                case 1:
                    // 動画を閲覧するための署名付きCookie取得
                    _a.sent();
                    setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _b.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, lbPlayerCtx.play()];
                                case 1:
                                    _b.sent();
                                    setPlayButtonVisible(false);
                                    return [3 /*break*/, 3];
                                case 2:
                                    _a = _b.sent();
                                    setPlayButtonVisible(true);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); }, 300);
                    return [2 /*return*/];
            }
        });
    }); };
    var lbVideoOptions = function (src) {
        return {
            sources: [
                {
                    src: src,
                    type: 'application/x-mpegURL',
                },
            ],
            preload: 'auto',
            playsinline: true,
            controls: true,
            controlBar: {
                playToggle: false,
                volumePanel: false,
                muteToggle: false,
                fullscreenToggle: false,
                pictureInPictureToggle: false,
            },
        };
    };
    var videoOperationProps = {
        onClickExitInnerFullScreen: function () { },
        onClickPrev: function () { },
        onClickNext: function () { },
        isFirstLesson: true,
        isLastLesson: true,
        showAutoplayToggle: false,
        showBackButton: false,
        isAlwaysShowStepButtons: true,
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.playerWrapper, playButtonVisible && styles.playerWrapperNoEvents) },
            React.createElement(LBPlayer, { getPlayer: function (player) { return setLbPlayerCtx(player); }, videoOptions: lbVideoOptions(liveLesson.archiveVideo.hlsURL), operationOptions: videoOperationProps })),
        React.createElement(VideoPlayButton, { visible: playButtonVisible, onClick: playVideo, className: css(styles.playButton) })));
};
var styles = StyleSheet.create({
    container: (_a = {},
        _a[MediaBreakPointDown.SM] = {
            width: '100%',
            top: '-2px',
            margin: '0 auto',
        },
        _a[MediaBreakPointUp.XL] = {
            marginBottom: 24,
        },
        _a.padding = '56.25% 0 0 0',
        _a.position = 'relative',
        _a),
    playerWrapper: {
        width: '100%',
        height: '100%',
        display: 'inherit',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    playerWrapperNoEvents: {
        pointerEvents: 'none',
    },
    playButton: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
});
