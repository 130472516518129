var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import bind from 'bind-decorator';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Lesson } from '@lean-body/src/domain';
import { VideoVM, ReceivedBadgeOrUserSeasonRankDialog, TwitterFormDialog, Video, LessonMeta, } from '@lean-body/components/molecules';
import { FIXED_FOOTER_HEIGHT, PageLoading } from '@lean-body/components/organisms';
import { MediaBreakPointDown, MediaBreakPointUp, Sizes } from '@lean-body/components/styles';
import { routes } from '@lean-body/src/route';
import { FavoriteStatus, UserChallengeStatus } from '@lean-body/src/interfaces';
import { useNavigate } from 'react-router';
import { HeroHeader } from '../atoms';
import { getFormatedDateTime, getUnixTimeInUTC, useQuery, useXlMediaQuery } from '@lean-body/src/util';
import { VIDEO_WIDTH_OVER_MD } from './main_lesson';
import { LessonPlaylistBox, SHADOW_WIDTH } from '../molecules/lesson_playlist_box';
import { PlaylistMeta } from '../molecules/playlist_meta';
import { PlaylistMetaAboveLessonName } from '../atoms/playlist_meta_above_lesson_name';
export var PLAYLIST_ORDER_QUERY = 'order_index';
export var PlaylistDetail = observer(function (_a) {
    var _b, _c, _d;
    var vm = _a.vm;
    var isXL = useXlMediaQuery();
    var _e = React.useState(false), isOpenStopChallengeDialog = _e[0], setIsOpenStopChallengeDialog = _e[1];
    var onClickStopChallenge = function () {
        setIsOpenStopChallengeDialog(!isOpenStopChallengeDialog);
    };
    var onClickCancelStopChallenge = function () {
        setIsOpenStopChallengeDialog(false);
    };
    if (!vm.isFirstViewLoaded) {
        return React.createElement(PageLoading, null);
    }
    var styles = StyleSheet.create({
        container: (_b = {
                height: "calc(100vh - ".concat(FIXED_FOOTER_HEIGHT, "px - ").concat(Sizes.MobileHeaderHeight, "px)"),
                margin: '0 auto'
            },
            _b[MediaBreakPointDown.MD] = {
                overflow: isOpenStopChallengeDialog ? 'hidden' : 'unset',
            },
            _b[MediaBreakPointUp.MD] = {
                width: VIDEO_WIDTH_OVER_MD + SHADOW_WIDTH * 2,
                minHeight: '100vh',
            },
            _b[MediaBreakPointUp.XL] = {
                height: 'unset',
                width: '100%',
                maxWidth: 1024,
                display: 'flex',
                marginTop: 32,
                paddingBottom: 120,
            },
            _b),
        pageTop: (_c = {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%'
            },
            _c[MediaBreakPointUp.XL] = {
                maxWidth: VIDEO_WIDTH_OVER_MD,
                marginRight: 32,
            },
            _c),
        header: (_d = {},
            _d[MediaBreakPointUp.MD] = {
                padding: "0 ".concat(SHADOW_WIDTH, "px"),
            },
            _d[MediaBreakPointUp.XL] = {
                padding: 0,
            },
            _d),
        challengeInfoBox: {
            height: 103,
            width: '100%',
        },
        aboveLessonName: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 2,
        },
    });
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.pageTop) },
            React.createElement("div", { className: css(styles.header) },
                React.createElement(HeroHeader, { heroURL: vm.hasOrderIndex ? vm.lesson.heroURL : vm.playlist.thumbnailURL, videoVisible: vm.hasOrderIndex, isPlaylist: true }, vm.showVideo && (React.createElement(Video, { vm: vm.videoVM, src: vm.srcUrl, playButtonVisible: vm.videoVM.playButtonVisible, onClickPlayButton: vm.onClickVideoPlayButton })))),
            vm.hasOrderIndex ? (React.createElement(LessonMeta, { lesson: vm.lesson, currentLessonIndex: vm.currentLessonIndex, program: vm.lesson.programs[0], playlist: vm.playlist, nextLesson: vm.nextLesson, programLessons: vm.lessons, isFavorite: vm.isFavoritedLesson, onClickLesson: vm.onClickLesson, onClickFavLesson: vm.onClickFavLesson, onClickTwitterShareIfAuthed: vm.onClickTwitterShareIfAuthed, addFavoriteLesson: vm.apiClient.addFavoriteLesson, unFavoriteLesson: vm.apiClient.unFavoriteLesson, twitterService: vm.twitterService, displayLessonPlaylistBox: !isXL })) : (React.createElement(PlaylistMeta, { playlist: vm.playlist, currentLessonIndex: vm.currentOrderIndex, playlistCurrentOrderIndex: vm.playlistCurrentOrderIndex, nextLesson: vm.nextLesson, programLessons: vm.lessons, onClickFav: vm.onClickFavProgram, twitterService: vm.twitterService, onClickTwitterShareIfAuthed: vm.onClickTwitterShareIfAuthed, addFavoriteLesson: vm.apiClient.addFavoriteLesson, unFavoriteLesson: vm.apiClient.unFavoriteLesson, displayLessonPlaylistBox: !isXL, hasOrderIndex: vm.hasOrderIndex, onClickLesson: vm.onClickLesson, isFavorited: vm.isFavorited, isChallenge: vm.playlist.isChallenge, isChallenging: vm.isChallenging, totalFavoriteCount: vm.totalFavoriteCount, isOpenStopChallengeDialog: isOpenStopChallengeDialog, onClickChallenge: vm.onClickChallenge, playCurrentVideo: vm.playCurrentVideo, onClickStopChallenge: onClickStopChallenge, onClickCancelStopChallenge: onClickCancelStopChallenge, aboveLessonName: function (index) {
                    return vm.playlist.isChallenge && (React.createElement(PlaylistMetaAboveLessonName, { classObject: styles.aboveLessonName, index: index + 1, isNext: vm.isChallenging && index === vm.playlistCurrentOrderIndex, isDone: vm.isChallenging && vm.lessons[index].isCompleted }));
                } }))),
        isXL && (React.createElement(LessonPlaylistBox, { title: !vm.hasOrderIndex && "\u30EC\u30C3\u30B9\u30F3 (".concat(vm.lessons.length, ")"), playlist: vm.playlist, nextLesson: vm.hasOrderIndex && vm.nextLesson, currentLessonIndex: vm.currentOrderIndex, programLessons: vm.lessons, addFavoriteLesson: vm.apiClient.addFavoriteLesson, unFavoriteLesson: vm.apiClient.unFavoriteLesson, isShowOtherLessons: true, onClickLesson: vm.onClickLesson, aboveLessonName: function (index) {
                return vm.playlist.isChallenge && (React.createElement(PlaylistMetaAboveLessonName, { classObject: styles.aboveLessonName, index: index + 1, isNext: vm.isChallenging && index === vm.playlistCurrentOrderIndex, isDone: vm.isChallenging && vm.lessons[index].isCompleted }));
            } })),
        vm.isReceivedBadge && (React.createElement(ReceivedBadgeOrUserSeasonRankDialog, { badges: vm.badges, onClickCancel: vm.onCloseReceivedBadgeOrUserSeasonRankDialog, updateDisplayedUserBadges: vm.apiClient.updateDisplayedUserBadges, twitterService: vm.twitterService, onClickTwitterShareIfAuthed: vm.onClickTwitterShareIfAuthed })),
        vm.isShowTwitterFormDialog && (React.createElement(TwitterFormDialog, { imageURL: vm.twitterService.tweetImgSrc, centerImageWidth: vm.twitterService.tweetCenterImageWidth, defaultText: vm.twitterService.tweetDefaultText, onClickCancel: vm.onClickTwitterPostCancel, submit: vm.onClickTwitterPost }))));
});
var PlaylistDetailPageViewModel = /** @class */ (function () {
    function PlaylistDetailPageViewModel(id, lessonService, signedCookieServie, tracker, apiClient, router, twitterService) {
        Object.defineProperty(this, "router", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "navigate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "query", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "signedCookieServie", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playlist", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "srcUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "videoVM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "lesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "programLessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "currentLessonIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "prevLessonIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "prevLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "nextLessonIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nextLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "lessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "isFavorited", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isFavoritedLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isChallenging", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "totalFavoriteCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playlistCurrentOrderIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "currentOrderIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isFirstViewLoaded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "badges", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        // Videoコンポーネントのマウント/アンマウントを制御し初期処理を行うためのフラグ
        Object.defineProperty(this, "showVideo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        Object.defineProperty(this, "favoriteRequesting", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "favoriteLessonRequesting", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "twitterService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isShowTwitterFormDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "queryOrederIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            id: observable,
            videoVM: observable.ref,
            playlist: observable,
            lesson: observable,
            lessons: observable.ref,
            prevLesson: observable.ref,
            nextLesson: observable.ref,
            isFavorited: observable,
            isFavoritedLesson: observable,
            isFirstViewLoaded: observable,
            badges: observable,
            showVideo: observable,
            isShowTwitterFormDialog: observable,
            isChallenging: observable,
            currentOrderIndex: observable,
            prevLessonIndex: observable,
            nextLessonIndex: observable,
            playlistCurrentOrderIndex: observable,
            totalFavoriteCount: observable,
        });
        this.id = id;
        this.lessonService = lessonService;
        this.signedCookieServie = signedCookieServie;
        this.tracker = tracker;
        this.router = router;
        this.navigate = useNavigate();
        this.query = useQuery();
        this.queryOrederIndex = this.query.get(PLAYLIST_ORDER_QUERY);
        this.apiClient = apiClient;
        this.twitterService = twitterService;
        this.initialize();
    }
    /*---------- 初期処理  ----------*/
    // シリーズ動画ページの初期化
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "initialize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.load()];
                        case 1:
                            _a.sent();
                            if (this.twitterService.showFormDialog) {
                                this.isShowTwitterFormDialog = true;
                                this.twitterService.showFormDialog = false;
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // シリーズ動画ページの表示に必要なデータの取得
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "load", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, lesson, badges;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = this;
                            return [4 /*yield*/, this.apiClient.fetchPlaylist(this.id)];
                        case 1:
                            _a.playlist = _b.sent();
                            this.isChallenging = this.playlist.isChallenging;
                            this.isFavorited = this.playlist.isFavorited;
                            this.playlistCurrentOrderIndex = this.playlist.currentOrderIndex;
                            this.totalFavoriteCount = this.playlist.playlistAggregate.totalFavoriteCount;
                            this.setOrderIndex();
                            lesson = this.playlist.lessons[this.currentOrderIndex];
                            // 動画プレイヤーの初期化
                            this.videoVM = new VideoVM(this.router, this.navigate, this.apiClient, this.lessonService, this.signedCookieServie, this.hasOrderIndex, this.reload, lesson, this.playlist, this.playPrevVideo, this.playNextVideo, null, this.completeLesson);
                            this.setLessonData(lesson);
                            this.srcUrl = this.lesson.video.hlsURL;
                            return [4 /*yield*/, Promise.all([this.apiClient.fetchUndisplayedBadges()])
                                // ファーストビューに必要なコンテンツの初期処理が完了したタイミングでページをレンダリングする
                            ];
                        case 2:
                            badges = (_b.sent())[0];
                            // ファーストビューに必要なコンテンツの初期処理が完了したタイミングでページをレンダリングする
                            this.isFirstViewLoaded = true;
                            // ユーザが獲得した未表示のバッジを取得
                            this.badges = badges;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "isReceivedBadge", {
        get: function () {
            return this.badges.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "hasOrderIndex", {
        get: function () {
            return this.queryOrederIndex !== null;
        },
        enumerable: false,
        configurable: true
    });
    /*---------- イベントハンドラー ----------*/
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "onClickFavProgram", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (this.favoriteRequesting)
                                return [2 /*return*/];
                            this.favoriteRequesting = true;
                            if (!this.isFavorited) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.apiClient.unFavoriteProgram(this.playlist.id)];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, this.apiClient.addFavoriteProgram(this.playlist.id)];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4:
                            this.isFavorited = !this.isFavorited;
                            this.isFavorited ? this.totalFavoriteCount++ : this.totalFavoriteCount--;
                            this.favoriteRequesting = false;
                            this.tracker.trackFavoriteProgram(this.playlist.id, this.isFavorited ? FavoriteStatus.Add : FavoriteStatus.Remove);
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "onClickFavLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (this.favoriteLessonRequesting)
                                return [2 /*return*/];
                            this.favoriteLessonRequesting = true;
                            if (!this.isFavoritedLesson) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.apiClient.unFavoriteLesson(this.lesson.id)];
                        case 1:
                            _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, this.apiClient.addFavoriteLesson(this.lesson.id)];
                        case 3:
                            _b.sent();
                            _b.label = 4;
                        case 4:
                            this.isFavoritedLesson = !this.isFavoritedLesson;
                            this.favoriteLessonRequesting = false;
                            this.tracker.trackFavoriteLesson(this.lesson, this.isFavoritedLesson ? FavoriteStatus.Add : FavoriteStatus.Remove);
                            _a = this;
                            return [4 /*yield*/, this.updateProgramLesson(this.lesson.id, this.isFavoritedLesson)];
                        case 5:
                            _a.lesson = _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "updateProgramLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lessonId, isFavorite) {
            return __awaiter(this, void 0, void 0, function () {
                var sameLessonIndexInPlaylist, fetchedLesson;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            sameLessonIndexInPlaylist = this.lessons.findIndex(function (v) { return lessonId === v.id; });
                            return [4 /*yield*/, this.apiClient.fetchLessonWithSlug(this.lessons[sameLessonIndexInPlaylist].slug)];
                        case 1:
                            fetchedLesson = _a.sent();
                            fetchedLesson.isFavorite = isFavorite;
                            this.lessons[sameLessonIndexInPlaylist] = fetchedLesson;
                            return [2 /*return*/, fetchedLesson];
                    }
                });
            });
        }
    });
    // レッスンページ上部の再生ボタンのクリックイベントのハンドリング
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "onClickVideoPlayButton", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: 
                        // 動画を再生する
                        return [4 /*yield*/, this.videoVM.playLessonVideo(this.playlist, this.lessons[this.currentOrderIndex])];
                        case 1:
                            // 動画を再生する
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // バッジの取得情報をリセットする
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "onCloseReceivedBadgeOrUserSeasonRankDialog", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.badges = [];
        }
    });
    // ページの表示データを再取得
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "reload", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // ビデオプレーヤーの状態を完全に破棄する
                            this.showVideo = false;
                            this.videoVM = new VideoVM(this.router, this.navigate, this.apiClient, this.lessonService, this.signedCookieServie, this.hasOrderIndex, this.reload, this.lesson, this.playlist, this.playPrevVideo, this.playNextVideo, null, this.completeLesson);
                            return [4 /*yield*/, this.load()];
                        case 1:
                            _a.sent();
                            this.showVideo = true;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "onClickTwitterShareIfAuthed", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.isShowTwitterFormDialog = true;
        }
    });
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "onClickTwitterPost", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (text, file) {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    this.twitterService.postTweet(text, file).finally(function () {
                        _this.isShowTwitterFormDialog = false;
                    });
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "onClickTwitterPostCancel", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.isShowTwitterFormDialog = false;
        }
    });
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "onClickChallenge", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var startDateTime, endDateTime, _a, startDateTime, endDateTime, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            if (!this.isChallenging) return [3 /*break*/, 2];
                            startDateTime = getFormatedDateTime(getUnixTimeInUTC(new Date()));
                            endDateTime = getFormatedDateTime(this.endDateTime);
                            _a = this.playlist;
                            return [4 /*yield*/, this.apiClient.updateChallengeStatus(this.playlist.userChallenge.id, this.playlist.id, UserChallengeStatus.ChallengeStopped, startDateTime, endDateTime)];
                        case 1:
                            _a.userChallenge = _c.sent();
                            this.isChallenging = false;
                            this.lessons.forEach(function (v) {
                                v.isCompleted = false;
                            });
                            this.playlistCurrentOrderIndex = 0;
                            this.tracker.trackClickUpdateChallengeStatus(this.playlist.id, UserChallengeStatus.ChallengeStopped, startDateTime, endDateTime);
                            return [3 /*break*/, 4];
                        case 2:
                            startDateTime = getFormatedDateTime(getUnixTimeInUTC(new Date()));
                            endDateTime = getFormatedDateTime(this.endDateTime);
                            _b = this.playlist;
                            return [4 /*yield*/, this.apiClient.startChallenge(this.id, startDateTime, endDateTime)];
                        case 3:
                            _b.userChallenge = _c.sent();
                            this.isChallenging = true;
                            this.tracker.trackClickChallengeStart(this.id, startDateTime, endDateTime);
                            _c.label = 4;
                        case 4:
                            this.tracker.trackClickEvent({
                                category: 'Challenge Button Clicks',
                                label: "PlaylistID: ".concat(this.playlist.id, ", isChallenging: ").concat(this.isChallenging),
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "onClickLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (targetLesson) {
            var order = this.lessons.findIndex(function (v) { return v.orderIndex === targetLesson.orderIndex; });
            this.navigate("".concat(routes.PLAYLIST.compile({ id: this.playlist.id.toString() }), "?").concat(PLAYLIST_ORDER_QUERY, "=").concat(order));
            this.tracker.trackClickEvent({
                category: 'Lesson Clicks',
                label: "PlaylistID: ".concat(this.playlist.id, ", isChallenging: ").concat(this.isChallenging),
            });
        }
    });
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "playCurrentVideo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.navigate("".concat(routes.PLAYLIST.compile({ id: this.playlist.id.toString() }), "?").concat(PLAYLIST_ORDER_QUERY, "=").concat(this.currentLessonIndex));
            this.tracker.trackClickEvent({
                category: 'Play Current Video Clicks',
                label: "PlaylistID: ".concat(this.playlist.id, ", isChallenging: ").concat(this.isChallenging),
            });
        }
    });
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "playPrevVideo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.navigate("".concat(routes.PLAYLIST.compile({ id: this.playlist.id.toString() }), "?").concat(PLAYLIST_ORDER_QUERY, "=").concat(this.prevLessonIndex));
        }
    });
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "playNextVideo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.navigate("".concat(routes.PLAYLIST.compile({ id: this.playlist.id.toString() }), "?").concat(PLAYLIST_ORDER_QUERY, "=").concat(this.nextLessonIndex));
        }
    });
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "setLessonData", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lesson) {
            var _a, _b;
            this.lesson = lesson;
            this.isFavoritedLesson = lesson.isFavorite;
            this.lessons = this.playlist.lessons;
            this.currentLessonIndex = this.playlist.lessonIndex(lesson.orderIndex);
            _a = this.playlist.prevLesson(this.currentLessonIndex), this.prevLessonIndex = _a[0], this.prevLesson = _a[1];
            _b = this.playlist.nextLesson(this.currentLessonIndex), this.nextLessonIndex = _b[0], this.nextLesson = _b[1];
            this.videoVM.setIsFirstLesson(this.playlist.isFirstLesson(this.currentLessonIndex));
            this.videoVM.setIsLastLesson(this.playlist.isLastLesson(this.currentLessonIndex));
            this.videoVM.setPrevLesson(this.prevLesson);
            this.videoVM.setNextLesson(this.nextLesson);
        }
    });
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "setOrderIndex", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.playlist.isChallenging && !this.hasOrderIndex && this.playlist) {
                this.currentOrderIndex = this.playlistCurrentOrderIndex;
            }
            else {
                this.currentOrderIndex = Number(this.queryOrederIndex);
            }
        }
    });
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "completeLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.isChallenging && this.playlistCurrentOrderIndex === this.currentOrderIndex) {
                this.lessons[this.playlistCurrentOrderIndex].isCompleted = true;
                this.playlistCurrentOrderIndex++;
            }
        }
    });
    Object.defineProperty(PlaylistDetailPageViewModel.prototype, "endDateTime", {
        // プログラム終了日
        get: function () {
            var endDateTime = getUnixTimeInUTC(new Date());
            endDateTime.setDate(endDateTime.getDate() + this.lessons.length);
            return endDateTime;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], PlaylistDetailPageViewModel.prototype, "onClickFavProgram", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], PlaylistDetailPageViewModel.prototype, "onClickFavLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], PlaylistDetailPageViewModel.prototype, "onClickVideoPlayButton", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], PlaylistDetailPageViewModel.prototype, "onCloseReceivedBadgeOrUserSeasonRankDialog", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], PlaylistDetailPageViewModel.prototype, "reload", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], PlaylistDetailPageViewModel.prototype, "onClickTwitterShareIfAuthed", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, File]),
        __metadata("design:returntype", Promise)
    ], PlaylistDetailPageViewModel.prototype, "onClickTwitterPost", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], PlaylistDetailPageViewModel.prototype, "onClickTwitterPostCancel", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], PlaylistDetailPageViewModel.prototype, "onClickChallenge", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Lesson]),
        __metadata("design:returntype", void 0)
    ], PlaylistDetailPageViewModel.prototype, "onClickLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], PlaylistDetailPageViewModel.prototype, "playCurrentVideo", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], PlaylistDetailPageViewModel.prototype, "playPrevVideo", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], PlaylistDetailPageViewModel.prototype, "playNextVideo", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Lesson]),
        __metadata("design:returntype", void 0)
    ], PlaylistDetailPageViewModel.prototype, "setLessonData", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], PlaylistDetailPageViewModel.prototype, "setOrderIndex", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], PlaylistDetailPageViewModel.prototype, "completeLesson", null);
    return PlaylistDetailPageViewModel;
}());
export { PlaylistDetailPageViewModel };
