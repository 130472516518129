import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Task } from '@lean-body/src/domain';
import { Colors } from '@lean-body/components/styles/variables';
import { ArrowDirection, ArrowIcon } from '../atoms';
import { WorkoutLessonIcon } from '../atoms/icons/workout_lesson_icon';
import { WorkoutStepsIcon } from '../atoms/icons/workout_steps_icon';
import { CustomWorkoutIcon } from '../atoms/icons/custom_workout_icon';
export var CompletedWorkout = function (_a) {
    var userCustomWorkout = _a.userCustomWorkout, taskLesson = _a.taskLesson, stepActivity = _a.stepActivity, onClick = _a.onClick, classObject = _a.classObject;
    var headerText;
    var icon;
    var titleText;
    var calories;
    var durationMinutes;
    if (userCustomWorkout) {
        headerText = 'カスタムワークアウト';
        icon = React.createElement(CustomWorkoutIcon, null);
        titleText = userCustomWorkout.customWorkout.name;
        calories = Task.roundTaskCalories(userCustomWorkout.calorie);
        durationMinutes = userCustomWorkout.duration;
    }
    else if (taskLesson) {
        headerText = 'LEAN BODYレッスン';
        icon = React.createElement(WorkoutLessonIcon, null);
        titleText = taskLesson.lesson ? taskLesson.lesson.name : taskLesson.liveLesson.title;
        calories = Task.roundTaskCalories(taskLesson.spentCalories);
        durationMinutes = taskLesson.durationMinutes;
    }
    else {
        headerText = '歩数';
        icon = React.createElement(WorkoutStepsIcon, null);
        titleText = "".concat(stepActivity.steps, "\u6B69");
        calories = Task.roundTaskCalories(stepActivity.calories);
    }
    return (React.createElement("div", { className: css(styles.container, classObject), onClick: onClick },
        React.createElement("div", { className: css(styles.contents) },
            React.createElement("div", { className: css(styles.header) },
                icon,
                React.createElement("span", { className: css(styles.headerText) }, headerText)),
            React.createElement("div", { className: css(styles.title) }, titleText),
            React.createElement("div", { className: css(styles.info) },
                React.createElement("span", { className: css(styles.infoCalories) },
                    calories,
                    "kcal"),
                durationMinutes != null && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: css(styles.infoDivider) }, " | "),
                    React.createElement("span", { className: css(styles.infoMinutes) },
                        durationMinutes,
                        "\u5206"))))),
        React.createElement(ArrowIcon, { direction: ArrowDirection.right, color: Colors.GrayDarkest })));
};
var styles = StyleSheet.create({
    container: {
        cursor: 'pointer',
        padding: '16px',
        borderRadius: '14px',
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        display: 'flex',
        alignItems: 'center',
        gap: 20,
    },
    contents: {
        flex: '1 0 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: 4,
        overflow: 'hidden',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
    },
    headerText: {
        flex: '1 0 0',
        color: Colors.GrayDarkest,
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: '0.48px',
    },
    title: {
        color: Colors.Black,
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '160%',
        letterSpacing: '0.48px',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
    },
    info: {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: '0.48px',
    },
    infoCalories: {
        color: Colors.PrimaryDarkB,
    },
    infoDivider: {
        color: Colors.BorderLightGray,
    },
    infoMinutes: {
        color: Colors.GrayDarkest,
    },
});
