import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
export var BadgeImage = function (props) {
    var styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '0 15px 10px 0',
            cursor: 'pointer',
        },
        badgeWrapper: {
            position: 'relative',
            width: 60,
            height: 60,
            borderRadius: 100,
            backgroundColor: Colors.White,
        },
        badge: {
            width: 60,
            marginBottom: 5,
        },
        badgeName: {
            color: Colors.GrayLightText,
            fontSize: 10,
        },
    });
    var classObject = props.classObject, badge = props.badge, onClick = props.onClick;
    return (React.createElement("div", { className: css(styles.container, classObject), onClick: function () { return onClick && onClick(badge); } },
        React.createElement("img", { className: css(styles.badge), src: badge.renderImageURL }),
        React.createElement("span", { className: css(styles.badgeName) }, badge.name)));
};
