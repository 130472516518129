import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../styles';
import { MealMenuList } from './meal_menu_list';
import { MealMenuListItem } from '../molecules/meal_menu_list_item';
import { RecordMealMenuSearch } from './record_meal_menu_search';
export var RecordMealAnalysisMenuChange = function (_a) {
    var apiClient = _a.apiClient, config = _a.config, analysisResultMenus = _a.analysisResultMenus, displayedAnalysisMenu = _a.displayedAnalysisMenu, changeCheckedAnalysisMenuMap = _a.changeCheckedAnalysisMenuMap, setChangeCheckedAnalysisMenuMap = _a.setChangeCheckedAnalysisMenuMap, setIsChangedAnalysisMenu = _a.setIsChangedAnalysisMenu, onClickMenu = _a.onClickMenu, classObject = _a.classObject;
    var _b = React.useState(false), isInputSearch = _b[0], setIsInputSearch = _b[1];
    var onClickSelectMenu = function (menu) {
        setChangeCheckedAnalysisMenuMap(new Map([[menu.menuKey, menu]]));
        setIsChangedAnalysisMenu(true);
    };
    var onClickDeselectMenu = function () {
        setChangeCheckedAnalysisMenuMap(new Map());
        setIsChangedAnalysisMenu(true);
    };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(RecordMealMenuSearch, { apiClient: apiClient, config: config, selectedMenuMap: changeCheckedAnalysisMenuMap, onClickMenu: onClickMenu, onClickSelectMenu: onClickSelectMenu, onClickDeselectMenu: onClickDeselectMenu, setIsInputSearch: setIsInputSearch }),
        !isInputSearch && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u9078\u629E\u4E2D\u306E\u30E1\u30CB\u30E5\u30FC"),
            React.createElement(MealMenuListItem, { menu: displayedAnalysisMenu, isSelected: !!changeCheckedAnalysisMenuMap.get(displayedAnalysisMenu.menuKey), isDisplayAmount: true, onClick: onClickMenu, onClickSelect: onClickSelectMenu, onClickDeselect: onClickDeselectMenu, classObject: [styles.borderAround, styles.menuItem] }),
            analysisResultMenus && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(styles.sectionTitle) }, "\u3053\u3061\u3089\u306E\u30E1\u30CB\u30E5\u30FC\u3092\u304A\u63A2\u3057\u3067\u3059\u304B\uFF1F"),
                React.createElement(MealMenuList, { mealMenus: analysisResultMenus, selectedMenuMap: changeCheckedAnalysisMenuMap, onClickMenu: onClickMenu, onClickSelectMenu: onClickSelectMenu, onClickDeselectMenu: onClickDeselectMenu, excludeMenuKey: displayedAnalysisMenu.menuKey })))))));
};
var styles = StyleSheet.create({
    container: {},
    sectionTitle: {
        color: Colors.TextDark,
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 1.28,
        paddingTop: 24,
        paddingBottom: 12,
    },
    menuItem: {
        padding: 12,
        cursor: 'pointer',
    },
    borderAround: {
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        borderRadius: 10,
    },
});
