import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '@lean-body/components/styles';
export var SearchIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 16,
            height: 16,
        },
    });
    var color = props.color || Colors.GrayDarkest;
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("circle", { stroke: color, cx: "6.04915", cy: "6.04915", r: "5.04915", strokeWidth: "2" }),
        React.createElement("path", { fill: color, d: "M10.4331 9.11719L15.3416 14.0257C15.705 14.3891 15.705 14.9783 15.3416 15.3418C14.9781 15.7052 14.3889 15.7052 14.0255 15.3418L9.11698 10.4333L10.4331 9.11719Z" })));
};
