import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../styles';
import { ArrowDirection, ArrowIconWithMargin, CloseIcon } from '../atoms';
import { ZIndex } from '../constants';
export var RecordMealOverlayImage = function (_a) {
    var imageMap = _a.imageMap, displayTargetIndex = _a.displayTargetIndex, setDisplayTargetIndex = _a.setDisplayTargetIndex, onCloseImageDetail = _a.onCloseImageDetail;
    var imageKeyList = Array.from(imageMap.keys());
    var displayImageCount = imageMap.size > 1;
    var onClickPrevImage = function () {
        setDisplayTargetIndex(imageKeyList[imageKeyList.indexOf(displayTargetIndex) - 1]);
    };
    var onClickNextImage = function () {
        setDisplayTargetIndex(imageKeyList[imageKeyList.indexOf(displayTargetIndex) + 1]);
    };
    var displayLeftArrowIcon = function () {
        return imageKeyList.indexOf(displayTargetIndex) > 0;
    };
    var displayRightArrowIcon = function () {
        return imageKeyList.indexOf(displayTargetIndex) < imageMap.size - 1;
    };
    var displayTargetPageCount = function () {
        return imageKeyList.indexOf(displayTargetIndex) + 1;
    };
    return (React.createElement("div", { className: css(styles.overlay) },
        React.createElement(CloseIcon, { containerClassObject: styles.dialogCloseIcon, color: Colors.White, size: 16, onClick: onCloseImageDetail }),
        React.createElement("div", { className: css(styles.detailImageContainer) },
            React.createElement("img", { className: css(styles.detailImage), src: imageMap.get(displayTargetIndex).url }),
            displayLeftArrowIcon() && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(styles.arrowIcon, styles.leftArrowIcon, styles.arrowIconBackground) }),
                React.createElement(ArrowIconWithMargin, { classObject: [styles.arrowIcon, styles.leftArrowIcon], color: Colors.White, direction: ArrowDirection.left, onClick: onClickPrevImage }))),
            displayRightArrowIcon() && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(styles.arrowIcon, styles.rightArrowIcon, styles.arrowIconBackground) }),
                React.createElement(ArrowIconWithMargin, { classObject: [styles.arrowIcon, styles.rightArrowIcon], color: Colors.White, direction: ArrowDirection.right, onClick: onClickNextImage })))),
        displayImageCount && (React.createElement("div", { className: css(styles.imageCount) },
            displayTargetPageCount(),
            " / ",
            imageMap.size))));
};
var styles = StyleSheet.create({
    overlay: {
        background: Colors.StrictBlack,
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        top: '0',
        left: '0',
        zIndex: ZIndex.Overlay,
    },
    dialogCloseIcon: {
        position: 'absolute',
        top: 20,
        left: 24,
    },
    detailImageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100% - 230px)',
        marginTop: 112,
    },
    detailImage: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    arrowIcon: {
        position: 'absolute',
        top: 'calc(50% - 22px)',
        width: 44,
        height: 44,
    },
    leftArrowIcon: {
        left: 20,
    },
    rightArrowIcon: {
        right: 20,
    },
    arrowIconBackground: {
        background: Colors.TextDark,
        borderRadius: 22,
        opacity: 0.15,
    },
    imageCount: {
        color: Colors.White,
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 700,
        lineHeight: '160%',
        letterSpacing: 0.48,
        height: 19,
        marginTop: 35,
        marginBottom: 64,
    },
});
