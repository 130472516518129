import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import Lottie from 'react-lottie';
import * as animationData from '../../../lotties/medal.json';
export var CourseDayStatusIcon = function (_a) {
    var completed = _a.completed, animate = _a.animate, loopAnimation = _a.loopAnimation, classObject = _a.classObject;
    var styles = animate
        ? StyleSheet.create({
            icon: {
                width: 60,
                height: 84,
            },
        })
        : StyleSheet.create({
            icon: {
                width: 29,
                height: 42,
            },
        });
    return (React.createElement("div", { className: css(styles.icon, classObject) }, completed ? (React.createElement(CourseDayCompletedIcon, { classObject: [styles.icon, classObject], animate: animate, loopAnimation: loopAnimation })) : (React.createElement(CourseDayInProgressIcon, { classObject: [styles.icon, classObject] }))));
};
var CourseDayCompletedIcon = function (_a) {
    var animate = _a.animate, loopAnimation = _a.loopAnimation, classObject = _a.classObject;
    var animationOptions = {
        loop: loopAnimation,
        animationData: animationData,
    };
    return (React.createElement(React.Fragment, null, animate ? (React.createElement(Lottie, { options: animationOptions })) : (React.createElement("svg", { fill: "none", className: css(classObject), viewBox: "0 0 29 42", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" },
        React.createElement("filter", { id: "a", colorInterpolationFilters: "sRGB", filterUnits: "userSpaceOnUse", height: "11.9604", width: "11.9609", x: "2.62305", y: "20.9045" },
            React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
            React.createElement("feColorMatrix", { in: "SourceAlpha", result: "hardAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" }),
            React.createElement("feOffset", null),
            React.createElement("feGaussianBlur", { stdDeviation: "2" }),
            React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
            React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" }),
            React.createElement("feBlend", { in2: "BackgroundImageFix", mode: "normal", result: "effect1_dropShadow_20934_35995" }),
            React.createElement("feBlend", { in: "SourceGraphic", in2: "effect1_dropShadow_20934_35995", mode: "normal", result: "shape" })),
        React.createElement("path", { d: "m20.2681.25-9.7251 16.8562h8.732l9.7251-16.8562z", fill: "#65ced9" }),
        React.createElement("path", { d: "m14.9058 14.583v2.5453h4.7215v.7055h-4.7215v4.3958h7.2667v-5.5898c0-1.1342-.9226-2.0568-2.0568-2.0568h-5.2153z", fill: "#b38916" }),
        React.createElement("path", { d: "m10.1839 17.1283v.7055h4.7215v4.3958h-7.26673v-5.5898c0-1.1342.92259-2.0568 2.05683-2.0568h5.2153v2.5453h-4.7215z", fill: "#b38916" }),
        React.createElement("path", { d: "m26.368 30.2884c0 6.3279-5.1339 11.4618-11.4618 11.4618v-22.9235c6.3279 0 11.4618 5.1339 11.4618 11.4617z", fill: "#f2cf66" }),
        React.createElement("path", { d: "m14.9071 18.8267v22.9235c-6.32788 0-11.46179-5.1339-11.46179-11.4618 0-6.3278 5.13391-11.4617 11.46179-11.4617z", fill: "#f2cf66" }),
        React.createElement("path", { d: "m21.864 30.2879c0 3.8423-3.1151 6.9574-6.9574 6.9574s-6.95738-3.1151-6.95738-6.9574 3.11508-6.9573 6.95738-6.9573 6.9574 3.115 6.9574 6.9573z", fill: "#edebce" }),
        React.createElement("g", { filter: "url(#a)" },
            React.createElement("path", { d: "m8.60317 24.9046-1.98012 1.9802 1.98012 1.9801 1.98013-1.9801z", fill: "#fff" })),
        React.createElement("path", { d: "m9.54449.25h-8.73199l9.7251 16.8562h8.7374z", fill: "#a3e2e8" })))));
};
var CourseDayInProgressIcon = function (_a) {
    var classObject = _a.classObject;
    return (React.createElement("svg", { className: css(classObject), viewBox: "0 0 29 42", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M20.2677 0.125L10.5371 16.9811H19.2691L28.9996 0.125H20.2677Z", fill: "#DEE1E4" }),
        React.createElement("path", { d: "M20.1079 14.4575H14.9088H9.69354C8.5593 14.4575 7.63672 15.3801 7.63672 16.5143V22.1041H22.1593V16.5143C22.1593 15.3801 21.2367 14.4575 20.1025 14.4575H20.1079ZM19.614 17.7083H10.182V17.0028H19.614V17.7083Z", fill: "#C5CBD1" }),
        React.createElement("path", { d: "M14.9071 41.625C21.2372 41.625 26.3688 36.4934 26.3688 30.1633C26.3688 23.8331 21.2372 18.7015 14.9071 18.7015C8.57691 18.7015 3.44531 23.8331 3.44531 30.1633C3.44531 36.4934 8.57691 41.625 14.9071 41.625Z", fill: "#DEE1E4" }),
        React.createElement("path", { d: "M21.8659 30.1631C21.8659 34.0053 18.7508 37.1204 14.9085 37.1204C11.0662 37.1204 7.95117 34.0053 7.95117 30.1631C7.95117 26.3208 11.0662 23.2057 14.9085 23.2057C18.7508 23.2057 21.8659 26.3208 21.8659 30.1631Z", fill: "#F2F6F6" }),
        React.createElement("path", { d: "M9.54644 0.125H0.814453L10.5396 16.9811H19.2716L9.54644 0.125Z", fill: "#F2F6F6" })));
};
