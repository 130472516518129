import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
import { ArrowIcon, ArrowDirection } from '../atoms';
export var InitialGuideCard = function (_a) {
    var imgSrc = _a.imgSrc, title = _a.title, description = _a.description, tag = _a.tag, onClick = _a.onClick;
    return (React.createElement("div", { className: css(styles.container), onClick: onClick },
        React.createElement("img", { className: css(styles.img), src: imgSrc }),
        React.createElement("div", { className: css(styles.text) },
            tag && React.createElement("div", { className: css(styles.tag) }, tag),
            React.createElement("div", { className: css(styles.title) }, title),
            React.createElement("div", { className: css(styles.description) }, description)),
        React.createElement(ArrowIcon, { classObject: styles.arrowIcon, color: Colors.GrayDarkest, direction: ArrowDirection.right })));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        gap: 16,
        backgroundColor: Colors.White,
        maxHeight: 140,
        padding: 20,
        marginBottom: 20,
        borderRadius: 10,
        boxShadow: '0px 15px 30px 0px rgba(0, 180, 199, 0.08)',
        cursor: 'pointer',
    },
    img: {
        width: 87,
    },
    text: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        gap: 8,
    },
    tag: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: Colors.White,
        backgroundColor: Colors.Accent,
        fontSize: 12,
        width: 'auto',
        height: 27,
        padding: '4px 10px',
        borderRadius: 20,
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: '0.56px',
    },
    description: {
        fontSize: 13,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: '0.52px',
    },
    arrowIcon: {
        width: 24,
    },
});
