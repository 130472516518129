import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { Button, ButtonType } from '@lean-body/components/atoms';
export var DailyTasksEmptyState = function (_a) {
    var onClick = _a.onClick;
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.description) },
            "\u8A3A\u65AD\u3092\u53D7\u3051\u308B\u3068",
            React.createElement("br", null),
            "\u3042\u306A\u305F\u306B\u5408\u3063\u305F\u76EE\u6A19\u3092\u8A2D\u5B9A\u3067\u304D\u307E\u3059\u3002",
            React.createElement("br", null),
            "\u5065\u5EB7\u7BA1\u7406\u306B\u5F79\u7ACB\u3066\u307E\u3057\u3087\u3046\uFF01"),
        React.createElement(Button, { buttonType: ButtonType.primary, className: css(styles.button), onClick: onClick, isRadius: true }, "\u8A3A\u65AD\u3057\u3066\u76EE\u6A19\u3092\u8A2D\u5B9A\u3059\u308B")));
};
var styles = StyleSheet.create({
    container: {
        position: 'absolute',
        textAlign: 'center',
        width: '100%',
        height: 'calc(100% - 24px)',
        marginTop: 24,
        paddingTop: 118,
        top: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        backdropFilter: 'blur(5px)',
    },
    description: {
        color: Colors.TextDark,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
    button: {
        fontSize: 18,
        textAlign: 'center',
        minWidth: 250,
        marginTop: 16,
        height: 44,
        paddingTop: 0,
        paddingBottom: 0,
    },
});
