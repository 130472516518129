import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MediaBreakPointDown, MediaBreakPointUp } from '@lean-body/components/styles';
export var LiveChat = function (props) {
    var _a, _b;
    var styles = StyleSheet.create({
        liveChatContainer: (_a = {
                textAlign: 'center',
                marginBottom: 40
            },
            _a[MediaBreakPointUp.XL] = {
                marginBottom: 40,
            },
            _a),
        iframe: (_b = {
                width: '100%',
                height: 370,
                border: 'none'
            },
            _b[MediaBreakPointDown.XS] = {
                height: 320,
            },
            _b[MediaBreakPointUp.SM] = {
                height: 409,
            },
            _b[MediaBreakPointUp.XL] = {
                border: 'lightgray solid 1px',
            },
            _b),
    });
    return (React.createElement("div", { className: css(styles.liveChatContainer) },
        React.createElement("iframe", { src: props.src, className: css(styles.iframe) })));
};
