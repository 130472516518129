import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { CloseIcon } from '@lean-body/components/atoms';
export var CircleCloseButton = function (_a) {
    var classObject = _a.classObject, onClick = _a.onClick;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(CloseIcon, { size: 10, color: Colors.CyanLightest, onClick: onClick })));
};
var styles = StyleSheet.create({
    container: {
        background: 'rgba(30, 32, 32, 0.15)',
        width: 26,
        height: 26,
        borderRadius: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    shareIcon: {
        width: 12,
        height: 13,
        margin: 5,
    },
});
