import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { CourseEmptyStateIcon, Button, ButtonType } from '@lean-body/components/atoms';
export var CourseEmptyState = function (_a) {
    var onClick = _a.onClick, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(CourseEmptyStateIcon, null),
        React.createElement("div", { className: css(styles.description) },
            "\u3042\u306A\u305F\u306B\u3074\u3063\u305F\u308A\u306E\u30B3\u30FC\u30B9\u3067",
            React.createElement("br", null),
            "\u30EC\u30C3\u30B9\u30F3\u3092\u3057\u3066\u307F\u307E\u3057\u3087\u3046\uFF01"),
        React.createElement(Button, { buttonType: ButtonType.primary, className: css(styles.button), onClick: onClick, isRadius: true }, "\u8A3A\u65AD\u3057\u3066\u30B3\u30FC\u30B9\u3092\u8A2D\u5B9A")));
};
var styles = StyleSheet.create({
    container: {
        textAlign: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 18,
    },
    description: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '140%',
        letterSpacing: '0.04em',
        color: Colors.TextDark,
    },
    button: {
        fontSize: 18,
        textAlign: 'center',
        minWidth: 250,
        height: 44,
        paddingTop: 0,
        paddingBottom: 0,
    },
});
