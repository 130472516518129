import { css } from 'aphrodite';
import * as React from 'react';
// srcが""の場合、もしくは画像リクエストが失敗した場合 fallbackElement を表示する
export var ImageWithFallback = function (_a) {
    var src = _a.src, classObject = _a.classObject, fallbackElement = _a.fallbackElement, lazy = _a.lazy;
    var _b = React.useState(!src), hasError = _b[0], setHasError = _b[1];
    var onError = function (e) {
        e.stopPropagation();
        setHasError(true);
    };
    return (React.createElement(React.Fragment, null, hasError ? (fallbackElement) : (React.createElement("img", { className: css(classObject), src: src, loading: lazy ? 'lazy' : 'eager', onError: onError }))));
};
