import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles/variables';
export var SwitchButtonComponent = function (_a) {
    var classObject = _a.classObject, onClick = _a.onClick, isOn = _a.isOn;
    var _b = React.useState(false), submitting = _b[0], setSubmitting = _b[1];
    var onSubmit = function () {
        if (submitting)
            return;
        setSubmitting(true);
        onClick().finally(function () {
            setSubmitting(false);
        });
    };
    return (React.createElement("div", { className: css(classObject, styles.switchContainer, isOn ? styles.switchOn : styles.switchOff), onClick: onSubmit }));
};
var styles = StyleSheet.create({
    switchContainer: {
        position: 'relative',
        width: 26,
        height: 14,
        borderRadius: 7,
        cursor: 'pointer',
        ':before': {
            content: '""',
            position: 'absolute',
            top: -3,
            borderRadius: 80,
            width: 20,
            height: 20,
        },
    },
    switchOn: {
        background: Colors.BlueLighterB,
        ':before': {
            right: -7,
            background: Colors.PrimaryDark,
        },
    },
    switchOff: {
        background: Colors.BackgroundLightGray,
        ':before': {
            left: -7,
            background: Colors.GrayDarkest,
        },
    },
});
