import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Input } from '../atoms';
import { Colors } from '../styles';
export var CorporationFormSection = function (_a) {
    var employeeCode = _a.employeeCode, employeeName = _a.employeeName, onChangeEmployeeCode = _a.onChangeEmployeeCode, onChangeEmployeeName = _a.onChangeEmployeeName;
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(Input, { type: "text", label: "\u793E\u54E1\u30B3\u30FC\u30C9", value: employeeCode, onChange: onChangeEmployeeCode, dataTest: "employeeCode", placeholder: "\u793E\u54E1\u30B3\u30FC\u30C9\u3092\u5165\u529B", containerClassObject: styles.employeeCode, labelClassObject: styles.inputLabel }),
        React.createElement(Input, { type: "text", label: "\u6C0F\u540D", value: employeeName, onChange: onChangeEmployeeName, dataTest: "employeeName", placeholder: "\u540D\u524D\u3092\u5165\u529B", labelClassObject: styles.inputLabel })));
};
var styles = StyleSheet.create({
    container: {
        marginBottom: 16,
        textAlign: 'left',
    },
    employeeCode: {
        marginBottom: 14,
    },
    inputLabel: {
        fontWeight: 'bold',
        color: Colors.Black,
    },
});
