import * as React from 'react';
import { ITEM_WIDTH_OVER_XL, ITEM_WIDTH_SP, VerticalListArrows, } from '@lean-body/components/molecules/vertical_list_arrows';
import { LessonComponent } from '../molecules/lesson_component';
export var LessonComponentCarousel = function (_a) {
    var lessons = _a.lessons, classObject = _a.classObject, itemMarginRight = _a.itemMarginRight, itemsPadding = _a.itemsPadding, addFavoriteLesson = _a.addFavoriteLesson, unFavoriteLesson = _a.unFavoriteLesson, isMin = _a.isMin, disableHistoryPush = _a.disableHistoryPush;
    var addFavorite = function (lesson) {
        lesson.isFavorite = true;
        addFavoriteLesson(lesson.id);
    };
    var unFavorite = function (lesson) {
        lesson.isFavorite = false;
        unFavoriteLesson(lesson.id);
    };
    return (React.createElement(VerticalListArrows, { classObject: classObject, itemsPadding: itemsPadding, itemMarginRight: itemMarginRight, itemSize: isMin ? ITEM_WIDTH_SP : ITEM_WIDTH_OVER_XL, items: lessons.map(function (v) { return (React.createElement(LessonComponent, { lesson: v, addFavoriteLesson: function () { return addFavorite(v); }, unFavoriteLesson: function () { return unFavorite(v); }, isMin: isMin, disableHistoryPush: disableHistoryPush })); }) }));
};
