/**
 * DBテーブルのモデルとAPIから返ってくるモデルを定義
 */
export var SuggestMethodType;
(function (SuggestMethodType) {
    SuggestMethodType["NextLesson"] = "next_lesson";
    SuggestMethodType["OtherChallengeNextLesson"] = "other_challenge_next_lesson";
    SuggestMethodType["Recommend"] = "recommend";
    SuggestMethodType["Favorite"] = "favorite";
})(SuggestMethodType || (SuggestMethodType = {}));
