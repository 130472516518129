import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { CameraIcon } from './icons/camera_icon';
export var CameraWithBg = function (_a) {
    var classObject = _a.classObject, svgWidth = _a.svgWidth;
    var styles = StyleSheet.create({
        container: {
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '0.1vmin',
            outline: 'none',
            cursor: 'pointer',
            width: 64,
            height: 64,
            background: Colors.Primary,
        },
        svg: {
            width: svgWidth || '50%',
        },
    });
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(CameraIcon, { classObject: styles.svg })));
};
