var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CourseHeaderThumbnail, CourseHeaderDetail } from '@lean-body/components/molecules';
import { MediaBreakPointUp, Colors } from '@lean-body/components/styles';
export var CourseHeader = function (_a) {
    var course = _a.course, classObject = _a.classObject, config = _a.config, onClickSetting = _a.onClickSetting, children = _a.children;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.content) },
            React.createElement(CourseHeaderDetail, { course: course, onClickSetting: onClickSetting }),
            React.createElement(CourseHeaderThumbnail, { courseThumbnailSrc: course.thumbnailURL(config), imgSize: 173 }),
            children)));
};
var styles = StyleSheet.create({
    container: (_a = {
            backgroundColor: Colors.PrimaryLighter,
            height: 173,
            paddingLeft: 20
        },
        _a[MediaBreakPointUp.MD] = {
            paddingLeft: 0,
        },
        _a),
    content: (_b = {
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
            margin: 'auto'
        },
        _b[MediaBreakPointUp.MD] = {
            width: 600,
        },
        _b),
});
