var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { bind } from 'bind-decorator';
import { PageLoading } from '@lean-body/components/organisms';
import { Button, ButtonType } from '@lean-body/components/atoms';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { routes } from '@lean-body/src/route';
import { ImageBlurCircle } from '@lean-body/components/atoms/image_blur_circle';
import { NewPlaylistForms } from '@lean-body/components/organisms/new_playlist_forms';
import { NewPlaylistLessons } from '@lean-body/components/organisms/new_playlist_lessons';
import { NewPlaylistImage } from '@lean-body/components/organisms/new_playlist_image';
import { ImageBlurSquare } from '@lean-body/components/atoms/image_blur_square';
import { DEFAULT_THUMBNAIL_KEYS } from '@lean-body/components/organisms/select_thumbnail_dialog';
import { useSmMediaQuery, useXlMediaQuery } from '@lean-body/src/util/media_query_hooks';
export var MainNewPlaylist = function (_a) {
    var vm = _a.vm;
    var isSM = useSmMediaQuery();
    var isXL = useXlMediaQuery();
    var isMounting = true;
    var isUpdate = !!vm.id;
    var _b = React.useState(null), profile = _b[0], setProfile = _b[1];
    var _c = React.useState(null), title = _c[0], setTitle = _c[1];
    var _d = React.useState(''), description = _d[0], setDescription = _d[1];
    var _e = React.useState([]), lessons = _e[0], setLessons = _e[1];
    var _f = React.useState(null), file = _f[0], setFile = _f[1];
    var _g = React.useState(null), imgSrc = _g[0], setImgSrc = _g[1];
    var _h = React.useState(DEFAULT_THUMBNAIL_KEYS.blue), defaultImgSrcKey = _h[0], setDefaultImgSrcKey = _h[1];
    var _j = React.useState(false), submitting = _j[0], setSubmitting = _j[1];
    var lessonIds = lessons.map(function (v) { return v.id; });
    /*
      methods
    */
    var submit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!lessonIds.length)
                return [2 /*return*/];
            if (!vm.validate(title, description)) {
                return [2 /*return*/];
            }
            setSubmitting(true);
            if (isUpdate) {
                vm.updateOriginalPlaylist(title, lessonIds, file, description, vm.id);
            }
            else {
                vm.createOriginalPlaylist(title, lessonIds, file, description);
                vm.tracker.trackOriginalPlaylistCreate();
            }
            return [2 /*return*/];
        });
    }); };
    var setTitleWithParams = function (fetchedProfile, fetchedPlaylist) {
        if (fetchedPlaylist)
            return setTitle(fetchedPlaylist.nameWithBreakLine);
        if (fetchedProfile.userName) {
            return setTitle("".concat(fetchedProfile.userName, "\u306E\n\u30AA\u30EA\u30B8\u30CA\u30EB\n\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8"));
        }
        setTitle('オリジナル\nプレイリスト');
    };
    var fetchPlaylist = function () {
        if (!isUpdate)
            return;
        return vm.apiClient.fetchOriginalPlaylist(vm.id);
    };
    /*
      useEffect
    */
    React.useEffect(function () {
        Promise.all([vm.apiClient.patchProfile(), fetchPlaylist()]).then(function (_a) {
            var fetchedProfile = _a[0], fetchedPlaylist = _a[1];
            if (isMounting) {
                setProfile(fetchedProfile);
                setTitleWithParams(fetchedProfile, fetchedPlaylist);
                if (fetchedPlaylist) {
                    setImgSrc(fetchedPlaylist.thumbnailURL);
                    setDescription(fetchedPlaylist.description);
                    setLessons(fetchedPlaylist.lessons);
                }
            }
        });
        return function () {
            isMounting = false;
        };
    }, []);
    /*
      Component
    */
    if (!profile || !title)
        return React.createElement(PageLoading, null);
    return (React.createElement("div", { className: css(styles.container) },
        !isXL ? (React.createElement(ImageBlurCircle, { src: imgSrc || "/assets/images/original_playlist/".concat(defaultImgSrcKey, ".jpg"), height: 240 })) : (React.createElement(ImageBlurSquare, { src: imgSrc || "/assets/images/original_playlist/".concat(defaultImgSrcKey, ".jpg"), height: 360 })),
        React.createElement("div", { className: css(styles.haedText) }, isUpdate ? 'プレイリストを編集' : '新規プレイリスト作成'),
        React.createElement("div", { className: css(isXL && styles.headerFlex) },
            React.createElement(NewPlaylistImage, { title: title, imgSrc: imgSrc, defaultImgSrcKey: defaultImgSrcKey, file: file, setImgSrc: setImgSrc, setFile: setFile, setDefaultImgSrcKey: setDefaultImgSrcKey }),
            React.createElement(NewPlaylistForms, { title: title, description: description, setTitle: setTitle, setDescription: setDescription, maxTitle: vm.MAX_TITLE, maxDescription: vm.MAX_DESCRIPTION, maxTitleLine: vm.MAX_TITLE_LINE, checkTitle: vm.checkTitle, checkDescription: vm.checkDescription })),
        React.createElement(NewPlaylistLessons, { lessons: lessons, setLessons: setLessons, fetchFavoriteLessons: vm.fetchFavoriteLessons, searchLessons: vm.searchLessons, clearSearchedLessons: vm.clearSearchedLessons, fetchLessonHistory: vm.fetchLessonHistory, getSearchKeywords: vm.getSearchKeywords }),
        React.createElement(Button, { buttonType: lessonIds.length > 0 ? ButtonType.primary : ButtonType.transparentPrimary, className: css(styles.button), isRadius: true, disabled: submitting, onClick: submit }, isUpdate ? '編集を終了する' : '作成する'),
        !isUpdate && isSM && (React.createElement(Button, { buttonType: ButtonType.secondary, className: css(styles.button), isRadius: true, onClick: vm.moveToMainHome }, "\u30AD\u30E3\u30F3\u30BB\u30EB"))));
};
var styles = StyleSheet.create({
    container: (_a = {
            position: 'relative',
            padding: '32px 24px 40px',
            backgroundColor: Colors.White,
            overflow: 'hidden'
        },
        _a[MediaBreakPointUp.MD] = {
            padding: '32px 48px 40px',
        },
        _a),
    haedText: (_b = {
            position: 'relative',
            textAlign: 'center',
            fontWeight: 'bold',
            margin: '0 0 24px'
        },
        _b[MediaBreakPointUp.XL] = {
            fontSize: 24,
            marginLeft: 32,
            textAlign: 'start',
        },
        _b),
    headerFlex: {
        display: 'flex',
        marginBottom: 80,
    },
    button: (_c = {
            width: '100%',
            height: 56,
            marginTop: 16,
            maxWidth: 375
        },
        _c[MediaBreakPointUp.MD] = {
            marginLeft: 32,
        },
        _c[':first-of-type'] = {
            marginTop: 40,
        },
        _c),
});
var MainNewPlaylistVM = /** @class */ (function () {
    function MainNewPlaylistVM(id, accountService, apiClient, router, alertPresenter, tracker) {
        Object.defineProperty(this, "MAX_TITLE", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 24
        });
        Object.defineProperty(this, "MAX_DESCRIPTION", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 200
        });
        Object.defineProperty(this, "MAX_TITLE_LINE", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 3
        });
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "router", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "fetchedFavoriteLessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "fetchedFavoriteLessonsPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "searchedLessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "searchedLessonsPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "fetchedLessonHistory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "fetchedLessonHistoryPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "favoriteLessonsPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "searchedkey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "keywords", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "checkInputBreakLine", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (text, maxLine) {
                var _a;
                var lineLimit = maxLine - 1; // 改行コードはmaxLine-1個まで
                var breakLineLength = (_a = text.match(/\n/g)) === null || _a === void 0 ? void 0 : _a.length;
                return breakLineLength && breakLineLength > lineLimit;
            }
        });
        this.id = id;
        this.accountService = accountService;
        this.apiClient = apiClient;
        this.router = router;
        this.alertPresenter = alertPresenter;
        this.tracker = tracker;
    }
    Object.defineProperty(MainNewPlaylistVM.prototype, "fetchFavoriteLessons", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (page) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.apiClient.fetchFavoriteLessons(page)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(MainNewPlaylistVM.prototype, "searchLessons", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (page, key) {
            return __awaiter(this, void 0, void 0, function () {
                var searchResult;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.apiClient.search({ q: key }, page)];
                        case 1:
                            searchResult = _a.sent();
                            return [2 /*return*/, searchResult.lessons];
                    }
                });
            });
        }
    });
    Object.defineProperty(MainNewPlaylistVM.prototype, "getSearchKeywords", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!!this.keywords) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.apiClient.getKeywords()];
                        case 1:
                            res = _a.sent();
                            this.keywords = res.keywords;
                            _a.label = 2;
                        case 2: return [2 /*return*/, this.keywords];
                    }
                });
            });
        }
    });
    Object.defineProperty(MainNewPlaylistVM.prototype, "clearSearchedLessons", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.searchedLessons = [];
        }
    });
    Object.defineProperty(MainNewPlaylistVM.prototype, "fetchLessonHistory", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (page) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.apiClient.fetchLessonHistory(page)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(MainNewPlaylistVM.prototype, "createOriginalPlaylist", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (title, lessonIds, file, description) {
            var _this = this;
            this.apiClient.createOriginalPlaylist(title, lessonIds, file, description).then(function (id) {
                _this.router.push(routes.ORIGINAL_PLAYLIST, { id: String(id) });
            });
        }
    });
    Object.defineProperty(MainNewPlaylistVM.prototype, "updateOriginalPlaylist", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (title, lessonIds, file, description, id) {
            var _this = this;
            this.apiClient.updateOriginalPlaylist(title, lessonIds, file, description, id).then(function () {
                _this.router.push(routes.ORIGINAL_PLAYLIST, { id: String(id) });
            });
        }
    });
    Object.defineProperty(MainNewPlaylistVM.prototype, "validate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (title, description) {
            var _this = this;
            var errorMessages = [];
            var titleErrorMessage = this.checkTitle(title);
            var descriptionErrorMessage = this.checkDescription(description);
            if (titleErrorMessage)
                errorMessages.push(titleErrorMessage);
            if (descriptionErrorMessage)
                errorMessages.push(descriptionErrorMessage);
            errorMessages.forEach(function (message) {
                _this.alertPresenter.showMessage(message);
            });
            return errorMessages.length === 0;
        }
    });
    Object.defineProperty(MainNewPlaylistVM.prototype, "checkTitle", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (title) {
            if (this.checkInputLength(title, this.MAX_TITLE)) {
                return "\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u540D\u306F".concat(this.MAX_TITLE, "\u6587\u5B57\u4EE5\u4E0A\u306F\u5165\u529B\u3067\u304D\u307E\u305B\u3093\u3002");
            }
            if (this.checkInputBreakLine(title, this.MAX_TITLE_LINE)) {
                return "\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u540D\u306F".concat(this.MAX_TITLE_LINE, "\u884C\u3092\u8D85\u3048\u3066\u6539\u884C\u3067\u304D\u307E\u305B\u3093\u3002");
            }
            return;
        }
    });
    Object.defineProperty(MainNewPlaylistVM.prototype, "checkDescription", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (description) {
            if (this.checkInputLength(description, this.MAX_DESCRIPTION)) {
                return "\u8AAC\u660E\u6587\u306F".concat(this.MAX_DESCRIPTION, "\u6587\u5B57\u4EE5\u4E0A\u306F\u5165\u529B\u3067\u304D\u307E\u305B\u3093\u3002");
            }
            return;
        }
    });
    Object.defineProperty(MainNewPlaylistVM.prototype, "checkInputLength", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (text, maxText) {
            return text.replace(/\n/g, '').length > maxText;
        }
    });
    Object.defineProperty(MainNewPlaylistVM.prototype, "moveToMainHome", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.router.push(routes.TOP);
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Promise)
    ], MainNewPlaylistVM.prototype, "fetchFavoriteLessons", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, String]),
        __metadata("design:returntype", Promise)
    ], MainNewPlaylistVM.prototype, "searchLessons", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], MainNewPlaylistVM.prototype, "getSearchKeywords", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], MainNewPlaylistVM.prototype, "clearSearchedLessons", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Promise)
    ], MainNewPlaylistVM.prototype, "fetchLessonHistory", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, Array, File, String]),
        __metadata("design:returntype", void 0)
    ], MainNewPlaylistVM.prototype, "createOriginalPlaylist", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, Array, File, String, Number]),
        __metadata("design:returntype", void 0)
    ], MainNewPlaylistVM.prototype, "updateOriginalPlaylist", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, String]),
        __metadata("design:returntype", Boolean)
    ], MainNewPlaylistVM.prototype, "validate", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", String)
    ], MainNewPlaylistVM.prototype, "checkTitle", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", String)
    ], MainNewPlaylistVM.prototype, "checkDescription", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], MainNewPlaylistVM.prototype, "moveToMainHome", null);
    return MainNewPlaylistVM;
}());
export { MainNewPlaylistVM };
