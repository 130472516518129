import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
var dsn = 'https://65ef11089e0441ceba8bcf87de2e58a0@o181208.ingest.sentry.io/6145432';
// Sentryで無視するエラーメッセージ
var ignoredErrorMessagesRegExp = [
    /ResizeObserver loop/i,
    /database unavailable/i,
    /NotAllowedError: play\(\) failed because/i,
    /AbortError: The play\(\) request was interrupted/i,
    /AbortError: The operation was aborted/i,
    /NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission/i,
    /null is not an object \(evaluating 'planElement.innerText'\)/i,
    /Cannot read properties of null \(reading 'innerText'\)/i,
];
export var LoadSentry = function (config) {
    var environment = config.isProduction ? 'production' : 'development';
    Sentry.init({
        dsn: dsn,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: useEffect,
                useLocation: useLocation,
                useNavigationType: useNavigationType,
                createRoutesFromChildren: createRoutesFromChildren,
                matchRoutes: matchRoutes,
            }),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        environment: environment,
        beforeSend: function (event, hint) {
            var originalErr = hint === null || hint === void 0 ? void 0 : hint.originalException;
            var hasIgnoreOriginalErr = originalErr === null || originalErr === void 0 ? void 0 : originalErr.isUnauthorizedError;
            var hasIgnoreMessage = event && event.message && ignoredErrorMessagesRegExp.some(function (regexp) { return regexp.test(event.message); });
            if (hasIgnoreMessage || hasIgnoreOriginalErr)
                return null;
            return event;
        },
    });
};
