import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { FormLabel } from '@lean-body/components/atoms/form_label';
import { Textarea } from '@lean-body/components/atoms/textarea';
import { FormGroup } from '../layouts';
var styles = StyleSheet.create({
    textArea: {
        height: '10rem',
        width: '100%',
    },
});
export var TextareaFormGroup = function (props) {
    var containerClass = props.className || '';
    var onChange = function (event) {
        props.onChange && props.onChange(event);
    };
    return (React.createElement(FormGroup, { className: containerClass },
        React.createElement(FormLabel, null, props.title),
        React.createElement("div", null,
            React.createElement(Textarea, { className: css(styles.textArea), onChange: onChange, value: props.value }))));
};
