var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { routes } from '@lean-body/src/route';
import { LocalStorage, SessionStorage } from '@lean-body/src/infra';
import { MAX_DATE_TIMESTAMP, exceedWeek, isAndroidApp } from '@lean-body/src/util';
import { UserSeasonAggregate } from '@lean-body/src/domain';
import { MypageInputPromotionDialog, ReceivedBadgeOrUserSeasonRankDialog, ReleaseNotificationModal, TwitterFormDialog, } from '../molecules';
import { useNavigate } from 'react-router';
import { SettingItemKeys } from './setting_menu';
import { MealTaskNotificationRequestDialog } from '../molecules/meal_task_notification_permission_dialog';
import { MealTaskAboutDialog } from '../molecules/meal_task_about_dialog';
export var HomeDialogGroup = function (_a) {
    var mealTaskAboutDialogRoute = _a.mealTaskAboutDialogRoute, profile = _a.profile, twitterService = _a.twitterService, apiClient = _a.apiClient, config = _a.config;
    var navigate = useNavigate();
    var localStorage = new LocalStorage();
    var sessionStorage = new SessionStorage();
    var _b = React.useState(), newReleaseNotificationDialog = _b[0], setNewReleaseNotificationDialog = _b[1];
    var userSeasonAggregate = React.useState((function (v) { return v && new UserSeasonAggregate(v, config); })(localStorage.loadUserSeasonAggregateRank))[0];
    var androidUIDismissals = localStorage.loadAndroidNotificationPermissionRequestUIDismissals();
    var _c = React.useState(false), showUserSeasonRankDialog = _c[0], setShowUserSeasonRankDialog = _c[1];
    var _d = React.useState(false), showTwitterFormDialog = _d[0], setShowTwitterFormDialog = _d[1];
    var _e = React.useState(false), showNewReleaseNotificationDialog = _e[0], setShowNewReleaseNotificationDialog = _e[1];
    var _f = React.useState(false), showMypageInputPromotionDialog = _f[0], setShowMypageInputPromotionDialog = _f[1];
    var _g = React.useState(false), showMealTaskNotificationRequestDialog = _g[0], setShowMealTaskNotificationRequestDialog = _g[1];
    var _h = React.useState(false), showMealTaskAboutDialog = _h[0], setShowMealTaskAboutDialog = _h[1];
    // 食事記録ダイアログはpropsから確認して表示
    React.useEffect(function () {
        setShowMealTaskAboutDialog(!!mealTaskAboutDialogRoute);
    }, [mealTaskAboutDialogRoute]);
    React.useEffect(function () {
        var _a;
        // シーズンランクダイアログ
        setShowUserSeasonRankDialog(!!userSeasonAggregate);
        // Twitter(X)ダイアログ
        setShowTwitterFormDialog(twitterService.showFormDialog);
        twitterService.showFormDialog = false;
        // リリースダイアログ
        apiClient.fetchDialog().then(function (fetchDialog) {
            setNewReleaseNotificationDialog(fetchDialog);
            if (fetchDialog && fetchDialog.id > localStorage.loadHomeDialogId) {
                setShowNewReleaseNotificationDialog(true);
            }
        });
        // マイページ記入ダイアログ
        setShowMypageInputPromotionDialog(profile.showPromotionDialog);
        // 食事通知許可ダイアログ
        if (localStorage.loadShowMealTaskNotificationPermissionDialog) {
            // 食事記録をした直後のみ表示したいので、すぐ無効にする
            localStorage.saveShowMealTaskNotificationPermissionDialog(false);
            if (isAndroidApp()) {
                /*
                  以下の条件で食事入力忘れプッシュ通知許諾を求めるダイアログを表示する
                    1. 端末の通知設定がOFFになっている、もしくは食事入力忘れ通知がOFFになっている
                    2. ダイアログを表示したことない
                */
                var hasNotificationPermission = sessionStorage.loadAndroidDeviceNotificationPermission();
                var useMealTaskNotifications = ((_a = sessionStorage.loadAndroidNotificationSettings()) === null || _a === void 0 ? void 0 : _a.mealTaskRemindAt) !== '';
                if (hasNotificationPermission && useMealTaskNotifications) {
                    return;
                }
                if (androidUIDismissals.mealTaskDialog === false || exceedWeek(androidUIDismissals.mealTaskDialog)) {
                    setShowMealTaskNotificationRequestDialog(true);
                }
            }
        }
    }, []);
    var onClickTwitterShareIfAuthed = function () {
        setShowTwitterFormDialog(true);
    };
    var onCloseUserSeasonRankingDialog = function () {
        setShowUserSeasonRankDialog(false);
    };
    var onCloseReleaseNotificationDialog = function () {
        localStorage.saveHomeDialogId(newReleaseNotificationDialog.id);
        setShowNewReleaseNotificationDialog(false);
    };
    var onCloseMypageInputPromotionDialog = function () {
        setShowMypageInputPromotionDialog(false);
    };
    var onClickTwitterPost = function (text, file) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            twitterService.postTweet(text, file).finally(function () {
                setShowTwitterFormDialog(false);
            });
            return [2 /*return*/];
        });
    }); };
    var onClickTwitterPostCancel = function () {
        setShowTwitterFormDialog(false);
    };
    var onClickMealTaskNotificationPermissionDialog = function (isDontShowAgain) {
        androidUIDismissals.mealTaskDialog = isDontShowAgain ? MAX_DATE_TIMESTAMP : Date.now();
        localStorage.setAndroidNotificationPermissionRequestUIDismissals(androidUIDismissals);
        navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.notification));
    };
    var onCloseMealTaskNotificationPermissionDialog = function (isDontShowAgain) {
        androidUIDismissals.mealTaskDialog = isDontShowAgain ? MAX_DATE_TIMESTAMP : Date.now();
        localStorage.setAndroidNotificationPermissionRequestUIDismissals(androidUIDismissals);
        setShowMealTaskNotificationRequestDialog(false);
    };
    var onClickMealTaskAboutDialogCancel = function () {
        setShowMealTaskAboutDialog(false);
    };
    var onClickMealTaskAboutDialogSuccess = function () {
        navigate(mealTaskAboutDialogRoute);
    };
    return (React.createElement(React.Fragment, null, showUserSeasonRankDialog ? (React.createElement(ReceivedBadgeOrUserSeasonRankDialog, { userSeasonAggregate: userSeasonAggregate, onClickCancel: onCloseUserSeasonRankingDialog, twitterService: twitterService, onClickTwitterShareIfAuthed: onClickTwitterShareIfAuthed })) : showTwitterFormDialog ? (React.createElement(TwitterFormDialog, { imageURL: twitterService.tweetImgSrc, centerImageWidth: twitterService.tweetCenterImageWidth, defaultText: twitterService.tweetDefaultText, onClickCancel: onClickTwitterPostCancel, submit: onClickTwitterPost })) : showNewReleaseNotificationDialog ? (React.createElement(ReleaseNotificationModal, { close: onCloseReleaseNotificationDialog, dialog: newReleaseNotificationDialog })) : showMypageInputPromotionDialog ? (React.createElement(MypageInputPromotionDialog, { apiClient: apiClient, close: onCloseMypageInputPromotionDialog })) : showMealTaskNotificationRequestDialog ? (React.createElement(MealTaskNotificationRequestDialog, { onClickSuccess: onClickMealTaskNotificationPermissionDialog, onClickCancel: onCloseMealTaskNotificationPermissionDialog })) : (showMealTaskAboutDialog && (React.createElement(MealTaskAboutDialog, { onClickCancel: onClickMealTaskAboutDialogCancel, onClickSuccess: onClickMealTaskAboutDialogSuccess })))));
};
