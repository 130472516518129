var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import { observer } from 'mobx-react';
import { computed, observable, makeObservable } from 'mobx';
import { bind } from 'bind-decorator';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { AnnouncementListItem } from '@lean-body/components/organisms/announcement_list_item';
import { PaginationNextButton } from '@lean-body/components/atoms/pagination_next_button';
var MainAnnouncementListComponent = /** @class */ (function (_super) {
    __extends(MainAnnouncementListComponent, _super);
    function MainAnnouncementListComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(MainAnnouncementListComponent.prototype, "componentWillUnmount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            this.props.vm.apiClient
                .updateAllAnnouncementReadInformation()
                .then(function () { return _this.props.vm.announcementService.unreadAnnouncementCount.changeToZero(); });
        }
    });
    Object.defineProperty(MainAnnouncementListComponent.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            // src/view/pages/main.tsxのcontentのcssの影響を受けないためにdivタグで囲んでます
            return (React.createElement("div", { className: css(this.styles.container) },
                React.createElement("div", { className: css(this.styles.head) }, "\u304A\u77E5\u3089\u305B"),
                this.props.vm.announcements.map(function (v, i) { return (React.createElement(AnnouncementListItem, { announcement: v, me: _this.props.vm.me, key: i })); }),
                React.createElement(PaginationNextButton, { getNextPage: this.fetchAnnouncements, isAvailable: !this.props.vm.isLastPage })));
        }
    });
    Object.defineProperty(MainAnnouncementListComponent.prototype, "styles", {
        get: function () {
            var _a, _b;
            return StyleSheet.create({
                container: {
                    backgroundColor: Colors.BackgroundLightGray,
                },
                head: (_a = {
                        fontSize: 16,
                        fontWeight: 'bold',
                        padding: '36px 0 24px',
                        borderBottom: "1px solid ".concat(Colors.BorderLightGrayB),
                        textAlign: 'center',
                        maxWidth: 600
                    },
                    _a[MediaBreakPointUp.SM] = {
                        fontSize: 20,
                        margin: '0 40px',
                        textAlign: 'left',
                    },
                    _a),
                moreButton: (_b = {
                        padding: '14px 0',
                        textAlign: 'center',
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: Colors.GrayDarkestB,
                        border: "1px solid  ".concat(Colors.GrayDarkest),
                        borderRadius: 4,
                        width: 120,
                        margin: '32px 24px 40px',
                        backgroundColor: Colors.White
                    },
                    _b[MediaBreakPointUp.SM] = {
                        margin: '32px 40px 40px',
                    },
                    _b),
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MainAnnouncementListComponent.prototype, "fetchAnnouncements", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.props.vm.fetchAnnouncements();
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], MainAnnouncementListComponent.prototype, "fetchAnnouncements", null);
    return MainAnnouncementListComponent;
}(React.Component));
export var MainAnnouncementList = observer(MainAnnouncementListComponent);
var MainAnnouncementListVM = /** @class */ (function () {
    function MainAnnouncementListVM(announcementService, accountService, apiClient) {
        var _this = this;
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "announcementService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "announcements", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "lastPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "currentPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "me", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            announcements: observable,
            lastPage: observable,
            currentPage: observable,
            me: observable,
            isLastPage: computed,
        });
        this.apiClient = apiClient;
        this.announcementService = announcementService;
        this.fetchAnnouncements();
        accountService.fetchMe().then(function (me) {
            _this.me = me;
        });
    }
    Object.defineProperty(MainAnnouncementListVM.prototype, "fetchAnnouncements", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            this.currentPage += 1;
            this.apiClient.fetchAnnouncements(this.currentPage).then(function (v) {
                var _a;
                _this.announcements = (_a = _this.announcements).concat.apply(_a, v.announcements);
                _this.lastPage = v.lastPage;
            });
        }
    });
    Object.defineProperty(MainAnnouncementListVM.prototype, "isLastPage", {
        get: function () {
            return this.currentPage === this.lastPage;
        },
        enumerable: false,
        configurable: true
    });
    return MainAnnouncementListVM;
}());
export { MainAnnouncementListVM };
