import * as React from 'react';
import { css } from 'aphrodite';
import { LocalStorage } from '@lean-body/src/infra';
import { RecordMealAnalysisResult } from './record_meal_analysis_result';
import { RecordMealAnalysisMenuChange } from './record_meal_analysis_menu_change';
import { MENU_AMOUNT_PERCENT_FULL, RecordMealActionType } from './record_meal';
import { ExitConfirmationDialog } from '../molecules/exit_confirmation_dialog';
import { RecordMealAnalysisMenuAdd } from './record_meal_analysis_menu_add';
import { MealImageAnalysisDialog } from '../molecules/meal_image_analysis_dialog';
import { MealHeader } from '../atoms/meal_header';
import { MealRecordSheet } from '../molecules/meal_record_sheet';
import { RecordMealMenuDetail } from './record_meal_menu_detail';
import { useSmMediaQuery } from '@lean-body/src/util';
import { CalomealErrorMessage } from '../atoms/calomeal_error_message';
import { LoadingOverlay } from '../atoms/loading_overlay';
import { ECONNABORTED_ERROR_CODE } from '@lean-body/src/errors';
import { IMAGE_ANALYSIS_ERROR, NETWORK_ERROR } from '@lean-body/src/messages/alerts';
var MEAL_IMAGE_ANALYSIS_DEFAULT_MENU_INDEX = 0;
export var ImageAnalysisActionType = {
    result: 'result',
    add: 'add',
    change: 'change',
};
export var RecordMealAnalysis = function (_a) {
    var _b;
    var headerTitle = _a.headerTitle, selectedMenuMap = _a.selectedMenuMap, selectedAnalysisMenuMap = _a.selectedAnalysisMenuMap, analysisImage = _a.analysisImage, mealType = _a.mealType, setAction = _a.setAction, setIsChangedMenu = _a.setIsChangedMenu, setSelectedAnalysisMenuMap = _a.setSelectedAnalysisMenuMap, onAddImageMap = _a.onAddImageMap, onAnalyseError = _a.onAnalyseError, onShowError = _a.onShowError, classObject = _a.classObject, apiClient = _a.apiClient, config = _a.config;
    var localStorage = new LocalStorage();
    var isSM = useSmMediaQuery();
    var _c = React.useState(''), calomealErrMessage = _c[0], setCalomealErrMessage = _c[1];
    var _d = React.useState(false), isAnalyzingImage = _d[0], setIsAnalyzingImage = _d[1];
    var _e = React.useState(''), imageUrl = _e[0], setImageUrl = _e[1];
    var _f = React.useState(ImageAnalysisActionType.result), analysisAction = _f[0], setAnalysisAction = _f[1];
    var _g = React.useState(new Map()), analysisResultMenuMap = _g[0], setAnalysisResultMenuMap = _g[1];
    // 画像解析結果ページに表示するメニュー
    var _h = React.useState(new Map()), displayedAnalysisMenuMap = _h[0], setDisplayedAnalysisMenuMap = _h[1];
    // 画像解析中にチェックを入れているメニュー
    var _j = React.useState(new Map()), checkedAnalysisMenuMap = _j[0], setCheckedAnalysisMenuMap = _j[1];
    // 画像解析中のメニュー追加、もしくは変更時にチェックを入れているメニュー
    var _k = React.useState(new Map()), changeCheckedAnalysisMenuMap = _k[0], setChangeCheckedAnalysisMenuMap = _k[1];
    var _l = React.useState(), analysisMenuChangeIndex = _l[0], setAnalysisMenuChangeIndex = _l[1];
    var _m = React.useState(null), menuSelection = _m[0], setMenuSelection = _m[1];
    var _o = React.useState(), menuAmount = _o[0], setMenuAmount = _o[1];
    var _p = React.useState(false), showExitDialog = _p[0], setShowExitDialog = _p[1];
    var _q = React.useState(false), showAnalysisDescriptionDialog = _q[0], setShowAnalysisDescriptionDialog = _q[1];
    var _r = React.useState(false), isChangedAnalysisMenu = _r[0], setIsChangedAnalysisMenu = _r[1];
    React.useEffect(function () {
        setIsAnalyzingImage(true);
        var reader = new FileReader();
        reader.onload = function (e) { return setImageUrl(e.target.result.toString()); };
        reader.readAsDataURL(analysisImage);
        apiClient
            .fetchAnalyseMealImage(analysisImage)
            .then(function (v) {
            setAnalysisResultMenuMap(new Map(v.mealImageAnalyses.map(function (mealImageAnalysis, i) { return [i, mealImageAnalysis]; })));
            var initialMealMenuMap = new Map(v.mealImageAnalyses.map(function (v, i) { return [i, v.menus[MEAL_IMAGE_ANALYSIS_DEFAULT_MENU_INDEX]]; }));
            setDisplayedAnalysisMenuMap(initialMealMenuMap);
            setCheckedAnalysisMenuMap(new Map(initialMealMenuMap));
            if (v.displayImageAnalysisDescriptionDialog && !localStorage.loadShowMealImageAnalysisDescriptionDialog) {
                setShowAnalysisDescriptionDialog(true);
                localStorage.saveShowMealImageAnalysisDescriptionDialog(true);
            }
            setCalomealErrMessage(v.error);
        })
            .catch(function (e) {
            if (e.code === ECONNABORTED_ERROR_CODE) {
                onAnalyseError(NETWORK_ERROR);
            }
            else {
                onAnalyseError(IMAGE_ANALYSIS_ERROR);
            }
        })
            .finally(function () {
            setIsAnalyzingImage(false);
        });
    }, []);
    var onClickBack = function () {
        if (analysisAction === ImageAnalysisActionType.result || isChangedAnalysisMenu) {
            setShowExitDialog(true);
        }
        else {
            setAnalysisAction(ImageAnalysisActionType.result);
        }
    };
    var onClickSelectMenu = function (index, menu) {
        if (index === null) {
            setCheckedAnalysisMenuMap(new Map(checkedAnalysisMenuMap.set(Math.max.apply(Math, Array.from(displayedAnalysisMenuMap.keys())) + 1, menu)));
        }
        else {
            setCheckedAnalysisMenuMap(new Map(checkedAnalysisMenuMap.set(index, menu)));
        }
    };
    var onClickDeselectMenu = function (index) {
        if (index === null) {
            checkedAnalysisMenuMap.delete(Math.max.apply(Math, Array.from(checkedAnalysisMenuMap.keys())));
        }
        else {
            checkedAnalysisMenuMap.delete(index);
        }
        setCheckedAnalysisMenuMap(new Map(checkedAnalysisMenuMap));
    };
    var onClickAddMenu = function () {
        setAnalysisMenuChangeIndex(null);
        setChangeCheckedAnalysisMenuMap(new Map());
        setAnalysisAction(ImageAnalysisActionType.add);
        window.scrollTo(0, 0);
    };
    var onClickMenuChange = function (menuIndex) {
        setAnalysisMenuChangeIndex(menuIndex);
        var checkedMenu = checkedAnalysisMenuMap.get(menuIndex);
        var checkedMenuMap = checkedMenu ? new Map([[checkedMenu.menuKey, checkedMenu]]) : new Map();
        setChangeCheckedAnalysisMenuMap(checkedMenuMap);
        setAnalysisAction(ImageAnalysisActionType.change);
        window.scrollTo(0, 0);
    };
    var onClickExit = function () {
        setShowExitDialog(false);
        if (analysisAction === ImageAnalysisActionType.result) {
            setCheckedAnalysisMenuMap(new Map());
            setAction(RecordMealActionType.select);
        }
        else {
            setAnalysisAction(ImageAnalysisActionType.result);
            setIsChangedAnalysisMenu(false);
        }
    };
    var onCloseExitDialog = function () {
        setShowExitDialog(false);
    };
    var onCloseAnalysisDescriptionDialog = function () {
        setShowAnalysisDescriptionDialog(false);
    };
    var onClickConfirm = function () {
        if (analysisAction === ImageAnalysisActionType.result) {
            onAddImageMap(analysisImage, imageUrl);
            var selectedAnalysisMenuMaxIndex_1 = selectedAnalysisMenuMap.size
                ? Math.max.apply(Math, Array.from(selectedAnalysisMenuMap.keys()).map(function (i) { return i; })) : 0;
            Array.from(checkedAnalysisMenuMap.values()).forEach(function (mealMenu) {
                selectedAnalysisMenuMap.set(++selectedAnalysisMenuMaxIndex_1, mealMenu);
            });
            setSelectedAnalysisMenuMap(new Map(selectedAnalysisMenuMap));
            setCheckedAnalysisMenuMap(new Map());
            setIsChangedMenu(true);
            setAction(RecordMealActionType.confirm);
        }
        else {
            if (changeCheckedAnalysisMenuMap.size) {
                Array.from(changeCheckedAnalysisMenuMap.values()).forEach(function (mealMenu) {
                    checkedAnalysisMenuMap.set(analysisMenuAddOrChangeIndex(), mealMenu);
                    displayedAnalysisMenuMap.set(analysisMenuAddOrChangeIndex(), mealMenu);
                });
                setCheckedAnalysisMenuMap(new Map(checkedAnalysisMenuMap));
                setDisplayedAnalysisMenuMap(new Map(displayedAnalysisMenuMap));
            }
            setChangeCheckedAnalysisMenuMap(new Map());
            setAnalysisAction(ImageAnalysisActionType.result);
            setIsChangedAnalysisMenu(false);
        }
    };
    var analysisMenuAddOrChangeIndex = function () {
        if (analysisMenuChangeIndex == null) {
            return displayedAnalysisMenuMap.size > 0 ? Math.max.apply(Math, Array.from(displayedAnalysisMenuMap.keys())) + 1 : 0;
        }
        else {
            return analysisMenuChangeIndex;
        }
    };
    var onClickMenu = function (index, menu) {
        setAnalysisMenuChangeIndex(index);
        setMenuSelection(menu);
        setMenuAmount(menu.amountPercentage.toString());
        window.scrollTo(0, 0);
    };
    var onClickMenuAddOrChange = function (menu) {
        setMenuSelection(menu);
        setMenuAmount(menu.amountPercentage.toString());
    };
    var onCloseMenuDetail = function () {
        setMenuSelection(null);
    };
    var onClickSaveAmount = function () {
        var amount = menuAmount ? parseFloat(menuAmount) : MENU_AMOUNT_PERCENT_FULL;
        if (amount <= 0) {
            onShowError('0より大きい数値を入力してください。');
            return;
        }
        menuSelection.amount = amount / 100;
        if (analysisAction === ImageAnalysisActionType.result) {
            setCheckedAnalysisMenuMap(new Map(checkedAnalysisMenuMap.set(analysisMenuAddOrChangeIndex(), menuSelection)));
        }
        else if (analysisAction === ImageAnalysisActionType.add) {
            changeCheckedAnalysisMenuMap.set(menuSelection.menuKey, menuSelection);
            setChangeCheckedAnalysisMenuMap(new Map(changeCheckedAnalysisMenuMap));
            setIsChangedAnalysisMenu(true);
        }
        else if (analysisAction === ImageAnalysisActionType.change) {
            setChangeCheckedAnalysisMenuMap(new Map([[menuSelection.menuKey, menuSelection]]));
            setIsChangedAnalysisMenu(true);
        }
        setMenuSelection(null);
    };
    return (React.createElement(React.Fragment, null, isAnalyzingImage ? (React.createElement(LoadingOverlay, { text: "\u8AAD\u307F\u53D6\u308A\u4E2D\u2026" })) : (React.createElement(React.Fragment, null, menuSelection && isSM ? (React.createElement(RecordMealMenuDetail, { menu: menuSelection, amount: menuAmount, onAmountInputChange: setMenuAmount, onCloseMenuDetail: onCloseMenuDetail, onClickSaveAmount: onClickSaveAmount })) : (React.createElement(React.Fragment, null,
        analysisAction == ImageAnalysisActionType.result ? (React.createElement(React.Fragment, null,
            React.createElement(CalomealErrorMessage, { config: config, message: calomealErrMessage }),
            React.createElement(RecordMealAnalysisResult, { headerTitle: headerTitle, imageUrl: imageUrl, analysisResultMenuMap: analysisResultMenuMap, displayedAnalysisMenuMap: displayedAnalysisMenuMap, checkedAnalysisMenuMap: checkedAnalysisMenuMap, onClick: onClickMenu, onClickBack: onClickBack, onClickSelect: onClickSelectMenu, onClickDeselect: onClickDeselectMenu, onClickAddMenu: onClickAddMenu, onClickChange: onClickMenuChange, menusClassObject: classObject }))) : analysisAction == ImageAnalysisActionType.add ? (React.createElement(React.Fragment, null,
            React.createElement(MealHeader, { onClickBack: onClickBack }, headerTitle),
            React.createElement("div", { className: css(classObject) },
                React.createElement(RecordMealAnalysisMenuAdd, { mealType: mealType, changeCheckedAnalysisMenuMap: changeCheckedAnalysisMenuMap, setChangeCheckedAnalysisMenuMap: setChangeCheckedAnalysisMenuMap, setIsChangedAnalysisMenu: setIsChangedAnalysisMenu, onClickMenu: onClickMenuAddOrChange, apiClient: apiClient, config: config })))) : (React.createElement(React.Fragment, null,
            React.createElement(MealHeader, { onClickBack: onClickBack }, headerTitle),
            React.createElement("div", { className: css(classObject) },
                React.createElement(RecordMealAnalysisMenuChange, { analysisResultMenus: (_b = analysisResultMenuMap.get(analysisMenuChangeIndex)) === null || _b === void 0 ? void 0 : _b.menus, displayedAnalysisMenu: displayedAnalysisMenuMap.get(analysisMenuChangeIndex), changeCheckedAnalysisMenuMap: changeCheckedAnalysisMenuMap, setChangeCheckedAnalysisMenuMap: setChangeCheckedAnalysisMenuMap, setIsChangedAnalysisMenu: setIsChangedAnalysisMenu, onClickMenu: onClickMenuAddOrChange, apiClient: apiClient, config: config })))),
        React.createElement(MealRecordSheet, { action: RecordMealActionType.analysis, analysisAction: analysisAction, selectedMenuMap: selectedMenuMap, selectedAnalysisMenuMap: selectedAnalysisMenuMap, checkedAnalysisMenuMap: checkedAnalysisMenuMap, changeCheckedAnalysisMenuMap: changeCheckedAnalysisMenuMap, onClickConfirm: onClickConfirm }),
        menuSelection && (React.createElement(RecordMealMenuDetail, { menu: menuSelection, amount: menuAmount, onAmountInputChange: setMenuAmount, onCloseMenuDetail: onCloseMenuDetail, onClickSaveAmount: onClickSaveAmount })),
        showExitDialog && (React.createElement(ExitConfirmationDialog, { description: "\u7DE8\u96C6\u4E2D\u306E\u767B\u9332\u5185\u5BB9\u306F\u7834\u68C4\u3055\u308C\u307E\u3059\u3002\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F", exitButtonMessage: analysisAction == ImageAnalysisActionType.result ? '破棄して終了' : '破棄して戻る', cancelButtonMessage: "\u767B\u9332\u3092\u7D9A\u3051\u308B", onClickExit: onClickExit, onClickCancel: onCloseExitDialog })),
        showAnalysisDescriptionDialog && React.createElement(MealImageAnalysisDialog, { onClick: onCloseAnalysisDescriptionDialog })))))));
};
