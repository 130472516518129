import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var ShareIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject, onClick = _a.onClick;
    var styles = StyleSheet.create({
        icon: {
            width: 22,
            height: 24,
        },
    });
    var iconColor = color || Colors.Black;
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 22 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", onClick: onClick },
        React.createElement("path", { d: "M20.8401 12.9297V22.0297H1.16016V12.9297", stroke: iconColor, strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M4.64062 7.48988L11.0006 1.12988L17.3606 7.48988", stroke: iconColor, strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M11 15.7899V1.12988", stroke: iconColor, strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })));
};
