var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, StyleSheet } from 'aphrodite-jss';
import bind from 'bind-decorator';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CloseButton } from '@lean-body/components/atoms/close_button';
import { Colors } from '@lean-body/components/styles';
var Alert = /** @class */ (function (_super) {
    __extends(Alert, _super);
    function Alert() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        Object.defineProperty(_this, "keyframes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                '0%': {
                    opacity: 0,
                    transform: 'scale(0.7, 0.7)',
                },
                '100%': {
                    opacity: 1,
                    transform: 'scale(1, 1)',
                },
            }
        });
        return _this;
    }
    Object.defineProperty(Alert.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement("div", { className: this.containerClass },
                React.createElement("div", { className: css(this.styles.message) }, this.props.vm.message),
                React.createElement("div", { className: css(this.styles.close) },
                    React.createElement(CloseButton, { size: this.innerHeight, color: Colors.GrayDarkest, onClick: this.onClickCloseButton }))));
        }
    });
    Object.defineProperty(Alert.prototype, "containerClass", {
        get: function () {
            return [this.props.className || '', css(this.styles.container)].join(' ');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Alert.prototype, "styles", {
        get: function () {
            return StyleSheet.create({
                '@keyframes container': this.keyframes,
                container: {
                    animationName: 'container',
                    animationDuration: '0.2s',
                    background: Colors.White,
                    padding: '1rem 1rem',
                    borderRadius: 2,
                    boxShadow: '0 4px 8px rgba(0,0,0,0.07), 0 2px 2px rgba(0,0,0,0.12)',
                    display: 'flex',
                    flexDirection: 'row',
                },
                message: {
                    color: this.isError ? Colors.Red : Colors.Black,
                    flexGrow: 1,
                    lineHeight: 1,
                    fontSize: this.innerHeight,
                },
                close: {
                    marginLeft: this.innerHeight,
                },
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Alert.prototype, "innerHeight", {
        get: function () {
            return 14;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Alert.prototype, "isError", {
        get: function () {
            return this.props.vm.isError;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Alert.prototype, "onClickCloseButton", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.props.vm.close();
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], Alert.prototype, "onClickCloseButton", null);
    return Alert;
}(React.Component));
export { Alert };
var AlertVM = /** @class */ (function () {
    function AlertVM(message, group, isError) {
        if (isError === void 0) { isError = false; }
        var _this = this;
        Object.defineProperty(this, "uuid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: uuidv4()
        });
        Object.defineProperty(this, "group", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "message", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lifeEnd", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "notifyKilled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        this.message = message;
        this.group = group;
        this.isError = isError;
        var durationSec = 5;
        this.lifeEnd = new Promise(function (resolve) {
            _this.notifyKilled = resolve;
        });
        setTimeout(function () {
            _this.close();
        }, durationSec * 1000);
    }
    Object.defineProperty(AlertVM.prototype, "close", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.notifyKilled && this.notifyKilled();
            this.notifyKilled = null;
        }
    });
    return AlertVM;
}());
export { AlertVM };
