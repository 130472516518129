var _a;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
export var MatchingAssessmentDescription = function (_a) {
    var title = _a.title, description = _a.description, classObject = _a.classObject;
    return (React.createElement("section", null,
        React.createElement("div", { className: css(styles.container, classObject) },
            React.createElement("span", { className: css(styles.title) }, title),
            React.createElement("span", { className: css(styles.description) }, description))));
};
var styles = StyleSheet.create({
    container: (_a = {
            position: 'relative',
            width: '100vw',
            whiteSpace: 'pre-wrap',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        _a[MediaBreakPointUp.MD] = {
            width: 800,
        },
        _a),
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        marginBottom: 14,
    },
    description: {
        fontSize: 14,
        fontWeight: 500,
        color: Colors.GrayDarkest,
        marginBottom: 16,
    },
});
