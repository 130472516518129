import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
import { LessonType, routes, generateLessonQueryParams } from '@lean-body/src/route';
import { HeartIcon } from '../atoms/icons/heart_icon';
import { FireIcon } from '../atoms/icons/fire_icon';
import { ShadowHeartIcon } from '../atoms/icons/shadow_heart_icon';
import { RaderIcon } from '../atoms/icons/rader_icon';
import { useNavigate } from 'react-router';
export var LessonComponent = function (_a) {
    var lesson = _a.lesson, addFavoriteLesson = _a.addFavoriteLesson, unFavoriteLesson = _a.unFavoriteLesson, onClick = _a.onClick, isVertical = _a.isVertical, isMin = _a.isMin, isActive = _a.isActive, disableHistoryPush = _a.disableHistoryPush, aboveName = _a.aboveName, isWhiteColor = _a.isWhiteColor, courseDayLessonID = _a.courseDayLessonID, hideFavoriteButton = _a.hideFavoriteButton, hideFavoriteCount = _a.hideFavoriteCount, hideDifficulty = _a.hideDifficulty, hideTags = _a.hideTags, hideDescription = _a.hideDescription, classObject = _a.classObject, headImgClassObject = _a.headImgClassObject;
    var _b = React.useState(lesson.isFavorite), isFavorited = _b[0], setIsFavorited = _b[1];
    var favoriteCount = lesson.lessonAggregate ? lesson.lessonAggregate.totalFavoriteCount.toLocaleString() : 0;
    var navigate = useNavigate();
    var clickFavorite = function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (isFavorited) {
            unFavoriteLesson(lesson.id);
            setIsFavorited(false);
        }
        else {
            addFavoriteLesson(lesson.id);
            setIsFavorited(true);
        }
    };
    var onClickHandler = function (e) {
        if (lesson.isDayOff) {
            e.stopPropagation();
            e.preventDefault();
        }
        else {
            if (onClick)
                onClick(lesson);
            if (!disableHistoryPush) {
                var url = routes.LESSON.compile({ type: LessonType.normal, slug: lesson.slug });
                var params = generateLessonQueryParams(courseDayLessonID);
                navigate("".concat(url).concat(params));
            }
        }
    };
    return (React.createElement("div", { className: css(styles.item, isMin && styles.itemMin, isVertical && styles.itemVertical, classObject), onClick: onClickHandler },
        React.createElement("div", { className: css(styles.headImg, isVertical && (isMin ? styles.headImgVerticalMin : styles.headImgVertical), headImgClassObject) },
            !lesson.isDayOff && !hideFavoriteButton && (React.createElement(ShadowHeartIcon, { color: Colors.White, filled: isFavorited, classObject: [styles.favoriteIconTopRight, isMin && styles.favoriteIconTopRightMin], onClick: clickFavorite })),
            !lesson.isDayOff && (React.createElement(React.Fragment, null, isActive ? (React.createElement(RaderIcon, { classObject: [styles.labelBottomRight, styles.rader, isMin && styles.labelBottomRightMin] })) : (React.createElement("div", { className: css(styles.labelBottomRight, styles.time, isMin && styles.labelBottomRightMin) }, "".concat(lesson.duration, ":00"))))),
            React.createElement("img", { className: css(styles.itemImg), src: lesson.thumbnailURL, loading: "lazy" })),
        React.createElement("div", { className: css(styles.itemDescription, isWhiteColor && styles.whiteColor) },
            aboveName,
            React.createElement("div", { className: css(styles.itemName, isVertical && styles.itemNameLarge, isMin && styles.itemNameMin) }, lesson.name),
            !lesson.isDayOff && (React.createElement("div", { className: css(styles.itemInfo, isVertical && styles.itemInfoLarge, isMin && styles.itemInfoMin) },
                !hideFavoriteCount && (React.createElement(React.Fragment, null,
                    React.createElement(HeartIcon, { classObject: [styles.favoriteIcon, isVertical && styles.favoriteIconLarge], color: isWhiteColor ? Colors.White : Colors.Black }),
                    favoriteCount)),
                !hideDifficulty && lesson.difficulty.name && "\u30FB".concat(lesson.difficulty.name),
                !isMin && (React.createElement(React.Fragment, null,
                    (!hideFavoriteCount || !hideDifficulty) && '・',
                    React.createElement(FireIcon, { classObject: [styles.fireicon, isVertical && styles.fireiconLarge], color: isWhiteColor ? Colors.White : Colors.Black }),
                    React.createElement("span", null, "".concat(lesson.minCal, "-").concat(lesson.maxCal, "kcal")))))),
            isMin && isVertical && (React.createElement("div", { className: css(styles.itemInfo) },
                React.createElement(FireIcon, { classObject: [styles.fireicon, isVertical && styles.fireiconLarge], color: isWhiteColor ? Colors.White : Colors.Black }),
                React.createElement("span", null, "".concat(lesson.minCal, "-").concat(lesson.maxCal, "kcal"),
                    lesson.pace.name && "\u30FB".concat(lesson.pace.name)))),
            !hideTags && !isMin && (React.createElement("div", { className: css(styles.itemTags) }, lesson.tags.map(function (v, i) { return (React.createElement("div", { key: i, className: css(styles.itemTag) },
                "#",
                v.name)); }))),
            !hideDescription && !isMin && isVertical && React.createElement("div", { className: css(styles.desc) }, lesson.description))));
};
var styles = StyleSheet.create({
    item: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px 0',
        overflow: 'hidden',
        color: Colors.Black,
        cursor: 'pointer',
    },
    itemMin: {
        gap: '5px 0',
    },
    itemVertical: {
        flexDirection: 'row',
        gap: '0 10px',
    },
    headImg: {
        position: 'relative',
        flex: 1,
    },
    headImgVertical: {
        minWidth: 270,
        maxWidth: 335,
    },
    headImgVerticalMin: {
        minWidth: 160,
        maxWidth: 184,
    },
    labelBottomRight: {
        fontSize: 12,
        fontWeight: 'bold',
        letterSpacing: '0.04em',
        lineHeight: 1,
        borderRadius: 4,
        padding: '3px 4px',
        color: Colors.White,
        position: 'absolute',
        bottom: 6,
        right: 6,
    },
    rader: {
        backgroundColor: 'none',
    },
    time: {
        backgroundColor: Colors.Black,
    },
    labelBottomRightMin: {
        fontSize: 10,
    },
    favoriteIconTopRight: {
        width: 37,
        height: 33,
        position: 'absolute',
        top: 4,
        right: 4,
    },
    favoriteIconTopRightMin: {
        width: 34,
        height: 30,
        top: 3,
        right: 3,
    },
    itemDescription: {
        flex: 1,
    },
    whiteColor: {
        color: Colors.White,
    },
    itemImg: {
        aspectRatio: '16/9',
        width: '100%',
        borderRadius: 4,
        display: 'block',
    },
    itemName: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: '0.04em',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '1',
        overflow: 'hidden',
    },
    itemNameMin: {
        fontSize: 12,
        '-webkit-line-clamp': '2',
    },
    itemNameLarge: {
        fontSize: 16,
    },
    itemInfo: {
        fontSize: 12,
        lineHeight: '160%',
        letterSpacing: '0.04em',
        display: 'flex',
        alignItems: 'center',
        margin: '2px 0',
    },
    itemInfoMin: {
        fontSize: 12,
    },
    itemInfoLarge: {
        fontSize: 14,
        fontWeight: 500,
    },
    favoriteIcon: {
        width: 12,
        height: 11,
        marginRight: 4,
    },
    favoriteIconLarge: {
        width: 15,
        height: 14,
    },
    fireicon: {
        width: 10,
        height: 14,
        margin: '0 4px 1px 0',
    },
    fireiconLarge: {
        width: 12,
        height: 16,
    },
    itemTags: {
        display: 'flex',
        flexWrap: 'wrap',
        height: 18,
        overflow: 'hidden',
    },
    itemTag: {
        marginRight: 6,
        fontSize: 12,
        color: Colors.GrayDarkest,
    },
    desc: {
        fontSize: 12,
        marginTop: 6,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
        overflow: 'hidden',
    },
});
