var AdlocateClient = /** @class */ (function () {
    function AdlocateClient(config) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.config = config;
    }
    // Adlocateの流入用タグ
    Object.defineProperty(AdlocateClient.prototype, "loadViewScript", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var script = document.createElement('script');
            script.innerHTML = "\n      var _CIDN = \"cid\";\n      var _PMTN = \"p\";\n      var _LPTU = \"./\";\n      var _param = location.search.substring(1).split(\"&\");\n      var _ulp = \"\", _ulcid = \"\";\n      for(var i = 0; _param[i]; i++){ var kv = _param[i].split(\"=\"); if(kv[0] == _PMTN && kv[1].length > 1){ _ulp = kv[1]; } if(kv[0] == _CIDN && kv[1].length > 1){ _ulcid = kv[1]; }}\n      if(_ulp && _ulcid){ _xhr = new XMLHttpRequest(); _xhr.open(\"GET\", _LPTU + \"lptag.php?p=\" + _ulp + \"&cid=\" + _ulcid); _xhr.send(); localStorage.setItem(\"CL_\" + _ulp, _ulcid); }\n    ";
            document.getElementsByTagName('head')[0].appendChild(script);
        }
    });
    // Adlocateのコンバージョン用タグ
    Object.defineProperty(AdlocateClient.prototype, "loadConversionScript", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userID, email) {
            var script = document.createElement('script');
            script.innerHTML = "\n      var _CIDN = \"cid\";\n      var _PMTV = \"610357fc2c5fd\";\n      var _ARGSV = \"".concat(userID, "\";\n      var _TKP1V = \"\";\n      var _TKP2V = \"").concat(email, "\";\n      var _TKP3V = \"\";\n      var _TKP4V = \"\";\n      var _TKP5V = \"\";\n      var _TKP6V = \"\";\n      var _TKP7V = \"\";\n      var _TKP8V = \"\";\n      var _TKP9V = \"\";\n      var _TKP10V = \"\";\n      var _TRKU = \"https://adlocate.jp/track.php?p=\" + _PMTV + \"&t=610357fc&args=\" + _ARGSV + \"&tkp1=\" + _TKP1V + \"&tkp2=\" + _TKP2V + \"&tkp3=\" + _TKP3V + \"&tkp4=\" + _TKP4V + \"&tkp5=\" + _TKP5V + \"&tkp6=\" + _TKP6V + \"&tkp7=\" + _TKP7V + \"&tkp8=\" + _TKP8V + \"&tkp9=\" + _TKP9V + \"&tkp10=\" + _TKP10V;\n      var _cks = document.cookie.split(\"; \");\n      var _cidv = \"\";\n      for(var i = 0; i < _cks.length; i++){ var _ckd = _cks[i].split( \"=\" ); if(_ckd[0] == \"CL_\" + _PMTV && _ckd[1].length > 1){ _cidv = _ckd[1]; break; }}\n      if(!_cidv && localStorage.getItem(\"CL_\" + _PMTV)){ _cidv = localStorage.getItem(\"CL_\" + _PMTV); }\n      if(_cidv){ _TRKU += \"&\" + _CIDN + \"=\" + _cidv; }\n      img = document.body.appendChild(document.createElement(\"img\"));\n      img.src = _TRKU;\n    ");
            document.getElementsByTagName('body')[0].appendChild(script);
        }
    });
    return AdlocateClient;
}());
export { AdlocateClient };
