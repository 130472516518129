import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var WorkoutLessonIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 18,
            height: 18,
            padding: '1.5px 5px',
        },
    });
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 8 15", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_29442_791)" },
            React.createElement("path", { id: "Vector", d: "M0.481656 15C0.404448 15 0.326579 14.9814 0.257289 14.9461C0.181401 14.9075 0.11607 14.8489 0.0685574 14.7777C0.0665776 14.7744 0.0645979 14.771 0.0626182 14.767C0.0210444 14.6905 -0.000732422 14.6046 -0.000732422 14.5187C-0.000732422 14.4329 0.0210444 14.347 0.0619583 14.2718L3.56868 8.15703C3.61223 8.08114 3.67493 8.0179 3.75015 7.97397C3.82538 7.93004 3.91051 7.90674 3.99762 7.90674C4.08538 7.9074 4.17051 7.9307 4.24508 7.9753C4.31965 8.01923 4.38168 8.08247 4.42391 8.15836L7.928 14.2731C7.97155 14.347 7.99531 14.4335 7.99531 14.5214C7.99531 14.6093 7.97155 14.6958 7.92668 14.7717C7.92536 14.7737 7.92404 14.7757 7.92272 14.7777C7.87586 14.8476 7.81251 14.9055 7.73861 14.9441C7.668 14.9807 7.58947 15.0007 7.51028 15.0007H0.497494C0.497494 15.0007 0.488915 15.0007 0.481656 15.0007V15ZM6.52307 13.9503L4.98945 11.2277L2.98137 11.2197L1.42994 13.9263L6.52307 13.9503ZM3.46376 10.4149H4.49518L3.99234 9.48635L3.46442 10.4149H3.46376Z", fill: "url(#paint0_linear_29442_791)" }),
            React.createElement("path", { id: "Vector_2", d: "M4.00231 7.11325C3.91454 7.11192 3.82942 7.08796 3.75485 7.04469C3.68028 7.00142 3.61759 6.93885 3.57403 6.86429L0.0646705 0.747537C0.0224367 0.669655 0 0.585116 0 0.499246C0 0.413375 0.0217768 0.328171 0.0626907 0.252951C0.107564 0.175069 0.170255 0.111165 0.245484 0.0672317C0.320713 0.0232981 0.4065 0 0.492947 0H7.50309C7.5902 0 7.67599 0.0232981 7.75122 0.0672317C7.82645 0.111165 7.88914 0.174403 7.93269 0.250288C7.97624 0.324177 8 0.410713 8 0.49858C8 0.586447 7.97624 0.672983 7.93137 0.748868L4.42795 6.86296C4.38637 6.93752 4.32434 7.00142 4.24977 7.04535C4.1752 7.08929 4.08942 7.11325 4.00297 7.11325H4.00231ZM3.98647 5.49969L6.55019 1.05374L1.50458 1.04575C1.4993 1.04575 1.49468 1.04575 1.49006 1.04509H1.48412C1.48412 1.04509 1.47818 1.04509 1.47554 1.04642C1.47224 1.04775 1.46894 1.05041 1.4663 1.05374C1.46366 1.05707 1.46234 1.0604 1.46168 1.06439C1.46168 1.06639 1.46168 1.06905 1.46168 1.07171L3.98647 5.49969Z", fill: "#9CCDB6" })),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "paint0_linear_29442_791", x1: "-7.25192e-05", y1: "11.454", x2: "7.99597", y2: "11.454", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#CCE2A7" }),
                React.createElement("stop", { offset: "0.5", stopColor: "#A0D4BB" }),
                React.createElement("stop", { offset: "1", stopColor: "#67C4CB" })),
            React.createElement("clipPath", { id: "clip0_29442_791" },
                React.createElement("rect", { width: "8", height: "15", fill: "white" })))));
};
