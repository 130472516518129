var GAClient = /** @class */ (function () {
    function GAClient(config) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.config = config;
        if (!this.config.isProdOrStg)
            return;
        (function (i, s, tagName, src, gaObj, a, m) {
            i['GoogleAnalyticsObject'] = gaObj;
            (i[gaObj] =
                i[gaObj] ||
                    function () {
                        ;
                        (i[gaObj].q = i[gaObj].q || []).push(arguments);
                    }),
                (i[gaObj].l = 1 * new Date().getTime());
            (a = s.createElement(tagName)),
                (m = s.getElementsByTagName(tagName)[0]);
            a.async = !0;
            a.src = src;
            m.parentNode.insertBefore(a, m);
        })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
        ga('create', config.gaGeneralId, 'auto', { allowLinker: true });
        ga('require', 'linker');
        ga('linker:autoLink', [config.lpDomain]);
    }
    Object.defineProperty(GAClient.prototype, "trackView", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            window.ga &&
                ga('send', {
                    hitType: 'event',
                    eventLabel: window.location.pathname + window.location.search,
                });
            window.ga && ga('send', 'pageview');
        }
    });
    Object.defineProperty(GAClient.prototype, "trackConversion", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            window.ga && ga('send', 'event', 'acquisition', 'click', 'cc.register', 1);
        }
    });
    Object.defineProperty(GAClient.prototype, "trackSignup", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            window.ga && ga('send', 'event', 'acquisition', 'click', 'signup', 1);
        }
    });
    // セッションで初再生 & /recommendを経由してたら発火
    Object.defineProperty(GAClient.prototype, "trackFirstMoviePlayEvent", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            window.ga && ga('send', 'event', 'contents', 'lesson_play', 'first_play_from_recommend', 1);
        }
    });
    Object.defineProperty(GAClient.prototype, "trackFacebookSignupClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            window.ga && ga('send', 'event', 'link', 'click', 'facebook_signup.click', 1);
        }
    });
    Object.defineProperty(GAClient.prototype, "trackAppleSignupClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            window.ga && ga('send', 'event', 'link', 'click', 'apple_signup.click', 1);
        }
    });
    Object.defineProperty(GAClient.prototype, "trackEmailSignupClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            window.ga && ga('send', 'event', 'link', 'click', 'signup.click', 1);
        }
    });
    Object.defineProperty(GAClient.prototype, "trackAmazonPaySignupClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            window.ga && ga('send', 'event', 'link', 'click', 'amazon_pay_signup.click', 1);
        }
    });
    Object.defineProperty(GAClient.prototype, "trackConversionGTM", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            window.dataLayer && window.dataLayer.push({ event: 'cc_register' });
        }
    });
    return GAClient;
}());
export { GAClient };
