import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
import { routes } from '@lean-body/src/route';
import { HeartIcon } from '../atoms/icons/heart_icon';
import { ShadowHeartIcon } from '../atoms/icons/shadow_heart_icon';
import { Link } from 'react-router-dom';
import { FireIcon } from '../atoms/icons/fire_icon';
import { PlaylistLandscapeImg } from '../atoms/playlist_landscape_img';
export var PlaylistComponentLandscapeImg = function (_a) {
    var _b;
    var playlist = _a.playlist, addFavoriteProgram = _a.addFavoriteProgram, unFavoriteProgram = _a.unFavoriteProgram, onClick = _a.onClick, isVertical = _a.isVertical;
    var _c = React.useState(playlist.isFavorited), isFavorited = _c[0], setIsFavorited = _c[1];
    var favoriteCount = playlist.playlistAggregate.totalFavoriteCount.toLocaleString();
    var clickFavorite = function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (isFavorited) {
            unFavoriteProgram(playlist.id);
            setIsFavorited(false);
        }
        else {
            addFavoriteProgram(playlist.id);
            setIsFavorited(true);
        }
    };
    return (React.createElement(Link, { className: css(styles.item, isVertical && styles.itemVertical), to: routes.PLAYLIST.compile({ id: playlist.id.toString() }), onClick: function () { return onClick && onClick(playlist); } },
        React.createElement("div", { className: css(styles.headImg, isVertical && styles.headImgVertical) },
            React.createElement(ShadowHeartIcon, { color: Colors.White, filled: isFavorited, classObject: styles.favoriteIconTopRight, onClick: clickFavorite }),
            React.createElement(PlaylistLandscapeImg, { thumbnailURL: playlist.thumbnailURL }),
            React.createElement("div", { className: css(styles.labelBottomRight) }, "".concat(playlist.lessonCount, "LESSON"))),
        React.createElement("div", { className: css(styles.itemDescription) },
            React.createElement("div", { className: css(styles.itemName, isVertical && styles.itemNameLarge) }, playlist.name),
            React.createElement("div", { className: css(styles.itemInfo, isVertical && styles.itemInfoLarge) },
                React.createElement(HeartIcon, { classObject: [styles.favoriteIcon, isVertical && styles.favoriteIconLarge], color: Colors.Black }),
                favoriteCount,
                "\u30FB",
                playlist.difficulty.name,
                "\u30FB",
                React.createElement(FireIcon, { classObject: [styles.fireicon, isVertical && styles.fireiconLarge], color: Colors.Black }),
                React.createElement("span", null, "".concat(playlist.totalMinCal, "-").concat(playlist.totalMaxCal, "kcal"))),
            !!((_b = playlist.tags) === null || _b === void 0 ? void 0 : _b.length) && (React.createElement("div", { className: css(styles.itemTags) }, playlist.tags.map(function (v, i) { return (React.createElement("div", { key: i, className: css(styles.itemTag) },
                "#",
                v.name)); }))),
            isVertical && React.createElement("div", { className: css(styles.desc) }, playlist.description))));
};
var styles = StyleSheet.create({
    item: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px 0',
        overflow: 'hidden',
        color: Colors.Black,
        cursor: 'pointer',
        width: '100%',
    },
    itemVertical: {
        flexDirection: 'row',
        gap: '0 10px',
    },
    headImg: {
        position: 'relative',
        flex: 1,
        aspectRatio: '16/9',
        backgroundColor: Colors.StrictBlack,
        borderRadius: 4,
    },
    headImgVertical: {
        minWidth: 300,
        maxWidth: 335,
    },
    labelTopLeft: {
        fontSize: 12,
        fontWeight: 'bold',
        letterSpacing: '0.04em',
        lineHeight: 1,
        padding: '7px 12px',
        borderRadius: 100,
        backgroundColor: Colors.White,
        position: 'absolute',
        top: 10,
        left: 10,
    },
    labelBottomRight: {
        fontSize: 12,
        fontWeight: 'bold',
        letterSpacing: '0.04em',
        lineHeight: 1,
        borderRadius: 4,
        padding: '3px 4px',
        color: Colors.White,
        backgroundColor: Colors.Black,
        position: 'absolute',
        bottom: 6,
        right: 6,
    },
    favoriteIconTopRight: {
        width: 37,
        height: 33,
        position: 'absolute',
        top: 4,
        right: 4,
    },
    itemDescription: {
        flex: 1,
    },
    itemName: {
        fontSize: 14,
        fontWeight: 'bold',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '1',
        overflow: 'hidden',
    },
    itemNameLarge: {
        fontSize: 16,
    },
    itemInfo: {
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
        margin: '2px 0',
    },
    itemInfoLarge: {
        fontSize: 16,
    },
    favoriteIcon: {
        width: 12,
        height: 11,
        marginRight: 4,
    },
    favoriteIconLarge: {
        width: 15,
        height: 14,
    },
    fireicon: {
        width: 10,
        height: 14,
        margin: '0 4px 1px 0',
    },
    fireiconLarge: {
        width: 12,
        height: 16,
    },
    itemTags: {
        display: 'flex',
        flexWrap: 'wrap',
        height: 18,
        overflow: 'hidden',
    },
    itemTag: {
        marginRight: 6,
        fontSize: 12,
        color: Colors.GrayDarkest,
    },
    desc: {
        fontSize: 12,
        marginTop: 6,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
        overflow: 'hidden',
    },
});
