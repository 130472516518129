import { LocalStorage } from '@lean-body/src/infra';
import { useSmMediaQuery } from '@lean-body/src/util/media_query_hooks';
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { useLocation } from 'react-router';
import { liveEventId } from '../../domain';
import { routes } from '../../route';
import { ZIndex } from '../constants';
import { FixedFooterBanner } from '../molecules/fixed_footer_banner';
import { Colors } from '../styles';
import { StoreBanner } from '@lean-body/components/organisms';
import { isNativeWeb } from '@lean-body/src/util';
import { FooterItem } from './footer_item';
export var FIXED_FOOTER_HEIGHT = 60;
export var FixedFooter = function (props) {
    var styles = StyleSheet.create({
        container: {
            backgroundColor: Colors.White,
            zIndex: ZIndex.Footer,
            position: 'fixed',
            bottom: 0,
            width: '100%',
            height: FIXED_FOOTER_HEIGHT,
            display: 'flex',
            borderTop: "1px solid ".concat(Colors.BorderLightGray),
        },
        banner: {
            zIndex: ZIndex.Footer,
            position: 'fixed',
            bottom: FIXED_FOOTER_HEIGHT,
            width: '100%',
        },
        homeIconNotification: {
            right: -4,
        },
    });
    var footer = props.footer;
    var location = useLocation();
    var isSM = useSmMediaQuery();
    var localStorage = new LocalStorage();
    var isLiveLessonPage = routes.LIVE_GROUP.matchPath(location.pathname);
    var isActivityPage = routes.ACTIVITY.matchPath(location.pathname);
    var hasLiveLessonNotification = !isLiveLessonPage && !!(footer === null || footer === void 0 ? void 0 : footer.hasLiveLessonNotification);
    var hasActivityNotification = !isActivityPage && !!(footer === null || footer === void 0 ? void 0 : footer.hasActivityNotification);
    if (isLiveLessonPage && (footer === null || footer === void 0 ? void 0 : footer.hasLiveLessonNotification)) {
        localStorage.saveLiveScheduleTabNoticed();
    }
    else if (isActivityPage && (footer === null || footer === void 0 ? void 0 : footer.hasActivityNotification)) {
        localStorage.saveActivityTabNoticed();
    }
    return (React.createElement(React.Fragment, null,
        isNativeWeb() && (React.createElement(StoreBanner, { onClickHideButton: props.onClickAppstoreBannerHideButton, classObject: styles.banner })),
        React.createElement("div", { className: css(styles.container), id: "footer" },
            routes.TOP.matchPath(location.pathname) && props.fixedBannerDisplayable && isSM && (React.createElement(FixedFooterBanner, { imgSrc: "".concat(props.config.cdnBaseURL, "/banner/lb-hw_banner-pr-home.jpg"), link: routes.LIVE_LESSON.compile({ id: "".concat(liveEventId()) }), hideFixedBanner: props.hideFixedBanner })),
            React.createElement(FooterItem, { linkTo: routes.TOP.path, iconSrc: '/assets/images/icons/home.svg', iconSrcActive: '/assets/images/icons/home_light_blue.svg', label: 'HOME', isActive: routes.TOP.matchPath(location.pathname), notificationClassObject: styles.homeIconNotification }),
            React.createElement(FooterItem, { linkTo: routes.SEARCH.path, iconSrc: '/assets/images/icons/search.svg', iconSrcActive: '/assets/images/icons/search_light_blue.svg', label: '見つける', isActive: routes.SEARCH_GROUP.matchPath(location.pathname) }),
            React.createElement(FooterItem, { linkTo: routes.LIVE_SCHEDULE.path, iconSrc: '/assets/images/icons/live_stream.svg', iconSrcActive: '/assets/images/icons/live_stream_light_blue.svg', label: 'LIVE', isActive: routes.LIVE_GROUP.matchPath(location.pathname), hasNotification: hasLiveLessonNotification }),
            React.createElement(FooterItem, { linkTo: routes.MY_LESSON.path, iconSrc: '/assets/images/icons/my_lesson.svg', iconSrcActive: '/assets/images/icons/my_lesson_light_blue.svg', label: 'MYレッスン', isActive: routes.MY_LESSON.matchPath(location.pathname) }),
            React.createElement(FooterItem, { linkTo: routes.ACTIVITY.path, iconSrc: '/assets/images/icons/activity.svg', iconSrcActive: '/assets/images/icons/activity_light_blue.svg', label: 'アクティビティ', isActive: routes.ACTIVITY.matchPath(location.pathname), isLongLabel: true, hasNotification: hasActivityNotification }))));
};
