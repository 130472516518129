// 動画再生、hlsURL取得、再生時間の計算などを担う。
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { range } from 'lodash-es';
import validator from 'validator';
import { observable, makeObservable } from 'mobx';
import { isMobileSafari, isiOSApp } from '@lean-body/src/util';
export var VideoType;
(function (VideoType) {
    VideoType["ProgramIntroduce"] = "program_introduce";
    VideoType["Lesson"] = "lesson";
})(VideoType || (VideoType = {}));
/**
 * 動画再生状態のステータス
 */
export var VideoState;
(function (VideoState) {
    VideoState[VideoState["Loading"] = 0] = "Loading";
    VideoState[VideoState["Ready"] = 1] = "Ready";
    VideoState[VideoState["Playing"] = 2] = "Playing";
    VideoState[VideoState["Quit"] = 3] = "Quit";
    VideoState[VideoState["Result"] = 4] = "Result";
    VideoState[VideoState["Finished"] = 5] = "Finished";
})(VideoState || (VideoState = {}));
/**
 * TODO: ほぼ使われていないためVideoStateと統合する
 * 動画プレイヤーのステータス
 */
export var VideoPlayerStatus;
(function (VideoPlayerStatus) {
    VideoPlayerStatus[VideoPlayerStatus["Waiting"] = 0] = "Waiting";
    VideoPlayerStatus[VideoPlayerStatus["Ready"] = 1] = "Ready";
    VideoPlayerStatus[VideoPlayerStatus["Playing"] = 2] = "Playing";
    VideoPlayerStatus[VideoPlayerStatus["Paused"] = 3] = "Paused";
    VideoPlayerStatus[VideoPlayerStatus["Seeking"] = 4] = "Seeking";
    VideoPlayerStatus[VideoPlayerStatus["Finished"] = 5] = "Finished";
})(VideoPlayerStatus || (VideoPlayerStatus = {}));
var VideoSrc = /** @class */ (function () {
    function VideoSrc(rawPath, config) {
        Object.defineProperty(this, "rawPath", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.config = config;
        this.rawPath = rawPath;
    }
    Object.defineProperty(VideoSrc.prototype, "isValid", {
        get: function () {
            if (validator.isEmpty(this.rawPath)) {
                return false;
            }
            return true;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VideoSrc.prototype, "id", {
        get: function () {
            return this.rawPath;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VideoSrc.prototype, "hlsURL", {
        get: function () {
            return this.config.cdnBaseForMovie + this.hlsPath;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VideoSrc.prototype, "hlsPath", {
        get: function () {
            return this.rawPath.replace('.mp4', '') + '/master.m3u8';
        },
        enumerable: false,
        configurable: true
    });
    return VideoSrc;
}());
export { VideoSrc };
/*=============================================
= Video Events =
=============================================*/
var VideoEvent = /** @class */ (function () {
    function VideoEvent(time) {
        Object.defineProperty(this, "time", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.time = time;
    }
    return VideoEvent;
}());
export { VideoEvent };
var VideoSeekedEvent = /** @class */ (function (_super) {
    __extends(VideoSeekedEvent, _super);
    function VideoSeekedEvent(range) {
        var _this = _super.call(this, range.to) || this;
        Object.defineProperty(_this, "range", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        _this.range = range;
        return _this;
    }
    return VideoSeekedEvent;
}(VideoEvent));
export { VideoSeekedEvent };
var VideoPlayEvent = /** @class */ (function (_super) {
    __extends(VideoPlayEvent, _super);
    function VideoPlayEvent(range) {
        var _this = _super.call(this, range.to) || this;
        Object.defineProperty(_this, "range", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        _this.range = range;
        return _this;
    }
    return VideoPlayEvent;
}(VideoEvent));
export { VideoPlayEvent };
/*=============================================
= Domain Models =
=============================================*/
/**
 * HTMLVideoElementのイベントをハンドルし、
 * VideoVM、VideoPlayerContext、VideoPlayAnalyzerの操作を行う
 */
var VideoElementEventHandler = /** @class */ (function () {
    function VideoElementEventHandler(videoVM, playerCtx, analyzer) {
        Object.defineProperty(this, "videoVM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playerCtx", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "analyzer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.videoVM = videoVM;
        this.playerCtx = playerCtx;
        this.analyzer = analyzer;
    }
    Object.defineProperty(VideoElementEventHandler.prototype, "setAnalyzer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (analyzer) {
            this.analyzer = analyzer;
        }
    });
    Object.defineProperty(VideoElementEventHandler.prototype, "initialize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el) {
            var _this = this;
            el.addEventListener('ended', function () {
                _this.videoVM.onLessonComplete();
                _this.playerCtx.setFinished();
                _this.analyzer.onCompleted();
            });
            el.addEventListener('pause', function () {
                // シーク時、終了時もpauseイベントが飛んでくるのでそれらを除外
                if (!el.seeking && el.currentTime < el.duration) {
                    _this.videoVM.onLessonSuspend();
                    // HACK: iOS Safari は FullscreenAPI が非対応のため、フルスクリーンの解除をした場合に発火される動画停止のイベントをハンドリングしてフルスクリーン解除時の後処理をします
                    if (isMobileSafari() || isiOSApp()) {
                        _this.videoVM.onFullscreenCancellation();
                    }
                    _this.playerCtx.setPaused();
                    _this.analyzer.onPaused();
                }
            });
            el.addEventListener('seeking', function () {
                _this.playerCtx.setSeeking();
            });
            el.addEventListener('seeked', function () {
                _this.videoVM.onLessonSeeked();
                _this.playerCtx.setSeeking();
                _this.playerCtx.setCurrentTime(el.currentTime);
                var range = _this.playerCtx.getSeekRange();
                _this.playerCtx.setPlaying();
                if (range) {
                    // 再生開始前(VideoPlayerStatusがreadyやwaitingの時に)seeking、seekedが呼ばれることがあり、
                    // このときはrange = null
                    var event_1 = new VideoSeekedEvent(range);
                    _this.analyzer.onSeeked(event_1);
                }
            });
            el.addEventListener('playing', function () {
                _this.videoVM.onClickPlayButton().then(function () {
                    _this.playerCtx.setPlaying();
                    _this.playerCtx.setCurrentTime(el.currentTime);
                });
            });
            el.addEventListener('timeupdate', function () {
                var newCurrentTime = el.currentTime;
                var lastPlayedTime = _this.playerCtx.currentTime;
                var lapsed = newCurrentTime - _this.playerCtx.currentTime;
                switch (_this.playerCtx.status) {
                    case VideoPlayerStatus.Playing:
                        // イベントフィルタ
                        if (lapsed < 0 || lapsed > 1) {
                            // シーク時、一時停止時に seeking, pause, seeked イベントより前にtimeupdate イベントが発行される場合がある
                            // つまり status = playing の状態で、シークに伴うtimeupdateが呼ばれる場合があるで、
                            // 再生中のときのみイベントハンドルするようフィルタしたい。
                            // 再生中にtimeupdateイベントは250ms以内に発行されるため、
                            // 前回のcurrentTimeとの差分が0未満または(余裕を持って)1sec以上だった場合は、
                            // シークに伴うtimeupdateとみなしてハンドルしない
                            return;
                        }
                        // timeupdate イベントがseekedより前に発行された場合、
                        // playerCtx.setCurrentTime() をイベントフィルタ前に呼ぶと、
                        // seekedイベントのハンドルでシーク範囲を算出できなくなるためここで呼ぶ。
                        _this.playerCtx.setCurrentTime(el.currentTime);
                        _this.analyzer.onPlaying(new VideoPlayEvent({
                            from: lastPlayedTime,
                            to: _this.playerCtx.currentTime,
                        }));
                        break;
                }
            });
        }
    });
    return VideoElementEventHandler;
}());
export { VideoElementEventHandler };
/**
 * 動画再生状態のコンテキスト
 */
var VideoPlayerContext = /** @class */ (function () {
    function VideoPlayerContext() {
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: VideoPlayerStatus.Waiting
        });
        Object.defineProperty(this, "currentTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "seekFromTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        makeObservable(this, {
            status: observable,
        });
    }
    Object.defineProperty(VideoPlayerContext.prototype, "setStatus", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (status) {
            this.status = status;
        }
    });
    /**
     * 再生可能状態へ遷移
     */
    Object.defineProperty(VideoPlayerContext.prototype, "setReady", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.setStatus(VideoPlayerStatus.Ready);
        }
    });
    /**
     * シーク中状態へ遷移
     * 再生中、停止中からのみシーク中へ遷移可能
     * 状態遷移時に `currentTime` をシーク元時刻 seekFromTime として保持
     */
    Object.defineProperty(VideoPlayerContext.prototype, "setSeeking", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            switch (this.status) {
                case VideoPlayerStatus.Playing:
                case VideoPlayerStatus.Paused:
                    this.setStatus(VideoPlayerStatus.Seeking);
                    this.seekFromTime = this.currentTime;
                    break;
                default:
                    break;
            }
        }
    });
    /**
     * シーク中のときシーク範囲を取得する
     */
    Object.defineProperty(VideoPlayerContext.prototype, "getSeekRange", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            switch (this.status) {
                case VideoPlayerStatus.Seeking:
                    return { from: this.seekFromTime, to: this.currentTime };
                default:
                    return null;
            }
        }
    });
    /**
     * ポーズ状態へ遷移
     * 再生中、ポーズ中、シーク中からのみ遷移可能
     */
    Object.defineProperty(VideoPlayerContext.prototype, "setPaused", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            switch (this.status) {
                case VideoPlayerStatus.Playing:
                case VideoPlayerStatus.Paused:
                case VideoPlayerStatus.Seeking:
                    this.setStatus(VideoPlayerStatus.Paused);
                    break;
                default:
                    break;
            }
        }
    });
    /**
     * 再生状態へ遷移
     */
    Object.defineProperty(VideoPlayerContext.prototype, "setPlaying", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.setStatus(VideoPlayerStatus.Playing);
        }
    });
    /**
     * 終了状態へ遷移
     */
    Object.defineProperty(VideoPlayerContext.prototype, "setFinished", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.setStatus(VideoPlayerStatus.Finished);
        }
    });
    Object.defineProperty(VideoPlayerContext.prototype, "setCurrentTime", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (time) {
            this.currentTime = time;
        }
    });
    Object.defineProperty(VideoPlayerContext.prototype, "reset", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.setStatus(VideoPlayerStatus.Waiting);
            this.currentTime = 0;
            this.seekFromTime = 0;
        }
    });
    return VideoPlayerContext;
}());
export { VideoPlayerContext };
/**
 * VideoEventをハンドルして動画再生統計情報を計算
 */
var VideoPlayAnalyzer = /** @class */ (function () {
    function VideoPlayAnalyzer() {
        Object.defineProperty(this, "completed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "pauseCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "playDuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "track", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new VideoPlayTrack()
        });
        Object.defineProperty(this, "seekedEvents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
    }
    Object.defineProperty(VideoPlayAnalyzer.prototype, "onPaused", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.pauseCount++;
        }
    });
    Object.defineProperty(VideoPlayAnalyzer.prototype, "onSeeked", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            this.seekedEvents.push(event);
        }
    });
    Object.defineProperty(VideoPlayAnalyzer.prototype, "onPlaying", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            var _this = this;
            var from = event.range.from;
            var to = event.range.to;
            this.playDuration += to - from;
            var fromFloor = Math.floor(from);
            var toFloor = Math.floor(to);
            range(fromFloor, toFloor + 1).forEach(function () {
                _this.track.setPlayedAt(from);
            });
        }
    });
    Object.defineProperty(VideoPlayAnalyzer.prototype, "onCompleted", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.completed = true;
        }
    });
    Object.defineProperty(VideoPlayAnalyzer.prototype, "setDuration", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (duration) {
            this.track.setDuration(duration);
        }
    });
    Object.defineProperty(VideoPlayAnalyzer.prototype, "seekRanges", {
        get: function () {
            return this.seekedEvents.map(function (e) {
                return e.range;
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VideoPlayAnalyzer.prototype, "totalSeekDuration", {
        get: function () {
            return this.seekRanges.reduce(function (total, r) {
                return total + Math.abs(r.to - r.from);
            }, 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VideoPlayAnalyzer.prototype, "stats", {
        get: function () {
            return {
                duration: this.track.duration || 0,
                pauseCount: this.pauseCount,
                playDuration: this.playDuration,
                dedupedPlayDuration: this.track.playedDuration,
                playDurationRatio: this.track.playedRatio,
                totalSeekDuration: this.totalSeekDuration,
            };
        },
        enumerable: false,
        configurable: true
    });
    return VideoPlayAnalyzer;
}());
export { VideoPlayAnalyzer };
/**
 * 動画のどの部分を再生したかの軌跡を計算する
 * 動画の時間軸を1secごとのVideoTimeSegmentに分割して、
 * それぞれのセグメントの再生有無を保持
 */
var VideoPlayTrack = /** @class */ (function () {
    function VideoPlayTrack() {
        Object.defineProperty(this, "duration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        }); // 動画全体の長さ
        Object.defineProperty(this, "segments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        }); // セグメント配列
    }
    Object.defineProperty(VideoPlayTrack.prototype, "getSegmentAt", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (time) {
            var _time = Math.floor(time);
            var segment = this.segments[_time];
            if (!segment) {
                segment = new VideoTimeSegment(_time);
                this.segments[_time] = segment;
            }
            return segment;
        }
    });
    Object.defineProperty(VideoPlayTrack.prototype, "setPlayedAt", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (time) {
            var segment = this.getSegmentAt(time);
            if (segment) {
                segment.played();
            }
        }
    });
    /**
     * 動画全体の長さをセット
     */
    Object.defineProperty(VideoPlayTrack.prototype, "setDuration", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (length) {
            this.duration = length;
        }
    });
    Object.defineProperty(VideoPlayTrack.prototype, "playedDuration", {
        /**
         * 重複排除再生時間を取得
         * 再生したセグメント × 1sec = 再生時間として計算
         */
        get: function () {
            var played = this.segments
                .filter(function (s) { return !!s; })
                .reduce(function (total, s) {
                return s.hasPlayed ? total + 1 : total;
            }, 0);
            if (this.duration && played > this.duration) {
                return this.duration;
            }
            return played;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VideoPlayTrack.prototype, "playedRatio", {
        /**
         * 全体のうち再生した時間の割合を返す
         */
        get: function () {
            if (!this.duration || this.duration === 0) {
                return 0;
            }
            return this.playedDuration / this.duration;
        },
        enumerable: false,
        configurable: true
    });
    return VideoPlayTrack;
}());
export { VideoPlayTrack };
var VideoTimeSegment = /** @class */ (function () {
    function VideoTimeSegment(time) {
        Object.defineProperty(this, "time", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_hasPlayed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        this.time = time;
    }
    Object.defineProperty(VideoTimeSegment.prototype, "hasPlayed", {
        get: function () {
            return this._hasPlayed;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VideoTimeSegment.prototype, "played", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this._hasPlayed = true;
        }
    });
    return VideoTimeSegment;
}());
export { VideoTimeSegment };
