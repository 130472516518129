var weekdays = {
    月: 'monday',
    火: 'tuesday',
    水: 'wednesday',
    木: 'thursday',
    金: 'friday',
    土: 'saturday',
    日: 'sunday',
};
var CourseSchedule = /** @class */ (function () {
    function CourseSchedule(data) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dayOfWeek", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isSelected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "time", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.userId = data.userId;
        this.dayOfWeek = data.dayOfWeek;
        this.time = data.time;
        this.isSelected = data.isSelected;
    }
    Object.defineProperty(CourseSchedule.prototype, "changeTime", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (time) {
            this.time = time;
        }
    });
    Object.defineProperty(CourseSchedule.prototype, "dayOfWeekToKanji", {
        get: function () {
            var _this = this;
            return Object.keys(weekdays).find(function (key) { return weekdays[key] === _this.dayOfWeek; });
        },
        enumerable: false,
        configurable: true
    });
    return CourseSchedule;
}());
export { CourseSchedule };
