var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { FormDialog, FormDialogType } from '@lean-body/components/organisms/form_dialog';
var DESCRIPTION_PLACEHOLDER = '例）いつも朝にするレッスンまとめ';
export var NewPlaylistForms = function (_a) {
    var title = _a.title, setTitle = _a.setTitle, description = _a.description, setDescription = _a.setDescription, checkTitle = _a.checkTitle, checkDescription = _a.checkDescription, maxTitle = _a.maxTitle, maxDescription = _a.maxDescription, maxTitleLine = _a.maxTitleLine;
    var _b = React.useState(false), showTitleDialog = _b[0], setShowTitleDialog = _b[1];
    var _c = React.useState(false), showDescDialog = _c[0], setShowDescDialog = _c[1];
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.sectionHead) },
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u540D"),
            React.createElement("div", { className: css(styles.edit), onClick: function () { return setShowTitleDialog(true); } }, "\u7DE8\u96C6")),
        React.createElement("div", { className: css(styles.textarea), onClick: function () { return setShowTitleDialog(true); } }, title.replace(/\n/g, '')),
        React.createElement("div", { className: css(styles.sectionHead) },
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u8AAC\u660E\u6587(\u4EFB\u610F)"),
            React.createElement("div", { className: css(styles.edit), onClick: function () { return setShowDescDialog(true); } }, "\u7DE8\u96C6")),
        React.createElement("div", { className: css(styles.textarea), onClick: function () { return setShowDescDialog(true); } }, description || React.createElement("span", { className: css(styles.placeholder) }, "".concat(DESCRIPTION_PLACEHOLDER, "\n "))),
        showTitleDialog && (React.createElement(FormDialog, { type: FormDialogType.textarea, value: title, submit: setTitle, close: function () { return setShowTitleDialog(false); }, checkWarning: function (text) { return checkTitle(text); }, title: "\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u540D", description: "\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u540D\u3092\u5165\u529B\u3057\u3066\u4E0B\u3055\u3044\u3002\n".concat(maxTitleLine, "\u884C\u4EE5\u5185\u3067\u3001\u6700\u5927").concat(maxTitle, "\u6587\u5B57\u307E\u3067\u5165\u529B\u53EF\u80FD\u3067\u3059\u3002"), inputClassObject: styles.textareaInputSquare })),
        showDescDialog && (React.createElement(FormDialog, { type: FormDialogType.textarea, value: description, submit: setDescription, close: function () { return setShowDescDialog(false); }, checkWarning: function (text) { return checkDescription(text); }, title: "\u8AAC\u660E\u6587(\u4EFB\u610F)", placeholder: DESCRIPTION_PLACEHOLDER, description: "\u3053\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u306E\u8AAC\u660E\u6587\u3092\u8A2D\u5B9A\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002\u6700\u5927\u6587\u5B57\u6570\u306F".concat(maxDescription, "\u6587\u5B57\u3067\u3059\u3002") }))));
};
var styles = StyleSheet.create({
    container: (_a = {
            position: 'relative'
        },
        _a[MediaBreakPointUp.MD] = {
            flex: 1,
            margin: '0 32px 0 32px',
        },
        _a),
    sectionHead: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItem: 'center',
        marginBottom: 6,
    },
    sectionTitle: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    textarea: {
        fontSize: 14,
        lineHeight: 1.5,
        marginBottom: 32,
        padding: 16,
        backgroundColor: Colors.BackgroundLightGray,
        borderRadius: 4,
        whiteSpace: 'pre-wrap',
        cursor: 'pointer',
    },
    textareaInputSquare: (_b = {
            height: 104,
            width: 174,
            display: 'block',
            margin: '0 auto',
            padding: '16px 32px'
        },
        _b[MediaBreakPointUp.XL] = {
            height: 104,
            width: '100%',
        },
        _b),
    edit: {
        color: Colors.Primary,
        fontSize: 13,
        lineHeight: 1,
        alignSelf: 'center',
        padding: '6px 12px',
        borderRadius: 4,
        backgroundColor: Colors.BlueLighterB,
        cursor: 'pointer',
    },
    placeholder: {
        color: Colors.GrayDarkest,
    },
});
