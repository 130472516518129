import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles/variables';
import { PlaylistLabelInfo } from '@lean-body/components/atoms/playlist_label_info';
import { ImageBlurSquare } from '@lean-body/components/atoms/image_blur_square';
import { FavoriteWithCount } from '@lean-body/components/molecules';
import { TwitterWithText } from '../molecules/twitter_with_text';
export var PlaylistSummary = function (_a) {
    var playlist = _a.playlist, totalFavoriteCount = _a.totalFavoriteCount, creator = _a.creator, className = _a.className, isFavorited = _a.isFavorited, numOfLessons = _a.numOfLessons, programTotalMinCal = _a.programTotalMinCal, programTotalMaxCal = _a.programTotalMaxCal, updatedDiff = _a.updatedDiff, isOriginalPlaylist = _a.isOriginalPlaylist, onClickFavorite = _a.onClickFavorite, twitterService = _a.twitterService, onClickTwitterShareIfAuthed = _a.onClickTwitterShareIfAuthed;
    var wrapperClass = [className || '', css(styles.wrapper)].join(' ');
    var playlistName = playlist.name;
    var playlistDescription = playlist.description;
    var thumbnailURL = playlist.thumbnailURL;
    var totalDuration = playlist.totalDuration;
    return (React.createElement("div", { className: wrapperClass },
        React.createElement(ImageBlurSquare, { src: thumbnailURL }),
        React.createElement("div", { className: css(styles.container) },
            React.createElement("div", { className: css(styles.imageBox) },
                React.createElement("img", { className: css(styles.image), src: thumbnailURL, alt: "" })),
            React.createElement("div", { className: css(styles.playlistInfo) },
                React.createElement("div", null,
                    React.createElement("div", { className: css(styles.playlistTitle) }, playlistName),
                    React.createElement("div", { className: css(styles.playlistInfoBox) },
                        React.createElement("div", null,
                            React.createElement("div", { className: css(styles.playlistCreator) },
                                React.createElement("span", { className: css(styles.creatorImgContainer) },
                                    React.createElement("img", { src: creator.iconSrc, height: "22" })),
                                React.createElement("span", { className: css(styles.creatorName) }, creator.name)),
                            React.createElement(PlaylistLabelInfo, { numOfLessons: numOfLessons, programTotalMinCal: programTotalMinCal, programTotalMaxCal: programTotalMaxCal, totalDuration: totalDuration, updatedDiff: updatedDiff, className: css(styles.labelInfo) })),
                        !isOriginalPlaylist && (React.createElement("div", { className: css(styles.userActionRow) },
                            React.createElement(FavoriteWithCount, { favCount: totalFavoriteCount, onClick: onClickFavorite, isStoppedFav: !isFavorited, classObject: styles.favoriteWithCount }),
                            React.createElement(TwitterWithText, { text: "\u30B7\u30A7\u30A2\u3059\u308B", twitterService: twitterService, onClickTwitterShareIfAuthed: onClickTwitterShareIfAuthed, playlist: playlist }))))),
                React.createElement("div", { className: css(styles.description) },
                    React.createElement("span", null, playlistDescription))))));
};
var styles = StyleSheet.create({
    wrapper: {
        position: 'relative',
        width: '100%',
        padding: 40,
    },
    container: {
        maxWidth: 988,
        display: 'flex',
    },
    imageBox: {
        zIndex: 1,
    },
    image: {
        width: 220,
        borderRadius: 16,
    },
    playlistInfo: {
        padding: '20px 5px 0 32px',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    playlistTitle: {
        fontWeight: 'bold',
        fontSize: 18,
        margin: 0,
        lineHeight: '1.75',
    },
    playlistInfoBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 18,
    },
    playlistCreator: {
        display: 'flex',
        alignItems: 'center',
    },
    creatorImgContainer: {
        width: 22,
        height: 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.White,
        borderRadius: '15px',
        border: "1px solid ".concat(Colors.BorderLightGrayB),
        overflow: 'hidden',
    },
    labelInfo: {
        marginTop: 7,
    },
    outlinedHeart: {
        width: 17,
    },
    creatorName: {
        marginLeft: 8,
        fontSize: 11,
        fontWeight: 'bold',
    },
    userActionRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: 250,
        gap: 15,
    },
    favoriteWithCount: {
        width: 100,
    },
    description: {
        position: 'relative',
        marginTop: 32,
        fontSize: 14,
        letterSpacing: '1px',
        lineHeight: 1.7,
    },
});
