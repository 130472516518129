var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite-jss'; // aphrodite-jssでしか出来ないcssを使用しているので、変更しないように
import { Colors, MediaBreakPointUp } from '../styles';
import { NutrientFood } from '@lean-body/src/domain/nutrient_food';
export var NutrientFoodTable = function (_a) {
    var config = _a.config;
    var nutrientFood = new NutrientFood(config);
    return (React.createElement("table", { className: css(styles.table) },
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("th", null, "\u6804\u990A\u7D20"),
                React.createElement("th", null, "\u591A\u304F\u542B\u307E\u308C\u308B\u98DF\u3079\u7269\u306E\u4F8B"))),
        React.createElement("tbody", null, nutrientFood.list.map(function (v, i) { return (React.createElement("tr", { key: i },
            React.createElement("td", null, v.name),
            React.createElement("td", null,
                React.createElement("div", { className: css(styles.foods) }, nutrientFood.nutrientFoods(v.key).map(function (food, foodIndex) { return (React.createElement("div", { className: css(styles.food), key: foodIndex },
                    React.createElement("img", { className: css(styles.foodImg), src: nutrientFood.imageURL(food.key) }),
                    React.createElement("div", { className: css(styles.foodName) }, food.name))); }))))); }))));
};
var styles = StyleSheet.create({
    table: {
        width: '100%',
        marginBottom: 50,
        '& th': {
            fontSize: 12,
            fontWeight: 500,
            height: 32,
            border: "1.5px solid ".concat(Colors.BorderLightGray),
            textAlign: 'center',
            verticalAlign: 'middle',
        },
        '& td': {
            fontSize: 12,
            fontWeight: 'bold',
            border: "1.5px solid ".concat(Colors.BorderLightGray),
            textAlign: 'left',
            verticalAlign: 'middle',
            padding: 6,
        },
    },
    foods: (_a = {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: 290,
            marginLeft: 6
        },
        _a[MediaBreakPointUp.MD] = {
            marginLeft: 68,
        },
        _a),
    food: {},
    foodImg: {
        width: 50,
    },
    foodName: {
        fontSize: 10,
        fontWeight: 'normal',
        textAlign: 'center',
    },
});
