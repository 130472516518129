export var checkSameArray = function (arrayA, arrayB) {
    if (arrayA.length !== arrayB.length)
        return false;
    var conArray = arrayA.concat(arrayB);
    var dupDeleteArray = Array.from(new Set(conArray));
    return dupDeleteArray.length === arrayA.length;
};
// 指定した数で区切って二次元配列にして返す
export var separateArrayBySpecifiedNumber = function (array, number) {
    var list = [];
    var elementList = [];
    array.forEach(function (v, i) {
        elementList.push(v);
        if ((i + 1) % number === 0) {
            list.push(elementList);
            elementList = [];
        }
    });
    if (elementList.length > 0)
        list.push(elementList);
    return list;
};
/*
  配列を２D配列に変換する
  --example
  toMatrix([1, 2, 3, 4, 5, 6, 7], 3) -> [[1, 2, 3], [4, 5, 6], [7]]
*/
export var toMatrix = function (array, cols) {
    var matrix = [];
    var totalRows = Math.ceil(array.length / cols);
    for (var i = 0; i < totalRows; i++) {
        matrix.push(array.slice(i * cols, (i + 1) * cols));
    }
    return matrix;
};
