export var AuthStatus;
(function (AuthStatus) {
    AuthStatus["Authed"] = "authed";
    AuthStatus["Unauth"] = "unauth";
    AuthStatus["Dormant"] = "dormant";
    AuthStatus["Future"] = "future";
    AuthStatus["NoSubscription"] = "no_subscription";
})(AuthStatus || (AuthStatus = {}));
export var SnsProvider;
(function (SnsProvider) {
    SnsProvider["Twitter"] = "Twitter";
})(SnsProvider || (SnsProvider = {}));
export var SharedTiming;
(function (SharedTiming) {
    SharedTiming["summary"] = "summary";
    SharedTiming["collection"] = "collection";
    SharedTiming["share_button"] = "share_button";
    SharedTiming["getreward"] = "getreward";
})(SharedTiming || (SharedTiming = {}));
/**
 * Auth method
 */
export var AuthMethod;
(function (AuthMethod) {
    AuthMethod["Email"] = "email";
    AuthMethod["Facebook"] = "facebook";
    AuthMethod["LINE"] = "line";
    AuthMethod["Apple"] = "apple";
})(AuthMethod || (AuthMethod = {}));
/**
 * Track event type
 */
export var FavoriteStatus;
(function (FavoriteStatus) {
    FavoriteStatus["Add"] = "add";
    FavoriteStatus["Remove"] = "remove";
})(FavoriteStatus || (FavoriteStatus = {}));
export var ContentType;
(function (ContentType) {
    ContentType["Lesson"] = "lesson";
    ContentType["Program"] = "program";
})(ContentType || (ContentType = {}));
/**
 * UserChallenge status
 */
export var UserChallengeStatus;
(function (UserChallengeStatus) {
    UserChallengeStatus["NotChallnegedYet"] = "not_challenged_yet";
    UserChallengeStatus["ChallengeInProgress"] = "in_progress";
    UserChallengeStatus["ChallengeComplete"] = "complete";
    UserChallengeStatus["ChallengeStopped"] = "stopped";
})(UserChallengeStatus || (UserChallengeStatus = {}));
