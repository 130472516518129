import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
var SCALE_FACTOR_DEFAULT = 2;
var DURATION_DEFAULT = '0.3s';
export var PulseAnimation = function (_a) {
    var scale = _a.scale, duration = _a.duration, children = _a.children;
    var styles = StyleSheet.create({
        container: {
            animationName: {
                '0%': {
                    transform: 'scale(1)',
                },
                '50%': {
                    transform: "scale(".concat(scale !== null && scale !== void 0 ? scale : SCALE_FACTOR_DEFAULT, ")"),
                },
                '100%': {
                    transform: 'scale(1)',
                },
            },
            animationDuration: duration !== null && duration !== void 0 ? duration : DURATION_DEFAULT,
            'animation-timing-function': 'linear',
        },
    });
    return React.createElement("div", { className: css(styles.container) }, children);
};
