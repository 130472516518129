import { Lesson, Program } from '.';
/*=============================================
= Entities =
=============================================*/
export var RankingStatus = {
    new: 'new',
    same: 'same',
    higher: 'higher',
    lower: 'lower',
};
var WatchedLessonRanking = /** @class */ (function () {
    function WatchedLessonRanking(data, config) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "program", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "programId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "ranking", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "rankingStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalViewCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.lesson = new Lesson(data.lesson, config);
        this.lessonId = data.lessonId;
        this.program = new Program(data.program, config);
        this.programId = data.programId;
        this.ranking = data.ranking;
        this.rankingStatus = RankingStatus[data.rankingStatus];
        this.totalViewCount = data.totalViewCount;
        this.config = config;
    }
    return WatchedLessonRanking;
}());
export { WatchedLessonRanking };
