import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors } from '@lean-body/components/styles';
import { Link } from 'react-router-dom';
export var FooterItem = function (props) {
    var isActive = props.isActive, iconSrc = props.iconSrc, iconSrcActive = props.iconSrcActive, classObject = props.classObject, linkTo = props.linkTo, label = props.label, notificationClassObject = props.notificationClassObject, hasNotification = props.hasNotification, isLongLabel = props.isLongLabel;
    var styles = StyleSheet.create({
        button: {
            textAlign: 'center',
            width: '20%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'relative',
        },
        imgContainer: {
            position: 'relative',
            height: '100%',
            display: 'flex',
            alignItems: 'flex-end',
        },
        icon: {
            textAlign: 'center',
            width: 28,
            height: 28,
        },
        label: {
            display: 'block',
            color: Colors.Black,
            fontSize: 10,
            fontWeight: 400,
            letterSpacing: '0.05em',
            lineHeight: 1,
            marginBottom: 11,
        },
        activeLabel: {
            color: Colors.PrimaryDarkB,
            fontWeight: 500,
        },
        longLabel: {
            letterSpacing: '-0.05em',
        },
        hasNotification: {
            width: 6,
            height: 6,
            borderRadius: 100,
            backgroundColor: Colors.Primary,
            position: 'absolute',
            top: 7,
            right: -5,
        },
    });
    return (React.createElement(Link, { to: linkTo, className: css(styles.button) },
        React.createElement("div", { className: css(styles.imgContainer) },
            React.createElement("img", { src: isActive ? iconSrcActive : iconSrc, className: css(styles.icon, classObject) }),
            hasNotification && React.createElement("div", { className: css(styles.hasNotification, notificationClassObject) })),
        React.createElement("span", { className: css(styles.label, isActive && styles.activeLabel, isLongLabel && styles.longLabel) }, label)));
};
