var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { icon, MediaBreakPointUp, MediaBreakPointDown } from '@lean-body/components/styles';
var useState = React.useState;
export var SearchTreeView = function (props) {
    var _a = useState(true), isOpen = _a[0], setIsOpen = _a[1];
    var openClose = function () {
        setIsOpen(!isOpen);
    };
    var containerStyle = [css(styles.containter), props.isFirst ? css(styles.firstContainer) : ''].join(' ');
    var arrowStyle = [
        css(styles.arrow),
        css(icon.anticon),
        isOpen ? css(icon.anticonArrowUp) : css(icon.anticonArrowDown),
    ].join(' ');
    var header = function () {
        return (React.createElement("div", { className: css(styles.headerContainer), onClick: openClose },
            React.createElement("div", { className: css(styles.headerTitle) }, props.title),
            React.createElement("i", { className: arrowStyle })));
    };
    return (React.createElement("div", { className: containerStyle },
        React.createElement("div", { className: css(styles.contents) },
            header(),
            isOpen ? props.children : '')));
};
var styles = StyleSheet.create({
    containter: (_a = {
            borderBottom: '1px solid #DDDDDD'
        },
        _a[MediaBreakPointDown.SM] = {
            // 親要素のmarginを無視するため
            marginLeft: 'calc(((100vw - 100%) / 2) * -1)',
            marginRight: 'calc(((100vw - 100%) / 2) * -1)',
        },
        _a),
    contents: {
        margin: '0 12px 0 24px',
    },
    firstContainer: {
        borderTop: '1px solid #DDDDDD',
    },
    headerContainer: (_b = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer'
        },
        _b[MediaBreakPointUp.XL] = {
            marginRight: '12px',
        },
        _b),
    headerTitle: {
        fontWeight: 500,
        fontSize: '1.2rem',
        color: '#65CED9',
        margin: '1rem 0',
    },
    arrow: {
        fontSize: '1.2rem',
        color: '#333333',
    },
});
