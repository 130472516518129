var Footer = /** @class */ (function () {
    function Footer(data, storage) {
        Object.defineProperty(this, "tabs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "storage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.tabs = data;
        this.storage = storage;
    }
    Object.defineProperty(Footer.prototype, "hasLiveLessonNotification", {
        get: function () {
            return this.tabs.liveSchedule.hasNotice && !this.storage.noticedLiveScheduleTabToday;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Footer.prototype, "hasActivityNotification", {
        get: function () {
            return this.tabs.activity.hasNotice && !this.storage.noticedActivityTabToday;
        },
        enumerable: false,
        configurable: true
    });
    return Footer;
}());
export { Footer };
