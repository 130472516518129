var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c, _d;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Button, ButtonType } from '@lean-body/components/atoms';
import { SelectLessonDialog } from '@lean-body/components/organisms/select_lesson_dialog';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { PlusIcon } from '@lean-body/components/atoms/icons/plus_icon';
import { OriginalPlaylistLessonCard } from '@lean-body/components/molecules/original_playlist_lesson_card';
import { ArrowIcon } from '@lean-body/components/atoms/icons/arrow_icon';
import { PlaylistLessonCard } from '@lean-body/components/molecules/playlist_lesson_card';
import { useSmMediaQuery } from '@lean-body/src/util/media_query_hooks';
export var NewPlaylistLessons = function (_a) {
    var lessons = _a.lessons, setLessons = _a.setLessons, fetchFavoriteLessons = _a.fetchFavoriteLessons, searchLessons = _a.searchLessons, clearSearchedLessons = _a.clearSearchedLessons, fetchLessonHistory = _a.fetchLessonHistory, getSearchKeywords = _a.getSearchKeywords;
    var _b = React.useState(false), showLessonsDialog = _b[0], setShowLessonsDialog = _b[1];
    var isSM = useSmMediaQuery();
    var changeLessonsIndex = function (targetIndex, moveIndex) {
        if (moveIndex < 0 || moveIndex > lessons.length - 1)
            return; // 対象外の位置には移動できないのでreturn
        var sortedLessons = lessons.filter(function (_, i) { return i !== targetIndex; });
        sortedLessons.splice(moveIndex, 0, lessons[targetIndex]);
        setLessons(sortedLessons);
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.sectionHead) },
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u30EC\u30C3\u30B9\u30F3")),
        React.createElement("ul", null, lessons.map(function (v, i) { return (React.createElement("li", { className: css(styles.selectedLesson), key: i },
            React.createElement("div", { className: css(styles.arrowIcons) },
                React.createElement("div", { onClick: function () { return changeLessonsIndex(i, i - 1); }, className: css(styles.arrowIcon) },
                    React.createElement(ArrowIcon, { color: i === 0 && Colors.GrayLighter })),
                React.createElement("div", { onClick: function () { return changeLessonsIndex(i, i + 1); }, className: css(styles.arrowIcon, styles.arrowDown) },
                    React.createElement(ArrowIcon, { color: i === lessons.length - 1 && Colors.GrayLighter }))),
            isSM ? (React.createElement(OriginalPlaylistLessonCard, { lesson: v, onClickMinus: function () { return setLessons(lessons.filter(function (lesson) { return lesson !== v; })); } })) : (React.createElement(React.Fragment, null,
                React.createElement(PlaylistLessonCard, { lesson: v, classObject: styles.playlistLessonCard }),
                React.createElement("div", { className: css(styles.delete), onClick: function () { return setLessons(lessons.filter(function (lesson) { return lesson !== v; })); } }, "\u524A\u9664"))))); })),
        React.createElement(Button, { buttonType: ButtonType.lightPrimary, className: css(styles.button), isRadius: true, onClick: function () { return setShowLessonsDialog(true); } },
            React.createElement(PlusIcon, { classObject: styles.plusIcon }),
            "\u30EC\u30C3\u30B9\u30F3\u3092\u8FFD\u52A0\u3059\u308B"),
        showLessonsDialog && (React.createElement(SelectLessonDialog, { alreadySelectedLessons: lessons, closeDialog: function () { return setShowLessonsDialog(false); }, submit: function (v) { return setLessons(__spreadArray(__spreadArray([], lessons, true), v, true)); }, fetchFavoriteLessons: fetchFavoriteLessons, searchLessons: searchLessons, fetchLessonHistory: fetchLessonHistory, clearSearchedLessons: clearSearchedLessons, getSearchKeywords: getSearchKeywords }))));
};
var styles = StyleSheet.create({
    container: (_a = {},
        _a[MediaBreakPointUp.MD] = {
            margin: '0 32px',
        },
        _a),
    sectionHead: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItem: 'center',
        marginBottom: 6,
    },
    sectionTitle: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    selectedLesson: {
        display: 'flex',
        ':last-of-type': {
            marginBottom: 16,
        },
    },
    playlistLessonCard: {
        backgroundColor: Colors.GrayLight,
        marginBottom: 16,
    },
    arrowIcons: (_b = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginBottom: 8
        },
        _b[MediaBreakPointUp.MD] = {
            marginBottom: 16,
        },
        _b),
    arrowIcon: (_c = {
            height: 22,
            width: 22,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
        },
        _c[MediaBreakPointUp.MD] = {
            height: 40,
            width: 40,
            marginTop: 24,
        },
        _c),
    arrowDown: (_d = {
            transform: 'rotate(180deg)',
            justifyContent: 'flex-end'
        },
        _d[MediaBreakPointUp.MD] = {
            margin: '0 0 24px',
        },
        _d),
    plusIcon: {
        margin: '-2px 10px 0 0',
    },
    button: {
        width: '100%',
        height: 56,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 375,
    },
    delete: {
        color: Colors.Primary,
        fontSize: 14,
        lineHeight: 1,
        alignSelf: 'center',
        padding: '9px 17px',
        margin: '0 0 0 24px',
        borderRadius: 4,
        width: 77,
        textAlign: 'center',
        backgroundColor: Colors.BlueLighterB,
        cursor: 'pointer',
    },
});
