import * as React from 'react';
import { FormControl, Select as MuiSelect, InputBase, MenuItem, createStyles } from '@mui/material';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { styled } from '@mui/system';
var BootstrapInput = styled(InputBase)(function () { return createStyles({}); });
export var SelectWithoutOutline = function (_a) {
    var selectOptions = _a.selectOptions, value = _a.value, containerClassObject = _a.containerClassObject, onChange = _a.onChange;
    var name = "".concat(Math.random());
    return (React.createElement(FormControl, { className: css(styles.container, containerClassObject) },
        React.createElement(MuiSelect, { IconComponent: function () { return null; }, value: value, onChange: onChange, input: React.createElement(BootstrapInput, null), inputProps: { name: name, id: "select-without-outline-".concat(name) }, classes: {
                root: css(styles.root),
                select: css(styles.select),
            } }, selectOptions.map(function (data, i) {
            return (React.createElement(MenuItem, { key: i, value: data.value }, data.name));
        }))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    },
    root: {
        width: '100%',
        color: Colors.Black,
    },
    select: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 32,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: 2.56,
        padding: '0 0 20px',
        borderBottom: "2px solid ".concat(Colors.BorderLightGray),
        borderRadius: 0,
    },
});
