var ValidateResult = /** @class */ (function () {
    function ValidateResult() {
        Object.defineProperty(this, "errors", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "isValid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
    }
    Object.defineProperty(ValidateResult.prototype, "pushErrorMessage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (message) {
            this.errors.push(message);
            this.isValid = false;
        }
    });
    return ValidateResult;
}());
export { ValidateResult };
