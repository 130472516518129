// ABテスト用のサービス
var ABTestService = /** @class */ (function () {
    function ABTestService(gTagClient, localStorage) {
        Object.defineProperty(this, "tagClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "localStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.tagClient = gTagClient;
        this.localStorage = localStorage;
    }
    Object.defineProperty(ABTestService.prototype, "loadGoogleOptimizeTag", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.tagClient.embedGoogleOptimize();
        }
    });
    return ABTestService;
}());
export { ABTestService };
