import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Button } from '@lean-body/components/atoms/button';
import { FormGroup } from '../layouts';
export var ButtonFormGroup = function (props) {
    var containerClass = [props.className || '', css(styles.container)].join(' ');
    var btnClass = [props.btnClassName || '', css(styles.button)].join(' ');
    return (React.createElement(FormGroup, { className: containerClass },
        React.createElement(Button, { loading: props.loading, disabled: props.disabled, onClick: props.onClick, buttonType: props.buttonType, hasArrow: props.hasArrow, className: btnClass, dataTest: props.dataTest, id: props.id, isRadius: props.isRadius }, props.label)));
};
var styles = StyleSheet.create({
    container: {},
    button: {
        width: '100%',
        marginBottom: 0,
    },
});
