import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { MealHistoryEmptyStateIcon } from '../atoms/icons/meal_history_empty_state_icon';
import { Colors } from '../styles';
export var MealHistoryEmptyState = function (_a) {
    var classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(MealHistoryEmptyStateIcon, { classObject: styles.icon }),
        React.createElement("div", { className: css(styles.title) }, "\u5C65\u6B74\u304C\u3042\u308A\u307E\u305B\u3093"),
        React.createElement("div", { className: css(styles.description) }, "\u98DF\u3079\u305F\u3082\u306E\u3092\u691C\u7D22\u3057\u3066\u98DF\u4E8B\u8A18\u9332\u3092\u3057\u3066\u307F\u307E\u3057\u3087\u3046\uFF01")));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    icon: {
        marginBottom: 12,
    },
    title: {
        color: Colors.GrayDarkest,
        marginBottom: 10,
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
    description: {
        color: Colors.GrayDarkest,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
});
