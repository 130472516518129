var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { css, StyleSheet } from 'aphrodite';
import bind from 'bind-decorator';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import validator from 'validator';
import { FormError } from '../../errors';
import * as msg from '@lean-body/src/messages';
import { FooterComponent } from '@lean-body/components/organisms/footer';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Button, ButtonType, Input, Logo } from '../atoms';
import { useSmMediaQuery } from '@lean-body/src/util';
export var PasswordResetRequestPage = observer(function (_a) {
    var vm = _a.vm;
    var isSM = useSmMediaQuery();
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(Logo, { forHeader: isSM, classObject: styles.header }),
        React.createElement("div", { className: css(styles.contentWithFooter) },
            React.createElement("div", { className: css(styles.content) },
                React.createElement("div", { className: css(styles.title) }, "\u30D1\u30B9\u30EF\u30FC\u30C9\u30EA\u30BB\u30C3\u30C8"),
                React.createElement("div", { className: css(styles.message) },
                    "\u3054\u767B\u9332\u306B\u4F7F\u7528\u3057\u305F\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
                    !isSM && React.createElement("br", null),
                    "\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u30EA\u30BB\u30C3\u30C8\u3059\u308B\u70BA\u306E\u30EA\u30F3\u30AF\u3092\u304A\u9001\u308A\u3057\u307E\u3059\u3002"),
                React.createElement(Input, { type: "email", label: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9", value: vm.email, onChange: vm.onEmailChanged, labelClassObject: styles.inputLabel, placeholder: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u5165\u529B" }),
                React.createElement(Button, { buttonType: vm.email ? ButtonType.primary : ButtonType.transparentPrimary, className: css(styles.button), onClick: vm.submit, loading: vm.isSubmitting, disabled: vm.isSubmitting, fullWidth: true, isRadius: true }, "\u30EA\u30F3\u30AF\u3092\u9001\u4FE1")),
            React.createElement(FooterComponent, null))));
});
var PasswordResetRequestPageVM = /** @class */ (function () {
    function PasswordResetRequestPageVM(apiClient, alertPresenter) {
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "email", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "isSubmitting", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        makeObservable(this, {
            email: observable,
            isSubmitting: observable,
        });
        this.apiClient = apiClient;
        this.alertPresenter = alertPresenter;
    }
    Object.defineProperty(PasswordResetRequestPageVM.prototype, "onEmailChanged", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            this.email = event.target.value;
        }
    });
    Object.defineProperty(PasswordResetRequestPageVM.prototype, "validate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var errors = [];
            if (validator.isEmpty(this.email)) {
                errors.push(msg.EMAIL_EMPTY);
            }
            else if (!validator.isEmail(this.email)) {
                errors.push(msg.EMAIL_INVALID);
            }
            if (errors.length > 0) {
                this.alertPresenter.showError(new FormError('', errors.join('\n')), msg.INVALID_INPUT);
                return false;
            }
            return true;
        }
    });
    Object.defineProperty(PasswordResetRequestPageVM.prototype, "submit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            var _this = this;
            event.preventDefault();
            if (!this.validate()) {
                return;
            }
            this.isSubmitting = true;
            this.apiClient
                .requestResetPassword({ email: this.email })
                .then(function () {
                _this.isSubmitting = false;
                _this.alertPresenter.showMessage('パスワードリセットメールを送信しました');
            })
                .catch(function (err) {
                _this.isSubmitting = false;
                _this.alertPresenter.showError(err, 'パスワードリセットメール送信に失敗しました');
            });
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PasswordResetRequestPageVM.prototype, "onEmailChanged", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PasswordResetRequestPageVM.prototype, "submit", null);
    return PasswordResetRequestPageVM;
}());
export { PasswordResetRequestPageVM };
var styles = StyleSheet.create({
    container: (_a = {},
        _a[MediaBreakPointUp.MD] = {
            paddingTop: 80,
        },
        _a),
    header: (_b = {},
        _b[MediaBreakPointUp.MD] = {
            width: 271,
            margin: '0 auto 18px',
        },
        _b),
    contentWithFooter: (_c = {
            padding: '40px 24px 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 'calc(100vh - 50px)'
        },
        _c[MediaBreakPointUp.MD] = {
            padding: 0,
            minHeight: 'calc(100vh - 146px)',
        },
        _c),
    content: (_d = {},
        _d[MediaBreakPointUp.MD] = {
            borderRadius: 10,
            maxWidth: 375,
            margin: '0 auto',
            padding: '40px 0',
        },
        _d),
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 14,
    },
    message: {
        color: Colors.GrayDarkest,
        fontSize: 14,
        fontWeight: 500,
        marginBottom: 14,
        textAlign: 'center',
    },
    inputLabel: {
        color: Colors.Text,
        fontWeight: 'bold',
    },
    button: {
        fontSize: 16,
        marginTop: 30,
    },
});
