var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { LiveEventItems } from '@lean-body/components/molecules/live_event_items';
export var LiveEventPresent = function () {
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.title) }, "Present"),
        React.createElement("div", { className: css(styles.desc) },
            "\u30E9\u30A4\u30D6\u30EC\u30C3\u30B9\u30F3\u306B\u53C2\u52A0\u3057\u3066\u3001Twitter\u304BInstagram\u3067",
            React.createElement("span", { className: css(styles.bold) }, "#\u8102\u80AA\u71C3\u713C\u30D5\u30A7\u30B9\u30C6\u30A3\u30D0\u30EB"),
            "\u3068\u6295\u7A3F\u3057\u3066\u304F\u308C\u305F\u65B9\u306B\u3001\u62BD\u9078\u3067\u4E0B\u8A18\u306E\u8CDE\u54C1\u3092\u30D7\u30EC\u30BC\u30F3\u30C8\uFF01"),
        React.createElement(LiveEventItems, { displayLowerItems: true })));
};
var styles = StyleSheet.create({
    container: (_a = {
            borderTop: "1px solid ".concat(Colors.Gray),
            padding: '28px 24px',
            marginBottom: 22
        },
        _a[MediaBreakPointUp.XL] = {
            padding: '40px 0 18px',
        },
        _a),
    title: (_b = {
            fontSize: 22,
            fontWeight: 'bold',
            marginBottom: 16
        },
        _b[MediaBreakPointUp.XL] = {
            marginBottom: 24,
        },
        _b),
    desc: {
        fontSize: 14,
    },
    bold: {
        fontWeight: 'bold',
    },
});
