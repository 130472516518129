var _a, _b, _c;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MediaBreakPointUp } from '@lean-body/components/styles';
export var ParticipationCount = function (props) {
    var formatCount = function () {
        return props.count.toLocaleString();
    };
    return (React.createElement("div", { className: css(styles.participation) },
        React.createElement("span", { className: css(styles.icon) },
            React.createElement("img", { src: "/assets/images/icons/outlined_person.svg", className: css(styles.outlinedPerson) })),
        React.createElement("span", { className: css(styles.count) }, formatCount())));
};
var styles = StyleSheet.create({
    participation: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 8,
    },
    icon: (_a = {
            display: 'inline-block',
            width: 16,
            height: 16,
            backgroundColor: '#65CED9',
            borderRadius: '50%',
            textAlign: 'center'
        },
        _a[MediaBreakPointUp.LG] = {
            width: 32,
            height: 32,
            marginRight: '.5rem',
        },
        _a),
    outlinedPerson: (_b = {
            width: 8,
            height: 8,
            marginTop: -4
        },
        _b[MediaBreakPointUp.LG] = {
            width: 16,
            height: 16,
            marginTop: 8,
        },
        _b),
    count: (_c = {
            fontSize: 10,
            fontWeight: 'bold',
            color: '#6F6F7F',
            marginLeft: 4
        },
        _c[MediaBreakPointUp.LG] = {
            fontSize: '1rem',
        },
        _c),
});
