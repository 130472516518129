var _a;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Button } from '../atoms';
import * as dayjs from 'dayjs';
import { getDiffDays } from '@lean-body/src/util';
import { InputRadioButton } from '../atoms/input_radio_button';
import { useNavigate } from 'react-router';
import { routes } from '@lean-body/src/route';
import { SettingItemKeys } from './setting_menu';
import { MediaBreakPointUp } from '../styles';
var DORMANT_DAFAULT_MONTH = 1;
export var RecommendDormantInputs = [
    { label: '1ヶ月', value: '1' },
    { label: '3ヶ月', value: '3' },
    { label: '6ヶ月', value: '6' },
];
export var RecommendDormant = function (props) {
    var payment = props.payment, dormantAccount = props.dormantAccount, classObject = props.classObject, strictDormantMonth = props.strictDormantMonth;
    var _a = React.useState(strictDormantMonth || DORMANT_DAFAULT_MONTH), month = _a[0], setMonth = _a[1];
    var _b = React.useState(dayjs().add(month, 'M')), dormantFinishDay = _b[0], setDormantFinishDay = _b[1];
    var _c = React.useState(false), submitted = _c[0], setSubmitted = _c[1];
    var navigate = useNavigate();
    var setMonthForRadiInput = function (value) {
        setMonth(parseInt(value));
    };
    var submit = function () {
        if (submitted)
            return;
        setSubmitted(true);
        dormantAccount(month).then(function () {
            navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.plan));
        });
    };
    React.useEffect(function () {
        // NOTE: 課金当日に解約しようとしてお問い合わせになるケースがあるので、休眠期間終了日一日前を表示する。
        setDormantFinishDay(dayjs().add(month, 'M').subtract(1, 'd'));
    }, [month]);
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.description) },
            "\u300C\u4F11\u7720\u30D7\u30E9\u30F3\u300D\u306F\u3044\u304B\u304C\u3067\u3059\u304B\uFF1F",
            React.createElement("br", null),
            "\u30B5\u30FC\u30D3\u30B9\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u306A\u304F\u306A\u308B\u4EE3\u308F\u308A\u306B\u3001\u305D\u306E\u671F\u9593\u4E2D\u306E\u304A\u652F\u6255\u3044\u304C\u767A\u751F\u3057\u306A\u3044\u3001\u4E00\u6642\u7684\u306B\u304A\u4F11\u307F\u304C\u3067\u304D\u308B\u30D7\u30E9\u30F3\u3068\u306A\u3063\u3066\u3044\u307E\u3059\u3002"),
        React.createElement("div", { className: css(styles.descriptionMin) },
            "\u203B\u4F11\u7720\u306F\u3044\u3064\u3067\u3082\u89E3\u9664\u3067\u304D\u307E\u3059",
            React.createElement("br", null),
            "\u203B\u4F11\u7720\u89E3\u9664\u3057\u305F\u65E5\u304B\u3089\u3001",
            getDiffDays(payment.curPeriodEndDate, new Date()),
            "\u65E5\u9593\u306F\u30D7\u30E9\u30F3\u3092\u5F15\u304D\u7D9A\u304D\u3054\u5229\u7528\u3067\u304D\u307E\u3059",
            payment.isAmazonPayUser() && (React.createElement("div", { className: css(styles.amazonpayDesc) }, "\u203BAmazon pay\u3092\u3054\u5229\u7528\u306E\u65B9\u306F\u3001\u4F11\u7720\u306B\u3042\u305F\u308A1\u5186\u306E\u4E0E\u4FE1\u60C5\u5831\u306E\u78BA\u8A8D\u624B\u7D9A\u304D\u304C\u5165\u308A\u307E\u3059\u300230\u65E5\u5F8C\u306B\u81EA\u52D5\u8FD4\u91D1\u3055\u308C\u307E\u3059\u306E\u3067\u3054\u5B89\u5FC3\u304F\u3060\u3055\u3044"))),
        !strictDormantMonth && (React.createElement("div", { className: css(styles.radioInput) },
            "\u4F11\u7720\u671F\u9593\u306E\u76EE\u5B89\uFF1A",
            React.createElement(InputRadioButton, { classObject: styles.radioButton, items: RecommendDormantInputs, submitValue: month.toString(), setSubmitValue: setMonthForRadiInput }))),
        React.createElement(Button, { className: css(styles.button), onClick: submit, loading: submitted },
            "\u4F11\u7720\u30D7\u30E9\u30F3\u3067",
            strictDormantMonth ? "".concat(strictDormantMonth, "\u30F6\u6708") : '少し',
            "\u4F11\u3080"),
        React.createElement("div", { className: css(styles.limit) },
            "\u4F11\u7720\u306E\u671F\u65E5\uFF1A",
            dormantFinishDay.format('YYYY.MM.DD'))));
};
var styles = StyleSheet.create({
    container: {},
    description: {
        fontSize: 14,
        letterSpacing: '0.04em',
    },
    descriptionMin: {
        fontSize: 12,
        margin: '8px 0 40px',
        letterSpacing: '0.04em',
    },
    amazonpayDesc: {
        marginTop: 8,
    },
    radioInput: {
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    radioButton: {
        marginLeft: 14,
    },
    button: (_a = {
            display: 'block',
            margin: '18px auto 8px',
            width: '100%'
        },
        _a[MediaBreakPointUp.MD] = {
            maxWidth: 240,
        },
        _a),
    limit: {
        fontSize: 12,
        textAlign: 'center',
    },
});
