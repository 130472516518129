import { Colors } from '@lean-body/src/view/styles/index';
import * as dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);
// カレンダーの日にちの色スタイル
var highlightTypeToDayStyleWithState = new Map([
    [
        'accent',
        {
            normal: { backgroundColor: Colors.BackgroundAccent, color: Colors.Accent },
            selected: { backgroundColor: Colors.Accent, color: Colors.White },
        },
    ],
    [
        'default',
        {
            normal: { backgroundColor: Colors.BackgroundBlue, color: Colors.PrimaryDark },
            selected: { backgroundColor: Colors.PrimaryDark, color: Colors.White },
        },
    ],
]);
var LiveCalendarDayState = /** @class */ (function () {
    function LiveCalendarDayState(data) {
        Object.defineProperty(this, "year", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "month", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "day", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "highlightType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "style", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.year = data.year;
        this.month = data.month;
        this.day = data.day;
        this.state = data.state;
        this.highlightType = data.highlightType;
        this.style =
            highlightTypeToDayStyleWithState.get(this.highlightType) || highlightTypeToDayStyleWithState.get('default');
    }
    Object.defineProperty(LiveCalendarDayState.prototype, "date", {
        get: function () {
            return new Date(this.year, this.month - 1, this.day);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LiveCalendarDayState.prototype, "dayjs", {
        get: function () {
            return dayjs(this.date);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LiveCalendarDayState.prototype, "isAfterToday", {
        get: function () {
            return this.dayjs.isSameOrAfter(dayjs().startOf('day'));
        },
        enumerable: false,
        configurable: true
    });
    return LiveCalendarDayState;
}());
export { LiveCalendarDayState };
