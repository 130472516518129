import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import SearchTitle from './search_title';
import { Colors } from '../../styles';
import { routes } from '@lean-body/src/route';
import { Link } from 'react-router-dom';
import { SeriesIcon } from '../../atoms/icons/series_icon';
import { PlaylistIcon } from '../../atoms/icons/playlist_icon';
export var SearchProgram = function () {
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(SearchTitle, null, "\u30D7\u30ED\u30B0\u30E9\u30E0"),
        React.createElement("div", { className: css(styles.items) },
            React.createElement(Link, { className: css(styles.item), to: routes.PROGRAMS.compile() },
                React.createElement("div", { className: css(styles.itemText) },
                    React.createElement(SeriesIcon, null),
                    "\u30B7\u30EA\u30FC\u30BA\u4E00\u89A7")),
            React.createElement(Link, { className: css(styles.item), to: routes.PLAYLISTS.compile() },
                React.createElement("div", { className: css(styles.itemText) },
                    React.createElement(PlaylistIcon, null),
                    "\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u4E00\u89A7")))));
};
var styles = StyleSheet.create({
    container: {},
    items: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    item: {
        cursor: 'pointer',
        color: Colors.Black,
        width: '100%',
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 0',
        ':not(:last-of-type)': {
            marginRight: 8,
        },
    },
    itemText: {
        fontSize: 14,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
