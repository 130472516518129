import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var DayIconTimeOfDay = {
    morning: 'morning',
    afternoon: 'afternoon',
    night: 'night',
};
export var DayIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 30,
            height: 30,
        },
    });
    var color = props.color || Colors.PrimaryDark;
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 30 30", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props.timeOfDay === DayIconTimeOfDay.morning ? (React.createElement(React.Fragment, null,
        React.createElement("path", { d: "M29.9993 21.1998C29.7938 21.7128 29.4077 21.8809 28.8682 21.8802C19.6203 21.8713 10.3723 21.8743 1.12433 21.8736C0.489453 21.8736 0.173848 21.6879 0.0373306 21.2379C-0.113132 20.7447 0.206877 20.2207 0.716249 20.1399C0.860106 20.1172 1.00837 20.1157 1.15443 20.1157C3.50825 20.1142 5.86135 20.1106 8.21517 20.1208C8.45738 20.1216 8.54399 20.0541 8.5917 19.814C9.30658 16.2073 12.583 13.9592 16.2059 14.5787C18.7476 15.0132 20.9546 17.196 21.3847 19.726C21.4383 20.0416 21.5521 20.1245 21.8669 20.1223C24.2009 20.1076 26.535 20.1223 28.869 20.1076C29.4092 20.104 29.7952 20.2757 30 20.7888V21.199L29.9993 21.1998Z", fill: color }),
        React.createElement("path", { d: "M2.7662 13.021C2.93721 13.0856 3.11924 13.1289 3.2763 13.2177C4.04917 13.6537 4.81763 14.0977 5.58316 14.5469C6.06684 14.831 6.22391 15.3323 5.97803 15.7653C5.72555 16.2108 5.21911 16.3532 4.73763 16.0831C3.92953 15.6302 3.12804 15.1649 2.33169 14.6922C1.95443 14.4684 1.81351 14.0801 1.92801 13.6867C2.03517 13.3197 2.37426 13.0606 2.7662 13.0217V13.021Z", fill: color }),
        React.createElement("path", { d: "M24.8497 16.219C24.3859 16.2168 24.0424 15.9511 23.9352 15.5694C23.831 15.1951 23.9793 14.8069 24.3418 14.5903C25.0112 14.1903 25.6901 13.8057 26.3654 13.4153C26.5261 13.3228 26.6839 13.2237 26.8513 13.1459C27.2689 12.9514 27.7438 13.0967 27.975 13.4791C28.2142 13.8754 28.1298 14.3966 27.7254 14.6432C26.8762 15.1606 26.0079 15.645 25.1426 16.1353C25.0369 16.1955 24.9026 16.2051 24.849 16.2197L24.8497 16.219Z", fill: color }),
        React.createElement("path", { d: "M14.1177 8.27749C14.1177 7.82904 14.1133 7.37985 14.1184 6.9314C14.1258 6.38313 14.4905 6 14.9962 6C15.502 6 15.8711 6.3824 15.8741 6.92993C15.8799 7.83712 15.8807 8.74503 15.8741 9.65221C15.8697 10.1895 15.4785 10.5851 14.983 10.5748C14.4942 10.5645 14.128 10.1792 14.1199 9.65295C14.1133 9.19422 14.1184 8.73549 14.1184 8.27749H14.1177Z", fill: color }),
        React.createElement("path", { d: "M8.04931 7.89062C8.25702 7.89283 8.51831 8.03081 8.68345 8.31192C9.16714 9.13543 9.65082 9.95967 10.1147 10.7942C10.3481 11.2148 10.2013 11.7087 9.81376 11.948C9.41081 12.1968 8.88676 12.1117 8.62987 11.6918C8.11169 10.8441 7.60526 9.98683 7.14359 9.10754C6.85001 8.54826 7.29993 7.8965 8.04931 7.89062Z", fill: color }),
        React.createElement("path", { d: "M21.9146 7.88916C22.7022 7.90237 23.1426 8.5578 22.8468 9.11782C22.3822 9.99564 21.8779 10.8536 21.3576 11.7006C21.1021 12.1168 20.5707 12.1961 20.1715 11.9436C19.7883 11.7006 19.6489 11.2067 19.883 10.7854C20.3432 9.95968 20.821 9.14351 21.2988 8.32807C21.4691 8.03742 21.7333 7.88843 21.9146 7.88989V7.88916Z", fill: color }))) : props.timeOfDay === DayIconTimeOfDay.afternoon ? (React.createElement(React.Fragment, null,
        React.createElement("path", { d: "M14.9792 22.9976C10.5955 22.9958 7.00243 19.4046 7.00061 15.0227C6.9988 10.5763 10.5746 6.99871 15.0218 6.9978C19.4183 6.9978 23.0022 10.5917 23.0013 15.0018C23.0004 19.4209 19.4101 22.9985 14.9792 22.9967V22.9976Z", fill: color }),
        React.createElement("path", { d: "M25.277 5.85756C25.2126 5.97739 25.1318 6.24881 24.9584 6.43128C24.2639 7.16477 23.5441 7.87376 22.8269 8.58637C22.3776 9.033 21.7612 9.0439 21.3517 8.62541C20.9505 8.21509 20.9687 7.61595 21.4062 7.17567C22.1252 6.45125 22.8478 5.72956 23.5722 5.0115C23.8663 4.7201 24.3012 4.6384 24.6688 4.80271C25.0583 4.977 25.2543 5.28565 25.2761 5.85756H25.277Z", fill: color }),
        React.createElement("path", { d: "M16.0003 3.50468C16.0003 3.98399 16.004 4.4624 16.0003 4.94171C15.994 5.55175 15.57 5.99656 15.0027 5.99747C14.4353 5.99747 14.0059 5.55174 14.0023 4.94443C13.9977 3.97582 13.9968 3.00812 14.0023 2.03951C14.0059 1.43401 14.4435 0.992826 15.0145 1.00009C15.5691 1.00735 15.9922 1.44763 15.9994 2.03678C16.0058 2.52608 16.0003 3.01538 16.0003 3.50468Z", fill: color }),
        React.createElement("path", { d: "M3.51281 15.9977C3.02351 15.9977 2.53421 16.0022 2.04492 15.9968C1.44033 15.9895 0.994603 15.5547 1.00005 14.9873C1.0055 14.4299 1.44305 14.0033 2.03221 13.9996C3.0108 13.9933 3.99031 13.9933 4.96891 13.9996C5.55443 14.0033 5.99471 14.4363 5.99834 14.9919C6.00106 15.5483 5.56351 15.9868 4.98161 15.9959C4.49232 16.004 4.00302 15.9977 3.51372 15.9977H3.51281Z", fill: color }),
        React.createElement("path", { d: "M26.5271 13.9979C27.0064 13.9979 27.4848 13.9933 27.9642 13.9988C28.5524 14.006 28.9936 14.4291 29 14.9846C29.0072 15.5529 28.5624 15.9923 27.9587 15.9959C26.9901 16.0014 26.0215 16.0014 25.0538 15.9959C24.4465 15.9932 24.0008 15.5611 24.0026 14.9937C24.0044 14.4245 24.4474 14.0033 25.0592 13.9979C25.5485 13.9933 26.0378 13.9979 26.5271 13.9979Z", fill: color }),
        React.createElement("path", { d: "M14.0005 26.4836C14.0005 26.0043 13.9968 25.5259 14.0014 25.0466C14.0077 24.4374 14.4344 23.9953 15.0054 23.9981C15.5718 24.0008 15.9967 24.4465 15.9994 25.0574C16.004 26.0152 16.004 26.9729 15.9994 27.9315C15.9967 28.5452 15.5773 28.9909 15.0108 28.9963C14.4317 29.0018 14.0059 28.5506 14.0014 27.9206C13.9977 27.4413 14.0014 26.9629 14.0014 26.4836H14.0005Z", fill: color }),
        React.createElement("path", { d: "M22.2006 21.0522C22.3304 21.1221 22.6018 21.2038 22.7843 21.3781C23.4987 22.059 24.1904 22.7652 24.8804 23.4715C25.2925 23.8927 25.2916 24.5055 24.8967 24.8967C24.4982 25.2916 23.8963 25.2898 23.4715 24.874C22.7661 24.1832 22.068 23.4842 21.3727 22.7834C21.0558 22.4639 20.976 22.0799 21.1557 21.665C21.3245 21.2756 21.6441 21.0876 22.2006 21.0522Z", fill: color }),
        React.createElement("path", { d: "M5.92565 25.1926C5.41819 25.1753 5.10682 25.0155 4.92527 24.6579C4.73281 24.2793 4.75006 23.8844 5.03783 23.5803C5.77314 22.8024 6.52842 22.0416 7.29913 21.2991C7.66951 20.9423 8.26321 20.9922 8.62995 21.3517C8.99761 21.7112 9.0666 22.3076 8.71166 22.6798C7.96636 23.4632 7.19383 24.2203 6.41222 24.9665C6.26698 25.1054 6.02914 25.1463 5.92474 25.1926H5.92565Z", fill: color }),
        React.createElement("path", { d: "M8.94771 7.82388C8.90867 8.3395 8.73347 8.65269 8.35855 8.82789C7.97274 9.00854 7.58602 8.97586 7.28373 8.68356C6.55023 7.97639 5.83127 7.25288 5.12682 6.51666C4.77187 6.14538 4.81091 5.5535 5.16767 5.18584C5.5326 4.80911 6.14173 4.75918 6.52028 5.1223C7.25649 5.82674 7.97728 6.54843 8.68172 7.28465C8.83423 7.44442 8.88779 7.6986 8.9468 7.82388H8.94771Z", fill: color }))) : (props.timeOfDay === DayIconTimeOfDay.night && (React.createElement(React.Fragment, null,
        React.createElement("path", { d: "M15.2404 3C15.2576 3.01463 15.2722 3.03442 15.292 3.04216C15.5932 3.15403 15.7662 3.37002 15.7868 3.6884C15.8075 4.00162 15.638 4.20814 15.3815 4.37336C14.4109 4.99722 13.602 5.79233 12.9558 6.74834C9.82269 11.3804 11.9473 17.6879 17.2591 19.4502C20.0971 20.3916 22.7345 19.9235 25.1388 18.1405C25.279 18.0364 25.4442 17.9314 25.6103 17.9056C26.1507 17.823 26.5276 18.329 26.3417 18.8702C25.9209 20.0973 25.3246 21.2332 24.5295 22.2589C22.596 24.7535 20.0764 26.305 16.9614 26.8221C14.0546 27.304 11.3148 26.7619 8.80988 25.2113C5.89106 23.4033 4.00054 20.8167 3.28116 17.4478C2.57641 14.1461 3.20028 11.0655 5.11059 8.2852C6.9839 5.55827 9.57315 3.86566 12.8258 3.2134C13.238 3.1308 13.6597 3.09896 14.077 3.04216C14.1527 3.03184 14.2285 3.01463 14.3033 3C14.6157 3 14.928 3 15.2404 3Z", fill: color }),
        React.createElement("path", { d: "M26.9991 10.963C26.9681 11.0146 26.9371 11.0654 26.907 11.117C26.7444 11.3958 26.4268 11.5352 26.1317 11.4561C25.8116 11.37 25.5947 11.0955 25.5947 10.7754C25.5947 10.4562 25.8116 10.1799 26.1317 10.0948C26.4268 10.0156 26.7452 10.155 26.9079 10.4338C26.938 10.4854 26.9689 10.5371 26.9999 10.5887V10.963H26.9991Z", fill: color }),
        React.createElement("path", { d: "M22.7819 7.9738C22.7767 8.37393 22.4755 8.65101 22.0255 8.66909C21.0738 8.70695 20.283 9.32995 20.0463 10.2412C20.0016 10.4133 19.9861 10.594 19.974 10.7721C19.9456 11.1783 19.6574 11.4743 19.2745 11.4778C18.8907 11.4803 18.5912 11.1852 18.5671 10.7816C18.4931 9.52873 17.7066 8.74223 16.4529 8.66736C16.0502 8.64327 15.755 8.34296 15.7576 7.96003C15.7602 7.57797 16.057 7.28454 16.4632 7.26217C17.6584 7.19763 18.5172 6.33369 18.5671 5.14619C18.5852 4.70562 18.8769 4.40186 19.277 4.4053C19.6694 4.40874 19.9551 4.70906 19.974 5.13587C20.0274 6.34315 20.8664 7.18644 22.0917 7.26217C22.4936 7.28712 22.7887 7.59002 22.7836 7.97294L22.7819 7.9738Z", fill: color }),
        React.createElement("path", { d: "M25.5933 5.11855C25.5872 5.49803 25.2663 5.81297 24.8868 5.81039C24.4978 5.80781 24.1777 5.4748 24.1898 5.08671C24.2018 4.70809 24.5288 4.39831 24.9074 4.40691C25.2869 4.41552 25.5993 4.73993 25.5933 5.11941V5.11855Z", fill: color }))))));
};
