import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
import { CompletedWorkout } from './completed_workout';
export var CompletedWorkoutsList = function (_a) {
    var userCustomWorkouts = _a.userCustomWorkouts, taskLessons = _a.taskLessons, stepActivity = _a.stepActivity, onClickCustomWorkout = _a.onClickCustomWorkout, onClickLessonWorkout = _a.onClickLessonWorkout, onClickStepsWorkout = _a.onClickStepsWorkout, classObject = _a.classObject;
    var hasWorkout = taskLessons.length > 0 || stepActivity || (userCustomWorkouts === null || userCustomWorkouts === void 0 ? void 0 : userCustomWorkouts.length) > 0;
    return (React.createElement(React.Fragment, null, hasWorkout ? (React.createElement("div", { className: css(styles.container, classObject) },
        userCustomWorkouts.map(function (userCustomWorkout, idx) { return (React.createElement(CompletedWorkout, { key: idx, userCustomWorkout: userCustomWorkout, onClick: function () {
                onClickCustomWorkout(userCustomWorkout);
            } })); }),
        taskLessons.map(function (taskLesson, idx) { return (React.createElement(CompletedWorkout, { key: idx, taskLesson: taskLesson, onClick: function () {
                onClickLessonWorkout(taskLesson);
            } })); }),
        stepActivity && React.createElement(CompletedWorkout, { stepActivity: stepActivity, onClick: onClickStepsWorkout }))) : (React.createElement("div", { className: css(styles.containerEmpty, classObject) },
        React.createElement("div", { className: css(styles.emptyTitle) }, "\u5B8C\u4E86\u3057\u305F\u30EF\u30FC\u30AF\u30A2\u30A6\u30C8\u304C\u3042\u308A\u307E\u305B\u3093"),
        React.createElement("div", { className: css(styles.emptySubtitle) }, "\u30EF\u30FC\u30AF\u30A2\u30A6\u30C8\u3059\u308B\u3068\u3053\u3053\u306B\u8868\u793A\u3055\u308C\u307E\u3059")))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    containerEmpty: {
        display: 'flex',
        padding: '70px 0px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 10,
    },
    emptyTitle: {
        color: Colors.GrayDarkest,
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: '1.28px',
    },
    emptySubtitle: {
        color: Colors.GrayDarkest,
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: '0.56px',
    },
});
