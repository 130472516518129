var _a, _b, _c;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import SearchKeyword from '@lean-body/components/organisms/search/search_keyword';
import { InputSearch } from '@lean-body/components/molecules';
import { ZIndex } from '@lean-body/components/constants';
export var SearchKeywordBox = function (_a) {
    var keywordValue = _a.keywordValue, searchKeywords = _a.searchKeywords, searchHistories = _a.searchHistories, submitDisabled = _a.submitDisabled, onChangeKeyword = _a.onChangeKeyword, onSelectedKeyword = _a.onSelectedKeyword, submit = _a.submit, clear = _a.clear;
    var _b = React.useState(false), isFocus = _b[0], setIsFocus = _b[1];
    var onFocus = function () {
        setIsFocus(true);
    };
    var onCloseRecommendation = function () {
        setIsFocus(false);
    };
    var onClickClear = function () {
        clear();
    };
    var onClickCancel = function () {
        setIsFocus(false);
    };
    var onClickKeyword = function (v) {
        setIsFocus(false);
        onSelectedKeyword(v);
    };
    var onSubmitKeyword = function (e) {
        setIsFocus(false);
        submit(e);
    };
    return (React.createElement(React.Fragment, null,
        isFocus && React.createElement("div", { className: css(styles.overlay), onClick: onClickCancel }),
        React.createElement("div", { className: css(styles.container) },
            React.createElement(InputSearch, { className: css(styles.inputSearch), keywordValue: keywordValue, onChangeKeyword: onChangeKeyword, onClickClear: onClickClear, submitDisabled: submitDisabled, onSubmit: onSubmitKeyword, onFocus: onFocus, autoFocus: isFocus, "data-test": "inputSearch" }),
            isFocus && (React.createElement("div", { className: css(styles.searchRecommendation), onClick: function (e) { return e.stopPropagation(); } },
                React.createElement(SearchKeyword, { title: "\u4EBA\u6C17\u306E\u30AD\u30FC\u30EF\u30FC\u30C9", keywords: searchKeywords, onClickKeyword: onClickKeyword, onClickCancel: onCloseRecommendation }),
                React.createElement(SearchKeyword, { title: "\u691C\u7D22\u5C65\u6B74", keywords: searchHistories, onClickKeyword: onClickKeyword }))))));
};
var styles = StyleSheet.create({
    container: (_a = {
            position: 'relative',
            margin: '16px auto 0',
            maxWidth: 800
        },
        _a[MediaBreakPointUp.MD] = {
            margin: '38px auto 0',
        },
        _a),
    inputSearch: {
        width: '100%',
    },
    overlay: (_b = {
            position: 'absolute',
            width: '100vw',
            height: '100%',
            top: -16
        },
        _b[MediaBreakPointUp.MD] = {
            top: 0,
            left: -16,
        },
        _b),
    searchRecommendation: (_c = {
            position: 'absolute',
            backgroundColor: Colors.White,
            padding: '8px 16px 32px',
            marginTop: 8,
            borderRadius: 4,
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: ZIndex.SearchBox
        },
        _c[MediaBreakPointUp.MD] = {
            margin: 0,
            padding: '8px 40px 40px',
            maxWidth: 407,
        },
        _c),
    keywordTitle: {
        margin: 18,
    },
});
