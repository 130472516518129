var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, drawerWidth, MediaBreakPointUp } from '../styles';
export var InitialGuideQA = function (_a) {
    var question = _a.question, children = _a.children;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.question) }, question),
        React.createElement("div", { className: css(styles.answer) }, children)));
};
var styles = StyleSheet.create({
    question: (_a = {
            position: 'relative',
            textAlign: 'center',
            color: Colors.White,
            backgroundColor: Colors.PrimaryDark,
            width: '100%',
            padding: '14px 6px 0',
            marginTop: 30,
            marginBottom: 46,
            borderRadius: 4,
            '::after': {
                content: "''",
                borderStyle: 'solid',
                borderWidth: '32px calc(50vw - 24px) 0',
                borderColor: "".concat(Colors.PrimaryDark, " transparent transparent transparent"),
                position: 'absolute',
                top: '97%',
                left: 0,
                right: 0,
            }
        },
        _a[MediaBreakPointUp.MD] = {
            '::after': {
                borderWidth: "32px calc(50vw - 40px - (".concat(drawerWidth.md, "px / 2)) 0"),
            },
        },
        _a[MediaBreakPointUp.XL] = {
            '::after': {
                borderWidth: "32px 308px 0",
            },
        },
        _a),
    answer: {
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 30,
        lineHeight: '160%',
        letterSpacing: '0.56px',
    },
});
