import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
import { ProgressBar } from '../atoms';
export var NutrientItem = function (_a) {
    var _b;
    var classObject = _a.classObject, nameWithValueClassObject = _a.nameWithValueClassObject, nutrient = _a.nutrient, isSnackingType = _a.isSnackingType, isEntered = _a.isEntered, isShowMessage = _a.isShowMessage;
    var minReferenceValue = nutrient.minReferenceValue.toLocaleString();
    var maxReferenceValue = (_b = nutrient.maxReferenceValue) === null || _b === void 0 ? void 0 : _b.toLocaleString();
    var currentValue = isEntered ? nutrient.currentValue.toLocaleString() : '-';
    var progressMax = nutrient.maxValue || nutrient.minReferenceValue;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.nameWithValue, nameWithValueClassObject) },
            React.createElement("div", { className: css(styles.name) }, nutrient.name),
            React.createElement("div", { className: css(styles.value) }, isSnackingType ? (React.createElement("p", { className: css(styles.currentValue) },
                currentValue,
                nutrient.unit)) : (React.createElement(React.Fragment, null,
                isEntered && (React.createElement("span", { className: css(styles.label, styles[nutrient.statusKey]) }, nutrient.statusText)),
                React.createElement("p", { className: css(styles.currentValue) }, currentValue),
                React.createElement("p", { className: css(styles.valueRange) },
                    "\u00A0/ ",
                    minReferenceValue != '0' && minReferenceValue,
                    minReferenceValue != '0' && maxReferenceValue && ' - ',
                    maxReferenceValue,
                    nutrient.unit))))),
        React.createElement(ProgressBar, { max: progressMax, threshold: nutrient.maxReferenceValue, current: isEntered ? nutrient.currentValue : 0, color: Colors.ProgressGreen, trackColor: Colors.BackgroundGrayD }),
        isShowMessage && React.createElement("div", { className: css(styles.message) }, nutrient.message)));
};
var styles = StyleSheet.create({
    container: {
        width: '100%',
    },
    nameWithValue: {
        height: 44,
        marginBottom: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    wideSize: {
        height: 22,
        alignItems: 'center',
    },
    name: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    value: {
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        fontSize: 10,
        fontWeight: 'bold',
        border: '1px solid',
        padding: '2px 2px 3px',
        marginRight: 4,
        borderRadius: 4,
        lineHeight: 1,
    },
    lack: {
        color: Colors.ProgressBlue,
        borderColor: Colors.ProgressBlue,
    },
    appropriate: {
        color: Colors.ProgressGreen,
        borderColor: Colors.ProgressGreen,
    },
    excess: {
        color: Colors.Orange,
        borderColor: Colors.Orange,
    },
    currentValue: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    valueRange: {
        fontSize: 10,
        fontWeight: 500,
    },
    message: {
        fontSize: 12,
        fontWeight: 500,
        color: Colors.GrayDarkest,
        marginTop: 4,
    },
});
