var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { PageLoading, MyLessons, DETAILS_QUERY_PARAMS, MyLessonDetails } from '../organisms';
import { UserChallengeStatus } from '@lean-body/src/interfaces';
import { useQuery } from '@lean-body/src/util';
import { MediaBreakPointUp } from '../styles';
var LESSON_HISTORY_FIRST_PAGE = 0;
var FAVORITE_LESSON_FIRST_PAGE = 1;
export var DISPLAY_LESSON_PER_PAGE = 20;
export var MainMyLesson = function (_a) {
    var apiClient = _a.apiClient, tracker = _a.tracker;
    var query = useQuery();
    var selectedDetails = query.get(DETAILS_QUERY_PARAMS) || '';
    var isSelectDetails = selectedDetails !== '';
    var _b = React.useState([]), subscribedLiveLesson = _b[0], setSubscribedLiveLesson = _b[1];
    var _c = React.useState([]), challengingPlaylist = _c[0], setChallengingPlaylist = _c[1];
    var _d = React.useState([]), lessonHistories = _d[0], setLessonHistories = _d[1];
    var _e = React.useState([]), lessons = _e[0], setLessons = _e[1];
    var _f = React.useState([]), programs = _f[0], setPrograms = _f[1];
    var _g = React.useState([]), playlists = _g[0], setPlaylists = _g[1];
    var _h = React.useState([null]), originalPlaylists = _h[0], setOriginalPlaylists = _h[1];
    var _j = React.useState(LESSON_HISTORY_FIRST_PAGE), lessonHistoryPage = _j[0], setLessonHistoryPage = _j[1];
    var _k = React.useState(FAVORITE_LESSON_FIRST_PAGE), favoriteLessonPage = _k[0], setFavoriteLessonPage = _k[1];
    var _l = React.useState(true), isLoadingSubscribedLiveLesson = _l[0], setIsLoadingSubscribedLiveLesson = _l[1];
    var _m = React.useState(true), isLoadingChallengingPlaylist = _m[0], setIsLoadingChallengingPlaylist = _m[1];
    var _o = React.useState(true), isLoadingLessonHistories = _o[0], setIsLoadingLessonHistories = _o[1];
    var _p = React.useState(true), isLoadingLessons = _p[0], setIsLoadingLessons = _p[1];
    var _q = React.useState(true), isLoadingProgramsAndPlaylists = _q[0], setIsLoadingProgramsAndPlaylists = _q[1];
    var _r = React.useState(true), isLoadingOriginalPlaylists = _r[0], setIsLoadingOriginalPlaylists = _r[1];
    var isLoading = function () {
        if (isSelectDetails) {
            switch (selectedDetails) {
                case MyLessonDetails.subscribedLiveLesson:
                    return isLoadingSubscribedLiveLesson;
                case MyLessonDetails.challengingPlaylist:
                    return isLoadingChallengingPlaylist;
                case MyLessonDetails.lessonHistory:
                    return isLoadingLessonHistories;
                case MyLessonDetails.lesson:
                    return isLoadingLessons;
                case MyLessonDetails.program:
                case MyLessonDetails.playlist:
                    return isLoadingProgramsAndPlaylists;
                case MyLessonDetails.originalPlaylist:
                    return isLoadingChallengingPlaylist;
            }
        }
        else {
            return (isLoadingSubscribedLiveLesson ||
                isLoadingChallengingPlaylist ||
                isLoadingLessonHistories ||
                isLoadingLessons ||
                isLoadingProgramsAndPlaylists ||
                isLoadingOriginalPlaylists);
        }
    };
    React.useEffect(function () {
        // 参加予定のLIVEレッスン
        if (!isSelectDetails || selectedDetails === MyLessonDetails.subscribedLiveLesson) {
            setIsLoadingSubscribedLiveLesson(true);
            if (subscribedLiveLesson.length > 0) {
                setIsLoadingSubscribedLiveLesson(false);
            }
            else {
                apiClient
                    .fetchSubscribedLiveLesson()
                    .then(function (fetchSubscribedLiveLesson) {
                    setSubscribedLiveLesson(fetchSubscribedLiveLesson);
                })
                    .finally(function () {
                    setIsLoadingSubscribedLiveLesson(false);
                });
            }
        }
        // チャレンジ中のプレイリスト
        if (!isSelectDetails || selectedDetails === MyLessonDetails.challengingPlaylist) {
            setIsLoadingChallengingPlaylist(true);
            if (challengingPlaylist.length > 0) {
                setIsLoadingChallengingPlaylist(false);
            }
            else {
                apiClient
                    .fetchUserChallengesByChallengeStatus(UserChallengeStatus.ChallengeInProgress)
                    .then(function (fetchChallengingPlaylist) {
                    setChallengingPlaylist(fetchChallengingPlaylist);
                })
                    .finally(function () {
                    setIsLoadingChallengingPlaylist(false);
                });
            }
        }
        // 視聴履歴
        if (!isSelectDetails || selectedDetails === MyLessonDetails.lessonHistory) {
            setIsLoadingLessonHistories(true);
            if (lessonHistories.length > 0) {
                setIsLoadingLessonHistories(false);
            }
            else {
                apiClient
                    .fetchLessonSession(LESSON_HISTORY_FIRST_PAGE)
                    .then(function (histories) {
                    setLessonHistories(histories);
                })
                    .finally(function () {
                    setIsLoadingLessonHistories(false);
                });
            }
        }
        // お気に入りのレッスン
        if (!isSelectDetails || selectedDetails === MyLessonDetails.lesson) {
            setIsLoadingLessons(true);
            if (lessons.length > 0) {
                setIsLoadingLessons(false);
            }
            else {
                apiClient
                    .fetchFavoriteLessons(FAVORITE_LESSON_FIRST_PAGE)
                    .then(function (fetchLessons) {
                    setLessons(fetchLessons);
                })
                    .finally(function () {
                    setIsLoadingLessons(false);
                });
            }
        }
        // お気に入りのシリーズ、お気に入りのプレイリスト
        if (!isSelectDetails ||
            selectedDetails === MyLessonDetails.program ||
            selectedDetails === MyLessonDetails.playlist) {
            setIsLoadingProgramsAndPlaylists(true);
            if (programs.length > 0 && playlists.length > 0) {
                setIsLoadingProgramsAndPlaylists(false);
            }
            else {
                apiClient
                    .fetchFavoritePrograms()
                    .then(function (fetchedPrograms) {
                    setPrograms(fetchedPrograms.filter(function (v) { return !v.isPlaylist; }));
                    setPlaylists(fetchedPrograms.filter(function (v) { return v.isPlaylist; }));
                })
                    .finally(function () {
                    setIsLoadingProgramsAndPlaylists(false);
                });
            }
        }
        // オリジナルプレイリスト
        if (!isSelectDetails || selectedDetails === MyLessonDetails.originalPlaylist) {
            setIsLoadingOriginalPlaylists(true);
            // 作成用のカードをデフォルトで追加するので未取得の場合でも1になる
            if (subscribedLiveLesson.length > 1) {
                setIsLoadingOriginalPlaylists(false);
            }
            else {
                apiClient
                    .getOriginalPlaylists()
                    .then(function (fetchOriginalPlaylists) {
                    setOriginalPlaylists(fetchOriginalPlaylists.concat(null));
                })
                    .finally(function () {
                    setIsLoadingOriginalPlaylists(false);
                });
            }
        }
    }, [selectedDetails]);
    var onClickLessonHistoryNext = function () { return __awaiter(void 0, void 0, void 0, function () {
        var nextLessonHistoryPage, histories;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    nextLessonHistoryPage = lessonHistoryPage + 1;
                    return [4 /*yield*/, apiClient.fetchLessonSession(nextLessonHistoryPage)];
                case 1:
                    histories = _a.sent();
                    setLessonHistories(lessonHistories.concat(histories));
                    setLessonHistoryPage(nextLessonHistoryPage);
                    return [2 /*return*/];
            }
        });
    }); };
    var onClickLessonNext = function () { return __awaiter(void 0, void 0, void 0, function () {
        var nextFavoriteLessonPage, fetchLessons;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    nextFavoriteLessonPage = favoriteLessonPage + 1;
                    return [4 /*yield*/, apiClient.fetchFavoriteLessons(nextFavoriteLessonPage)];
                case 1:
                    fetchLessons = _a.sent();
                    setLessons(lessons.concat(fetchLessons));
                    setFavoriteLessonPage(nextFavoriteLessonPage);
                    return [2 /*return*/];
            }
        });
    }); };
    if (isLoading()) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(styles.container, isSelectDetails && styles.detail) },
        React.createElement(MyLessons, { details: selectedDetails, subscribedLiveLessons: subscribedLiveLesson, challengingPlaylists: challengingPlaylist, histories: lessonHistories, lessons: lessons, seriesPrograms: programs, playlists: playlists, originalPlaylists: originalPlaylists, onClickLessonHistoryNext: onClickLessonHistoryNext, onClickLessonNext: onClickLessonNext, apiClient: apiClient, tracker: tracker })));
};
var styles = StyleSheet.create({
    container: (_a = {
            padding: '17px 0 28px 20px'
        },
        _a[MediaBreakPointUp.MD] = {
            padding: '30px 48px 50px',
        },
        _a[MediaBreakPointUp.XL] = {
            padding: '50px 78px 80px',
        },
        _a),
    detail: (_b = {
            paddingRight: 20
        },
        _b[MediaBreakPointUp.MD] = {
            padding: '0px 48px 50px',
        },
        _b[MediaBreakPointUp.XL] = {
            padding: '0px 12px 80px',
        },
        _b),
});
