var Tag = /** @class */ (function () {
    function Tag(data) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "thumbnailSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "slug", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.thumbnailSrc = data.thumbnailSrc;
    }
    Object.defineProperty(Tag.prototype, "thumbnailURL", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (config) {
            return config.cdnBaseURL + this.thumbnailSrc;
        }
    });
    return Tag;
}());
export { Tag };
