import * as dayjs from 'dayjs';
import { Lesson, LiveLesson } from '.';
var LessonSession = /** @class */ (function () {
    function LessonSession(sessionData, config) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "liveLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "badges", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "program", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "completed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dedupedplayDuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playDurationRatio", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playDuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = sessionData.id;
        this.lesson = sessionData.lesson ? new Lesson(sessionData.lesson, config) : null;
        this.liveLesson = sessionData.liveLesson ? new LiveLesson(sessionData.liveLesson, config) : null;
        this.program = sessionData.program;
        this.createdAt = sessionData.createdAt;
        this.completed = sessionData.completed;
        this.dedupedplayDuration = sessionData.dedupedplayDuration;
        this.playDurationRatio = sessionData.playDurationRatio;
        this.playDuration = sessionData.playDuration;
    }
    return LessonSession;
}());
export { LessonSession };
var UserLessonSessionGroupByDaily = /** @class */ (function () {
    function UserLessonSessionGroupByDaily(v) {
        Object.defineProperty(this, "playDuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "targetDateAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.playDuration = v.playDuration;
        this.targetDateAt = dayjs(v.targetDateString);
    }
    Object.defineProperty(UserLessonSessionGroupByDaily.prototype, "playDurationMinute", {
        get: function () {
            return Math.round(this.playDuration / 60);
        },
        enumerable: false,
        configurable: true
    });
    return UserLessonSessionGroupByDaily;
}());
export { UserLessonSessionGroupByDaily };
