var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MediaBreakPointUp, MediaBreakPointDown, Colors } from '@lean-body/components/styles';
import { Button, ButtonType } from '@lean-body/components/atoms';
import { Dialog } from '@lean-body/components/molecules';
export var CoursePage = function (_a) {
    var onClickConfirm = _a.onClickConfirm, onClickCancel = _a.onClickCancel;
    return (React.createElement("div", { className: css(styles.dialogCourseChangeContainer) },
        React.createElement(Dialog, { classObject: styles.content, onClickCancel: onClickCancel, content: React.createElement("div", { className: css(styles.dialogCourseChangeContainer) },
                React.createElement("div", { className: css(styles.dialogCourseChangeTitle) }, "\u30B3\u30FC\u30B9\u3092\u5909\u66F4\u3057\u307E\u3059\u304B\uFF1F"),
                React.createElement("div", { className: css(styles.dialogCourseChangeDiscription) },
                    "\u73FE\u5728\u8A2D\u5B9A\u4E2D\u306E\u30B3\u30FC\u30B9\u5C65\u6B74\u306F\u5931\u308F\u308C\u307E\u3059\u3002",
                    React.createElement("br", null),
                    "\u30B3\u30FC\u30B9\u306F\u7D99\u7D9A\u3059\u308B\u3053\u3068\u3067\u52B9\u679C\u304C\u8868\u308C\u307E\u3059\u3002"),
                React.createElement("div", { className: css(styles.dialogCourseChangeButtonsWrapper) },
                    React.createElement(Button, { className: css(styles.dialogCourseChangeButton), isRadius: true, buttonType: ButtonType.secondary, onClick: function () { return onClickCancel; } }, "\u30AD\u30E3\u30F3\u30BB\u30EB"),
                    React.createElement(Button, { className: css(styles.dialogCourseChangeButton), isRadius: true, buttonType: ButtonType.primary, onClick: onClickConfirm }, "\u5909\u66F4\u3059\u308B"))) })));
};
var styles = StyleSheet.create({
    content: (_a = {
            width: 327,
            transform: 'translateX(-50%)',
            borderRadius: 8
        },
        _a[MediaBreakPointDown.SM] = {
            top: '30%',
        },
        _a[MediaBreakPointUp.MD] = {
            top: '30%',
            width: 375,
            borderRadius: '8px',
        },
        _a),
    dialogCourseChangeContainer: {
        background: Colors.White,
        padding: 20,
        borderRadius: 14,
        textAlign: 'center',
    },
    dialogCourseChangeTitle: {
        fontWeight: 700,
        fontSize: 18,
        marginBottom: 8,
    },
    dialogCourseChangeDiscription: {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '0.04em',
    },
    dialogCourseChangeButtonsWrapper: {
        marginTop: 16,
        display: 'flex',
        gap: 10,
        justifyContent: 'center',
    },
    dialogCourseChangeButton: {
        fontWeight: 700,
        fontSize: 16,
        letterSpacing: '0.08em',
        padding: '15px 29px',
    },
});
