import { CourseDay } from '.';
var CourseVolume = /** @class */ (function () {
    function CourseVolume(data, config) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "courseID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "title", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isCompleted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalDays", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "finishedDays", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "restOfDays", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "volumeNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "minDuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "maxDuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "completedCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "days", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.courseID = data.courseId;
        this.title = data.title;
        this.isCompleted = data.isCompleted;
        this.totalDays = data.totalDays;
        this.finishedDays = data.finishedDays;
        this.restOfDays = data.restOfDays;
        this.volumeNumber = data.volumeNumber;
        this.minDuration = data.minDuration;
        this.maxDuration = data.maxDuration;
        this.completedCount = data.completedCount;
        this.days = data.days ? data.days.map(function (o) { return new CourseDay(o, config); }) : [];
    }
    Object.defineProperty(CourseVolume.prototype, "currentDay", {
        get: function () {
            return this.days[this.finishedDays];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CourseVolume.prototype, "isFirstCompleted", {
        get: function () {
            return this.completedCount >= 1;
        },
        enumerable: false,
        configurable: true
    });
    return CourseVolume;
}());
export { CourseVolume };
