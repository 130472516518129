import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var URLIcon = function (props) {
    var classObject = props.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 13,
            height: 14,
        },
    });
    return (React.createElement("svg", { className: css(styles.icon, classObject), fill: "none", viewBox: "0 0 13 14", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" },
        React.createElement("clipPath", { id: "a" },
            React.createElement("path", { d: "m0 .5h13v13h-13z" })),
        React.createElement("clipPath", { id: "b" },
            React.createElement("path", { d: "m0 .5h13v13h-13z" })),
        React.createElement("g", { "clip-path": "url(#a)" },
            React.createElement("g", { "clip-path": "url(#b)", fill: "#3b434b" },
                React.createElement("path", { "clip-rule": "evenodd", d: "m3.31827 5.96244c.24159.24158.24159.63327 0 .87485l-1.3905 1.39051c-.92.92-.92159 2.4181.00049 3.3449.92007.9196 2.41785.921 3.34451-.001.00014-.0002.00029-.0003.00043-.0005l1.38951-1.3895c.24158-.24156.63327-.24156.87485 0 .24159.2416.24159.6333 0 .8749l-1.39051 1.3905-.00099.001c-1.40841 1.402-3.68872 1.4034-5.09315-.001l-.001-.001c-1.401999-1.4084-1.403429-3.68873.001-5.09315l1.39051-1.39051c.24158-.24159.63327-.24159.87485 0z", "fill-rule": "evenodd" }),
                React.createElement("path", { "clip-rule": "evenodd", d: "m6.85394 1.55191c1.40841-1.401999 3.68876-1.403429 5.09316.001l.001.001c1.402 1.4084 1.4034 3.68872-.001 5.09314l-1.3905 1.39051c-.2416.24159-.63329.24159-.87487 0-.24159-.24158-.24159-.63327 0-.87485l1.39047-1.39051c.92-.92001.9216-2.41814-.0005-3.34498-.9201-.91947-2.4178-.92086-3.34445.0011-.00015.00014-.0003.00029-.00045.00044l-1.38951 1.38951c-.24158.24159-.63327.24159-.87485 0-.24159-.24158-.24159-.63327 0-.87485z", "fill-rule": "evenodd" }),
                React.createElement("path", { d: "m8.72259 5.83499-2.98012 2.97726c-.29229.292-.76223.292-1.05451 0s-.29228-.76149 0-1.05349l2.98012-2.97726c.29228-.292.76223-.292 1.05451 0 .28655.292.28655.76722 0 1.05349z" })))));
};
