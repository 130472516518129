var _a, _b, _c, _d;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles/variables';
import { Button, ButtonType } from '@lean-body/components/atoms/button';
import { ZIndex } from '../constants';
import { drawerWidth, settingMenuWidth } from '@lean-body/src/view/styles/utils';
import { FIXED_FOOTER_HEIGHT } from '@lean-body/components/organisms/fixed_footer';
export var FixedFooterUpdateButton = function (_a) {
    var onSaveClick = _a.onSaveClick, onCancelClick = _a.onCancelClick, className = _a.className, disabeled = _a.disabeled;
    return (React.createElement("div", { className: [css(styles.wrapper), className].join(' ') },
        React.createElement("div", { className: css(styles.buttonBox) },
            React.createElement(Button, { className: css(styles.cancelButton), onClick: onCancelClick, buttonType: ButtonType.whiteWithBlack, dataTest: "cancel" }, "\u30AD\u30E3\u30F3\u30BB\u30EB"),
            React.createElement(Button, { className: css(styles.saveButton), onClick: onSaveClick, buttonType: disabeled ? ButtonType.transparentPrimary : ButtonType.primary, dataTest: "save", disabled: disabeled }, "\u4FDD\u5B58"))));
};
var styles = StyleSheet.create({
    wrapper: (_a = {
            position: 'fixed',
            bottom: FIXED_FOOTER_HEIGHT,
            left: 0,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            margin: 0,
            padding: '14px 16px',
            borderRadius: 0,
            backgroundColor: Colors.White,
            borderTop: "1px solid ".concat(Colors.BorderLightGray)
        },
        _a[MediaBreakPointUp.MD] = {
            justifyContent: 'flex-end',
            left: drawerWidth.md,
            bottom: 0,
            width: "calc(100vw - ".concat(drawerWidth.md, "px)"),
            background: "linear-gradient(180deg, ".concat(Colors.White, " 0%, ").concat(Colors.White, " 100%), ").concat(Colors.White),
            zIndex: ZIndex.MypageFooter,
            opacity: 0.9,
        },
        _a[MediaBreakPointUp.XL] = {
            left: "calc(".concat(drawerWidth.xl, "px + ").concat(settingMenuWidth.xl, "px)"),
            width: "calc(100vw - ".concat(drawerWidth.xl, "px - ").concat(settingMenuWidth.xl, "px)"),
        },
        _a),
    buttonBox: (_b = {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%'
        },
        _b[MediaBreakPointUp.MD] = {
            width: 'auto',
            margin: '0 15px 0 20px',
        },
        _b),
    cancelButton: (_c = {
            width: 'calc((100% - 10px) / 2)'
        },
        _c[MediaBreakPointUp.MD] = {
            width: 121,
            height: 50,
            padding: 0,
        },
        _c),
    saveButton: (_d = {
            width: 'calc((100% - 10px) / 2)'
        },
        _d[MediaBreakPointUp.MD] = {
            width: 77,
            height: 50,
            padding: 0,
            marginLeft: 10,
        },
        _d),
});
