import * as dayjs from 'dayjs';
var DEFAULT_AMAZON_GIFT_PRICE_OLD = 1000;
var CAMPAIGM_AMAZON_GIFT_PRICE_OLD = 3000;
var DEFAULT_AMAZON_GIFT_PRICE = 1000;
var CAMPAIGM_AMAZON_GIFT_PRICE = 2000;
var AMAZON_GIFT_SWITCH_DATE = 1724166000; // 2024-08-21 00:00
var FriendInvitationCampaign = /** @class */ (function () {
    function FriendInvitationCampaign(data) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "startAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "endAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amazonGiftEndAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.startAt = data.startAt;
        this.endAt = data.endAt;
        this.amazonGiftEndAt = data.amazonGiftEndAt;
    }
    Object.defineProperty(FriendInvitationCampaign.prototype, "isHolding", {
        get: function () {
            var now = dayjs().unix();
            return this.startAt <= now && now < this.endAt;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FriendInvitationCampaign.prototype, "amazonGiftPrice", {
        get: function () {
            // 友達招待の旧価格 8/28以降に分岐を消してok
            if (dayjs().unix() < AMAZON_GIFT_SWITCH_DATE) {
                return this.isHolding ? CAMPAIGM_AMAZON_GIFT_PRICE_OLD : DEFAULT_AMAZON_GIFT_PRICE_OLD;
            }
            return this.isHolding ? CAMPAIGM_AMAZON_GIFT_PRICE : DEFAULT_AMAZON_GIFT_PRICE;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FriendInvitationCampaign.prototype, "endAtStr", {
        get: function () {
            return dayjs.unix(this.endAt).format('M/D');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FriendInvitationCampaign.prototype, "periodStr", {
        get: function () {
            return "".concat(dayjs.unix(this.startAt).format('M/D'), " ~ ").concat(dayjs.unix(this.endAt).format('M/D'));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FriendInvitationCampaign.prototype, "amazonGiftCampaignIsAvailable", {
        get: function () {
            return this.amazonGiftEndAt > dayjs().unix();
        },
        enumerable: false,
        configurable: true
    });
    return FriendInvitationCampaign;
}());
export { FriendInvitationCampaign };
