var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles/variables';
import { FavoriteWithCount, TwitterWithText } from '@lean-body/components/molecules';
export var PlaylistInfo = function (_a) {
    var playlist = _a.playlist, totalFavoriteCount = _a.totalFavoriteCount, creator = _a.creator, isFavorited = _a.isFavorited, numOfLessons = _a.numOfLessons, programTotalMinCal = _a.programTotalMinCal, programTotalMaxCal = _a.programTotalMaxCal, updatedDiff = _a.updatedDiff, onClickFavorite = _a.onClickFavorite, className = _a.className, isOriginalPlaylist = _a.isOriginalPlaylist, twitterService = _a.twitterService, onClickTwitterShareIfAuthed = _a.onClickTwitterShareIfAuthed;
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var toggleText = function () { return setIsOpen(!isOpen); };
    var wrapperClass = [className || '', css(styles.wrapper)].join(' ');
    var playlistName = playlist.name;
    var playlistDescription = playlist.description;
    var totalDuration = playlist.totalDuration;
    var descriptionClass;
    if (isOpen) {
        descriptionClass = css(styles.description);
    }
    else {
        descriptionClass = [css(styles.description), css(styles.omitDescription)].join(' ');
    }
    return (React.createElement("div", { className: wrapperClass },
        React.createElement("div", { className: css(styles.playlistTitle) }, playlistName),
        React.createElement("div", { className: css(styles.playlistCreator) },
            React.createElement("span", { className: css(styles.creatorImgContainer) },
                React.createElement("img", { src: creator.iconSrc, height: 22 })),
            React.createElement("span", { className: css(styles.creatorName) }, creator.name)),
        React.createElement("div", { className: css(styles.calInfo) },
            programTotalMinCal,
            " - ",
            programTotalMaxCal,
            " kcal"),
        React.createElement("div", { className: css(styles.labelInfo) },
            numOfLessons,
            "\u30EC\u30C3\u30B9\u30F3",
            totalDuration && "\u30FB".concat(totalDuration),
            updatedDiff && "\u30FB".concat(updatedDiff, "\u66F4\u65B0")),
        !isOriginalPlaylist && (React.createElement("div", { className: css(styles.userActionRow) },
            React.createElement(FavoriteWithCount, { favCount: totalFavoriteCount, onClick: onClickFavorite, isStoppedFav: !isFavorited }),
            React.createElement(TwitterWithText, { text: "\u30B7\u30A7\u30A2\u3059\u308B", twitterService: twitterService, onClickTwitterShareIfAuthed: onClickTwitterShareIfAuthed, playlist: playlist }))),
        playlistDescription !== '' && (React.createElement("div", { onClick: toggleText },
            React.createElement("div", { className: descriptionClass },
                React.createElement("span", null, playlistDescription)),
            React.createElement("div", { className: css(styles.toggleText) }, isOpen ? '閉じる' : 'すべて見る')))));
};
var styles = StyleSheet.create({
    wrapper: (_a = {
            padding: '0 32px'
        },
        _a[MediaBreakPointUp.MD] = {
            padding: '0 100px',
        },
        _a),
    playlistTitle: {
        fontWeight: 'bold',
        fontSize: 18,
    },
    playlistCreator: {
        display: 'flex',
        alignItems: 'center',
        margin: '12px 0',
    },
    creatorImgContainer: {
        width: 22,
        height: 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.White,
        borderRadius: '15px',
        border: "1px solid ".concat(Colors.BorderLightGrayB),
        overflow: 'hidden',
    },
    outlinedHeart: {
        width: 17,
    },
    creatorName: {
        marginLeft: 8,
        fontSize: 11,
        fontWeight: 'bold',
    },
    userActionRow: {
        display: 'flex',
        gap: 15,
    },
    description: {
        position: 'relative',
        marginTop: 8,
        fontSize: 12,
        letterSpacing: '1px',
        lineHeight: 1.7,
    },
    omitDescription: {
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '3',
        overflow: 'hidden',
        ':before': {
            content: "' '",
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0) 52%, rgb(242, 246, 247))',
        },
    },
    toggleText: {
        marginTop: 12,
        color: Colors.GrayDarkest,
        fontSize: 11,
        textAlign: 'center',
        textDecoration: 'underline',
    },
    calInfo: {
        fontSize: 12,
        fontWeight: 'bold',
        margin: '8px 0 6px',
    },
    labelInfo: {
        fontSize: 11,
        marginBottom: 8,
        color: Colors.GrayDarkest,
    },
});
