var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import { AspectRatioLayout } from '../layouts';
var LoadingIndicator = /** @class */ (function (_super) {
    __extends(LoadingIndicator, _super);
    function LoadingIndicator() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(LoadingIndicator.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement("div", { className: this.containerClass },
                React.createElement(AspectRatioLayout, { whRatio: 1 },
                    React.createElement("div", { className: css(this.styles.arcWrapper) },
                        React.createElement("div", { className: this.arcClass })))));
        }
    });
    Object.defineProperty(LoadingIndicator.prototype, "containerClass", {
        get: function () {
            return [this.props.className || '', css(this.styles.container)].join(' ');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LoadingIndicator.prototype, "arcClass", {
        get: function () {
            return [css(this.styles.arc), this.props.isWhite ? css(this.styles.arcWhite) : ''].join(' ');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LoadingIndicator.prototype, "rotateKeyFrames", {
        get: function () {
            return {
                '0%': {
                    transform: 'rotate(0)',
                },
                '100%': {
                    transform: 'rotate(360deg)',
                },
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LoadingIndicator.prototype, "styles", {
        get: function () {
            return StyleSheet.create({
                container: {
                    display: 'inline-block',
                    width: this.props.size,
                },
                arcWrapper: {
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    transform: "translate(-50%, -50%) scale(1) translate(50%, 50%)",
                },
                arc: {
                    position: 'absolute',
                    width: '80%',
                    height: '80%',
                    top: '20%',
                    left: '20%',
                    borderRadius: '50%',
                    border: "1px solid #000",
                    borderColor: "#65C3CA transparent #65C3CA transparent",
                    animationName: 'arc',
                    animationDuration: '1s',
                    animationTimingFunction: 'linear',
                    animationIterationCount: 'infinite',
                },
                arcWhite: {
                    borderColor: "#ffffff transparent #ffffff transparent",
                },
                '@keyframes arc': this.rotateKeyFrames,
            });
        },
        enumerable: false,
        configurable: true
    });
    return LoadingIndicator;
}(React.Component));
export { LoadingIndicator };
