import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var StepsCaloriesIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 18,
            height: 18,
            padding: '1px 2.2px 1px 3px',
        },
    });
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 13 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_28920_27656)" },
            React.createElement("path", { d: "M5.95375 16.5009C5.67139 16.46 5.38819 16.4246 5.10711 16.377C2.68752 15.9654 0.728037 14.1325 0.195218 11.7687C-0.303583 9.55513 0.159073 7.53818 1.51897 5.71448C1.90169 5.20106 2.55485 5.23028 2.90141 5.77C2.97285 5.88145 3.05833 5.95533 3.19993 5.92152C3.34578 5.88688 3.39511 5.78169 3.40914 5.64394C3.52651 4.50314 3.77825 3.3974 4.29448 2.3597C4.57514 1.79536 4.92171 1.274 5.33333 0.794392C5.55998 0.530168 5.85552 0.439588 6.19231 0.54102C6.52101 0.639948 6.723 0.864101 6.75319 1.20638C6.81613 1.92142 7.13208 2.49411 7.71337 2.93115C8.64166 3.62907 9.13366 4.57619 9.33309 5.68819C9.34245 5.73953 9.34925 5.79212 9.36669 5.84054C9.43217 6.02421 9.67711 6.0672 9.80468 5.91776C9.83147 5.88646 9.85018 5.84806 9.87187 5.81216C10.221 5.22611 10.8861 5.19104 11.3007 5.73786C12.0958 6.78683 12.581 7.95851 12.7422 9.2571C12.9072 10.5857 12.7299 11.8664 12.129 13.0719C11.2237 14.888 9.72729 15.9625 7.72358 16.3715C7.43442 16.4304 7.13846 16.4584 6.84589 16.5005H5.9546L5.95375 16.5009ZM6.53292 14.0578C6.70514 14.0357 6.91776 14.0227 7.124 13.9798C8.95804 13.5991 9.84167 11.5162 8.92572 9.88072C8.67781 9.43784 8.07397 9.39443 7.74569 9.7843C7.6649 9.8803 7.59388 9.98633 7.50416 10.0736C7.10018 10.4689 6.55376 10.4133 6.24759 9.93916C6.16764 9.81518 6.09833 9.6645 6.08812 9.52132C6.06856 9.24917 6.07324 8.97117 6.10684 8.70068C6.15361 8.32292 6.02519 8.02655 5.70711 7.88004C5.39031 7.73436 5.08074 7.81033 4.82603 8.09668C3.9679 9.06091 3.54607 10.1788 3.6005 11.4623C3.62134 11.9532 3.73275 12.424 4.02106 12.8339C4.61681 13.6809 5.48684 14.0019 6.53335 14.0578H6.53292Z", fill: "#C5CBD1" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_28920_27656" },
                React.createElement("rect", { width: "12.8", height: "16", fill: "white", transform: "translate(0 0.500977)" })))));
};
