import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
export var CommentIcon = function (props) {
    var styles = StyleSheet.create({
        container: {
            width: 41,
            height: 38,
        },
    });
    return (React.createElement("svg", { className: css(styles.container, props.classObject), viewBox: "0 0 41 38", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M40.5 34.3659V34.8371C40.3133 35.4436 39.879 35.7632 39.2824 35.8534C37.9592 36.0623 36.6401 36.3328 35.3089 36.4352C32.7072 36.6319 30.1624 36.3246 27.784 35.1486C27.3619 34.9396 27.0494 34.9601 26.6394 35.1649C22.3291 37.3531 17.832 37.7752 13.2253 36.3615C4.08913 33.5587 -1.23998 24.0438 1.01667 14.6807C3.40726 4.77247 13.55 -1.32487 23.2463 1.51891C30.5358 3.6579 34.9152 8.68575 36.2383 16.2378C36.973 20.4215 36.1531 24.4167 34.0304 28.1169C33.9289 28.2931 33.8315 28.4775 33.73 28.6578C33.8396 28.7767 33.9289 28.8709 34.0182 28.9652C35.662 30.7108 37.5655 32.1286 39.5908 33.3866C39.9886 33.6324 40.3336 33.907 40.4959 34.3659H40.5ZM18.4652 24.7077C21.014 24.7077 23.5629 24.7077 26.1159 24.7077C26.2985 24.7077 26.4811 24.7077 26.6597 24.6831C27.4309 24.5724 27.8855 23.7775 27.577 23.0604C27.3254 22.4744 26.8302 22.3146 26.2376 22.3146C21.0749 22.3187 15.9082 22.3146 10.7454 22.3187C10.579 22.3187 10.4045 22.3269 10.2422 22.3597C9.66989 22.4785 9.30054 22.958 9.31272 23.548C9.32489 24.1299 9.73483 24.597 10.3112 24.679C10.4897 24.7036 10.6724 24.7036 10.855 24.7036C13.3917 24.7036 15.9284 24.7036 18.4652 24.7036V24.7077ZM18.4733 15.6805C20.1252 15.6805 21.7771 15.6805 23.429 15.6805C24.468 15.6805 25.5111 15.6887 26.5501 15.6723C27.1427 15.66 27.6014 15.2011 27.6663 14.6069C27.7312 14.0414 27.3416 13.48 26.7734 13.3407C26.5988 13.2997 26.4121 13.3038 26.2295 13.3038C21.0627 13.3038 15.9 13.3038 10.7333 13.3038C10.6034 13.3038 10.4735 13.2997 10.3436 13.312C9.75918 13.3776 9.32489 13.8652 9.30866 14.4553C9.29242 15.1765 9.80382 15.6764 10.5831 15.6764C13.2132 15.6805 15.8392 15.6764 18.4692 15.6764L18.4733 15.6805Z", fill: "#84D8E1" })));
};
