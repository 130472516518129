import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { ArrowDirection, ArrowIcon } from '../atoms';
import { BellOffIcon } from '../atoms/icons/bell_off_icon';
export var NotificationPermissionAlert = function (_a) {
    var onClick = _a.onClick, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject), onClick: onClick },
        React.createElement(BellOffIcon, { color: Colors.PrimaryDark }),
        React.createElement("div", { className: css(styles.info) },
            React.createElement("div", { className: css(styles.title) }, "\u901A\u77E5\u8A2D\u5B9A\u304C\u30AA\u30D5\u306B\u306A\u3063\u3066\u3044\u307E\u3059"),
            React.createElement("div", { className: css(styles.description) }, "\u7AEF\u672B\u306E[\u8A2D\u5B9A]\uFF1E[\u901A\u77E5]\u304B\u3089LEAN BODY\u306E\u901A\u77E5\u3092\u8A31\u53EF\u3057\u3066\u304F\u3060\u3055\u3044\u3002")),
        React.createElement(ArrowIcon, { direction: ArrowDirection.right, color: Colors.Black })));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 10,
        backgroundColor: Colors.BackgroundBlue,
        padding: 16,
        borderRadius: 10,
    },
    info: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '140%',
        letterSpacing: 0.56,
        marginBottom: 2,
    },
    description: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: 0.48,
    },
    arrow: {
        width: 20,
        height: 20,
    },
});
