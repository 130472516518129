import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
import { ArrowDirection } from './arrow_icon';
var Arrowtransform = {
    top: 'rotate(90deg)',
    right: 'rotate(180deg)',
    bottom: 'rotate(270deg)',
    left: 'rotate(0deg)',
};
export var ArrowIconWithMargin = function (_a) {
    var color = _a.color, classObject = _a.classObject, direction = _a.direction, onClick = _a.onClick;
    var styles = StyleSheet.create({
        icon: {
            width: 32,
            height: 32,
            transform: Arrowtransform[direction || ArrowDirection.top],
            transition: 'transform 0.2s',
            cursor: onClick ? 'pointer' : 'unset',
        },
    });
    var iconColor = color || Colors.Primary;
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", onClick: onClick },
        React.createElement("path", { d: "M18.4301 25.68L9.60006 16.84C9.13006 16.37 9.13006 15.62 9.60006 15.15L18.4301 6.32C18.6901 6.06 19.1001 6.06 19.3601 6.32L20.3301 7.29C20.5901 7.55 20.5901 7.96 20.3301 8.22L12.5601 16L20.3401 23.78C20.6001 24.04 20.6001 24.45 20.3401 24.71L19.3701 25.68C19.1101 25.94 18.6901 25.94 18.4301 25.68Z", fill: iconColor })));
};
