import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { MealMenuInfo } from './meal_menu_info';
import { CloseRoundIcon } from '../atoms/icons/close_round_icon';
import { ArrowDirection, ArrowIcon } from '../atoms';
import { Colors } from '../styles';
export var MealMenuConfirmListItem = function (_a) {
    var index = _a.index, menuSelection = _a.menuSelection, onClick = _a.onClick, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject), onClick: function () { return onClick(index, menuSelection.menu); } },
        React.createElement(CloseRoundIcon, { containerClassObject: [styles.icon, styles.closeIcon], onClick: function (e) {
                e.stopPropagation();
                menuSelection.onClickDeselect(index);
            } }),
        React.createElement(MealMenuInfo, { menu: menuSelection.menu, amount: menuSelection.menu.amountPercentage, classObject: styles.mealMenuInfo }),
        React.createElement(ArrowIcon, { direction: ArrowDirection.right, color: Colors.GrayDarkest, classObject: styles.icon })));
};
var styles = StyleSheet.create({
    container: {
        padding: '12px 16px 12px 8px',
        display: 'flex',
        alignItems: 'center',
        gap: 6,
    },
    mealMenuInfo: {
        flex: 1,
    },
    icon: {
        width: 20,
        height: 20,
    },
    closeIcon: {
        margin: 8,
    },
});
