import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var ReplayIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 16,
            height: 19,
        },
    });
    var iconColor = color || Colors.White;
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 16 19", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M8 18.3335C6.95833 18.3335 5.98278 18.1357 5.07333 17.7402C4.16333 17.3441 3.37167 16.8091 2.69833 16.1352C2.02444 15.4618 1.48944 14.6702 1.09333 13.7602C0.697778 12.8507 0.5 11.8752 0.5 10.8335H2.16667C2.16667 12.4585 2.73278 13.8368 3.865 14.9685C4.99667 16.1007 6.375 16.6668 8 16.6668C9.625 16.6668 11.0033 16.1007 12.135 14.9685C13.2672 13.8368 13.8333 12.4585 13.8333 10.8335C13.8333 9.2085 13.2672 7.82988 12.135 6.69766C11.0033 5.566 9.625 5.00016 8 5.00016H7.875L9.16667 6.29183L8 7.50016L4.66667 4.16683L8 0.833496L9.16667 2.04183L7.875 3.3335H8C9.04167 3.3335 10.0175 3.53155 10.9275 3.92766C11.8369 4.32322 12.6286 4.85766 13.3025 5.531C13.9758 6.20488 14.5106 6.99655 14.9067 7.906C15.3022 8.816 15.5 9.79183 15.5 10.8335C15.5 11.8752 15.3022 12.8507 14.9067 13.7602C14.5106 14.6702 13.9758 15.4618 13.3025 16.1352C12.6286 16.8091 11.8369 17.3441 10.9275 17.7402C10.0175 18.1357 9.04167 18.3335 8 18.3335Z", fill: String(iconColor) }),
        React.createElement("path", { d: "M8 18.3335C6.95833 18.3335 5.98278 18.1357 5.07333 17.7402C4.16333 17.3441 3.37167 16.8091 2.69833 16.1352C2.02444 15.4618 1.48944 14.6702 1.09333 13.7602C0.697778 12.8507 0.5 11.8752 0.5 10.8335H2.16667C2.16667 12.4585 2.73278 13.8368 3.865 14.9685C4.99667 16.1007 6.375 16.6668 8 16.6668C9.625 16.6668 11.0033 16.1007 12.135 14.9685C13.2672 13.8368 13.8333 12.4585 13.8333 10.8335C13.8333 9.2085 13.2672 7.82988 12.135 6.69766C11.0033 5.566 9.625 5.00016 8 5.00016H7.875L9.16667 6.29183L8 7.50016L4.66667 4.16683L8 0.833496L9.16667 2.04183L7.875 3.3335H8C9.04167 3.3335 10.0175 3.53155 10.9275 3.92766C11.8369 4.32322 12.6286 4.85766 13.3025 5.531C13.9758 6.20488 14.5106 6.99655 14.9067 7.906C15.3022 8.816 15.5 9.79183 15.5 10.8335C15.5 11.8752 15.3022 12.8507 14.9067 13.7602C14.5106 14.6702 13.9758 15.4618 13.3025 16.1352C12.6286 16.8091 11.8369 17.3441 10.9275 17.7402C10.0175 18.1357 9.04167 18.3335 8 18.3335Z", fill: String(iconColor) }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "paint0_linear_20975_32608", x1: "8", y1: "0.833496", x2: "8", y2: "18.3335", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "white" }),
                React.createElement("stop", { offset: "1", stopColor: "white", stopOpacity: "0" })))));
};
