import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { Link } from 'react-router-dom';
import { LessonType, routes } from '@lean-body/src/route';
import { FireIcon } from '../atoms/icons/fire_icon';
import { HeartIcon } from '../atoms/icons/heart_icon';
import { ShadowHeartIcon } from '../atoms/icons/shadow_heart_icon';
export var LessonCardForVertical = function (_a) {
    var _b, _c;
    var lesson = _a.lesson, children = _a.children, classObject = _a.classObject, imgClassObject = _a.imgClassObject, addFavoriteLesson = _a.addFavoriteLesson, unFavoriteLesson = _a.unFavoriteLesson, isBig = _a.isBig;
    var _d = React.useState(lesson.isFavorite), isFavorited = _d[0], setIsFavorited = _d[1];
    var favoritable = !!addFavoriteLesson; // 診断ページのAPIが修正されるまで、お気に入りボタンを非表示にしています
    var favoriteCount = (_c = (_b = lesson.lessonAggregate) === null || _b === void 0 ? void 0 : _b.totalFavoriteCount) === null || _c === void 0 ? void 0 : _c.toLocaleString();
    var clickFavorite = function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (isFavorited) {
            lesson.isFavorite = false;
            unFavoriteLesson(lesson.id);
            setIsFavorited(false);
        }
        else {
            lesson.isFavorite = true;
            addFavoriteLesson(lesson.id);
            setIsFavorited(true);
        }
    };
    return (React.createElement(Link, { to: routes.LESSON.compile({ slug: lesson.slug, type: LessonType.normal }), className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.imgContainer, imgClassObject) },
            favoritable && (React.createElement(ShadowHeartIcon, { color: Colors.White, filled: isFavorited, classObject: styles.favoriteIconTopRight, onClick: clickFavorite })),
            React.createElement("div", { className: css(styles.labelBottomRight) }, "".concat(lesson.duration, ":00")),
            React.createElement("img", { className: css(styles.img), src: lesson.thumbnailURL })),
        React.createElement("div", { className: css(styles.text) },
            React.createElement("div", { className: css(styles.title, isBig && styles.titleBig) }, lesson.name),
            React.createElement("div", { className: css(styles.itemInfo, isBig && styles.itemInfoIsBig) },
                favoriteCount && (React.createElement(React.Fragment, null,
                    React.createElement(HeartIcon, { classObject: [styles.favoriteIcon, isBig && styles.favoriteIconBig], color: Colors.Black }),
                    favoriteCount)),
                lesson.difficulty.name && React.createElement(React.Fragment, null,
                    "\u30FB",
                    lesson.difficulty.name),
                isBig ? '・' : React.createElement("br", { className: css(styles.br) }),
                React.createElement(FireIcon, { classObject: styles.fireicon, color: Colors.Black }),
                React.createElement("span", null, "".concat(lesson.minCal, "-").concat(lesson.maxCal, "kcal"))),
            isBig && !!lesson.tags.length && (React.createElement("div", { className: css(styles.itemTags) }, lesson.tags.map(function (v, i) { return (React.createElement("div", { key: i, className: css(styles.itemTag) },
                "#",
                v.name)); }))),
            isBig && React.createElement("div", { className: css(styles.desc) }, lesson.description)),
        children));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        marginBottom: 10,
    },
    imgContainer: {
        position: 'relative',
        flex: 1,
        marginRight: 8,
    },
    favoriteIconTopRight: {
        position: 'absolute',
        width: 34,
        height: 30,
        top: 3,
        right: 3,
    },
    labelBottomRight: {
        fontSize: 10,
        fontWeight: 'bold',
        letterSpacing: '0.04em',
        lineHeight: 1,
        borderRadius: 4,
        padding: '3px 4px',
        color: Colors.White,
        backgroundColor: Colors.Black,
        position: 'absolute',
        bottom: 6,
        right: 6,
    },
    img: {
        aspectRatio: '16/9',
        width: '100%',
        borderRadius: 4,
        display: 'block',
    },
    text: {
        flex: 1,
        color: Colors.Black,
    },
    title: {
        fontSize: 12,
        lineHeight: '160%',
        fontWeight: 'bold',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
    },
    titleBig: {
        fontSize: 20,
    },
    itemInfo: {
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
        margin: '2px 0',
        flexWrap: 'wrap',
    },
    itemInfoIsBig: {
        fontSize: 14,
    },
    br: {
        width: '100%',
        content: '""',
    },
    favoriteIcon: {
        width: 12,
        height: 11,
        marginRight: 4,
    },
    favoriteIconBig: {
        width: 15,
        height: 14,
    },
    fireicon: {
        width: 10,
        height: 14,
        margin: '0 4px 1px 0',
    },
    itemTags: {
        display: 'flex',
        flexWrap: 'wrap',
        height: 18,
        overflow: 'hidden',
    },
    itemTag: {
        marginRight: 6,
        fontSize: 12,
        color: Colors.GrayDarkest,
    },
    desc: {
        fontSize: 12,
        marginTop: 6,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
        overflow: 'hidden',
    },
});
