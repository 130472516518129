import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { Input } from '@lean-body/components/atoms';
export var CampaignCodeForm = function (props) {
    var styles = StyleSheet.create({
        container: {
            borderRadius: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            height: 50,
        },
        input: {
            width: '100%',
        },
        submit: {
            cursor: 'pointer',
            borderRadius: '4px',
            fontSize: 14,
            letterSpacing: 2,
            width: 68,
            height: '100%',
            marginLeft: 8,
            color: Colors.White,
            backgroundColor: Colors.PrimaryDark,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'all .2s ease',
        },
        submitDisabled: {
            backgroundColor: Colors.PrimaryTransparent,
        },
    });
    var submit = function () {
        if (props.submitDisabled)
            return;
        props.onSubmit();
    };
    return (React.createElement("div", { className: css(styles.container, props.classObject) },
        React.createElement(Input, { type: "text", placeholder: "\u30AF\u30FC\u30DD\u30F3\u30B3\u30FC\u30C9\u3092\u5165\u529B", onChange: props.onChangeKeyword, value: props.value, dataTest: "campaignCodeInput", containerClassObject: styles.input }),
        React.createElement("div", { className: css(styles.submit, props.submitDisabled && styles.submitDisabled), onClick: submit, "data-test": "submitCampaignCode" }, "\u9069\u7528")));
};
