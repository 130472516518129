import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useSmMediaQuery } from '@lean-body/src/util/media_query_hooks';
import { Colors } from '@lean-body/components/styles';
import { HIDE_HEADER_QUERY_PARAMS } from '@lean-body/components/pages/main';
import { ZIndex } from '@lean-body/src/view/constants';
import { Logo } from '@lean-body/components/atoms';
import { MatchingAssessmentSection, MatchingAssessmentCourseSection, PageLoading, } from '@lean-body/components/organisms';
import { LocalStorage } from '@lean-body/src/infra';
var useState = React.useState, useEffect = React.useEffect;
var START_QUETIONS_INDEX = 0;
/**
 * マッチングテストのページ
 */
export var MatchingAssessmentPage = function (_a) {
    var config = _a.config, apiClient = _a.apiClient, tracker = _a.tracker;
    var isSM = useSmMediaQuery();
    var localStorage = new LocalStorage();
    var selectedChoices = useState([])[0];
    var _b = useState(false), isFinishedAssessment = _b[0], setIsFinishedAssessment = _b[1];
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var _d = useState(), matchingAssessments = _d[0], setMatchingAssessments = _d[1];
    var _e = useState(START_QUETIONS_INDEX), questionIndex = _e[0], setQuestionIndex = _e[1];
    var startFromIntroPage = new URLSearchParams(location.search).get(HIDE_HEADER_QUERY_PARAMS);
    var _f = useState(null), courses = _f[0], setCourses = _f[1];
    useEffect(function () {
        apiClient.fetchMatchingAssessments().then(function (v) {
            setMatchingAssessments(v);
        });
    }, []);
    var finish = function (selectedChoices) {
        setIsLoading(true);
        tracker.trackClickMatchingAssessmentComplete();
        tracker.trackClickMatchingAssessmentChoices(selectedChoices);
        var selectedChoiceIDs = selectedChoices.flat().map(function (choice) {
            return choice.id;
        });
        apiClient
            .fetchMatchingAssessmentResult(selectedChoiceIDs)
            .then(function () {
            return apiClient.fetchCourseMatchingAssessmentResult(selectedChoiceIDs, localStorage.loadCourseInstructorCode);
        })
            .then(function (res) {
            setCourses(res);
            setIsFinishedAssessment(true);
            setIsLoading(false);
            localStorage.removeCourseInstructorCode();
        });
    };
    var back = function () {
        setIsFinishedAssessment(false);
    };
    if (!matchingAssessments || isLoading) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(styles.container) },
        isSM && startFromIntroPage && React.createElement(Logo, { forHeader: isSM, classObject: styles.logo }),
        React.createElement("div", { className: css(styles.content) }, isFinishedAssessment ? (React.createElement(MatchingAssessmentCourseSection, { config: config, apiClient: apiClient, tracker: tracker, courses: courses, back: back, selectedChoices: selectedChoices[0] })) : (React.createElement(MatchingAssessmentSection, { tracker: tracker, matchingAssesments: matchingAssessments, questionIndex: questionIndex, startQuestionIndex: START_QUETIONS_INDEX, setQuestionIndex: setQuestionIndex, selectedChoices: selectedChoices, finish: finish, startFromIntroPage: startFromIntroPage })))));
};
var styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.White,
    },
    logo: {
        position: 'fixed',
        width: '100%',
        backgroundColor: Colors.White,
        zIndex: ZIndex.Header,
    },
    content: {
        position: 'relative',
        textAlign: 'center',
        top: 0,
        left: 0,
        width: '100%',
        minHeight: '100vh',
    },
});
