import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var CloseButtonLabel;
(function (CloseButtonLabel) {
    CloseButtonLabel["Close"] = "\u9589\u3058\u308B";
    CloseButtonLabel["Cancel"] = "\u30AD\u30E3\u30F3\u30BB\u30EB";
})(CloseButtonLabel || (CloseButtonLabel = {}));
export var DialogCloseButton = function (_a) {
    var label = _a.label, onClick = _a.onClick, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject), "data-test": "closeDialog" },
        React.createElement("div", { className: css(styles.button), onClick: onClick }, label || CloseButtonLabel.Close)));
};
var styles = StyleSheet.create({
    container: {
        position: 'absolute',
        bottom: -60,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: 30,
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        height: 30,
        fontSize: 12,
        fontWeight: 'bold',
        color: Colors.White,
        padding: '0 12px',
        borderRadius: 15,
        border: "1px solid ".concat(Colors.White),
        cursor: 'pointer',
    },
});
