var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CameraWithBg } from '@lean-body/components/atoms/camera_with_bg';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { SelectThumbnailDialog } from '@lean-body/components/organisms/select_thumbnail_dialog';
import { CanvasConbineText } from '@lean-body/components/atoms/canvas_conbine_text';
import { canvasToFile } from '@lean-body/src/util/canvas';
export var NewPlaylistImage = function (_a) {
    var title = _a.title, imgSrc = _a.imgSrc, file = _a.file, defaultImgSrcKey = _a.defaultImgSrcKey, setImgSrc = _a.setImgSrc, setFile = _a.setFile, setDefaultImgSrcKey = _a.setDefaultImgSrcKey;
    var _b = React.useState(false), showImgDialog = _b[0], setShowImgDialog = _b[1];
    var canvas = React.useRef();
    var selectThumbnail = function (selectedFile, key) {
        setFile(selectedFile);
        setImgSrc(window.URL.createObjectURL(selectedFile));
        setDefaultImgSrcKey(key);
    };
    var onLoadCanvas = function () { return __awaiter(void 0, void 0, void 0, function () {
        var canvasFile;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!canvas.current)
                        return [2 /*return*/];
                    return [4 /*yield*/, canvasToFile(canvas.current, "".concat(defaultImgSrcKey, ".jpg"), 'image/jpg')];
                case 1:
                    canvasFile = _a.sent();
                    setFile(canvasFile);
                    return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        // デフォルトの画像選択後に、タイトル変更されたら、CanvasConbineTextを表示したいので、ImgSrcとFileを消す
        if (file && defaultImgSrcKey) {
            setImgSrc(null);
            setFile(null);
        }
    }, [title]);
    if (!title)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.imageContent), onClick: function () { return setShowImgDialog(true); } },
            imgSrc ? (React.createElement("img", { className: css(styles.image), src: imgSrc })) : (React.createElement(CanvasConbineText, { ref: canvas, text: title, width: 220, height: 220, imgSrc: "/assets/images/original_playlist/".concat(defaultImgSrcKey, ".jpg"), classObject: styles.image, onLoad: function () { return onLoadCanvas(); } })),
            React.createElement(CameraWithBg, { classObject: styles.camera })),
        showImgDialog && (React.createElement(SelectThumbnailDialog, { initKey: defaultImgSrcKey, canvasText: title, submit: selectThumbnail, close: function () { return setShowImgDialog(false); } }))));
};
var styles = StyleSheet.create({
    container: {
        position: 'relative',
        padding: '32px 24px 40px',
        backgroundColor: Colors.White,
        overflow: 'hidden',
    },
    haedText: {
        position: 'relative',
        textAlign: 'center',
        fontWeight: 'bold',
        margin: '0 0 24px',
    },
    imageContent: (_a = {
            position: 'relative',
            width: 220,
            margin: '0 auto 50px',
            filter: 'drop-shadow(0px 0px 10px rgba(0, 54, 58, 0.1))',
            cursor: 'pointer'
        },
        _a[MediaBreakPointUp.XL] = {
            margin: '0 8px 0 32px',
        },
        _a),
    image: {
        width: 220,
        height: 220,
        borderRadius: 16,
    },
    camera: (_b = {
            position: 'absolute',
            bottom: -16,
            right: -32,
            boxShadow: '0px 4px 10px rgba(0, 54, 58, 0.1)'
        },
        _b[MediaBreakPointUp.XL] = {
            bottom: 16,
            right: -16,
        },
        _b),
    button: {
        width: '100%',
        height: 56,
        marginTop: 40,
    },
});
