import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { Tab, Tabs as MauTabs } from '@mui/material';
var TAB_HEIGHT = 27;
export var Tabs = function (props) {
    var setTab = props.setTab, currentTab = props.currentTab, tabs = props.tabs, classObject = props.classObject, tabWrapperClassObject = props.tabWrapperClassObject, tabIndicatorClassObject = props.tabIndicatorClassObject, tabLabelClassObject = props.tabLabelClassObject, selectedTabClassObject = props.selectedTabClassObject, variant = props.variant;
    var onChangeTab = function (_, v) {
        setTab(v);
    };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(MauTabs, { value: currentTab, onChange: onChangeTab, variant: variant, classes: {
                root: css(styles.tabWrapper, tabWrapperClassObject),
                indicator: css(styles.indicator, tabIndicatorClassObject),
            } }, Object.keys(tabs).map(function (key, i) { return (React.createElement(Tab, { label: tabs[key], classes: {
                root: css(styles.tabRoot, tabLabelClassObject),
                selected: css(styles.selectedTabName, selectedTabClassObject),
            }, value: tabs[key], key: i })); }))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    tabWrapper: {
        display: 'inline-block',
        minHeight: TAB_HEIGHT,
        height: TAB_HEIGHT,
        color: Colors.GrayDarkest,
        backgroundColor: Colors.BackgroundLightGray,
        borderRadius: 100,
    },
    tabRoot: {
        position: 'relative',
        fontSize: 12,
        fontWeight: 'bold',
        padding: '0 16px',
        lineHeight: 1,
        minHeight: TAB_HEIGHT,
        opacity: 1,
        textAlign: 'center',
        zIndex: 1, // material-uiのindicatorのdomの位置を変えられないために調整のためZIndexは使わない
        transition: 'color 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms', // material-uiに合わせてる
        minWidth: 0, // material-uiのデフォルトのminWidthが大きすぎるので消す
    },
    selectedTabName: {
        color: Colors.White,
    },
    indicator: {
        backgroundColor: Colors.PrimaryDark,
        height: '100%',
        borderRadius: 100,
    },
});
