import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../styles';
import { CirclePlusIcon } from '../atoms/icons/circle_plus_icon';
import { CircleCheckIcon } from '../atoms';
import { MealMenuInfo } from './meal_menu_info';
export var MealMenuListItem = function (_a) {
    var menu = _a.menu, isSelected = _a.isSelected, isDisplayAmount = _a.isDisplayAmount, onClick = _a.onClick, onClickSelect = _a.onClickSelect, onClickDeselect = _a.onClickDeselect, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject), onClick: function () { return onClick(menu); } },
        React.createElement(MealMenuInfo, { menu: menu, amount: isDisplayAmount && menu.amountPercentage, classObject: styles.mealMenuInfo }),
        isSelected ? (React.createElement(CircleCheckIcon, { color: Colors.ProgressGreen, filled: true, onClick: function (e) {
                e.stopPropagation();
                onClickDeselect(menu.menuKey);
            }, classObject: styles.selectIcon })) : (React.createElement(CirclePlusIcon, { onClick: function (e) {
                e.stopPropagation();
                onClickSelect(menu);
            }, classObject: styles.selectIcon }))));
};
var styles = StyleSheet.create({
    container: {
        padding: '12px 6px 12px 0',
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    mealMenuInfo: {
        flex: 1,
    },
    selectIcon: {
        width: 35,
        height: 35,
    },
});
