import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Task } from '@lean-body/src/domain';
import { Colors } from '@lean-body/components/styles/variables';
import { StepsCaloriesIcon } from '../atoms/icons/steps_calories_icon';
export var StepsCalories = function (_a) {
    var stepActivity = _a.stepActivity, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.header) },
            React.createElement(StepsCaloriesIcon, null),
            React.createElement("span", { className: css(styles.headerText) }, "\u6D88\u8CBB\u30AB\u30ED\u30EA\u30FC")),
        stepActivity ? (React.createElement("div", { className: css(styles.title) },
            Task.roundTaskCalories(stepActivity.calories),
            "kcal")) : (React.createElement("div", { className: css(styles.title) }, "0kcal"))));
};
var styles = StyleSheet.create({
    container: {
        padding: 16,
        borderRadius: 14,
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
    },
    header: {
        display: 'flex',
        gap: 6,
    },
    headerText: {
        color: Colors.GrayDarkest,
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: 0.48,
    },
    title: {
        color: Colors.Black,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
    },
});
