import * as React from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import { Colors } from '@lean-body/components/styles';
import { getFormattedToSlashFormat } from '@lean-body/src/util';
export var AnnouncementLabel = function (_a) {
    var announcement = _a.announcement, me = _a.me, dateLabelClass = _a.dateLabelClass, newLabelClass = _a.newLabelClass;
    var styles = StyleSheet.create({
        labelContent: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 10,
        },
        date: {
            color: Colors.GrayDarkest,
        },
        newLabel: {
            marginRight: 6,
            color: Colors.White,
            padding: '2px 6px',
            backgroundColor: Colors.Accent,
            borderRadius: 4,
        },
    });
    var ANNOUNCEMENT_RELEASE_AT = new Date('2021-05-14T18:00:00+09:00').getTime() / 1000;
    var meCreatedAt;
    if (me) {
        meCreatedAt = new Date(me.createdAt).getTime() / 1000;
    }
    // 2021-05-14以前に登録した人は最初のお知らせの日付をリリース日にする
    var firstAnnouncementAt = getFormattedToSlashFormat(meCreatedAt && meCreatedAt > ANNOUNCEMENT_RELEASE_AT ? meCreatedAt : ANNOUNCEMENT_RELEASE_AT);
    return (React.createElement("div", { className: css(styles.labelContent) },
        announcement.isNew && React.createElement("div", { className: css(styles.newLabel, newLabelClass) }, "NEW"),
        React.createElement("div", { className: css(styles.date, dateLabelClass) }, announcement.formattedPublishAt || firstAnnouncementAt)));
};
