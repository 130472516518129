var _a, _b, _c, _d, _e, _f, _g, _h, _j;
/* eslint-disable no-case-declarations */
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MediaBreakPointUp } from '@lean-body/components/styles';
import { isEmptyArray } from '@lean-body/src/util/common';
import { MyLessonAllContents } from './my_lesson_all_contents';
import { useSmMediaQuery, useXlMediaQuery } from '@lean-body/src/util';
import { PlaylistComponentLandscapeImg, PlaylistComponentSquare } from '../molecules';
import { ProgramComponent } from '../molecules/program_component';
import { LessonComponent } from '../molecules/lesson_component';
import { LiveLessonComponent } from '../molecules/live_lesson_component';
import { OriginalPlaylistSectionCard, ORIGINAL_PLAYLIST_ITEM_WIDTH } from '../molecules/original_playlist_section_card';
import { PaginationNextButton } from '../atoms/pagination_next_button';
import { DISPLAY_LESSON_PER_PAGE } from '../pages/main_my_lesson';
import { MyLessonHeaderSection } from './my_lesson_header_section';
import { routes } from '@lean-body/src/route';
import { MyLessonEmptyState } from '../molecules/my_lesson_empty_state';
export var DISPLAY_WIDTH_XL = 1030;
export var CARD_WIDTH_XL = 335;
export var DETAILS_QUERY_PARAMS = 'details';
export var MyLessonDetails = {
    subscribedLiveLesson: 'subscribedLiveLesson',
    challengingPlaylist: 'challengingPlaylist',
    lessonHistory: 'lessonHistory',
    lesson: 'lesson',
    program: 'program',
    playlist: 'playlist',
    originalPlaylist: 'originalPlaylist',
};
export var MyLessons = function (_a) {
    var details = _a.details, subscribedLiveLessons = _a.subscribedLiveLessons, challengingPlaylists = _a.challengingPlaylists, histories = _a.histories, lessons = _a.lessons, seriesPrograms = _a.seriesPrograms, playlists = _a.playlists, originalPlaylists = _a.originalPlaylists, onClickLessonHistoryNext = _a.onClickLessonHistoryNext, onClickLessonNext = _a.onClickLessonNext, apiClient = _a.apiClient, tracker = _a.tracker;
    var isSM = useSmMediaQuery();
    var isXL = useXlMediaQuery();
    var nextLessonHistoryAvailable = (histories === null || histories === void 0 ? void 0 : histories.length) % DISPLAY_LESSON_PER_PAGE === 0;
    var nextFavoriteLessonAvailable = (lessons === null || lessons === void 0 ? void 0 : lessons.length) % DISPLAY_LESSON_PER_PAGE === 0;
    var onClickCreateOriginalPlaylist = function () {
        tracker.trackOriginalPlaylistBannerClick('favorites');
    };
    return (React.createElement("div", null, details === '' ? (React.createElement(React.Fragment, null,
        React.createElement(MyLessonHeaderSection, { subscribedLiveLessonCount: subscribedLiveLessons.length, challengingPlaylistCount: challengingPlaylists.length }),
        React.createElement(MyLessonAllContents, { lessonHistories: histories, lessons: lessons, playlists: playlists, seriesPrograms: seriesPrograms, originalPlaylists: originalPlaylists, addFavoriteLesson: apiClient.addFavoriteLesson, unFavoriteLesson: apiClient.unFavoriteLesson, addFavoriteProgram: apiClient.addFavoriteProgram, unFavoriteProgram: apiClient.unFavoriteProgram, onClickCreateOriginalPlaylist: onClickCreateOriginalPlaylist }))) : // 参加予定のLIVEレッスン
        details === MyLessonDetails.subscribedLiveLesson ? (isEmptyArray(subscribedLiveLessons) ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.title, styles.emptyTitle) }, "\u53C2\u52A0\u4E88\u5B9A\u306ELIVE\u30EC\u30C3\u30B9\u30F3"),
            React.createElement(MyLessonEmptyState, { bottomImgSrc: "/assets/images/my_lesson/subscribed_live_lesson_empty.png", title: "\u53C2\u52A0\u4E88\u5B9A\u306ELIVE\u30EC\u30C3\u30B9\u30F3\u304C\u3042\u308A\u307E\u305B\u3093", description: "\u6C17\u306B\u306A\u308BLIVE\u30EC\u30C3\u30B9\u30F3\u306B\u300C\u53C2\u52A0\u3057\u305F\u3044\uFF01\u300D\u3092\u62BC\u3057\u3066\u6642\u9593\u306B\u306A\u3063\u305F\u3089\u53C2\u52A0\u3057\u307E\u3057\u3087\u3046", buttonText: "LIVE\u30EC\u30C3\u30B9\u30F3\u3092\u63A2\u3059", linkUrl: routes.LIVE_SCHEDULE.compile() }))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.blockContainer) },
                React.createElement("div", { className: css(styles.title) }, "\u53C2\u52A0\u4E88\u5B9A\u306ELIVE\u30EC\u30C3\u30B9\u30F3"),
                subscribedLiveLessons.map(function (liveLesson, i) { return (React.createElement("div", { className: css(styles.lessonCard), key: i },
                    React.createElement(LiveLessonComponent, { liveLesson: liveLesson, isMin: isSM, isVertical: !isXL }))); }))))) : // チャレンジ中のプレイリスト
            details === MyLessonDetails.challengingPlaylist ? (isEmptyArray(challengingPlaylists) ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(styles.title, styles.emptyTitle) }, "\u30C1\u30E3\u30EC\u30F3\u30B8\u4E2D\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8"),
                React.createElement(MyLessonEmptyState, { bottomImgSrc: "/assets/images/my_lesson/challenging_playlist_empty.png", title: "\u30C1\u30E3\u30EC\u30F3\u30B8\u4E2D\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u304C\u3042\u308A\u307E\u305B\u3093", description: "\u3042\u306A\u305F\u306B\u5408\u3063\u305F\u30C6\u30FC\u30DE\u3068\u671F\u9593\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u306B\u30C1\u30E3\u30EC\u30F3\u30B8\u3057\u3066\u307F\u307E\u3057\u3087\u3046!", buttonText: "\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u63A2\u3059", linkUrl: routes.PLAYLISTS.compile() }))) : (React.createElement("div", { className: css(styles.blockContainer) },
                React.createElement("div", { className: css(styles.title) }, "\u30C1\u30E3\u30EC\u30F3\u30B8\u4E2D\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8"),
                challengingPlaylists.map(function (challengingPlaylist, i) { return (React.createElement("div", { className: css(styles.playlistCard), key: i }, isSM ? (React.createElement(PlaylistComponentSquare, { playlist: challengingPlaylist.playlist, addFavoriteProgram: apiClient.addFavoriteProgram, unFavoriteProgram: apiClient.unFavoriteProgram, isMin: isSM })) : (React.createElement(PlaylistComponentLandscapeImg, { playlist: challengingPlaylist.playlist, addFavoriteProgram: apiClient.addFavoriteProgram, unFavoriteProgram: apiClient.unFavoriteProgram, isVertical: !isXL })))); })))) : // 視聴履歴
                details === MyLessonDetails.lessonHistory ? (isEmptyArray(histories) ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: css(styles.title, styles.emptyTitleWithImg) }, "\u8996\u8074\u5C65\u6B74"),
                    React.createElement(MyLessonEmptyState, { topImgSrc: "/assets/images/my_lesson/lesson_history_empty.png", title: "\u8996\u8074\u3057\u305F\u30EC\u30C3\u30B9\u30F3\u304C\u3042\u308A\u307E\u305B\u3093", description: "\u8996\u8074\u3057\u305F\u30EC\u30C3\u30B9\u30F3\u304C\u3053\u3053\u306B\u8868\u793A\u3055\u308C\u307E\u3059" }))) : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: css(styles.blockContainer) },
                        React.createElement("div", { className: css(styles.title) }, "\u8996\u8074\u5C65\u6B74"),
                        histories.map(function (history, i) { return (React.createElement("div", { className: css(styles.lessonCard), key: i }, history.lesson ? (React.createElement(LessonComponent, { lesson: history.lesson, addFavoriteLesson: apiClient.addFavoriteLesson, unFavoriteLesson: apiClient.unFavoriteLesson, isMin: isSM, isVertical: !isXL })) : (React.createElement(LiveLessonComponent, { liveLesson: history.liveLesson, isMin: isSM, isVertical: !isXL })))); })),
                    React.createElement(PaginationNextButton, { getNextPage: onClickLessonHistoryNext, classObject: styles.nextButton, isAvailable: nextLessonHistoryAvailable })))) : // お気に入りのレッスン
                    details === MyLessonDetails.lesson ? (isEmptyArray(lessons) ? (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: css(styles.title, styles.emptyTitleWithImg) }, "\u304A\u6C17\u306B\u5165\u308A\u306E\u30EC\u30C3\u30B9\u30F3"),
                        React.createElement(MyLessonEmptyState, { topImgSrc: "/assets/images/my_lesson/favorite_lesson_empty.png", title: "\u304A\u6C17\u306B\u5165\u308A\u3057\u305F\u30EC\u30C3\u30B9\u30F3\u304C\u3042\u308A\u307E\u305B\u3093", description: "\u6C17\u306B\u306A\u308B\u30EC\u30C3\u30B9\u30F3\u3092\u304A\u6C17\u306B\u5165\u308A\u3057\u3066\u3044\u3064\u3067\u3082\u898B\u8FD4\u3057\u307E\u3057\u3087\u3046", buttonText: "\u4EBA\u6C17\u306E\u30EC\u30C3\u30B9\u30F3\u3092\u898B\u308B", linkUrl: routes.RANKING.compile() }))) : (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: css(styles.blockContainer) },
                            React.createElement("div", { className: css(styles.title) }, "\u304A\u6C17\u306B\u5165\u308A\u306E\u30EC\u30C3\u30B9\u30F3"),
                            lessons.map(function (lesson, i) { return (React.createElement("div", { className: css(styles.lessonCard), key: i },
                                React.createElement(LessonComponent, { lesson: lesson, addFavoriteLesson: apiClient.addFavoriteLesson, unFavoriteLesson: apiClient.unFavoriteLesson, isMin: isSM, isVertical: !isXL }))); })),
                        React.createElement(PaginationNextButton, { getNextPage: onClickLessonNext, classObject: styles.nextButton, isAvailable: nextFavoriteLessonAvailable })))) : // お気に入りのシリーズ
                        details === MyLessonDetails.program ? (isEmptyArray(seriesPrograms) ? (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: css(styles.title, styles.emptyTitleWithImg) }, "\u304A\u6C17\u306B\u5165\u308A\u306E\u30B7\u30EA\u30FC\u30BA"),
                            React.createElement(MyLessonEmptyState, { topImgSrc: "/assets/images/my_lesson/favorite_program_empty.png", title: "\u304A\u6C17\u306B\u5165\u308A\u3057\u305F\u30B7\u30EA\u30FC\u30BA\u304C\u3042\u308A\u307E\u305B\u3093", description: "\u6C17\u306B\u306A\u308B\u30B7\u30EA\u30FC\u30BA\u3092\u304A\u6C17\u306B\u5165\u308A\u3057\u3066\u30B3\u30F3\u30D7\u30EA\u30FC\u30C8\u3057\u307E\u3057\u3087\u3046", buttonText: "\u30B7\u30EA\u30FC\u30BA\u3092\u63A2\u3059", linkUrl: routes.PROGRAMS.compile() }))) : (React.createElement("div", { className: css(styles.blockContainer) },
                            React.createElement("div", { className: css(styles.title) }, "\u304A\u6C17\u306B\u5165\u308A\u306E\u30B7\u30EA\u30FC\u30BA"),
                            seriesPrograms.map(function (seriesProgram, i) { return (React.createElement("div", { className: css(styles.programCard), key: i }, seriesProgram.slug != '' && (React.createElement(ProgramComponent, { program: seriesProgram, addFavoriteProgram: apiClient.addFavoriteProgram, unFavoriteProgram: apiClient.unFavoriteProgram, isMin: isSM, isVertical: !isXL })))); })))) : // お気に入りのプレイリスト
                            details === MyLessonDetails.playlist ? (isEmptyArray(playlists) ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: css(styles.title, styles.emptyTitleWithImg) }, "\u304A\u6C17\u306B\u5165\u308A\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8"),
                                React.createElement(MyLessonEmptyState, { topImgSrc: "/assets/images/my_lesson/favorite_playlist_empty.png", title: "\u304A\u6C17\u306B\u5165\u308A\u3057\u305F\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u304C\u3042\u308A\u307E\u305B\u3093", description: "\u6C17\u306B\u306A\u308B\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u304A\u6C17\u306B\u5165\u308A\u3057\u3066\u3042\u3068\u3067\u30C1\u30E3\u30EC\u30F3\u30B8\u3057\u307E\u3057\u3087\u3046", buttonText: "\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u63A2\u3059", linkUrl: routes.PLAYLISTS.compile() }))) : (React.createElement("div", { className: css(styles.blockContainer) },
                                React.createElement("div", { className: css(styles.title) }, "\u304A\u6C17\u306B\u5165\u308A\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8"),
                                playlists.map(function (playlist, i) { return (React.createElement("div", { className: css(styles.playlistCard), key: i }, isSM ? (React.createElement(PlaylistComponentSquare, { playlist: playlist, addFavoriteProgram: apiClient.addFavoriteProgram, unFavoriteProgram: apiClient.unFavoriteProgram, isMin: isSM })) : (React.createElement(PlaylistComponentLandscapeImg, { playlist: playlist, addFavoriteProgram: apiClient.addFavoriteProgram, unFavoriteProgram: apiClient.unFavoriteProgram, isVertical: !isXL })))); })))) : (details === MyLessonDetails.originalPlaylist && (React.createElement("div", { className: css(styles.blockContainer, styles.OriginalPlaylistContainer) },
                                React.createElement("div", { className: css(styles.title) }, "\u30AA\u30EA\u30B8\u30CA\u30EB\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8"),
                                originalPlaylists.map(function (originalPlaylist, i) { return (React.createElement(OriginalPlaylistSectionCard, { playlist: originalPlaylist, key: i, onClickCreate: onClickCreateOriginalPlaylist })); }))))));
};
var styles = StyleSheet.create({
    blockContainer: (_a = {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: 10,
            ':before': {
                display: 'block',
                content: '""',
                width: 163,
                order: 1,
            }
        },
        _a[MediaBreakPointUp.MD] = {
            marginTop: 0,
            gap: 20,
        },
        _a[MediaBreakPointUp.XL] = {
            maxWidth: 1030,
            margin: 'auto',
            gap: '20px 12px',
            ':before': {
                width: CARD_WIDTH_XL,
            },
            ':after': {
                display: 'block',
                content: '""',
                width: CARD_WIDTH_XL,
            },
        },
        _a),
    playlistBlockContainer: {
        gap: '16px 10px',
    },
    OriginalPlaylistContainer: (_b = {
            ':before': {
                width: ORIGINAL_PLAYLIST_ITEM_WIDTH,
            }
        },
        _b[MediaBreakPointUp.MD] = {
            ':after': {
                display: 'block',
                content: '""',
                width: ORIGINAL_PLAYLIST_ITEM_WIDTH,
            },
        },
        _b[MediaBreakPointUp.XL] = {
            justifyContent: 'flex-start',
            gap: '16px 12px',
            ':before': {
                content: 'none',
            },
            ':after': {
                content: 'none',
            },
        },
        _b),
    title: (_c = {
            textAlign: 'center',
            width: '100%',
            height: 54,
            fontSize: 16,
            fontWeight: 'bold',
            lineHeight: '160%',
            letterSpacing: '0.08em',
            paddingTop: 16,
            paddingBottom: 12,
            marginBottom: 6
        },
        _c[MediaBreakPointUp.MD] = {
            height: 57,
            fontSize: 18,
            lineHeight: '140%',
            paddingTop: 24,
            paddingBottom: 8,
            marginBottom: 0,
        },
        _c[MediaBreakPointUp.XL] = {
            height: 85,
            paddingTop: 40,
            paddingBottom: 20,
        },
        _c),
    emptyTitle: (_d = {
            marginBottom: 30
        },
        _d[MediaBreakPointUp.MD] = {
            paddingBottom: 12,
            marginBottom: 50,
        },
        _d[MediaBreakPointUp.XL] = {
            height: 85,
            paddingTop: 40,
            paddingBottom: 24,
        },
        _d),
    emptyTitleWithImg: (_e = {
            marginBottom: 16
        },
        _e[MediaBreakPointUp.MD] = {
            paddingBottom: 12,
            marginBottom: 20,
        },
        _e[MediaBreakPointUp.XL] = {
            height: 85,
            paddingTop: 40,
        },
        _e),
    lessonCard: (_f = {
            width: '100%',
            gap: '0 10px'
        },
        _f[MediaBreakPointUp.XL] = {
            width: CARD_WIDTH_XL,
        },
        _f),
    programCard: (_g = {
            width: '100%',
            gap: '0 10px'
        },
        _g[MediaBreakPointUp.XL] = {
            width: CARD_WIDTH_XL,
        },
        _g),
    playlistCard: (_h = {
            width: 163
        },
        _h[MediaBreakPointUp.MD] = {
            width: '100%',
        },
        _h[MediaBreakPointUp.XL] = {
            width: CARD_WIDTH_XL,
        },
        _h),
    nextButton: (_j = {
            margin: '10px auto 20px',
            width: 240
        },
        _j[MediaBreakPointUp.MD] = {
            margin: '0 auto 40px',
        },
        _j),
});
