import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../styles';
import { MealSelectButtons } from '../molecules/meal_select_buttons';
import { MealMenuList } from './meal_menu_list';
import { MealHistoryEmptyState } from '../molecules/meal_history_empty_state';
import { APISequences } from '@lean-body/src/interfaces';
export var RecordMealMenuHistory = function (_a) {
    var apiClient = _a.apiClient, selectedMenuMap = _a.selectedMenuMap, defaultMealType = _a.defaultMealType, onClickSelectMenu = _a.onClickSelectMenu, onClickDeselectMenu = _a.onClickDeselectMenu, onClickMenu = _a.onClickMenu, classObject = _a.classObject;
    var _b = React.useState(defaultMealType), mealType = _b[0], setMealType = _b[1];
    var _c = React.useState([]), mealMenus = _c[0], setMealMenus = _c[1];
    React.useEffect(function () {
        apiClient.appendToSequence(APISequences.MEAL_MENUS).then(function (next) {
            apiClient.fetchMealHistories(mealType).then(setMealMenus).finally(next);
        });
    }, [mealType]);
    return (React.createElement("div", { className: css(classObject) },
        React.createElement("div", { className: css(styles.sectionTitle) }, "\u5C65\u6B74"),
        React.createElement(MealSelectButtons, { selected: mealType, onClick: setMealType, classObject: styles.mealSelectButtons }),
        mealMenus.length > 0 ? (React.createElement(MealMenuList, { mealMenus: mealMenus, selectedMenuMap: selectedMenuMap, onClickMenu: onClickMenu, onClickSelectMenu: onClickSelectMenu, onClickDeselectMenu: onClickDeselectMenu })) : (React.createElement(MealHistoryEmptyState, { classObject: styles.mealMenuEmptyState }))));
};
var styles = StyleSheet.create({
    sectionTitle: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '160%',
        letterSpacing: 1.28,
        marginTop: 24,
        marginBottom: 8,
    },
    mealSelectButtons: {
        borderBottom: "1px solid ".concat(Colors.BorderLightGray),
    },
    mealMenuEmptyState: {
        marginTop: 70,
    },
});
