var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Button, ButtonType } from '@lean-body/components/atoms/button';
export var MatchingAssessmentButtons = function (_a) {
    var nextButtonClickable = _a.nextButtonClickable, nextButtonText = _a.nextButtonText, onClickNext = _a.onClickNext, onClickBack = _a.onClickBack, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject), "data-test": "matchingAssessmentNext" },
        React.createElement(Button, { className: css(styles.button), buttonType: nextButtonClickable ? ButtonType.primary : ButtonType.transparentPrimary, disabled: !nextButtonClickable, onClick: onClickNext }, nextButtonText),
        React.createElement("div", { className: css(styles.backButton), onClick: onClickBack }, "\u3082\u3069\u308B")));
};
var styles = StyleSheet.create({
    container: {
        position: 'fixed',
        bottom: 0,
        padding: '30px 0',
        backgroundColor: Colors.White,
    },
    button: (_a = {
            display: 'block',
            width: 300,
            borderRadius: 24,
            margin: '0 auto'
        },
        _a[MediaBreakPointUp.MD] = {
            width: 500,
        },
        _a),
    backButton: {
        marginTop: 20,
        color: Colors.Black,
        cursor: 'pointer',
    },
});
