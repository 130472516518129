var _a, _b, _c;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MediaBreakPointUp, Colors } from '@lean-body/components/styles';
import { CourseHeaderThumbnail, CourseRewardTitle } from '@lean-body/components/molecules';
export var CourseRewardHeader = function (_a) {
    var courseReward = _a.courseReward, classObject = _a.classObject, config = _a.config;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.container, classObject) },
            React.createElement(CourseHeaderThumbnail, { courseThumbnailSrc: courseReward.thumbnailURL(config), classObject: styles.thumbnail, imgSize: 213 }),
            React.createElement(CourseRewardTitle, { day: courseReward.dayNumber, classObject: styles.title }))));
};
var styles = StyleSheet.create({
    container: {
        position: 'relative',
        overflow: 'hidden',
        height: 356,
    },
    thumbnail: (_a = {
            right: '10%'
        },
        _a[MediaBreakPointUp.MD] = {
            right: '15%',
        },
        _a[MediaBreakPointUp.XL] = {
            right: '40%',
        },
        _a),
    title: (_b = {
            position: 'relative',
            marginTop: 77
        },
        _b[MediaBreakPointUp.MD] = {
            marginTop: 72.5,
            marginLeft: 50,
        },
        _b[MediaBreakPointUp.XL] = {
            marginLeft: 150,
        },
        _b),
    name: (_c = {
            color: Colors.TextDark,
            letterSpacing: '0.04em',
            fontWeight: 700,
            fontSize: 24,
            marginBottom: 10
        },
        _c[MediaBreakPointUp.MD] = {
            marginBottom: 20,
        },
        _c),
});
