var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import * as React from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from '@lean-body/src/util';
import { Colors } from '@lean-body/components/styles';
import { MediaBreakPointUp } from '@lean-body/components/styles';
import { PageLoading, TrainerDetailSection, LessonComponentCarouselSeparate } from '@lean-body/components/organisms';
import { UnderbarTabs } from '../molecules/underbar_tabs';
import { InstaAndSiteLink } from '@lean-body/components/atoms';
import * as routes from '@lean-body/src/route';
import { css, StyleSheet } from 'aphrodite/no-important';
import LazyLoad from 'react-lazyload';
import { MoreLink } from '@lean-body/components/atoms/more_link';
import { PlaylistTileCarousel } from '@lean-body/components/organisms';
import { ProgramComponentCarousel } from '../organisms/program_component_carousel';
import { PlaylistComponentCarousel } from '../organisms/playlist_component_carousel';
import { useXlMediaQuery, useSmMediaQuery } from '@lean-body/src/util/media_query_hooks';
import { LIST_ITEM_MARGIN_RIGHT } from '../pages/main_home';
import { LessonComponent } from '../molecules/lesson_component';
import { MoreLoading } from '@lean-body/components/atoms';
import { ProgramComponent } from '../molecules/program_component';
import { ITEM_WIDTH_OVER_XL, ITEM_WIDTH_SP, VerticalListArrows, } from '@lean-body/components/molecules/vertical_list_arrows';
import { PlaylistComponentLandscapeImg, ContentsEmptyState, LiveLessonComponent } from '@lean-body/components/molecules';
var useState = React.useState;
var TAB_QUERY_PARAMS = 'tab';
var InstructorTabs = {
    all: 'すべて',
    series: 'シリーズ',
    lesson: 'レッスン',
    lifestyle: 'ライフスタイル',
    live: 'LIVE',
    playlist: 'プレイリスト',
};
var LESSON_ITEM_WIDTH = 350;
var LESSON_ITEM_WIDTH_XL = 390;
var MARGIN_CONTAINER_SP = 20;
var MARGIN_CONTAINER_MD = 48;
var MARGIN_CONTAINER_XL = 80;
export var MainInstructorPage = function (_a) {
    var apiClient = _a.apiClient, instructorID = _a.instructorID;
    var isXL = useXlMediaQuery();
    var isSM = useSmMediaQuery();
    var navigate = useNavigate();
    var query = useQuery();
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = React.useState(null), instructor = _c[0], setInstructor = _c[1];
    var _d = React.useState([]), programs = _d[0], setPrograms = _d[1];
    var _e = React.useState([]), playlists = _e[0], setPlaylists = _e[1];
    var _f = React.useState([]), lessons = _f[0], setLessons = _f[1];
    var _g = React.useState([]), liveLessons = _g[0], setLiveLessons = _g[1];
    var _h = React.useState([]), lifeStyleLessons = _h[0], setLifeStyleLessons = _h[1];
    // すべてタブの為のリソース
    var _j = React.useState([]), programsForAllTab = _j[0], setProgramsForAllTab = _j[1];
    var _k = React.useState([]), playlistsForAllTab = _k[0], setPlaylistsForAllTab = _k[1];
    var _l = React.useState([]), lessonsForAllTab = _l[0], setLessonsForAllTab = _l[1];
    var _m = React.useState([]), liveLessonsForAllTab = _m[0], setLiveLessonsForAllTab = _m[1];
    var _o = React.useState(InstructorTabs[query.get(TAB_QUERY_PARAMS)] || InstructorTabs.all), tab = _o[0], setTab = _o[1];
    var tabKey = React.useMemo(function () {
        return Object.keys(InstructorTabs).find(function (key) { return InstructorTabs[key] === tab; });
    }, [tab]);
    var lessonFilterExceptLifestyle = 'except_lifestyle';
    var lessonFilterOnlyLifestyle = 'only_lifestyle';
    React.useEffect(function () {
        if (tab === InstructorTabs.all) {
            navigate(routes.INSTRUCTOR.compile({ id: instructorID.toString() }), { replace: true });
        }
        else {
            navigate("".concat(routes.INSTRUCTOR.compile({ id: instructorID.toString() }), "?").concat(TAB_QUERY_PARAMS, "=").concat(tabKey), {
                replace: true,
            });
        }
        setIsLoading(true);
        if (!instructor) {
            apiClient.fetchTrainer(instructorID).then(function (v) { return setInstructor(v); });
        }
        var fetchContents = function () {
            var page = 0;
            var limit = 0;
            switch (tab) {
                case InstructorTabs.series:
                    return apiClient.fetchProgramByInstructorID(instructorID, page, limit).then(function (v) {
                        setPrograms(v);
                    });
                case InstructorTabs.lesson:
                    return apiClient
                        .fetchLessonByInstructorID(instructorID, lessonFilterExceptLifestyle, page, limit)
                        .then(function (v) {
                        setLessons(v);
                    });
                case InstructorTabs.lifestyle:
                    return apiClient.fetchLessonByInstructorID(instructorID, lessonFilterOnlyLifestyle, page, limit).then(function (v) {
                        setLifeStyleLessons(v);
                    });
                case InstructorTabs.live:
                    return apiClient.fetchLiveLessonByInstructorID(instructorID, page, limit).then(function (v) {
                        setLiveLessons(v);
                    });
                case InstructorTabs.playlist:
                    return apiClient.fetchPlaylistByInstructorID(instructorID, page, limit).then(function (v) {
                        setPlaylists(v);
                    });
                default: {
                    // すべてタブのリソース取得
                    var page_1 = 1;
                    var limit_1 = 10;
                    var lessonLimit = 9; // レッスンのみ9件取得のデザインのため
                    return Promise.all([
                        apiClient.fetchProgramByInstructorID(instructorID, page_1, limit_1).then(function (v) { return setProgramsForAllTab(v); }),
                        apiClient
                            .fetchLessonByInstructorID(instructorID, lessonFilterExceptLifestyle, page_1, lessonLimit)
                            .then(function (v) { return setLessonsForAllTab(v); }),
                        apiClient.fetchLiveLessonByInstructorID(instructorID, page_1, limit_1).then(function (v) { return setLiveLessonsForAllTab(v); }),
                        apiClient.fetchPlaylistByInstructorID(instructorID, page_1, limit_1).then(function (v) { return setPlaylistsForAllTab(v); }),
                    ]).then();
                }
            }
        };
        fetchContents().then(function () { return setIsLoading(false); });
    }, [tab]);
    if (!instructor) {
        return React.createElement(PageLoading, null);
    }
    var isEmptyAllTab = !programsForAllTab.length && !lessonsForAllTab.length && !liveLessonsForAllTab.length && !playlistsForAllTab.length;
    return (React.createElement("div", null,
        React.createElement("div", { className: css(styles.header) },
            React.createElement(TrainerDetailSection, { trainer: instructor }),
            React.createElement(InstaAndSiteLink, { containerClassName: css(styles.snsLinksContainer), linkContentClassName: css(styles.snsLinkContent), trainer: instructor })),
        React.createElement(UnderbarTabs, { setTab: setTab, currentTab: tab, tabs: InstructorTabs, classObject: styles.tabWrapper }),
        React.createElement("div", { className: css(styles.tabContainer) }, tab === InstructorTabs.all ? (React.createElement(React.Fragment, null,
            !isLoading && isEmptyAllTab && React.createElement(ContentsEmptyState, { classObject: styles.contentsEmptyStateContainer }),
            programsForAllTab.length > 0 && (React.createElement(LazyLoad, null,
                React.createElement("div", { className: css(styles.contentInnerSection) },
                    React.createElement("div", { className: css(styles.sectionTitle) },
                        "\u30B7\u30EA\u30FC\u30BA",
                        React.createElement(MoreLink, { onClick: function () { return setTab(InstructorTabs.series); } })),
                    React.createElement(ProgramComponentCarousel, { programs: programsForAllTab, addFavoriteProgram: apiClient.addFavoriteProgram, unFavoriteProgram: apiClient.unFavoriteProgram, itemMarginRight: LIST_ITEM_MARGIN_RIGHT, isMin: true })))),
            lessonsForAllTab.length > 0 && (React.createElement(LazyLoad, null,
                React.createElement("div", { className: css(styles.contentInnerSection) },
                    React.createElement("div", { className: css(styles.sectionTitle) },
                        "\u30EC\u30C3\u30B9\u30F3",
                        React.createElement(MoreLink, { onClick: function () { return setTab(InstructorTabs.lesson); } })),
                    React.createElement(LessonComponentCarouselSeparate, { lessons: lessonsForAllTab, addFavoriteLesson: apiClient.addFavoriteLesson, unFavoriteLesson: apiClient.unFavoriteLesson, itemSize: isXL ? LESSON_ITEM_WIDTH_XL : LESSON_ITEM_WIDTH })))),
            liveLessonsForAllTab.length > 0 && (React.createElement(LazyLoad, null,
                React.createElement("div", { className: css(styles.contentInnerSection) },
                    React.createElement("div", { className: css(styles.sectionTitle) },
                        "LEAN BODY LIVE",
                        React.createElement(MoreLink, { onClick: function () { return setTab(InstructorTabs.live); } })),
                    React.createElement(VerticalListArrows, { classObject: !isXL && [styles.contentInnerSection], itemSize: !isXL ? ITEM_WIDTH_SP : ITEM_WIDTH_OVER_XL, itemsPadding: isXL && 0, items: liveLessonsForAllTab.map(function (liveLesson, i) {
                            return (React.createElement("div", { className: css(styles.liveLessonContainer), key: i },
                                React.createElement(LiveLessonComponent, { liveLesson: liveLesson, isMin: isSM, isVertical: false })));
                        }) })))),
            playlistsForAllTab.length > 0 && (React.createElement("div", { className: css(styles.contentInnerSection) },
                React.createElement("div", { className: css(styles.sectionTitle) },
                    "\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8",
                    React.createElement(MoreLink, { onClick: function () { return setTab(InstructorTabs.playlist); } })),
                React.createElement(PlaylistComponentCarousel, { playlists: playlistsForAllTab, addFavoriteProgram: apiClient.addFavoriteProgram, unFavoriteProgram: apiClient.unFavoriteProgram, itemMarginRight: LIST_ITEM_MARGIN_RIGHT, isMin: true }))))) : tab === InstructorTabs.series ? (React.createElement(React.Fragment, null,
            !isLoading && !programs.length && React.createElement(ContentsEmptyState, { classObject: styles.contentsEmptyStateContainer }),
            React.createElement("div", { className: css(styles.listItems) }, programs.map(function (program, i) { return (React.createElement(ProgramComponent, { key: i, program: program, addFavoriteProgram: apiClient.addFavoriteProgram, unFavoriteProgram: apiClient.unFavoriteProgram, isVertical: !isSM })); })),
            isLoading && React.createElement(MoreLoading, null))) : tab === InstructorTabs.lesson ? (React.createElement(React.Fragment, null,
            !isLoading && !lessons.length && React.createElement(ContentsEmptyState, { classObject: styles.contentsEmptyStateContainer }),
            React.createElement("div", { className: css(styles.listLessonItems) }, lessons.map(function (lesson, i) { return (React.createElement(LessonComponent, { key: i, lesson: lesson, addFavoriteLesson: apiClient.addFavoriteLesson, unFavoriteLesson: apiClient.unFavoriteLesson, isVertical: !isSM })); })),
            isLoading && React.createElement(MoreLoading, null))) : tab === InstructorTabs.lifestyle ? (React.createElement(React.Fragment, null,
            !isLoading && !lifeStyleLessons.length && (React.createElement(ContentsEmptyState, { classObject: styles.contentsEmptyStateContainer })),
            React.createElement("div", { className: css(styles.listLessonItems) }, lifeStyleLessons.map(function (lesson, i) { return (React.createElement(LessonComponent, { key: i, lesson: lesson, addFavoriteLesson: apiClient.addFavoriteLesson, unFavoriteLesson: apiClient.unFavoriteLesson, isVertical: !isSM })); })),
            isLoading && React.createElement(MoreLoading, null))) : tab === InstructorTabs.live ? (React.createElement(React.Fragment, null,
            !isLoading && !liveLessons.length && (React.createElement(ContentsEmptyState, { classObject: styles.contentsEmptyStateContainer })),
            React.createElement("div", { className: css(styles.listItems) }, liveLessons.map(function (liveLesson, i) { return (React.createElement("div", { className: css(styles.liveLessonContainer), key: i },
                React.createElement(LiveLessonComponent, { liveLesson: liveLesson, isMin: isSM, isVertical: !isSM }))); })),
            isLoading && React.createElement(MoreLoading, null))) : (React.createElement(React.Fragment, null,
            !isLoading && !playlists.length && React.createElement(ContentsEmptyState, { classObject: styles.contentsEmptyStateContainer }),
            React.createElement("div", { className: css(styles.listItems) }, isSM ? (React.createElement(PlaylistTileCarousel, { playlists: playlists, classObject: styles.programCarouselContainer, addFavoriteProgram: apiClient.addFavoriteProgram, unFavoriteProgram: apiClient.unFavoriteProgram })) : (playlists.map(function (playlist, i) { return (React.createElement(PlaylistComponentLandscapeImg, { key: i, playlist: playlist, addFavoriteProgram: apiClient.addFavoriteProgram, unFavoriteProgram: apiClient.unFavoriteProgram, isVertical: !isSM })); }))),
            isLoading && React.createElement(MoreLoading, null))))));
};
var styles = StyleSheet.create({
    header: (_a = {
            marginLeft: MARGIN_CONTAINER_SP,
            marginRight: MARGIN_CONTAINER_SP
        },
        _a[MediaBreakPointUp.MD] = {
            marginLeft: MARGIN_CONTAINER_MD,
            marginRight: MARGIN_CONTAINER_MD,
        },
        _a[MediaBreakPointUp.XL] = {
            marginLeft: MARGIN_CONTAINER_XL,
            marginRight: MARGIN_CONTAINER_XL,
        },
        _a),
    snsLinksContainer: (_b = {
            marginLeft: 0,
            marginBottom: 29
        },
        _b[MediaBreakPointUp.MD] = {
            marginLeft: 170,
        },
        _b),
    snsLinkContent: (_c = {
            justifyContent: 'flex-end'
        },
        _c[MediaBreakPointUp.MD] = {
            justifyContent: 'initial',
        },
        _c),
    contentInnerSection: {
        marginBottom: 24,
    },
    sectionTitle: (_d = {
            fontSize: 16,
            fontWeight: 'bold',
            marginBottom: 22,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        _d[MediaBreakPointUp.MD] = {
            fontSize: 18,
        },
        _d),
    tabWrapper: (_e = {
            marginBottom: 16,
            marginRight: 20,
            marginLeft: 20,
            overflow: 'hidden',
            borderBottom: "1px solid ".concat(Colors.BorderLightGray)
        },
        _e[MediaBreakPointUp.MD] = {
            marginTop: 24,
        },
        _e[MediaBreakPointUp.XL] = {
            marginTop: 36,
        },
        _e),
    listItems: (_f = {
            display: 'flex',
            gap: '12px 0',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        _f[MediaBreakPointUp.MD] = {
            gap: '20px 0',
        },
        _f),
    listLessonItems: (_g = {
            display: 'flex',
            gap: '12px 0',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        _g[MediaBreakPointUp.MD] = {
            gap: '20px 0',
        },
        _g),
    liveLessonContainer: {
        width: '100%',
    },
    tabContainer: (_h = {
            marginLeft: MARGIN_CONTAINER_SP,
            marginRight: MARGIN_CONTAINER_SP
        },
        _h[MediaBreakPointUp.MD] = {
            marginLeft: MARGIN_CONTAINER_MD,
            marginRight: MARGIN_CONTAINER_MD,
        },
        _h[MediaBreakPointUp.XL] = {
            marginLeft: MARGIN_CONTAINER_XL,
            marginRight: MARGIN_CONTAINER_XL,
        },
        _h),
    programCarouselContainer: (_j = {
            marginTop: 8
        },
        _j[MediaBreakPointUp.MD] = {
            marginTop: 22,
        },
        _j),
    liveDateContainer: {},
    contentsEmptyStateContainer: (_k = {
            marginTop: 89
        },
        _k[MediaBreakPointUp.MD] = {
            marginTop: 156,
        },
        _k),
});
