var REFERRERS = [
    // クラブごとに下記ドメインからの遷移可能性があり。
    // （福利厚生倶楽部）
    'https://www.fukuri.jp',
    'https://sp.fukuri.jp',
    // （クラブオフアライアンス）
    'https://www.club-off.com',
    'https://sp.club-off.com',
    'https://www.gaku-wari.com',
    'https://sp.gaku-wari.com',
    'https://www.fromnow.jp',
    'https://sp.fromnow.jp',
    'http://www.offtime-members.com',
    'http://sp.offtime-members.com',
];
var ReloclubService = /** @class */ (function () {
    function ReloclubService(localStorage) {
        var _this = this;
        Object.defineProperty(this, "localStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "saveKidIfShould", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (kid) {
                var referrer = document.referrer; // その時々のリファラが欲しいのでローカルストレージからは取得しない
                if (!referrer)
                    return;
                var domain = new URL(referrer).origin;
                if (!REFERRERS.includes(domain))
                    return;
                _this.localStorage.saveReloclubKid(kid);
            }
        });
        this.localStorage = localStorage;
    }
    return ReloclubService;
}());
export { ReloclubService };
