import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../styles';
import { CircleCheckIcon } from '../atoms';
export var DailyTaskSubtask = function (_a) {
    var label = _a.label, isComplete = _a.isComplete, taskName = _a.taskName, subTaskName = _a.subTaskName, onClick = _a.onClick, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject), onClick: function (e) {
            e.stopPropagation();
            onClick(taskName, subTaskName);
        } },
        React.createElement(CircleCheckIcon, { color: isComplete ? Colors.ProgressGreen : Colors.BackgroundGrayD, classObject: styles.checkIcon, filled: true }),
        React.createElement("span", { className: css(styles.label) }, label)));
};
var styles = StyleSheet.create({
    checkIcon: {
        width: 24,
        height: 24,
    },
    container: {
        padding: '12px 4px',
        borderRadius: 14,
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        justifyContent: 'center',
        alignItems: 'center',
    },
    label: {
        fontSize: 13,
        fontWeight: 700,
        lineHeight: '140%',
    },
});
