import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { CircleCheckIcon } from '../atoms/icons/circle_check_icon';
import { Colors } from '../styles';
export var CCFormAmazonPay = function (_a) {
    var classObject = _a.classObject, openSection = _a.openSection, onClickSection = _a.onClickSection;
    return (React.createElement("div", { className: css(styles.container, openSection && styles.sectionOpened, classObject) },
        React.createElement("div", { className: css(styles.sectionTitle), onClick: onClickSection },
            openSection ? (React.createElement(CircleCheckIcon, { classObject: styles.checkIcon, filled: true, color: Colors.Black })) : (React.createElement("div", { className: css(styles.checkIcon, styles.notChecked) })),
            "Amazon Pay\u3067\u304A\u652F\u6255\u3044"),
        "Amazon\u306B\u767B\u9332\u3057\u305F\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3084\u304A\u652F\u6255\u3044\u60C5\u5831\u3092\u305D\u306E\u307E\u307E\u4F7F\u7528\u3067\u304D\u307E\u3059"));
};
var styles = StyleSheet.create({
    container: {
        padding: '16px 14px',
        border: "1px solid ".concat(Colors.PrimaryDark),
        borderRadius: 4,
        maxHeight: 52,
        transition: 'all .2s',
        overflow: 'hidden',
        fontWeight: 'normal',
    },
    sectionOpened: {
        maxHeight: 600,
    },
    sectionTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
        fontWeight: 'bold',
    },
    checkIcon: {
        margin: '0 8px 0 4px',
        width: 16,
        height: 16,
    },
    notChecked: {
        backgroundColor: Colors.Gray,
        borderRadius: 100,
    },
});
