import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
export var InputRadioButton = function (props) {
    var classObject = props.classObject, items = props.items, submitValue = props.submitValue, setSubmitValue = props.setSubmitValue;
    var _a = React.useState(submitValue), currentValue = _a[0], setCurrentValue = _a[1];
    var onChange = function (e) {
        setSubmitValue(e.target.value);
        setCurrentValue(e.target.value);
    };
    return (React.createElement("div", { className: css(styles.container, classObject) }, items.map(function (_a, index) {
        var value = _a.value, label = _a.label;
        return (React.createElement("label", { className: css(styles.labelContainer), key: index },
            value === currentValue ? (React.createElement("svg", { className: css(styles.icon), viewBox: "0 0 17 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("circle", { stroke: Colors.PrimaryDark, cx: "8.5", cy: "8.5", r: "7.5", fill: "white" }),
                React.createElement("circle", { fill: Colors.PrimaryDark, cx: "8.5", cy: "8.5", r: "4" }))) : (React.createElement("svg", { className: css(styles.icon), viewBox: "0 0 17 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("circle", { stroke: Colors.Gray, cx: "8.5", cy: "8.5", r: "7.5", fill: "white" }))),
            React.createElement("span", { className: css(styles.text) }, label),
            React.createElement("input", { type: "radio", name: "radioInput", value: value, onChange: onChange, className: css(styles.input) })));
    })));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    labelContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 16,
        cursor: 'pointer',
        ':last-of-type': {
            marginRight: 0,
        },
    },
    input: {
        display: 'none',
        visibility: 'hidden',
    },
    icon: {
        marginRight: 4,
        width: 17,
        height: 17,
    },
    text: {
        fontSize: 12,
    },
});
