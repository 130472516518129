var _a, _b, _c, _d;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { InitialGuideHowToDetail } from '../molecules';
export var InitialGuideSearchDetail = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u898B\u3064\u3051\u308B\u3068\u306F\uFF1F"),
            React.createElement("div", { className: css(styles.sectionTitleBorder) }),
            React.createElement("div", { className: css(styles.topImgBox) },
                React.createElement("img", { className: css(styles.topImg), src: "/assets/images/initial_guide/search/search_top.png" })),
            React.createElement("div", { className: css(styles.description) },
                "\u3084\u308A\u305F\u3044\u30EC\u30C3\u30B9\u30F3\u304C\u306A\u304B\u306A\u304B\u898B\u3064\u304B\u3089\u306A\u3044\u65B9\u3078\uFF01",
                React.createElement("br", null),
                "\u30EC\u30C3\u30B9\u30F3\u3092\u691C\u7D22\u3057\u305F\u308A\u3001\u6761\u4EF6\u3067\u7D5E\u308A\u8FBC\u3080\u3053\u3068\u3067\u3001\u304D\u3063\u3068\u304A\u6C17\u306B\u5165\u308A\u306E\u30EC\u30C3\u30B9\u30F3\u304C\u898B\u3064\u304B\u308A\u307E\u3059\u3088\uFF01")),
        React.createElement("div", { className: css(styles.section, styles.noMarginBottom) },
            React.createElement("div", { className: css(styles.howToSection) },
                React.createElement("div", { className: css(styles.sectionTitle) }, "\u898B\u3064\u3051\u308B\u3067\u3067\u304D\u308B\u3053\u3068"),
                React.createElement("div", { className: css(styles.sectionTitleBorder) }),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/search/search_by_various_word.png", classObject: styles.howToDetail, imgClassObject: styles.searchByVariousWordImg, title: "\u30B8\u30E3\u30F3\u30EB/\u96E3\u6613\u5EA6/\u6642\u9593/\u30A4\u30F3\u30B9\u30C8\u30E9\u30AF\u30BF\u30FC\u3067\u7D5E\u308A\u8FBC\u307F" }, "\u6C17\u306B\u306A\u308B\u30EF\u30FC\u30C9\u3067\u7D5E\u308A\u8FBC\u3093\u3067\u307F\u307E\u3057\u3087\u3046"),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/search/search_by_keyword.png", classObject: styles.howToDetail, imgClassObject: styles.searchByKeywordImg, title: "\u30AD\u30FC\u30EF\u30FC\u30C9\u3067\u691C\u7D22" }, "\u4F53\u306E\u304A\u60A9\u307F\u3001\u30B8\u30E3\u30F3\u30EB\u306A\u3069\u3001\u601D\u3044\u3064\u304F\u30EF\u30FC\u30C9\u3067\u691C\u7D22\u3057\u3066\u307F\u307E\u3057\u3087\u3046"),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/search/refined_search.png", imgClassObject: styles.refinedSearchImg, title: "\u30EC\u30C3\u30B9\u30F3\u3001\u30B7\u30EA\u30FC\u30BA\u3001\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u9078\u307C\u3046" }, "\u3055\u3089\u306B\u7D5E\u308A\u8FBC\u3093\u3067\u3001\u3074\u3063\u305F\u308A\u306A\u30EC\u30C3\u30B9\u30F3\u3092\u53D7\u3051\u3088\u3046\uFF01"))),
        React.createElement("div", { className: css(styles.searchPoint) },
            React.createElement("img", { className: css(styles.pointImg), src: "/assets/images/initial_guide/search/favorite_lesson.png" }),
            React.createElement("div", { className: css(styles.pointText) },
                React.createElement("div", { className: css(styles.pointTitle) },
                    "\u597D\u304D\u306A\u30EC\u30C3\u30B9\u30F3\u304C\u898B\u3064\u304B\u3063\u305F\u3089",
                    React.createElement("br", null),
                    "\u304A\u6C17\u306B\u5165\u308A\u3057\u3088\u3046"),
                React.createElement("div", { className: css(styles.pointDescription) }, "\u30CF\u30FC\u30C8\u30DE\u30FC\u30AF\u3092\u62BC\u3059\u3068\u3001\u304A\u6C17\u306B\u5165\u308A\u306E\u30EC\u30C3\u30B9\u30F3\u306B\u4FDD\u5B58\u3055\u308C\u307E\u3059\u3002\u5F8C\u3067\u8996\u8074\u3059\u308B\u306E\u306B\u4FBF\u5229\u3067\u3059\u3002")))));
};
var styles = StyleSheet.create({
    section: (_a = {
            marginBottom: 40,
            paddingTop: 30,
            paddingLeft: 24,
            paddingRight: 24
        },
        _a[MediaBreakPointUp.MD] = {
            marginBottom: 0,
            paddingLeft: 40,
            paddingRight: 40,
        },
        _a),
    noMarginBottom: {
        marginBottom: 0,
    },
    sectionTitle: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: '1.44px',
        height: 25,
    },
    sectionTitleBorder: {
        border: "3px solid ".concat(Colors.PrimaryDark),
        borderRadius: 3,
        width: 60,
        margin: '12px auto 30px',
    },
    topImgBox: {
        textAlign: 'center',
        marginTop: 35,
        marginBottom: 35,
    },
    topImg: {
        height: 77,
    },
    description: (_b = {
            backgroundColor: Colors.CyanLightest,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '160%',
            letterSpacing: '0.56px',
            padding: 24,
            marginBottom: 40,
            borderRadius: 10
        },
        _b[MediaBreakPointUp.MD] = {
            marginBottom: 10,
        },
        _b),
    howToSection: (_c = {},
        _c[MediaBreakPointUp.MD] = {
            marginLeft: 60,
            marginRight: 60,
        },
        _c),
    howToDetail: {
        marginBottom: 30,
    },
    searchByVariousWordImg: {
        height: 158,
    },
    searchByKeywordImg: {
        height: 223,
    },
    refinedSearchImg: {
        height: 176,
    },
    searchPoint: (_d = {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: Colors.PrimaryLighter,
            marginTop: 30,
            marginBottom: 30,
            padding: '40px 24px',
            gap: 16
        },
        _d[MediaBreakPointUp.MD] = {
            marginTop: 40,
            padding: '40px 60px',
        },
        _d),
    pointImg: {
        height: 114,
    },
    pointText: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        gap: 16,
    },
    pointTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: '1.44px',
    },
    pointDescription: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: '0.56px',
    },
});
