import { Gender } from '@lean-body/src/view/constants';
import { AVATAR_DEFAULT_SRC } from '@lean-body/components/atoms/avatar_uploader';
var UserProfile = /** @class */ (function () {
    function UserProfile(profile, config) {
        Object.defineProperty(this, "userId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalCal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalWatchDuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalSteps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "watchedCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "gender", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "birthday", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "height", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "weight", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "prefecture", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userNameMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "profilePictureSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isAutoPlayNextVideo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "promotionDisplayedAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "showPromotionDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "showGoalAchievedDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "exerciseFrequency", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activityLevel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isGoalsSet", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "worries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "interests", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "DEFAULT_USER_NAME", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'No Name'
        });
        Object.defineProperty(this, "MAX_USER_NAME_LENGTH", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 15
        });
        Object.defineProperty(this, "UserNameMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                Success: '',
                ExceedLengthLimitError: "".concat(this.MAX_USER_NAME_LENGTH, "\u6587\u5B57\u4EE5\u5185\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044\u3002"),
                UpdateError: '更新に失敗しました',
            }
        });
        this.userId = profile.userId;
        this.totalCal = profile.totalCal;
        this.totalWatchDuration = profile.totalWatchDuration;
        this.totalSteps = profile.totalSteps;
        this.watchedCount = profile.watchedCount;
        this.userName = profile.userName;
        this.gender = profile.gender;
        this.birthday = profile.birthday ? profile.birthday.substring(0, 10) : '';
        this.height = profile.height;
        this.weight = profile.weight;
        this.prefecture = profile.prefecture;
        this.profilePictureSrc = profile.profilePictureSrc;
        this.isAutoPlayNextVideo = profile.isAutoPlayNextVideo;
        this.exerciseFrequency = profile.exerciseFrequency;
        this.activityLevel = profile.activityLevel;
        this.worries = profile.worries;
        this.interests = profile.interests;
        this.promotionDisplayedAt = profile.promotionDisplayedAt;
        this.showPromotionDialog = profile.showPromotionDialog;
        this.showGoalAchievedDialog = profile.showGoalAchievedDialog;
        this.isGoalsSet = profile.isGoalsSet;
        this.config = config;
    }
    Object.defineProperty(UserProfile.prototype, "initialName", {
        get: function () {
            return this.userName ? this.userName : this.DEFAULT_USER_NAME;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserProfile.prototype, "genderName", {
        get: function () {
            var _this = this;
            var _a;
            var key = Object.keys(Gender).find(function (key) {
                return _this.gender === Gender[key].value;
            });
            return (_a = Gender[key]) === null || _a === void 0 ? void 0 : _a.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserProfile.prototype, "worriesName", {
        get: function () {
            if (!this.worries.length) {
                return '';
            }
            return this.worries
                .map(function (worry) {
                return worry['content'];
            })
                .join(',');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserProfile.prototype, "interestsName", {
        get: function () {
            if (!this.interests.length) {
                return '';
            }
            return this.interests
                .map(function (interest) {
                return interest['content'];
            })
                .join(',');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserProfile.prototype, "worriesIdsToString", {
        get: function () {
            if (!this.worries.length) {
                return [];
            }
            return this.worries.map(function (worry) {
                return "".concat(worry['id']);
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserProfile.prototype, "interestsIdsToString", {
        get: function () {
            if (!this.interests.length) {
                return [];
            }
            return this.interests.map(function (interest) {
                return "".concat(interest['id']);
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserProfile.prototype, "pictureSrc", {
        get: function () {
            return this.profilePictureSrc ? this.config.cdnUserBaseURL + '/' + this.profilePictureSrc : AVATAR_DEFAULT_SRC;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserProfile.prototype, "validateUserName", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userName) {
            if (userName.length > this.MAX_USER_NAME_LENGTH) {
                this.userNameMessage = this.UserNameMessages.ExceedLengthLimitError;
                return false;
            }
            this.userNameMessage = this.UserNameMessages.Success;
            if (userName !== this.DEFAULT_USER_NAME) {
                return true;
            }
            if (userName.length > 0) {
                return true;
            }
        }
    });
    Object.defineProperty(UserProfile.prototype, "validateHeight", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (height) {
            var minHeight = 0;
            var maxHeight = 300;
            return minHeight <= height && height <= maxHeight;
        }
    });
    return UserProfile;
}());
export { UserProfile };
