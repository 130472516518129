var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles/';
import { Dialog, DIALOG_IMAGE_HEIGHT, DIALOG_IMAGE_HEIGHT_XL, DIALOG_WIDTH, DIALOG_WIDTH_XL, TwitterEllipseButton, } from '@lean-body/components/molecules';
import { Textarea, CanvasWithCenterImage } from '@lean-body/components/atoms';
import { canvasToFile } from '@lean-body/src/util/canvas';
import { CloseButtonLabel } from '../atoms/dialog_close_button';
import { useXlMediaQuery } from '@lean-body/src/util';
var MAX_TWEET_TEXT = 140;
export var TwitterFormDialog = function (_a) {
    var defaultText = _a.defaultText, onClickCancel = _a.onClickCancel, imageURL = _a.imageURL, submit = _a.submit, imgWidth = _a.imgWidth, imgHeight = _a.imgHeight, centerImageWidth = _a.centerImageWidth, aspectRatio = _a.aspectRatio, closeButtonLabel = _a.closeButtonLabel, strictSameSize = _a.strictSameSize;
    var isXL = useXlMediaQuery();
    var canvas = React.useRef();
    var _b = React.useState(defaultText), tmpText = _b[0], setTmpText = _b[1];
    var _c = React.useState(''), warningText = _c[0], setwarningText = _c[1];
    var _d = React.useState(null), file = _d[0], setFile = _d[1];
    var onChange = function (event) {
        setTmpText(event.target.value);
    };
    var onClickSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (tmpText.replace(/\n/g, '').length > MAX_TWEET_TEXT) {
                        setwarningText("".concat(MAX_TWEET_TEXT, "\u6587\u5B57\u4EE5\u4E0A\u306F\u5165\u529B\u3067\u304D\u307E\u305B\u3093\u3002"));
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, submit(tmpText, file)];
                case 1:
                    _a.sent();
                    close();
                    return [2 /*return*/];
            }
        });
    }); };
    var onLoad = function () { return __awaiter(void 0, void 0, void 0, function () {
        var canvasFile;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, canvasToFile(canvas.current, 'img_file.jpg', 'image/jpg')];
                case 1:
                    canvasFile = _a.sent();
                    setFile(canvasFile);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Dialog, { closeButtonLabel: closeButtonLabel || CloseButtonLabel.Cancel, onClickCancel: onClickCancel, disableBackgroundCancel: true, isCloseButton: true, strictSameSize: strictSameSize, content: React.createElement("div", { className: css(styles.container) },
            React.createElement(CanvasWithCenterImage, { ref: canvas, imgSrc: imageURL, width: imgWidth || (isXL ? DIALOG_WIDTH_XL : DIALOG_WIDTH), height: imgHeight || (isXL ? DIALOG_IMAGE_HEIGHT_XL : DIALOG_IMAGE_HEIGHT), centerImageWidth: centerImageWidth || (isXL ? DIALOG_WIDTH_XL : DIALOG_WIDTH), aspectRatio: aspectRatio, onLoad: onLoad, classObject: styles.canvas }),
            React.createElement("div", { className: css(styles.tweetArea) },
                React.createElement(Textarea, { className: css(styles.textarea), onChange: onChange, value: tmpText, placeholder: 'ツイートする文言を入力しよう！' }),
                warningText.length > 0 && React.createElement("div", { className: css(styles.warningText) }, warningText),
                React.createElement("div", { className: css(styles.buttonOuter) },
                    React.createElement(TwitterEllipseButton, { text: 'ポストする', onClick: onClickSubmit, classObject: styles.button })))) }));
};
var styles = StyleSheet.create({
    container: {
        height: '100%',
        borderRadius: 8,
        overflow: 'hidden',
    },
    tweetArea: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '16px 18px 20px',
    },
    textarea: {
        width: '100%',
        height: 134,
        minHeight: '54.5px',
        fontSize: 14,
        padding: 16,
        backgroundColor: Colors.BackgroundLightGray,
        borderRadius: 4,
        border: 'none',
        '::placeholder': {
            color: Colors.GrayDarkest,
        },
    },
    warningText: {
        height: 20,
        fontSize: 12,
        color: Colors.Red,
        textAlign: 'center',
        marginTop: 16,
    },
    buttonOuter: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 16,
        width: '100%',
    },
    button: (_a = {
            width: '100%'
        },
        _a[MediaBreakPointUp.XL] = {
            width: 'auto',
            minWidth: 290,
        },
        _a),
    canvas: {
        borderRadius: '8px 8px 0 0',
    },
});
