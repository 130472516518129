import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var PlaylistIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 32,
            height: 32,
        },
    });
    var iconColor = color || Colors.PrimaryDark;
    return (React.createElement("svg", { className: css(styles.icon, classObject), width: "33", height: "32", viewBox: "0 0 33 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: iconColor, d: "M4.58333 8.66667C4.20556 8.66667 3.88911 8.53911 3.634 8.284C3.378 8.028 3.25 7.71111 3.25 7.33333C3.25 6.95556 3.378 6.63867 3.634 6.38267C3.88911 6.12756 4.20556 6 4.58333 6H27.0833C27.4611 6 27.778 6.12756 28.034 6.38267C28.2891 6.63867 28.4167 6.95556 28.4167 7.33333C28.4167 7.71111 28.2891 8.028 28.034 8.284C27.778 8.53911 27.4611 8.66667 27.0833 8.66667H4.58333ZM4.58333 16C4.20556 16 3.88911 15.872 3.634 15.616C3.378 15.3609 3.25 15.0444 3.25 14.6667C3.25 14.2889 3.378 13.972 3.634 13.716C3.88911 13.4609 4.20556 13.3333 4.58333 13.3333H27.0833C27.4611 13.3333 27.778 13.4609 28.034 13.716C28.2891 13.972 28.4167 14.2889 28.4167 14.6667C28.4167 15.0444 28.2891 15.3609 28.034 15.616C27.778 15.872 27.4611 16 27.0833 16H4.58333ZM4.58333 23.3333C4.20556 23.3333 3.88911 23.2053 3.634 22.9493C3.378 22.6942 3.25 22.3778 3.25 22C3.25 21.6222 3.378 21.3053 3.634 21.0493C3.88911 20.7942 4.20556 20.6667 4.58333 20.6667H12.5833C12.9611 20.6667 13.278 20.7942 13.534 21.0493C13.7891 21.3053 13.9167 21.6222 13.9167 22C13.9167 22.3778 13.7891 22.6942 13.534 22.9493C13.278 23.2053 12.9611 23.3333 12.5833 23.3333H4.58333ZM20.1167 27.4L17.25 24.5333C17.0056 24.2889 16.8833 23.9778 16.8833 23.6C16.8833 23.2222 17.0056 22.9111 17.25 22.6667C17.4944 22.4222 17.8002 22.3 18.1673 22.3C18.5336 22.3 18.85 22.4222 19.1167 22.6667L21.05 24.5333L25.7833 19.8C26.0278 19.5556 26.3331 19.4333 26.6993 19.4333C27.0664 19.4333 27.3833 19.5667 27.65 19.8333C27.9167 20.1 28.0447 20.4222 28.034 20.8C28.0224 21.1778 27.8833 21.5 27.6167 21.7667L21.9833 27.4C21.7389 27.6444 21.4278 27.7667 21.05 27.7667C20.6722 27.7667 20.3611 27.6444 20.1167 27.4Z" })));
};
