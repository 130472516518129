import { StyleSheet } from 'aphrodite';
// https://github.com/fjc0k/ant-design-icons/blob/master/dist/anticons.json
var antIconFontFamily = {
    fontFamily: 'anticon',
    // woff -> chrome、firefox
    // ttf -> chrome、firefox、opera、Safari, Android, iOS 4.2+
    src: 'url("/assets/fonts/anticon.woff") format("woff"), ' + 'url("/assets/fonts/anticon.ttf") format("truetype")',
};
export var icon = StyleSheet.create({
    anticon: {
        display: 'inline-block',
        fontStyle: 'normal',
        verticalAlign: 'baseline',
        textAlign: 'center',
        textTransform: 'none',
        lineHeight: 1,
        textRendering: 'optimizeLegibility',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        ':before': {
            display: 'block',
            fontFamily: [antIconFontFamily], // https://github.com/Khan/aphrodite/issues/243
        },
    },
    anticonHome: {
        ':before': {
            content: '"\\e65e"',
        },
    },
    anticonCalendar: {
        ':before': {
            content: '"\\e6bb"',
        },
    },
    anticonAppstoreO: {
        ':before': {
            content: '"\\e695"',
        },
    },
    anticonUserGroupAdd: {
        ':before': {
            content: '"\\e6dd"',
        },
    },
    anticonMail: {
        ':before': {
            content: '"\\e659"',
        },
    },
    anticonUser: {
        ':before': {
            content: '"\\e66a"',
        },
    },
    anticonFacebook: {
        ':before': {
            content: '"\\e9b8"',
        },
    },
    anticonStar: {
        ':before': {
            content: '"\\e660"',
        },
    },
    anticonStarO: {
        ':before': {
            content: '"\\e661"',
        },
    },
    anticonInfoCircleO: {
        ':before': {
            content: '"\\e629"',
        },
    },
    anticonExternalLink: {
        ':before': {
            content: '"\\e691"',
        },
    },
    anticonCheck: {
        ':before': {
            content: '"\\E6C5"',
        },
    },
    anticonCheck2: {
        ':before': {
            content: '"\\E632"',
        },
    },
    anticonSearch: {
        ':before': {
            content: '"\\E670"',
        },
    },
    anticonHeart: {
        ':before': {
            content: '"\\E6A4"',
        },
    },
    anticonArrowDown: {
        ':before': {
            content: '"\\E61D"',
        },
    },
    anticonArrowUp: {
        ':before': {
            content: '"\\E61E"',
        },
    },
});
