var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { Link } from 'react-router-dom';
import { Button, ButtonType } from '../atoms';
export var MyLessonEmptyState = function (_a) {
    var classObject = _a.classObject, title = _a.title, description = _a.description, topImgSrc = _a.topImgSrc, bottomImgSrc = _a.bottomImgSrc, buttonText = _a.buttonText, linkUrl = _a.linkUrl;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        topImgSrc && React.createElement("img", { className: css(styles.topImg), src: topImgSrc }),
        React.createElement("div", { className: css(styles.title) }, title),
        React.createElement("div", { className: css(styles.description) }, description),
        bottomImgSrc && React.createElement("img", { className: css(styles.bottomImg), src: bottomImgSrc }),
        linkUrl && buttonText && (React.createElement(Link, { to: linkUrl },
            React.createElement(Button, { buttonType: ButtonType.primary, className: css(styles.button, bottomImgSrc && styles.bottomImgMargin), isRadius: true }, buttonText)))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: 60,
    },
    topImg: {
        marginTop: 40,
        marginBottom: 20,
    },
    bottomImg: (_a = {
            width: 206,
            height: 286,
            marginTop: 10
        },
        _a[MediaBreakPointUp.MD] = {
            marginTop: 20,
        },
        _a),
    title: {
        textAlign: 'center',
        minHeight: 26,
        marginBottom: 10,
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: '0.08em',
        color: Colors.GrayDarkest,
    },
    description: {
        textAlign: 'center',
        minHeight: 20,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: '0.04em',
        color: Colors.GrayDarkest,
    },
    button: {
        height: 35,
        padding: '7px 13px',
        marginTop: 20,
        fontSize: 14,
        fontWeight: 'bold',
    },
    bottomImgMargin: (_b = {
            marginTop: 10
        },
        _b[MediaBreakPointUp.MD] = {
            marginTop: 20,
        },
        _b),
});
