import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { SearchIcon } from '@lean-body/components/atoms/icons/search_icon';
var SEARCH_TIMER_DELAY = 200;
export var SearchInputNoButton = function (_a) {
    var classObject = _a.classObject, onChange = _a.onChange, inputValueMaster = _a.inputValueMaster, setInputValueMaster = _a.setInputValueMaster, clear = _a.clear;
    var _b = React.useState(inputValueMaster), inputValueSlave = _b[0], setInputValueSlave = _b[1];
    var _c = React.useState(null), onChangeTimer = _c[0], setOnChangeTimer = _c[1];
    var onChangeHandler = function (event) {
        var nextValue = event.target.value;
        setInputValueSlave(nextValue);
        onChangeTimerHandler(nextValue);
    };
    var onChangeTimerHandler = function (nextValue) {
        clearTimeout(onChangeTimer);
        if (nextValue === '')
            return; // バックスペースで最後の文字を消した時に最後の文字でonChangeが実行されないようにする
        var timer = window.setTimeout(function () { return onChange(nextValue); }, SEARCH_TIMER_DELAY);
        setOnChangeTimer(timer);
    };
    var clearHandler = function () {
        setInputValueSlave('');
        setInputValueMaster('');
        clear();
    };
    React.useEffect(function () { return setInputValueSlave(inputValueMaster); }, [inputValueMaster]);
    return (React.createElement("div", { className: css(styles.searchContainer, classObject) },
        React.createElement(SearchIcon, null),
        React.createElement("input", { className: css(styles.input), type: "text", value: inputValueSlave, onChange: onChangeHandler, placeholder: "\u30AD\u30FC\u30EF\u30FC\u30C9\u3067\u691C\u7D22" }),
        inputValueSlave && (React.createElement("img", { className: css(styles.clear), src: "/assets/images/icons/clear.svg", onClick: clearHandler }))));
};
var styles = StyleSheet.create({
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: Colors.BackgroundGrayC,
        height: 36,
        borderRadius: '100px',
        padding: '10px 24px',
    },
    input: {
        flex: 1,
        backgroundColor: Colors.BackgroundGrayC,
        margin: '0 4px',
        border: 'none',
        outline: 'unset',
        color: Colors.Black,
        fontSize: 16,
        width: 132,
        '::placeholder': {
            color: Colors.GrayDarkest,
            fontSize: 16,
        },
    },
    clear: {
        width: 16,
        height: 16,
    },
});
