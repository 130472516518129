import * as React from 'react';
import { Link } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite';
import { SectionHead } from '@lean-body/components/atoms/section_head';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { routes } from '@lean-body/src/route';
export var TrainerSection = function (props) {
    var styles = StyleSheet.create({
        container: {},
    });
    var title = props.title, trainers = props.trainers, className = props.className;
    var containerClass = [className || '', css(styles.container)].join(' ');
    return (React.createElement("div", { className: containerClass },
        title && React.createElement(SectionHead, null, title),
        trainers.map(function (t) {
            return (React.createElement(TrainerRow, { key: t.id, trainer: t, disableBorder: props.disableBorder, iconClassName: props.iconClassName }));
        })));
};
export var TrainerRow = function (props) {
    var _a;
    var styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            alignItems: 'center',
        },
        icon: {
            width: 100,
            height: 100,
            flexBasis: 100,
            borderRadius: '50%',
            overflow: 'hidden',
            border: props.disableBorder ? 0 : "1px solid ".concat(Colors.Gray),
        },
        title: {
            fontSize: 16,
            fontWeight: 'bold',
            marginBottom: 8,
            marginLeft: 0,
        },
        img: {
            maxWidth: '100%',
            height: 'auto',
        },
        textContainer: {},
        description: (_a = {
                color: Colors.Black,
                fontWeight: 300,
                fontSize: 13,
                lineHeight: '1.75'
            },
            _a[MediaBreakPointUp.LG] = {
                fontSize: 16,
            },
            _a),
    });
    var iconClass = [css(styles.icon), props.iconClassName || ''].join(' ');
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: iconClass, "data-test": "trainer" },
            React.createElement(Link, { to: routes.INSTRUCTOR.compile({ id: props.trainer.id.toString() }) },
                React.createElement("img", { className: css(styles.img), src: props.trainer.thumbnailURL }))),
        React.createElement("div", { className: css(styles.textContainer) },
            React.createElement("div", { className: css(styles.title) }, props.trainer.name),
            React.createElement("div", { className: css(styles.description) }, props.trainer.bio))));
};
