var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
export var ECONNABORTED_ERROR_CODE = 'ECONNABORTED';
var LBError = /** @class */ (function (_super) {
    __extends(LBError, _super);
    function LBError(message, displayMessage, status) {
        var _newTarget = this.constructor;
        var _this = this;
        var proto = _newTarget.prototype;
        _this = _super.call(this, message) || this;
        Object.defineProperty(_this, "displayMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // tslint:disable-next-line:variable-name
        Object.defineProperty(_this, "__proto__", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "isUnauthorizedError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "isForbiddenError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "isNotFoundError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "isBadRequestError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "isMaintenanceError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        _this.__proto__ = proto;
        _this.displayMessage = displayMessage;
        _this.status = status;
        return _this;
    }
    return LBError;
}(Error));
export { LBError };
var APIServerError = /** @class */ (function (_super) {
    __extends(APIServerError, _super);
    function APIServerError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return APIServerError;
}(LBError));
export { APIServerError };
var ASPServerError = /** @class */ (function (_super) {
    __extends(ASPServerError, _super);
    function ASPServerError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ASPServerError;
}(LBError));
export { ASPServerError };
var BigQueryError = /** @class */ (function (_super) {
    __extends(BigQueryError, _super);
    function BigQueryError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return BigQueryError;
}(LBError));
export { BigQueryError };
var FormError = /** @class */ (function (_super) {
    __extends(FormError, _super);
    function FormError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return FormError;
}(LBError));
export { FormError };
var NotFoundError = /** @class */ (function (_super) {
    __extends(NotFoundError, _super);
    function NotFoundError(message, displayMessage, status) {
        var _this = _super.call(this, message, displayMessage, status) || this;
        _this.isNotFoundError = true;
        return _this;
    }
    return NotFoundError;
}(APIServerError));
export { NotFoundError };
var BadRequestError = /** @class */ (function (_super) {
    __extends(BadRequestError, _super);
    function BadRequestError(message, displayMessage, status) {
        var _this = _super.call(this, message, displayMessage, status) || this;
        _this.isBadRequestError = true;
        return _this;
    }
    return BadRequestError;
}(APIServerError));
export { BadRequestError };
var UnauthorizedError = /** @class */ (function (_super) {
    __extends(UnauthorizedError, _super);
    function UnauthorizedError(message, displayMessage, status) {
        var _this = _super.call(this, message, displayMessage, status) || this;
        _this.isUnauthorizedError = true;
        return _this;
    }
    return UnauthorizedError;
}(APIServerError));
export { UnauthorizedError };
var ForbiddenError = /** @class */ (function (_super) {
    __extends(ForbiddenError, _super);
    function ForbiddenError(message, displayMessage, status, errorCode) {
        var _this = _super.call(this, message, displayMessage, status) || this;
        Object.defineProperty(_this, "isQuit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "isDormant", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "isFuture", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "isPaymentNotFound", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "isPaymentExpired", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        _this.isForbiddenError = true;
        _this.isQuit = errorCode === 'quit_user';
        _this.isDormant = errorCode === 'dormant_user';
        _this.isFuture = errorCode === 'future_user'; // megalosで有効期限が未来になるユーザーがいる
        _this.isPaymentNotFound = errorCode === 'payment_not_found';
        _this.isPaymentExpired = errorCode === 'payment_expired';
        return _this;
    }
    return ForbiddenError;
}(APIServerError));
export { ForbiddenError };
var UnderMaintenance = /** @class */ (function (_super) {
    __extends(UnderMaintenance, _super);
    function UnderMaintenance(message, status, maintenance) {
        var _this = _super.call(this, message, 'サーバメンテナンス中', status) || this;
        Object.defineProperty(_this, "maintenance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        _this.isMaintenanceError = true;
        _this.maintenance = maintenance;
        return _this;
    }
    return UnderMaintenance;
}(APIServerError));
export { UnderMaintenance };
