import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
import { ArrowDirection, ArrowIcon } from '../atoms';
import { MealScoreIcons } from './meal_score_icons';
export var MealScoreRow = function (_a) {
    var scoreDetail = _a.scoreDetail, onClickDetail = _a.onClickDetail;
    var onClick = function () {
        onClickDetail(scoreDetail.key);
    };
    return (React.createElement("div", { className: css(styles.container, scoreDetail.isStar && styles.isStarContainer), onClick: scoreDetail && onClick },
        React.createElement("div", { className: css(styles.scoreTitle) },
            scoreDetail.title,
            React.createElement(ArrowIcon, { direction: ArrowDirection.right, color: Colors.GrayDarkest, classObject: styles.arrowIcon })),
        React.createElement("div", { className: css(styles.score) },
            React.createElement(MealScoreIcons, { score: scoreDetail === null || scoreDetail === void 0 ? void 0 : scoreDetail.score, isStar: scoreDetail.isStar }))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: 41,
        padding: '10px 0',
        cursor: 'pointer',
    },
    isStarContainer: {
        height: 50,
    },
    scoreTitle: {
        fontSize: 13,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 0.52,
    },
    arrowIcon: {
        marginLeft: 4,
    },
    score: {
        display: 'flex',
    },
});
