var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { Fragment, createElement } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import * as route from '../route';
import { AlertContainer } from './organisms/alert_container';
/**
 * view/pages 以下の画面コンポーネントをルーティング
 */
export var Root = React.memo(function (_a) {
    var pageProvider = _a.pageProvider, routeController = _a.routeController, alertContainerVM = _a.alertContainerVM;
    var pp = pageProvider;
    var domainFactory = pageProvider.domainFactory;
    var accountService = pageProvider.domainFactory.AccountService();
    var location = useLocation();
    var _b = React.useState(true), loading = _b[0], setLoading = _b[1];
    var authorization = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (accountService.loggedIn) {
                return [2 /*return*/, routeController.authorizationWithForbiddenRedirect().then(function (v) {
                        return v.userId;
                    })];
            }
            return [2 /*return*/, null];
        });
    }); };
    React.useEffect(function () {
        var userID;
        authorization()
            .then(function (res) {
            userID = res;
        })
            .catch(function (e) {
            if (e.isForbiddenError) {
                // authorization()でforbiddenエラーの時は、userIDを受け取れないので、fetchMeで受け取ってから、実行
                return accountService.fetchMe().then(function (me) {
                    userID = me.id;
                });
            }
        })
            .finally(function () {
            domainFactory.buildWithUserID(userID);
            setLoading(false);
        });
    }, []);
    React.useEffect(function () {
        if (!loading) {
            routeController.onLocationChanged(location, domainFactory.Tracker());
        }
        // authorization()完了時にonLocationChanged()を実行したいので、loadingも検知対象に入れてる
    }, [location, loading]);
    if (loading)
        return null;
    return (React.createElement(Fragment, null,
        React.createElement(Routes, null,
            React.createElement(Route, __assign({}, route.MAINTENANCE, { element: createElement(pp.Maintenance) })),
            React.createElement(Route, __assign({}, route.CC_REGISTRATION, { element: createElement(pp.PaymentRegistratoin) })),
            React.createElement(Route, __assign({}, route.CALLBACK_LINE, { element: createElement(pp.LineCallback) })),
            React.createElement(Route, __assign({}, route.CALLBACK_APPLE, { element: createElement(pp.AppleCallback) })),
            React.createElement(Route, __assign({}, route.CALLBACK_AMAZON_PAY, { element: createElement(pp.AmazonPayCallback) })),
            React.createElement(Route, __assign({}, route.CALLBACK_AMAZON_PAY_FOR_SIGNUP, { element: createElement(pp.AmazonPayForSignupCallback) })),
            React.createElement(Route, __assign({}, route.SIGNUP, { element: createElement(pp.SignUp) })),
            React.createElement(Route, __assign({}, route.SIGNIN, { element: createElement(pp.SignIn) })),
            React.createElement(Route, __assign({}, route.EMAIL_VERIFICATION, { element: createElement(pp.EmailVerification) })),
            React.createElement(Route, __assign({}, route.PASSWORD_RESET_REQUEST, { element: createElement(pp.PasswordResetRequestPage) })),
            React.createElement(Route, __assign({}, route.PASSWORD_RESET, { element: createElement(pp.PasswordResetPage) })),
            React.createElement(Route, __assign({}, route.MAGAZINE_UNSUBSCRIBE, { element: createElement(pp.MagazineUnsubscribePage) })),
            React.createElement(Route, __assign({}, route.LP_IFRAME, { element: createElement(pp.LPIframePage) })),
            React.createElement(Route, __assign({}, route.CC_REGISTRATION, { element: createElement(pp.PaymentRegistratoin) })),
            React.createElement(Route, __assign({}, route.CALLBACK_APPLE, { element: createElement(pp.AppleCallback) })),
            React.createElement(Route, { path: "".concat(route.TOP.path, "*"), element: createElement(pp.Main) })),
        React.createElement(AlertContainer, { vm: alertContainerVM })));
});
