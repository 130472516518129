var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { motion } from 'framer-motion';
var Tag = function (_a) {
    var tagName = _a.tagName;
    return React.createElement("span", { className: css(styles.tag) }, tagName);
};
export var SuggestLessons = function (_a) {
    var suggestLessons = _a.suggestLessons, setCurrentSlide = _a.setCurrentSlide, onClickPlayButton = _a.onClickPlayButton;
    var handleOnClick = function (currentSlide) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setCurrentSlide(currentSlide);
            onClickPlayButton();
            return [2 /*return*/];
        });
    }); };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.inner) }, suggestLessons.map(function (suggestLesson, index) { return (React.createElement(motion.div, { key: index, animate: { scale: [0.5, 1], display: ['none', 'block'] }, whileHover: { scale: 1.1 } },
            React.createElement("img", { className: css(styles.image), src: suggestLesson.lesson.thumbnailURL, onClick: function () { return handleOnClick(index); } }),
            React.createElement("div", { className: css(styles.tagList) }, suggestLesson.lesson.tagNameList.map(function (tagName, index) { return (React.createElement(Tag, { key: index, tagName: tagName })); })),
            React.createElement("div", { className: css(styles.lessonTitle) }, suggestLesson.lesson.name))); }))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        paddingTop: 32,
    },
    inner: {
        display: 'grid',
        gridGap: '20px 0',
        gridTemplateColumns: 'repeat(auto-fit, 31%)',
        justifyContent: 'space-between',
        maxWidth: 840,
        width: '98%',
    },
    image: {
        width: '100%',
    },
    tagList: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        fontSize: 12,
        margin: '12px 0 0',
        lineHeight: '1.75',
    },
    lessonTitle: {
        fontSize: 20,
        margin: 0,
        lineHeight: '1.75',
    },
    tag: {
        marginRight: 8,
    },
});
