var _a;
import { css, StyleSheet } from 'aphrodite/no-important';
import * as React from 'react';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles/index';
export var FormBox = function (props) {
    var containerClass = [props.className || '', css(styles.container)].join(' ');
    return React.createElement("div", { className: containerClass }, props.children);
};
var styles = StyleSheet.create({
    container: (_a = {
            background: Colors.White,
            borderRadius: 4,
            position: 'relative',
            zIndex: 1,
            width: '92.5%',
            padding: '27px 17px',
            margin: '0 auto',
            maxWidth: 365
        },
        _a[MediaBreakPointUp.LG] = {
            maxWidth: 400,
            padding: '48px 32px',
        },
        _a),
});
