import * as React from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import { useSmMediaQuery } from '@lean-body/src/util/media_query_hooks';
export var MegalosLiveCalendarHeaderImg = function (props) {
    var isSM = useSmMediaQuery();
    var styles = StyleSheet.create({
        img: {
            width: '100%',
        },
    });
    var headerImgUrl = function () {
        if (isSM) {
            return "/assets/images/banner/megalos_live_calendar_sp.png";
        }
        return "/assets/images/banner/megalos_live_calendar_pc.png";
    };
    return React.createElement("img", { src: headerImgUrl(), className: css(styles.img) });
};
