import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var MealMenuSearchEmptyStateIcon = function (_a) {
    var classObject = _a.classObject;
    var styles = StyleSheet.create({
        container: {
            width: 83,
            height: 82,
        },
    });
    return (React.createElement("svg", { className: css(styles.container, classObject), viewBox: "0 0 83 82", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { id: "Union", fillRule: "evenodd", clipRule: "evenodd", d: "M9.39209 34.5C9.39209 20.3463 20.8659 8.87256 35.0195 8.87256C49.1732 8.87256 60.647 20.3463 60.647 34.5C60.647 40.976 58.2449 46.8909 54.2831 51.4025C53.776 51.6284 53.3009 51.9494 52.8849 52.3654C52.4689 52.7814 52.1479 53.2565 51.9221 53.7636C47.4105 57.7254 41.4955 60.1274 35.0195 60.1274C20.8659 60.1274 9.39209 48.6536 9.39209 34.5ZM55.896 61.9691C50.1 66.3808 42.8657 69 35.0195 69C15.9657 69 0.519531 53.5538 0.519531 34.5C0.519531 15.4462 15.9657 0 35.0195 0C54.0734 0 69.5195 15.4462 69.5195 34.5C69.5195 42.3461 66.9003 49.5805 62.4886 55.3765L81.1542 74.042C82.9746 75.8625 82.9747 78.8141 81.1542 80.6346C79.3337 82.4551 76.3821 82.4551 74.5616 80.6346L55.896 61.9691Z", fill: "#C5CBD1" })));
};
