import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var SearchBackButton = function (props) {
    var styles = StyleSheet.create({
        backButton: {
            color: '#fff',
            cursor: 'pointer',
            borderRadius: 3,
            fontSize: '1em',
            borderStyle: 'none',
            backgroundColor: '#C5CBD1',
            marginTop: 10,
            marginBottom: 10,
            letterSpacing: 1,
            padding: '15px 35px',
        },
    });
    return (React.createElement("button", { className: css(styles.backButton), onClick: props.onClick }, "\u623B\u308B"));
};
