import * as React from 'react';
import { isiOSApp } from '@lean-body/src/util';
import { useNavigate } from 'react-router-dom';
import { routes } from '@lean-body/src/route';
import { LiveVideo } from '../organisms/live_video';
export var LiveLessonVimeo = function (_a) {
    var vm = _a.vm, signedCookieService = _a.signedCookieService;
    var tracker = vm.tracker, accountService = vm.accountService, id = vm.id, apiClient = vm.apiClient;
    var navigate = useNavigate();
    var _b = React.useState(null), me = _b[0], setMe = _b[1];
    var _c = React.useState(), liveLesson = _c[0], setLiveLesson = _c[1];
    React.useEffect(function () {
        var isMounted = true;
        // ios専用ページなので、iosじゃない場合リダイレクト
        if (!isiOSApp()) {
            navigate(routes.LIVE_LESSON.compile({ id: id.toString() }));
        }
        else {
            Promise.all([apiClient.fetchLiveLesson(id), accountService.fetchMe()]).then(function (_a) {
                var resLiveLesson = _a[0], resMe = _a[1];
                if (isMounted) {
                    setMe(resMe);
                    setLiveLesson(resLiveLesson);
                }
            });
        }
        return function () {
            isMounted = false;
        };
    }, []);
    if (!liveLesson)
        return null;
    return (React.createElement(LiveVideo, { liveLesson: liveLesson, apiClient: apiClient, signedCookieService: signedCookieService, tracker: tracker, me: me }));
};
var LiveLessonVimeoVM = /** @class */ (function () {
    function LiveLessonVimeoVM(id, accountService, apiClient, tracker) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = id;
        this.accountService = accountService;
        this.apiClient = apiClient;
        this.tracker = tracker;
    }
    return LiveLessonVimeoVM;
}());
export { LiveLessonVimeoVM };
