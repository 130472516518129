import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
export var LifetimeActivityRankRate = function (props) {
    var onAnimationEnd = props.onAnimationEnd, numerator = props.numerator, numeratorMax = props.numeratorMax, denominator = props.denominator;
    var playDurationRate = denominator ? (numeratorMax / denominator) * 100 : 100;
    var animation = {
        '0%': {
            width: '0%',
        },
        '100%': {
            width: "".concat(playDurationRate, "%"),
        },
    };
    var animationStyles = StyleSheet.create({
        animation: {
            animationName: [animation],
            animationDuration: '1s',
            'animation-timing-function': 'ease-in',
            'animation-fill-mode': 'forwards',
        },
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.playDuration) },
            React.createElement("span", { className: css(styles.playDurationBold) },
                numerator,
                !denominator && 'min'),
            denominator && React.createElement("span", null,
                " / ",
                denominator,
                "\u5206")),
        React.createElement("div", { className: css(styles.playDurationRateCotainer) },
            React.createElement("div", { className: css(styles.playDurationRate, animationStyles.animation), onAnimationEnd: onAnimationEnd }))));
};
var styles = StyleSheet.create({
    playDuration: {
        fontSize: 12,
        fontWeight: 'bold',
        color: Colors.GrayDarkest,
        marginBottom: 6,
    },
    playDurationBold: {
        fontSize: 16,
        color: Colors.Primary,
        letterSpacing: '0.04em',
    },
    playDurationRateCotainer: {
        height: 6,
        borderRadius: 100,
        backgroundColor: Colors.BackgroundLightGray,
        position: 'relative',
    },
    playDurationRate: {
        borderRadius: 100,
        backgroundColor: Colors.Primary,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
    },
});
