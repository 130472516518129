import * as React from 'react';
import { StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { Tabs } from '@lean-body/components/molecules/tabs';
import { MealNutrientTabs } from '../pages/nutrient_page';
import { ActivityTabs, LifetimeActivityTabs, } from '../organisms/lifetime_activity_chart';
export var TabTypes = {
    activity: 'activity',
    lifetimeActivity: 'lifetime_activity',
    mealNutrient: 'mealNutrient',
};
export var GrayBoxTabs = function (_a) {
    var tab = _a.tab, setTab = _a.setTab, tabType = _a.tabType, classObject = _a.classObject;
    return (React.createElement(React.Fragment, null, tabType === TabTypes.activity ? (React.createElement(Tabs, { setTab: setTab, currentTab: tab, tabs: ActivityTabs, classObject: classObject, tabWrapperClassObject: styles.tabWrapper, tabIndicatorClassObject: [styles.tabIndicator, styles.activityTabIndicator], tabLabelClassObject: styles.tabLabel, selectedTabClassObject: styles.selectedTab, variant: "fullWidth" })) : tabType === TabTypes.lifetimeActivity ? (React.createElement(Tabs, { setTab: setTab, currentTab: tab, tabs: LifetimeActivityTabs, classObject: classObject, tabWrapperClassObject: styles.tabWrapper, tabIndicatorClassObject: [styles.tabIndicator, styles.lifetimeActivityTabIndicator], tabLabelClassObject: styles.tabLabel, selectedTabClassObject: styles.selectedTab, variant: "fullWidth" })) : (tabType === TabTypes.mealNutrient && (React.createElement(Tabs, { setTab: setTab, currentTab: tab, tabs: MealNutrientTabs, classObject: classObject, tabWrapperClassObject: styles.tabWrapper, tabIndicatorClassObject: [styles.tabIndicator, styles.mealNutrientTabIndicator], tabLabelClassObject: styles.tabLabel, selectedTabClassObject: styles.selectedTab, variant: "fullWidth" })))));
};
var styles = StyleSheet.create({
    tabWrapper: {
        width: '100%',
        borderRadius: 9,
    },
    tabIndicator: {
        fontWeight: 'normal',
        color: Colors.StrictBlack,
        backgroundColor: Colors.White,
        height: 'calc(100% - 4px)',
        margin: 2,
        borderRadius: 7,
        border: '0.5px solid rgba(0, 0, 0, 0.04)',
        boxShadow: '0 3px 1px 0 rgba(0, 0, 0, 0.04), 0 3px 8px 0 rgba(0, 0, 0, 0.12)',
    },
    mealNutrientTabIndicator: {
        width: 'calc(100% / 5 - 4px)',
    },
    activityTabIndicator: {
        width: 'calc(100% / 4 - 4px)',
    },
    lifetimeActivityTabIndicator: {
        fontWeight: 'normal',
        width: 'calc(100% / 3 - 4px)',
    },
    tabLabel: {
        color: Colors.StrictBlack,
        borderRadius: 7,
    },
    selectedTab: {
        color: Colors.StrictBlack,
    },
});
