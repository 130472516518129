import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { ImageBlurCircle } from '@lean-body/components/atoms/image_blur_circle';
export var PlaylistHeader = function (_a) {
    var thumbnailURL = _a.thumbnailURL;
    var styles = StyleSheet.create({
        wrapper: {
            position: 'relative',
            overflowX: 'hidden',
            height: 280,
        },
        image: {
            width: 220,
            position: 'absolute',
            top: 32,
            left: 'calc(50% - 110px)',
            borderRadius: 16,
        },
    });
    return (React.createElement("div", { className: css(styles.wrapper) },
        React.createElement(ImageBlurCircle, { src: thumbnailURL }),
        React.createElement("img", { className: css(styles.image), src: thumbnailURL, alt: "" })));
};
