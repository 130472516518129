import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
export var CollectionRankImage = function (props) {
    var userSeasonAggregate = props.userSeasonAggregate;
    var acquiredDate = userSeasonAggregate.seasonStartAtDayjs.format('YYYY.MM');
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.rankImgWrapper) },
            React.createElement("img", { src: userSeasonAggregate.userSeasonAggregateRank.thumbnailSrc, className: css(styles.rankImg) })),
        React.createElement("span", { className: css(styles.acquiredDate) }, acquiredDate)));
};
var styles = StyleSheet.create({
    rankImgWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 85,
        height: 95,
        borderRadius: 10,
        backgroundColor: Colors.White,
        marginBottom: 10,
        cursor: 'pointer',
        border: "1px solid ".concat(Colors.BorderLightGray),
    },
    rankImg: {
        height: '68%',
    },
    acquiredDate: {
        color: Colors.GrayLightText,
        fontSize: 10,
        fontWeight: 700,
        letterSpacing: 0.4,
    },
});
