import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CourseDayItem, CourseRewardVolume } from '@lean-body/components/organisms';
export var CourseReward = function (_a) {
    var courseReward = _a.courseReward, apiClient = _a.apiClient, classObject = _a.classObject;
    if (courseReward === null || courseReward.volume === null || courseReward.day === null) {
        return;
    }
    var volume = courseReward.volume;
    var day = courseReward.day;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(CourseRewardVolume, { volume: volume, courseName: courseReward.courseName, classObject: styles.volume }),
        React.createElement(CourseDayItem, { isReward: true, day: day, apiClient: apiClient, classObject: styles.day })));
};
var styles = StyleSheet.create({
    container: {},
    volume: {
        boxShadow: '0px 15px 30px rgba(0, 180, 199, 0.08)',
    },
    day: {
        marginTop: 8,
        boxShadow: '0px 15px 30px rgba(0, 180, 199, 0.08)',
    },
});
