var _a, _b;
import { css, StyleSheet } from 'aphrodite';
import { Button, ButtonType } from '@lean-body/components/atoms/button';
import * as React from 'react';
import { routes } from '@lean-body/src/route';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Logo, CommentIcon } from '@lean-body/components/atoms';
import { useNavigate } from 'react-router-dom';
import { useSmMediaQuery } from '@lean-body/src/util/media_query_hooks';
import { HIDE_HEADER_QUERY_PARAMS } from './main';
/**
 * マッチングテストのイントロダクションページ
 */
export var MatchingAssessmentIntroPage = function (_a) {
    var tracker = _a.tracker;
    var navigate = useNavigate();
    var isSM = useSmMediaQuery();
    var onClickNext = function () {
        tracker.trackClickMatchingAssessmentStart();
        navigate("".concat(routes.MATCHING_ASSESSMENT.compile(), "?").concat(HIDE_HEADER_QUERY_PARAMS, "=true"));
    };
    var onClickLater = function () {
        navigate(routes.TOP.compile());
    };
    return (React.createElement("div", { className: css(styles.container) },
        isSM && React.createElement(Logo, { forHeader: isSM }),
        React.createElement("div", { className: css(styles.content) },
            React.createElement("div", { className: css(styles.mainBox) },
                React.createElement("div", null,
                    React.createElement(CommentIcon, null)),
                React.createElement("div", { className: css(styles.title) }, "\u3042\u306A\u305F\u306E\u305F\u3081\u306E\u30B3\u30FC\u30B9\u3092\u4F5C\u6210\u3057\u307E\u3059\u306E\u3067\u3001\u3042\u306A\u305F\u306B\u3064\u3044\u3066\u6559\u3048\u3066\u304F\u3060\u3055\u3044\u3002"),
                React.createElement("div", { className: css(styles.description) }, "\u30B3\u30FC\u30B9\u3084\u30B9\u30B1\u30B8\u30E5\u30FC\u30EB\u306F\u5909\u66F4\u53EF\u80FD\u3067\u3059\u3002"),
                React.createElement("div", { className: css(styles.buttonBox) },
                    React.createElement(Button, { className: css(styles.button), buttonType: ButtonType.primary, onClick: onClickNext }, "\u6B21\u3078"),
                    React.createElement(Button, { className: css(styles.button, styles.buttonLater), buttonType: ButtonType.borderGray, onClick: onClickLater }, "\u3042\u3068\u3067"))))));
};
var styles = StyleSheet.create({
    container: {
        height: '100vh',
    },
    content: (_a = {
            height: '100%',
            backgroundColor: Colors.White,
            textAlign: 'center',
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 105
        },
        _a[MediaBreakPointUp.MD] = {
            paddingTop: 340,
        },
        _a),
    mainBox: {
        position: 'relative',
        maxWidth: 265,
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        textAlign: 'center',
        letterSpacing: '0.08em',
        color: Colors.Black,
        marginTop: 10,
    },
    description: {
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'center',
        letterSpacing: '0.04em',
        color: Colors.GrayDarkest,
        marginTop: 35,
    },
    buttonBox: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        padding: '30px 0',
        backgroundColor: Colors.White,
    },
    button: (_b = {
            display: 'block',
            width: 300,
            borderRadius: 24,
            margin: '0 auto'
        },
        _b[MediaBreakPointUp.MD] = {
            width: 500,
        },
        _b),
    buttonLater: {
        marginTop: 12,
        color: Colors.Black,
    },
});
