var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Dialog } from '@lean-body/components/molecules';
import { Button, ButtonType, SelectMark } from '@lean-body/components/atoms';
import { CanvasConbineText } from '@lean-body/components/atoms/canvas_conbine_text';
import { CloseButtonLabel } from '@lean-body/components/atoms/dialog_close_button';
export var DEFAULT_THUMBNAIL_KEYS;
(function (DEFAULT_THUMBNAIL_KEYS) {
    DEFAULT_THUMBNAIL_KEYS["blue"] = "blue";
    DEFAULT_THUMBNAIL_KEYS["pink"] = "pink";
    DEFAULT_THUMBNAIL_KEYS["yellow"] = "yellow";
})(DEFAULT_THUMBNAIL_KEYS || (DEFAULT_THUMBNAIL_KEYS = {}));
var CANVAS_WIDTH = 140;
var CANVAS_HEIGHT = CANVAS_WIDTH;
export var SelectThumbnailDialog = function (_a) {
    var close = _a.close, submit = _a.submit, canvasText = _a.canvasText, _b = _a.initKey, initKey = _b === void 0 ? DEFAULT_THUMBNAIL_KEYS.blue : _b;
    var _c = React.useState(null), activeImgKey = _c[0], setActiveImgKey = _c[1];
    var _d = React.useState(null), file = _d[0], setFile = _d[1];
    var defaultThumbnails = [
        {
            key: DEFAULT_THUMBNAIL_KEYS.blue,
            ref: React.useRef(),
        },
        {
            key: DEFAULT_THUMBNAIL_KEYS.pink,
            ref: React.useRef(),
        },
        {
            key: DEFAULT_THUMBNAIL_KEYS.yellow,
            ref: React.useRef(),
        },
    ];
    var onClickSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!file)
                return [2 /*return*/];
            submit(file, activeImgKey);
            close();
            return [2 /*return*/];
        });
    }); };
    var onClickCanvas = function (key, ref) {
        setActiveImgKey(key);
        ref.current.toBlob(function (blob) {
            setFile(new File([blob], "".concat(activeImgKey, ".jpg"), { type: 'image/jpg' }));
        });
    };
    var onLoadDefaultCanvast = function () {
        setActiveImgKey(initKey);
        var canvas = defaultThumbnails.find(function (v) { return v.key === initKey; }).ref.current;
        canvas.toBlob(function (blob) {
            setFile(new File([blob], "".concat(initKey, ".jpg"), { type: 'image/jpg' }));
        });
    };
    return (React.createElement(Dialog, { closeButtonLabel: CloseButtonLabel.Cancel, onClickCancel: close, content: React.createElement("div", { className: css(styles.container) },
            React.createElement("div", { className: css(styles.title) }, "\u30B5\u30E0\u30CD\u30A4\u30EB\u753B\u50CF\u3092\u9078\u629E"),
            React.createElement("ul", { className: css(styles.images) }, defaultThumbnails.map(function (v, i) { return (React.createElement("li", { className: css(styles.img, activeImgKey === v.key && styles.imgActive), key: i, onClick: function () { return onClickCanvas(v.key, v.ref); } },
                React.createElement(CanvasConbineText, { ref: v.ref, text: canvasText, width: CANVAS_WIDTH, height: CANVAS_HEIGHT, imgSrc: "/assets/images/original_playlist/".concat(v.key, ".jpg"), onLoad: v.key === initKey && onLoadDefaultCanvast }),
                activeImgKey === v.key && React.createElement(SelectMark, { markSize: 14, isChecked: true, className: css(styles.selectMark) }))); })),
            React.createElement(Button, { buttonType: ButtonType.primary, isRadius: true, className: css(styles.button), onClick: onClickSubmit }, "\u9078\u629E\u3059\u308B")) }));
};
var styles = StyleSheet.create({
    container: (_a = {
            padding: 24,
            maxWidth: 420,
            margin: '0 auto'
        },
        _a[MediaBreakPointUp.XL] = {
            padding: 42,
        },
        _a),
    title: {
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 40,
    },
    images: {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: 290,
        margin: '0 auto',
    },
    img: {
        position: 'relative',
        width: CANVAS_WIDTH,
        height: CANVAS_WIDTH,
        borderRadius: 4,
        overflow: 'hidden',
        marginBottom: 10,
        backgroundColor: Colors.BackgroundGrayC,
        ':nth-child(odd)': {
            marginRight: 9,
        },
    },
    imgActive: {
        border: "3px solid ".concat(Colors.Primary),
    },
    selectMark: {
        position: 'absolute',
        right: 7,
        top: 7,
    },
    button: {
        fontSize: 16,
        width: '100%',
        margin: '30px 0 16px',
    },
});
