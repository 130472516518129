import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var ThumbsUpIcon = function (_a) {
    var classObject = _a.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 24,
            height: 24,
        },
    });
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M5.56434 11.2383H2.03631C1.38091 11.2383 0.849609 11.7696 0.849609 12.425V22.1966C0.849609 22.852 1.38091 23.3833 2.03631 23.3833H5.56434C6.21974 23.3833 6.75104 22.852 6.75104 22.1966V12.425C6.75104 11.7696 6.21974 11.2383 5.56434 11.2383Z", fill: "#FEFEFE" }),
        React.createElement("path", { d: "M21.484 12.3885H21.2755C22.3072 12.3885 23.1518 11.5439 23.1518 10.5123C23.1518 9.48058 22.3072 8.63599 21.2755 8.63599H15.2511C15.9941 7.46533 16.9991 5.59975 16.9991 4.07628C16.9991 1.66011 15.6787 0.323735 14.8128 0.168715C13.9522 0.0136958 13.316 0.473409 13.0701 1.20574C12.8189 1.93273 13.2626 3.31187 13.0915 3.80366C12.8296 4.56272 11.4986 5.57836 9.69713 7.88762C8.08814 9.95099 8.31799 12.3885 8.31799 12.3885V20.6046C8.31799 21.246 8.84185 21.7752 9.48866 21.7752H20.6768C21.4252 21.7752 22.0345 21.1658 22.0345 20.4175C22.0345 19.6691 21.4198 19.0597 20.6768 19.0597H20.8692C21.7833 19.0597 22.537 18.3113 22.537 17.3919C22.537 16.4725 21.7887 15.7241 20.8692 15.7241H21.484C22.398 15.7241 23.1518 14.9758 23.1518 14.0563C23.1518 13.1369 22.4034 12.3885 21.484 12.3885Z", fill: "#FEFEFE" })));
};
