var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var FBClient = /** @class */ (function () {
    function FBClient(appId) {
        Object.defineProperty(this, "ready", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "auth", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "appId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.appId = appId;
        this.ready = this.init();
    }
    Object.defineProperty(FBClient.prototype, "init", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, this.loadScript().then(function () {
                                FB.init({
                                    appId: _this.appId,
                                    version: 'v2.12',
                                });
                            })];
                        case 1:
                            _b.sent();
                            _a = this;
                            return [4 /*yield*/, this.getAuth()];
                        case 2:
                            _a.auth = _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(FBClient.prototype, "loadScript", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var loadResolved, loadRejected, loaded, id, head, scriptEl;
                return __generator(this, function (_a) {
                    loaded = new Promise(function (res, rej) {
                        loadResolved = res;
                        loadRejected = rej;
                    });
                    id = 'fb-js-sdk';
                    if (document.getElementById(id)) {
                        return [2 /*return*/];
                    }
                    head = document.getElementsByTagName('head')[0];
                    scriptEl = document.createElement('script');
                    // tslint:disable-next-line:max-line-length
                    scriptEl.src = "https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v2.12&appId=".concat(this.appId, "&autoLogAppEvents=1");
                    scriptEl.id = id;
                    scriptEl.onload = function () {
                        loadResolved();
                    };
                    scriptEl.onerror = function () {
                        loadRejected();
                    };
                    head.insertBefore(scriptEl, null);
                    return [2 /*return*/, loaded];
                });
            });
        }
    });
    Object.defineProperty(FBClient.prototype, "login", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.init()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/, new Promise(function (resolve, reject) {
                                    FB.login(function (res) {
                                        switch (res.status) {
                                            case 'connected':
                                                if (res.authResponse) {
                                                    resolve(res.authResponse);
                                                }
                                                else {
                                                    reject('couldnt get auth reponse');
                                                }
                                                break;
                                            case 'not_authorized':
                                                reject('not_authorized');
                                                break;
                                            case 'unknown':
                                                reject('unknown');
                                                break;
                                        }
                                    }, { scope: 'email' });
                                })];
                    }
                });
            });
        }
    });
    Object.defineProperty(FBClient.prototype, "getAuth", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, new Promise(function (resolve) {
                            FB.getLoginStatus(function (res) {
                                switch (res.status) {
                                    case 'connected':
                                        if (res.authResponse) {
                                            resolve(res.authResponse);
                                        }
                                        else {
                                            resolve(null);
                                        }
                                        break;
                                    case 'not_authorized':
                                        resolve(null);
                                        break;
                                    case 'unknown':
                                        resolve(null);
                                }
                            });
                        })];
                });
            });
        }
    });
    return FBClient;
}());
export { FBClient };
