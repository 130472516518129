import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles/variables';
var Duration = function (_a) {
    var duration = _a.duration, className = _a.className, radius = _a.radius;
    var durationClass = [css(styles.wrapper, radius && styles.radius), className].join(' ');
    return React.createElement("span", { className: durationClass }, "".concat(duration, ":00"));
};
var styles = StyleSheet.create({
    wrapper: {
        height: 16,
        minWidth: 34,
        lineHeight: '16px',
        padding: '0 4px',
        color: Colors.White,
        fontSize: 10,
        fontWeight: 'bold',
        background: Colors.GrayDarkest,
        borderRadius: '4px 0px 0px 0px',
        textAlign: 'center',
    },
    radius: {
        borderRadius: 4,
    },
});
export default Duration;
