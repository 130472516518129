import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors, drawerWidth, MediaBreakPointUp } from '@lean-body/components/styles';
import { LessonPlaylistBox, SHADOW_WIDTH } from './lesson_playlist_box';
import { LessonMetaUserAction } from './lesson_meta_user_action';
import { LessonMetaTitleWithInfo } from '../atoms/lesson_meta_title_with_info';
import { useRefHooks, useXlMediaQuery } from '@lean-body/src/util';
import { useNavigate } from 'react-router';
import { routes } from '@lean-body/src/route';
import { PLAYLIST_ORDER_QUERY } from '../pages/main_playlist_detail';
import { LessonComponent } from './lesson_component';
import { Button, ButtonType } from '../atoms';
import { PlayButtonIcon } from '../atoms/icons/play_button_icon';
import { PlaylistMetaChallengeBox } from './playlist_meta_challenge_box';
export var PlaylistMeta = function (_a) {
    var _b, _c;
    var currentLessonIndex = _a.currentLessonIndex, playlistCurrentOrderIndex = _a.playlistCurrentOrderIndex, playlist = _a.playlist, programLessons = _a.programLessons, nextLesson = _a.nextLesson, onClickFav = _a.onClickFav, onClickTwitterShareIfAuthed = _a.onClickTwitterShareIfAuthed, addFavoriteLesson = _a.addFavoriteLesson, unFavoriteLesson = _a.unFavoriteLesson, twitterService = _a.twitterService, displayLessonPlaylistBox = _a.displayLessonPlaylistBox, hasOrderIndex = _a.hasOrderIndex, onClickLesson = _a.onClickLesson, isFavorited = _a.isFavorited, isChallenge = _a.isChallenge, isChallenging = _a.isChallenging, totalFavoriteCount = _a.totalFavoriteCount, isOpenStopChallengeDialog = _a.isOpenStopChallengeDialog, onClickChallenge = _a.onClickChallenge, playCurrentVideo = _a.playCurrentVideo, onClickStopChallenge = _a.onClickStopChallenge, onClickCancelStopChallenge = _a.onClickCancelStopChallenge, aboveLessonName = _a.aboveLessonName;
    var navigate = useNavigate();
    var isXL = useXlMediaQuery();
    var _d = React.useState(false), isShowOtherLessons = _d[0], setIsShowOtherLessons = _d[1];
    var _e = useRefHooks(), ref = _e.ref, element = _e.element;
    var onClickShowOtherLessonsIcon = function () {
        element.scrollTo(0, 0); // container がスクロールされた状態で LessonPlaylistBox を開くと位置がバグるので、上に移動
        setIsShowOtherLessons(!isShowOtherLessons);
    };
    var onClickLessonPlaylistBoxButton = function () {
        navigate("".concat(routes.PLAYLIST.compile({ id: playlist.id.toString() }), "?").concat(PLAYLIST_ORDER_QUERY, "=0"));
    };
    var styles = StyleSheet.create({
        container: (_b = {
                position: 'relative',
                background: Colors.White,
                padding: isChallenge ? '14px 16px 250px' : '14px 16px 160px',
                flex: 1
            },
            _b[MediaBreakPointUp.MD] = {
                padding: isChallenge ? "14px ".concat(SHADOW_WIDTH, "px 160px") : "14px ".concat(SHADOW_WIDTH, "px 100px"),
            },
            _b[MediaBreakPointUp.XL] = {
                padding: '14px 0 0',
            },
            _b),
        info: {
            marginBottom: 14,
        },
        userActionRow: {
            marginTop: 16,
        },
        playlistPlayButton: {
            width: '100%',
            height: 48,
            marginTop: 4,
            marginBottom: 24,
            paddingTop: 11,
            paddingBottom: 11,
            borderRadius: 24,
        },
        playButton: {
            marginRight: 10,
        },
        otherInfo: {
            paddingTop: 10,
            paddingBottom: 10,
            borderTop: "1px solid ".concat(Colors.BorderLightGray),
        },
        description: {
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '18px',
            display: 'flex',
            alignItems: 'flex-start',
        },
        aboveLessonName: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 2,
        },
        section: {
            borderTop: "1px solid ".concat(Colors.BorderLightGray),
        },
        lessonSection: {
            paddingTop: 10,
        },
        lessonSectionTitle: {
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: '160%',
            letterSpacing: '0.04em',
            marginBottom: 10,
        },
        lesson: {
            paddingTop: 6,
            paddingBottom: 6,
        },
        challengeInfoBox: {
            marginBottom: 14,
        },
        fixedChallengeInfoBox: (_c = {
                position: 'fixed',
                height: 140,
                width: '100%',
                left: 0,
                bottom: 59,
                padding: 16,
                backgroundColor: 'rgb(255, 255, 255)',
                borderRadius: '14px 14px 0px 0px',
                boxShadow: "0 0 ".concat(SHADOW_WIDTH, "px rgba(0, 0, 0, 0.16)")
            },
            _c[MediaBreakPointUp.MD] = {
                maxWidth: 600,
                left: drawerWidth.md,
                right: 0,
                bottom: 0,
                margin: 'auto',
            },
            _c),
    });
    return (React.createElement("div", { className: css(styles.container), ref: ref },
        React.createElement("div", { className: css(styles.info) },
            React.createElement(LessonMetaTitleWithInfo, { title: playlist.name, favoriteCount: totalFavoriteCount, difficulty: playlist.difficulty, tags: playlist.tags }),
            React.createElement(LessonMetaUserAction, { classObject: styles.userActionRow, playlist: playlist, isFavorite: isFavorited, onClickFav: onClickFav, twitterService: twitterService, onClickTwitterShareIfAuthed: onClickTwitterShareIfAuthed })),
        !displayLessonPlaylistBox && !hasOrderIndex && (React.createElement(React.Fragment, null, isChallenge ? (React.createElement("div", { className: css(styles.challengeInfoBox) },
            React.createElement(PlaylistMetaChallengeBox, { isChallenging: isChallenging, currentOrderIndex: playlistCurrentOrderIndex, totalChallengedCount: playlist.playlistAggregate.totalChallengedCount, lessonLength: programLessons.length, isOpenStopChallengeDialog: isOpenStopChallengeDialog, onClickChallenge: onClickChallenge, onClickPlayCurrentVideo: playCurrentVideo, onClickStopChallenge: onClickStopChallenge, onClickCancelStopChallenge: onClickCancelStopChallenge }))) : (React.createElement(Button, { className: css(styles.playlistPlayButton), buttonType: ButtonType.primary, onClick: onClickLessonPlaylistBoxButton },
            React.createElement(PlayButtonIcon, { classObject: styles.playButton }),
            "\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u518D\u751F")))),
        React.createElement("div", { className: css(styles.otherInfo) },
            React.createElement("div", { className: css(styles.description) }, playlist.description)),
        displayLessonPlaylistBox && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.section, styles.lessonSection) },
                React.createElement("div", { className: css(styles.lessonSectionTitle) },
                    "\u30EC\u30C3\u30B9\u30F3 (",
                    programLessons.length,
                    ")"),
                programLessons.map(function (v, i) {
                    return (React.createElement("div", { className: css(styles.lesson), key: i, "data-test": "lessonItem" },
                        React.createElement(LessonComponent, { lesson: v, addFavoriteLesson: addFavoriteLesson, unFavoriteLesson: unFavoriteLesson, isVertical: true, isMin: true, onClick: onClickLesson, disableHistoryPush: true, aboveName: aboveLessonName && aboveLessonName(i) })));
                })),
            isChallenge ? (React.createElement("div", { className: css(styles.fixedChallengeInfoBox) },
                React.createElement(PlaylistMetaChallengeBox, { isChallenging: isChallenging, currentOrderIndex: playlistCurrentOrderIndex, totalChallengedCount: playlist.playlistAggregate.totalChallengedCount, lessonLength: programLessons.length, isOpenStopChallengeDialog: isOpenStopChallengeDialog, onClickChallenge: onClickChallenge, onClickPlayCurrentVideo: playCurrentVideo, onClickStopChallenge: onClickStopChallenge, onClickCancelStopChallenge: onClickCancelStopChallenge }))) : (React.createElement(LessonPlaylistBox, { currentLessonIndex: currentLessonIndex, nextLesson: nextLesson, programLessons: programLessons, addFavoriteLesson: addFavoriteLesson, unFavoriteLesson: unFavoriteLesson, isShowOtherLessons: isShowOtherLessons, onClickShowOtherLessonsIcon: onClickShowOtherLessonsIcon, showTopImg: true, buttonText: !isXL && !hasOrderIndex && !playlist.isChallenge && 'プレイリストを再生', buttonClick: onClickLessonPlaylistBoxButton, onClickLesson: onClickLesson }))))));
};
