var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import videojs from 'video.js';
import { LessonResultSection } from '../../organisms/lesson_result_section';
import { BrowserRouter } from 'react-router-dom';
var VjsResult = /** @class */ (function (_super) {
    __extends(VjsResult, _super);
    function VjsResult(player, options) {
        var _this = _super.call(this, player, options) || this;
        Object.defineProperty(_this, "videoResultOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "resultPlayer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        _this.videoResultOptions = options;
        _this.resultPlayer = player;
        _this.mount = _this.mount.bind(_this);
        _this.resultPlayer.ready(function () {
            _this.mount();
        });
        return _this;
    }
    Object.defineProperty(VjsResult.prototype, "mount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var root = ReactDOM.createRoot(this.el());
            root.render(React.createElement(BrowserRouter, null,
                React.createElement(LessonResultSection, __assign({ onClickExit: this.videoResultOptions.onClickExit }, this.processedOptions))));
        }
    });
    Object.defineProperty(VjsResult.prototype, "processedOptions", {
        get: function () {
            this.videoResultOptions.resultOptions.vm.setVideoRefs(this.resultPlayer);
            return this.videoResultOptions.resultOptions;
        },
        enumerable: false,
        configurable: true
    });
    return VjsResult;
}(videojs.getComponent('Component')));
export default VjsResult;
