var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useXlMediaQuery } from '@lean-body/src/util';
import { Colors, MediaBreakPointUp } from '../styles';
import { PaginationNextButton } from '../atoms/pagination_next_button';
import { MerchandiseComponent } from '../molecules/merchandise_component';
export var MerchandisesList = function (_a) {
    var classObject = _a.classObject, items = _a.items, getNextPage = _a.getNextPage, disableNextButton = _a.disableNextButton;
    var isXL = useXlMediaQuery();
    // PCは1列が6つなので、足りない分の擬似要素を入れる
    // SP/Tabletは常に擬似要素1つでok
    var shadowItemLength = isXL ? items.length % 6 : 1;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.items) },
            items.map(function (v, i) { return (React.createElement(MerchandiseComponent, { merchandise: v, key: i })); }),
            __spreadArray([], Array(shadowItemLength), true).map(function (_, i) { return (React.createElement("div", { className: css(styles.item), key: i })); })),
        React.createElement(PaginationNextButton, { classObject: styles.paginationButton, getNextPage: getNextPage, isAvailable: !disableNextButton })));
};
var styles = StyleSheet.create({
    container: {},
    items: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: '16px 10px',
    },
    item: (_a = {
            color: Colors.Black,
            width: 'calc(50% - 5px)'
        },
        _a[MediaBreakPointUp.MD] = {
            width: 'calc(33.3% - 7px)',
        },
        _a[MediaBreakPointUp.XL] = {
            width: 'calc(16.6% - 8px)',
        },
        _a),
    paginationButton: (_b = {
            margin: '0 0 32px'
        },
        _b[MediaBreakPointUp.MD] = {
            margin: '0 0 16px',
        },
        _b),
});
