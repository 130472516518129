var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { css, StyleSheet } from 'aphrodite/no-important';
import * as React from 'react';
import { MediaBreakPointUp } from '@lean-body/components/styles';
var FullWidthLayout = /** @class */ (function (_super) {
    __extends(FullWidthLayout, _super);
    function FullWidthLayout() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FullWidthLayout.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement("div", { className: this.containerClass }, this.props.children);
        }
    });
    Object.defineProperty(FullWidthLayout.prototype, "containerClass", {
        get: function () {
            return [this.props.className || '', css(this.styles.container)].join(' ');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FullWidthLayout.prototype, "styles", {
        get: function () {
            var _a;
            return StyleSheet.create({
                container: (_a = {
                        backgroundImage: this.props.noBackground ? '' : 'url("/assets/images/backgrounds/1.jpg")',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '100%',
                        overflowY: 'scroll',
                        padding: this.props.noPadding ? '0' : '1.5rem 0',
                        '-webkit-overflow-scrolling': 'touch',
                        ':before': {
                            position: 'fixed',
                            content: this.props.noBackground ? '' : '" "',
                            display: 'block',
                            width: '100%',
                            height: '100%',
                            top: 0,
                            left: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        }
                    },
                    _a[MediaBreakPointUp.SM] = {
                        padding: this.props.noPadding ? '0' : '3rem 0',
                    },
                    _a),
            });
        },
        enumerable: false,
        configurable: true
    });
    return FullWidthLayout;
}(React.Component));
export { FullWidthLayout };
