var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { liveEventSections } from '../../domain';
import { InstagramIcon } from '../atoms/icons/instagram_icon';
export var LiveEventSchedules = function (_a) {
    var config = _a.config;
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.title) }, "Event Schedule"),
        liveEventSections(config).map(function (v, i) {
            return (React.createElement("div", { className: css(styles.section), key: i },
                React.createElement("div", { className: css(styles.sectionTime) }, v.time),
                React.createElement("div", { className: css(styles.sectionCard) },
                    React.createElement("div", { className: css(styles.sectionCardLabel) }, v.label),
                    React.createElement("div", { className: css(styles.sectionCardTitle) }, v.title),
                    React.createElement("div", { className: css(styles.sectionCardDesc) }, v.desc),
                    React.createElement("div", { className: css(styles.instructor) },
                        React.createElement("img", { src: v.trainer.thumbnailURL, className: css(styles.instructorIcon) }),
                        React.createElement("div", { className: css(styles.instructorDetail) },
                            React.createElement("div", { className: css(styles.instructorLabel) }, v.trainerLabel),
                            React.createElement("div", { className: css(styles.instructorName) }, v.trainer.name),
                            React.createElement("div", { className: css(styles.instructorDesc) }, v.trainer.bio),
                            React.createElement("a", { href: v.trainer.instaUrl, className: css(styles.instagram), target: "_blank" },
                                React.createElement(InstagramIcon, null),
                                React.createElement("div", { className: css(styles.instructorInstagram) },
                                    v.trainer.name,
                                    "\u306EInstagram")))))));
        })));
};
var styles = StyleSheet.create({
    container: (_a = {
            borderTop: "1px solid ".concat(Colors.Gray),
            padding: '28px 24px'
        },
        _a[MediaBreakPointUp.XL] = {
            padding: '40px 0 170px',
        },
        _a),
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        marginBottom: 4,
    },
    section: {
        marginTop: 24,
    },
    sectionTime: (_b = {
            fontWeight: 'bold',
            marginBottom: 12
        },
        _b[MediaBreakPointUp.XL] = {
            fontSize: 18,
        },
        _b),
    sectionCard: (_c = {
            backgroundColor: Colors.White,
            position: 'relative',
            padding: '68px 20px 24px'
        },
        _c[MediaBreakPointUp.MD] = {
            padding: '68px 30px 24px',
        },
        _c[MediaBreakPointUp.XL] = {
            padding: '34px 40px 34px 160px',
        },
        _c),
    sectionCardLabel: (_d = {
            fontSize: 14,
            fontWeight: 'bold',
            padding: '8px 11px',
            textAlign: 'center',
            backgroundColor: Colors.Orange,
            color: Colors.White,
            borderRadius: '0px 20px 20px 0px',
            position: 'absolute',
            left: 0,
            top: 16,
            width: 134
        },
        _d[MediaBreakPointUp.XL] = {
            top: 34,
        },
        _d),
    sectionCardTitle: (_e = {
            fontSize: 16,
            fontWeight: 'bold',
            marginBottom: 8
        },
        _e[MediaBreakPointUp.XL] = {
            fontSize: 18,
        },
        _e),
    sectionCardDesc: (_f = {
            fontSize: 13,
            lineHeight: '21px',
            whiteSpace: 'pre-wrap'
        },
        _f[MediaBreakPointUp.XL] = {
            fontSize: 14,
        },
        _f),
    instructor: {
        marginTop: 20,
        display: 'flex',
    },
    instructorIcon: (_g = {
            borderRadius: '50%',
            border: "1px solid ".concat(Colors.Gray),
            width: 44,
            height: 44,
            marginRight: 12
        },
        _g[MediaBreakPointUp.XL] = {
            width: 54,
            height: 54,
        },
        _g),
    instructorDetail: {
        marginTop: 4,
    },
    instructorLabel: {
        fontSize: 11,
        fontWeight: 'bold',
        color: Colors.GrayDarkest,
        whiteSpace: 'pre-wrap',
    },
    instructorName: (_h = {
            fontSize: 14,
            fontWeight: 'bold',
            margin: '2px 0 6px'
        },
        _h[MediaBreakPointUp.XL] = {
            fontSize: 16,
        },
        _h),
    instructorDesc: (_j = {
            fontSize: 12,
            color: Colors.GrayDarkest,
            marginBottom: 6,
            lineHeight: '19px',
            whiteSpace: 'pre-wrap'
        },
        _j[MediaBreakPointUp.XL] = {
            fontSize: 13,
            marginBottom: 8,
            lineHeight: '20px',
        },
        _j),
    instructorInstagramIcon: {
        height: 16,
        width: 16,
    },
    instagram: {
        display: 'flex',
        alignItems: 'center',
    },
    instructorInstagram: {
        fontSize: 12,
        color: Colors.Primary,
        marginLeft: 8,
        textDecoration: 'underline',
    },
});
