var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useSmMediaQuery } from '@lean-body/src/util';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Dialog } from './dialog';
export var MealInputMenu = function (_a) {
    var onClickBreakfast = _a.onClickBreakfast, onClickLunch = _a.onClickLunch, onClickDinner = _a.onClickDinner, onClickSnacking = _a.onClickSnacking, onClickCancel = _a.onClickCancel;
    var isSM = useSmMediaQuery();
    return (React.createElement(Dialog, { classObject: styles.dialog, onClickCancel: onClickCancel, isBottom: isSM, borderRadius: 14, content: React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.item, styles.itemBorder), onClick: onClickBreakfast },
                React.createElement("div", { className: css(styles.title) }, "\u671D\u98DF")),
            React.createElement("div", { className: css(styles.item, styles.itemBorder), onClick: onClickLunch },
                React.createElement("div", { className: css(styles.title) }, "\u663C\u98DF")),
            React.createElement("div", { className: css(styles.item, styles.itemBorder), onClick: onClickDinner },
                React.createElement("div", { className: css(styles.title) }, "\u5915\u98DF ")),
            React.createElement("div", { className: css(styles.item, styles.itemBorder), onClick: onClickSnacking },
                React.createElement("div", { className: css(styles.title) }, "\u9593\u98DF ")),
            React.createElement("div", { className: css(styles.item), onClick: onClickCancel },
                React.createElement("div", { className: css(styles.title) }, "\u30AD\u30E3\u30F3\u30BB\u30EB "))) }));
};
var styles = StyleSheet.create({
    dialog: (_a = {
            padding: '0 12px 10px'
        },
        _a[MediaBreakPointUp.MD] = {
            width: 375,
            padding: '0 12px',
        },
        _a),
    item: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 62,
        padding: '18px 24px',
        cursor: 'pointer',
    },
    itemBorder: {
        borderBottom: "0.5px solid ".concat(Colors.Gray),
    },
    title: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
});
