var _a, _b;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { MediaBreakPointUp } from '@lean-body/components/styles';
import { GoalMatchingAssessmentAnswerResponse, KIND_COURSE_DAYS_OF_WEEK, KIND_WEIGHT_GOAL, } from '@lean-body/src/domain';
import { useState, useEffect } from 'react';
import { GoalMatchingAssessmentForm } from './goal_matching_assessment_form';
import { PageLoading } from './page_loading';
import { GoalMatchingAssessmentResult } from './goal_matching_assessment_result';
import { GoalMatchingAssessmentStartPage } from './goal_matching_assessment_start_page';
export var GoalMatchingAssessment = function (_a) {
    var apiClient = _a.apiClient, config = _a.config, setProfile = _a.setProfile, assessments = _a.assessments, pastAnswers = _a.pastAnswers, isNewUser = _a.isNewUser, isReassessment = _a.isReassessment, showStartPage = _a.showStartPage, skipToKind = _a.skipToKind;
    var _b = useState(showStartPage), isShowStartPage = _b[0], setIsShowStartPage = _b[1];
    var _c = useState(false), showResultsPage = _c[0], setShowResultsPage = _c[1];
    var _d = useState(0), assessmentIndex = _d[0], setAssessmentIndex = _d[1];
    var _e = useState([]), currentAnswers = _e[0], setCurrentAnswers = _e[1];
    // 診断の表示や結果画面で使われる
    var _f = useState(''), userName = _f[0], setUserName = _f[1];
    var _g = useState(), activityLevel = _g[0], setActivityLevel = _g[1];
    var _h = useState(), gender = _h[0], setGender = _h[1];
    var _j = useState(''), birthday = _j[0], setBirthday = _j[1];
    var _k = useState(), currentHeight = _k[0], setCurrentHeight = _k[1];
    var _l = useState(), currentWeight = _l[0], setCurrentWeight = _l[1];
    var _m = useState(), weightGoal = _m[0], setWeightGoal = _m[1];
    var _o = useState(), weightGoalPaceId = _o[0], setWeightGoalPaceId = _o[1];
    var _p = useState(false), isWeightKeeping = _p[0], setIsWeightKeeping = _p[1];
    useEffect(function () {
        if (skipToKind) {
            var idx = findAssessmentIndexByKind(skipToKind);
            setAssessmentIndex(idx);
            applyPastAnswers(idx, pastAnswers);
        }
    }, []);
    var setAssessmentInfo = function (assessment, answer) {
        if (assessment.isKindGender) {
            setGender(answer.choiceIds[0]);
        }
        else if (assessment.isKindActivityLevel) {
            setActivityLevel(answer.choiceIds[0]);
        }
        else if (assessment.isKindUserName) {
            setUserName(answer.textInput);
        }
        else if (assessment.isKindBirthday) {
            setBirthday(answer.textInput);
        }
        else if (assessment.isKindHeight) {
            setCurrentHeight(Number(answer.textInput));
        }
        else if (assessment.isKindWeight) {
            setCurrentWeight(Number(answer.textInput));
        }
        else if (assessment.isKindWeightGoal) {
            var value = Number(answer.textInput);
            setWeightGoal(value);
            setIsWeightKeeping(currentWeight === value);
        }
        else if (assessment.isKindWeightGoalPace) {
            setWeightGoalPaceId(answer.choiceIds[0]);
        }
    };
    // kindで特定の質問indexを取得する
    var findAssessmentIndexByKind = function (kind) {
        return assessments.findIndex(function (assessment) { return assessment.kind === kind; });
    };
    // indexまで過去の回答を反映する
    var applyPastAnswers = function (idx, pastAnswers) {
        var answers = [];
        assessments.slice(0, idx).forEach(function (assessment, index) {
            var pastAnswer = pastAnswers.find(function (answer) { return assessment.id === answer.matchingAssessmentId; });
            var answer = GoalMatchingAssessmentAnswerResponse.createEmptyParam(assessment);
            if (pastAnswer) {
                answer = pastAnswer.toParam();
                setAssessmentInfo(assessment, answer);
            }
            answers[index] = answer;
        });
        setCurrentAnswers(answers);
    };
    var onClickNext = function () {
        var _a;
        if (isShowStartPage) {
            setIsShowStartPage(false);
            return;
        }
        setAssessmentInfo(assessments[assessmentIndex], currentAnswers[assessmentIndex]);
        // 現状維持の場合コース日選択まで飛ばす（目標ペース、アプローチはスキップ）
        if (assessments[assessmentIndex].isKindWeightGoal) {
            var weightGoal_1 = Number((_a = currentAnswers[assessmentIndex]) === null || _a === void 0 ? void 0 : _a.textInput);
            if (currentWeight === weightGoal_1) {
                var idx = findAssessmentIndexByKind(KIND_COURSE_DAYS_OF_WEEK);
                setAssessmentIndex(idx);
                return;
            }
        }
        var isFinished = assessmentIndex === assessments.length - 1;
        if (isFinished) {
            setShowResultsPage(true);
        }
        else {
            setAssessmentIndex(assessmentIndex + 1);
        }
    };
    var onClickBack = function () {
        if (assessmentIndex === 0) {
            setIsShowStartPage(true);
            return;
        }
        // 現状維持の場合、目標ペース、アプローチの質問を飛ばすため
        if (isWeightKeeping && assessments[assessmentIndex].isKindCourseDaysOfWeek) {
            var idx = findAssessmentIndexByKind(KIND_WEIGHT_GOAL);
            setAssessmentIndex(idx);
            return;
        }
        setAssessmentIndex(assessmentIndex - 1);
    };
    var onClickBackForResult = function () {
        setShowResultsPage(false);
    };
    if (!assessments) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(styles.container) }, isShowStartPage ? (React.createElement(GoalMatchingAssessmentStartPage, { isNewUser: isNewUser, onClickNext: onClickNext })) : showResultsPage ? (React.createElement(GoalMatchingAssessmentResult, { apiClient: apiClient, config: config, userName: userName, currentAnswers: currentAnswers, isWeightKeeping: isWeightKeeping, isReassessment: isReassessment, setProfile: setProfile, onClickBack: onClickBackForResult })) : (React.createElement("div", { className: css(styles.content) },
        React.createElement(GoalMatchingAssessmentForm, { apiClient: apiClient, config: config, index: assessmentIndex, questionCount: assessments.length, matchingAssessment: assessments[assessmentIndex], pastAnswers: pastAnswers, currentAnswers: currentAnswers, activityLevel: activityLevel, gender: gender, birthday: birthday, currentHeight: currentHeight, currentWeight: currentWeight, weightGoal: weightGoal, weightGoalPaceId: weightGoalPaceId, onClickNext: onClickNext, onClickBack: onClickBack, showProgress: !isReassessment })))));
};
var styles = StyleSheet.create({
    container: (_a = {
            position: 'relative',
            height: '100%',
            paddingTop: 50
        },
        _a[MediaBreakPointUp.MD] = {
            paddingTop: 0,
        },
        _a),
    content: (_b = {
            padding: '24px 16px'
        },
        _b[MediaBreakPointUp.MD] = {
            padding: 0,
        },
        _b),
});
