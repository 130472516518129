var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c, _d;
/* eslint-disable no-dupe-class-members */
import { css, StyleSheet } from 'aphrodite';
import { bind } from 'bind-decorator';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import videojs from 'video.js';
import * as React from 'react';
import { Lesson } from '@lean-body/src/domain';
import { VideoVM } from '@lean-body/components/molecules/video';
import { Colors, MediaBreakPointUp } from '../styles';
import { LessonComponent } from '../molecules/lesson_component';
import { Button } from '../atoms';
export var LessonResultSectionState;
(function (LessonResultSectionState) {
    LessonResultSectionState[LessonResultSectionState["Loading"] = 0] = "Loading";
    LessonResultSectionState[LessonResultSectionState["Ready"] = 1] = "Ready";
    LessonResultSectionState[LessonResultSectionState["Finished"] = 2] = "Finished";
})(LessonResultSectionState || (LessonResultSectionState = {}));
var State = LessonResultSectionState;
export var LessonResultSectionComponent = function (_a) {
    var vm = _a.vm, onClickExit = _a.onClickExit, addFavoriteLesson = _a.addFavoriteLesson, unFavoriteLesson = _a.unFavoriteLesson;
    React.useEffect(function () {
        return vm.onUnMount;
    }, []);
    if (!vm.videoVM.nextLesson) {
        return null;
    }
    return (vm.isVisible && (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.header) },
            React.createElement("div", { className: css(styles.iconContainer), onClick: onClickExit, "data-test": "exitButton" },
                React.createElement("img", { src: "/assets/images/video/back.svg", className: css(styles.icon) })),
            React.createElement("div", { className: css(styles.playText) },
                vm.nextLessonStartTimerCount,
                "\u79D2\u5F8C\u306B\u6B21\u306E\u52D5\u753B\u3092\u518D\u751F")),
        React.createElement(LessonComponent, { lesson: vm.videoVM.nextLesson, addFavoriteLesson: addFavoriteLesson, unFavoriteLesson: unFavoriteLesson, onClick: vm.playNextVideo, isVertical: true, isMin: true, isWhiteColor: true, classObject: styles.lessonContainer }),
        React.createElement("div", { className: css(styles.buttonContainer) },
            React.createElement(Button, { className: css(styles.button, styles.cancel), onClick: vm.onClickCancel, isRadius: true }, "\u30AD\u30E3\u30F3\u30BB\u30EB"),
            React.createElement(Button, { className: css(styles.button, styles.play), onClick: vm.onClickPlay, isRadius: true }, "\u4ECA\u3059\u3050\u518D\u751F")))));
};
export var LessonResultSection = observer(LessonResultSectionComponent);
var LessonResultSectionVM = /** @class */ (function () {
    function LessonResultSectionVM(nextLesson, playNextVideo) {
        // 自動再生のタイマーを管理
        Object.defineProperty(this, "timerId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // 自動再生までの時間
        Object.defineProperty(this, "nextLessonStartTimerCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "player", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "videoVM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // 動画の視聴完了UIのステータス
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: LessonResultSectionState.Loading
        });
        // 動画の視聴完了UIの表示制御
        Object.defineProperty(this, "isVisible", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        // 次の動画の自動再生を制御する
        Object.defineProperty(this, "isAutoPlay", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        // 次の動画のカウントダウンを制御する
        Object.defineProperty(this, "isCountdown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        // 次に再生する動画
        Object.defineProperty(this, "nextLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "watchingTimeSecond", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "unMounted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "playNextVideo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            nextLessonStartTimerCount: observable,
            player: observable,
            videoVM: observable,
            state: observable,
            isVisible: observable,
            isAutoPlay: observable,
            isCountdown: observable,
            nextLesson: observable,
            watchingTimeSecond: observable,
        });
        this.nextLesson = nextLesson;
        this.playNextVideo = playNextVideo;
    }
    Object.defineProperty(LessonResultSectionVM.prototype, "onUnMount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.clearHideTimer();
            this.unMounted = true;
        }
    });
    Object.defineProperty(LessonResultSectionVM.prototype, "finishLessonResultSection", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.isVisible = false;
            this.clearHideTimer();
            this.finish();
            this.showVideoPlayer();
        }
    });
    Object.defineProperty(LessonResultSectionVM.prototype, "initialize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (videoVM) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.videoVM = videoVM;
                    this.ready();
                    return [2 /*return*/];
                });
            });
        }
    });
    // 動画終了プログレスバーの表示が終わったタイミングのコールバック
    Object.defineProperty(LessonResultSectionVM.prototype, "closeLessonProgressUpdate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.startAutoPlaySection();
        }
    });
    Object.defineProperty(LessonResultSectionVM.prototype, "setState", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (state) {
            this.state = state;
        }
    });
    Object.defineProperty(LessonResultSectionVM.prototype, "setVideoRefs", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (player) {
            this.player = player;
        }
    });
    Object.defineProperty(LessonResultSectionVM.prototype, "setNextLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (nextLesson) {
            this.nextLesson = nextLesson;
        }
    });
    Object.defineProperty(LessonResultSectionVM.prototype, "setIsAutoPlay", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isAutoPlay) {
            this.isAutoPlay = isAutoPlay;
        }
    });
    Object.defineProperty(LessonResultSectionVM.prototype, "onClickCancel", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            clearInterval(this.timerId);
                            this.isVisible = false;
                            return [4 /*yield*/, this.showVideoPlayer()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // 動画の視聴完了時に実行
    Object.defineProperty(LessonResultSectionVM.prototype, "start", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: 
                        // HACK: 視聴完了した動画が背景に残ってしまう場合があるためCSSで非表示にしています。。
                        return [4 /*yield*/, this.hideVideoPlayer()];
                        case 1:
                            // HACK: 視聴完了した動画が背景に残ってしまう場合があるためCSSで非表示にしています。。
                            _a.sent();
                            if (this.isAutoPlay) {
                                this.isVisible = true;
                                this.startAutoPlaySection();
                            }
                            else {
                                this.onClickCancel();
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(LessonResultSectionVM.prototype, "onClickPlay", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.clearHideTimer();
            this.playNextVideo();
        }
    });
    Object.defineProperty(LessonResultSectionVM.prototype, "ready", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.setState(State.Ready);
        }
    });
    Object.defineProperty(LessonResultSectionVM.prototype, "finish", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.setState(State.Finished);
        }
    });
    // HACK: 動画プレイヤーを非表示にする
    Object.defineProperty(LessonResultSectionVM.prototype, "hideVideoPlayer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.videoVM.addClass('hideVideoPlayer')];
                        case 1:
                            _a.sent();
                            this.videoVM.hideController();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // HACK: 動画プレイヤーを表示する
    Object.defineProperty(LessonResultSectionVM.prototype, "showVideoPlayer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.videoVM.removeClass('hideVideoPlayer')];
                        case 1:
                            _a.sent();
                            this.videoVM.showController();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(LessonResultSectionVM.prototype, "setIsCountdown", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isCountdown) {
            this.isCountdown = isCountdown;
        }
    });
    Object.defineProperty(LessonResultSectionVM.prototype, "clearHideTimer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.timerId) {
                clearTimeout(this.timerId);
            }
        }
    });
    /*---------- private methods  ----------*/
    // 次の動画を再生するUIを表示してカウントダウンを開始する
    Object.defineProperty(LessonResultSectionVM.prototype, "startAutoPlaySection", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.setState(State.Finished);
            this.nextLessonStartTimerCount = 10;
            this.nextLessonStartTimer();
        }
    });
    // 指定時間の経過後に次の動画を再生する
    Object.defineProperty(LessonResultSectionVM.prototype, "nextLessonStartTimer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            clearInterval(this.timerId); // nextLessonStartTimer()が複数回実行された場合、過去のsetInterval()は消す
            this.timerId = window.setInterval(function () {
                if (--_this.nextLessonStartTimerCount < 0) {
                    clearInterval(_this.timerId);
                    _this.playNextVideo();
                }
            }, 1000);
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], LessonResultSectionVM.prototype, "finishLessonResultSection", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [VideoVM]),
        __metadata("design:returntype", Promise)
    ], LessonResultSectionVM.prototype, "initialize", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], LessonResultSectionVM.prototype, "closeLessonProgressUpdate", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", void 0)
    ], LessonResultSectionVM.prototype, "setState", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [videojs.Player]),
        __metadata("design:returntype", void 0)
    ], LessonResultSectionVM.prototype, "setVideoRefs", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Lesson]),
        __metadata("design:returntype", void 0)
    ], LessonResultSectionVM.prototype, "setNextLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], LessonResultSectionVM.prototype, "setIsAutoPlay", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], LessonResultSectionVM.prototype, "onClickCancel", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], LessonResultSectionVM.prototype, "start", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], LessonResultSectionVM.prototype, "onClickPlay", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], LessonResultSectionVM.prototype, "ready", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], LessonResultSectionVM.prototype, "finish", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], LessonResultSectionVM.prototype, "hideVideoPlayer", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], LessonResultSectionVM.prototype, "showVideoPlayer", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], LessonResultSectionVM.prototype, "setIsCountdown", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], LessonResultSectionVM.prototype, "clearHideTimer", null);
    return LessonResultSectionVM;
}());
export { LessonResultSectionVM };
var styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        gap: 14,
        left: 0,
        position: 'absolute',
        top: 0,
        zIndex: 8,
        width: '100%',
        height: '100%',
        padding: '16px 24px',
    },
    header: {
        textAlign: 'center',
    },
    iconContainer: (_a = {
            position: 'absolute',
            top: 14,
            left: 16,
            cursor: 'pointer',
            display: 'inline-block',
            textAlign: 'center',
            transition: 'opacity 0.2s',
            ':hover': {
                opacity: 0.8,
            }
        },
        _a[MediaBreakPointUp.MD] = {
            left: 24,
        },
        _a),
    icon: {
        width: 24,
    },
    playText: (_b = {
            fontSize: 12,
            fontWeight: 'bold',
            letterSpacing: '4%',
            lineHeight: '160%'
        },
        _b[MediaBreakPointUp.MD] = {
            fontSize: 14,
            marginTop: 64,
        },
        _b),
    lessonContainer: (_c = {},
        _c[MediaBreakPointUp.MD] = {
            width: 327,
        },
        _c),
    buttonContainer: (_d = {
            display: 'flex',
            width: '100%',
            gap: 7
        },
        _d[MediaBreakPointUp.MD] = {
            width: 327,
            marginBottom: 64,
        },
        _d),
    button: {
        width: '50%',
        height: 36,
        padding: 8,
    },
    cancel: {
        backgroundColor: Colors.GrayDarkestB,
    },
    play: {
        backgroundColor: Colors.PrimaryDark,
    },
});
