import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { routes } from '@lean-body/src/route';
import { PlanChangeSubsection, PageLoading } from '@lean-body/components/organisms';
import { Colors } from '@lean-body/components/styles';
import { useNavigate } from 'react-router';
import { SettingItemKeys } from '../organisms/setting_menu';
export var PlanChangePage = function (_a) {
    var vm = _a.vm;
    var _b = React.useState(), me = _b[0], setMe = _b[1];
    var _c = React.useState(), plan = _c[0], setPlan = _c[1];
    var navigate = useNavigate();
    React.useEffect(function () {
        vm.accountService.fetchMe().then(function (v) {
            if (!v.canChangePlans) {
                navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.plan));
                return;
            }
            setMe(v);
        });
        vm.apiClient.fetchChangeablePlans().then(function (plans) {
            setPlan(plans[0]);
        });
    }, []);
    if (!me || !plan)
        return React.createElement(PageLoading, null);
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(PlanChangeSubsection, { me: me, changePlan: plan, apiClient: vm.apiClient, accountService: vm.accountService, tracker: vm.tracker, alertPresenter: vm.alertPresenter })));
};
var PlanChangePageVM = /** @class */ (function () {
    function PlanChangePageVM(accountService, apiClient, tracker, alertPresenter) {
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.accountService = accountService;
        this.apiClient = apiClient;
        this.tracker = tracker;
        this.alertPresenter = alertPresenter;
    }
    return PlanChangePageVM;
}());
export { PlanChangePageVM };
var styles = StyleSheet.create({
    container: {
        padding: '30px 30px 0',
        minHeight: '100vh',
        textAlign: 'center',
        backgroundColor: Colors.White,
    },
});
