import { css, StyleSheet } from 'aphrodite/no-important';
import * as React from 'react';
import { Colors } from '@lean-body/components/styles';
export var Card = function (props) {
    var containerClass = [
        props.className || '',
        css(styles.container),
        props.isBordered && css(styles.borderedContainer),
        props.isCentered && css(styles.centeredContainer),
    ].join(' ');
    return (React.createElement("div", { className: containerClass, id: props.id || '' }, props.children));
};
var styles = StyleSheet.create({
    container: {
        background: Colors.White,
        padding: '1rem',
        border: "1px solid ".concat(Colors.Gray),
    },
    borderedContainer: {
        borderRadius: '5px',
        borderStyle: 'none',
    },
    centeredContainer: {
        textAlign: 'center',
    },
});
