import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var CourseFeatureThumbnail = function (_a) {
    var src = _a.src, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("img", { src: src, alt: "course feature", className: css(styles.image) })));
};
var styles = StyleSheet.create({
    container: {
        width: 78,
        height: 78,
        border: "0.5px solid ".concat(Colors.BorderLightGray),
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        maxHeight: '100%',
    },
});
