var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { routes } from '@lean-body/src/route';
import { PageLoading, FIXED_FOOTER_HEIGHT } from '@lean-body/components/organisms';
import { Colors, MediaBreakPointUp, Sizes } from '@lean-body/components/styles';
import { useNavigate } from 'react-router';
import { SettingItemKeys } from '../organisms/setting_menu';
import { CoachingPlanUnsubscribeSubsection } from '../organisms/coaching_plan_unsubscribe_subsection';
export var CoachingPlanUnsubscribePage = function (_a) {
    var apiClient = _a.apiClient, accountService = _a.accountService, alertPresenter = _a.alertPresenter;
    var navigate = useNavigate();
    var _b = React.useState(), me = _b[0], setMe = _b[1];
    React.useEffect(function () {
        accountService.fetchMe().then(function (v) {
            if (!v.payment || !v.payment.coachingPayment || v.payment.coachingPayment.deleted) {
                navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.plan));
                return;
            }
            setMe(v);
        });
    }, []);
    if (!me)
        return React.createElement(PageLoading, null);
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(CoachingPlanUnsubscribeSubsection, { coachingPayment: me.payment.coachingPayment, apiClient: apiClient, alertPresenter: alertPresenter })));
};
var styles = StyleSheet.create({
    container: (_a = {
            backgroundColor: Colors.White,
            textAlign: 'center',
            minHeight: "calc(100vh - ".concat(FIXED_FOOTER_HEIGHT, "px - ").concat(Sizes.MobileHeaderHeight, "px)"),
            padding: '0 30px'
        },
        _a[MediaBreakPointUp.MD] = {
            minHeight: '100vh',
        },
        _a),
});
