var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/* eslint-disable */
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import videojs from 'video.js';
import { css, StyleSheet } from 'aphrodite';
import { bind } from 'bind-decorator';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { isMobileSafari, isiOSApp } from '../../../util';
var FullScreenButton = observer(/** @class */ (function (_super) {
    __extends(FullScreenButton, _super);
    function FullScreenButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FullScreenButton.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var img = this.props.vm.isFullScreen ? 'fullscreen_off.svg' : 'fullscreen_on.svg';
            return (React.createElement("div", { className: css(styles.wrapper) },
                React.createElement("img", { src: "/assets/images/video/".concat(img), className: css(styles.image) })));
        }
    });
    return FullScreenButton;
}(React.Component)));
var VjsFullScreenButtonVM = /** @class */ (function () {
    function VjsFullScreenButtonVM() {
        Object.defineProperty(this, "isFullScreen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        makeObservable(this, {
            isFullScreen: observable,
        });
    }
    return VjsFullScreenButtonVM;
}());
var VjsFullScreenButton = /** @class */ (function (_super) {
    __extends(VjsFullScreenButton, _super);
    function VjsFullScreenButton(player, options) {
        var _this = _super.call(this, player, options) || this;
        Object.defineProperty(_this, "fullScreenButtonPlayer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "vm", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        _this.fullScreenButtonPlayer = player;
        _this.vm = new VjsFullScreenButtonVM();
        _this.mount = _this.mount.bind(_this);
        _this.fullScreenButtonPlayer.ready(function () {
            _this.mount();
        });
        return _this;
    }
    Object.defineProperty(VjsFullScreenButton.prototype, "handleFullscreenChange", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            // @ts-ignore FullscreenToggle のフルスクリーン切り替えAPIをコール
            _super.prototype.handleFullscreenChange.call(this, e);
            // HACK: iOS Safari は Fullscreen API が非対応でiOS独自のフルスクリーンが展開されるためアイコンの状態は変化させない
            if (isMobileSafari() || isiOSApp()) {
                return;
            }
            var sideSuper = this.fullScreenButtonPlayer.getChild('VjsVideoSideSuper');
            if (sideSuper) {
                sideSuper.vm.setIsFullScreen(!this.vm.isFullScreen);
            }
            var controller = this.fullScreenButtonPlayer.getChild('VjsVideoController');
            controller.vm.setIsFullScreen(!this.vm.isFullScreen);
            this.vm.isFullScreen = !this.vm.isFullScreen;
        }
    });
    Object.defineProperty(VjsFullScreenButton.prototype, "mount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var root = ReactDOM.createRoot(this.el());
            root.render(React.createElement(FullScreenButton, { vm: this.vm }));
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], VjsFullScreenButton.prototype, "handleFullscreenChange", null);
    return VjsFullScreenButton;
}(videojs.getComponent('FullscreenToggle')));
export default VjsFullScreenButton;
var styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '2vh',
        marginRight: 4,
        outline: 'none',
    },
    image: {
        width: 20,
    },
});
