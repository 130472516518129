import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Button, ButtonType, CloseButtonLabel, ProgressDots } from '../atoms';
import { Dialog } from '../molecules';
import { Colors } from '../styles';
export var MealImageAnalysisDialog = function (_a) {
    var onClick = _a.onClick;
    var _b = React.useState(0), pageIndex = _b[0], setPageIndex = _b[1];
    var pages = [
        {
            imageSrc: '/assets/images/dialog/meal_image_analysis_dialog_menu_change.jpg',
            title: '提案されたメニューが違っている時は',
            description: '提案されたメニューが間違っているときは、変更することができます。',
            buttonText: '次へ',
        },
        {
            imageSrc: '/assets/images/dialog/meal_image_analysis_dialog_amount_change.jpg',
            title: 'たくさん食べた、少しだけ食べた時は',
            description: '食べた量が100%でない時は、調整することができます。',
            buttonText: 'OK',
        },
    ];
    var isLastPage = function () {
        return pageIndex === pages.length - 1;
    };
    var onClickNext = function () { return (isLastPage() ? onClick() : setPageIndex(pageIndex + 1)); };
    return (React.createElement(Dialog, { onClickCancel: onClick, isCloseButton: true, closeButtonLabel: CloseButtonLabel.Close, classObject: styles.dialog, content: React.createElement(React.Fragment, null,
            React.createElement("img", { className: css(styles.image), src: pages[pageIndex].imageSrc }),
            React.createElement("div", { className: css(styles.info) },
                React.createElement("div", { className: css(styles.title) }, pages[pageIndex].title),
                React.createElement("div", { className: css(styles.description) }, pages[pageIndex].description),
                React.createElement(ProgressDots, { length: pages.length, currentIndex: pageIndex }),
                React.createElement(Button, { className: css(styles.button), isRadius: true, buttonType: ButtonType.pink, onClick: onClickNext }, pages[pageIndex].buttonText))) }));
};
var styles = StyleSheet.create({
    dialog: {
        maxWidth: 327,
        backgroundColor: Colors.White,
    },
    image: {
        width: '100%',
        borderRadius: '8px 8px 0 0',
    },
    info: {
        textAlign: 'center',
        padding: '16px 18.5px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: 18,
    },
    title: {
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '140%',
        letterSpacing: 1.6,
        whiteSpace: 'pre-line',
    },
    description: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
    button: {
        height: 48,
        padding: 0,
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
});
