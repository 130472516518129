import * as React from 'react';
import ReactLoading from 'react-loading';
import { Colors } from '@lean-body/components/styles';
import { css, StyleSheet } from 'aphrodite';
export var MoreLoading = function () {
    var styles = StyleSheet.create({
        container: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: 20,
            marginBottom: 20,
            width: '100%',
        },
        loading: {
            height: 40,
            width: 40,
        },
    });
    return (React.createElement("span", { className: css(styles.container) },
        React.createElement(ReactLoading, { type: "spin", color: Colors.Gray, className: css(styles.loading) })));
};
