import * as React from 'react';
import { Colors } from '@lean-body/components/styles';
import { css, StyleSheet } from 'aphrodite';
import { useNavigate } from 'react-router';
import { routes } from '@lean-body/src/route';
import { getFormattedToSlashFormat, toTimeFromUnix } from '@lean-body/src/util';
import { Logo } from '../atoms';
import { PageLoading } from '../organisms';
/**
 * メンテナンス画面
 */
export var MaintenancePage = function (props) {
    var apiClient = props.apiClient;
    var navigate = useNavigate();
    var _a = React.useState(null), maintenance = _a[0], SetMaintenance = _a[1];
    var endAt;
    var text;
    if (maintenance) {
        endAt = "".concat(getFormattedToSlashFormat(maintenance.endAt), " ").concat(toTimeFromUnix(maintenance.endAt));
        text = maintenance.text;
    }
    React.useEffect(function () {
        apiClient
            .ping()
            .then(function () {
            // 200で返却された場合、メンテナンスが終了しているので、TOPへリダイレクト
            navigate(routes.TOP.compile());
        })
            .catch(function (e) {
            if (e.isMaintenanceError) {
                var maintenanceErr = e;
                SetMaintenance(maintenanceErr.maintenance);
            }
        });
    }, []);
    if (!maintenance) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(Logo, { classObject: styles.logo }),
        React.createElement("div", { className: css(styles.title) }, "\u305F\u3060\u3044\u307E\u30E1\u30F3\u30C6\u30CA\u30F3\u30B9\u4E2D\u3067\u3059"),
        React.createElement("div", { className: css(styles.period) },
            React.createElement("span", null, "\u7D42\u4E86\u4E88\u5B9A\u6642\u523B"),
            React.createElement("span", null, endAt)),
        React.createElement("div", { className: css(styles.message) }, text)));
};
var styles = StyleSheet.create({
    container: {
        height: '100%',
        background: Colors.BackgroundLightGray,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        marginTop: 85,
        width: 141,
    },
    title: {
        marginTop: 10,
        fontWeight: 'bold',
        fontSize: 18,
    },
    period: {
        marginTop: 35,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    message: {
        margin: '45px 35px 0',
        fontSize: 14,
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
    },
});
