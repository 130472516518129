export var NUTRIENT_LIST = [
    { name: 'カロリー', key: 'calorie', message: '毎日チェック！' },
    { name: 'たんぱく質', key: 'protein', message: '筋肉や髪・肌のモト！' },
    { name: '脂質', key: 'lipid', message: 'ホルモンバランスの要！' },
    { name: '炭水化物', key: 'carbohydrate', message: 'エネルギー源！' },
    { name: '塩分', key: 'salt_amount', message: '摂りすぎはむくみに' },
    { name: 'カルシウム', key: 'calcium', message: '骨をつくるよ' },
    { name: '鉄分', key: 'iron', message: '貧血防止に♪' },
    { name: '食物繊維', key: 'fiber', message: '整腸効果' },
    { name: 'ビタミンA', key: 'vitamin_a', message: '免疫力アップ' },
    { name: 'ビタミンC', key: 'vitamin_c', message: '美肌の味方♪' },
    { name: 'ビタミンE', key: 'vitamin_e', message: '抗酸化作用' },
    { name: 'ビタミンB1', key: 'vitamin_b1', message: '疲労回復' },
    { name: 'ビタミンB2', key: 'vitamin_b2', message: 'スキンケア効果' },
    { name: 'ビタミンD', key: 'vitamin_d', message: '骨の健康に不可欠' },
    { name: 'コレステロール', key: 'cholesterol', message: '血液ドロドロの原因' },
];
export var NUTRIENT_STATUSES = [
    { text: '不足', key: 'lack' },
    { text: '適正', key: 'appropriate' },
    { text: '過剰', key: 'excess' },
];
var Nutrient = /** @class */ (function () {
    function Nutrient(data) {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "currentValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "minReferenceValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "maxReferenceValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "maxValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "unit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "statusText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "statusKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "message", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "key", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.name = data.name;
        this.currentValue = data.currentValue;
        this.minReferenceValue = data.minReferenceValue;
        this.maxReferenceValue = data.maxReferenceValue;
        this.maxValue = data.maxValue;
        this.unit = data.unit;
        var status = NUTRIENT_STATUSES.find(function (v) { return v.text === data.statusText; });
        this.statusText = status.text;
        this.statusKey = status.key;
        var info = NUTRIENT_LIST.find(function (v) { return v.key === data.key; });
        this.message = info.message;
        this.key = info.key;
    }
    Object.defineProperty(Nutrient.prototype, "isCalorie", {
        get: function () {
            return this.key === 'calorie';
        },
        enumerable: false,
        configurable: true
    });
    return Nutrient;
}());
export { Nutrient };
