var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Link } from 'react-router-dom';
import { AnnouncementFullContent } from '@lean-body/components/organisms/announcement_full_content';
import { PageLoading } from '@lean-body/components/organisms/page_loading';
import { isiOSApp } from '@lean-body/src/util';
export var MainAnnouncementDetail = observer(/** @class */ (function (_super) {
    __extends(MainAnnouncementDetail, _super);
    function MainAnnouncementDetail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(MainAnnouncementDetail.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a = this.props.vm, announcement = _a.announcement, me = _a.me;
            if (!announcement) {
                return React.createElement(PageLoading, null);
            }
            return (React.createElement("div", { className: css(this.styles.container) },
                React.createElement("div", { className: css(this.styles.content) },
                    React.createElement(AnnouncementFullContent, { announcement: announcement, me: me }),
                    !isiOSApp() && (React.createElement(Link, { className: css(this.styles.backToTop), to: "/announcements" }, "\u4E00\u89A7\u306B\u623B\u308B")))));
        }
    });
    Object.defineProperty(MainAnnouncementDetail.prototype, "styles", {
        get: function () {
            var _a, _b;
            return StyleSheet.create({
                container: {
                    backgroundColor: Colors.BackgroundLightGray,
                },
                content: (_a = {
                        maxWidth: 600
                    },
                    _a[MediaBreakPointUp.SM] = {
                        margin: '0 120px',
                    },
                    _a),
                backToTop: (_b = {
                        color: Colors.Primary,
                        textAlign: 'center',
                        display: 'block',
                        paddingBottom: 60,
                        textDecoration: 'underline',
                        fontSize: 14,
                        maxWidth: 600
                    },
                    _b[MediaBreakPointUp.SM] = {
                        margin: '0 120px 60px',
                    },
                    _b),
            });
        },
        enumerable: false,
        configurable: true
    });
    return MainAnnouncementDetail;
}(React.Component)));
var MainAnnouncementDetailVM = /** @class */ (function () {
    function MainAnnouncementDetailVM(id, apiClient, accountService) {
        var _this = this;
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "announcement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "me", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            announcement: observable,
        });
        this.apiClient = apiClient;
        this.apiClient.getAnnouncement(id).then(function (v) {
            _this.announcement = v;
        });
        accountService.fetchMe().then(function (me) {
            _this.me = me;
        });
    }
    return MainAnnouncementDetailVM;
}());
export { MainAnnouncementDetailVM };
