var _a, _b, _c, _d, _e, _f;
import * as React from 'react';
var useState = React.useState, useEffect = React.useEffect;
import { useNavigate } from 'react-router';
import { css, StyleSheet } from 'aphrodite';
import { routes } from '@lean-body/src/route';
import { MediaBreakPointUp } from '@lean-body/components/styles';
import { PageLoading, CourseDetail } from '@lean-body/components/organisms';
import { CourseSelectItem, ChangeCourseButtons, Dialog, ChangeCourseWarn } from '@lean-body/components/molecules';
import { useSmMediaQuery } from '@lean-body/src/util';
var statuses = {
    selectCourse: 1,
    showCourse: 2,
};
export var ChangeCoursePage = function (_a) {
    var config = _a.config, apiClient = _a.apiClient, tracker = _a.tracker;
    var navigate = useNavigate();
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(false), isDialogOpen = _c[0], setIsDialogOpen = _c[1];
    var _d = useState(statuses.selectCourse), status = _d[0], setStatus = _d[1];
    var _e = useState(null), courses = _e[0], setCourses = _e[1];
    var _f = useState(null), selectedCourse = _f[0], setSelectedCourse = _f[1];
    var isSM = useSmMediaQuery();
    useEffect(function () {
        if (status === statuses.selectCourse) {
            apiClient.fetchCourses('popularity').then(function (courses) {
                setCourses(courses);
                setSelectedCourse(courses.find(function (v) { return v.selected; }));
                setIsLoading(false);
            });
        }
    }, [status]);
    var onClickCourse = function (id) {
        tracker.trackClickEvent({ category: 'Select Course', label: "courseID: ".concat(id) });
        apiClient.fetchCourse(id).then(function (v) {
            setSelectedCourse(v);
            window.scrollTo({ top: 0 });
            setStatus(statuses.showCourse);
        });
    };
    var handleSelectCourse = function (id) {
        tracker.trackClickEvent({ category: 'Select Course', label: "courseID: ".concat(id) });
        var course = courses.find(function (course) { return course.id === id; });
        setSelectedCourse(course);
        if (course.selected) {
            return;
        }
        setIsDialogOpen(true);
    };
    var onClickChangeCourse = function () {
        setIsDialogOpen(true);
        tracker.trackClickEvent({ category: 'Change Course Clicks', label: "courseID: ".concat(selectedCourse.id) });
    };
    var onClickConfirmChangeCourse = function () {
        var courseID = selectedCourse.id;
        tracker.trackClickEvent({ category: 'Confirm Change Course', label: "courseID: ".concat(courseID) });
        apiClient.changeCourse(courseID).then(function () {
            setIsDialogOpen(false);
            navigate(routes.TOP.compile());
        });
    };
    var onClickBack = function () {
        setStatus(statuses.selectCourse);
        tracker.trackClickEvent({
            category: 'Button Clicks',
            label: "Back Change Course, Current: ".concat(statuses.selectCourse),
        });
    };
    var onClickClose = function () {
        setIsDialogOpen(false);
        tracker.trackClickEvent({ category: 'Close Modal', label: 'courseChange' });
    };
    return (React.createElement("div", { className: css(styles.container) }, isLoading ? (React.createElement(PageLoading, null)) : (React.createElement(React.Fragment, null, status === statuses.selectCourse ? (React.createElement("div", { className: css(styles.courses) },
        courses.map(function (course, i) { return (React.createElement("div", { className: css(styles.courseBox), key: i },
            React.createElement(CourseSelectItem, { classObject: styles.courseItem, course: course, config: config, disabled: course.selected, isActive: course.selected, isForChangeCourse: true, onClickCourse: onClickCourse, onClickSelect: handleSelectCourse }))); }),
        isDialogOpen && (React.createElement(Dialog, { classObject: styles.dialog, isBottom: isSM, borderRadius: 8, content: React.createElement(ChangeCourseWarn, { classObject: styles.dialogContent, onClickConfirm: onClickConfirmChangeCourse, onClickBack: onClickClose }) })))) : (React.createElement("div", { className: css(styles.courseDetailContainer) },
        React.createElement(CourseDetail, { course: selectedCourse, apiClient: apiClient, config: config, classObject: styles.courseDetail }),
        React.createElement(ChangeCourseButtons, { classObject: styles.buttonsContainer, nextButtonText: selectedCourse.selected ? '設定中' : 'このコースに変更', nextButtonClickable: !selectedCourse.selected, onClickNext: function () { return onClickChangeCourse(); }, onClickBack: function () { return onClickBack(); } }),
        isDialogOpen && (React.createElement(Dialog, { classObject: styles.dialog, isBottom: isSM, borderRadius: 8, content: React.createElement(ChangeCourseWarn, { classObject: styles.dialogContent, onClickConfirm: onClickConfirmChangeCourse, onClickBack: onClickClose }) }))))))));
};
var styles = StyleSheet.create({
    container: {
        position: 'relative',
    },
    courseDetailContainer: (_a = {
            maxWidth: '100vw',
            margin: '0 auto',
            minHeight: '100vh'
        },
        _a[MediaBreakPointUp.MD] = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: 480,
        },
        _a[MediaBreakPointUp.XL] = {
            maxWidth: 800,
        },
        _a),
    courses: (_b = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: 335,
            margin: '30px auto',
            gap: 30
        },
        _b[MediaBreakPointUp.XL] = {
            width: 700,
        },
        _b),
    courseBox: (_c = {
            cursor: 'pointer'
        },
        _c[MediaBreakPointUp.XL] = {
            width: 335,
            height: 308,
        },
        _c),
    courseItem: (_d = {
            width: 335
        },
        _d[MediaBreakPointUp.XL] = {
            height: 308,
        },
        _d),
    courseDetail: {
        paddingBottom: 170,
    },
    buttonsContainer: (_e = {
            width: '100%',
            bottom: 55,
            position: 'fixed'
        },
        _e[MediaBreakPointUp.MD] = {
            bottom: 0,
        },
        _e),
    dialog: (_f = {},
        _f[MediaBreakPointUp.MD] = {
            top: '30%',
            width: 375,
            transform: 'translateX(-50%)',
        },
        _f),
    dialogContent: {
        margin: 25,
    },
});
