var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MediaBreakPointUp } from '@lean-body/components/styles';
import { PlaylistComponentSquare, PlaylistComponentLandscapeImg } from '@lean-body/components/molecules';
export var Playlists = function (_a) {
    var classObject = _a.classObject, playlists = _a.playlists, onClickPlaylist = _a.onClickPlaylist, addFavoriteProgram = _a.addFavoriteProgram, unFavoriteProgram = _a.unFavoriteProgram, isSM = _a.isSM;
    if (!playlists) {
        return null;
    }
    return (React.createElement("div", { className: css(styles.container, classObject) }, playlists.map(function (playlist, i) { return (React.createElement("div", { className: css(styles.playlistCard), key: i }, isSM ? (React.createElement(PlaylistComponentSquare, { playlist: playlist, addFavoriteProgram: addFavoriteProgram, unFavoriteProgram: unFavoriteProgram, isMin: isSM })) : (React.createElement(PlaylistComponentLandscapeImg, { key: playlist.id, playlist: playlist, addFavoriteProgram: addFavoriteProgram, unFavoriteProgram: unFavoriteProgram, isVertical: !isSM, onClick: onClickPlaylist })))); })));
};
var styles = StyleSheet.create({
    container: {},
    playlistCard: (_a = {
            width: 163
        },
        _a[MediaBreakPointUp.MD] = {
            width: '100%',
        },
        _a),
});
