import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { SwitchButtonComponent } from '@lean-body/src/view/atoms/switch_button';
export var LabelSwitchButton = function (props) {
    return (React.createElement("div", { className: css(props.classObject) },
        React.createElement("div", { className: css(styles.switchLabel) }, props.label),
        React.createElement(SwitchButtonComponent, { classObject: styles.switchButton, onClick: props.onClick, isOn: props.isOn })));
};
var styles = StyleSheet.create({
    switchLabel: {
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: '19.2px',
        marginRight: 20,
    },
    switchButton: {
        marginTop: 2,
    },
});
