import { getParser } from 'bowser';
var browser = getParser(window.navigator.userAgent);
export var getUserAgent = function () {
    return window.navigator.userAgent;
};
// iOSアプリのwebviewのユーザーエージェント
export var isiOSApp = function () {
    var userAgent = getUserAgent().toLowerCase();
    return userAgent.indexOf('lbiosapp') != -1;
};
// iOSアプリのwebブラウザの判定
export var isiOSWeb = function () {
    var userAgent = getUserAgent().toLowerCase();
    return userAgent.indexOf('lbiosapp') == -1 && userAgent.match(/iphone/);
};
// Androidアプリのwebviewのユーザーエージェント
export var isAndroidApp = function () {
    var userAgent = getUserAgent().toLowerCase();
    return userAgent.indexOf('lbandroidapp') != -1;
};
// Androidアプリのwebブラウザの判定
export var isAndroidWeb = function () {
    var userAgent = getUserAgent().toLocaleLowerCase();
    return userAgent.indexOf('lbandroidapp') == -1 && userAgent.match(/android/);
};
// iOS/Androidアプリのwebviewの判定
export var isNativeApp = function () {
    return isAndroidApp() || isiOSApp();
};
// iOS/Androidアプリのブラウザの判定
export var isNativeWeb = function () {
    return isAndroidWeb() || isiOSWeb();
};
export var isMobileSafari = function () {
    return browser.satisfies({
        mobile: {
            safari: '>=9',
        },
    });
};
