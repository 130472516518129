var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { StyleSheet } from 'aphrodite';
import { Dialog, BadgeDialog, DIALOG_IMAGE_HEIGHT_XL, DIALOG_WIDTH_XL } from '@lean-body/components/molecules';
import { css } from 'aphrodite';
import { SliderNextArrow, SliderPrevArrow } from '@lean-body/components/atoms';
import Slider from 'react-slick';
import { routes } from '@lean-body/src/route';
import { LocalStorage } from '@lean-body/src/infra';
import { Colors } from '../styles';
import { SharedTiming } from '@lean-body/src/interfaces';
import { useXlMediaQuery } from '@lean-body/src/util';
var settings = {
    slidesToShow: 1,
    centerPadding: '100px',
    autoplaySpeed: 5000,
    speed: 500,
    arrows: true,
    infinite: false,
    initialSlide: 0,
    nextArrow: React.createElement(SliderNextArrow, null),
    prevArrow: React.createElement(SliderPrevArrow, null),
};
var DIALOG_WIDTH = 327;
var DIALOG_IMAGE_HEIGHT = 184;
export var ReceivedBadgeOrUserSeasonRankDialog = function (_a) {
    var badges = _a.badges, userSeasonAggregate = _a.userSeasonAggregate, onClickCancel = _a.onClickCancel, twitterService = _a.twitterService, onClickTwitterShareIfAuthed = _a.onClickTwitterShareIfAuthed, updateDisplayedUserBadges = _a.updateDisplayedUserBadges;
    var isXL = useXlMediaQuery();
    var rank = userSeasonAggregate === null || userSeasonAggregate === void 0 ? void 0 : userSeasonAggregate.userSeasonAggregateRank;
    var localStorage = new LocalStorage();
    // 初期表示は表示済のステータスに更新
    React.useEffect(function () {
        if (rank) {
            localStorage.saveUserSeasonAggregateRank(null);
        }
        var badge = badges && badges[0];
        if (badge) {
            updateDisplayedUserBadges([badge.id]);
        }
    }, []);
    var handleSwipe = function (currentSlide) {
        var badge = badges && badges[rank ? currentSlide - 1 : currentSlide];
        if (badge) {
            updateDisplayedUserBadges([badge.id]);
        }
    };
    var onClickTwitterShare = function () {
        onClickCancel();
        onClickTwitterShareIfAuthed();
    };
    var description = function (badge) {
        if (badge.isChallenge) {
            return (React.createElement(React.Fragment, null,
                "\u7D20\u6674\u3089\u3057\u3044\u3067\u3059\uFF01",
                React.createElement("br", null),
                "\u300C",
                React.createElement("span", { className: css(styles.descriptionBold) }, badge.name),
                "\u300D\u3092\u3059\u3079\u3066\u30AF\u30EA\u30A2\u3057\u307E\u3057\u305F"));
        }
        var isMinuteBadge = badge.badgeConditions[0].isMinuteBadge;
        var badgeCondition = badge.badgeConditions[0].condition;
        return (React.createElement(React.Fragment, null,
            "\u300C",
            React.createElement("span", { className: css(styles.descriptionBold) },
                "\u30EC\u30C3\u30B9\u30F3",
                isMinuteBadge ? "\u6642\u9593".concat(badgeCondition, "\u5206") : "".concat(badgeCondition, "\u56DE")),
            "\u300D\u3092\u9054\u6210\u3057\u307E\u3057\u305F\uFF01",
            React.createElement("br", null),
            "\u3053\u308C\u307E\u3067\u7372\u5F97\u3057\u305F\u30D0\u30C3\u30B8\u306F\u3001\u30A2\u30AF\u30C6\u30A3\u30D3\u30C6\u30A3\u30BF\u30D6\u304B\u3089\u78BA\u8A8D\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002"));
    };
    var title = function (badge) {
        return badge.isChallenge ? 'チャレンジ修了！' : '新しいバッジを\n獲得しました！';
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { onClickCancel: onClickCancel, isCloseButton: true, classObject: styles.dialogContent, content: React.createElement(Slider, __assign({ afterChange: handleSwipe, className: 'badgeSlider' }, settings),
                rank && (React.createElement(BadgeDialog, { imgWidth: isXL ? DIALOG_WIDTH_XL : DIALOG_WIDTH, imgHeight: isXL ? DIALOG_IMAGE_HEIGHT_XL : DIALOG_IMAGE_HEIGHT, title: React.createElement("span", { className: css(styles.rankTitle) }, "\u30E9\u30F3\u30AF\u30A2\u30C3\u30D7"), description: React.createElement("div", { className: css(badges && badges.length && styles.descForRank) },
                        "\u304A\u3081\u3067\u3068\u3046\u3054\u3056\u3044\u307E\u3059\uFF01\u30E9\u30F3\u30AF\u304C",
                        rank.nameJp,
                        "\u306B\u306A\u308A\u307E\u3057\u305F\u266A"), linkTo: routes.ACTIVITY.compile(), linkText: "\u30A2\u30AF\u30C6\u30A3\u30D3\u30C6\u30A3\u3092\u898B\u308B", rankOrBadge: rank, twitterService: twitterService, onClickTwitterShareIfAuthed: onClickTwitterShare, sharedTiming: SharedTiming.getreward })), badges === null || badges === void 0 ? void 0 :
                badges.map(function (v, index) { return (React.createElement(BadgeDialog, { key: index, imgWidth: isXL ? DIALOG_WIDTH_XL : DIALOG_WIDTH, imgHeight: isXL ? DIALOG_IMAGE_HEIGHT_XL : DIALOG_IMAGE_HEIGHT, title: title(v), description: description(v), linkTo: "".concat(routes.ACTIVITY.compile(), "?tab=collection"), linkText: "\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3\u3092\u898B\u308B", rankOrBadge: v, twitterService: twitterService, onClickTwitterShareIfAuthed: onClickTwitterShare, sharedTiming: SharedTiming.getreward })); })) })));
};
var styles = StyleSheet.create({
    descForRank: {
        minHeight: 87,
    },
    rankTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ':before': {
            content: '""',
            display: 'inline-block',
            width: 2,
            height: 20,
            marginRight: 18,
            backgroundColor: Colors.Primary,
            transform: 'rotate(-30deg)',
        },
        ':after': {
            content: '""',
            display: 'inline-block',
            width: 2,
            height: 20,
            marginLeft: 18,
            backgroundColor: Colors.Primary,
            transform: 'rotate(30deg)',
        },
    },
    descriptionBold: {
        fontWeight: 'bold',
    },
    dialogContent: {
        top: '50%',
    },
});
