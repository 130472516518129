import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useNavigate } from 'react-router';
import { routes } from '@lean-body/src/route';
import { Colors } from '@lean-body/components/styles';
export var Certificate = function (props) {
    var badge = props.badge, classObject = props.classObject, onClick = props.onClick;
    var navigate = useNavigate();
    var handleClick = function () {
        if (badge.isReceived) {
            onClick(badge);
        }
        else {
            navigate(routes.PLAYLIST.compile({ id: badge.badgeConditions[0].challengeID.toString() }));
        }
    };
    return (React.createElement("div", { className: css(styles.container, classObject), onClick: handleClick },
        React.createElement("img", { "data-test": "certificate", className: css(styles.certificate), src: badge.isReceived ? badge.completeImageURL : badge.incompleteImageURL })));
};
var styles = StyleSheet.create({
    container: {
        width: 150,
        height: 150,
        border: "1px solid ".concat(Colors.BorderLightGray),
        borderRadius: 10,
        cursor: 'pointer',
    },
    certificate: {
        height: '100%',
        borderRadius: 10,
    },
});
