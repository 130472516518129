var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { ThreePointLeaderIcon } from '@lean-body/components/atoms';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
export var ThreePointLeader = function (_a) {
    var classObject = _a.classObject, onClick = _a.onClick;
    return (React.createElement("div", { className: css(styles.container, classObject), onClick: onClick },
        React.createElement(ThreePointLeaderIcon, null)));
};
var styles = StyleSheet.create({
    container: (_a = {
            background: "linear-gradient(180deg, ".concat(Colors.White, " 0%, rgba(255, 255, 255, 0) 100%), ").concat(Colors.White),
            borderRadius: 24,
            width: 34,
            height: 34,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        _a[MediaBreakPointUp.MD] = {
            width: 36,
            height: 36,
        },
        _a),
});
