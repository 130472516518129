var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { LBError } from '@lean-body/src/errors';
import { COACHING_PLAN_PAYMENT_FAILED, COACHING_PLAN_PAYMENT_FAILED_BECAUSE_USER_QUIT } from '@lean-body/src/messages';
import { PageLoading } from '../organisms';
import { CoachingLP } from '../organisms/coaching_lp';
import { Button, ButtonType } from '../atoms';
import { Dialog } from '../molecules';
import { CoachingDialogContent } from '../molecules/coaching_dialog_content';
import { Colors, MediaBreakPointUp } from '../styles';
import { isNativeApp, useQuery, useSmMediaQuery } from '@lean-body/src/util';
import { useNavigate } from 'react-router';
import { routes } from '@lean-body/src/route';
export var coachingDialogs = {
    notSubscribed: 'not_subscribed',
    startSubscription: 'start_subscription',
    startCompleted: 'start_completed',
    subscribing: 'subscribing',
};
var LINE_LINK_PATH = 'https://lin.ee/LSkwGp3';
var DIALOG_QUERY = 'dialog';
export var CoachingPage = function (_a) {
    var _b;
    var accountService = _a.accountService, alertPresenter = _a.alertPresenter, apiClient = _a.apiClient, tracker = _a.tracker, me = _a.me, setMe = _a.setMe;
    var query = useQuery();
    var payment = me.payment;
    var subscribed = (_b = payment.coachingPayment) === null || _b === void 0 ? void 0 : _b.isValid;
    var cardLastFour = payment.cardLastFour;
    var firstStatus = subscribed ? coachingDialogs.subscribing : coachingDialogs.notSubscribed;
    var _c = React.useState(), coachingPlan = _c[0], setCoachingPlan = _c[1];
    var _d = React.useState(), coachingAmazonPayPlan = _d[0], setCoachingAmazonPayPlan = _d[1];
    var _e = React.useState(query.get(DIALOG_QUERY) || firstStatus), openDialog = _e[0], setOpendDialog = _e[1];
    var _f = React.useState(false), submitDisabled = _f[0], setSubmitDisabled = _f[1];
    var gtagLabelSubscribed = subscribed ? 'subscribed' : 'not_subscribed';
    var _g = React.useState(false), userQuit = _g[0], setUserQuit = _g[1];
    var isSM = useSmMediaQuery();
    var navigate = useNavigate();
    var isCampaignPlan = (coachingPlan === null || coachingPlan === void 0 ? void 0 : coachingPlan.isCampaignPlan) || (coachingAmazonPayPlan === null || coachingAmazonPayPlan === void 0 ? void 0 : coachingAmazonPayPlan.isCampaignPlan);
    React.useEffect(function () {
        tracker.trackClickEvent({ category: 'openCoachingLp', label: gtagLabelSubscribed });
    }, []);
    // 次回更新日をダイアログで表示しているので、ダイアログを開いた時に再度APIリクエストしている
    React.useEffect(function () {
        if (me.payment.isStripePayment()) {
            apiClient.fetchCoachingPlan().then(function (v) { return setCoachingPlan(v); });
        }
        else {
            apiClient.fetchCoachingAmazonPayPlan().then(function (v) { return setCoachingAmazonPayPlan(v); });
        }
    }, [openDialog]);
    var onCloseDialog = function () {
        setOpendDialog(firstStatus);
    };
    var onClickBackNotSubscribed = function () {
        setOpendDialog(coachingDialogs.notSubscribed);
        tracker.trackClickEvent({ category: 'closeSubscriptionPage', label: gtagLabelSubscribed });
    };
    var onClickStartSubscription = function () {
        setSubmitDisabled(true);
        apiClient
            .registerCoachingPayment({
            coachingPlanID: (coachingPlan !== null && coachingPlan !== void 0 ? coachingPlan : coachingAmazonPayPlan).id,
            amazonPayIdempotencyKey: coachingAmazonPayPlan === null || coachingAmazonPayPlan === void 0 ? void 0 : coachingAmazonPayPlan.idempotencyKey,
        })
            .then(function () {
            accountService.fetchMe({ nocache: true }).then(function (fetchedMe) {
                setMe(fetchedMe);
                setOpendDialog(coachingDialogs.startCompleted);
            });
        })
            .catch(function (e) {
            if (e.isForbiddenError && e.isQuit) {
                setUserQuit(true);
            }
            else {
                onCloseDialog();
                alertPresenter.showError(new LBError(), COACHING_PLAN_PAYMENT_FAILED);
            }
        });
    };
    var onClickSubscription = function () {
        if (isNativeApp()) {
            window.location.href = "".concat(routes.COACHING.compile(), "?").concat(DIALOG_QUERY, "=").concat(coachingDialogs.startSubscription);
            return;
        }
        if (firstStatus === coachingDialogs.notSubscribed) {
            setOpendDialog(coachingDialogs.startSubscription);
        }
        else {
            setOpendDialog(coachingDialogs.startCompleted);
        }
        tracker.trackClickEvent({ category: 'openCoachingSubscriptionPage', label: gtagLabelSubscribed });
    };
    var onClickRedirectLine = function () {
        if (isNativeApp()) {
            window.location.href = LINE_LINK_PATH;
        }
        else {
            window.open(LINE_LINK_PATH);
        }
    };
    if (!coachingPlan && !coachingAmazonPayPlan)
        return React.createElement(PageLoading, null);
    if (!payment.coachingPaymentAvailable)
        return (React.createElement(React.Fragment, null,
            React.createElement(Dialog, { classObject: styles.errorContainer, content: React.createElement(React.Fragment, null,
                    "\u30B3\u30FC\u30C1\u30F3\u30B0\u3092\u5229\u7528\u3067\u304D\u306A\u3044\u30D7\u30E9\u30F3\u3067\u3059\u3002",
                    React.createElement(Button, { isRadius: true, buttonType: ButtonType.primary, onClick: function () { return navigate(routes.TOP.compile()); } }, "OK")) })));
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(CoachingLP, { onClickSubscription: onClickSubscription, onClickRedirectLine: onClickRedirectLine, subscribed: subscribed, isCampaignPlan: isCampaignPlan }),
        (openDialog === coachingDialogs.startSubscription || openDialog === coachingDialogs.startCompleted) &&
            (userQuit ? (React.createElement(Dialog, { classObject: styles.errorContainer, content: React.createElement(React.Fragment, null,
                    COACHING_PLAN_PAYMENT_FAILED_BECAUSE_USER_QUIT,
                    React.createElement(Button, { isRadius: true, buttonType: ButtonType.primary, onClick: onCloseDialog }, "OK")) })) : (React.createElement(Dialog, { classObject: styles.dialog, onClickCancel: onCloseDialog, isBottom: isSM, borderRadius: 20, content: React.createElement(CoachingDialogContent, { coachingPlan: coachingPlan, coachingAmazonPayPlan: coachingAmazonPayPlan, openDialog: openDialog, onClickCancel: onCloseDialog, onClickBackNotSubscribed: onClickBackNotSubscribed, onClickStartSubscription: onClickStartSubscription, onClickRedirectLine: onClickRedirectLine, submitDisabled: submitDisabled, cardLastFour: cardLastFour }) })))));
};
var styles = StyleSheet.create({
    container: {},
    dialog: (_a = {},
        _a[MediaBreakPointUp.MD] = {
            width: 375,
        },
        _a),
    errorContainer: {
        borderRadius: 14,
        maxWidth: 327,
        padding: '25px 24px 20px 24px',
        display: 'flex',
        gap: 20,
        flexDirection: 'column',
        color: Colors.GrayDarkest,
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
});
