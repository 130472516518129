import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var TwitterLogoIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 19,
            height: 18,
        },
    });
    var classObject = props.classObject;
    var color = props.color || Colors.White;
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 19 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: color, d: "M0.387698 17.484C2.65818 14.9173 4.91642 12.3643 7.18579 9.79873C4.79609 6.70686 2.40752 3.61721 0 0.502755C0.0752001 0.502755 0.119763 0.502755 0.163769 0.502755C2.06494 0.502755 3.96611 0.503857 5.86728 0.5C5.97367 0.5 6.03328 0.536368 6.09455 0.616269C7.42086 2.35423 8.74995 4.09055 10.0785 5.82741C10.1008 5.85662 10.1247 5.88417 10.1593 5.92715C10.1966 5.88858 10.2311 5.85441 10.2629 5.8186C11.7964 4.08669 13.3305 2.35589 14.8612 0.621228C14.9392 0.532511 15.0166 0.5 15.1336 0.500551C15.987 0.50551 16.8398 0.503306 17.6932 0.503306H17.8804C15.7458 2.91684 13.6357 5.30283 11.5156 7.70038C14.0045 10.9587 16.4928 14.2158 19 17.4978H18.8268C16.9813 17.4978 15.1358 17.4967 13.2898 17.5C13.1817 17.5 13.1138 17.4686 13.0481 17.3832C11.5892 15.4915 10.1275 13.6014 8.66584 11.7114C8.63019 11.6651 8.59342 11.6193 8.54607 11.5598C8.3227 11.8111 8.10935 12.0503 7.89657 12.2905C6.38421 13.9982 4.87241 15.7058 3.35727 17.4113C3.31994 17.4537 3.25254 17.4939 3.19907 17.4939C2.28107 17.4989 1.36307 17.4972 0.444516 17.4967C0.435604 17.4967 0.426691 17.4928 0.387698 17.4846V17.484ZM3.40851 2.1498C3.45419 2.21207 3.48316 2.25284 3.51379 2.29197C5.52247 4.8879 7.53059 7.48382 9.53927 10.0792C10.9787 11.9395 12.418 13.7998 13.8574 15.6606C13.9053 15.7229 13.9471 15.7747 14.0446 15.7736C14.5131 15.767 14.981 15.7708 15.4494 15.7703C15.4779 15.7703 15.5063 15.762 15.548 15.7554C15.2974 15.4276 15.0573 15.1129 14.8166 14.7988C11.612 10.6087 8.40681 6.41922 5.19883 2.2319C5.16207 2.18396 5.07963 2.14594 5.0178 2.14539C4.52649 2.13823 4.03462 2.14154 3.54332 2.14154C3.50767 2.14154 3.47202 2.14539 3.40907 2.14925L3.40851 2.1498Z" }),
        React.createElement("path", { fill: "url(#paint0_linear_1231_760)", d: "M0.387698 17.484C2.65818 14.9173 4.91642 12.3643 7.18579 9.79873C4.79609 6.70686 2.40752 3.61721 0 0.502755C0.0752001 0.502755 0.119763 0.502755 0.163769 0.502755C2.06494 0.502755 3.96611 0.503857 5.86728 0.5C5.97367 0.5 6.03328 0.536368 6.09455 0.616269C7.42086 2.35423 8.74995 4.09055 10.0785 5.82741C10.1008 5.85662 10.1247 5.88417 10.1593 5.92715C10.1966 5.88858 10.2311 5.85441 10.2629 5.8186C11.7964 4.08669 13.3305 2.35589 14.8612 0.621228C14.9392 0.532511 15.0166 0.5 15.1336 0.500551C15.987 0.50551 16.8398 0.503306 17.6932 0.503306H17.8804C15.7458 2.91684 13.6357 5.30283 11.5156 7.70038C14.0045 10.9587 16.4928 14.2158 19 17.4978H18.8268C16.9813 17.4978 15.1358 17.4967 13.2898 17.5C13.1817 17.5 13.1138 17.4686 13.0481 17.3832C11.5892 15.4915 10.1275 13.6014 8.66584 11.7114C8.63019 11.6651 8.59342 11.6193 8.54607 11.5598C8.3227 11.8111 8.10935 12.0503 7.89657 12.2905C6.38421 13.9982 4.87241 15.7058 3.35727 17.4113C3.31994 17.4537 3.25254 17.4939 3.19907 17.4939C2.28107 17.4989 1.36307 17.4972 0.444516 17.4967C0.435604 17.4967 0.426691 17.4928 0.387698 17.4846V17.484ZM3.40851 2.1498C3.45419 2.21207 3.48316 2.25284 3.51379 2.29197C5.52247 4.8879 7.53059 7.48382 9.53927 10.0792C10.9787 11.9395 12.418 13.7998 13.8574 15.6606C13.9053 15.7229 13.9471 15.7747 14.0446 15.7736C14.5131 15.767 14.981 15.7708 15.4494 15.7703C15.4779 15.7703 15.5063 15.762 15.548 15.7554C15.2974 15.4276 15.0573 15.1129 14.8166 14.7988C11.612 10.6087 8.40681 6.41922 5.19883 2.2319C5.16207 2.18396 5.07963 2.14594 5.0178 2.14539C4.52649 2.13823 4.03462 2.14154 3.54332 2.14154C3.50767 2.14154 3.47202 2.14539 3.40907 2.14925L3.40851 2.1498Z" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "paint0_linear_1231_760", x1: "9.5", y1: "0.5", x2: "9.5", y2: "17.5", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "white" }),
                React.createElement("stop", { offset: "1", stopColor: "white", stopOpacity: "0" })))));
};
