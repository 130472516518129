var _a, _b, _c;
import * as React from 'react';
import { useState, useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { LoadingIndicator } from '../atoms';
import { MerchandisesList } from '../organisms/merchandises_list';
var MERCHANDISE_PER_PAGE = 30;
export var MainMerchandisePage = function (_a) {
    var apiClient = _a.apiClient;
    var _b = useState([]), merchandises = _b[0], setMerchandises = _b[1];
    var _c = useState(0), merchandisePage = _c[0], setMerchandisePage = _c[1];
    var _d = useState(false), disableNextMerchandises = _d[0], setDisableNextMerchandises = _d[1];
    useEffect(function () {
        getMerchandises();
    }, []);
    var getMerchandises = function () {
        var nextPage = merchandisePage + 1;
        setMerchandisePage(nextPage);
        apiClient.getMerchandises(nextPage).then(function (v) {
            setMerchandises(merchandises.concat(v));
            if (v.length < MERCHANDISE_PER_PAGE) {
                setDisableNextMerchandises(true);
            }
        });
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.title) }, "\u30D4\u30C3\u30AF\u30A2\u30C3\u30D7\u5546\u54C1"),
        React.createElement("div", { className: css(styles.description) }, "\u66AE\u3089\u3057\u306E\u4E2D\u3067\u306E\u30AA\u30B9\u30B9\u30E1\u5546\u54C1\u3084\u4F1A\u54E1\u69D8\u3060\u3051\u306E\u304A\u30C8\u30AF\u306A\u60C5\u5831\u3092\u30D4\u30C3\u30AF\u30A2\u30C3\u30D7\u2728"),
        merchandises.length ? (React.createElement(MerchandisesList, { items: merchandises, getNextPage: getMerchandises, disableNextButton: disableNextMerchandises })) : (React.createElement(LoadingIndicator, { size: 50 }))));
};
var styles = StyleSheet.create({
    container: (_a = {
            padding: '0 24px 20px',
            width: '100%'
        },
        _a[MediaBreakPointUp.MD] = {
            maxWidth: 600,
            padding: 0,
            margin: '0 auto 40px',
        },
        _a[MediaBreakPointUp.XL] = {
            maxWidth: 1024,
            marginBottom: 40,
        },
        _a),
    title: (_b = {
            textAlign: 'center',
            fontSize: 16,
            fontWeight: 'bold',
            lineHeight: '160%',
            letterSpacing: 1.28,
            height: 54,
            padding: '16px 0 12px'
        },
        _b[MediaBreakPointUp.MD] = {
            height: 62,
            paddingTop: 24,
        },
        _b[MediaBreakPointUp.XL] = {
            height: 90,
            padding: '40px 0 24px',
        },
        _b),
    description: (_c = {
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: '140%',
            letterSpacing: 0.56,
            marginBottom: 16,
            padding: '10px 18px',
            borderRadius: 4,
            border: "1px solid ".concat(Colors.Gray)
        },
        _c[MediaBreakPointUp.MD] = {
            marginTop: 16,
        },
        _c),
});
