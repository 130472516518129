var _a, _b, _c, _d;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
export var MerchandiseComponent = function (_a) {
    var merchandise = _a.merchandise, descriptionClassObject = _a.descriptionClassObject;
    return (React.createElement("a", { className: css(styles.container), href: merchandise.url, target: "_blank" },
        React.createElement("img", { className: css(styles.img), src: merchandise.imageSrc }),
        React.createElement("div", { className: css(styles.title) }, merchandise.title),
        React.createElement("div", { className: css(styles.companyName) }, merchandise.companyName),
        React.createElement("div", { className: css(styles.description, descriptionClassObject) }, merchandise.description)));
};
var styles = StyleSheet.create({
    container: (_a = {
            color: Colors.Black,
            width: 'calc(50% - 5px)'
        },
        _a[MediaBreakPointUp.MD] = {
            width: 'calc(33.3% - 7px)',
        },
        _a[MediaBreakPointUp.XL] = {
            width: 'calc(16.6% - 8px)',
        },
        _a),
    img: {
        display: 'block',
        border: "1px solid ".concat(Colors.BorderLightGray),
        borderRadius: 4,
        marginBottom: 8,
        width: '100%',
    },
    title: (_b = {
            fontSize: 14,
            fontWeight: 'bold'
        },
        _b[MediaBreakPointUp.MD] = {
            fontSize: 15,
        },
        _b),
    companyName: (_c = {
            fontSize: 10,
            fontWeight: 500,
            margin: '2px 0'
        },
        _c[MediaBreakPointUp.MD] = {
            fontSize: 11,
        },
        _c),
    description: (_d = {
            fontSize: 12,
            lineHeight: '160%',
            letterSpacing: '0.04em',
            whiteSpace: 'pre-wrap'
        },
        _d[MediaBreakPointUp.MD] = {
            fontSize: 13,
        },
        _d),
});
