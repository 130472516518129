import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors } from '../styles';
export var Logo = function (props) {
    var isWhite = props.isWhite, classObject = props.classObject, logoClassObject = props.logoClassObject, onCliclLogo = props.onCliclLogo, forHeader = props.forHeader;
    var styles = StyleSheet.create({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: forHeader ? 50 : 'unset',
            backgroundColor: forHeader ? Colors.White : 'unset',
        },
        img: {
            width: forHeader ? 141 : '100%',
        },
    });
    var logoSrc = isWhite ? '/assets/images/logo-white.svg' : '/assets/images/logo-black.svg';
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("img", { src: logoSrc, alt: "LEAN BODY", className: css(styles.img, logoClassObject), onClick: onCliclLogo })));
};
