var _a, _b, _c, _d;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { InitialGuideHowToDetail } from '../molecules';
import { InitialGuideQA } from '../molecules/initial_guide_qa';
export var InitialGuidePlaylistDetail = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3068\u306F\uFF1F"),
            React.createElement("div", { className: css(styles.sectionTitleBorder) }),
            React.createElement("div", { className: css(styles.topImgBox) },
                React.createElement("img", { className: css(styles.topImg), src: "/assets/images/initial_guide/playlist/playlist_top.png" })),
            React.createElement("div", { className: css(styles.description) }, "\u30EC\u30C3\u30B9\u30F3\u304C\u30C6\u30FC\u30DE\u306B\u6CBF\u3063\u3066\u7D44\u307F\u5408\u308F\u3055\u308C\u3066\u3044\u307E\u3059\u3002\u6C17\u5206\u3084\u76EE\u7684\u306B\u5408\u3063\u305F\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u81EA\u5206\u306E\u30DA\u30FC\u30B9\u3067\u697D\u3057\u307F\u307E\u3057\u3087\u3046\uFF01")),
        React.createElement("div", { className: css(styles.section, styles.noMarginBottom) },
            React.createElement("div", { className: css(styles.howToSection) },
                React.createElement("div", { className: css(styles.sectionTitle) }, "\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u306E\u4F7F\u3044\u65B9"),
                React.createElement("div", { className: css(styles.sectionTitleBorder) }),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/playlist/find_playlist.png", imgClassObject: styles.findPlaylistImg, stepNumber: 1, title: "\u597D\u304D\u306A\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u63A2\u305D\u3046" },
                    "\u6C17\u306B\u306A\u308B\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u898B\u3064\u3051\u307E\u3057\u3087\u3046\uFF01",
                    React.createElement("br", null),
                    "\u898B\u3064\u3051\u308B\u30DA\u30FC\u30B8\u304B\u3089\u63A2\u3059\u3053\u3068\u304C\u3067\u304D\u307E\u3059"),
                React.createElement("div", { className: css(styles.triangleBox) },
                    React.createElement("div", { className: css(styles.triangle) })),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/playlist/favorite_playlist.png", imgClassObject: styles.favoritePlaylistImg, stepNumber: 2, title: "\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u304A\u6C17\u306B\u5165\u308A\u3057\u3066MY\u30EC\u30C3\u30B9\u30F3\u306B\u4FDD\u5B58\u3057\u3088\u3046" }, "\u3084\u3063\u3066\u307F\u305F\u3044\u3068\u601D\u3063\u305F\u3089\u304A\u6C17\u306B\u5165\u308A\u3057\u3066\u3001\u898B\u8FD4\u3057\u3084\u3059\u304F\u3057\u307E\u3057\u3087\u3046"),
                React.createElement("div", { className: css(styles.triangleBox) },
                    React.createElement("div", { className: css(styles.triangle) })),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/playlist/take_playlist_lesson.png", imgClassObject: styles.takePlaylistLessonImg, stepNumber: 3, title: "\u81EA\u5206\u306E\u30DA\u30FC\u30B9\u3067\u30EC\u30C3\u30B9\u30F3\u3092\u53D7\u3051\u3088\u3046" }, "\u30EC\u30C3\u30B9\u30F3\u3092\u697D\u3057\u307F\u307E\u3057\u3087\u3046\uFF01"))),
        React.createElement("div", { className: css(styles.playlistPoint) },
            React.createElement("img", { className: css(styles.pointImg), src: "/assets/images/initial_guide/playlist/create_original_playlist.png" }),
            React.createElement("div", { className: css(styles.pointText) },
                React.createElement("div", { className: css(styles.pointTitle) }, "\u30AA\u30EA\u30B8\u30CA\u30EB\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u4F5C\u308D\u3046"),
                React.createElement("div", { className: css(styles.pointDescription) }, "\u3042\u306A\u305F\u5C02\u7528\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u4F5C\u6210\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\uFF01(web\u7248\u306E\u307F\u306E\u6A5F\u80FD\u3068\u306A\u308A\u307E\u3059\u3002\u30A2\u30D7\u30EA\u3067\u306F\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u305B\u3093\u3002)"))),
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u3088\u304F\u3042\u308B\u8CEA\u554F"),
            React.createElement("div", { className: css(styles.sectionTitleBorder) }),
            React.createElement(InitialGuideQA, { question: "\u30AA\u30EA\u30B8\u30CA\u30EB\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u304C\u898B\u5F53\u305F\u308A\u307E\u305B\u3093" }, "\u30AA\u30EA\u30B8\u30CA\u30EB\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u306Fweb\u7248\u306E\u307F\u306E\u6A5F\u80FD\u3068\u306A\u308A\u307E\u3059\u3002\u6050\u308C\u5165\u308A\u307E\u3059\u304C\u3001\u30A2\u30D7\u30EA\u3067\u306F\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u305B\u3093\u3002"))));
};
var styles = StyleSheet.create({
    section: (_a = {
            marginBottom: 40,
            paddingTop: 30,
            paddingLeft: 24,
            paddingRight: 24
        },
        _a[MediaBreakPointUp.MD] = {
            marginBottom: 0,
            paddingLeft: 40,
            paddingRight: 40,
        },
        _a),
    sectionTitle: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: '1.44px',
        height: 25,
    },
    noMarginBottom: {
        marginBottom: 0,
    },
    sectionTitleBorder: {
        border: "3px solid ".concat(Colors.PrimaryDark),
        borderRadius: 3,
        width: 60,
        margin: '12px auto 14px',
    },
    topImgBox: {
        textAlign: 'center',
        marginTop: 35,
        marginBottom: 35,
    },
    topImg: {
        height: 192,
    },
    description: (_b = {
            backgroundColor: Colors.CyanLightest,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '160%',
            letterSpacing: '0.56px',
            padding: 24,
            marginBottom: 40,
            borderRadius: 10
        },
        _b[MediaBreakPointUp.MD] = {
            marginBottom: 10,
        },
        _b),
    howToSection: (_c = {},
        _c[MediaBreakPointUp.MD] = {
            marginLeft: 60,
            marginRight: 60,
        },
        _c),
    triangleBox: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 12,
        paddingBottom: 12,
    },
    triangle: {
        borderStyle: 'solid',
        borderWidth: '20px 15px 0',
        borderColor: "".concat(Colors.BackgroundGrayD, " transparent transparent transparent"),
        width: 31,
    },
    findPlaylistImg: {
        height: 225,
    },
    favoritePlaylistImg: {
        height: 246,
    },
    takePlaylistLessonImg: {
        height: 183,
    },
    playlistPoint: (_d = {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: Colors.PrimaryLighter,
            marginTop: 30,
            padding: '40px 24px',
            gap: 16
        },
        _d[MediaBreakPointUp.MD] = {
            marginTop: 40,
            padding: '40px 60px',
        },
        _d),
    pointImg: {
        height: 245,
    },
    pointText: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        gap: 16,
    },
    pointTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: '1.44px',
    },
    pointDescription: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: '0.56px',
    },
});
