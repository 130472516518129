import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var PlaylistPlayButtonType;
(function (PlaylistPlayButtonType) {
    PlaylistPlayButtonType[PlaylistPlayButtonType["blue"] = 0] = "blue";
    PlaylistPlayButtonType[PlaylistPlayButtonType["white"] = 1] = "white";
})(PlaylistPlayButtonType || (PlaylistPlayButtonType = {}));
var PlaylistPlayButton = function (_a) {
    var onClick = _a.onClick, _b = _a.size, size = _b === void 0 ? 'normal' : _b, className = _a.className, buttonType = _a.buttonType;
    var wrapperClass = [css(styles.wrapper), className].join(' ');
    var playButtonClass;
    switch (size) {
        case 'small':
            playButtonClass = css(styles.small);
            break;
        case 'normal':
            playButtonClass = css(styles.normal);
            break;
        case 'large':
            playButtonClass = css(styles.large);
            break;
        default:
            break;
    }
    var imgFilename = buttonType === PlaylistPlayButtonType.white ? 'playlist_play_button_white' : 'playlist_play_button';
    return (React.createElement("div", { className: wrapperClass, onClick: onClick },
        React.createElement("img", { src: "/assets/images/icons/".concat(imgFilename, ".png"), className: playButtonClass })));
};
var styles = StyleSheet.create({
    wrapper: {},
    small: {
        width: 30,
    },
    normal: {
        width: 40,
    },
    large: {
        width: 54,
    },
});
export default PlaylistPlayButton;
