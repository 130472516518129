import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointDown } from '@lean-body/components/styles';
import { GoogleCalendar } from '@lean-body/components/atoms';
import { MegalosLiveCalendarHeaderImg } from '@lean-body/components/molecules';
import { useSmMediaQuery } from '@lean-body/src/util/media_query_hooks';
export var MegalosLiveCalendar = function (props) {
    var _a, _b;
    var isSM = useSmMediaQuery();
    var styles = StyleSheet.create({
        mainContentBox: {
            paddingTop: '2%',
            paddingBottom: '3%',
        },
        description: {
            color: Colors.Text,
            fontSize: 17,
            fontWeight: 300,
            marginBottom: 16,
            lineHeight: '1.75',
        },
        descriptionBox: {
            width: '90%',
            margin: '0 auto',
        },
        calendarBox: (_a = {},
            _a[MediaBreakPointDown.SM] = {
                width: '95%',
            },
            _a.width = '80%',
            _a.paddingTop = '5%',
            _a.margin = '0 auto',
            _a),
        zoomDescription: {
            color: Colors.Text,
            fontSize: 13,
            fontWeight: 300,
            marginBottom: 16,
            lineHeight: '1.75',
        },
        zoomDescriptionBox: (_b = {},
            _b[MediaBreakPointDown.SM] = {
                width: '90%',
            },
            _b.paddingTop = '2%',
            _b.width = '80%',
            _b.margin = '0 auto',
            _b),
        warn: {
            fontSize: 11,
            color: Colors.Black,
            fontWeight: 700,
            marginBottom: 16,
        },
        warnBox: {
            textAlign: 'center',
            marginTop: '2%',
        },
    });
    var rootClass = [css(styles.mainContentBox), props.className || ''].join(' ');
    var googleCalendarHeight = function () {
        if (isSM) {
            return 350;
        }
        return 600;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MegalosLiveCalendarHeaderImg, null),
        React.createElement("div", { className: css(styles.warnBox) },
            React.createElement("div", { className: css(styles.warn) }, "\u203B\u3053\u306E\u30DA\u30FC\u30B8\u306F\u30E1\u30AC\u30ED\u30B9LEAN BODY\u4F1A\u54E1\u9650\u5B9A\u30B5\u30FC\u30D3\u30B9\u3068\u306A\u308A\u307E\u3059\u3002")),
        React.createElement("div", { className: rootClass },
            React.createElement("div", { className: css(styles.descriptionBox) },
                React.createElement("div", { className: css(styles.description) }, "\u5168\u56FD\u306B45\u3082\u306E\u5E97\u8217\u3092\u6301\u3064\u30B9\u30DD\u30FC\u30C4\u30AF\u30E9\u30D6 \u30E1\u30AC\u30ED\u30B9\u306ELIVE\u30EC\u30C3\u30B9\u30F3\u304C\u53D7\u3051\u653E\u984C\uFF01\u307E\u308B\u3067\u305D\u306E\u5834\u306B\u30A4\u30F3\u30B9\u30C8\u30E9\u30AF\u30BF\u30FC\u304C\u3044\u308B\u3088\u3046\u306A\u81E8\u5834\u611F\u3067\u69D8\u3005\u306A\u30B8\u30E3\u30F3\u30EB\u306E\u30EC\u30C3\u30B9\u30F3\u3092\u304A\u697D\u3057\u307F\u3044\u305F\u3060\u3051\u307E\u3059\u3002")),
            React.createElement(GoogleCalendar, { src: "https://calendar.google.com/calendar/embed?height=800&wkst=1&bgcolor=%23ffffff&ctz=Asia%2FTokyo&src=b25saW5lLm1lZ2Fsb3NAZ21haWwuY29t&color=%23039BE5&showPrint=0&showTz=0&showCalendars=0", width: "100%", height: googleCalendarHeight(), classObject: styles.calendarBox }),
            React.createElement("div", { className: css(styles.zoomDescriptionBox) },
                React.createElement("div", { className: css(styles.zoomDescription) }, "LIVE\u30EC\u30C3\u30B9\u30F3\u306E\u53C2\u52A0\u306B\u306Fzoom\u30A2\u30D7\u30EA\u3078\u306E\u767B\u9332\uFF08\u7121\u6599\uFF09\u304C\u5FC5\u8981\u306B\u306A\u308A\u307E\u3059")))));
};
