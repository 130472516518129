import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { ArrowDirection, ArrowIcon } from '../atoms';
import { getFormatedDate, getFormattedDateWithYear, getFormattedWithPastYear } from '@lean-body/src/util';
import dayjs from 'dayjs';
import { Colors } from '../styles';
export var DatePaginationFirstPage = 1;
export var DatePaginationStep = {
    day: 1,
    week: 7,
    month: 30,
    quarter: 90,
};
var UNIT_DAY = 'day';
export var DatePagination = function (_a) {
    var value = _a.value, max = _a.max, step = _a.step, onClickNext = _a.onClickNext, onClickPrev = _a.onClickPrev, classObject = _a.classObject;
    var rangeMax = dayjs(max).startOf(UNIT_DAY);
    // rangeStart ~ rangeEnd (e.g. 2024/2/1 ~ 2024/2/7)
    var rangeEnd = dayjs(value).startOf(UNIT_DAY).isAfter(rangeMax) ? rangeMax : dayjs(value).startOf(UNIT_DAY);
    var rangeStart = rangeEnd.subtract(step - 1, UNIT_DAY); // rangeEndを含めるため-1
    var canClickNext = rangeEnd.isBefore(rangeMax);
    var onClickNextArrow = function () {
        if (canClickNext) {
            onClickNext(rangeEnd.add(step, UNIT_DAY).toDate());
        }
    };
    var onClickPrevArrow = function () {
        onClickPrev(rangeEnd.subtract(step, UNIT_DAY).toDate());
    };
    var isCurrentYear = function (date) {
        return date.year() === new Date().getFullYear();
    };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(ArrowIcon, { onClick: onClickPrevArrow, direction: ArrowDirection.left, color: Colors.GrayDarkest }),
        React.createElement("div", { className: css(styles.day) }, step === DatePaginationStep.day ? (React.createElement(React.Fragment, null, rangeEnd.isToday() ? '今日' : getFormattedWithPastYear(rangeEnd.toDate()))) : step === DatePaginationStep.quarter ? (React.createElement(React.Fragment, null,
            getFormattedDateWithYear(rangeStart.toDate()),
            "~",
            getFormattedDateWithYear(rangeEnd.toDate()))) : (React.createElement(React.Fragment, null,
            isCurrentYear(rangeStart)
                ? getFormatedDate(rangeStart.toDate())
                : getFormattedDateWithYear(rangeStart.toDate()),
            "~",
            isCurrentYear(rangeEnd) ? getFormatedDate(rangeEnd.toDate()) : getFormattedDateWithYear(rangeEnd.toDate())))),
        canClickNext ? (React.createElement(ArrowIcon, { onClick: onClickNextArrow, direction: ArrowDirection.right, color: Colors.GrayDarkest })) : (React.createElement(ArrowIcon, { direction: ArrowDirection.right, color: Colors.GrayDarkest, classObject: styles.daysSelectorOpacity }))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 16,
    },
    day: {
        display: 'flex',
        alignItems: 'center',
        height: 27,
        fontWeight: 'bold',
        fontSize: 12,
        color: Colors.GrayDarkest,
        textAlign: 'center',
        padding: '5px 16px',
        borderRadius: 100,
        backgroundColor: Colors.BackgroundLightGray,
        userSelect: 'none',
    },
    daysSelectorOpacity: {
        opacity: 0.3,
    },
});
