var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
var LINE_HEIGHT = 28;
var FONT_SIZE = '15px';
var FONT_FAMILY = "'Gotham SSm A', 'Gotham SSm B', '-apple-system', 'BlinkMacSystemFont', 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'Original Yu Gothic', 'Yu Gothic', 'YuGothic', 'Meiryo', sans-serif";
var COLOR = Colors.White;
var CANVAS_WIDTH = 150; // canvasの大きさは固定して、propsで受け取った大きさに拡大/縮小してる
var CANVAS_HEIGHT = 150;
var TEXT_PISITON_X = 14;
var TEXT_POSITION_Y = 50;
var SCALE = 2; // 解像度を上げるため150pxの2倍で作成して、propsの大きさに縮小してる
var TEXT_WIDTH_LIMIT = 8;
export var CanvasConbineText = React.forwardRef(function (_a, ref) {
    var text = _a.text, imgSrc = _a.imgSrc, width = _a.width, height = _a.height, classObject = _a.classObject, onLoad = _a.onLoad;
    var canvasRef = ref;
    // textにある改行コードは残したまま、横幅最大8文字で改行コードを挿入
    var drawText = function (ctx) {
        var textWithBreak = text
            .split('\n')
            .filter(function (v) { return v !== ''; }) // 改行の連続は対応してないので握り潰す
            .reduce(function (result, v) {
            var tmp = '';
            var splitText = v.match(/.{1}/g); // 1文字ずつに分解
            var times = __spreadArray([], Array(Math.floor(splitText.length / TEXT_WIDTH_LIMIT)), true);
            times.forEach(function () {
                tmp += splitText.splice(0, TEXT_WIDTH_LIMIT).join('') + '\n';
            });
            tmp += splitText.join('');
            result.push(tmp);
            return result;
        }, [])
            .join('\n');
        // textにある改行コードは残しているので、改行コードが二重になる可能性があるため、消す
        textWithBreak = textWithBreak.replace('\n\n', '\n');
        textWithBreak.split('\n').forEach(function (v, i) {
            ctx.fillText(v, TEXT_PISITON_X, TEXT_POSITION_Y + i * LINE_HEIGHT);
        });
    };
    React.useEffect(function () {
        var canvas = canvasRef.current;
        canvas.width = CANVAS_WIDTH * SCALE;
        canvas.height = CANVAS_HEIGHT * SCALE;
        canvas.style.width = "".concat(width, "px");
        canvas.style.height = "".concat(height, "px");
        var ctx = canvas.getContext('2d');
        ctx.font = "bold ".concat(FONT_SIZE, " ").concat(FONT_FAMILY);
        ctx.fillStyle = COLOR;
        ctx.scale(SCALE, SCALE);
        var image = new Image();
        image.onload = function () {
            ctx.drawImage(image, 0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
            drawText(ctx);
            if (onLoad)
                onLoad();
        };
        image.src = imgSrc; // srcのプロパティを設定する前にonloadを設定しないとonloadが実行されない可能性があるので注意
    }, [text]);
    return React.createElement("canvas", { ref: ref, className: css(styles.canvas, classObject) });
});
var styles = StyleSheet.create({
    canvas: {
        position: 'relative',
        top: -1, // borderをつけると微妙に空白が生まれるので位置調整してます
        bottom: -1,
        right: -1,
        left: -1,
    },
});
