import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import SearchTitle from './search_title';
import { Colors } from '../../styles';
import { ClockIcon, ClockMinute } from '../../atoms/icons/clock_icon';
export var SearchDuration = function (_a) {
    var onClickDuration = _a.onClickDuration, durations = _a.durations;
    var clockList = [ClockMinute.five, ClockMinute.ten, ClockMinute.twenty, ClockMinute.fourty];
    return (React.createElement(React.Fragment, null,
        React.createElement(SearchTitle, null, "\u30EC\u30C3\u30B9\u30F3\u6642\u9593"),
        React.createElement("div", { className: css(styles.items) }, durations.map(function (_a, index) {
            var name = _a.name, value = _a.value;
            return (React.createElement("div", { className: css(styles.item), onClick: function () { return onClickDuration(value); }, key: index },
                React.createElement(ClockIcon, { classObject: styles.icon, color: Colors.PrimaryDark, minute: clockList[index] }),
                React.createElement("div", { className: css(styles.itemText) }, name)));
        }))));
};
var styles = StyleSheet.create({
    items: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: 8,
    },
    item: {
        cursor: 'pointer',
        width: 'calc(50% - 4px)',
        backgroundColor: Colors.White,
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 0',
    },
    itemText: {
        fontSize: 14,
        lineHeight: '140%',
        textAlign: 'center',
        paddingTop: 2,
    },
    icon: {
        width: 25,
        height: 28,
    },
});
