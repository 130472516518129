import * as React from 'react';
import Lottie from 'react-lottie';
import { css, StyleSheet } from 'aphrodite';
import { Colors, icon, MediaBreakPointUp } from '@lean-body/components/styles/';
import * as animationData from '../../lotties/favorite_heart.json';
export var ButtonFavorite = function (props) {
    var _a;
    var options = {
        loop: false,
        animationData: animationData,
    };
    var styles = StyleSheet.create({
        buttonCommon: {
            cursor: 'pointer',
            backgroundColor: props.isStopped ? Colors.White : Colors.Primary,
            borderRadius: '50%',
            border: 'none',
            color: Colors.Primary,
            padding: 0, // user agentのデフォルトでpaddingがあるので消す
            boxShadow: "0px 15px 30px rgba(0, 180, 199, 0.08)",
        },
        button: (_a = {
                position: 'relative',
                width: 32,
                height: 32,
                border: "1px solid ".concat(Colors.BorderLightGrayB)
            },
            _a[MediaBreakPointUp.MD] = {
                width: 44,
                height: 44,
            },
            _a),
        innerCommon: {
            // Lottieを中央に寄せるcss
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            // anticonを中央に寄せるcss
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    });
    var innerClass = props.innerClassName
        ? [css(styles.innerCommon), props.innerClassName].join(' ')
        : css(styles.innerCommon);
    var buttonClass = props.buttonClassName
        ? [css(styles.buttonCommon), props.buttonClassName].join(' ')
        : css(styles.buttonCommon, styles.button);
    return (React.createElement("button", { className: buttonClass, onClick: props.onClick },
        React.createElement("div", { className: innerClass, "data-test": "favButton" },
            React.createElement(Icon, { isStopped: props.isStopped, options: options, iconClass: props.iconClassName }))));
};
var Icon = function (_a) {
    var _b;
    var isStopped = _a.isStopped, options = _a.options, iconClass = _a.iconClass;
    var styles = StyleSheet.create({
        icon: (_b = {
                fontSize: 16
            },
            _b[MediaBreakPointUp.MD] = {
                fontSize: 20,
            },
            _b),
    });
    var favIcon = iconClass ? iconClass : css(styles.icon);
    var iconClassName = [css(icon.anticon), css(icon.anticonHeart), favIcon].join(' ');
    if (isStopped) {
        return React.createElement("i", { className: iconClassName });
    }
    return React.createElement(Lottie, { options: options, isStopped: isStopped });
};
