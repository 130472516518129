var StepActivity = /** @class */ (function () {
    function StepActivity(data) {
        Object.defineProperty(this, "dateAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "steps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "calories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.dateAt = data.dateAt;
        this.steps = data.steps;
        this.calories = data.calories;
    }
    return StepActivity;
}());
export { StepActivity };
