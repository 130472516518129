import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { MealMenuListItem } from '../molecules/meal_menu_list_item';
import { Colors } from '../styles';
export var MealMenuList = function (_a) {
    var mealMenus = _a.mealMenus, selectedMenuMap = _a.selectedMenuMap, onClickMenu = _a.onClickMenu, onClickSelectMenu = _a.onClickSelectMenu, onClickDeselectMenu = _a.onClickDeselectMenu, classObject = _a.classObject, excludeMenuKey = _a.excludeMenuKey;
    return (React.createElement("div", { className: css(styles.container, classObject) }, mealMenus.map(function (menu) { return (React.createElement("div", { key: menu.menuKey }, menu.menuKey !== excludeMenuKey && (React.createElement(MealMenuListItem, { menu: menu, isSelected: selectedMenuMap.has(menu.menuKey), onClick: onClickMenu, onClickSelect: onClickSelectMenu, onClickDeselect: onClickDeselectMenu, classObject: [styles.borderBottom, styles.menuItem] })))); })));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    menuItem: {
        cursor: 'pointer',
    },
    borderBottom: {
        borderBottom: "1px solid ".concat(Colors.BorderLightGray),
    },
});
