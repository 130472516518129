import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CheckIcon, HeartIcon } from '@lean-body/components/atoms';
import { Colors } from '@lean-body/components/styles';
export var LiveLessonAttendanceButton = function (_a) {
    var isAttendance = _a.isAttendance, onClick = _a.onClick, classObject = _a.classObject;
    var styles = StyleSheet.create({
        container: {
            display: 'flex',
            alignItems: 'center',
            height: 26,
            padding: '1px 12px 0px 12px',
            gap: 6,
            flexShrink: 0,
            borderRadius: 13,
            background: isAttendance
                ? "linear-gradient(180deg, ".concat(Colors.White, " 0%, rgba(255, 255, 255, 0.00) 100%), ").concat(Colors.White)
                : Colors.PrimaryDark,
            color: isAttendance ? Colors.Black : Colors.White,
            border: isAttendance ? "1px solid ".concat(Colors.Gray) : 'none',
            boxShadow: '0px 0px 6px 0px rgba(59, 67, 75, 0.35)',
            fontSize: 12,
            fontWeight: 700,
            letterSpacing: 0.48,
        },
        heartIcon: {
            width: 16,
            height: 16,
        },
        checkIcon: {
            width: 16,
            height: 17,
        },
    });
    return (React.createElement("div", { className: css(styles.container, classObject), onClick: onClick }, isAttendance ? (React.createElement(React.Fragment, null,
        React.createElement(CheckIcon, { classObject: styles.checkIcon, color: Colors.Black }),
        React.createElement("p", null, "\u53C2\u52A0\u4E88\u5B9A"))) : (React.createElement(React.Fragment, null,
        React.createElement(HeartIcon, { classObject: styles.heartIcon, color: Colors.White }),
        React.createElement("p", null, "\u53C2\u52A0\u3057\u305F\u3044")))));
};
