import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
export var FormIcon = function (_a) {
    var iconSrc = _a.iconSrc, classObject = _a.classObject;
    return React.createElement("img", { src: iconSrc, className: css(styles.iconImage, classObject) });
};
var styles = StyleSheet.create({
    iconImage: {
        marginRight: 12,
    },
});
