import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var CourseRewardSectionTitle = function (_a) {
    var title = _a.title, color = _a.color, classObject = _a.classObject;
    var styles = StyleSheet.create({
        container: {},
        title: {
            fontWeight: 700,
            fontSize: 16,
            letterSpacing: '0.08em',
            color: color,
        },
    });
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("p", { className: css(styles.title) }, title)));
};
