var _a, _b, _c;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Button, ButtonType } from '@lean-body/components/atoms';
import { Link } from 'react-router-dom';
import { routes } from '@lean-body/src/route';
import { DeleteDialog } from '@lean-body/components/atoms/delete_dialog';
import { MediaBreakPointUp } from '@lean-body/components/styles';
export var OriginalPlaylistActions = function (_a) {
    var id = _a.id, deleteOriginalPlaylist = _a.deleteOriginalPlaylist;
    var _b = React.useState(false), showDeleteDialog = _b[0], setShowDeleteDialog = _b[1];
    var deletePlaylist = function () {
        deleteOriginalPlaylist();
        setShowDeleteDialog(false);
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(Link, { to: routes.ORIGINAL_PLAYLIST_UPDATE.compile({ id: id.toString() }), className: css(styles.link) },
            React.createElement(Button, { buttonType: ButtonType.primary, className: css(styles.button), isRadius: true }, "\u7DE8\u96C6\u3059\u308B")),
        React.createElement(Button, { buttonType: ButtonType.secondary, className: css(styles.button, styles.delete), isRadius: true, onClick: function () { return setShowDeleteDialog(true); } }, "\u524A\u9664\u3059\u308B"),
        showDeleteDialog && (React.createElement(DeleteDialog, { title: "\u3053\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u524A\u9664\u3057\u307E\u3059\u304B\uFF1F", description: "\u524A\u9664\u3092\u884C\u3046\u3068\u3001\u518D\u5EA6\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u5FA9\u5143\u3059\u308B\u4E8B\u306F\u3067\u304D\u307E\u305B\u3093\u3002", close: function () { return setShowDeleteDialog(false); }, submit: deletePlaylist }))));
};
var styles = StyleSheet.create({
    container: (_a = {
            margin: '32px 20px 0'
        },
        _a[MediaBreakPointUp.MD] = {
            margin: '32px 90px 0',
            display: 'flex',
        },
        _a[MediaBreakPointUp.XL] = {
            margin: '32px 40px 0',
            maxWidth: 944,
        },
        _a),
    link: (_b = {
            display: 'block',
            marginBottom: 24,
            maxWidth: 375
        },
        _b[MediaBreakPointUp.MD] = {
            width: '50%',
            margin: '0 10px',
        },
        _b[MediaBreakPointUp.XL] = {
            margin: '0 auto',
        },
        _b),
    button: {
        height: 56,
        fontSize: 16,
        width: '100%',
        maxWidth: 375,
    },
    delete: (_c = {},
        _c[MediaBreakPointUp.MD] = {
            width: '50%',
            margin: '0 10px',
        },
        _c[MediaBreakPointUp.XL] = {
            margin: '0 auto',
        },
        _c),
});
