var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { HeartIcon } from '../atoms/icons/heart_icon';
export var EmptySection = function (_a) {
    var text = _a.text;
    return (React.createElement("div", { className: css(styles.contianer) },
        React.createElement("span", { className: css(styles.notice) }, text),
        React.createElement(HeartIcon, { classObject: styles.heartIcon })));
};
var styles = StyleSheet.create({
    contianer: (_a = {
            marginTop: 127,
            marginBottom: 150,
            textAlign: 'center'
        },
        _a[MediaBreakPointUp.MD] = {
            marginTop: 160,
        },
        _a),
    notice: {
        color: Colors.GrayDarkest,
        fontSize: '0.9em',
        letterSpacing: 2,
        display: 'block',
        width: 247,
        margin: '0 auto 23px',
    },
    heartIcon: {
        width: 31,
        height: 26,
        margin: 'auto',
    },
});
