import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../../styles';
export var HintIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 16,
            height: 16,
        },
    });
    var fill = color || Colors.PrimaryDark;
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 16 15", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: fill, d: "M7.26829 8.2317C7.26829 8.03628 7.34556 7.84995 7.4819 7.7136C7.61824 7.57726 7.80458 7.5 8 7.5C8.19542 7.5 8.38176 7.57726 8.5181 7.7136C8.65444 7.84995 8.7317 8.03628 8.7317 8.2317V10.4268C8.7317 10.6222 8.65444 10.8086 8.5181 10.9449C8.38176 11.0813 8.19542 11.1585 8 11.1585C7.80458 11.1585 7.61824 11.0813 7.4819 10.9449C7.34556 10.8086 7.26829 10.6222 7.26829 10.4268V8.2317ZM8 4.2096C7.70914 4.2096 7.43191 4.32322 7.22285 4.53228C7.01834 4.73679 6.90017 5.01857 6.90017 5.30943C6.90017 5.6003 7.01379 5.87753 7.22285 6.08658C7.42737 6.2911 7.70914 6.40926 8 6.40926C8.29086 6.40926 8.5681 6.29564 8.77715 6.08658C8.98167 5.88207 9.09983 5.6003 9.09983 5.30943C9.09983 5.01857 8.98621 4.74134 8.77715 4.53228C8.57264 4.32776 8.29086 4.2096 8 4.2096ZM0.6875 7.5C0.6875 5.55939 1.4601 3.70059 2.82807 2.32808C4.20059 0.955562 6.05939 0.1875 8 0.1875C9.94061 0.1875 11.7994 0.960107 13.1719 2.32808C14.5444 3.70059 15.3125 5.55939 15.3125 7.5C15.3125 9.44061 14.5399 11.2994 13.1719 12.6719C11.7994 14.0444 9.94061 14.8125 8 14.8125C6.05939 14.8125 4.20059 14.0399 2.82807 12.6719C1.45556 11.2994 0.6875 9.44061 0.6875 7.5ZM8 1.64636C6.45024 1.64636 4.95957 2.26445 3.86428 3.35973C2.769 4.45502 2.15091 5.9457 2.15091 7.49545C2.15091 9.04521 2.769 10.5359 3.86428 11.6312C4.95957 12.7265 6.45024 13.3445 8 13.3445C9.54976 13.3445 11.0404 12.7265 12.1357 11.6312C13.231 10.5359 13.8491 9.04521 13.8491 7.49545C13.8491 5.9457 13.231 4.45502 12.1357 3.35973C11.0404 2.26445 9.54976 1.64636 8 1.64636Z" })));
};
