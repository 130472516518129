import * as React from 'react';
import * as dayjs from 'dayjs';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
export var HomeCalendarDayType = {
    primary: 'primary',
    secondary: 'secondary',
    green: 'green',
    inActive: 'inActive',
    disabled: 'disabled',
};
export var HomeCalendarDay = function (_a) {
    var classObject = _a.classObject, date = _a.date, dayOfWeek = _a.dayOfWeek, selectedDate = _a.selectedDate, taskCompleted = _a.taskCompleted, onClick = _a.onClick;
    var today = new Date();
    var onClickDay = function () {
        onClick(date);
    };
    var dayType = function () {
        var dayjsDate = dayjs(date);
        if (dayjsDate.isSame(selectedDate, 'day')) {
            return HomeCalendarDayType.primary;
        }
        else if (dayjsDate.isSame(today, 'day')) {
            return HomeCalendarDayType.secondary;
        }
        else if (taskCompleted) {
            return HomeCalendarDayType.green;
        }
        else if (dayjsDate.isBefore(today)) {
            return HomeCalendarDayType.inActive;
        }
        else {
            return HomeCalendarDayType.disabled;
        }
    };
    var dayOfWeekClass = function () {
        switch (dayType()) {
            case HomeCalendarDayType.primary:
                return styles.dayOfWeekPrimary;
            case HomeCalendarDayType.secondary:
                return styles.dayOfWeekSecondary;
            case HomeCalendarDayType.green:
                return styles.dayOfWeekGreen;
            case HomeCalendarDayType.inActive:
                return styles.dayOfWeekInActive;
            case HomeCalendarDayType.disabled:
                return styles.dayOfWeekDisabled;
        }
    };
    var dayClass = function () {
        switch (dayType()) {
            case HomeCalendarDayType.primary:
                return styles.dayPrimary;
            case HomeCalendarDayType.secondary:
                return styles.daySecondary;
            case HomeCalendarDayType.green:
                return styles.dayGreen;
            case HomeCalendarDayType.inActive:
                return styles.dayInActive;
            case HomeCalendarDayType.disabled:
                return styles.dayDisabled;
        }
    };
    return (React.createElement("div", { className: css(styles.container, classObject), onClick: onClickDay },
        React.createElement("div", { className: css(styles.dayOfWeek, dayOfWeekClass()) }, dayOfWeek),
        React.createElement("div", { className: css(styles.day, dayClass()) }, date.getDate())));
};
var styles = StyleSheet.create({
    container: {
        textAlign: 'center',
        width: 28,
        '-webkit-tap-highlight-color': 'transparent',
        'tap-highlight-color': 'transparent',
    },
    dayOfWeek: {
        fontSize: 10,
    },
    dayOfWeekPrimary: {
        fontWeight: 700,
        color: Colors.PrimaryDarkB,
    },
    dayOfWeekSecondary: {
        fontWeight: 500,
        color: Colors.PrimaryDarkB,
    },
    dayOfWeekGreen: {
        fontWeight: 500,
        color: Colors.GrayDarkest,
    },
    dayOfWeekInActive: {
        fontWeight: 500,
        color: Colors.GrayDarkest,
    },
    dayOfWeekDisabled: {
        fontWeight: 500,
        color: Colors.BackgroundGrayD,
    },
    day: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 28,
        height: 28,
        borderRadius: '50%',
        fontWeight: 700,
        fontSize: 16,
    },
    dayPrimary: {
        color: Colors.White,
        background: Colors.PrimaryDark,
    },
    daySecondary: {
        color: Colors.PrimaryDarkB,
        background: Colors.White,
        border: "1px solid ".concat(Colors.PrimaryDark),
    },
    dayGreen: {
        color: Colors.ProgressGreen,
        background: Colors.BackgroundGreen,
    },
    dayInActive: {
        color: Colors.GrayDarkest,
        background: Colors.White,
    },
    dayDisabled: {
        color: Colors.BackgroundGrayD,
        background: Colors.White,
    },
});
