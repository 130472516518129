import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import * as routes from '@lean-body/src/route';
import { Colors, MediaBreakPointUp, MediaBreakPointDown } from '@lean-body/components/styles';
import { Link } from 'react-router-dom';
export var SearchSuggestionInstructor = function (props) {
    var _a, _b, _c;
    var styles = StyleSheet.create({
        container: (_a = {},
            _a[MediaBreakPointUp.SM] = {
                marginBottom: 40,
            },
            _a),
        itemsContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: 20,
            justifyContent: 'flex-start',
            gap: 8,
        },
        empty: {
            color: Colors.GrayDarkest,
            fontSize: '0.9em',
            letterSpacing: 1,
            marginBottom: 10,
        },
        instructor: (_b = {
                cursor: 'pointer',
                width: 'calc(50% - 4px)',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 4,
                border: "1px solid ".concat(Colors.Gray)
            },
            _b[MediaBreakPointUp.MD] = {
                width: 'calc((100% / 3) - (16px / 3))',
            },
            _b),
        instructorThumb: {
            height: 56,
            borderRadius: 4,
        },
        instructorName: (_c = {
                fontWeight: 500,
                fontSize: '0.918em',
                letterSpacing: 1,
                wordBreak: 'break-word',
                color: Colors.Black,
                display: 'block',
                margin: '0 auto',
                textAlign: 'center'
            },
            _c[MediaBreakPointDown.SM] = {
                fontSize: '0.8rem',
            },
            _c),
    });
    var items = props.instructors.map(function (trainer, i) {
        return (React.createElement(Link, { key: i, to: routes.INSTRUCTOR.compile({ id: trainer.id.toString() }), className: css(styles.instructor) },
            React.createElement("img", { src: trainer.thumbnailURL, className: css(styles.instructorThumb) }),
            React.createElement("div", { className: css(styles.instructorName) }, trainer.name)));
    });
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.itemsContainer) }, items)));
};
