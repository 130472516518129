var _a, _b, _c, _d;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CCChangeSubsection, EMailChangeSubsection, PageLoading, PasswordChangeSubsection, PlanSubsection, ProfileDetailSubsection, ProfileChangeSubsection, } from '@lean-body/components/organisms';
import { SettingMenu, SettingItemKeys, SettingItems } from '../organisms/setting_menu';
import { Colors, MediaBreakPointUp, Sizes } from '../styles';
import { useLocation } from 'react-router';
import { useQuery, useXlMediaQuery } from '@lean-body/src/util';
import { settingMenuWidth } from '@lean-body/src/view/styles/utils';
import { NotificationSettingSubsection } from '../organisms/notification_setting_subsection';
export var SettingPage = function (_a) {
    var _b;
    var accountService = _a.accountService, apiClient = _a.apiClient, alertPresenter = _a.alertPresenter, config = _a.config, tracker = _a.tracker, profile = _a.profile, setProfile = _a.setProfile;
    var location = useLocation();
    var query = useQuery();
    var isXL = useXlMediaQuery();
    var _c = React.useState(), me = _c[0], setMe = _c[1];
    var _d = React.useState(SettingItemKeys[query.get('key')]), currentItem = _d[0], setCurrentItem = _d[1];
    var payment = me === null || me === void 0 ? void 0 : me.payment;
    var title = ((_b = SettingItems[currentItem]) === null || _b === void 0 ? void 0 : _b.text) || '設定';
    var necessaryTitle = !(currentItem === SettingItemKeys.mypage || currentItem === SettingItemKeys.editMypage);
    var hideMenu = !isXL && currentItem;
    React.useEffect(function () {
        accountService.fetchMe({ nocache: true }).then(function (v) { return setMe(v); });
    }, []);
    React.useEffect(function () {
        setCurrentItem(query.get('key'));
    }, [location]);
    var onClick = function (key) {
        setCurrentItem(key);
        window.scrollTo(0, 0);
    };
    if (!me || !profile) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(styles.container) },
        !hideMenu && (React.createElement(SettingMenu, { currentItem: currentItem, onClick: onClick, isMegalosUser: payment === null || payment === void 0 ? void 0 : payment.isMegalosUser, me: me, isStripeUser: payment === null || payment === void 0 ? void 0 : payment.isStripePayment(), onClickSignOut: function () {
                accountService.signOut();
            }, profile: profile })),
        React.createElement("div", { className: css(styles.item) },
            currentItem && necessaryTitle && (React.createElement("div", { className: css(styles.titleForItem) },
                title,
                currentItem === SettingItemKeys.plan && me.isDormant && (React.createElement(React.Fragment, null,
                    "\uFF08",
                    React.createElement("span", { className: css(styles.titleForItemAccent) }, "\u4F11\u7720\u4E2D"),
                    "\uFF09")))),
            currentItem === SettingItemKeys.mypage && (React.createElement(ProfileDetailSubsection, { classObject: styles.centerAlignedSection, setItemKey: setCurrentItem, profile: profile })),
            currentItem === SettingItemKeys.editMypage && (React.createElement(ProfileChangeSubsection, { classObject: styles.centerAlignedSection, apiClient: apiClient, alertPresenter: alertPresenter, setItemKey: setCurrentItem, profile: profile, setProfile: setProfile, tracker: tracker })),
            currentItem === SettingItemKeys.notification && React.createElement(NotificationSettingSubsection, { apiClient: apiClient }),
            currentItem === SettingItemKeys.email && (React.createElement(EMailChangeSubsection, { accountService: accountService, apiClient: apiClient, alertPresenter: alertPresenter, me: me, classObject: styles.section })),
            currentItem === SettingItemKeys.password && (React.createElement(PasswordChangeSubsection, { accountService: accountService, apiClient: apiClient, alertPresenter: alertPresenter, me: me, classObject: styles.section })),
            currentItem === SettingItemKeys.payment && (React.createElement(CCChangeSubsection, { accountService: accountService, apiClient: apiClient, alertPresenter: alertPresenter, config: config, me: me, classObject: styles.section })),
            currentItem === SettingItemKeys.plan && (React.createElement(PlanSubsection, { accountService: accountService, apiClient: apiClient, alertPresenter: alertPresenter, tracker: tracker, me: me, classObject: styles.section, setMe: setMe })))));
};
var styles = StyleSheet.create({
    container: (_a = {
            backgroundColor: Colors.White,
            minHeight: "calc(100vh - ".concat(Sizes.MobileHeaderHeight, "px)")
        },
        _a[MediaBreakPointUp.MD] = {
            minHeight: '100vh',
        },
        _a[MediaBreakPointUp.XL] = {
            display: 'flex',
        },
        _a),
    titleForItem: {
        fontSize: 18,
        fontWeight: 'bold',
        padding: '30px 30px 24px',
    },
    titleForItemAccent: {
        color: Colors.Accent,
    },
    section: {
        margin: '0 30px',
    },
    centerAlignedSection: (_b = {
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 40,
            paddingBottom: 70
        },
        _b[MediaBreakPointUp.MD] = {
            paddingBottom: 100,
        },
        _b),
    item: (_c = {
            width: '100%',
            flex: 1,
            backgroundColor: Colors.White
        },
        _c[MediaBreakPointUp.XL] = {
            marginLeft: settingMenuWidth.xl,
        },
        _c),
});
export var SettingStyles = StyleSheet.create({
    button: (_d = {
            display: 'block',
            width: '100%',
            height: 50,
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: '140%',
            letterSpacing: '0.56px'
        },
        _d[MediaBreakPointUp.MD] = {
            maxWidth: 315,
            margin: '0 auto',
        },
        _d),
});
