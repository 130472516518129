import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { FireIcon } from '../atoms/icons/fire_icon';
import { HeartIcon } from '../atoms/icons/heart_icon';
import { Colors } from '../styles';
import { ClockIcon } from './icons/clock_icon';
export var LessonMetaTitleWithInfo = function (_a) {
    var classObject = _a.classObject, linkText = _a.linkText, link = _a.link, title = _a.title, favoriteCount = _a.favoriteCount, difficulty = _a.difficulty, minCal = _a.minCal, maxCal = _a.maxCal, pace = _a.pace, tags = _a.tags, style = _a.style, lessonLength = _a.lessonLength;
    return (React.createElement("div", { className: css(classObject) },
        link && linkText && (React.createElement(Link, { to: link, className: css(styles.link) }, linkText)),
        React.createElement("div", { className: css(styles.title) }, title),
        React.createElement("div", { className: css(styles.itemDatail) },
            React.createElement(HeartIcon, { classObject: styles.favoriteIcon, color: Colors.Black }),
            React.createElement("div", null, favoriteCount),
            difficulty.name && React.createElement("div", null,
                "\u30FB",
                difficulty.name),
            !!minCal && !!maxCal && (React.createElement("div", null,
                "\u30FB",
                React.createElement(FireIcon, { classObject: styles.fireicon, color: Colors.Black }), "".concat(minCal, " - ").concat(maxCal, "kcal"))),
            lessonLength && (React.createElement("div", null,
                "\u30FB",
                React.createElement(ClockIcon, { classObject: styles.clockIcon, color: Colors.Black }), "".concat(lessonLength, "min"))),
            pace && React.createElement("div", null,
                "\u30FB",
                pace.name)),
        React.createElement("div", { className: css(styles.itemTags) }, tags &&
            tags.map(function (v, i) { return (React.createElement("div", { key: i, className: css(styles.itemTag) },
                "#",
                v.name)); })),
        style && React.createElement("div", { className: css(styles.itemStyle) }, style.name)));
};
var styles = StyleSheet.create({
    link: {
        display: 'block',
        color: Colors.Primary,
        fontWeight: 'bold',
        fontSize: 12,
        lineHeight: '160%',
        letterSpacing: '0.04em',
    },
    title: {
        color: Colors.Black,
        fontWeight: 'bold',
        fontSize: 16,
        lineHeight: '160%',
        letterSpacing: '0.08em',
    },
    itemDatail: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexFlow: 'row wrap',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: '0.04em',
    },
    favoriteIcon: {
        width: 11,
        height: 10,
        marginRight: 4,
    },
    fireicon: {
        width: 10,
        height: 14,
        marginRight: 4,
    },
    clockIcon: {
        width: 12,
        height: 14,
        marginRight: 4,
        position: 'relative',
        top: 1.5,
    },
    itemTags: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexFlow: 'row wrap',
        marginBottom: 8,
    },
    itemTag: {
        marginRight: 6,
        fontSize: 12,
        color: Colors.GrayDarkest,
    },
    itemStyle: {
        backgroundColor: Colors.BackgroundBlue,
        display: 'inline-block',
        alignItems: 'center',
        height: 22,
        fontSize: 10,
        marginBottom: 8,
        padding: '4px 10px',
        borderRadius: 13,
    },
});
