var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c, _d;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import * as dayjs from 'dayjs';
import * as msg from '@lean-body/src/messages';
import { useState, useEffect } from 'react';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { ArrowDirection, ArrowIcon, CalendarSolidIcon, InputWithoutOutline, ProgressBar } from '../atoms';
import { Course, } from '@lean-body/src/domain';
import { DateSelectFormWithoutOutline, GoalMatchingAssessmentFormDailyGoals, GoalMatchingAssessmentFormFooter, GoalMatchingAssessmentFormMessage, TimeSelectForm, TimeSelectFormat, } from '../molecules';
import { validateDate } from '@lean-body/src/util';
import { ArrowIconWithMargin } from '../atoms/icons/arrow_icon_with_margin';
import { CourseTimeInfo } from './course_time_info';
import { GOAL_MATCHING_ASSESSMENT_VERSION } from '../pages/main_goal_matching_assessment';
export var GoalMatchingAssessmentForm = function (_a) {
    var apiClient = _a.apiClient, config = _a.config, index = _a.index, questionCount = _a.questionCount, matchingAssessment = _a.matchingAssessment, pastAnswers = _a.pastAnswers, currentAnswers = _a.currentAnswers, activityLevel = _a.activityLevel, gender = _a.gender, birthday = _a.birthday, currentWeight = _a.currentWeight, currentHeight = _a.currentHeight, weightGoal = _a.weightGoal, weightGoalPaceId = _a.weightGoalPaceId, onClickNext = _a.onClickNext, onClickBack = _a.onClickBack, showProgress = _a.showProgress;
    var choices = matchingAssessment.matchingAssessmentChoices;
    var isChoiceQuestion = choices.length !== 0;
    var textField = matchingAssessment.matchingAssessmentTextField;
    var _b = useState(''), textValue = _b[0], setTextValue = _b[1];
    var _c = useState(''), dateValue = _c[0], setDateValue = _c[1];
    var _d = useState(''), messageTitle = _d[0], setMessageTitle = _d[1];
    var _e = useState(''), messageBody = _e[0], setMessageBody = _e[1];
    var _f = useState([]), currentChoices = _f[0], setCurrentChoices = _f[1];
    var _g = useState(), goalDate = _g[0], setGoalDate = _g[1];
    var _h = useState([]), dailyGoals = _h[0], setDailyGoals = _h[1];
    var isEmptyValues = currentChoices.length === 0 && !textValue && !dateValue;
    var answer = pastAnswers.find(function (v) { return v.matchingAssessmentId === matchingAssessment.id; });
    useEffect(function () {
        var _a, _b, _c, _d;
        if (isChoiceQuestion) {
            if (matchingAssessment.isKindWeightGoalPace) {
                // 減量ペース設定
                if (currentWeight > weightGoal) {
                    apiClient.fetchWeightGoalPace(currentWeight, weightGoal, GOAL_MATCHING_ASSESSMENT_VERSION).then(function (values) {
                        choices.forEach(function (choice) {
                            values.forEach(function (v) {
                                if (choice.id === v.choiceId) {
                                    choice.description = v.description;
                                    choice.goalDate = v.goalDate;
                                }
                            });
                        });
                        setDefaultCurrentChoices();
                    });
                }
            }
            else if (matchingAssessment.isKindWeightGoalApproach) {
                // 減量アプローチ設定
                apiClient
                    .fetchWeightGoalApproach(activityLevel, gender, birthday, currentHeight, currentWeight, weightGoal, weightGoalPaceId, GOAL_MATCHING_ASSESSMENT_VERSION)
                    .then(function (values) {
                    choices.forEach(function (choice) {
                        values.forEach(function (v) {
                            if (choice.id === v.choiceId)
                                choice.dailyGoals = v.dailyGoals;
                        });
                    });
                    setDefaultCurrentChoices();
                });
            }
            else {
                setDefaultCurrentChoices();
            }
        }
        else if (textField.isInputTypeDate) {
            // 今回の回答 -> 過去の回答 -> 未回答 の順番で設定する
            if ((_a = currentAnswers[index]) === null || _a === void 0 ? void 0 : _a.textInput) {
                setDateValue((_b = currentAnswers[index]) === null || _b === void 0 ? void 0 : _b.textInput);
            }
            else if (answer === null || answer === void 0 ? void 0 : answer.textInput) {
                setDateValue(answer === null || answer === void 0 ? void 0 : answer.textInput);
            }
            else {
                setDateValue('');
            }
        }
        else if (matchingAssessment.isKindCourseTime) {
            setTextValue(Course.getDefaultTime(false));
        }
        else {
            // 今回の回答 -> 過去の回答 -> 未回答 の順番で設定する
            if ((_c = currentAnswers[index]) === null || _c === void 0 ? void 0 : _c.textInput) {
                setTextValue((_d = currentAnswers[index]) === null || _d === void 0 ? void 0 : _d.textInput);
            }
            else if (answer === null || answer === void 0 ? void 0 : answer.textInput) {
                setTextValue(answer === null || answer === void 0 ? void 0 : answer.textInput);
            }
            else if (textField.defaultValue) {
                setTextValue(textField.defaultValue);
            }
            else {
                setTextValue('');
            }
        }
    }, [index]);
    useEffect(function () {
        var _a, _b;
        if (currentChoices.length > 0 && ((_a = currentChoices[0]) === null || _a === void 0 ? void 0 : _a.goalDate)) {
            setGoalDate(dayjs.unix(Number(currentChoices[0].goalDate)));
        }
        else {
            setGoalDate(null);
        }
        setDailyGoals((_b = currentChoices[0]) === null || _b === void 0 ? void 0 : _b.dailyGoals);
    }, [currentChoices]);
    var setDefaultCurrentChoices = function () {
        var _a;
        var selectedChoiceList = choices.filter(function (v) { var _a; return (_a = currentAnswers[index]) === null || _a === void 0 ? void 0 : _a.choiceIds.includes(v.id); });
        // 今回の回答 -> 過去の回答 -> おすすめ の順番で設定する
        if (selectedChoiceList.length > 0) {
            setCurrentChoices(selectedChoiceList);
        }
        else if ((_a = answer === null || answer === void 0 ? void 0 : answer.choiceIds) === null || _a === void 0 ? void 0 : _a.length) {
            setCurrentChoices(choices.filter(function (v) { return answer.choiceIds.includes(v.id); }));
        }
        else if (matchingAssessment.hasDefaultChoice) {
            var defaultChoices = choices.filter(function (v) { return v.isDefaultSelected; });
            setCurrentChoices(defaultChoices);
        }
        else {
            var recommendedChoices = choices.filter(function (v) { return v.isRecommended; });
            setCurrentChoices(recommendedChoices);
        }
    };
    var onClickChoice = function (choice) {
        var matchingAssessmentChoices = [];
        if (currentChoices.includes(choice)) {
            matchingAssessmentChoices = currentChoices.filter(function (v) { return v.id !== choice.id; });
        }
        else {
            // 複数選択できない質問、もしくはクリックした選択肢が unique の場合は choice.id で上書き
            if (!matchingAssessment.isMultiple || choice.selectedUnique) {
                matchingAssessmentChoices = [choice];
            }
            else {
                // クリックした選択肢を追加しつつ unique の選択肢が既に選択されている場合は外す
                var uniqueChoiceIds_1 = currentChoices.filter(function (v) { return v.selectedUnique; }).map(function (v) { return v.id; });
                matchingAssessmentChoices = __spreadArray(__spreadArray([], currentChoices.filter(function (v) { return !uniqueChoiceIds_1.includes(v.id); }), true), [choice], false);
            }
        }
        setCurrentChoices(matchingAssessmentChoices);
    };
    var onChangeTextValue = function (e) {
        var value = e.target.value.trim();
        if (matchingAssessment.isKindUserName && value.length > 15)
            return;
        setTextValue(value);
    };
    var onClickNextButton = function () { return __awaiter(void 0, void 0, void 0, function () {
        var isValid, choice;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    resetMessage();
                    if (!(matchingAssessment.isKindWeightGoal && currentWeight !== Number(textValue))) return [3 /*break*/, 2];
                    return [4 /*yield*/, checkWeightGoal()];
                case 1:
                    isValid = _a.sent();
                    if (!isValid) {
                        return [2 /*return*/];
                    }
                    _a.label = 2;
                case 2:
                    choice = {
                        matchingAssessmentID: matchingAssessment.id,
                        choiceIds: [],
                        textInput: '',
                    };
                    if (isChoiceQuestion) {
                        choice.choiceIds = currentChoices.map(function (choice) { return choice.id; });
                        setCurrentChoices([]);
                    }
                    else if (textField.isInputTypeDate) {
                        if (matchingAssessment.isKindBirthday && !checkDate(dateValue)) {
                            return [2 /*return*/];
                        }
                        choice.textInput = dateValue;
                        setDateValue('');
                    }
                    else {
                        choice.textInput = textValue;
                        setTextValue('');
                    }
                    currentAnswers[index] = choice;
                    onClickNext();
                    return [2 /*return*/];
            }
        });
    }); };
    var onClickBackButton = function () {
        resetMessage();
        onClickBack();
    };
    var onClickCopyCurrentWeight = function () {
        setTextValue(currentWeight.toString());
    };
    var checkDate = function (v) {
        var format = 'YYYY-MM-DD';
        if (v && !validateDate(v, format)) {
            setMessageBody(msg.DATE_INVALID);
            return false;
        }
        return true;
    };
    var checkWeightGoal = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiClient.checkWeightGoal(currentHeight, currentWeight, textValue).then(function (v) {
                        if (!v.isValid) {
                            setMessageTitle(v.message);
                            setMessageBody(v.description);
                            return false;
                        }
                        return true;
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result];
            }
        });
    }); };
    var resetMessage = function () {
        setMessageTitle('');
        setMessageBody('');
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.content) },
            showProgress && (React.createElement("div", { className: css(styles.header) },
                React.createElement(ArrowIconWithMargin, { onClick: onClickBackButton, direction: ArrowDirection.left, color: Colors.TextDark, classObject: styles.fixedBackButton }),
                React.createElement("div", { className: css(styles.progressBarBox) },
                    React.createElement(ProgressBar, { classObject: styles.progressBar, max: questionCount, current: index, color: Colors.PrimaryDark, trackColor: Colors.BackgroundBlue })))),
            React.createElement("div", { className: css(styles.question) },
                React.createElement("div", { className: css(styles.title) }, matchingAssessment.question),
                matchingAssessment.description && (React.createElement("div", { className: css(styles.description) }, matchingAssessment.description))),
            isChoiceQuestion ? (
            // 選択肢ありのフォーム
            React.createElement("div", { className: css(styles.choiceContainer, matchingAssessment.isKindCourseDaysOfWeek && styles.choiceContainerLinear) },
                choices.map(function (choice) { return (React.createElement("div", { className: css(styles.button, choices.length == 3 && styles.trisectionButton, choices.length >= 4 && styles.bipartitionButton, (choices.length <= 2 || matchingAssessment.isKindWeightGoalApproach) && styles.fullWidthButton, matchingAssessment.isKindWeightGoalPace && styles.buttonWithDescription, matchingAssessment.isKindCourseDaysOfWeek && styles.smallCircleButton, choice.isRecommended && styles.recommendedButton, currentChoices.includes(choice) && styles.selectedButton), onClick: function () { return onClickChoice(choice); }, key: choice.id, "data-test": "matchingAssessmentChoice" },
                    choice.isRecommended && React.createElement("div", { className: css(styles.recommend) }, "\uFF3C\u304A\u3059\u3059\u3081\uFF0F"),
                    choice.iconSrc && React.createElement("img", { className: css(styles.choiceImg), src: choice.iconImageURL }),
                    React.createElement("div", { className: css(styles.choiceText) },
                        React.createElement("div", { className: css(styles.choiceName) }, choice.choices),
                        choice.description && React.createElement("div", { className: css(styles.choiceDescription) }, choice.description)))); }),
                goalDate && (React.createElement("div", { className: css(styles.goalDate) },
                    React.createElement(CalendarSolidIcon, null),
                    React.createElement("div", { className: css(styles.goalDateText) },
                        React.createElement("div", { className: css(styles.dateNumber) }, goalDate.year()),
                        "\u5E74",
                        React.createElement("div", { className: css(styles.dateNumber) }, goalDate.month() + 1),
                        "\u6708",
                        React.createElement("div", { className: css(styles.dateNumber) }, goalDate.date()),
                        "\u65E5\u306B\u9054\u6210"))),
                (dailyGoals === null || dailyGoals === void 0 ? void 0 : dailyGoals.length) > 0 && React.createElement(GoalMatchingAssessmentFormDailyGoals, { config: config, dailyGoals: dailyGoals }))) : (React.createElement(React.Fragment, null, textField && (React.createElement(React.Fragment, null, textField.isInputTypeText ? (
            // テキスト入力フォーム
            React.createElement(InputWithoutOutline, { type: textField.inputType, placeholder: textField.placeholder, unitLabel: textField.unit, onChange: onChangeTextValue, value: textValue })) : textField.isInputTypeDate ? (
            // 日付入力フォーム
            React.createElement("div", { className: css(styles.inputDate) },
                React.createElement(DateSelectFormWithoutOutline, { currentValue: dateValue, setCurrentValue: setDateValue }))) : textField.isInputTypeTime ? (React.createElement("div", { className: css(styles.inputTime) },
                React.createElement(TimeSelectForm, { label: "", currentValue: textValue, setCurrentValue: setTextValue, selectClassObject: styles.timeSelect, listClassObject: styles.timeSelectList, listItemClassObject: styles.timeSelectListItem, displayIcon: false, format: TimeSelectFormat.hmm }),
                React.createElement(CourseTimeInfo, { time: textValue }))) : (textField.isInputTypeNumber && (
            // 数値入力フォーム
            React.createElement(React.Fragment, null, matchingAssessment.isKindWeightGoal ? (React.createElement("div", { className: css(styles.weightGoal) },
                React.createElement("div", { className: css(styles.currentWeight) },
                    React.createElement("div", { className: css(styles.currentWeightNumber) }, currentWeight),
                    React.createElement("div", { className: css(styles.currentWeightUnit) }, "kg")),
                React.createElement(ArrowIcon, { classObject: styles.weightArrowIcon, direction: ArrowDirection.right, color: Colors.BackgroundGrayD }),
                React.createElement(InputWithoutOutline, { classObject: styles.inputWeightGoal, type: textField.inputType, placeholder: textField.placeholder, unitLabel: textField.unit, onChange: onChangeTextValue, value: textValue }))) : (React.createElement(InputWithoutOutline, { type: textField.inputType, placeholder: textField.placeholder, unitLabel: textField.unit, onChange: onChangeTextValue, value: textValue })))))))))),
        (messageTitle || messageBody) && (React.createElement(GoalMatchingAssessmentFormMessage, { isInputTypeDate: textField === null || textField === void 0 ? void 0 : textField.isInputTypeDate, messageTitle: messageTitle, messageBody: messageBody })),
        React.createElement(GoalMatchingAssessmentFormFooter, { isKindWeightGoal: matchingAssessment.isKindWeightGoal, isEmptyValues: isEmptyValues, onClickNext: onClickNextButton, onClickCopyCurrentWeight: onClickCopyCurrentWeight })));
};
var styles = StyleSheet.create({
    container: {
        position: 'relative',
    },
    content: (_a = {
            padding: '0 20px'
        },
        _a[MediaBreakPointUp.MD] = {
            width: 303,
            padding: '166px 0 170px',
            margin: 'auto',
        },
        _a),
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 24,
        marginBottom: 30,
    },
    fixedBackButton: (_b = {
            width: 24,
            height: 24
        },
        _b[MediaBreakPointUp.MD] = {
            position: 'fixed',
            top: 16,
            left: 16,
            width: 32,
            height: 32,
        },
        _b),
    progressBarBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginRight: 14,
    },
    progressBar: {
        width: 173,
    },
    question: (_c = {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            gap: 18,
            marginBottom: 40
        },
        _c[MediaBreakPointUp.MD] = {
            marginBottom: 80,
        },
        _c),
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: 1.6,
    },
    description: {
        color: Colors.GrayDarkest,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
    weightGoal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 13,
    },
    currentWeight: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        lineHeight: '140%',
        height: 28,
    },
    currentWeightNumber: {
        fontSize: 20,
        letterSpacing: 1.6,
    },
    currentWeightUnit: {
        fontSize: 14,
        letterSpacing: 1.12,
    },
    weightArrowIcon: {
        width: 22,
        height: 22,
    },
    inputWeightGoal: {
        marginRight: 35,
    },
    inputDate: {
        display: 'flex',
        justifyContent: 'center',
        gap: 40,
    },
    inputTime: (_d = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 40
        },
        _d[MediaBreakPointUp.MD] = {
            gap: 80,
        },
        _d),
    choiceContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: 8,
    },
    choiceContainerLinear: {
        flexWrap: 'nowrap',
        gap: 'auto',
        maxWidth: 327,
        margin: '0 auto',
    },
    recommend: {
        color: Colors.PrimaryDarkB,
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
    choiceImg: {
        width: 48,
        height: 48,
    },
    choiceText: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
    },
    choiceName: {
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: 0.56,
        minHeight: 20,
        whiteSpace: 'pre-wrap',
    },
    button: {
        padding: 16,
        gap: 10,
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: Colors.White,
        border: "1px ".concat(Colors.Gray, " solid"),
        cursor: 'pointer',
    },
    fullWidthButton: {
        width: '100%',
        minHeight: 68,
    },
    buttonWithDescription: {
        minHeight: 150,
    },
    bipartitionButton: {
        width: 'calc(50% - 4px)',
        minHeight: 110,
    },
    trisectionButton: {
        width: 'calc((100% - 16px) / 3)',
        minHeight: 147,
    },
    smallCircleButton: {
        width: 40,
        minHeight: 40,
        borderRadius: '50%',
        padding: 0,
    },
    recommendedButton: {
        gap: 4,
    },
    selectedButton: {
        backgroundColor: Colors.PrimaryLighter,
        border: "2px ".concat(Colors.PrimaryDark, " solid"),
    },
    choiceDescription: {
        fontSize: 10,
        fontWeight: 400,
        color: Colors.GrayDarkest,
        lineHeight: '140%',
        letterSpacing: 0.5,
        textAlign: 'center',
    },
    goalDate: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: Colors.Black,
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: 0.56,
        width: '100%',
        height: 28,
        gap: 8,
        marginTop: 16,
    },
    goalDateText: {
        display: 'flex',
        alignItems: 'flex-end',
        height: '100%',
    },
    dateNumber: {
        fontSize: 20,
        letterSpacing: 0.8,
        marginBottom: 2,
    },
    timeSelect: {
        border: 0,
        borderRadius: 0,
        borderBottom: "2px solid ".concat(Colors.BorderLightGray),
        height: 45,
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '140%',
        letterSpacing: 2.56,
        textAlign: 'center',
        ':focus': {
            boxShadow: 'none',
        },
    },
    timeSelectList: {
        height: 429,
        minWidth: 81,
    },
    timeSelectListItem: {
        fontSize: 12,
        minHeight: 33,
        height: 33,
        justifyContent: 'center',
    },
});
