import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CircleProgress } from '../atoms/circle_progress';
import { Colors } from '@lean-body/components/styles';
export var CourseVolumeProgress = function (_a) {
    var totalDays = _a.totalDays, finishedDays = _a.finishedDays, isAnimated = _a.isAnimated, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(CircleProgress, { progressMax: totalDays, progressValue: finishedDays, color: Colors.Primary, circleBorder: 7, circleDiameter: 65, isAnimated: isAnimated },
            React.createElement("div", { className: css(styles.progress) },
                React.createElement("span", { className: css(styles.finishedDays) }, finishedDays),
                "/",
                totalDays),
            React.createElement("div", { className: css(styles.text) }, "\u5B8C\u4E86"))));
};
var styles = StyleSheet.create({
    container: {
        textAlign: 'center',
    },
    text: {
        fontWeight: 700,
        fontSize: 10,
        color: Colors.GrayDarkest,
        letterSpacing: '0.03em',
    },
    progress: {
        fontWeight: 700,
        fontSize: 12,
        fontFamily: 'Gotham SSm B',
        color: Colors.Black,
        letterSpacing: '0.03em',
        lineHeight: '18px',
    },
    finishedDays: {
        fontSize: 20,
    },
});
