import { useEffect, useMemo, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '@lean-body/src/route';
// scriptタグの挿入と削除をコンポーネントのマウント/アンマウントで制御する
export var useScript = function (url, callback) {
    useEffect(function () {
        var script = document.createElement('script');
        script.src = url;
        script.onload = callback;
        document.body.appendChild(script);
        return function () {
            document.body.removeChild(script);
        };
    }, [url]);
};
// cssのlinkタグの挿入と削除をコンポーネントのマウント/アンマウントで制御する
export var useCss = function (path) {
    useEffect(function () {
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = path;
        document.head.appendChild(link);
        return function () {
            document.head.removeChild(link);
        };
    }, [path]);
};
// クエリパラメータを取得
// const query = useQuery()
// query.get("name")
export var useQuery = function () {
    var search = useLocation().search;
    return useMemo(function () { return new URLSearchParams(search); }, [search]);
};
// ウィンドウのサイズを取得
// const windowSize = useWindowSize()
// windowSize.width
export var useWindowSize = function () {
    var _a = useState({
        width: undefined,
        height: undefined,
    }), windowSize = _a[0], setWindowSize = _a[1];
    var handleResize = function () {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };
    useEffect(function () {
        window.addEventListener('resize', handleResize);
        handleResize();
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    return windowSize;
};
// ref.currentがnullのまま更新されないのを防ぐhooks
// const { ref, element } = useRefHooks<HTMLDivElement>()
export var useRefHooks = function () {
    var ref = useRef();
    var _a = useState(ref.current), element = _a[0], setElement = _a[1];
    useEffect(function () {
        setElement(ref.current);
    }, [ref.current]);
    return { ref: ref, element: element };
};
// Mountedの判定をする
// isMounted = useIsMounted()
export var useIsMounted = function () {
    var componentIsMounted = useRef(true);
    useEffect(function () {
        return function () {
            componentIsMounted.current = false;
        };
    }, []);
    return componentIsMounted;
};
// ロケーションの変更を検知
export var useLocationChange = function (callback) {
    var callbackRef = useRef();
    var location = useLocation();
    useEffect(function () {
        callbackRef.current = callback;
    }, [callback]);
    useEffect(function () {
        if (callbackRef.current) {
            callbackRef.current(location);
        }
    }, [location]);
};
// 指定した秒数(duration)で指定した値（max）までインクリメントする
export var useIncrement = function (duration, max, setValue) {
    useEffect(function () {
        var interval = 10;
        var totalIncrements = duration / interval;
        var increment = max / totalIncrements;
        setValue(0);
        var id = setInterval(function () {
            setValue(function (prev) {
                if (prev + increment >= max) {
                    clearInterval(id);
                    return max;
                }
                return prev + increment;
            });
        }, interval);
        return function () { return clearInterval(id); };
    }, [max]);
};
// 前のページに戻る。前のルートがリーンボディではない場合、TOPに戻る
export var useNavigateBack = function () {
    var navigate = useNavigate();
    var location = useLocation();
    return function () {
        if (location.key !== 'default') {
            navigate(-1);
        }
        else {
            navigate(routes.TOP.compile());
        }
    };
};
