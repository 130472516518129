import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { ThreePointLeader } from '@lean-body/components/atoms';
import { Colors } from '@lean-body/components/styles';
export var CourseVolumeHeaderDetail = function (_a) {
    var volume = _a.volume, classObject = _a.classObject, onClickSetting = _a.onClickSetting, onClickCourse = _a.onClickCourse;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.name) },
            React.createElement("span", { className: css(styles.volumeNumber) },
                "Vol.",
                volume.volumeNumber),
            React.createElement("br", null),
            " ",
            volume.title),
        React.createElement("div", { className: css(styles.lengthInfo), onClick: onClickSetting },
            React.createElement("div", null,
                volume.totalDays,
                " DAYS"),
            React.createElement("div", null,
                volume.minDuration,
                "-",
                volume.maxDuration,
                "\u5206")),
        React.createElement("div", { className: css(styles.buttons) },
            React.createElement(ThreePointLeader, { onClick: onClickSetting, classObject: styles.threePointLeader }),
            React.createElement("div", { className: css(styles.button, styles.buttonToCourse), onClick: onClickCourse }, "\u30B3\u30FC\u30B9\u3092\u898B\u308B"))));
};
var styles = StyleSheet.create({
    container: {},
    name: {
        color: Colors.TextDark,
        letterSpacing: '0.04em',
        fontWeight: 700,
        fontSize: 16,
        marginBottom: 5,
        lineHeight: '140%',
    },
    volumeNumber: {
        fontSize: 14,
    },
    lengthInfo: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: '0.04em',
        color: Colors.Black,
    },
    buttons: {
        display: 'flex',
        gap: 10,
        marginTop: 5,
    },
    button: {
        background: "linear-gradient(180deg, ".concat(Colors.White, " 0%, rgba(255, 255, 255, 0) 100%), ").concat(Colors.White),
        borderRadius: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonToCourse: {
        fontWeight: 500,
        fontSize: 12,
        lineHeight: '160%',
        letterSpacing: '0.04em',
        color: Colors.Black,
        width: 100,
        height: 36,
        cursor: 'pointer',
    },
    threePointLeader: {
        cursor: 'pointer',
    },
});
