import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var CirclePlusIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 35,
            height: 35,
        },
    });
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 35 35", fill: "none", xmlns: "http://www.w3.org/2000/svg", onClick: props.onClick },
        React.createElement("path", { id: "Vector", d: "M7.18381 17.7873L7.18381 16.4284C7.18381 16.1382 7.42789 15.8941 7.71815 15.8941H26.3209C26.6112 15.8941 26.8552 16.1382 26.8552 16.4284L26.8552 17.7873C26.8552 18.0776 26.6112 18.3217 26.3209 18.3217H7.71815C7.42129 18.3151 7.18381 18.0776 7.18381 17.7873Z", fill: "#DEE1E4" }),
        React.createElement("path", { id: "Vector_2", d: "M17.5803 26.9442H16.4588C16.1026 26.9442 15.8123 26.6539 15.8057 26.2911L15.8057 7.9126C15.8057 7.55638 16.096 7.26613 16.4588 7.25953L17.5803 7.25953C17.9365 7.25953 18.2267 7.5498 18.2333 7.91262L18.2333 26.2911C18.2333 26.6605 17.9431 26.9508 17.5803 26.9442Z", fill: "#DEE1E4" }),
        React.createElement("path", { id: "Ellipse 50 (Stroke)", fillRule: "evenodd", clipRule: "evenodd", d: "M17.0195 31.96C25.2817 31.96 31.9795 25.2622 31.9795 17C31.9795 8.73782 25.2817 2.04 17.0195 2.04C8.75735 2.04 2.05953 8.73782 2.05953 17C2.05953 25.2622 8.75735 31.96 17.0195 31.96ZM17.0195 34C26.4084 34 34.0195 26.3888 34.0195 17C34.0195 7.61116 26.4084 0 17.0195 0C7.63069 0 0.0195312 7.61116 0.0195312 17C0.0195312 26.3888 7.63069 34 17.0195 34Z", fill: "#DEE1E4" })));
};
