import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var WarningTriangleIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 31,
            height: 30,
        },
    });
    var iconColor = color || Colors.Yellow;
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 31 30", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M30.3559 22.9557L19.4051 2.28289C17.6457 -0.758942 13.3567 -0.762984 11.5949 2.28289L0.644636 22.9557C-1.15397 26.064 1.0236 30 4.54882 30H26.4508C29.9731 30 32.1545 26.0672 30.3559 22.9557ZM15.5 26.2693C14.4987 26.2693 13.6836 25.4322 13.6836 24.4039C13.6836 23.3756 14.4987 22.5386 15.5 22.5386C16.5013 22.5386 17.3164 23.3756 17.3164 24.4039C17.3164 25.4322 16.5013 26.2693 15.5 26.2693ZM17.3164 18.8079C17.3164 19.8362 16.5013 20.6732 15.5 20.6732C14.4987 20.6732 13.6836 19.8362 13.6836 18.8079V9.48111C13.6836 8.4528 14.4987 7.61575 15.5 7.61575C16.5013 7.61575 17.3164 8.4528 17.3164 9.48111V18.8079Z", fill: iconColor })));
};
