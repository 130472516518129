var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CourseCompleteCrownIcon } from '@lean-body/components/atoms';
import { CourseVolumeInfo, CourseVolumeProgress } from '@lean-body/components/molecules';
import { MediaBreakPointUp, Colors } from '@lean-body/components/styles';
export var CourseVolumeItem = function (_a) {
    var volume = _a.volume, classObject = _a.classObject, onClickVolume = _a.onClickVolume;
    return (React.createElement("div", { className: css(styles.container, classObject), onClick: function () { return onClickVolume(volume.id); } },
        React.createElement(CourseVolumeInfo, { volume: volume }),
        React.createElement("div", null, volume.isFirstCompleted ? (React.createElement(CourseCompleteCrownIcon, null)) : (React.createElement(CourseVolumeProgress, { totalDays: volume.totalDays, finishedDays: volume.finishedDays })))));
};
var styles = StyleSheet.create({
    container: (_a = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: "1px solid ".concat(Colors.BorderLightGray),
            borderRadius: 10,
            padding: '14.5px 23.5px'
        },
        _a[MediaBreakPointUp.MD] = {
            padding: '28px 50px',
        },
        _a[MediaBreakPointUp.XL] = {
            padding: '28px 80px',
        },
        _a),
});
