var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Button, ButtonType, CourseRetakeButton } from '@lean-body/components/atoms';
export var CourseDayRewardButtons = function (_a) {
    var onClickNext = _a.onClickNext, onClickBack = _a.onClickBack, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(Button, { className: css(styles.button, styles.nextButton), buttonType: ButtonType.primary, onClick: onClickNext }, "\u6B21\u3078"),
        React.createElement(CourseRetakeButton, { buttonType: ButtonType.transparentBackgroundPrimary, classObject: styles.button, onClick: onClickBack })));
};
var styles = StyleSheet.create({
    container: {
        padding: '30px 0',
        backgroundColor: Colors.White,
    },
    button: (_a = {
            display: 'block',
            width: 327,
            borderRadius: 24,
            margin: '0 auto'
        },
        _a[MediaBreakPointUp.MD] = {
            width: 600,
        },
        _a[MediaBreakPointUp.XL] = {
            width: 600,
        },
        _a),
    nextButton: {
        marginBottom: 7,
    },
});
