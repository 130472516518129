var _a;
import * as React from 'react';
import { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { ZIndex } from '../constants';
import { MealImageDeleteConfirmDialog } from '../molecules/meal_image_delete_confirm_dialog';
import { RecordMealConfirmImage } from '../molecules/record_meal_confirm_image';
import { RecordMealOverlayImage } from './record_meal_overlay_image';
export var RecordMealConfirmImageList = function (_a) {
    var imageMap = _a.imageMap, onDeleteImage = _a.onDeleteImage;
    var _b = useState(), deleteTargetIndex = _b[0], setDeleteTargetIndex = _b[1];
    var _c = useState(), displayTargetIndex = _c[0], setDisplayTargetIndex = _c[1];
    var _d = useState(false), showDeleteImageConfirmDialog = _d[0], setShowDeleteImageConfirmDialog = _d[1];
    var _e = useState(false), showImageDetail = _e[0], setShowImageDetail = _e[1];
    var onClickDeleteIcon = function (index) {
        setDeleteTargetIndex(index);
        setShowDeleteImageConfirmDialog(true);
    };
    var onClickDeleteImage = function () {
        onDeleteImage(deleteTargetIndex);
        setShowDeleteImageConfirmDialog(false);
    };
    var onClickCancelDeleteImage = function () {
        setDeleteTargetIndex(null);
        setShowDeleteImageConfirmDialog(false);
    };
    var onClickImage = function (index) {
        setDisplayTargetIndex(index);
        setShowImageDetail(true);
    };
    var onCloseImageDetail = function () {
        setDisplayTargetIndex(null);
        setShowImageDetail(false);
    };
    return (React.createElement("div", { className: css(styles.container) },
        Array.from(imageMap.entries()).map(function (_a) {
            var index = _a[0], fileMap = _a[1];
            return (React.createElement(RecordMealConfirmImage, { key: index, index: index, fileMap: fileMap, onClickImage: onClickImage, onClickDeleteImage: onClickDeleteIcon }));
        }),
        showDeleteImageConfirmDialog && (React.createElement(MealImageDeleteConfirmDialog, { onClickDelete: onClickDeleteImage, onClickCancel: onClickCancelDeleteImage })),
        showImageDetail && (React.createElement(RecordMealOverlayImage, { imageMap: imageMap, displayTargetIndex: displayTargetIndex, setDisplayTargetIndex: setDisplayTargetIndex, onCloseImageDetail: onCloseImageDetail }))));
};
var styles = StyleSheet.create({
    container: (_a = {
            display: 'flex',
            flexWrap: 'wrap',
            gap: 6
        },
        _a[MediaBreakPointUp.MD] = {
            gap: 10,
        },
        _a),
    closeIcon: {
        position: 'absolute',
        top: -6,
        right: -4,
        width: 18,
        height: 18,
    },
    overlay: {
        background: Colors.StrictBlack,
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        top: '0',
        left: '0',
        zIndex: ZIndex.Overlay,
    },
    dialogCloseIcon: {
        position: 'absolute',
        top: 20,
        left: 24,
    },
    detailImageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100% - 230px)',
        marginTop: 112,
    },
    detailImage: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    arrowIcon: {
        position: 'absolute',
        top: 'calc(50% - 22px)',
        width: 44,
        height: 44,
    },
    leftArrowIcon: {
        left: 20,
    },
    rightArrowIcon: {
        right: 20,
    },
    arrowIconBackground: {
        background: Colors.TextDark,
        borderRadius: 22,
        opacity: 0.15,
    },
    imageCount: {
        color: Colors.White,
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 700,
        lineHeight: '160%',
        letterSpacing: 0.48,
        height: 19,
        marginTop: 35,
        marginBottom: 64,
    },
});
