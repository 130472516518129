var _a, _b, _c, _d;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Dialog } from '@lean-body/components/molecules';
import { TwitterLogoIcon } from '@lean-body/components/atoms/icons/twitter_logo_icon';
import { LiveEventItems } from '@lean-body/components/molecules/live_event_items';
import { useScript } from '@lean-body/src/util/custom_hooks';
export var LiveEventShareDialog = function (_a) {
    var close = _a.close;
    var _b = React.useState(false), displayable = _b[0], setDisplayable = _b[1];
    useScript('https://platform.twitter.com/widgets.js', function () { return setDisplayable(true); });
    if (!displayable)
        return null;
    return (React.createElement(Dialog, { onClickCancel: close, content: React.createElement("div", { className: css(styles.container) },
            React.createElement("div", { className: css(styles.headImage) },
                React.createElement("img", { className: css(styles.headBgLogo), src: "/assets/images/live_event/logo_bg.png" }),
                React.createElement("div", { className: css(styles.headTextMini) }, "THANK YOU !"),
                React.createElement("img", { className: css(styles.batLeft), src: "/assets/images/live_event/bat_left.png" }),
                React.createElement("img", { className: css(styles.batRight), src: "/assets/images/live_event/bat_right.png" }),
                React.createElement("img", { className: css(styles.pumpkin), src: "/assets/images/live_event/pumpkin.png" }),
                React.createElement("div", { className: css(styles.headText) },
                    "\u53C2\u52A0\u767B\u9332\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059",
                    React.createElement("br", null),
                    "\u30A4\u30D9\u30F3\u30C8\u5F53\u65E5\u307E\u3067\u304A\u5F85\u3061\u4E0B\u3055\u3044")),
            React.createElement("div", { className: css(styles.content) },
                React.createElement("div", { className: css(styles.description) },
                    React.createElement("span", { className: css(styles.bold) }, "Instagram"),
                    "\u3084",
                    React.createElement("span", { className: css(styles.bold) }, "Twitter"),
                    "\u3067 #\u8102\u80AA\u71C3\u713C\u30D5\u30A7\u30B9\u30C6\u30A3\u30D0\u30EB \u3068\u6295\u7A3F\u3059\u308B\u3068\u3001\u4E0B\u8A18\u306E\u30D7\u30EC\u30BC\u30F3\u30C8\u30AD\u30E3\u30F3\u30DA\u30FC\u30F3\u306B\u53C2\u52A0\u3067\u304D\u307E\u3059\u3002"),
                React.createElement(LiveEventItems, null),
                React.createElement("a", { href: "https://twitter.com/share?url=https://lp.lean-body.jp/event_202110/index.html&via=leanbodyjp&text=10/31(\u65E5)\u30CF\u30ED\u30A6\u30A3\u30F33\u6642\u9593\u30E9\u30A4\u30D6\u30A4\u30D9\u30F3\u30C8%20%23\u8102\u80AA\u71C3\u713C\u30D5\u30A7\u30B9\u30C6\u30A3\u30D0\u30EB%20\u306B\u53C2\u52A0\uFF01%0A", target: "_blank", className: css(styles.button), "data-show-count": "false" },
                    React.createElement(TwitterLogoIcon, { classObject: styles.twitterLogo }),
                    "\u53C2\u52A0\u3092\u30C4\u30A4\u30FC\u30C8"))) }));
};
var styles = StyleSheet.create({
    container: {
        borderRadius: 8,
        overflow: 'hidden',
    },
    headImage: {
        background: Colors.GradationOrange,
        position: 'relative',
        height: 180,
    },
    headBgLogo: {
        position: 'absolute',
        left: 0,
        right: 0,
        margin: '0 auto',
        width: 128,
    },
    headTextMini: {
        fontSize: 12,
        position: 'absolute',
        top: 32,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: '#E46113',
    },
    batLeft: (_a = {
            width: 47,
            position: 'absolute',
            top: 54,
            left: 99
        },
        _a[MediaBreakPointUp.XL] = {
            left: 211,
        },
        _a),
    batRight: (_b = {
            width: 29,
            position: 'absolute',
            top: 57,
            right: 108
        },
        _b[MediaBreakPointUp.XL] = {
            right: 238,
        },
        _b),
    pumpkin: {
        width: 62,
        position: 'absolute',
        top: 52,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    headText: {
        fontSize: 18,
        color: Colors.White,
        position: 'absolute',
        bottom: 14,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    content: (_c = {
            padding: '24px 12px',
            backgroundColor: Colors.BackgroundOrange
        },
        _c[MediaBreakPointUp.XL] = {
            padding: '32px 0 40px',
        },
        _c),
    description: (_d = {
            fontSize: 14,
            margin: '0 12px 20px'
        },
        _d[MediaBreakPointUp.XL] = {
            margin: '0 120px 20px',
        },
        _d),
    bold: {
        fontWeight: 'bold',
    },
    button: {
        fontWeight: 'bold',
        width: '100%',
        maxWidth: 300,
        color: Colors.White,
        backgroundColor: Colors.Twitter,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px 0',
        margin: '35px auto 0',
        borderRadius: 50,
        cursor: 'pointer',
    },
    twitterLogo: {
        marginRight: 8,
    },
});
