var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, StyleSheet } from 'aphrodite';
import { bind } from 'bind-decorator';
import { computed, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import validator from 'validator';
import * as msg from '@lean-body/src/messages';
import { Button, ButtonType, Input } from '../atoms';
import { SettingStyles } from '../pages/main_setting';
import { FORM_PASSWORD_PLACEHOLDER } from '@lean-body/components/molecules/input_form_group/password_vm';
export var PasswordChangeSubsection = observer(function (_a) {
    var classObject = _a.classObject, accountService = _a.accountService, apiClient = _a.apiClient, alertPresenter = _a.alertPresenter, me = _a.me;
    var _b = React.useState(new PasswordChangeSubsectionVM(accountService, apiClient, alertPresenter, me)), vm = _b[0], _ = _b[1];
    return (React.createElement("div", { className: css(styles.container, classObject) },
        vm.passwordEnabled && (React.createElement(Input, { type: "password", onChange: vm.onChangeCurPassword, value: vm.curPassword, placeholder: "\u73FE\u5728\u306E\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u5165\u529B", dataTest: "currentPass", containerClassObject: styles.input, label: "\u73FE\u5728\u306E\u30D1\u30B9\u30EF\u30FC\u30C9" })),
        React.createElement(Input, { type: "password", onChange: vm.onChangeNewPassword1, value: vm.newPassword1, placeholder: FORM_PASSWORD_PLACEHOLDER, dataTest: "newPassword", containerClassObject: styles.input, label: "\u65B0\u3057\u3044\u30D1\u30B9\u30EF\u30FC\u30C9" }),
        React.createElement(Input, { type: "password", onChange: vm.onChangeNewPassword2, value: vm.newPassword2, placeholder: "\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u518D\u5165\u529B", dataTest: "newPasswordConfirm", containerClassObject: styles.input, label: "\u65B0\u3057\u3044\u30D1\u30B9\u30EF\u30FC\u30C9(\u78BA\u8A8D)" }),
        React.createElement(Button, { onClick: vm.onSubmit, disabled: vm.status === Status.submitting, loading: vm.status === Status.submitting, dataTest: "newPasswordSubmit", buttonType: vm.curPassword && vm.newPassword1 && vm.newPassword2 ? ButtonType.primary : ButtonType.transparentPrimary, className: css(SettingStyles.button) }, "\u5909\u66F4")));
});
var PasswordChangeSubsectionStatus;
(function (PasswordChangeSubsectionStatus) {
    PasswordChangeSubsectionStatus[PasswordChangeSubsectionStatus["preparing"] = 0] = "preparing";
    PasswordChangeSubsectionStatus[PasswordChangeSubsectionStatus["prepareFailed"] = 1] = "prepareFailed";
    PasswordChangeSubsectionStatus[PasswordChangeSubsectionStatus["ready"] = 2] = "ready";
    PasswordChangeSubsectionStatus[PasswordChangeSubsectionStatus["submitting"] = 3] = "submitting";
    PasswordChangeSubsectionStatus[PasswordChangeSubsectionStatus["finished"] = 4] = "finished";
    PasswordChangeSubsectionStatus[PasswordChangeSubsectionStatus["failed"] = 5] = "failed";
})(PasswordChangeSubsectionStatus || (PasswordChangeSubsectionStatus = {}));
var Status = PasswordChangeSubsectionStatus;
var PasswordChangeSubsectionVM = /** @class */ (function () {
    function PasswordChangeSubsectionVM(accountService, apiClient, alertPresenter, me) {
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertGroup", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'password_change'
        });
        Object.defineProperty(this, "curPassword", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "newPassword1", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "newPassword2", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "me", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: Status.preparing
        });
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            curPassword: observable,
            newPassword1: observable,
            newPassword2: observable,
            me: observable,
            status: observable,
            passwordEnabled: computed,
        });
        this.alertPresenter = alertPresenter;
        this.accountService = accountService;
        this.apiClient = apiClient;
        this.setMe(me);
    }
    Object.defineProperty(PasswordChangeSubsectionVM.prototype, "passwordEnabled", {
        get: function () {
            if (!this.me) {
                return true;
            }
            return this.me.passwordEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PasswordChangeSubsectionVM.prototype, "setMe", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (me) {
            this.me = me;
            this.status = Status.ready;
        }
    });
    Object.defineProperty(PasswordChangeSubsectionVM.prototype, "onChangeCurPassword", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            this.curPassword = e.target.value;
        }
    });
    Object.defineProperty(PasswordChangeSubsectionVM.prototype, "onChangeNewPassword1", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            this.newPassword1 = e.target.value;
        }
    });
    Object.defineProperty(PasswordChangeSubsectionVM.prototype, "onChangeNewPassword2", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            this.newPassword2 = e.target.value;
        }
    });
    Object.defineProperty(PasswordChangeSubsectionVM.prototype, "validate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.passwordEnabled && validator.isEmpty(this.curPassword)) {
                this.alertPresenter.showMessage(msg.CUR_PASSWORD_EMPTY, this.alertGroup);
                return false;
            }
            if (validator.isEmpty(this.newPassword1)) {
                this.alertPresenter.showMessage(msg.NEW_PASSWORD_EMPTY, this.alertGroup);
                return false;
            }
            if (validator.isEmpty(this.newPassword2)) {
                this.alertPresenter.showMessage(msg.NEW_PASSWORD_CONFIRM_EMPTY, this.alertGroup);
                return false;
            }
            if (!validator.equals(this.newPassword1, this.newPassword2)) {
                this.alertPresenter.showMessage(msg.NEW_PASSWORD_DOESNT_MATCH, this.alertGroup);
                return false;
            }
            return true;
        }
    });
    Object.defineProperty(PasswordChangeSubsectionVM.prototype, "onSubmit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            var _this = this;
            e.preventDefault();
            if (!this.validate()) {
                return;
            }
            this.status = Status.submitting;
            this.apiClient
                .changePassword({
                oldPassword: this.curPassword,
                newPassword: this.newPassword1,
            })
                .then(function () {
                _this.accountService.reloadMe();
                _this.status = Status.finished;
                _this.curPassword = '';
                _this.newPassword1 = '';
                _this.newPassword2 = '';
                _this.alertPresenter.showMessage(msg.PASSWORD_CHANGE_SUCCEEDED, _this.alertGroup);
            })
                .catch(function (err) {
                _this.status = Status.failed;
                _this.alertPresenter.showError(err, msg.PASSWORD_CHANGE_FAILED, _this.alertGroup);
            });
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PasswordChangeSubsectionVM.prototype, "onChangeCurPassword", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PasswordChangeSubsectionVM.prototype, "onChangeNewPassword1", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PasswordChangeSubsectionVM.prototype, "onChangeNewPassword2", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PasswordChangeSubsectionVM.prototype, "onSubmit", null);
    return PasswordChangeSubsectionVM;
}());
export { PasswordChangeSubsectionVM };
var styles = StyleSheet.create({
    container: {},
    input: {
        marginBottom: 40,
    },
});
