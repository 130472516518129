import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var CourseScheduleAssessment = function (_a) {
    var children = _a.children, title = _a.title, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.titleBox) },
            React.createElement("div", { className: css(styles.title) }, title)),
        children));
};
var styles = StyleSheet.create({
    container: {
        textAlign: 'center',
    },
    titleBox: {
        maxWidth: 265,
        margin: '0 auto',
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        textAlign: 'center',
        letterSpacing: '0.08em',
        color: Colors.Black,
    },
});
