import { Lesson } from '.';
import { Equipment, Trainer } from '.';
import { VideoSrc } from './video';
import { getStringDuration } from '@lean-body/src/util';
import { AVATAR_DEFAULT_SRC } from '../view/atoms/avatar_uploader';
/*=============================================
= DomainModels =
=============================================*/
var Playlist = /** @class */ (function () {
    function Playlist(playlistData, config, isOriginalPlaylist) {
        if (isOriginalPlaylist === void 0) { isOriginalPlaylist = false; }
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nameWithBreakLine", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "summary", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "shortDescription", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonLength", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonLengthAvg", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "duration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "heroSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "thumbnailSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "videoSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "video", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "difficulty", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "equipments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "goals", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "trainers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isChallenge", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isComingSoon", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isPlaylist", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "publishAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tags", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playlistAggregate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isChecked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "updatedAtDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "creator", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalMinCal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalMaxCal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isOriginalPlaylist", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isFavorited", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isChallenging", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userChallenge", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "currentOrderIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        }); // チャレンジレッスンで使用する現在やるべきレッスンのindex、index:0の時は何もレッスンをしていない、index:1の時はindex:0のレッスンが完了している
        this.config = config;
        this.id = playlistData.id;
        this.nameWithBreakLine = playlistData.name;
        this.name = playlistData.name.replace('\n', ''); // オリジナルプレイリストの時、改行コードが含まれている
        this.status = playlistData.status;
        this.summary = playlistData.summary;
        this.description = playlistData.description;
        this.shortDescription = playlistData.shortDescription;
        this.lessonLength = playlistData.lessonLength;
        this.lessonLengthAvg = playlistData.lessonLengthAvg;
        this.duration = playlistData.duration;
        this.heroSrc = playlistData.heroSrc;
        this.thumbnailSrc = playlistData.thumbnailSrc;
        this.videoSrc = playlistData.videoSrc;
        this.video = new VideoSrc(playlistData.videoSrc, config);
        this.difficulty = playlistData.difficulty;
        this.equipments = (playlistData.equipments || []).map(function (o) { return new Equipment(o, config); });
        this.goals = playlistData.goals || [];
        this.trainers = (playlistData.trainers || []).map(function (o) { return new Trainer(o, config); });
        this.isChallenge = playlistData.isChallenge;
        this.isPlaylist = playlistData.isPlaylist;
        this.publishAt = playlistData.publishAt;
        this.orderIndex = playlistData.orderIndex;
        this.tags = playlistData.tags;
        this.playlistAggregate = playlistData.playlistAggregate || playlistData.programAggregate;
        this.isChecked = false;
        this.updatedAtDate = new Date(playlistData.updatedAt);
        this.creator = this.parseCreator(playlistData.creator);
        this.totalMinCal = playlistData.totalMinCal;
        this.totalMaxCal = playlistData.totalMaxCal;
        this.isOriginalPlaylist = isOriginalPlaylist;
        this.isFavorited = playlistData.isFavorited;
        this.lessonCount = playlistData.lessonCount;
        this.createdAt = new Date(playlistData.createdAt);
        this.isChallenging = playlistData.isChallenging;
        this.userChallenge = playlistData.userChallenge;
        this.currentOrderIndex = playlistData.currentOrderIndex;
        if (playlistData.lessons) {
            this.lessons = playlistData.lessons.map(function (o) { return new Lesson(o, config); });
        }
    }
    Object.defineProperty(Playlist.prototype, "thumbnailURL", {
        get: function () {
            return this.config.cdnBaseURL + this.thumbnailSrc;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Playlist.prototype, "totalDuration", {
        get: function () {
            var sumOfDuration = this.lessons.reduce(function (num, lesson) {
                num += lesson.duration;
                return num;
            }, 0);
            return getStringDuration(sumOfDuration);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Playlist.prototype, "calorieRange", {
        get: function () {
            return "".concat(this.totalMinCal, " - ").concat(this.totalMaxCal);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Playlist.prototype, "favoriteCount", {
        get: function () {
            if (!this.playlistAggregate) {
                return 0;
            }
            return this.playlistAggregate.totalFavoriteCount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Playlist.prototype, "setIsChecked", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isChecked) {
            this.isChecked = isChecked;
        }
    });
    Object.defineProperty(Playlist.prototype, "parseCreator", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (creator) {
            if (!creator)
                return;
            if (!creator.iconSrc) {
                creator.iconSrc = AVATAR_DEFAULT_SRC;
            }
            else {
                creator.iconSrc = "".concat(this.config.cdnUserBaseURL).concat(creator.iconSrc);
            }
            return creator;
        }
    });
    Object.defineProperty(Playlist.prototype, "lessonIndex", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lessonOrderIndex) {
            return this.lessons.map(function (v) { return v.orderIndex; }).indexOf(lessonOrderIndex);
        }
    });
    Object.defineProperty(Playlist.prototype, "prevLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (currentLessonIndex) {
            var prevLessonIndex;
            if (this.isFirstLesson(currentLessonIndex)) {
                return [null, null];
            }
            else {
                prevLessonIndex = currentLessonIndex - 1;
                if (this.lessons[prevLessonIndex].isDayOff) {
                    prevLessonIndex--;
                }
            }
            return [prevLessonIndex, this.lessons[prevLessonIndex]];
        }
    });
    Object.defineProperty(Playlist.prototype, "nextLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (currentLessonIndex) {
            var nextLessonIndex;
            if (this.lessons.length > currentLessonIndex + 1) {
                nextLessonIndex = currentLessonIndex + 1;
                if (this.lessons[nextLessonIndex].isDayOff) {
                    nextLessonIndex++;
                }
            }
            else {
                return [null, null];
            }
            return [nextLessonIndex, this.lessons[nextLessonIndex]];
        }
    });
    Object.defineProperty(Playlist.prototype, "isFirstLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (currentLessonIndex) {
            return currentLessonIndex == 0;
        }
    });
    Object.defineProperty(Playlist.prototype, "isLastLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (currentLessonIndex) {
            return this.lessons.length == currentLessonIndex + 1;
        }
    });
    return Playlist;
}());
export { Playlist };
