import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Link } from 'react-router-dom';
import { Logo } from '@lean-body/components/atoms';
import { FooterComponent } from '@lean-body/components/organisms';
export var NowRedirecting = function () {
    var _a;
    var styles = StyleSheet.create({
        container: {
            backgroundColor: '#fafffa',
            backgroundImage: 'none',
            textAlign: 'center',
            paddingTop: 50,
            width: '100%',
        },
        link: {
            display: 'inline-block',
        },
        notice: {
            marginTop: 15,
            marginBottom: 380,
            display: 'block',
            letterSpacing: 2,
            fontSize: '1.06em',
            color: Colors.GrayDarkest,
        },
        logo: (_a = {
                width: '240px'
            },
            _a[MediaBreakPointUp.MD] = {
                width: '290px',
            },
            _a),
    });
    return (React.createElement("section", { className: css(styles.container) },
        React.createElement(Link, { to: "/", className: css(styles.link) },
            React.createElement(Logo, { classObject: styles.logo })),
        React.createElement("span", { className: css(styles.notice) }, "...\u30EA\u30C0\u30A4\u30EC\u30AF\u30C8\u4E2D..."),
        React.createElement(FooterComponent, null)));
};
