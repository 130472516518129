var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { ArrowIcon, ArrowDirection } from '@lean-body/components/atoms';
import { CourseThumbnail } from '@lean-body/components/molecules';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
export var CourseSelectItem = function (_a) {
    var course = _a.course, onClickCourse = _a.onClickCourse, onClickSelect = _a.onClickSelect, dataTest = _a.dataTest, isActive = _a.isActive, isForChangeCourse = _a.isForChangeCourse, disabled = _a.disabled, classObject = _a.classObject, config = _a.config;
    var courseBoxClass = isActive && !disabled ? styles.active : styles.inActive;
    var courseStatusActiveText = isForChangeCourse ? '現在のコース' : '選択中';
    var courseStatusInActiveText = isForChangeCourse ? 'このコースに変更' : 'このコースを選択';
    var courseStatusActiveStyle = disabled
        ? css(styles.status, styles.courseStatusDisabled)
        : css(styles.status, styles.courseStatusActive);
    return (React.createElement("div", { className: css(styles.container, courseBoxClass, classObject), "data-test": dataTest },
        React.createElement("div", { onClick: function () { return onClickCourse(course.id); } },
            React.createElement(CourseThumbnail, { course: course, config: config }),
            React.createElement("div", { className: css(styles.detail) },
                React.createElement("div", { className: css(styles.messageBox) },
                    React.createElement("div", { className: css(styles.message) }, course.recommendText),
                    React.createElement(ArrowIcon, { direction: ArrowDirection.right, color: Colors.TextDark, classObject: styles.dialogArrow })),
                React.createElement("div", { className: css(styles.description) }, course.description))),
        React.createElement("div", { onClick: function () { return onClickSelect(course.id); } }, isActive ? (React.createElement("div", { className: courseStatusActiveStyle }, courseStatusActiveText)) : (React.createElement("div", { className: css(styles.status, styles.courseStatusInActive) }, courseStatusInActiveText)))));
};
var styles = StyleSheet.create({
    container: {
        borderRadius: 10,
        overflow: 'hidden',
    },
    title: {
        fontWeight: 700,
        fontSize: 30,
        letterSpacing: '0.04em',
        color: Colors.Black,
        marginLeft: 21,
    },
    detail: {
        padding: '10px 12px',
        textAlign: 'left',
        letterSpacing: '0.04em',
    },
    messageBox: (_a = {
            fontWeight: 700,
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: Colors.Black,
            marginBottom: 12
        },
        _a[MediaBreakPointUp.MD] = {
            height: 40,
        },
        _a),
    message: {
        width: 278,
    },
    dialogArrow: {
        height: 25,
    },
    description: {
        fontWeight: 400,
        fontSize: 14,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    status: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 14,
        height: 44,
        letterSpacing: '0.04em',
        padding: '12px 0 10px 0',
        borderRadius: '0 0 10px 10px',
    },
    courseStatusActive: {
        color: Colors.White,
        backgroundColor: Colors.PrimaryDark,
        borderTop: "1px solid transparent",
        boxShadow: "".concat(Colors.PrimaryDark, " 0px 0px 0px 0.5px, 0px 0px 30px rgba(0, 0, 0, 0.06)"),
    },
    courseStatusInActive: {
        borderTop: "1px solid ".concat(Colors.BorderLightGray),
        color: Colors.PrimaryDarkB,
    },
    courseStatusDisabled: {
        borderTop: "1px solid ".concat(Colors.BorderLightGray),
        color: Colors.GrayDarkest,
    },
    active: {
        boxShadow: "0 0 0 1.5px ".concat(Colors.PrimaryDark, ", 0px 0px 30px rgba(0, 0, 0, 0.06)"),
        outlineOffset: -1,
    },
    inActive: {
        boxShadow: "0 0 0 1px ".concat(Colors.Gray, ", 0px 0px 30px rgba(0, 0, 0, 0.06)"),
    },
});
