import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MediaBreakPointUp } from '@lean-body/components/styles';
import { ProgramComponent } from '@lean-body/components/molecules';
export var Programs = function (_a) {
    var _b;
    var classObject = _a.classObject, programs = _a.programs, addFavoriteProgram = _a.addFavoriteProgram, unFavoriteProgram = _a.unFavoriteProgram, onClickProgram = _a.onClickProgram, isSM = _a.isSM;
    if (!programs) {
        return null;
    }
    var styles = StyleSheet.create({
        container: (_b = {
                display: 'flex',
                gap: '12px 0',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            },
            _b[MediaBreakPointUp.MD] = {
                gap: '20px 0',
            },
            _b),
    });
    return (React.createElement("div", { className: css(styles.container, classObject) }, programs.map(function (program) { return (React.createElement(ProgramComponent, { key: program.id, program: program, onClick: onClickProgram, addFavoriteProgram: addFavoriteProgram, unFavoriteProgram: unFavoriteProgram, isVertical: !isSM })); })));
};
