var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Button, ButtonType } from '@lean-body/components/atoms/button';
export var ChangeCourseButtons = function (_a) {
    var nextButtonClickable = _a.nextButtonClickable, nextButtonText = _a.nextButtonText, onClickNext = _a.onClickNext, onClickBack = _a.onClickBack, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(Button, { className: nextButtonClickable
                ? css(styles.button, styles.nextbutton)
                : css(styles.button, styles.nextbutton, styles.disableButton), buttonType: nextButtonClickable ? ButtonType.primary : ButtonType.borderGray, disabled: !nextButtonClickable, onClick: onClickNext }, nextButtonText),
        React.createElement(Button, { className: css(styles.button, styles.backButton), buttonType: ButtonType.secondary, onClick: onClickBack }, "\u4ED6\u306E\u30B3\u30FC\u30B9\u3092\u898B\u308B")));
};
var styles = StyleSheet.create({
    container: {
        padding: '30px 0',
        backgroundColor: Colors.White,
        textAlign: 'center',
    },
    button: (_a = {
            display: 'block',
            width: 327,
            borderRadius: 24,
            margin: '0 auto'
        },
        _a[MediaBreakPointUp.MD] = {
            width: 375,
        },
        _a[MediaBreakPointUp.XL] = {
            width: 800,
        },
        _a),
    nextbutton: {
        fontWeight: 600,
        fontSize: 16,
        letterSpacing: '0.08em',
    },
    disableButton: {
        color: Colors.GrayDarkest,
    },
    backButton: {
        marginTop: 12,
        fontSize: 14,
        borderColor: Colors.PrimaryDarkB,
    },
});
