import { VideoSrc } from './video';
import { getCurrentUnixTimeInSeconds } from '@lean-body/src/util/date';
import { Equipment, Trainer, Lesson } from '.';
var Program = /** @class */ (function () {
    function Program(programData, config) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "slug", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "shortDescription", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonLength", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonLengthAvg", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "duration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "heroSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "thumbnailSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "videoSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "video", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "difficulty", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "equipments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "goals", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "trainers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isChallenge", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isComingSoon", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isPlaylist", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "publishAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tags", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "programAggregate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isChecked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isFavorited", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = programData.id;
        this.slug = programData.slug;
        this.name = programData.name;
        this.status = programData.status;
        this.description = programData.description;
        this.shortDescription = programData.shortDescription;
        this.lessonLength = programData.lessonLength;
        this.lessonLengthAvg = programData.lessonLengthAvg;
        this.duration = programData.duration;
        this.heroSrc = programData.heroSrc;
        this.thumbnailSrc = programData.thumbnailSrc;
        this.videoSrc = programData.videoSrc;
        this.video = new VideoSrc(programData.videoSrc, config);
        this.difficulty = programData.difficulty;
        this.equipments = (programData.equipments || []).map(function (d) {
            return new Equipment(d, config);
        });
        this.goals = programData.goals || [];
        this.trainers = (programData.trainers || []).map(function (d) {
            return new Trainer(d, config);
        });
        this.isChallenge = programData.isChallenge;
        this.publishAt = programData.publishAt;
        this.orderIndex = programData.orderIndex;
        this.tags = programData.tags;
        this.programAggregate = programData.programAggregate;
        this.lessons = programData.lessons && programData.lessons.map(function (v) { return new Lesson(v, config); });
        this.lessonCount = programData.lessonCount;
        var currentUnixTime = getCurrentUnixTimeInSeconds();
        this.isComingSoon = !this.publishAt || currentUnixTime < this.publishAt;
        this.isPlaylist = programData.isPlaylist;
        this.isChecked = false;
        this.config = config;
        this.isFavorited = programData.isFavorited;
        this.createdAt = new Date(programData.createdAt);
    }
    Object.defineProperty(Program.prototype, "videoId", {
        get: function () {
            return this.video.id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Program.prototype, "thumbnailURL", {
        get: function () {
            return this.config.cdnBaseURL + this.thumbnailSrc;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Program.prototype, "heroURL", {
        get: function () {
            return this.config.cdnBaseURL + this.heroSrc;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Program.prototype, "isLifeStyle", {
        get: function () {
            return this.status == Program.LIFESTYLE_STATUS;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Program.prototype, "isVisible", {
        // slugが無いプログラムはユーザーがプログラムページを閲覧できないようにする仕様の為の条件。
        // COMING SOONのレッスンはスラグが空だったりする
        get: function () {
            return this.slug !== '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Program.prototype, "setIsChecked", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isChecked) {
            this.isChecked = isChecked;
        }
    });
    Object.defineProperty(Program.prototype, "lessonIndex", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lessonID) {
            return this.lessons.map(function (v) { return v.id; }).indexOf(lessonID);
        }
    });
    Object.defineProperty(Program.prototype, "prevLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (currentLessonIndex) {
            var prevLessonIndex;
            if (this.isFirstLesson(currentLessonIndex)) {
                return null;
            }
            else {
                prevLessonIndex = currentLessonIndex - 1;
            }
            return this.lessons[prevLessonIndex];
        }
    });
    Object.defineProperty(Program.prototype, "nextLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (currentLessonIndex) {
            var nextLessonIndex;
            if (this.lessons.length > currentLessonIndex + 1) {
                nextLessonIndex = currentLessonIndex + 1;
            }
            else {
                return null;
            }
            return this.lessons[nextLessonIndex];
        }
    });
    Object.defineProperty(Program.prototype, "isFirstLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (currentLessonIndex) {
            return currentLessonIndex == 0;
        }
    });
    Object.defineProperty(Program.prototype, "isLastLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (currentLessonIndex) {
            return this.lessons.length == currentLessonIndex + 1;
        }
    });
    Object.defineProperty(Program, "BLANK_PROGRAM_ID", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: 100
    });
    Object.defineProperty(Program, "LIFESTYLE_STATUS", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: 2
    });
    return Program;
}());
export { Program };
var ProgramsLesson = /** @class */ (function () {
    function ProgramsLesson(programsLessonData, config) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "programId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = programsLessonData.id;
        this.programId = programsLessonData.programId;
        this.lessonId = programsLessonData.lessonId;
        this.orderIndex = programsLessonData.orderIndex;
        this.config = config;
    }
    return ProgramsLesson;
}());
export { ProgramsLesson };
