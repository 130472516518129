import * as React from 'react';
import { Dialog, BadgeDialog, DIALOG_IMAGE_HEIGHT, DIALOG_IMAGE_HEIGHT_XL, DIALOG_WIDTH, DIALOG_WIDTH_XL, } from '@lean-body/components/molecules';
import { routes } from '@lean-body/src/route';
import { SharedTiming } from '@lean-body/src/interfaces';
import { useXlMediaQuery } from '@lean-body/src/util';
export var BadgeOrUserSeasonRankDialog = function (_a) {
    var _b;
    var badge = _a.badge, userSeasonAggregate = _a.userSeasonAggregate, onClickCancel = _a.onClickCancel, twitterService = _a.twitterService, onClickTwitterShareIfAuthed = _a.onClickTwitterShareIfAuthed;
    var isXL = useXlMediaQuery();
    var rank = userSeasonAggregate === null || userSeasonAggregate === void 0 ? void 0 : userSeasonAggregate.userSeasonAggregateRank;
    var userSeasonAggregateStartAt = (_b = userSeasonAggregate === null || userSeasonAggregate === void 0 ? void 0 : userSeasonAggregate.seasonStartAtDayjs) === null || _b === void 0 ? void 0 : _b.format('YYYY年MM月');
    var playDurationMinute = userSeasonAggregate === null || userSeasonAggregate === void 0 ? void 0 : userSeasonAggregate.playDurationMinute;
    var title;
    if (rank) {
        title = "".concat(rank.nameJp, "\u30E9\u30F3\u30AF\u8A8D\u5B9A\uFF01");
    }
    else if (badge.isChallenge) {
        title = "\u300E".concat(badge.name, "\u300F\u3092\u4FEE\u4E86\uFF01");
    }
    else {
        title = "".concat(badge.name, "\u3092\u9054\u6210\uFF01");
    }
    var description = rank && !!playDurationMinute && "".concat(userSeasonAggregateStartAt, "\u306FLEAN BODY\u3067\n").concat(playDurationMinute, "\u5206\u904B\u52D5\u3057\u307E\u3057\u305F\uFF01");
    var linkText = (badge === null || badge === void 0 ? void 0 : badge.isChallenge) && 'チャレンジページを見る';
    var linkTo = linkText && "".concat(routes.PLAYLIST.compile({ id: badge.badgeConditions[0].condition.toString() }));
    return (React.createElement(Dialog, { onClickCancel: onClickCancel, isCloseButton: true, content: React.createElement(BadgeDialog, { imgWidth: isXL ? DIALOG_WIDTH_XL : DIALOG_WIDTH, imgHeight: isXL ? DIALOG_IMAGE_HEIGHT_XL : DIALOG_IMAGE_HEIGHT, title: title, description: description, linkTo: linkTo, linkText: linkText, rankOrBadge: rank ? rank : badge, twitterService: twitterService, onClickTwitterShareIfAuthed: onClickTwitterShareIfAuthed, sharedTiming: SharedTiming.collection }) }));
};
