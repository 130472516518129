var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { SelectMark } from '@lean-body/components/atoms';
import { PlusIcon } from '@lean-body/components/atoms/icons/plus_icon';
export var SelectLessonCard = function (_a) {
    var onClick = _a.onClick, lesson = _a.lesson, isSelected = _a.isSelected;
    return (React.createElement("div", { className: css(styles.lesson, isSelected && styles.selected, onClick && styles.clickable), onClick: onClick },
        React.createElement("img", { className: css(styles.lessonImg), src: lesson.thumbnailURL }),
        React.createElement("div", { className: css(styles.lessonName) }, lesson.name),
        React.createElement("div", { className: css(styles.plusIconContainer) }, isSelected ? React.createElement(SelectMark, { markSize: 14, isChecked: true }) : React.createElement(PlusIcon, { classObject: styles.plusIcon }))));
};
var styles = StyleSheet.create({
    lesson: {
        display: 'flex',
        alignItems: 'center',
        height: 56,
        backgroundColor: Colors.White,
        borderRadius: 4,
        overflow: 'hidden',
        marginBottom: 8,
    },
    clickable: {
        cursor: 'pointer',
    },
    lessonImg: {
        marginRight: 10,
        width: 90,
        height: 56,
        objectFit: 'cover',
    },
    lessonName: (_a = {
            flex: 1,
            fontSize: 12,
            fontWeight: 'bold',
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': '2',
            overflow: 'hidden'
        },
        _a[MediaBreakPointUp.XL] = {
            fontSize: 14,
        },
        _a),
    selected: {
        backgroundColor: Colors.BlueLighterB,
        border: "2px solid ".concat(Colors.Primary),
    },
    plusIconContainer: {
        margin: '0 13px',
        height: 20,
        width: 20,
        borderRadius: '100%',
        border: "1px solid ".concat(Colors.Primary),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
    plusIcon: {
        width: 14,
        height: 14,
    },
});
