var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import videojs from 'video.js';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import { css, StyleSheet } from 'aphrodite';
import { motion } from 'framer-motion';
import bind from 'bind-decorator';
import { MediaBreakPointUp } from '../../styles';
var VideoControllerComponent = /** @class */ (function (_super) {
    __extends(VideoControllerComponent, _super);
    function VideoControllerComponent(props) {
        var _this = _super.call(this, props) || this;
        Object.defineProperty(_this, "isPaused", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "isShowing", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        // コントローラーの非表示切り替えを管理する
        Object.defineProperty(_this, "timerId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(_this, {
            isPaused: observable,
            isShowing: observable,
        });
        return _this;
    }
    Object.defineProperty(VideoControllerComponent.prototype, "setIsPaused", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (paused) {
            this.isPaused = paused;
        }
    });
    Object.defineProperty(VideoControllerComponent.prototype, "setIsShowing", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (showing) {
            this.isShowing = showing;
            if (showing) {
                this.props.showProgressBar();
            }
            else {
                this.props.hideProgressBar();
            }
        }
    });
    Object.defineProperty(VideoControllerComponent.prototype, "componentWillUnmount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.clearHideTimer();
        }
    });
    Object.defineProperty(VideoControllerComponent.prototype, "handleSeekedEvent", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.setHideTimer();
        }
    });
    Object.defineProperty(VideoControllerComponent.prototype, "onPlayClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            e.stopPropagation();
            e.preventDefault();
            this.setIsPaused(false);
            this.props.play();
            this.setHideTimer();
        }
    });
    Object.defineProperty(VideoControllerComponent.prototype, "onPauseClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            e.stopPropagation();
            e.preventDefault();
            this.setIsPaused(true);
            this.props.pause();
            this.setHideTimer();
        }
    });
    Object.defineProperty(VideoControllerComponent.prototype, "onRewindClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            e.stopPropagation();
            e.preventDefault();
            this.props.rewind();
            this.setHideTimer();
        }
    });
    Object.defineProperty(VideoControllerComponent.prototype, "onForwardClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            e.stopPropagation();
            e.preventDefault();
            this.props.forward();
            this.setHideTimer();
        }
    });
    Object.defineProperty(VideoControllerComponent.prototype, "onPrevClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            e.stopPropagation();
            e.preventDefault();
            this.clearHideTimer();
            this.props.prev();
        }
    });
    Object.defineProperty(VideoControllerComponent.prototype, "onNextClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            e.stopPropagation();
            e.preventDefault();
            this.clearHideTimer();
            this.props.next();
        }
    });
    Object.defineProperty(VideoControllerComponent.prototype, "onOverlayClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            e.stopPropagation();
            e.preventDefault();
            if (this.isShowing) {
                this.setIsShowing(false);
            }
            else {
                this.setIsShowing(true);
                this.setHideTimer();
            }
        }
    });
    Object.defineProperty(VideoControllerComponent.prototype, "setHideTimer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            this.clearHideTimer();
            this.timerId = window.setTimeout(function () { return _this.setIsShowing(false); }, 3000);
        }
    });
    Object.defineProperty(VideoControllerComponent.prototype, "clearHideTimer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.timerId) {
                clearTimeout(this.timerId);
            }
        }
    });
    Object.defineProperty(VideoControllerComponent.prototype, "show", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.setIsShowing(true);
        }
    });
    Object.defineProperty(VideoControllerComponent.prototype, "hide", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.setIsShowing(false);
        }
    });
    Object.defineProperty(VideoControllerComponent.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.isShowing) {
                return React.createElement("div", { onClick: this.onOverlayClick, className: css(styles.overlay) });
            }
            return (React.createElement("div", { onClick: this.onOverlayClick, className: css(styles.overlay) },
                React.createElement("div", { className: css(styles.mask) }),
                React.createElement("div", { className: css(styles.wrapper) },
                    this.props.vm.isShowStepButtons || !this.props.vm.isFirstLesson ? (React.createElement(motion.button, { whileHover: { scale: 1.2 }, whileTap: { scale: 0.8 }, onClick: this.props.vm.isShowStepButtons ? this.onRewindClick : this.onPrevClick, className: css(styles.button) },
                        React.createElement("img", { src: "/assets/images/video/".concat(this.props.vm.isShowStepButtons ? 'rewind' : 'prev', ".svg"), className: css(styles.skipButtonImage) }))) : (React.createElement("div", { className: css(styles.emptyButton) })),
                    React.createElement(motion.button, { whileHover: { scale: 1.2 }, whileTap: { scale: 0.8 }, onClick: this.isPaused ? this.onPlayClick : this.onPauseClick, className: css(styles.button) },
                        React.createElement("img", { src: "/assets/images/video/".concat(this.isPaused ? 'play' : 'pause', ".svg"), className: css(styles.playButtonImage) })),
                    this.props.vm.isShowStepButtons || !this.props.vm.isLastLesson ? (React.createElement(motion.button, { whileHover: { scale: 1.2 }, whileTap: { scale: 0.8 }, onClick: this.props.vm.isShowStepButtons ? this.onForwardClick : this.onNextClick, className: css(styles.button) },
                        React.createElement("img", { src: "/assets/images/video/".concat(this.props.vm.isShowStepButtons ? 'forward' : 'next', ".svg"), className: css(styles.skipButtonImage) }))) : (React.createElement("div", { className: css(styles.emptyButton) })))));
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VideoControllerComponent.prototype, "handleSeekedEvent", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], VideoControllerComponent.prototype, "onPlayClick", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], VideoControllerComponent.prototype, "onPauseClick", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], VideoControllerComponent.prototype, "onRewindClick", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], VideoControllerComponent.prototype, "onForwardClick", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], VideoControllerComponent.prototype, "onPrevClick", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], VideoControllerComponent.prototype, "onNextClick", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], VideoControllerComponent.prototype, "onOverlayClick", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VideoControllerComponent.prototype, "setHideTimer", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VideoControllerComponent.prototype, "clearHideTimer", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VideoControllerComponent.prototype, "show", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VideoControllerComponent.prototype, "hide", null);
    return VideoControllerComponent;
}(React.Component));
var VjsVideoControllerVM = /** @class */ (function () {
    function VjsVideoControllerVM() {
        Object.defineProperty(this, "isFullScreen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isFirstLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isLastLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isAlwaysShowStepButtons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isShowStepButtons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        makeObservable(this, {
            isFullScreen: observable,
            isFirstLesson: observable,
            isLastLesson: observable,
            isAlwaysShowStepButtons: observable,
            isShowStepButtons: observable,
        });
    }
    Object.defineProperty(VjsVideoControllerVM.prototype, "setIsFullScreen", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isFullScreen) {
            this.isFullScreen = isFullScreen;
            if (!this.isAlwaysShowStepButtons) {
                this.isShowStepButtons = isFullScreen;
            }
        }
    });
    Object.defineProperty(VjsVideoControllerVM.prototype, "setIsFirstLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isFirstLesson) {
            this.isFirstLesson = isFirstLesson;
        }
    });
    Object.defineProperty(VjsVideoControllerVM.prototype, "setIsLastLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isLastLesson) {
            this.isLastLesson = isLastLesson;
        }
    });
    Object.defineProperty(VjsVideoControllerVM.prototype, "setShowStepButtons", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (showStepButtons) {
            this.isAlwaysShowStepButtons = showStepButtons;
        }
    });
    Object.defineProperty(VjsVideoControllerVM.prototype, "setIsShowStepButtons", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isShowStepButtons) {
            this.isShowStepButtons = isShowStepButtons;
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], VjsVideoControllerVM.prototype, "setIsFullScreen", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], VjsVideoControllerVM.prototype, "setIsFirstLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], VjsVideoControllerVM.prototype, "setIsLastLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], VjsVideoControllerVM.prototype, "setShowStepButtons", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], VjsVideoControllerVM.prototype, "setIsShowStepButtons", null);
    return VjsVideoControllerVM;
}());
var VideoController = observer(VideoControllerComponent);
var VjsVideoController = /** @class */ (function (_super) {
    __extends(VjsVideoController, _super);
    function VjsVideoController(player, options) {
        var _this = _super.call(this, player, options) || this;
        Object.defineProperty(_this, "videoControllerOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "videoControllerPlayer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "ref", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: React.createRef()
        });
        Object.defineProperty(_this, "vm", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        _this.videoControllerOptions = options;
        _this.videoControllerPlayer = player;
        _this.vm = new VjsVideoControllerVM();
        _this.vm.setIsFirstLesson(options.isFirstLesson);
        _this.vm.setIsLastLesson(options.isLastLesson);
        _this.vm.setShowStepButtons(options.isAlwaysShowStepButtons);
        _this.vm.setIsShowStepButtons(options.isAlwaysShowStepButtons);
        _this.mount = _this.mount.bind(_this);
        _this.videoControllerPlayer.ready(function () {
            _this.mount();
        });
        return _this;
    }
    Object.defineProperty(VjsVideoController.prototype, "seek", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (secs) {
            var time = this.videoControllerPlayer.currentTime() + secs;
            if (time < 0) {
                time = 0;
            }
            this.videoControllerPlayer.currentTime(time);
        }
    });
    Object.defineProperty(VjsVideoController.prototype, "forward", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.seek(10);
        }
    });
    Object.defineProperty(VjsVideoController.prototype, "rewind", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.seek(-10);
        }
    });
    Object.defineProperty(VjsVideoController.prototype, "prev", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.videoControllerOptions.onClickPrev();
        }
    });
    Object.defineProperty(VjsVideoController.prototype, "next", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.videoControllerOptions.onClickNext();
        }
    });
    Object.defineProperty(VjsVideoController.prototype, "play", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.videoControllerPlayer.play();
        }
    });
    Object.defineProperty(VjsVideoController.prototype, "pause", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.videoControllerPlayer.pause();
        }
    });
    Object.defineProperty(VjsVideoController.prototype, "paused", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.videoControllerPlayer.paused();
        }
    });
    Object.defineProperty(VjsVideoController.prototype, "showProgressBar", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var controlBar = this.getProgressBar();
            controlBar === null || controlBar === void 0 ? void 0 : controlBar.trigger('showProgressBar');
        }
    });
    Object.defineProperty(VjsVideoController.prototype, "hideProgressBar", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var controlBar = this.getProgressBar();
            controlBar === null || controlBar === void 0 ? void 0 : controlBar.trigger('hideProgressBar');
        }
    });
    Object.defineProperty(VjsVideoController.prototype, "getProgressBar", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.videoControllerPlayer.isDisposed())
                return null;
            return this.videoControllerPlayer.getChild('ControlBar');
        }
    });
    // HACK: iOS Safari でフルスクリーンの解除をした場合に呼び出される
    Object.defineProperty(VjsVideoController.prototype, "setIsPaused", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (paused) {
            var _a;
            (_a = this.ref.current) === null || _a === void 0 ? void 0 : _a.setIsPaused(paused);
        }
    });
    Object.defineProperty(VjsVideoController.prototype, "setHideTimer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            (_a = this.ref.current) === null || _a === void 0 ? void 0 : _a.setHideTimer();
        }
    });
    Object.defineProperty(VjsVideoController.prototype, "show", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            (_a = this.ref.current) === null || _a === void 0 ? void 0 : _a.show();
        }
    });
    Object.defineProperty(VjsVideoController.prototype, "hide", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            (_a = this.ref.current) === null || _a === void 0 ? void 0 : _a.hide();
        }
    });
    Object.defineProperty(VjsVideoController.prototype, "mount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var root = ReactDOM.createRoot(this.el());
            root.render(React.createElement(VideoController, { vm: this.vm, ref: this.ref, play: this.play, pause: this.pause, rewind: this.rewind, forward: this.forward, prev: this.prev, next: this.next, showProgressBar: this.showProgressBar, hideProgressBar: this.hideProgressBar }));
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], VjsVideoController.prototype, "seek", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VjsVideoController.prototype, "forward", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VjsVideoController.prototype, "rewind", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VjsVideoController.prototype, "prev", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VjsVideoController.prototype, "next", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VjsVideoController.prototype, "play", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VjsVideoController.prototype, "pause", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VjsVideoController.prototype, "paused", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VjsVideoController.prototype, "showProgressBar", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VjsVideoController.prototype, "hideProgressBar", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], VjsVideoController.prototype, "setIsPaused", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VjsVideoController.prototype, "setHideTimer", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VjsVideoController.prototype, "show", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VjsVideoController.prototype, "hide", null);
    return VjsVideoController;
}(videojs.getComponent('Component')));
export default VjsVideoController;
var styles = StyleSheet.create({
    wrapper: (_a = {
            position: 'absolute',
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            gap: 48
        },
        _a[MediaBreakPointUp.MD] = {
            gap: 68,
        },
        _a),
    overlay: {
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: 1,
    },
    mask: {
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        background: '#000',
        opacity: 0.4,
        pointerEvents: 'auto',
    },
    button: {
        userSelect: 'none',
        outline: 'none',
    },
    emptyButton: {
        width: 60,
    },
    playButtonImage: (_b = {
            width: 48
        },
        _b[MediaBreakPointUp.MD] = {
            width: 68,
        },
        _b),
    skipButtonImage: (_c = {
            width: 40
        },
        _c[MediaBreakPointUp.MD] = {
            width: 60,
        },
        _c),
});
