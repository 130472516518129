import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '../../styles/variables';
export var StopIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 18,
            height: 18,
        },
    });
    var fill = color || Colors.Alert;
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9 2.45797C5.38694 2.45797 2.45797 5.38694 2.45797 9C2.45797 12.6131 5.38694 15.542 9 15.542C12.6131 15.542 15.542 12.6131 15.542 9C15.542 5.38694 12.6131 2.45797 9 2.45797ZM1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9Z", fill: fill }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.900024 8.9999C0.900024 4.5264 4.52652 0.899902 9.00002 0.899902C13.4735 0.899902 17.1 4.5264 17.1 8.9999C17.1 13.4734 13.4735 17.0999 9.00002 17.0999C4.52652 17.0999 0.900024 13.4734 0.900024 8.9999ZM9.00002 1.0999C4.63697 1.0999 1.10002 4.63685 1.10002 8.9999C1.10002 13.363 4.63697 16.8999 9.00002 16.8999C13.3631 16.8999 16.9 13.363 16.9 8.9999C16.9 4.63685 13.3631 1.0999 9.00002 1.0999ZM9.00002 2.55787C5.44219 2.55787 2.558 5.44207 2.558 8.9999C2.558 12.5577 5.44219 15.4419 9.00002 15.4419C12.5579 15.4419 15.4421 12.5577 15.4421 8.9999C15.4421 5.44207 12.5579 2.55787 9.00002 2.55787ZM2.358 8.9999C2.358 5.33161 5.33173 2.35787 9.00002 2.35787C12.6683 2.35787 15.6421 5.33161 15.6421 8.9999C15.6421 12.6682 12.6683 15.6419 9.00002 15.6419C5.33173 15.6419 2.358 12.6682 2.358 8.9999Z", fill: fill }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.88026 3.88002C4.16495 3.59533 4.62652 3.59533 4.91121 3.88002L14.2282 13.197C14.5129 13.4817 14.5129 13.9433 14.2282 14.228C13.9435 14.5127 13.482 14.5127 13.1973 14.228L3.88026 4.91096C3.59558 4.62627 3.59558 4.16471 3.88026 3.88002Z", fill: fill }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.80945 3.80921C4.13319 3.48547 4.65808 3.48547 4.98182 3.80921L14.2988 13.1262C14.6226 13.45 14.6226 13.9748 14.2988 14.2986C13.9751 14.6223 13.4502 14.6223 13.1265 14.2986L3.80945 4.98157C3.48572 4.65783 3.48572 4.13295 3.80945 3.80921ZM4.8404 3.95063C4.59476 3.705 4.19651 3.705 3.95088 3.95063C3.70524 4.19627 3.70524 4.59452 3.95088 4.84015L13.2679 14.1572C13.5135 14.4028 13.9118 14.4028 14.1574 14.1572C14.403 13.9115 14.403 13.5133 14.1574 13.2676L4.8404 3.95063Z", fill: fill })));
};
