import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
export var InitialGuideHowToDetail = function (_a) {
    var imgSrc = _a.imgSrc, classObject = _a.classObject, imgClassObject = _a.imgClassObject, stepNumber = _a.stepNumber, title = _a.title, children = _a.children;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("img", { className: css(imgClassObject), src: imgSrc }),
        React.createElement("div", { className: css(styles.howToTextBox) },
            stepNumber && (React.createElement("div", { className: css(styles.StepLabel) },
                React.createElement("div", { className: css(styles.StepText) }, "STEP"),
                React.createElement("div", { className: css(styles.StepNumber) }, stepNumber))),
            React.createElement("div", { className: css(styles.title) }, title),
            React.createElement("div", { className: css(styles.description) }, children))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 20,
        paddingBottom: 30,
        gap: 16,
    },
    howToTextBox: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 400,
        width: '100%',
    },
    StepLabel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        color: Colors.PrimaryDark,
        height: 34,
        marginBottom: 4,
    },
    StepText: {
        lineHeight: '140%',
        letterSpacing: '1.12px',
    },
    StepNumber: {
        fontSize: 24,
        lineHeight: '140%',
        letterSpacing: '1.92px',
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        minHeight: 25,
        marginBottom: 16,
        lineHeight: '140%',
        letterSpacing: '1.44px',
    },
    description: {
        lineHeight: '140%',
        letterSpacing: '0.56px',
    },
});
