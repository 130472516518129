import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var MealHistoryEmptyStateIcon = function (_a) {
    var classObject = _a.classObject;
    var styles = StyleSheet.create({
        container: {
            width: 195,
            height: 183,
        },
    });
    return (React.createElement("svg", { className: css(styles.container, classObject), viewBox: "0 0 195 183", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { id: "Group", opacity: "0.8" },
            React.createElement("g", { id: "Layer 2" },
                React.createElement("g", { id: "Group_2" },
                    React.createElement("g", { id: "Group_3" },
                        React.createElement("g", { id: "Vector" },
                            React.createElement("path", { d: "M74.1078 131.161C85.2621 131.161 94.3045 128.7 94.3045 125.666C94.3045 122.631 85.2621 120.171 74.1078 120.171C62.9535 120.171 53.9111 122.631 53.9111 125.666C53.9111 128.7 62.9535 131.161 74.1078 131.161Z", fill: "#DFE0E0" })),
                        React.createElement("path", { id: "Vector_2", d: "M89.0897 120.954C89.0897 120.954 87.5061 124.706 86.2209 126.119C84.3071 128.224 63.4539 128.2 61.7311 126.286C60.0918 124.464 58.8623 121.014 58.8623 121.014L89.0936 120.95L89.0897 120.954Z", fill: "#A3E2E8" }),
                        React.createElement("path", { id: "Vector_3", d: "M40.3008 89.8955C40.3008 89.8955 41.2557 124.336 73.9738 124.336C104.396 124.336 107.647 89.8955 107.647 89.8955H40.3008Z", fill: "#A3E2E8" }),
                        React.createElement("path", { id: "Vector_4", d: "M73.9738 110.371C92.5709 110.371 107.647 101.804 107.647 91.2369C107.647 80.6693 92.5709 72.1025 73.9738 72.1025C55.3767 72.1025 40.3008 80.6693 40.3008 91.2369C40.3008 101.804 55.3767 110.371 73.9738 110.371Z", fill: "#BCDCE3" }),
                        React.createElement("g", { id: "Group_4" },
                            React.createElement("path", { id: "Vector_5", d: "M73.9736 108.481C65.2997 108.481 57.1749 106.579 51.0952 103.122C45.3497 99.8589 42.1865 95.6373 42.1865 91.2327C42.1865 86.8281 45.3497 82.6066 51.0952 79.3439C57.1749 75.8902 65.2997 73.9844 73.9736 73.9844C82.6475 73.9844 90.7723 75.8863 96.852 79.3439C102.597 82.6066 105.761 86.8281 105.761 91.2327C105.761 95.6373 102.597 99.8589 96.852 103.122C90.7723 106.575 82.6475 108.481 73.9736 108.481Z", fill: "#A3E2E8" }),
                            React.createElement("g", { id: "Clip path group" },
                                React.createElement("mask", { id: "mask0_33313_406664", maskUnits: "userSpaceOnUse", x: "42", y: "73", width: "64", height: "36" },
                                    React.createElement("g", { id: "clippath-1" },
                                        React.createElement("path", { id: "Vector_6", d: "M73.9726 108.48C65.2987 108.48 57.1739 106.578 51.0942 103.121C45.3487 99.8579 42.1855 95.6363 42.1855 91.2317C42.1855 86.8271 45.3487 82.6056 51.0942 79.3429C57.1739 75.8893 65.2987 73.9834 73.9726 73.9834C82.6465 73.9834 90.7713 75.8853 96.851 79.3429C102.596 82.6056 105.76 86.8271 105.76 91.2317C105.76 95.6363 102.596 99.8579 96.851 103.121C90.7713 106.574 82.6465 108.48 73.9726 108.48Z", fill: "white" }))),
                                React.createElement("g", { mask: "url(#mask0_33313_406664)" },
                                    React.createElement("g", { id: "Group_5" },
                                        React.createElement("g", { id: "Group_6" },
                                            React.createElement("path", { id: "Vector_7", d: "M105.521 101.136C107.482 102.735 107.248 104.295 104.876 105.529C106.348 107.307 105.688 108.763 103.03 109.623C103.985 111.533 102.943 112.85 100.11 113.327C100.527 115.313 99.1467 116.459 96.2382 116.554C96.1029 118.555 94.4357 119.502 91.5471 119.216C90.8548 121.166 88.9409 121.882 86.1637 121.225C84.9143 123.055 82.8016 123.509 80.2153 122.494C78.4208 124.126 76.1529 124.285 73.8292 122.936C71.5056 124.285 69.2376 124.122 67.4432 122.494C64.8569 123.509 62.7441 123.052 61.4948 121.225C58.7175 121.882 56.8037 121.166 56.1114 119.216C53.2267 119.502 51.5556 118.555 51.4203 116.554C48.5118 116.459 47.1351 115.313 47.5489 113.327C44.712 112.85 43.6735 111.533 44.6284 109.623C41.9705 108.763 41.3101 107.307 42.7822 105.529C40.4148 104.295 40.1761 102.731 42.1377 101.136C40.1761 99.5365 40.4108 97.9768 42.7822 96.7433C41.3101 94.9648 41.9705 93.5085 44.6284 92.6491C43.6735 90.7392 44.716 89.4222 47.5489 88.9448C47.1311 86.9593 48.5118 85.8134 51.4203 85.7179C51.5556 83.7165 53.2227 82.7696 56.1114 83.0561C56.8037 81.1064 58.7175 80.3902 61.4948 81.0467C62.7441 79.2165 64.8569 78.7629 67.4432 79.7775C69.2376 78.1461 71.5056 77.987 73.8292 79.3358C76.1529 77.987 78.4208 78.1501 80.2153 79.7775C82.8016 78.7629 84.9143 79.2204 86.1637 81.0467C88.9409 80.3902 90.8548 81.1064 91.5471 83.0561C94.4318 82.7696 96.1029 83.7165 96.2382 85.7179C99.1467 85.8134 100.523 86.9593 100.11 88.9448C102.947 89.4222 103.985 90.7392 103.03 92.6491C105.688 93.5085 106.348 94.9648 104.876 96.7433C107.244 97.9768 107.482 99.5404 105.521 101.136Z", fill: "white" }),
                                            React.createElement("g", { id: "Clip path group_2" },
                                                React.createElement("mask", { id: "mask1_33313_406664", maskUnits: "userSpaceOnUse", x: "40", y: "78", width: "67", height: "46" },
                                                    React.createElement("g", { id: "clippath-2" },
                                                        React.createElement("path", { id: "Vector_8", d: "M105.521 101.136C107.482 102.735 107.248 104.295 104.876 105.529C106.348 107.307 105.688 108.763 103.03 109.623C103.985 111.533 102.943 112.85 100.11 113.327C100.527 115.313 99.1467 116.459 96.2382 116.554C96.1029 118.555 94.4357 119.502 91.5471 119.216C90.8548 121.166 88.9409 121.882 86.1637 121.225C84.9143 123.055 82.8016 123.509 80.2153 122.494C78.4208 124.126 76.1529 124.285 73.8292 122.936C71.5056 124.285 69.2376 124.122 67.4432 122.494C64.8569 123.509 62.7441 123.052 61.4948 121.225C58.7175 121.882 56.8037 121.166 56.1114 119.216C53.2267 119.502 51.5556 118.555 51.4203 116.554C48.5118 116.459 47.1351 115.313 47.5489 113.327C44.712 112.85 43.6735 111.533 44.6284 109.623C41.9705 108.763 41.3101 107.307 42.7822 105.529C40.4148 104.295 40.1761 102.731 42.1377 101.136C40.1761 99.5365 40.4108 97.9768 42.7822 96.7433C41.3101 94.9648 41.9705 93.5085 44.6284 92.6491C43.6735 90.7392 44.716 89.4222 47.5489 88.9448C47.1311 86.9593 48.5118 85.8134 51.4203 85.7179C51.5556 83.7165 53.2227 82.7696 56.1114 83.0561C56.8037 81.1064 58.7175 80.3902 61.4948 81.0467C62.7441 79.2165 64.8569 78.7629 67.4432 79.7775C69.2376 78.1461 71.5056 77.987 73.8292 79.3358C76.1529 77.987 78.4208 78.1501 80.2153 79.7775C82.8016 78.7629 84.9143 79.2204 86.1637 81.0467C88.9409 80.3902 90.8548 81.1064 91.5471 83.0561C94.4318 82.7696 96.1029 83.7165 96.2382 85.7179C99.1467 85.8134 100.523 86.9593 100.11 88.9448C102.947 89.4222 103.985 90.7392 103.03 92.6491C105.688 93.5085 106.348 94.9648 104.876 96.7433C107.244 97.9768 107.482 99.5404 105.521 101.136Z", fill: "white" }))),
                                                React.createElement("g", { mask: "url(#mask1_33313_406664)" },
                                                    React.createElement("g", { id: "Group_7" },
                                                        React.createElement("path", { id: "Vector_9", d: "M90.0779 88.8644C89.5288 88.2596 88.5659 88.1999 87.8855 88.6098C87.8338 88.0607 87.3762 87.5633 86.8391 87.3723C86.0751 87.0978 85.2993 87.4718 84.6467 87.9135C84.6984 87.2729 84.2847 86.5885 83.6998 86.314C82.6772 85.8325 81.5273 86.5368 80.9663 87.3882C80.827 87.6031 81.1692 87.802 81.3085 87.5872C81.7501 86.9187 82.6732 86.2941 83.4968 86.6561C84.1175 86.9267 84.4955 87.7782 84.0579 88.3551C83.9385 88.5103 84.1732 88.745 84.3284 88.6257C84.9252 88.1761 85.6653 87.5951 86.4651 87.6867C87.042 87.7543 87.7383 88.4029 87.424 89.0116C87.3285 89.1986 87.5911 89.4374 87.7383 89.2543C88.2118 88.6575 89.2025 88.5381 89.7556 89.0991C90.3007 89.6522 90.1853 90.6788 89.5566 91.1204C89.3497 91.2676 89.5487 91.6098 89.7556 91.4626C90.5752 90.8896 90.7662 89.6204 90.0779 88.8644Z", fill: "#D8D8D8" }),
                                                        React.createElement("path", { id: "Vector_10", d: "M67.9533 89.1201C67.3326 88.4835 66.3976 88.1931 65.5262 88.4C64.806 88.5711 64.2092 89.0724 63.871 89.717C63.497 89.2912 62.8564 89.1599 62.2954 89.3191C61.4837 89.5459 61.0022 90.3138 60.6203 91.022C59.8643 90.5167 58.8218 90.5963 58.0857 91.1255C57.1786 91.778 56.8165 92.9199 56.7727 93.9942C56.7608 94.2489 57.1587 94.2489 57.1706 93.9942C57.2064 93.0871 57.4929 92.1083 58.225 91.5154C58.8696 90.9982 59.9001 90.8788 60.5487 91.4517C60.6402 91.5313 60.7993 91.5313 60.863 91.412C61.1893 90.7992 61.5633 90.0631 62.2317 89.7607C62.7171 89.5419 63.497 89.6135 63.7158 90.1745C63.7874 90.3576 64.0302 90.3695 64.0978 90.1745C64.3405 89.4902 64.9254 88.9491 65.6336 88.7819C66.3697 88.6069 67.1536 88.8655 67.6748 89.3987C67.8538 89.5817 68.1363 89.2992 67.9573 89.1162L67.9533 89.1201Z", fill: "#D8D8D8" }),
                                                        React.createElement("path", { id: "Vector_11", d: "M80.3151 93.0159C79.6626 92.8886 79.0021 93.1432 78.5804 93.6406C78.1347 93.2586 77.51 93.1552 76.9331 93.2546C76.2328 93.374 75.64 93.8077 75.0909 94.2414C74.7487 93.7241 74.0723 93.4934 73.4635 93.5889C72.7553 93.7003 72.1983 94.2175 71.8084 94.7984C71.2115 94.2175 70.2447 94.1857 69.5165 94.5836C68.5139 95.1327 68.1518 96.3542 68.0444 97.4125C68.0165 97.6672 68.4184 97.6672 68.4422 97.4125C68.5338 96.5332 68.8202 95.4987 69.612 94.9894C70.2287 94.5955 71.2115 94.5716 71.6651 95.2242C71.7487 95.3435 71.9277 95.3595 72.0073 95.2242C72.3256 94.6791 72.7951 94.1379 73.4397 93.9947C73.9967 93.8674 74.6254 94.0982 74.8561 94.6393C74.9119 94.7706 75.079 94.7507 75.1705 94.6791C76.018 94.0027 77.5061 92.9801 78.461 94.0783C78.5485 94.1777 78.7077 94.1379 78.7753 94.0385C79.0897 93.5491 79.7064 93.2706 80.2793 93.4138C80.8563 93.557 81.2621 94.0623 81.3377 94.6393C81.3735 94.8899 81.7555 94.7825 81.7197 94.5318C81.6202 93.8037 81.0433 93.1552 80.3151 93.0159Z", fill: "#D8D8D8" }),
                                                        React.createElement("path", { id: "Vector_12", d: "M62.2159 102.485C62.4587 101.347 63.8592 100.643 64.9295 101.093C65.2399 101.224 65.4786 101.507 65.8128 101.582C66.1868 101.666 66.4296 101.423 66.72 101.244C67.2452 100.922 68.0291 101.168 68.2558 101.745C68.3474 101.98 68.7333 101.877 68.6378 101.638C68.4389 101.137 67.9932 100.798 67.4601 100.719C67.2054 100.679 66.9428 100.703 66.7041 100.806C66.4097 100.93 66.1391 101.312 65.7969 101.156C65.5264 101.033 65.3115 100.818 65.029 100.707C64.7863 100.611 64.5237 100.564 64.2611 100.564C63.7518 100.56 63.2345 100.715 62.8128 100.997C62.3234 101.32 61.9533 101.805 61.83 102.382C61.7783 102.633 62.1602 102.74 62.212 102.489L62.2159 102.485Z", fill: "#D8D8D8" }),
                                                        React.createElement("path", { id: "Vector_13", d: "M91.3229 101.142C90.9847 100.354 90.165 99.7968 89.3016 99.8008C88.5258 99.8008 87.7857 100.254 87.3997 100.923C86.6438 100.246 85.4979 100.083 84.5588 100.457C84.3082 100.557 84.0734 100.692 83.8665 100.867C83.6397 101.054 83.5005 101.341 83.2737 101.516C83.019 101.715 82.6689 101.333 82.4182 101.241C82.1636 101.15 81.889 101.13 81.6225 101.166C80.9938 101.249 80.4527 101.643 80.21 102.232C80.1741 102.315 80.2338 102.415 80.3015 102.455C80.5999 102.646 80.95 102.303 80.8147 101.997C81.1609 101.619 81.7339 101.448 82.2233 101.591C82.5376 101.683 82.7723 101.989 83.1185 101.981C83.4806 101.973 83.6716 101.647 83.8824 101.404C84.7379 100.402 86.4488 100.406 87.3162 101.384C87.3918 101.472 87.5788 101.46 87.6305 101.345C87.913 100.68 88.5695 100.199 89.3056 100.195C90.0417 100.195 90.6982 100.676 90.9807 101.341C91.0802 101.575 91.4224 101.372 91.3229 101.142Z", fill: "#D8D8D8" }),
                                                        React.createElement("path", { id: "Vector_14", d: "M52.4707 91.4709C51.8619 90.9656 51.0064 91.1208 50.4335 91.6062C49.8088 92.1354 49.6297 92.9948 49.5223 93.7787C48.7823 93.532 47.9387 93.6792 47.3499 94.2163C46.6973 94.8132 46.4944 95.8238 46.8286 96.6315C46.9241 96.8663 47.3101 96.7628 47.2106 96.5241C46.9361 95.8596 47.0554 95.0598 47.5767 94.5466C48.0939 94.0333 48.9295 93.9139 49.578 94.2243C49.7252 94.2959 49.8565 94.2044 49.8764 94.0532C49.9679 93.3609 50.0714 92.5611 50.5648 92.024C50.9507 91.6022 51.6948 91.3476 52.1842 91.7534C52.3791 91.9166 52.6616 91.638 52.4667 91.4709H52.4707Z", fill: "#D8D8D8" }),
                                                        React.createElement("path", { id: "Vector_15", d: "M75.7715 81.58C75.4453 80.8837 74.6853 80.4023 73.9094 80.454C73.241 80.4978 72.6561 80.8917 72.3298 81.4606C71.745 80.8758 70.8537 80.6132 70.05 80.8797C69.1229 81.1901 68.4942 82.1331 68.534 83.1039C68.5459 83.3586 68.9438 83.3586 68.9319 83.1039C68.9001 82.3121 69.3895 81.5203 70.1574 81.2617C70.8895 81.019 71.7489 81.2935 72.2105 81.9142C72.3139 82.0575 72.5129 82.0336 72.5726 81.8665C72.7874 81.2896 73.3643 80.8598 73.985 80.8519C74.6097 80.8399 75.1707 81.2299 75.4334 81.7869C75.5408 82.0177 75.8869 81.8187 75.7755 81.588L75.7715 81.58Z", fill: "#D8D8D8" }),
                                                        React.createElement("path", { id: "Vector_16", d: "M100.715 94.7533C100.265 94.2042 99.4652 94.045 98.8166 94.2877C98.8843 93.6073 98.5341 92.8872 97.9055 92.5848C97.1376 92.2147 96.1707 92.5291 95.7291 93.2493C95.5938 93.4681 95.9399 93.667 96.0712 93.4482C96.4055 92.8991 97.1774 92.6484 97.7583 92.9548C98.3432 93.2612 98.5739 94.0092 98.3352 94.606C98.2715 94.7612 98.4904 94.9323 98.6257 94.8289C99.1509 94.4389 99.9466 94.4747 100.392 94.9801C100.834 95.4854 100.754 96.305 100.281 96.7546C100.094 96.9337 100.376 97.2122 100.563 97.0371C101.18 96.4483 101.26 95.4098 100.715 94.7453V94.7533Z", fill: "#D8D8D8" })))))))))),
                    React.createElement("g", { id: "Group_8" },
                        React.createElement("g", { id: "Vector_17" },
                            React.createElement("path", { d: "M147.276 129.95C158.43 129.95 167.472 127.489 167.472 124.455C167.472 121.42 158.43 118.96 147.276 118.96C136.121 118.96 127.079 121.42 127.079 124.455C127.079 127.489 136.121 129.95 147.276 129.95Z", fill: "#DFE0E0" })),
                        React.createElement("g", { id: "Group_9" },
                            React.createElement("g", { id: "Group_10" },
                                React.createElement("path", { id: "Vector_18", d: "M114.762 88.8018C114.762 88.8018 110.974 122.881 148.081 122.881C185.188 122.881 181.4 88.8018 181.4 88.8018H114.758H114.762Z", fill: "#05394F" }),
                                React.createElement("g", { id: "Group_11" },
                                    React.createElement("path", { id: "Vector_19", d: "M148.081 109.059C166.482 109.059 181.4 100.583 181.4 90.1277C181.4 79.6722 166.482 71.1963 148.081 71.1963C129.679 71.1963 114.762 79.6722 114.762 90.1277C114.762 100.583 129.679 109.059 148.081 109.059Z", fill: "#D0605E" }),
                                    React.createElement("g", { id: "Clip path group_3" },
                                        React.createElement("mask", { id: "mask2_33313_406664", maskUnits: "userSpaceOnUse", x: "114", y: "71", width: "68", height: "39" },
                                            React.createElement("g", { id: "clippath-3" },
                                                React.createElement("path", { id: "Vector_20", d: "M148.082 109.058C166.483 109.058 181.401 100.582 181.401 90.1267C181.401 79.6712 166.483 71.1953 148.082 71.1953C129.68 71.1953 114.763 79.6712 114.763 90.1267C114.763 100.582 129.68 109.058 148.082 109.058Z", fill: "white" }))),
                                        React.createElement("g", { mask: "url(#mask2_33313_406664)" },
                                            React.createElement("g", { id: "Group_12" },
                                                React.createElement("g", { id: "Group_13" },
                                                    React.createElement("path", { id: "Vector_21", d: "M147.938 117.299C165.229 117.299 179.247 108.344 179.247 97.2976C179.247 86.251 165.229 77.2959 147.938 77.2959C130.646 77.2959 116.628 86.251 116.628 97.2976C116.628 108.344 130.646 117.299 147.938 117.299Z", fill: "#D99E15" }),
                                                    React.createElement("g", { id: "Clip path group_4" },
                                                        React.createElement("mask", { id: "mask3_33313_406664", maskUnits: "userSpaceOnUse", x: "116", y: "77", width: "64", height: "41" },
                                                            React.createElement("g", { id: "clippath-4" },
                                                                React.createElement("path", { id: "Vector_22", d: "M147.938 117.299C165.229 117.299 179.247 108.344 179.247 97.2976C179.247 86.251 165.229 77.2959 147.938 77.2959C130.646 77.2959 116.628 86.251 116.628 97.2976C116.628 108.344 130.646 117.299 147.938 117.299Z", fill: "white" }))),
                                                        React.createElement("g", { mask: "url(#mask3_33313_406664)" },
                                                            React.createElement("g", { id: "Group_14" },
                                                                React.createElement("g", { id: "Vector_23", opacity: "0.65" },
                                                                    React.createElement("path", { d: "M176.988 85.9492C176.988 85.9492 178.882 107.176 151.619 109.472C177.366 112.914 187.21 96.8513 187.21 96.8513L176.988 85.9492Z", fill: "#D99E15" })))))))))),
                                React.createElement("path", { id: "Vector_24", d: "M163.038 119.849C163.038 119.849 161.47 123.561 160.197 124.961C158.303 127.042 137.669 127.023 135.966 125.129C134.343 123.326 133.125 120.227 133.125 120.227L163.038 119.849Z", fill: "#05394F" })),
                            React.createElement("path", { id: "Vector_25", d: "M155.749 86.9315C155.021 85.4753 157.162 83.2988 157.969 83.2988C160.269 83.2988 160.592 86.3267 164.021 86.1238C167.451 85.9209 170.678 86.1238 171.084 87.1344C171.486 88.1451 171.486 90.3613 170.682 90.5642C169.874 90.7671 164.427 90.5642 161.2 89.7565C157.973 88.9488 156.358 88.1411 155.753 86.9315H155.749Z", fill: "#E9BC63" }),
                            React.createElement("path", { id: "Vector_26", d: "M143.482 101.414C144.413 102.747 142.611 105.214 141.815 105.33C139.539 105.664 138.779 102.715 135.417 103.416C132.055 104.112 128.832 104.383 128.283 103.444C127.738 102.505 127.416 100.308 128.183 99.9899C128.951 99.6716 134.371 99.0827 137.681 99.409C140.991 99.7352 142.706 100.3 143.482 101.41V101.414Z", fill: "#E9BC63" }),
                            React.createElement("path", { id: "Vector_27", d: "M150.75 78.5043C151.9 79.5865 151.152 82.3001 150.539 82.5747C148.785 83.3585 147.304 80.7046 144.77 82.0574C142.235 83.4102 139.689 84.3254 138.973 83.5415C138.253 82.7577 137.349 80.7324 137.883 80.2709C138.416 79.8133 142.653 78.1382 145.446 77.7762C148.24 77.4141 149.799 77.6011 150.754 78.5003L150.75 78.5043Z", fill: "#E9BC63" }),
                            React.createElement("g", { id: "Group_15" },
                                React.createElement("path", { id: "Vector_28", d: "M123.067 88.5422L130.329 92.9786L132.748 85.1124C132.748 85.1124 127.703 83.497 123.063 88.5422H123.067Z", fill: "#D06623" }),
                                React.createElement("g", { id: "Clip path group_5" },
                                    React.createElement("mask", { id: "mask4_33313_406664", maskUnits: "userSpaceOnUse", x: "123", y: "84", width: "10", height: "9" },
                                        React.createElement("g", { id: "clippath-5" },
                                            React.createElement("path", { id: "Vector_29", d: "M123.067 88.5412L130.329 92.9776L132.748 85.1114C132.748 85.1114 127.703 83.496 123.063 88.5412H123.067Z", fill: "white" }))),
                                    React.createElement("g", { mask: "url(#mask4_33313_406664)" },
                                        React.createElement("g", { id: "Group_16" },
                                            React.createElement("path", { id: "Vector_30", d: "M130.125 87.1309H129.728V89.554H130.125V87.1309Z", fill: "#AD551B" }),
                                            React.createElement("path", { id: "Vector_31", d: "M127.459 88.0155L127.145 88.2598L128.557 90.075L128.871 89.8307L127.459 88.0155Z", fill: "#AD551B" }))))),
                            React.createElement("g", { id: "Group_17" },
                                React.createElement("path", { id: "Vector_32", opacity: "0.68", d: "M151.563 98.3839L151.917 106.095C151.917 106.095 147.265 107.734 144.763 102.713L151.563 98.3799V98.3839Z", fill: "white" }),
                                React.createElement("g", { id: "Clip path group_6" },
                                    React.createElement("mask", { id: "mask5_33313_406664", maskUnits: "userSpaceOnUse", x: "144", y: "98", width: "8", height: "9" },
                                        React.createElement("g", { id: "clippath-6" },
                                            React.createElement("path", { id: "Vector_33", d: "M151.565 98.3839L151.919 106.095C151.919 106.095 147.267 107.734 144.765 102.713L151.565 98.3799V98.3839Z", fill: "white" }))),
                                    React.createElement("g", { mask: "url(#mask5_33313_406664)" },
                                        React.createElement("g", { id: "Group_18" },
                                            React.createElement("path", { id: "Vector_34", d: "M148.587 101.81L146.982 103.274L147.251 103.568L148.855 102.104L148.587 101.81Z", fill: "#C0C0C1" }),
                                            React.createElement("path", { id: "Vector_35", d: "M149.524 103.058L149.191 105.137L149.584 105.2L149.917 103.121L149.524 103.058Z", fill: "#C0C0C1" }))))),
                            React.createElement("g", { id: "Group_19" },
                                React.createElement("path", { id: "Vector_36", d: "M152.844 93.5684L144.429 94.8416L147.636 102.425C147.636 102.425 152.538 100.416 152.844 93.5723V93.5684Z", fill: "#D06623" }),
                                React.createElement("g", { id: "Clip path group_7" },
                                    React.createElement("mask", { id: "mask6_33313_406664", maskUnits: "userSpaceOnUse", x: "144", y: "93", width: "9", height: "10" },
                                        React.createElement("g", { id: "clippath-7" },
                                            React.createElement("path", { id: "Vector_37", d: "M152.843 93.5703L144.428 94.8435L147.635 102.427C147.635 102.427 152.537 100.418 152.843 93.5743V93.5703Z", fill: "white" }))),
                                    React.createElement("g", { mask: "url(#mask6_33313_406664)" },
                                        React.createElement("g", { id: "Group_20" },
                                            React.createElement("path", { id: "Vector_38", d: "M147.089 97.0664L146.784 97.3223L148.34 99.1746L148.645 98.9187L147.089 97.0664Z", fill: "#AD551B" }),
                                            React.createElement("path", { id: "Vector_39", d: "M147.653 95.9274L147.569 96.3164L149.818 96.7985L149.901 96.4094L147.653 95.9274Z", fill: "#AD551B" }))))),
                            React.createElement("g", { id: "Group_21" },
                                React.createElement("path", { id: "Vector_40", opacity: "0.68", d: "M159.016 101.71L164.3 95.4189C164.3 95.4189 168.231 97.1299 167.029 102.609L159.016 101.71Z", fill: "white" }),
                                React.createElement("g", { id: "Clip path group_8" },
                                    React.createElement("mask", { id: "mask7_33313_406664", maskUnits: "userSpaceOnUse", x: "159", y: "95", width: "9", height: "8" },
                                        React.createElement("g", { id: "clippath-8" },
                                            React.createElement("path", { id: "Vector_41", d: "M159.019 101.71L164.302 95.4189C164.302 95.4189 168.234 97.1299 167.032 102.609L159.019 101.71Z", fill: "white" }))),
                                    React.createElement("g", { mask: "url(#mask7_33313_406664)" },
                                        React.createElement("g", { id: "Group_22" },
                                            React.createElement("path", { id: "Vector_42", d: "M165.61 100.407L163.441 100.538L163.465 100.935L165.634 100.804L165.61 100.407Z", fill: "#C0C0C1" }),
                                            React.createElement("path", { id: "Vector_43", d: "M163.539 99.3378L163.272 99.0434L164.836 97.6309L165.103 97.9293L163.539 99.3378Z", fill: "#C0C0C1" }))))),
                            React.createElement("g", { id: "Group_23" },
                                React.createElement("path", { id: "Vector_44", opacity: "0.68", d: "M148.599 83.0254L141.426 87.0241C141.426 87.0241 138.358 84.0241 141.433 79.333L148.599 83.0254Z", fill: "white" }),
                                React.createElement("g", { id: "Clip path group_9" },
                                    React.createElement("mask", { id: "mask8_33313_406664", maskUnits: "userSpaceOnUse", x: "140", y: "79", width: "9", height: "9" },
                                        React.createElement("g", { id: "clippath-9" },
                                            React.createElement("path", { id: "Vector_45", d: "M148.6 83.0264L141.426 87.0251C141.426 87.0251 138.359 84.0251 141.434 79.334L148.6 83.0264Z", fill: "white" }))),
                                    React.createElement("g", { mask: "url(#mask8_33313_406664)" },
                                        React.createElement("g", { id: "Group_24" },
                                            React.createElement("path", { id: "Vector_46", d: "M142.079 81.5186L141.96 81.8984L144.034 82.5459L144.152 82.1661L142.079 81.5186Z", fill: "#C0C0C1" }),
                                            React.createElement("path", { id: "Vector_47", d: "M143.533 83.6221L141.57 84.3818L141.714 84.7529L143.677 83.9931L143.533 83.6221Z", fill: "#C0C0C1" }))))),
                            React.createElement("g", { id: "Group_25" },
                                React.createElement("g", { id: "Group_26" },
                                    React.createElement("path", { id: "Vector_48", d: "M140.911 85.9004C140.915 85.9402 140.923 85.976 140.935 86.0118C141.146 86.7121 142.415 86.9508 143.772 86.541C145.053 86.1551 145.948 85.3354 145.873 84.655C145.889 84.4203 145.582 83.6524 145.538 83.5051C145.328 82.8049 144.058 82.5661 142.701 82.976C141.345 83.3858 140.414 84.281 140.625 84.9813C140.664 85.1086 140.784 85.5901 140.911 85.9004ZM143.103 84.3168C144.329 83.9468 145.399 84.1776 145.534 84.6272C145.67 85.0768 144.906 85.8567 143.676 86.2267C142.451 86.5967 141.381 86.3659 141.245 85.9163C141.11 85.4667 141.874 84.6869 143.103 84.3168Z", fill: "#6BB9C4" }),
                                    React.createElement("path", { id: "Vector_49", d: "M145.574 83.5931C145.57 83.5533 145.562 83.5175 145.55 83.4817C145.339 82.7814 144.069 82.5427 142.713 82.9525C141.432 83.3385 140.536 84.1581 140.612 84.8385C140.596 85.0733 140.902 85.8412 140.946 85.9884C141.157 86.6887 142.426 86.9274 143.783 86.5176C145.14 86.1078 146.067 85.2125 145.86 84.5122C145.82 84.3849 145.701 83.9035 145.574 83.5931ZM143.381 85.1767C142.156 85.5467 141.085 85.316 140.95 84.8663C140.815 84.4167 141.579 83.6369 142.808 83.2669C144.034 82.8968 145.104 83.1276 145.239 83.5772C145.375 84.0268 144.611 84.8067 143.381 85.1767Z", fill: "#509EA2" })),
                                React.createElement("g", { id: "Group_27" },
                                    React.createElement("path", { id: "Vector_50", d: "M148.391 93.4971C148.395 93.5369 148.402 93.5727 148.414 93.6085C148.625 94.3088 149.895 94.5475 151.251 94.1377C152.533 93.7517 153.428 92.9321 153.352 92.2517C153.368 92.017 153.062 91.249 153.018 91.1018C152.807 90.4015 151.538 90.1628 150.181 90.5726C148.824 90.9825 147.897 91.8777 148.104 92.578C148.144 92.7053 148.263 93.1867 148.391 93.4971ZM150.583 91.9135C151.808 91.5435 152.879 91.7742 153.014 92.2239C153.149 92.6735 152.385 93.4533 151.156 93.8234C149.926 94.1934 148.86 93.9626 148.725 93.513C148.589 93.0634 149.353 92.2835 150.583 91.9135Z", fill: "#6BB9C4" }),
                                    React.createElement("path", { id: "Vector_51", d: "M153.05 91.1888C153.046 91.149 153.038 91.1132 153.026 91.0774C152.815 90.3771 151.546 90.1384 150.189 90.5482C148.908 90.9342 148.013 91.7538 148.088 92.4342C148.073 92.669 148.379 93.4369 148.423 93.5841C148.634 94.2844 149.903 94.5231 151.26 94.1133C152.616 93.7035 153.543 92.8082 153.337 92.1079C153.297 91.9806 153.177 91.4992 153.05 91.1888ZM150.858 92.7724C149.632 93.1424 148.562 92.9117 148.427 92.4621C148.291 92.0124 149.055 91.2326 150.285 90.8626C151.51 90.4925 152.581 90.7233 152.716 91.1729C152.851 91.6225 152.087 92.4024 150.858 92.7724Z", fill: "#509EA2" })),
                                React.createElement("g", { id: "Group_28" },
                                    React.createElement("path", { id: "Vector_52", d: "M144.293 86.7819C144.281 86.8177 144.269 86.8535 144.261 86.8933C144.126 87.6134 145.148 88.4052 146.541 88.6678C147.854 88.9145 149.028 88.5962 149.27 87.9556C149.39 87.7527 149.473 86.933 149.501 86.7779C149.636 86.0577 148.614 85.2659 147.221 85.0033C145.829 84.7447 144.591 85.1147 144.456 85.8349C144.432 85.9622 144.317 86.4516 144.289 86.7819H144.293ZM146.967 86.384C148.228 86.6187 149.071 87.311 148.984 87.7766C148.896 88.2381 147.858 88.5803 146.601 88.3455C145.339 88.1108 144.496 87.4145 144.583 86.9529C144.671 86.4914 145.709 86.1492 146.967 86.384Z", fill: "#6BB9C4" }),
                                    React.createElement("path", { id: "Vector_53", d: "M149.493 86.8738C149.505 86.838 149.517 86.8022 149.525 86.7624C149.66 86.0422 148.637 85.2504 147.245 84.9878C145.932 84.7411 144.758 85.0594 144.515 85.7C144.396 85.9029 144.312 86.7226 144.284 86.8778C144.149 87.5979 145.172 88.3897 146.564 88.6523C147.957 88.9149 149.194 88.5409 149.33 87.8207C149.354 87.6934 149.469 87.204 149.497 86.8738H149.493ZM146.819 87.2717C145.558 87.0369 144.714 86.3446 144.802 85.8791C144.889 85.4175 145.928 85.0753 147.185 85.3101C148.446 85.5448 149.29 86.2411 149.202 86.7027C149.115 87.1642 148.076 87.5064 146.819 87.2717Z", fill: "#509EA2" })),
                                React.createElement("g", { id: "Group_29" },
                                    React.createElement("path", { id: "Vector_54", d: "M154.864 95.7682C154.852 95.804 154.84 95.8398 154.832 95.8796C154.697 96.5998 155.72 97.3915 157.112 97.6542C158.425 97.9008 159.599 97.5825 159.842 96.9419C159.961 96.739 160.045 95.9194 160.072 95.7642C160.208 95.044 159.185 94.2522 157.793 93.9896C156.4 93.731 155.163 94.101 155.027 94.8212C155.003 94.9485 154.888 95.4379 154.86 95.7682H154.864ZM157.538 95.3703C158.799 95.605 159.643 96.2974 159.555 96.7629C159.468 97.2244 158.429 97.5666 157.172 97.3319C155.911 97.0971 155.067 96.4008 155.155 95.9393C155.242 95.4777 156.281 95.1355 157.538 95.3703Z", fill: "#6BB9C4" }),
                                    React.createElement("path", { id: "Vector_55", d: "M160.064 95.8601C160.076 95.8243 160.088 95.7885 160.096 95.7487C160.231 95.0285 159.209 94.2367 157.816 93.9741C156.503 93.7274 155.329 94.0458 155.087 94.6863C154.967 94.8893 154.884 95.7089 154.856 95.8641C154.72 96.5843 155.743 97.3761 157.136 97.6387C158.528 97.9013 159.766 97.5272 159.901 96.8071C159.925 96.6798 160.04 96.1904 160.068 95.8601H160.064ZM157.39 96.258C156.129 96.0232 155.285 95.3309 155.373 94.8654C155.461 94.4038 156.499 94.0617 157.756 94.2964C159.018 94.5312 159.861 95.2275 159.774 95.689C159.686 96.1506 158.648 96.4927 157.39 96.258Z", fill: "#509EA2" })),
                                React.createElement("g", { id: "Group_30" },
                                    React.createElement("path", { id: "Vector_56", d: "M169.065 97.248C169.065 97.2878 169.061 97.3236 169.065 97.3634C169.121 98.0916 170.31 98.5969 171.723 98.4894C173.056 98.39 174.11 97.7812 174.178 97.0968C174.242 96.87 174.11 96.0584 174.098 95.8992C174.043 95.1711 172.853 94.6658 171.44 94.7732C170.028 94.8806 168.926 95.557 168.982 96.2891C168.989 96.4204 169.005 96.9218 169.065 97.248ZM171.544 96.1737C172.821 96.0783 173.816 96.5279 173.852 96.9974C173.887 97.4669 172.972 98.0637 171.695 98.1632C170.418 98.2587 169.423 97.8091 169.387 97.3395C169.352 96.87 170.267 96.2732 171.544 96.1737Z", fill: "#6BB9C4" }),
                                    React.createElement("path", { id: "Vector_57", d: "M174.115 95.9941C174.115 95.9543 174.119 95.9185 174.115 95.8787C174.059 95.1506 172.869 94.6453 171.457 94.7527C170.124 94.8522 169.069 95.4609 169.002 96.1453C168.938 96.3721 169.069 97.1838 169.081 97.3429C169.137 98.071 170.327 98.5764 171.739 98.4689C173.152 98.3615 174.254 97.6851 174.198 96.953C174.19 96.8217 174.174 96.3203 174.115 95.9941ZM171.636 97.0684C170.358 97.1639 169.364 96.7143 169.328 96.2448C169.292 95.7752 170.207 95.1784 171.485 95.079C172.762 94.9835 173.756 95.4331 173.792 95.9026C173.828 96.3721 172.913 96.9689 171.636 97.0684Z", fill: "#509EA2" })),
                                React.createElement("g", { id: "Group_31" },
                                    React.createElement("path", { id: "Vector_58", d: "M150.6 84.9264C150.588 84.9622 150.576 84.998 150.568 85.0378C150.432 85.758 151.455 86.5498 152.848 86.8124C154.161 87.059 155.334 86.7407 155.577 86.1001C155.696 85.8972 155.78 85.0776 155.808 84.9224C155.943 84.2022 154.921 83.4104 153.528 83.1478C152.135 82.8892 150.898 83.2592 150.763 83.9794C150.739 84.1067 150.623 84.5961 150.596 84.9264H150.6ZM153.273 84.5285C154.535 84.7632 155.378 85.4556 155.291 85.9211C155.203 86.3826 154.165 86.7248 152.907 86.4901C151.646 86.2553 150.802 85.559 150.89 85.0975C150.978 84.6359 152.016 84.2937 153.273 84.5285Z", fill: "#6BB9C4" }),
                                    React.createElement("path", { id: "Vector_59", d: "M155.8 85.0183C155.812 84.9825 155.824 84.9467 155.832 84.9069C155.968 84.1867 154.945 83.3949 153.552 83.1323C152.239 82.8856 151.066 83.204 150.823 83.8445C150.704 84.0475 150.62 84.8671 150.592 85.0223C150.457 85.7425 151.479 86.5343 152.872 86.7969C154.265 87.0555 155.502 86.6855 155.637 85.9653C155.661 85.838 155.777 85.3486 155.804 85.0183H155.8ZM153.127 85.4162C151.865 85.1814 151.022 84.4891 151.109 84.0236C151.197 83.562 152.235 83.2199 153.493 83.4546C154.754 83.6894 155.597 84.3857 155.51 84.8472C155.422 85.3088 154.384 85.6509 153.127 85.4162Z", fill: "#509EA2" })),
                                React.createElement("g", { id: "Group_32" },
                                    React.createElement("path", { id: "Vector_60", d: "M140.894 96.6558C140.894 96.6955 140.898 96.7314 140.91 96.7711C141.073 97.4834 142.322 97.8056 143.707 97.4913C145.008 97.1929 145.963 96.4369 145.931 95.7525C145.963 95.5178 145.712 94.734 145.677 94.5828C145.513 93.8706 144.264 93.5483 142.879 93.8626C141.499 94.1769 140.512 95.0125 140.671 95.7247C140.699 95.852 140.791 96.3454 140.894 96.6597V96.6558ZM143.19 95.2273C144.439 94.9409 145.49 95.2433 145.593 95.7008C145.696 96.1584 144.881 96.8865 143.631 97.173C142.382 97.4595 141.332 97.1571 141.228 96.6995C141.125 96.242 141.94 95.5138 143.19 95.2273Z", fill: "#6BB9C4" }),
                                    React.createElement("path", { id: "Vector_61", d: "M145.701 94.6698C145.701 94.63 145.697 94.5942 145.685 94.5544C145.522 93.8422 144.273 93.5199 142.888 93.8343C141.587 94.1327 140.632 94.8887 140.664 95.573C140.632 95.8078 140.883 96.5916 140.919 96.7428C141.082 97.455 142.331 97.7773 143.716 97.463C145.096 97.1487 146.083 96.3131 145.924 95.6009C145.896 95.4736 145.805 94.9802 145.701 94.6659V94.6698ZM143.405 96.0982C142.156 96.3847 141.106 96.0823 141.002 95.6248C140.899 95.1672 141.714 94.4391 142.964 94.1526C144.213 93.8661 145.263 94.1685 145.367 94.6261C145.47 95.0836 144.655 95.8118 143.405 96.0982Z", fill: "#509EA2" })),
                                React.createElement("g", { id: "Group_33" },
                                    React.createElement("path", { id: "Vector_62", d: "M124.692 98.4424C124.685 98.4782 124.677 98.518 124.673 98.5538C124.609 99.2819 125.699 99.9742 127.112 100.102C128.445 100.221 129.582 99.7912 129.762 99.1307C129.861 98.9198 129.865 98.0922 129.881 97.9371C129.945 97.2089 128.854 96.5166 127.442 96.3893C126.029 96.262 124.836 96.7514 124.768 97.4795C124.756 97.6108 124.688 98.1042 124.692 98.4384V98.4424ZM127.315 97.7899C128.592 97.9052 129.499 98.514 129.455 98.9835C129.415 99.453 128.413 99.8907 127.135 99.7793C125.858 99.6639 124.951 99.0551 124.995 98.5856C125.035 98.1161 126.037 97.6784 127.315 97.7899Z", fill: "#6BB9C4" }),
                                    React.createElement("path", { id: "Vector_63", d: "M129.878 98.0355C129.886 97.9997 129.894 97.9599 129.898 97.9241C129.961 97.196 128.871 96.5037 127.458 96.3763C126.126 96.257 124.988 96.6867 124.809 97.3472C124.709 97.5581 124.705 98.3857 124.689 98.5408C124.626 99.269 125.716 99.9613 127.128 100.089C128.541 100.216 129.734 99.7265 129.802 98.9984C129.814 98.8671 129.882 98.3737 129.878 98.0395V98.0355ZM127.256 98.6881C125.978 98.5727 125.071 97.9639 125.115 97.4944C125.155 97.0249 126.157 96.5872 127.435 96.6986C128.712 96.814 129.619 97.4228 129.575 97.8923C129.535 98.3618 128.533 98.7995 127.256 98.6881Z", fill: "#509EA2" })),
                                React.createElement("g", { id: "Group_34" },
                                    React.createElement("path", { id: "Vector_64", d: "M134.616 92.8459C134.616 92.8856 134.612 92.9215 134.616 92.9612C134.672 93.6894 135.865 94.1907 137.278 94.0833C138.611 93.9798 139.661 93.3711 139.729 92.6867C139.792 92.4599 139.661 91.6482 139.649 91.4891C139.593 90.7609 138.4 90.2596 136.987 90.367C135.575 90.4784 134.477 91.1548 134.532 91.887C134.544 92.0183 134.556 92.5196 134.616 92.8459ZM137.095 91.7676C138.372 91.6681 139.367 92.1177 139.402 92.5912C139.438 93.0607 138.527 93.6615 137.246 93.757C135.969 93.8565 134.974 93.4029 134.938 92.9334C134.902 92.4639 135.817 91.8631 137.095 91.7676Z", fill: "#6BB9C4" }),
                                    React.createElement("path", { id: "Vector_65", d: "M139.665 91.5839C139.665 91.5442 139.669 91.5084 139.665 91.4686C139.61 90.7404 138.42 90.2391 137.003 90.3465C135.671 90.45 134.62 91.0587 134.552 91.7431C134.489 91.9699 134.62 92.7816 134.632 92.9407C134.688 93.6689 135.877 94.1702 137.294 94.0628C138.706 93.9514 139.805 93.275 139.749 92.5429C139.737 92.4116 139.725 91.9102 139.665 91.5839ZM137.186 92.6622C135.909 92.7617 134.915 92.3121 134.879 91.8426C134.843 91.3731 135.754 90.7723 137.035 90.6768C138.313 90.5773 139.307 91.0309 139.343 91.5004C139.379 91.9699 138.464 92.5707 137.186 92.6662V92.6622Z", fill: "#509EA2" })),
                                React.createElement("g", { id: "Group_35" },
                                    React.createElement("path", { id: "Vector_66", d: "M156.725 90.3137C156.717 90.3495 156.709 90.3893 156.709 90.4291C156.677 91.1612 157.803 91.7978 159.215 91.8575C160.552 91.9132 161.666 91.4318 161.818 90.7633C161.909 90.5445 161.873 89.7209 161.877 89.5657C161.909 88.8336 160.783 88.197 159.371 88.1373C157.954 88.0776 156.784 88.6227 156.752 89.3548C156.748 89.4861 156.705 89.9835 156.725 90.3177V90.3137ZM159.315 89.5378C160.596 89.5896 161.531 90.1546 161.511 90.628C161.491 91.0976 160.512 91.5869 159.235 91.5312C157.954 91.4795 157.019 90.9145 157.039 90.441C157.059 89.9676 158.038 89.4821 159.319 89.5378H159.315Z", fill: "#6BB9C4" }),
                                    React.createElement("path", { id: "Vector_67", d: "M161.885 89.6605C161.893 89.6247 161.901 89.5849 161.901 89.5451C161.933 88.813 160.807 88.1764 159.394 88.1167C158.057 88.061 156.943 88.5424 156.792 89.2109C156.7 89.4297 156.736 90.2533 156.732 90.4085C156.7 91.1406 157.826 91.7772 159.243 91.8369C160.659 91.8966 161.829 91.3515 161.861 90.6194C161.865 90.4881 161.909 89.9907 161.889 89.6565L161.885 89.6605ZM159.295 90.4364C158.013 90.3846 157.078 89.8196 157.098 89.3462C157.118 88.8727 158.097 88.3872 159.374 88.443C160.655 88.4947 161.59 89.0597 161.57 89.5332C161.551 90.0027 160.572 90.4921 159.291 90.4364H159.295Z", fill: "#509EA2" })),
                                React.createElement("g", { id: "Group_36" },
                                    React.createElement("path", { id: "Vector_68", d: "M138.001 87.0114C137.989 87.0472 137.977 87.083 137.973 87.1228C137.854 87.843 138.892 88.6149 140.293 88.8417C141.61 89.0566 142.78 88.7144 143.006 88.0658C143.122 87.8629 143.186 87.0393 143.209 86.8841C143.329 86.1639 142.29 85.392 140.89 85.1652C139.493 84.9384 138.264 85.3363 138.144 86.0605C138.124 86.1918 138.021 86.6812 138.001 87.0114ZM140.663 86.5499C141.928 86.7568 142.788 87.4292 142.712 87.8947C142.636 88.3602 141.606 88.7263 140.341 88.5194C139.075 88.3125 138.216 87.6401 138.292 87.1746C138.367 86.709 139.398 86.343 140.663 86.5499Z", fill: "#6BB9C4" }),
                                    React.createElement("path", { id: "Vector_69", d: "M143.202 86.9793C143.214 86.9435 143.226 86.9077 143.23 86.8679C143.349 86.1477 142.311 85.3758 140.91 85.149C139.593 84.9342 138.423 85.2764 138.197 85.9249C138.081 86.1278 138.018 86.9515 137.994 87.1066C137.874 87.8268 138.913 88.5987 140.313 88.8255C141.714 89.0523 142.939 88.6544 143.059 87.9303C143.079 87.799 143.182 87.3096 143.202 86.9793ZM140.54 87.4409C139.275 87.234 138.415 86.5615 138.491 86.096C138.567 85.6305 139.597 85.2644 140.862 85.4713C142.128 85.6782 142.987 86.3507 142.912 86.8162C142.836 87.2817 141.805 87.6478 140.54 87.4409Z", fill: "#509EA2" }))))),
                    React.createElement("g", { id: "Group_37" },
                        React.createElement("g", { id: "Vector_70" },
                            React.createElement("path", { d: "M188.042 63.6048H99.1861C95.9434 63.6048 93.3889 61.1419 93.6356 58.2572L102.031 22.3162C102.254 19.726 104.673 17.7246 107.582 17.7246H180.076C182.985 17.7246 185.404 19.726 185.627 22.3162L193.596 58.2572C193.843 61.1419 191.289 63.6048 188.046 63.6048H188.042Z", fill: "#DFE0E0" })),
                        React.createElement("g", { id: "Group_38" },
                            React.createElement("g", { id: "Group_39" },
                                React.createElement("path", { id: "Vector_71", d: "M188.448 61.1858H99.5924C96.3496 61.1858 93.7952 58.7229 94.0419 55.8383L102.437 19.8973C102.66 17.307 105.079 15.3057 107.988 15.3057H180.482C183.391 15.3057 185.81 17.307 186.033 19.8973L194.003 55.8383C194.249 58.7229 191.695 61.1858 188.452 61.1858H188.448Z", fill: "white" }),
                                React.createElement("g", { id: "Clip path group_10" },
                                    React.createElement("mask", { id: "mask9_33313_406664", maskUnits: "userSpaceOnUse", x: "94", y: "15", width: "101", height: "47" },
                                        React.createElement("g", { id: "clippath-10" },
                                            React.createElement("path", { id: "Vector_72", d: "M188.449 61.1868H99.5933C96.3506 61.1868 93.7962 58.7239 94.0428 55.8392L102.438 19.8982C102.661 17.308 105.08 15.3066 107.989 15.3066H180.483C183.392 15.3066 185.811 17.308 186.034 19.8982L194.004 55.8392C194.25 58.7239 191.696 61.1868 188.453 61.1868H188.449Z", fill: "white" }))),
                                    React.createElement("g", { mask: "url(#mask9_33313_406664)" },
                                        React.createElement("g", { id: "Group_40" },
                                            React.createElement("path", { id: "Vector_73", d: "M186.008 56.3045H101.951C101.116 56.3045 100.332 55.9305 99.8065 55.2819C99.2813 54.6334 99.0744 53.7899 99.2494 52.9702L106.264 19.7508C106.288 19.6433 106.391 19.5757 106.499 19.5956C106.606 19.6195 106.674 19.7229 106.654 19.8304L99.6394 53.0498C99.4922 53.7501 99.6672 54.4703 100.117 55.0273C100.566 55.5843 101.239 55.9027 101.955 55.9027H186.012C186.729 55.9027 187.397 55.5843 187.851 55.0273C188.304 54.4703 188.475 53.7501 188.328 53.0498L181.313 19.8304C181.289 19.7229 181.361 19.6155 181.468 19.5956C181.572 19.5717 181.683 19.6394 181.703 19.7508L188.718 52.9702C188.889 53.7899 188.686 54.6294 188.161 55.2819C187.636 55.9305 186.852 56.3045 186.016 56.3045H186.008Z", fill: "#DBD9BC" }))))),
                            React.createElement("g", { id: "Vector_74" },
                                React.createElement("path", { d: "M105.799 38.5256C105.381 43.3918 114.023 47.1677 122.896 48.7195C131.769 50.2752 139.643 50.2115 144.795 48.2619C149.948 46.3123 159.672 43.1053 166.99 43.9448C174.307 44.7844 180.927 44.1955 181.799 41.9872C182.666 39.779 182.642 35.5216 180.553 33.5759C178.465 31.6303 172.906 32.1953 170.598 32.617C168.291 33.0348 157.842 32.8995 149.021 28.7138C142.579 25.658 130.416 23.4458 121.885 25.3755C112.073 27.5957 106.431 31.2244 105.799 38.5217V38.5256Z", fill: "#DFE0E0" })),
                            React.createElement("g", { id: "Group_41" },
                                React.createElement("path", { id: "Vector_75", d: "M105.784 36.9788C105.366 41.8449 114.008 45.6208 122.881 47.1726C131.754 48.7243 139.628 48.6647 144.781 46.715C149.933 44.7654 159.658 41.5584 166.975 42.398C174.292 43.2375 180.913 42.6486 181.784 40.4404C182.656 38.2321 182.628 33.9747 180.539 32.0291C178.45 30.0834 172.891 30.6484 170.584 31.0702C168.276 31.4919 157.828 31.3527 149.006 27.1669C142.565 24.1111 130.401 21.8989 121.871 23.8286C112.059 26.0488 106.417 29.6776 105.784 36.9748V36.9788Z", fill: "#DBBAC2" }),
                                React.createElement("g", { id: "Clip path group_11" },
                                    React.createElement("mask", { id: "mask10_33313_406664", maskUnits: "userSpaceOnUse", x: "105", y: "23", width: "78", height: "26" },
                                        React.createElement("g", { id: "clippath-11" },
                                            React.createElement("path", { id: "Vector_76", d: "M105.786 36.9797C105.368 41.8459 114.01 45.6218 122.883 47.1736C131.756 48.7253 139.63 48.6656 144.783 46.716C149.935 44.7664 159.66 41.5594 166.977 42.3989C174.294 43.2385 180.915 42.6496 181.786 40.4413C182.658 38.2331 182.63 33.9757 180.541 32.03C178.452 30.0844 172.893 30.6494 170.586 31.0711C168.278 31.4929 157.829 31.3536 149.008 27.1679C142.567 24.1121 130.403 21.8999 121.873 23.8296C112.061 26.0498 106.419 29.6785 105.786 36.9758V36.9797Z", fill: "white" }))),
                                    React.createElement("g", { mask: "url(#mask10_33313_406664)" },
                                        React.createElement("g", { id: "Group_42" },
                                            React.createElement("path", { id: "Vector_77", d: "M112.191 44.3048C114.002 42.2597 117.117 42.6018 119.512 43.1191C122.314 43.7239 125.011 44.8141 127.454 46.3221C128.831 47.1736 130.128 48.1523 131.318 49.2465C131.505 49.4216 131.25 49.724 131.063 49.5529C129.026 47.6749 126.686 46.151 124.148 45.0369C122.883 44.4838 121.574 44.0342 120.233 43.696C118.959 43.3777 117.642 43.1151 116.325 43.1231C114.925 43.135 113.457 43.4692 112.494 44.5594C112.323 44.7504 112.02 44.4958 112.187 44.3048H112.191Z", fill: "#D48B90" }),
                                            React.createElement("path", { id: "Vector_78", d: "M105.857 39.4649C109.513 36.2381 114.901 36.3813 119.385 37.3482C124.024 38.3509 128.437 40.3562 132.272 43.1573C134.433 44.7369 136.398 46.5672 138.125 48.6123C138.292 48.8073 137.966 49.0381 137.799 48.8431C134.779 45.2701 130.991 42.3576 126.778 40.3204C124.685 39.3058 122.488 38.51 120.228 37.953C117.956 37.392 115.609 37.0458 113.265 37.1652C110.663 37.2965 108.069 38.0405 106.084 39.7912C105.893 39.9623 105.662 39.636 105.853 39.4649H105.857Z", fill: "#D48B90" }),
                                            React.createElement("path", { id: "Vector_79", d: "M106.976 31.9525C109.861 30.6235 113.079 30.3092 116.215 30.6315C119.334 30.9538 122.37 31.8769 125.251 33.0904C127.889 34.2005 130.411 35.5852 132.775 37.1966C135.146 38.816 137.358 40.6701 139.368 42.7232C141.361 44.7604 143.156 46.9925 144.703 49.3838C144.902 49.6902 145.093 49.9966 145.284 50.3069C145.416 50.5258 145.093 50.7565 144.958 50.5377C143.482 48.1265 141.767 45.8586 139.837 43.7896C137.915 41.7245 135.791 39.8505 133.507 38.1993C131.215 36.5401 128.76 35.1117 126.186 33.9379C123.416 32.6766 120.5 31.6819 117.488 31.2044C114.392 30.715 111.154 30.7906 108.189 31.8848C107.823 32.0201 107.465 32.1713 107.111 32.3305C106.876 32.4379 106.741 32.0639 106.972 31.9564L106.976 31.9525Z", fill: "#D48B90" }),
                                            React.createElement("path", { id: "Vector_80", d: "M125.961 24.5917C130.374 26.1713 134.456 28.6263 137.974 31.7218C141.487 34.8174 144.431 38.5416 146.616 42.6836C147.849 45.0231 148.828 47.49 149.524 50.0405C149.592 50.2872 149.218 50.4264 149.15 50.1797C147.921 45.6876 145.808 41.466 143.011 37.7458C140.218 34.0335 136.744 30.8226 132.805 28.3597C130.597 26.979 128.241 25.8291 125.79 24.9538C125.548 24.8663 125.719 24.5082 125.961 24.5957V24.5917Z", fill: "#D48B90" }),
                                            React.createElement("path", { id: "Vector_81", d: "M142.615 27.0992C144.648 29.0528 146.076 31.5038 147.298 34.0185C147.946 35.3514 148.555 36.7082 149.156 38.0649C149.793 39.5013 150.401 40.9576 150.875 42.4576C151.404 44.1208 151.766 45.8436 151.818 47.5943C151.826 47.849 151.428 47.8848 151.42 47.6301C151.324 44.5266 150.25 41.5823 149.029 38.7652C147.891 36.1511 146.745 33.4893 145.249 31.0582C144.429 29.7332 143.486 28.4799 142.36 27.4016C142.177 27.2265 142.432 26.9202 142.615 27.0952V27.0992Z", fill: "#D48B90" }),
                                            React.createElement("path", { id: "Vector_82", d: "M150.993 30.9754C152.7 31.8826 153.706 33.7606 154.518 35.4357C155.433 37.3256 156.07 39.3469 156.42 41.4159C156.619 42.6056 156.718 43.8072 156.718 45.0128C156.718 45.2674 156.32 45.3032 156.32 45.0486C156.316 41.014 155.19 36.8999 152.986 33.5059C152.425 32.6425 151.745 31.8269 150.822 31.3375C150.595 31.2181 150.766 30.856 150.993 30.9794V30.9754Z", fill: "#D48B90" }),
                                            React.createElement("path", { id: "Vector_83", d: "M159.066 31.8545C161.445 34.576 162.822 38.1331 162.953 41.7419C162.989 42.7526 162.929 43.7672 162.766 44.7659C162.726 45.0205 162.333 44.9489 162.376 44.6942C162.933 41.2128 162.237 37.5562 160.474 34.5044C159.977 33.641 159.396 32.8333 158.74 32.0813C158.573 31.8863 158.899 31.6595 159.066 31.8505V31.8545Z", fill: "#D48B90" }),
                                            React.createElement("path", { id: "Vector_84", d: "M166.203 32.9051C167.779 35.4118 169.347 38.1811 169.048 41.2528C168.973 42.0406 168.758 42.8125 168.4 43.5247C168.284 43.7515 167.926 43.5844 168.042 43.3536C169.792 39.8681 167.735 36.0882 165.881 33.1359C165.746 32.9211 166.068 32.6903 166.207 32.9051H166.203Z", fill: "#D48B90" }),
                                            React.createElement("path", { id: "Vector_85", d: "M171.896 32.1879C173.102 33.5606 174.005 35.1879 174.53 36.9346C175.012 38.5301 175.298 40.3644 174.916 42.0116C174.721 42.8432 174.323 43.6151 173.647 44.1523C173.444 44.3114 173.217 43.9852 173.416 43.826C174.634 42.8671 174.761 41.1283 174.654 39.6999C174.522 37.993 174.033 36.3179 173.229 34.8059C172.772 33.9465 172.215 33.1468 171.574 32.4146C171.407 32.2237 171.729 31.9929 171.9 32.1839L171.896 32.1879Z", fill: "#D48B90" }),
                                            React.createElement("path", { id: "Vector_86", d: "M175.367 32.132C176.887 33.3097 178.057 34.9092 178.753 36.6997C179.095 37.579 179.322 38.5101 179.422 39.4531C179.513 40.3443 179.521 41.2794 179.263 42.1428C179.012 42.9823 178.503 43.7781 177.623 44.0327C177.377 44.1044 177.237 43.7303 177.484 43.6587C179.119 43.1852 179.163 40.9252 179.032 39.5605C178.861 37.7979 178.216 36.083 177.201 34.6307C176.617 33.7951 175.916 33.0591 175.109 32.4344C174.906 32.2752 175.16 31.9728 175.363 32.128L175.367 32.132Z", fill: "#D48B90" }),
                                            React.createElement("path", { id: "Vector_87", d: "M160.173 42.5803L151.066 43.1771L149.156 38.4463L149.394 43.5352L141.262 47.8802C141.262 47.8802 149.697 46.9173 160.173 42.5763", fill: "#FFFEEE" }),
                                            React.createElement("path", { id: "Vector_88", d: "M183.524 36.8832C181.606 34.4959 179.306 32.5702 175.538 33.3341C171.77 34.0941 161.704 35.8408 155.807 34.2373C149.914 32.6299 142.605 27.1032 134.46 26.5502C126.316 25.9971 113.941 28.1616 106.131 33.7559C107.527 26.3194 119.488 21.0673 132.089 21.4373C144.69 21.8074 149.866 27.3101 156.778 28.4003C163.689 29.4905 170.143 30.1948 174.985 29.5502C179.827 28.9056 182.553 30.7956 183.317 32.2161C184.081 33.6405 183.52 36.8832 183.52 36.8832H183.524Z", fill: "#808B97" }))))))),
                    React.createElement("g", { id: "Group_43" },
                        React.createElement("g", { id: "Vector_89" },
                            React.createElement("path", { d: "M73.3637 40.0465C79.789 40.0465 84.9978 38.4575 84.9978 36.4974C84.9978 34.5372 79.789 32.9482 73.3637 32.9482C66.9383 32.9482 61.7295 34.5372 61.7295 36.4974C61.7295 38.4575 66.9383 40.0465 73.3637 40.0465Z", fill: "#DFE0E0" })),
                        React.createElement("g", { id: "Group_44" },
                            React.createElement("g", { id: "Group_45" },
                                React.createElement("path", { id: "Vector_90", d: "M53.8418 14.9883C53.8418 14.9883 56.7225 26.5588 65.3924 34.1186C67.3699 35.8454 70.2824 36.0404 73.5451 36.0404C76.8077 36.0404 80.2972 35.7499 82.2389 33.7088C91.5335 23.9288 93.5707 14.9883 93.5707 14.9883H53.8418Z", fill: "#E6B346" }),
                                React.createElement("path", { id: "Vector_91", d: "M73.6778 26.2923C84.7355 26.2923 93.6994 20.4065 93.6994 13.1461C93.6994 5.88573 84.7355 0 73.6778 0C62.6202 0 53.6562 5.88573 53.6562 13.1461C53.6562 20.4065 62.6202 26.2923 73.6778 26.2923Z", fill: "#F1CF4B" }),
                                React.createElement("path", { id: "Vector_92", d: "M82.3983 33.1055C82.3983 33.1055 81.4554 35.6838 80.6914 36.6546C79.5535 38.0989 67.1514 38.087 66.1288 36.77C65.154 35.5167 64.4219 33.3681 64.4219 33.3681L82.3983 33.1055Z", fill: "#E6B346" })),
                            React.createElement("g", { id: "Group_46" },
                                React.createElement("path", { id: "Vector_93", d: "M73.6776 25.0161C63.5036 25.0161 55.2236 19.6924 55.2236 13.1472C55.2236 6.60196 63.5036 1.28223 73.6776 1.28223C83.8515 1.28223 92.1315 6.60593 92.1315 13.1511C92.1315 19.6964 83.8555 25.0201 73.6776 25.0201V25.0161Z", fill: "#E1A343" }),
                                React.createElement("g", { id: "Clip path group_12" },
                                    React.createElement("mask", { id: "mask11_33313_406664", maskUnits: "userSpaceOnUse", x: "55", y: "1", width: "38", height: "25" },
                                        React.createElement("g", { id: "clippath-13" },
                                            React.createElement("path", { id: "Vector_94", d: "M73.6756 25.0171C63.5017 25.0171 55.2217 19.6934 55.2217 13.1481C55.2217 6.60293 63.5017 1.2832 73.6756 1.2832C83.8495 1.2832 92.1295 6.60691 92.1295 13.1521C92.1295 19.6973 83.8535 25.021 73.6756 25.021V25.0171Z", fill: "white" }))),
                                    React.createElement("g", { mask: "url(#mask11_33313_406664)" },
                                        React.createElement("g", { id: "Group_47" },
                                            React.createElement("path", { id: "Vector_95", d: "M59.2902 14.9626C60.9136 11.9467 61.5661 11.5687 64.1683 10.4347C66.7705 9.30072 82.3716 9.67871 84.3212 10.8127C86.2709 11.9427 87.8982 15.3406 87.8982 15.3406C87.8982 15.3406 89.5216 17.9826 88.5468 19.1126C87.5719 20.2426 58.6417 18.7346 57.6629 18.3566C56.6881 17.9786 59.2902 14.9626 59.2902 14.9626Z", fill: "#94B73D" }),
                                            React.createElement("path", { id: "Vector_96", d: "M57.6663 18.3623C55.3904 19.8703 56.043 22.1343 57.0178 22.5122C57.9926 22.8902 89.525 22.5122 89.8513 21.3823C90.1776 20.2523 90.4999 19.8743 88.5502 19.1183C86.6006 18.3623 57.6663 18.3623 57.6663 18.3623Z", fill: "#6D9D41" }),
                                            React.createElement("path", { id: "Vector_97", d: "M88.85 20.998C89.5463 23.6201 89.5463 24.9331 86.7651 25.9199C83.9839 26.9027 63.1267 26.9027 60.3455 25.9199C57.5643 24.9371 55.8255 24.2806 56.5218 22.6413C57.2181 21.002 88.85 21.002 88.85 21.002V20.998Z", fill: "#94B73D" }))))),
                            React.createElement("path", { id: "Vector_98", d: "M59.8779 14.2929C60.813 12.1761 61.1233 11.5674 62.9894 11.5674C64.8555 11.5674 86.3333 13.0793 86.954 13.6841C87.1729 13.895 87.4235 14.1775 87.6663 14.46C88.0084 14.8619 87.6981 15.4667 87.1609 15.4428L59.8779 14.2889V14.2929Z", fill: "#6D9D41" }),
                            React.createElement("g", { id: "Group_48" },
                                React.createElement("path", { id: "Vector_99", opacity: "0.62", d: "M80.9817 11.7577L80.9459 14.523C80.9459 14.523 77.0188 15.832 73.7959 13.214L75.5546 10.1582C75.5546 10.1582 76.1394 12.4858 80.9817 11.7577Z", fill: "#DEBB69" }),
                                React.createElement("path", { id: "Vector_100", opacity: "0.62", d: "M71.924 12.1397L73.7741 13.9024C73.7741 13.9024 70.2966 17.8534 66.2979 17.1212L66.4928 14.5032C66.4928 14.5032 68.7568 15.5536 71.924 12.1357V12.1397Z", fill: "#DEBB69" }),
                                React.createElement("path", { id: "Vector_101", opacity: "0.62", d: "M80.2296 14.542L82.3344 16.054C82.3344 16.054 79.4298 20.3551 75.3236 20.1124L75.1406 17.5182C75.1406 17.5182 77.5558 18.2781 80.2296 14.542Z", fill: "#DEBB69" }),
                                React.createElement("path", { id: "Vector_102", opacity: "0.62", d: "M74.7857 10.4484L76.3972 13.0665C76.3972 13.0665 71.7061 12.0479 69.2114 15.3941L67.7432 13.0665C67.7432 13.0665 69.5018 9.57303 74.7818 10.4484H74.7857Z", fill: "#DEBB69" }),
                                React.createElement("path", { id: "Vector_103", opacity: "0.62", d: "M77.6617 14.0264L77.8567 17.289C77.8567 17.289 74.9561 19.9787 69.9229 18.4429L70.1178 15.3712C70.1178 15.3712 73.4083 17.293 77.6657 14.0264H77.6617Z", fill: "#DEBB69" }),
                                React.createElement("path", { id: "Vector_104", opacity: "0.62", d: "M70.2737 15.8916L70.6477 18.3346C70.6477 18.3346 68.6304 20.5111 64.7033 19.6277L64.6396 17.3081C64.6396 17.3081 67.2697 18.5733 70.2737 15.8916Z", fill: "#DEBB69" })))),
                    React.createElement("g", { id: "Group_49" },
                        React.createElement("g", { id: "Vector_105" },
                            React.createElement("path", { d: "M72.6629 64.793C79.9277 64.793 85.817 62.6482 85.817 60.0025C85.817 57.3567 79.9277 55.2119 72.6629 55.2119C65.3981 55.2119 59.5088 57.3567 59.5088 60.0025C59.5088 62.6482 65.3981 64.793 72.6629 64.793Z", fill: "#DFE0E0" })),
                        React.createElement("g", { id: "Group_50" },
                            React.createElement("g", { id: "Group_51" },
                                React.createElement("path", { id: "Vector_106", d: "M57.5352 53.4131C57.5352 53.4131 60.5631 62.8271 72.9691 62.8271C85.3752 62.8271 89.0079 53.4131 89.0079 53.4131H57.5352Z", fill: "#EDEDED" }),
                                React.createElement("path", { id: "Vector_107", d: "M73.2715 60.1381C81.9625 60.1381 89.0079 56.887 89.0079 52.8766C89.0079 48.8663 81.9625 45.6152 73.2715 45.6152C64.5806 45.6152 57.5352 48.8663 57.5352 52.8766C57.5352 56.887 64.5806 60.1381 73.2715 60.1381Z", fill: "white" })),
                            React.createElement("g", { id: "Vector_108" },
                                React.createElement("path", { d: "M67.4072 55.2709C67.2042 56.8863 68.2149 58.9035 70.634 58.7006C73.0532 58.4977 73.2561 57.69 74.8715 57.8929C76.4869 58.0958 79.109 57.8929 78.7031 56.4804C78.3013 55.0679 67.4072 55.2709 67.4072 55.2709Z", fill: "#DFE0E0" })),
                            React.createElement("g", { id: "Group_52" },
                                React.createElement("path", { id: "Vector_109", d: "M77.0199 57.0269C78.3369 56.5057 79.4152 55.1927 79.6181 53.6449C79.6698 53.239 79.7971 52.0334 79.9603 51.6634C80.1194 51.2974 80.1672 48.3331 78.2613 46.8211C77.5212 46.2323 74.3103 45.3728 73.0888 45.3092C71.2227 45.2097 68.724 46.4909 67.8646 47.2151C66.3725 48.4724 65.6722 50.7841 66.1775 52.7894C66.7545 55.0773 67.6577 56.6091 69.6073 57.5839C70.3792 57.9699 71.3063 58.2802 72.0424 57.8187C72.3487 57.6277 73.5862 57.3651 73.9084 57.2179C74.3819 57.003 74.9111 57.1781 75.4164 57.2418C75.9615 57.3134 76.5106 57.2298 77.0239 57.0269H77.0199Z", fill: "#D0605E" }),
                                React.createElement("path", { id: "Vector_110", d: "M69.9488 48.4371C70.0522 49.5551 69.6623 50.6732 68.8785 51.4769C68.6994 51.6599 68.9819 51.9424 69.161 51.7594C70.0125 50.8881 70.4621 49.6546 70.3507 48.4371C70.3268 48.1824 69.9289 48.1824 69.9528 48.4371H69.9488Z", fill: "#97403F" }),
                                React.createElement("path", { id: "Vector_111", d: "M74.3447 47.5694C74.0821 48.8228 74.8261 50.1517 76.0277 50.5894C76.2704 50.6769 76.3739 50.291 76.1351 50.2074C75.1444 49.8493 74.5118 48.7074 74.7306 47.6769C74.7823 47.4262 74.4004 47.3188 74.3486 47.5694H74.3447Z", fill: "#97403F" }),
                                React.createElement("path", { id: "Vector_112", d: "M74.7129 53.3722C73.1731 53.4199 71.689 54.1321 70.7102 55.3218C70.5471 55.5168 70.8295 55.7993 70.9927 55.6043C71.9078 54.4942 73.2765 53.8138 74.7169 53.7701C74.9715 53.7621 74.9715 53.3642 74.7169 53.3722H74.7129Z", fill: "#97403F" }),
                                React.createElement("path", { id: "Vector_113", d: "M77.943 52.6623C77.6048 53.2194 77.3939 53.8401 77.3144 54.4846C77.3024 54.5921 77.4138 54.6836 77.5133 54.6836C77.6327 54.6836 77.7003 54.5921 77.7122 54.4846C77.7839 53.9117 77.9828 53.3546 78.2852 52.8613C78.4205 52.6424 78.0743 52.4435 77.943 52.6623Z", fill: "#97403F" })))),
                    React.createElement("g", { id: "Group_53" },
                        React.createElement("g", { id: "Vector_114" },
                            React.createElement("path", { d: "M34.3314 61.3815C40.5788 61.3815 45.6433 59.7925 45.6433 57.8323C45.6433 55.8722 40.5788 54.2832 34.3314 54.2832C28.084 54.2832 23.0195 55.8722 23.0195 57.8323C23.0195 59.7925 28.084 61.3815 34.3314 61.3815Z", fill: "#DFE0E0" })),
                        React.createElement("g", { id: "Group_54" },
                            React.createElement("path", { id: "Vector_115", d: "M13.3602 33.2637C13.3602 33.2637 10.9211 56.8066 34.8141 56.8066C58.7072 56.8066 56.2681 33.2637 56.2681 33.2637H13.3602Z", fill: "#1B818C" }),
                            React.createElement("g", { id: "Group_55" },
                                React.createElement("path", { id: "Vector_116", d: "M34.8182 47.2576C46.667 47.2576 56.2722 41.4021 56.2722 34.1791C56.2722 26.956 46.667 21.1006 34.8182 21.1006C22.9695 21.1006 13.3643 26.956 13.3643 34.1791C13.3643 41.4021 22.9695 47.2576 34.8182 47.2576Z", fill: "#6BB9C4" }),
                                React.createElement("g", { id: "Clip path group_13" },
                                    React.createElement("mask", { id: "mask12_33313_406664", maskUnits: "userSpaceOnUse", x: "13", y: "21", width: "44", height: "27" },
                                        React.createElement("g", { id: "clippath-14" },
                                            React.createElement("path", { id: "Vector_117", d: "M34.8202 47.2576C46.6689 47.2576 56.2742 41.4021 56.2742 34.1791C56.2742 26.956 46.6689 21.1006 34.8202 21.1006C22.9715 21.1006 13.3662 26.956 13.3662 34.1791C13.3662 41.4021 22.9715 47.2576 34.8202 47.2576Z", fill: "white" }))),
                                    React.createElement("g", { mask: "url(#mask12_33313_406664)" },
                                        React.createElement("g", { id: "Group_56" },
                                            React.createElement("g", { id: "Group_57" },
                                                React.createElement("path", { id: "Vector_118", d: "M34.704 45.6812C23.973 45.6812 14.9092 40.3814 14.9092 34.1067C14.9092 27.8321 23.973 22.5322 34.704 22.5322C45.435 22.5322 54.4988 27.8321 54.4988 34.1067C54.4988 40.3814 45.435 45.6812 34.704 45.6812Z", fill: "#1B818C" }),
                                                React.createElement("g", { id: "Clip path group_14" },
                                                    React.createElement("mask", { id: "mask13_33313_406664", maskUnits: "userSpaceOnUse", x: "14", y: "22", width: "41", height: "24" },
                                                        React.createElement("g", { id: "clippath-15" },
                                                            React.createElement("path", { id: "Vector_119", d: "M34.704 45.6832C23.973 45.6832 14.9092 40.3833 14.9092 34.1087C14.9092 27.834 23.973 22.5342 34.704 22.5342C45.435 22.5342 54.4988 27.834 54.4988 34.1087C54.4988 40.3833 45.435 45.6832 34.704 45.6832Z", fill: "white" }))),
                                                    React.createElement("g", { mask: "url(#mask13_33313_406664)" },
                                                        React.createElement("g", { id: "Group_58" },
                                                            React.createElement("path", { id: "Vector_120", d: "M18.4113 42.0463C17.8701 41.1789 17.9537 39.9694 18.6022 39.1816C19.2548 38.3938 20.4246 38.0874 21.3795 38.4534C21.5585 36.4202 23.914 34.8963 25.8438 35.5648C26.182 34.781 26.556 33.9573 27.2523 33.464C27.9525 32.9746 29.0825 32.9944 29.5202 33.7266C29.7987 32.8631 30.6423 32.2106 31.5494 32.1589C32.4566 32.1072 33.3678 32.6562 33.7458 33.4838C34.1078 32.6483 35.019 32.0912 35.9262 32.143C36.8333 32.1947 37.6729 32.8631 37.9315 33.7345C39.157 32.2783 41.9621 32.8512 42.5152 34.6735C43.1796 34.1801 44.1584 34.1642 44.8348 34.6377C45.5112 35.1072 45.8415 36.0303 45.6107 36.8261C46.4781 36.2571 47.7155 36.3327 48.5033 37.0091C49.2911 37.6815 49.5617 38.8951 49.132 39.8381C49.8044 39.3726 50.8429 39.5755 51.2885 40.2598C51.7342 40.9442 51.4954 41.9787 50.7951 42.4005C50.7951 42.4005 44.6876 47.6963 34.824 47.2586C24.9604 46.821 18.4152 42.0424 18.4152 42.0424L18.4113 42.0463Z", fill: "#D07C6D" }),
                                                            React.createElement("g", { id: "Group_59", opacity: "0.55" },
                                                                React.createElement("path", { id: "Vector_121", d: "M31.2043 34.8548C29.8714 36.7766 28.7812 38.8575 27.9615 41.0459C27.9178 41.1653 28.1088 41.217 28.1525 41.0976C28.9682 38.9252 30.0504 36.8601 31.3754 34.9543C31.447 34.8468 31.2759 34.7474 31.2043 34.8548Z", fill: "white" }),
                                                                React.createElement("path", { id: "Vector_122", d: "M30.2619 34.1812C28.929 36.103 27.8388 38.1839 27.0192 40.3723C26.9754 40.4916 27.1664 40.5434 27.2102 40.424C28.0258 38.2516 29.1081 36.1865 30.433 34.2807C30.5046 34.1772 30.3336 34.0738 30.2619 34.1812Z", fill: "white" }),
                                                                React.createElement("path", { id: "Vector_123", d: "M37.482 32.4949C35.9979 34.6076 33.7936 36.1713 31.3108 36.8756C31.1875 36.9114 31.2392 37.1024 31.3626 37.0666C33.8971 36.3464 36.1372 34.7509 37.6531 32.5943C37.7287 32.4909 37.5537 32.3914 37.482 32.4949Z", fill: "white" }),
                                                                React.createElement("path", { id: "Vector_124", d: "M39.6838 38.1485C37.9729 39.7878 35.6612 40.7228 33.2937 40.7308C33.1664 40.7308 33.1664 40.9297 33.2937 40.9297C35.7129 40.9218 38.0803 39.9629 39.827 38.2917C39.9185 38.2042 39.7793 38.061 39.6878 38.1525L39.6838 38.1485Z", fill: "white" }),
                                                                React.createElement("path", { id: "Vector_125", d: "M44.8437 38.0651C43.272 39.6367 42.0466 41.5187 41.2428 43.5877C41.1951 43.7071 41.39 43.7588 41.4338 43.6394C42.2256 41.6023 43.4392 39.7481 44.9829 38.2043C45.0745 38.1128 44.9312 37.9736 44.8437 38.0651Z", fill: "white" }),
                                                                React.createElement("path", { id: "Vector_126", d: "M43.7685 37.527C42.1968 39.0986 40.9714 40.9806 40.1676 43.0496C40.1199 43.169 40.3148 43.2207 40.3586 43.1014C41.1504 41.0642 42.364 39.21 43.9078 37.6662C43.9993 37.5747 43.856 37.4355 43.7685 37.527Z", fill: "white" }),
                                                                React.createElement("path", { id: "Vector_127", d: "M22.5303 37.9382C22.2517 40.3295 23.1549 42.7884 24.9056 44.4357C24.9972 44.5232 25.1404 44.3839 25.0449 44.2964C23.326 42.681 22.4547 40.2817 22.7292 37.9422C22.7451 37.8148 22.5462 37.8148 22.5303 37.9422V37.9382Z", fill: "white" })),
                                                            React.createElement("path", { id: "Vector_128", d: "M27.6342 37.0811C27.857 38.3145 26.7708 39.544 25.2111 39.8265C23.6474 40.109 22.1991 39.3411 21.9762 38.1076C21.7534 36.8742 22.8397 35.6447 24.3994 35.3622C25.9631 35.0797 27.4074 35.8476 27.6342 37.0811Z", fill: "#DBA581" }),
                                                            React.createElement("path", { id: "Vector_129", d: "M34.0872 33.0469C34.31 34.2803 33.2238 35.5098 31.6641 35.7923C30.1004 36.0748 28.6561 35.3069 28.4293 34.0734C28.2025 32.84 29.2927 31.6105 30.8524 31.328C32.4121 31.0455 33.8604 31.8134 34.0872 33.0469Z", fill: "#DBA581" }),
                                                            React.createElement("path", { id: "Vector_130", d: "M35.3092 39.27C35.1063 40.5074 33.6739 41.3032 32.1062 41.0446C30.5385 40.7899 29.4324 39.5764 29.6353 38.339C29.8383 37.1015 31.2706 36.3058 32.8383 36.5644C34.406 36.823 35.5121 38.0326 35.3092 39.27Z", fill: "#DBA581" }),
                                                            React.createElement("path", { id: "Vector_131", d: "M41.1578 36.8462C40.9549 38.0836 39.5225 38.8794 37.9548 38.6208C36.3872 38.3661 35.281 37.1526 35.484 35.9151C35.6869 34.6777 37.1193 33.8819 38.6869 34.1406C40.2546 34.3952 41.3607 35.6088 41.1578 36.8462Z", fill: "#DBA581" }),
                                                            React.createElement("path", { id: "Vector_132", d: "M41.3609 42.0942C41.158 43.3317 39.7256 44.1274 38.158 43.8688C36.5903 43.6142 35.4842 42.4006 35.6871 41.1632C35.89 39.9258 37.3224 39.13 38.8901 39.3886C40.4577 39.6433 41.5639 40.8568 41.3609 42.0942Z", fill: "#DBA581" }),
                                                            React.createElement("path", { id: "Vector_133", d: "M43.108 45.7231C42.9051 46.9606 41.4727 47.7563 39.905 47.4977C38.3374 47.2431 37.2312 46.0295 37.4342 44.7921C37.6371 43.5547 39.0695 42.7589 40.6371 43.0175C42.2048 43.2761 43.3109 44.4857 43.108 45.7231Z", fill: "#DBA581" }),
                                                            React.createElement("path", { id: "Vector_134", d: "M47.9774 35.9271C48.2639 37.1487 47.2413 38.4298 45.6975 38.7919C44.1537 39.154 42.6656 38.4617 42.3792 37.2402C42.0927 36.0187 43.1152 34.7375 44.659 34.3754C46.2028 34.0133 47.6909 34.7056 47.9774 35.9271Z", fill: "#DBA581" }),
                                                            React.createElement("path", { id: "Vector_135", d: "M42.6864 31.845C42.9729 33.0666 41.9503 34.3477 40.4065 34.7138C38.8627 35.0759 37.3746 34.3836 37.0881 33.162C36.8017 31.9405 37.8242 30.6593 39.368 30.2973C40.9118 29.9352 42.3999 30.6275 42.6864 31.849V31.845Z", fill: "#DBA581" }),
                                                            React.createElement("path", { id: "Vector_136", d: "M30.2284 41.1762C30.5149 42.3977 29.4923 43.6789 27.9485 44.0409C26.4047 44.403 24.9166 43.7107 24.6301 42.4892C24.3437 41.2677 25.3662 39.9865 26.91 39.6244C28.4538 39.2623 29.9419 39.9547 30.2284 41.1762Z", fill: "#DBA581" }),
                                                            React.createElement("path", { id: "Vector_137", d: "M21.8974 44.8457C23.444 44.4824 24.4656 43.1993 24.1792 41.98C23.8927 40.7606 22.4067 40.0667 20.86 40.43C19.3134 40.7933 18.2918 42.0764 18.5782 43.2957C18.8647 44.5151 20.3507 45.209 21.8974 44.8457Z", fill: "#DBA581" }),
                                                            React.createElement("path", { id: "Vector_138", d: "M36.121 44.6876C35.93 45.925 34.5016 46.7367 32.934 46.494C31.3663 46.2512 30.2482 45.0536 30.4392 43.8122C30.6302 42.5748 32.0586 41.7631 33.6263 42.0058C35.1939 42.2485 36.312 43.4461 36.121 44.6876Z", fill: "#DBA581" }),
                                                            React.createElement("path", { id: "Vector_139", d: "M47.6191 42.4698C47.4281 43.7072 45.9997 44.5189 44.432 44.2762C42.8643 44.0335 41.7463 42.8358 41.9373 41.5944C42.1282 40.357 43.5567 39.5453 45.1243 39.788C46.692 40.0307 47.81 41.2284 47.6191 42.4698Z", fill: "#DBA581" }),
                                                            React.createElement("path", { id: "Vector_140", d: "M28.6562 34.3985C28.4652 35.6359 27.0368 36.4476 25.4691 36.2049C23.9014 35.9622 22.7834 34.7645 22.9744 33.5231C23.1654 32.2857 24.5938 31.474 26.1614 31.7167C27.7291 31.9595 28.8472 33.1571 28.6562 34.3985Z", fill: "#DBA581" }))))))))),
                            React.createElement("path", { id: "Vector_141", d: "M44.435 54.6904C44.435 54.6904 43.4284 57.0777 42.6087 57.977C41.3912 59.3178 28.1177 59.3019 27.0236 58.0844C25.9811 56.9265 25.1973 54.9331 25.1973 54.9331L44.439 54.6904H44.435Z", fill: "#1B818C" }))),
                    React.createElement("g", { id: "Group_60" },
                        React.createElement("g", { id: "Group_61" },
                            React.createElement("path", { id: "Vector_142", d: "M56.8408 146.18C56.8448 145.416 57.4575 144.791 76.9937 144.807L154.108 142.786C155.417 142.75 156.495 143.809 156.487 145.118C156.479 146.391 155.445 147.417 154.176 147.421L58.2653 147.608C57.4774 147.608 56.8368 146.968 56.8448 146.18H56.8408Z", fill: "#DFE0E0" }),
                            React.createElement("path", { id: "Vector_143", d: "M56.8253 148.909C56.8213 149.673 57.4221 150.305 58.186 150.333L154.057 153.441C155.358 153.484 156.44 152.442 156.448 151.141V151.129C156.456 149.856 155.437 148.813 154.16 148.797L58.2616 147.504C57.4738 147.492 56.8253 148.125 56.8213 148.913L56.8253 148.909Z", fill: "#DFE0E0" })),
                        React.createElement("g", { id: "Group_62" },
                            React.createElement("path", { id: "Vector_144", d: "M61.3063 140.622H68.6911L71.5638 151.289V154.162H59.7188V152.523L61.3063 140.622Z", fill: "white" }),
                            React.createElement("g", { id: "Group_63" },
                                React.createElement("path", { id: "Vector_145", d: "M56.8408 144.97C56.8448 144.206 57.4575 143.581 76.9937 143.597L154.108 141.576C155.417 141.54 156.495 142.599 156.487 143.908C156.479 145.181 155.445 146.207 154.176 146.211L58.2653 146.398C57.4774 146.398 56.8368 145.758 56.8448 144.97H56.8408Z", fill: "#126880" }),
                                React.createElement("path", { id: "Vector_146", d: "M56.8253 147.7C56.8213 148.464 57.4221 149.096 58.186 149.124L154.057 152.232C155.358 152.275 156.44 151.233 156.448 149.932V149.92C156.456 148.647 155.437 147.604 154.16 147.588L58.2616 146.295C57.4738 146.283 56.8253 146.916 56.8213 147.704L56.8253 147.7Z", fill: "#126880" })))),
                    React.createElement("g", { id: "Group_64" },
                        React.createElement("g", { id: "Group_65" },
                            React.createElement("path", { id: "Vector_147", d: "M41.1295 139.363L33.6689 133.806L28.307 141.005L35.7675 146.561L41.1295 139.363Z", fill: "#EEC76C" }),
                            React.createElement("g", { id: "Vector_148" },
                                React.createElement("path", { d: "M41.1268 139.361L35.7633 146.562L33.8057 145.102L39.1692 137.904L41.1268 139.361Z", fill: "#EEC76C" })),
                            React.createElement("path", { id: "Vector_149", d: "M34.8078 145.775L29.3321 141.696C27.6914 140.474 25.3706 140.813 24.1486 142.454L3.19278 170.589C1.97071 172.23 2.3101 174.551 3.95082 175.773L9.42654 179.851C11.0673 181.073 13.388 180.734 14.6101 179.093L35.5659 150.958C36.788 149.317 36.4486 146.997 34.8078 145.775Z", fill: "#126880" }),
                            React.createElement("path", { id: "Vector_150", d: "M30.5275 142.587C29.4015 141.787 27.8339 142.034 27.0023 143.148L4.67692 173.125C3.84136 174.243 4.06418 175.818 5.16234 176.67L3.02968 175.082C1.8957 174.239 1.66493 172.639 2.50447 171.509L24.8378 141.529C25.6813 140.399 27.2808 140.164 28.4108 141.007L30.5275 142.587Z", fill: "#1B818C" }),
                            React.createElement("g", { id: "Vector_151" },
                                React.createElement("path", { d: "M35.7317 146.459L30.5273 142.584C30.5273 142.584 30.5632 142.608 30.5791 142.62L35.7317 146.459Z", fill: "#1B273A" })),
                            React.createElement("path", { id: "Vector_152", d: "M36.2524 150.03L13.9191 180.011C13.0755 181.141 11.476 181.376 10.3461 180.532L7.81152 178.646C8.93754 179.454 10.5132 179.211 11.3447 178.093L33.6781 148.113C34.5136 146.994 34.2908 145.419 33.1926 144.567L35.7311 146.457C36.8611 147.301 37.0959 148.9 36.2524 150.03Z", fill: "#05394F" })),
                        React.createElement("g", { id: "Group_66" },
                            React.createElement("g", { id: "Vector_153", opacity: "0.2" },
                                React.createElement("path", { d: "M88.5565 132.012C103.577 116.991 103.577 92.6376 88.5565 77.6166C73.5355 62.5956 49.1817 62.5956 34.1608 77.6166C19.1398 92.6376 19.1398 116.991 34.1608 132.012C49.1817 147.033 73.5355 147.033 88.5565 132.012Z", fill: "white" })),
                            React.createElement("path", { id: "Vector_154", d: "M61.3597 67.2861C82.0855 67.2861 98.8882 84.0888 98.8882 104.815C98.8882 125.541 82.0855 142.343 61.3597 142.343C40.6338 142.343 23.8311 125.541 23.8311 104.815C23.8311 84.0888 40.6338 67.2861 61.3597 67.2861ZM61.3597 60.8643C55.4272 60.8643 49.6738 62.0261 44.2506 64.3219C39.0144 66.5341 34.3154 69.7053 30.2808 73.7398C26.2463 77.7744 23.0791 82.4734 20.8629 87.7096C18.5711 93.1328 17.4053 98.8862 17.4053 104.819C17.4053 110.751 18.5671 116.505 20.8629 121.928C23.0751 127.164 26.2463 131.863 30.2808 135.897C34.3154 139.932 39.0144 143.099 44.2506 145.315C49.6738 147.607 55.4272 148.773 61.3597 148.773C67.2922 148.773 73.0456 147.611 78.4688 145.315C83.7049 143.103 88.404 139.932 92.4385 135.897C96.4731 131.863 99.6402 127.164 101.856 121.928C104.148 116.505 105.314 110.751 105.314 104.819C105.314 98.8862 104.152 93.1328 101.856 87.7096C99.6442 82.4734 96.4731 77.7744 92.4385 73.7398C88.404 69.7053 83.7049 66.5381 78.4688 64.3219C73.0456 62.0301 67.2922 60.8643 61.3597 60.8643Z", fill: "#EEC76C" }),
                            React.createElement("g", { id: "Group_67", opacity: "0.49" },
                                React.createElement("path", { id: "Vector_155", d: "M94.0619 107.56C92.5459 107.56 91.3165 106.331 91.3165 104.815C91.3165 88.2946 77.8759 74.858 61.3597 74.858C59.8437 74.858 58.6143 73.6285 58.6143 72.1126C58.6143 70.5967 59.8437 69.3672 61.3597 69.3672C80.9038 69.3672 96.8073 85.2667 96.8073 104.815C96.8073 106.331 95.5778 107.56 94.0619 107.56Z", fill: "white" })),
                            React.createElement("g", { id: "Group_68", opacity: "0.49" },
                                React.createElement("path", { id: "Vector_156", d: "M72.886 134.199C72.4563 132.747 70.9324 131.915 69.4761 132.345C53.6323 137.02 36.941 127.928 32.2659 112.085C31.8362 110.632 30.3123 109.801 28.856 110.23C27.4037 110.66 26.5721 112.184 27.0019 113.64C32.5325 132.389 52.2795 143.139 71.0279 137.609C72.4802 137.179 73.3118 135.655 72.882 134.199H72.886Z", fill: "white" })),
                            React.createElement("g", { id: "Vector_157" },
                                React.createElement("path", { d: "M61.3601 66.3506C40.117 66.3506 22.8965 83.5711 22.8965 104.814C22.8965 126.057 40.117 143.278 61.3601 143.278C82.6032 143.278 99.8237 126.057 99.8237 104.814C99.8237 83.5711 82.6032 66.3506 61.3601 66.3506ZM61.3601 142.343C40.6342 142.343 23.8315 125.54 23.8315 104.814C23.8315 84.0883 40.6342 67.2856 61.3601 67.2856C82.0859 67.2856 98.8886 84.0883 98.8886 104.814C98.8886 125.54 82.0859 142.343 61.3601 142.343Z", fill: "#F7C561" })))))))));
};
