import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../styles';
import { Task } from '@lean-body/src/domain';
import { TimeSelectForm, TimeSelectFormat } from '../molecules';
import { RecordMealActionType } from './record_meal';
import { RecordMealConfirmImageList } from './record_meal_confirm_image_list';
import { PlusIcon } from '../atoms/icons/plus_icon';
import { MealMenuConfirmListItem } from '../molecules/meal_menu_confirm_list_item';
import { MealHeader } from '../atoms/meal_header';
import { MealRecordSheet } from '../molecules/meal_record_sheet';
export var RecordMealConfirmForm = function (_a) {
    var headerTitle = _a.headerTitle, selectedMenuMap = _a.selectedMenuMap, selectedAnalysisMenuMap = _a.selectedAnalysisMenuMap, imageMap = _a.imageMap, time = _a.time, setAction = _a.setAction, onTimeChange = _a.onTimeChange, onClickBack = _a.onClickBack, onClickDeleteMenu = _a.onClickDeleteMenu, onClickMenu = _a.onClickMenu, onClickDeleteAnalysisMenu = _a.onClickDeleteAnalysisMenu, onDeleteImage = _a.onDeleteImage, onSaveMeal = _a.onSaveMeal, isDisabledSaveButton = _a.isDisabledSaveButton, classObject = _a.classObject;
    var allSelectedMenus = new Map();
    var onClickDeselectMenu = function (menuKey) {
        allSelectedMenus.delete(menuKey);
        onClickDeleteMenu(menuKey);
    };
    var onClickDeselectAnalysisMenu = function (index) {
        allSelectedMenus.delete(index);
        onClickDeleteAnalysisMenu(index);
    };
    Array.from(selectedMenuMap.entries()).forEach(function (_a) {
        var k = _a[0], v = _a[1];
        allSelectedMenus.set(k, { menu: v, onClickDeselect: onClickDeselectMenu });
    });
    Array.from(selectedAnalysisMenuMap.entries()).forEach(function (_a) {
        var i = _a[0], v = _a[1];
        allSelectedMenus.set(i, { menu: v, onClickDeselect: onClickDeselectAnalysisMenu });
    });
    var sumSelectedMenuCalories = function () {
        return Array.from(allSelectedMenus.values()).reduce(function (calories, v) { return calories + v.menu.caloriesForAmount(v.menu.amountPercentage); }, 0);
    };
    var onClickAddMenu = function () {
        setAction(RecordMealActionType.select);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MealHeader, { onClickBack: onClickBack }, headerTitle),
        React.createElement("div", { className: css(styles.container, classObject) },
            React.createElement("div", { className: css(styles.detailContainer) },
                React.createElement("div", { className: css(styles.detail) },
                    React.createElement("div", { className: css(styles.label) }, "\u30AB\u30ED\u30EA\u30FC"),
                    React.createElement("div", { className: css(styles.calories) },
                        Task.roundTaskCalories(sumSelectedMenuCalories()).toLocaleString(),
                        "kcal")),
                React.createElement("div", { className: css(styles.detail, styles.confirmInfoTime) },
                    React.createElement("div", { className: css(styles.label) }, "\u98DF\u4E8B\u6642\u9593"),
                    React.createElement(TimeSelectForm, { selectClassObject: styles.timeSelect, currentValue: time, setCurrentValue: onTimeChange, label: "", format: TimeSelectFormat.hhmm, step: 15, listClassObject: styles.timeSelectList, listItemClassObject: styles.timeSelectListItem }))),
            React.createElement("div", { className: css(styles.mealMenuConfirmList) },
                React.createElement("div", { className: css(styles.borderAround, styles.addMenuButton), onClick: onClickAddMenu },
                    React.createElement("span", null, "\u30E1\u30CB\u30E5\u30FC\u3092\u8FFD\u52A0"),
                    React.createElement(PlusIcon, { color: Colors.Black })),
                Array.from(allSelectedMenus.entries()).map(function (_a) {
                    var i = _a[0], menuSelection = _a[1];
                    return (React.createElement(MealMenuConfirmListItem, { key: i, index: i, menuSelection: menuSelection, onClick: onClickMenu, classObject: [styles.borderAround, styles.menuItem] }));
                })),
            React.createElement(RecordMealConfirmImageList, { imageMap: imageMap, onDeleteImage: onDeleteImage })),
        React.createElement(MealRecordSheet, { action: RecordMealActionType.confirm, selectedMenuMap: selectedMenuMap, selectedAnalysisMenuMap: selectedAnalysisMenuMap, imageMap: imageMap, onClickConfirm: onSaveMeal, isDisabledSaveButton: isDisabledSaveButton })));
};
var styles = StyleSheet.create({
    container: {},
    detailContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 24,
        marginBottom: 8,
    },
    detail: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    label: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
        marginBottom: 2,
    },
    confirmInfoTime: {
        width: 82,
    },
    calories: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
    timeSelect: {
        boxSizing: 'border-box',
        fontSize: 12,
        padding: '2px 8px 0 12px',
        height: 30,
        borderRadius: 15,
        borderColor: Colors.BorderLightGray,
    },
    timeSelectList: {
        height: 528,
    },
    timeSelectListItem: {
        fontSize: 12,
        minHeight: 33,
        height: 33,
    },
    mealMenuConfirmList: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        marginBottom: 12,
    },
    borderAround: {
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        borderRadius: 10,
    },
    addMenuButton: {
        padding: 20,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.56,
        cursor: 'pointer',
    },
    menuItem: {
        cursor: 'pointer',
    },
});
