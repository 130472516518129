import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var TitleWithIcon = function (_a) {
    var icon = _a.icon, title = _a.title, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        icon,
        React.createElement("p", { className: css(styles.title) }, title)));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
    },
    title: {
        Color: Colors.Black,
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.56,
        lineHeight: 0,
    },
});
