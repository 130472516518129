import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var ClockMinute = {
    five: 'five',
    ten: 'ten',
    twenty: 'twenty',
    fourty: 'fourty',
};
export var ClockIcon = function (props) {
    var minute = props.minute, classObject = props.classObject;
    var styles = StyleSheet.create({
        icon: {
            width: 14,
            height: 16,
        },
    });
    var color = props.color || Colors.Text;
    return minute === ClockMinute.five ? (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 25 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: color, d: "M20.2432 5.92814L21.6227 4.49681C21.8136 4.3008 21.9136 4.04098 21.9091 3.76748C21.9045 3.49398 21.7932 3.23871 21.5977 3.04726C21.4068 2.86037 21.1545 2.76008 20.8864 2.76008C20.6068 2.76008 20.3455 2.87176 20.15 3.07233L18.5136 4.77031C17.0432 3.95893 15.4477 3.47574 13.7705 3.33215V2.05126H16.575C17.1386 2.05126 17.5977 1.59086 17.5977 1.02563C17.5977 0.460393 17.1386 0 16.575 0H8.92045C8.35682 0 7.89773 0.460393 7.89773 1.02563C7.89773 1.59086 8.35682 2.05126 8.92045 2.05126H11.725V3.33215C10.0477 3.47574 8.45455 3.95665 6.98636 4.76804L5.35227 3.07233C5.15682 2.86948 4.89773 2.76008 4.61591 2.76008C4.33409 2.76008 4.09773 2.86264 3.90455 3.04726C3.70909 3.23871 3.59773 3.49398 3.59318 3.76748C3.58864 4.04098 3.69091 4.3008 3.87955 4.49681L5.25682 5.92586C2.39318 8.2301 0.75 11.6511 0.75 15.3183C0.75 21.9553 6.13409 27.3524 12.75 27.3524C19.3659 27.3524 24.75 21.953 24.75 15.3183C24.75 11.6511 23.1068 8.23238 20.2409 5.92814H20.2432ZM12.75 25.3011C7.26136 25.3011 2.79545 20.8225 2.79545 15.3183C2.79545 9.81413 7.26136 5.33555 12.75 5.33555C18.2386 5.33555 22.7045 9.81413 22.7045 15.3183C22.7045 20.8225 18.2386 25.3011 12.75 25.3011Z" }),
        React.createElement("path", { fill: color, d: "M16.7911 9.032C16.2592 8.68785 15.6865 8.41435 15.0865 8.21378C14.4865 8.01549 13.8615 7.89469 13.232 7.85367H13.2002C12.9274 7.85367 12.7047 8.07703 12.7047 8.35053V13.955C12.7047 14.265 12.9592 14.4519 13.2047 14.4519C13.3615 14.4519 13.5024 14.3812 13.5979 14.2513L16.9252 9.74994C17.007 9.63826 17.0388 9.50151 17.0138 9.36476C16.9888 9.22801 16.9115 9.11177 16.7956 9.03656L16.7911 9.032Z" }))) : minute === ClockMinute.ten ? (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 25 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: color, d: "M19.7432 5.92814L21.1227 4.49682C21.3136 4.30081 21.4136 4.04098 21.4091 3.76748C21.4045 3.49398 21.2932 3.23871 21.0977 3.04726C20.9068 2.86037 20.6545 2.76008 20.3864 2.76008C20.1068 2.76008 19.8455 2.87176 19.65 3.07233L18.0136 4.77032C16.5432 3.95893 14.9477 3.47575 13.2705 3.33216V2.05126H16.075C16.6386 2.05126 17.0977 1.59086 17.0977 1.02563C17.0977 0.460394 16.6386 0 16.075 0H8.42045C7.85682 0 7.39773 0.460394 7.39773 1.02563C7.39773 1.59086 7.85682 2.05126 8.42045 2.05126H11.225V3.33216C9.54773 3.47575 7.95455 3.95665 6.48636 4.76804L4.85227 3.07233C4.65682 2.86948 4.39773 2.76008 4.11591 2.76008C3.83409 2.76008 3.59773 2.86265 3.40455 3.04726C3.20682 3.23871 3.09773 3.49398 3.09318 3.76748C3.08864 4.04098 3.19091 4.30081 3.37955 4.49682L4.75682 5.92586C1.89318 8.2301 0.25 11.6489 0.25 15.3183C0.25 21.9553 5.63409 27.3524 12.25 27.3524C18.8659 27.3524 24.25 21.953 24.25 15.3183C24.25 11.6511 22.6068 8.23239 19.7432 5.92814ZM12.25 25.3011C6.76136 25.3011 2.29545 20.8226 2.29545 15.3183C2.29545 9.81413 6.76136 5.33555 12.25 5.33555C17.7386 5.33555 22.2045 9.81413 22.2045 15.3183C22.2045 20.8226 17.7386 25.3011 12.25 25.3011Z" }),
        React.createElement("path", { fill: color, d: "M15.8502 8.76572C14.8956 8.23923 13.8161 7.92243 12.7297 7.85178H12.6979C12.4252 7.85178 12.2047 8.07513 12.2047 8.34863V14.5001C12.2047 14.7736 12.4274 14.997 12.7002 14.997C12.7933 14.997 12.8842 14.9696 12.9638 14.9195L18.1524 11.6352C18.2683 11.5623 18.3502 11.4415 18.3752 11.307C18.4024 11.1703 18.3706 11.0358 18.2911 10.9241C17.6502 10.0398 16.8092 9.29221 15.8547 8.76572H15.8502Z" }))) : minute === ClockMinute.twenty ? (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 25 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: color, d: "M20.2477 5.92814L21.6273 4.49682C21.8182 4.30081 21.9182 4.04098 21.9136 3.76748C21.9091 3.49398 21.7977 3.23871 21.6023 3.04726C21.4114 2.86037 21.1591 2.76008 20.8909 2.76008C20.6114 2.76008 20.35 2.87176 20.1545 3.07233L18.5182 4.77032C17.0477 3.95893 15.4523 3.47575 13.775 3.33216V2.05126H16.5795C17.1432 2.05126 17.6023 1.59086 17.6023 1.02563C17.6023 0.460394 17.1432 0 16.5795 0H8.925C8.36136 0 7.90227 0.460394 7.90227 1.02563C7.90227 1.59086 8.36136 2.05126 8.925 2.05126H11.7295V3.33216C10.0523 3.47575 8.45909 3.95665 6.99091 4.76804L5.35682 3.07233C5.16137 2.86949 4.90227 2.76008 4.62045 2.76008C4.33864 2.76008 4.10227 2.86265 3.90909 3.04726C3.71136 3.23871 3.60227 3.49398 3.59773 3.76748C3.59318 4.04098 3.69545 4.30081 3.88409 4.49682L5.26136 5.92586C2.39318 8.2301 0.75 11.6511 0.75 15.3183C0.75 21.9553 6.13409 27.3524 12.75 27.3524C19.3659 27.3524 24.75 21.953 24.75 15.3183C24.75 11.6511 23.1068 8.23239 20.2432 5.92814H20.2477ZM12.7545 25.3011C7.26591 25.3011 2.8 20.8226 2.8 15.3183C2.8 9.81413 7.26591 5.33555 12.7545 5.33555C18.2432 5.33555 22.7091 9.81413 22.7091 15.3183C22.7091 20.8226 18.2432 25.3011 12.7545 25.3011Z" }),
        React.createElement("path", { fill: color, d: "M13.3578 7.86037C13.3419 7.86037 13.326 7.86037 13.3101 7.86037C12.9805 7.86037 12.7123 8.13387 12.7123 8.46891V15.0056C12.7123 15.2153 12.8237 15.4136 13.001 15.523L18.5646 18.928C18.6601 18.9873 18.7692 19.0169 18.8805 19.0169C19.1101 19.0169 19.3146 18.8916 19.4169 18.6887C19.9396 17.6494 20.2169 16.4848 20.2169 15.3201C20.2169 11.4523 17.2032 8.17717 13.3578 7.86264V7.86037Z" }))) : minute === ClockMinute.fourty ? (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 25 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: color, d: "M19.7477 5.93042L21.1273 4.49909C21.3182 4.30309 21.4182 4.04326 21.4136 3.76976C21.4091 3.49625 21.2977 3.24099 21.1023 3.04954C20.9114 2.86265 20.6591 2.76008 20.3909 2.76008C20.1114 2.76008 19.85 2.87176 19.6545 3.07233L18.0182 4.77031C16.5477 3.95893 14.9523 3.47574 13.275 3.33215V2.05126H16.0795C16.6432 2.05126 17.1023 1.59086 17.1023 1.02563C17.1023 0.460394 16.6432 0 16.0795 0H8.425C7.86136 0 7.40227 0.460394 7.40227 1.02563C7.40227 1.59086 7.86136 2.05126 8.425 2.05126H11.2295V3.33215C9.55228 3.47574 7.95909 3.95665 6.49091 4.76804L4.85682 3.07233C4.66137 2.86948 4.40228 2.76008 4.12046 2.76008C3.83864 2.76008 3.60228 2.86264 3.40909 3.04726C3.21137 3.23871 3.10228 3.49398 3.09773 3.76748C3.09319 4.04098 3.19545 4.3008 3.38409 4.49681L4.76136 5.92586C1.89318 8.2301 0.25 11.6511 0.25 15.3183C0.25 21.9553 5.63409 27.3524 12.25 27.3524C18.8659 27.3524 24.25 21.953 24.25 15.3183C24.25 11.6511 22.6068 8.23238 19.7432 5.92814L19.7477 5.93042ZM12.2545 25.3034C6.76591 25.3034 2.3 20.8248 2.3 15.3206C2.3 9.81641 6.76591 5.33783 12.2545 5.33783C17.7432 5.33783 22.2091 9.81641 22.2091 15.3206C22.2091 20.8248 17.7432 25.3034 12.2545 25.3034Z" }),
        React.createElement("path", { fill: color, d: "M12.8506 7.86371C12.8347 7.86371 12.8188 7.86371 12.8029 7.86371C12.4756 7.86371 12.2097 8.13265 12.2097 8.46541V15.1343C12.2097 15.246 12.1733 15.3554 12.1051 15.4442L8.04833 20.7274C7.94606 20.8596 7.90288 21.0328 7.93242 21.1969C7.95969 21.3633 8.05287 21.5023 8.19151 21.5935C9.40515 22.3843 10.8097 22.8014 12.2574 22.8014C16.3711 22.8014 19.7165 19.4465 19.7165 15.3212C19.7165 11.4489 16.7006 8.17368 12.8506 7.86371Z" }))) : (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 14 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: color, fillRule: "evenodd", clipRule: "evenodd", d: "M6.51185 4.31651C3.64387 4.31651 1.31892 6.64147 1.31892 9.50945C1.31892 12.3774 3.64387 14.7024 6.51185 14.7024C9.37983 14.7024 11.7048 12.3774 11.7048 9.50945C11.7048 6.64147 9.37983 4.31651 6.51185 4.31651ZM0.0554199 9.50945C0.0554199 5.94366 2.94605 3.05301 6.51185 3.05301C10.0776 3.05301 12.9683 5.94366 12.9683 9.50945C12.9683 13.0752 10.0776 15.9659 6.51185 15.9659C2.94605 15.9659 0.0554199 13.0752 0.0554199 9.50945Z" }),
        React.createElement("path", { fill: color, fillRule: "evenodd", clipRule: "evenodd", d: "M6.51185 4.31651C3.64387 4.31651 1.31892 6.64147 1.31892 9.50945C1.31892 12.3774 3.64387 14.7024 6.51185 14.7024C9.37983 14.7024 11.7048 12.3774 11.7048 9.50945C11.7048 6.64147 9.37983 4.31651 6.51185 4.31651ZM0.0554199 9.50945C0.0554199 5.94366 2.94605 3.05301 6.51185 3.05301C10.0776 3.05301 12.9683 5.94366 12.9683 9.50945C12.9683 13.0752 10.0776 15.9659 6.51185 15.9659C2.94605 15.9659 0.0554199 13.0752 0.0554199 9.50945Z" }),
        React.createElement("path", { fill: color, d: "M4.54712 1.52288V0.657257H8.45869V1.52288H4.54712Z" }),
        React.createElement("path", { fill: color, d: "M4.54712 1.52288V0.657257H8.45869V1.52288H4.54712Z" }),
        React.createElement("path", { stroke: color, d: "M4.54712 1.52288V0.657257H8.45869V1.52288H4.54712Z", strokeWidth: "0.5" }),
        React.createElement("path", { fill: color, d: "M8.62457 6.50516L5.6521 9.47763L6.54636 10.3719L9.51883 7.39942L8.62457 6.50516Z" }),
        React.createElement("path", { fill: color, d: "M12.491 4.43888C12.9927 4.43888 13.3994 4.03216 13.3994 3.53045C13.3994 3.02873 12.9927 2.62201 12.491 2.62201C11.9892 2.62201 11.5825 3.02873 11.5825 3.53045C11.5825 4.03216 11.9892 4.43888 12.491 4.43888Z" })));
};
