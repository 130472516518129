/* eslint-disable @typescript-eslint/class-name-casing */
export var hoverFocusActiveStyle = function (styles) {
    return {
        ':hover': styles,
        ':focus': styles,
        ':active': styles,
    };
};
export var hoverFocusActiveStyleOfChild = function (child, styles) {
    var _a;
    return _a = {},
        _a[":hover .".concat(child)] = styles,
        _a[":focus .".concat(child)] = styles,
        _a[":active .".concat(child)] = styles,
        _a;
};
/**
 * transition の duration と timing-function をアプリ内で共通化
 */
export var transition = function (property) {
    if (property === void 0) { property = 'all'; }
    return "".concat(property, " .6s ease");
};
var _StyleMixin = /** @class */ (function () {
    function _StyleMixin(base) {
        // tslint:disable-next-line:variable-name
        Object.defineProperty(this, "_styles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this._styles = base;
        Object.defineProperty(this, '_styles', {
            enumerable: false,
        });
    }
    Object.defineProperty(_StyleMixin.prototype, "mouseReact", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (props) {
            var reactStyles = {
                ':hover': props,
                ':focus': props,
                ':active': props,
            };
            this._styles = Object.assign(this._styles, reactStyles);
            this.updateProps();
            return this;
        }
    });
    Object.defineProperty(_StyleMixin.prototype, "mouseReactOfChild", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (child, props) {
            var _a;
            var reactStyles = (_a = {},
                _a[":hover .".concat(child)] = props,
                _a[":focus .".concat(child)] = props,
                _a[":active .".concat(child)] = props,
                _a);
            this._styles = Object.assign(this._styles, reactStyles);
            this.updateProps();
            return this;
        }
    });
    Object.defineProperty(_StyleMixin.prototype, "updateProps", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            var _loop_1 = function (k) {
                Object.defineProperty(this_1, k, {
                    get: function () { return _this._styles[k]; },
                    enumerable: true,
                });
            };
            var this_1 = this;
            // tslint:disable-next-line:forin
            for (var k in this._styles) {
                _loop_1(k);
            }
        }
    });
    return _StyleMixin;
}());
export function StyleMixin(base) {
    return new _StyleMixin(base);
}
