import * as React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../../lotties/in_progress.json';
import { css } from 'aphrodite/no-important';
export var InProgressAnimationIcon = function (props) {
    var options = {
        loop: true,
        animationData: animationData,
    };
    return (React.createElement("div", { className: css(props.classObject) },
        React.createElement(Lottie, { options: options })));
};
