import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { VideoCameraIcon } from '../atoms/icons/video_camera_icon';
export var ContentsEmptyState = function (props) {
    return (React.createElement("div", { className: css(styles.container, props.classObject) },
        React.createElement(VideoCameraIcon, null),
        React.createElement("div", { className: css(styles.description) },
            "\u516C\u958B\u3055\u308C\u305F\u30EC\u30C3\u30B9\u30F3\u306F",
            React.createElement("br", null),
            "\u3053\u3053\u306B\u8868\u793A\u3055\u308C\u307E\u3059\u3002")));
};
var styles = StyleSheet.create({
    container: {
        textAlign: 'center',
    },
    description: {
        marginTop: 39,
        color: Colors.GrayDarkest,
        fontSize: 12,
        letterSpacing: '0.04em',
        fontWeight: 500,
    },
});
