var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Button, ButtonType, ThumbsUp, ThumbsDown, PencilIcon } from '@lean-body/components/atoms';
var COURSE_LESSON_REVIEW_LINK = 'https://docs.google.com/forms/d/e/1FAIpQLScTqqdqLUmQ2PWO8yqkSVTI6psvngZsBNw8ZQEuVkq-ZMgCdQ/viewform?usp=sf_link';
export var CourseLessonReview = function (_a) {
    var reviewSelected = _a.reviewSelected, isGood = _a.isGood, onClickReview = _a.onClickReview, classObject = _a.classObject, tracker = _a.tracker;
    var _b = React.useState(false), clickedBad = _b[0], setClickedBad = _b[1];
    var onClickBad = function () {
        setClickedBad(true);
        onClickReview(false);
    };
    var onClickComment = function () {
        tracker.trackClickEvent({
            category: 'onClickCourseDayReviewComment',
        });
    };
    var buttonMargin = StyleSheet.create({
        button: {
            margin: clickedBad ? '17px auto 0 auto' : '6px auto 0 auto',
        },
    });
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("p", { className: css(styles.title) }, "\u30EC\u30C3\u30B9\u30F3\u306F\u3044\u304B\u304C\u3067\u3057\u305F\u304B\uFF1F"),
        React.createElement("div", { className: css(styles.icons) },
            React.createElement(ThumbsUp, { isActive: reviewSelected && isGood, onClick: function () { return onClickReview(true); }, classObject: styles.icon }),
            React.createElement(ThumbsDown, { isActive: reviewSelected && !isGood, onClick: onClickBad, classObject: styles.icon })),
        React.createElement("a", { href: COURSE_LESSON_REVIEW_LINK, target: "_blank", rel: "noopener noreferrer" },
            React.createElement(Button, { className: css(styles.button, buttonMargin.button), onClick: onClickComment, buttonType: clickedBad ? ButtonType.primary : ButtonType.secondary },
                React.createElement(PencilIcon, { color: clickedBad ? Colors.White : Colors.PrimaryDarkB }),
                " \u8A73\u3057\u304F\u30B3\u30E1\u30F3\u30C8\u3092\u66F8\u304F"))));
};
var styles = StyleSheet.create({
    container: {
        background: Colors.White,
        padding: 20,
        borderRadius: 10,
    },
    title: {
        fontWeight: 700,
        fontSize: 14,
        letterSpacing: '0.04em',
        textAlign: 'center',
        color: Colors.Black,
    },
    icons: {
        marginTop: 17,
        display: 'flex',
        justifyContent: 'center',
        gap: 40,
    },
    icon: {
        borderRadius: '50%',
        width: 41,
        height: 41,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    button: (_a = {
            borderColor: Colors.White,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 7,
            borderRadius: 24
        },
        _a[MediaBreakPointUp.MD] = {
            width: 287,
        },
        _a),
});
