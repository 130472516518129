var _a;
import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors, MediaBreakPointUp, Sizes } from '../styles';
import { MealMenuAnalysisItem } from '../molecules/meal_menu_analysis_item';
import { PlusIcon } from '../atoms/icons/plus_icon';
import { CloseIcon } from '../atoms';
import { ZIndex } from '../constants';
import { RecordMealAnalysisResultImage } from './record_meal_analysis_result_image';
import { MealHeader } from '../atoms/meal_header';
export var RecordMealAnalysisResult = function (_a) {
    var headerTitle = _a.headerTitle, imageUrl = _a.imageUrl, analysisResultMenuMap = _a.analysisResultMenuMap, displayedAnalysisMenuMap = _a.displayedAnalysisMenuMap, checkedAnalysisMenuMap = _a.checkedAnalysisMenuMap, onClick = _a.onClick, onClickBack = _a.onClickBack, onClickSelect = _a.onClickSelect, onClickDeselect = _a.onClickDeselect, onClickAddMenu = _a.onClickAddMenu, onClickChange = _a.onClickChange, menusClassObject = _a.menusClassObject;
    var _b = React.useState(false), showOverlay = _b[0], setShowOverlay = _b[1];
    var positions = Array.from(analysisResultMenuMap.values())
        .map(function (v) { return v.position; })
        .filter(function (v) { return v; });
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.sticky) },
            React.createElement(MealHeader, { onClickBack: onClickBack }, headerTitle),
            React.createElement("div", { className: css(styles.imageContainer), onClick: function () { return setShowOverlay(true); } },
                React.createElement(RecordMealAnalysisResultImage, { imageUrl: imageUrl, positions: positions, classObject: styles.image }))),
        React.createElement("div", { className: css(styles.menus, menusClassObject) },
            Array.from(displayedAnalysisMenuMap.values()).map(function (v, i) {
                var _a;
                return (React.createElement(MealMenuAnalysisItem, { menuIndex: i, menu: v, analysisMenuSize: analysisResultMenuMap.size, isSelected: v.menuKey === ((_a = checkedAnalysisMenuMap.get(i)) === null || _a === void 0 ? void 0 : _a.menuKey), onClick: onClick, onClickSelect: onClickSelect, onClickDeselect: onClickDeselect, onClickChange: onClickChange, key: i }));
            }),
            React.createElement("div", { className: css(styles.addMenuButton), onClick: onClickAddMenu },
                React.createElement("span", null, "\u30E1\u30CB\u30E5\u30FC\u3092\u8FFD\u52A0"),
                React.createElement(PlusIcon, { color: Colors.Black }))),
        showOverlay && (React.createElement("div", { className: css(styles.overlay) },
            React.createElement(CloseIcon, { containerClassObject: styles.overlayCloseIcon, color: Colors.White, size: 16, onClick: function () { return setShowOverlay(false); } }),
            React.createElement("div", { className: css(styles.overlayImageContainer) },
                React.createElement(RecordMealAnalysisResultImage, { imageUrl: imageUrl, positions: positions, classObject: styles.overlayImage }))))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    sticky: (_a = {
            backgroundColor: Colors.White,
            position: 'sticky',
            top: Sizes.MobileHeaderHeight,
            zIndex: ZIndex.Header
        },
        _a[MediaBreakPointUp.MD] = {
            top: 0,
        },
        _a),
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 30,
        paddingBottom: 24,
    },
    image: {
        maxWidth: 257,
        maxHeight: 157,
        borderRadius: 5,
        cursor: 'pointer',
    },
    addMenuButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.56,
        padding: 20,
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        borderRadius: 10,
        cursor: 'pointer',
    },
    menus: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
    },
    overlay: {
        background: Colors.StrictBlack,
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        top: '0',
        left: '0',
        zIndex: ZIndex.Overlay,
    },
    overlayCloseIcon: {
        position: 'absolute',
        top: 20,
        left: 24,
    },
    overlayImageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100% - 230px)',
        marginTop: 112,
    },
    overlayImage: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
});
