import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors } from '@lean-body/components/styles';
export var TrainerImage = function (props) {
    var iconSize = 100;
    var styles = StyleSheet.create({
        icon: {
            width: iconSize,
            height: iconSize,
            flexBasis: iconSize,
            borderRadius: '50%',
            overflow: 'hidden',
            border: "1px solid ".concat(Colors.Gray),
            marginBottom: 8,
        },
        img: {
            maxWidth: '100%',
            height: 'auto',
        },
    });
    return (React.createElement("div", { className: css(styles.icon) },
        React.createElement("img", { alt: props.trainer.name, className: css(styles.img), src: props.trainer.thumbnailURL })));
};
