import * as React from 'react';
import { css } from 'aphrodite';
export var RecordMealAnalysisResultImage = function (_a) {
    var imageUrl = _a.imageUrl, positions = _a.positions, classObject = _a.classObject;
    var canvasRef = React.useRef(null);
    React.useEffect(function () {
        var canvas = canvasRef.current;
        var ctx = canvas.getContext('2d');
        var image = new Image();
        // 画像のロード後にキャンバスに画像を描画してから枠を描画
        image.onload = function () {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            if (positions.length > 0) {
                var lineWidth = 7;
                var rectSize_1 = 30;
                var textXOffset_1 = 4;
                var textYOffset_1 = 25;
                var fontSize = 30;
                // 画像サイズによって枠線や数字の大きさを調整
                if (image.width > 1000 || image.height > 1000) {
                    var ratio = void 0;
                    if (image.width > image.height) {
                        ratio = Math.ceil(image.width / 1000);
                    }
                    else {
                        ratio = Math.ceil(image.height / 1000);
                    }
                    lineWidth = ratio * 8;
                    rectSize_1 = ratio * 30 + 10;
                    if (image.width > 2000 || image.height > 2000) {
                        textXOffset_1 = (ratio - 1) * 8;
                    }
                    else {
                        textXOffset_1 = 14;
                    }
                    textYOffset_1 = ratio * 25;
                    fontSize = ratio * 30;
                }
                var borderColor_1 = '#FF569D';
                var textColor_1 = '#FFFFFF';
                ctx.strokeStyle = borderColor_1;
                ctx.lineWidth = lineWidth;
                ctx.font = "".concat(fontSize, "px \"Noto Sans JP\"");
                // 座標に基づいて枠を描画
                positions.forEach(function (v, i) {
                    // 枠線
                    ctx.strokeRect(v.xmin, v.ymin, v.xmax - v.xmin, v.ymax - v.ymin);
                    // 数字背景
                    ctx.fillStyle = borderColor_1;
                    ctx.fillRect(v.xmin, v.ymin, rectSize_1, rectSize_1);
                    // 数字
                    ctx.fillStyle = textColor_1;
                    ctx.fillText(i + 1, v.xmin + textXOffset_1, v.ymin + textYOffset_1);
                });
            }
        };
        image.src = imageUrl;
    }, []);
    return React.createElement("canvas", { ref: canvasRef, className: css(classObject) });
};
