var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { Button, ButtonType } from '@lean-body/components/atoms';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
import { ZENDESK_QA_LINK, ZENDESK_REQUESTS_LINK } from '../constants';
import { observer } from 'mobx-react';
export var AndroidAccountDeletion = observer(function (_a) {
    var accountService = _a.accountService;
    var logoSrc = '/assets/images/warning.png';
    var circleCheckBlue = '/assets/images/icons/circle-check-blue.svg';
    var circleCheck = '/assets/images/icons/circle-check.svg';
    var _b = React.useState(false), isChecked = _b[0], setIsChecked = _b[1];
    var _c = React.useState(Status.preparing), status = _c[0], setStatus = _c[1];
    var handleCheckbox = function () {
        setIsChecked(!isChecked);
    };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isChecked) {
                        return [2 /*return*/];
                    }
                    setStatus(Status.submitting);
                    return [4 /*yield*/, accountService.destroyAccount()];
                case 1:
                    _a.sent();
                    setStatus(Status.finished);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", null,
        React.createElement("div", { className: css(styles.headerContainer) }, "\u30A2\u30AB\u30A6\u30F3\u30C8\u524A\u9664"),
        React.createElement("div", { className: css(styles.alertContainer) },
            React.createElement("img", { className: css(styles.alertIcon), src: logoSrc })),
        React.createElement("div", { className: css(styles.noteContainer) },
            React.createElement("div", { className: css(styles.noteTitle) }, "\u6CE8\u610F\u4E8B\u9805"),
            React.createElement("div", { className: css(styles.noteContent) },
                React.createElement("div", null, "\u30A2\u30AB\u30A6\u30F3\u30C8\u3092\u524A\u9664\u3059\u308B\u3068\u3001\u30A2\u30AF\u30C6\u30A3\u30D3\u30C6\u30A3\u3084\u53D6\u5F97\u3057\u305F\u30D0\u30C3\u30B8\u306A\u3069\u306E\u30C7\u30FC\u30BF\u3082\u5B8C\u5168\u306B\u524A\u9664\u3055\u308C\u307E\u3059\u3002"),
                React.createElement("div", { className: css(styles.noteMargin) }, "\u307E\u305F\u3001\u30A2\u30AB\u30A6\u30F3\u30C8\u3092\u524A\u9664\u3057\u305F\u5F8C\u3067\u306E\u30C7\u30FC\u30BF\u306E\u5FA9\u65E7\u306F\u3044\u305F\u3057\u304B\u306D\u307E\u3059\u306E\u3067\u3001\u3054\u7406\u89E3\u304F\u3060\u3055\u3044\u3002"),
                React.createElement("div", { className: css(styles.noteMargin) }, "\u3054\u767B\u9332\u4E2D\u306E\u30D7\u30E9\u30F3\uFF08\u30AF\u30EC\u30B8\u30C3\u30C8\u30AB\u30FC\u30C9/Amazon Pay\uFF09\u306F\u81EA\u52D5\u3067\u9000\u4F1A\u3055\u308C\u3001\u5FA9\u65E7\u306F\u3044\u305F\u3057\u304B\u306D\u307E\u3059\u3002"))),
        React.createElement("div", { className: css(styles.buttonContainer) },
            React.createElement("div", { className: css(styles.agreeButtonContainer) },
                React.createElement("img", { src: isChecked ? circleCheckBlue : circleCheck, className: css(styles.agreeButtonImage), onClick: handleCheckbox }),
                React.createElement("div", { className: css(styles.agreeButtonLabel) }, "\u4E0A\u8A18\u306B\u540C\u610F\u3059\u308B")),
            React.createElement(Button, { onClick: isChecked && handleSubmit, isRadius: true, disabled: status === Status.submitting, loading: status === Status.submitting, buttonType: isChecked ? ButtonType.alert : ButtonType.disabled, className: css(styles.deleteButton) }, "\u30A2\u30AB\u30A6\u30F3\u30C8\u3092\u524A\u9664\u3059\u308B"),
            React.createElement("div", { className: css(styles.notationText) },
                "\u3054\u4E0D\u660E\u70B9\u304C\u3042\u308B\u65B9\u306F\u3001",
                React.createElement("span", { className: css(styles.qaInquiryText) },
                    React.createElement("a", { target: "_blank", className: css(styles.qaInquiryText), href: ZENDESK_QA_LINK }, "\u3088\u304F\u3042\u308B\u8CEA\u554F"),
                    "\u30FB",
                    React.createElement("a", { target: "_blank", className: css(styles.qaInquiryText), href: ZENDESK_REQUESTS_LINK }, "\u304A\u554F\u3044\u5408\u308F\u305B")),
                "\u3092\u3054\u78BA\u8A8D\u304F\u3060\u3055\u3044\u3002"))));
});
var AccountDestroyStatus;
(function (AccountDestroyStatus) {
    AccountDestroyStatus[AccountDestroyStatus["preparing"] = 0] = "preparing";
    AccountDestroyStatus[AccountDestroyStatus["submitting"] = 1] = "submitting";
    AccountDestroyStatus[AccountDestroyStatus["finished"] = 2] = "finished";
})(AccountDestroyStatus || (AccountDestroyStatus = {}));
var Status = AccountDestroyStatus;
var styles = StyleSheet.create({
    headerContainer: {
        padding: '16px 0 12px 0',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '16px',
        fontWeight: 'bold',
        color: Colors.TextDark,
    },
    alertContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '50px 0 50px 0',
    },
    noteContainer: {
        padding: '0 24px 32px 24px',
        fontWeight: 'normal',
    },
    agreeButtonContainer: {
        display: 'flex',
    },
    buttonContainer: {
        padding: '32px 16px 0 16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    alertIcon: {
        width: '40px',
    },
    noteTitle: {
        color: Colors.Black,
        fontSize: '13px',
        fontWeight: 'bold',
        marginBottom: '4px',
    },
    noteContent: {
        fontSize: '12px',
        fontWeight: 'lighter',
    },
    noteMargin: {
        marginTop: '8px',
    },
    agreeButtonImage: {
        width: '20px',
        height: '20px',
        marginRight: '5px',
        fill: Colors.Primary,
    },
    agreeButtonLabel: {
        fontSize: '13px',
    },
    deleteButton: {
        marginTop: '16px',
        fontSize: '13px',
        fontWeight: 700,
        width: '100%',
    },
    notationText: {
        marginTop: '16px',
        fontSize: '12px',
        fontWeight: 'lighter',
    },
    qaInquiryText: {
        color: Colors.Primary,
        textDecoration: 'none',
    },
});
