import * as React from 'react';
import ReactLoading from 'react-loading';
import { css, StyleSheet } from 'aphrodite';
export var PageLoading = function (_a) {
    var classObject = _a.classObject;
    var styles = StyleSheet.create({
        container: {
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            height: '75vh',
            width: '100%',
        },
    });
    return (React.createElement("section", { className: css(styles.container, classObject) },
        React.createElement(ReactLoading, { type: "spin", color: "#5f5f5f" })));
};
