import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
// TODO: Increase hit area size (without increasing layout size)
export var CloseButton = function (props) {
    var styles = StyleSheet.create({
        container: {
            position: 'relative',
            cursor: 'pointer',
        },
        icon: {
            fontSize: props.size,
            lineHeight: 1,
            color: props.color,
        },
    });
    var iconClass = ['ion-close', css(styles.icon)].join(' ');
    return (React.createElement("div", { className: css(styles.container), onClick: props.onClick },
        React.createElement("div", { className: iconClass })));
};
