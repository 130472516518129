var _a, _b, _c, _d;
import * as React from 'react';
import { useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { ArrowDirection, ArrowIcon, Button, ButtonType, Input } from '../atoms';
import { ZIndex } from '../constants';
import { Task } from '@lean-body/src/domain';
import { getFormattedDateToHyphenFormat, getFormattedToMonthDateDay } from '@lean-body/src/util';
var INPUT_HEIGHT_SIZE = 32;
export var CustomWorkoutForm = function (_a) {
    var apiClient = _a.apiClient, onClickBack = _a.onClickBack, onSave = _a.onSave, userCustomWorkout = _a.userCustomWorkout, date = _a.date;
    var customWorkout = userCustomWorkout === null || userCustomWorkout === void 0 ? void 0 : userCustomWorkout.customWorkout;
    var _b = useState((customWorkout === null || customWorkout === void 0 ? void 0 : customWorkout.name) || ''), workoutKeyword = _b[0], setWorkoutKeyword = _b[1];
    var _c = useState(customWorkout || null), workout = _c[0], setWorkout = _c[1];
    var _d = useState((userCustomWorkout === null || userCustomWorkout === void 0 ? void 0 : userCustomWorkout.duration) || null), duration = _d[0], setDuration = _d[1];
    var _e = useState(null), calorie = _e[0], setCalorie = _e[1];
    var _f = useState(), workoutList = _f[0], setWorkoutList = _f[1];
    var _g = useState(false), isFocus = _g[0], setIsFocus = _g[1];
    var _h = useState(false), saveButtonClickable = _h[0], setSaveButtonClickable = _h[1];
    useEffect(function () {
        apiClient.fetchCustomWorkouts(workoutKeyword).then(function (v) {
            setWorkoutList(v);
        });
    }, [workoutKeyword]);
    useEffect(function () {
        if (!workout || !duration) {
            setCalorie(null);
        }
        else {
            apiClient.fetchCalorie(workout.id, duration).then(function (v) {
                setCalorie(Task.roundTaskCalories(v));
            });
        }
    }, [workout, duration]);
    useEffect(function () {
        setSaveButtonClickable(isPossibleToSave());
    }, [workout, calorie]);
    var onClickSave = function () {
        setSaveButtonClickable(false);
        if (!isPossibleToSave()) {
            setSaveButtonClickable(true);
            return;
        }
        if (userCustomWorkout) {
            // 編集
            apiClient
                .updateCustomWorkout(userCustomWorkout.id, {
                customWorkoutID: workout.id,
                duration: duration,
                calorie: calorie,
            })
                .then(function () {
                onSave();
            });
        }
        else {
            // 新規作成
            apiClient
                .postCustomWorkout({
                customWorkoutID: workout.id,
                duration: duration,
                calorie: calorie,
                date: getFormattedDateToHyphenFormat(date),
            })
                .then(function () {
                onSave();
            });
        }
    };
    var isPossibleToSave = function () {
        return !!workout && !!duration && !!calorie;
    };
    var onFocus = function () {
        setIsFocus(true);
    };
    var onClickSearchWorkout = function (v) {
        setWorkoutKeyword(v.name);
        setWorkout(v);
        setIsFocus(false);
    };
    var onClickWorkoutCancel = function () {
        setIsFocus(false);
    };
    var onClickWorkoutClear = function () {
        setWorkoutKeyword('');
        setWorkout(null);
        setIsFocus(false);
    };
    var onChangeWorkoutKeyword = function (v) {
        setWorkoutKeyword(v);
        setWorkout(null);
    };
    var onChangeDuration = function (event) {
        setDuration(event.target.value == '' ? null : Number(event.target.value.split('.')[0]));
    };
    // 小数点入力を防止する
    var onDurationKeyDown = function (event) {
        if (event.key === '.') {
            event.preventDefault();
        }
    };
    var onClickDelete = function () {
        apiClient.deleteCustomWorkout(userCustomWorkout.id).then(function () {
            onSave();
        });
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: css(styles.header) },
            React.createElement(ArrowIcon, { classObject: styles.arrowIcon, color: Colors.Black, direction: ArrowDirection.left, onClick: onClickBack }),
            React.createElement("div", { className: css(styles.headerLabel) },
                getFormattedToMonthDateDay(date),
                " \u30EF\u30FC\u30AF\u30A2\u30A6\u30C8"),
            React.createElement("div", { className: css(styles.saveButton, saveButtonClickable && styles.clickableSaveButton), onClick: saveButtonClickable ? onClickSave : undefined }, "\u8A18\u9332")),
        React.createElement("div", { className: css(styles.content) },
            React.createElement("div", { className: css(styles.workoutContainer) },
                isFocus && React.createElement("div", { className: css(styles.overlay), onClick: onClickWorkoutCancel }),
                React.createElement("div", { className: css(styles.label) }, "\u30EF\u30FC\u30AF\u30A2\u30A6\u30C8\u306E\u7A2E\u985E"),
                React.createElement(Input, { type: "text", onChange: function (e) { return onChangeWorkoutKeyword(e.target.value); }, onClickClearBtn: onClickWorkoutClear, value: workoutKeyword, classObject: styles.input, containerClassObject: styles.textContainer, closeIconContainerClassObject: styles.closeIconContainer, dataTest: "searchSelect", height: INPUT_HEIGHT_SIZE, onFocus: onFocus, placeholder: "\u4F8B\uFF09\u30E8\u30AC" }),
                isFocus && (workoutList === null || workoutList === void 0 ? void 0 : workoutList.length) > 0 && (React.createElement("div", { className: css(styles.searchWorkoutBox) },
                    workoutKeyword == '' && React.createElement("div", { className: css(styles.historyLabel) }, "\u5C65\u6B74"),
                    workoutList.map(function (v, i) { return (React.createElement("div", { className: css(styles.suggestWordBox, i !== 0 && styles.suggestWordBorder), onClick: function () {
                            onClickSearchWorkout(v);
                        }, key: i },
                        React.createElement("div", { className: css(styles.suggestWord) }, v.name))); })))),
            React.createElement(Input, { label: "\u6642\u9593(\u5206)", type: "number", value: duration ? duration : '', placeholder: "0", hideSpinner: true, containerClassObject: styles.inputContainer, onChange: onChangeDuration, onKeyDown: onDurationKeyDown }),
            React.createElement("div", { className: css(styles.label) }, "\u6D88\u8CBB\u30AB\u30ED\u30EA\u30FC(kcal)"),
            React.createElement("div", { className: css(styles.calorie, styles.inputContainer) }, calorie ? calorie : '自動で計算されます'),
            userCustomWorkout && (React.createElement(Button, { isRadius: true, buttonType: ButtonType.alertOutline, className: css(styles.deleteButton), onClick: onClickDelete }, "\u524A\u9664")))));
};
var styles = StyleSheet.create({
    header: (_a = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 42,
            marginTop: 12
        },
        _a[MediaBreakPointUp.MD] = {
            marginTop: 14,
        },
        _a[MediaBreakPointUp.XL] = {
            margin: '30px 24px 20px',
        },
        _a),
    arrowIcon: {
        width: 20,
        height: 20,
        margin: '10px 6px 10px 16px',
        cursor: 'pointer',
    },
    headerLabel: {
        color: Colors.TextDark,
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: '1.28px',
        padding: '11px 0 8px',
    },
    saveButton: {
        color: Colors.GrayDarkest,
        padding: '10px 16px 10px 6px',
        cursor: 'pointer',
    },
    clickableSaveButton: {
        color: Colors.PrimaryDarkB,
    },
    content: (_b = {
            padding: '20px 24px 0'
        },
        _b[MediaBreakPointUp.MD] = {
            width: 375,
            margin: 'auto',
            paddingTop: 24,
        },
        _b),
    overlay: (_c = {
            position: 'absolute',
            width: '100vw',
            height: '100%',
            top: -16
        },
        _c[MediaBreakPointUp.MD] = {
            top: 0,
            left: -16,
        },
        _c),
    workoutContainer: {
        width: '100%',
        marginBottom: 20,
    },
    label: {
        fontSize: 14,
        color: Colors.GrayDarkest,
        marginBottom: 8,
    },
    input: {
        color: Colors.Black,
        backgroundColor: Colors.White,
        textAlign: 'start',
        width: '100%',
        height: 50,
        fontSize: 14,
        fontWeight: 400,
        paddingRight: 40,
        border: "1px solid ".concat(Colors.Gray),
        borderRadius: 4,
    },
    textContainer: {
        position: 'relative',
    },
    closeIconContainer: {
        width: 16,
        height: 16,
        top: 17,
        right: 17,
    },
    searchWorkoutBox: (_d = {
            position: 'absolute',
            backgroundColor: Colors.White,
            width: 'calc(100% - 48px)',
            maxHeight: 526,
            padding: '2px 24px',
            marginTop: 2,
            borderRadius: 4,
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: ZIndex.SearchBox,
            overflowY: 'auto'
        },
        _d[MediaBreakPointUp.MD] = {
            width: 327,
        },
        _d),
    historyLabel: {
        height: 26,
        margin: '18px 0',
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: '1.28px',
    },
    suggestWordBox: {
        width: '100%',
        cursor: 'pointer',
        padding: '20px 0',
    },
    suggestWordBorder: {
        borderTop: "1px solid ".concat(Colors.BorderLightGray),
    },
    suggestWord: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: '0.56px',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
        overflow: 'hidden',
    },
    inputContainer: {
        marginBottom: 20,
    },
    calorie: {
        color: Colors.GrayDarkest,
        width: '100%',
        height: 50,
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: '1.28px',
        padding: 12,
        borderRadius: 4,
        border: "1px solid ".concat(Colors.Gray),
    },
    deleteButton: {
        width: '100%',
        height: 48,
        marginTop: 100,
    },
});
