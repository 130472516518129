var HomeCalendar = /** @class */ (function () {
    function HomeCalendar(data) {
        Object.defineProperty(this, "date", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dayOfWeek", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dailyTaskCompleted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.date = data.date;
        this.dayOfWeek = data.dayOfWeek;
        this.dailyTaskCompleted = data.dailyTaskCompleted;
    }
    return HomeCalendar;
}());
export { HomeCalendar };
