import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { ThumbsUpIcon } from '@lean-body/components/atoms';
export var ThumbsUp = function (_a) {
    var isActive = _a.isActive, classObject = _a.classObject, onClick = _a.onClick;
    var styles = StyleSheet.create({
        container: {
            backgroundColor: isActive ? Colors.PrimaryDarkB : Colors.BackgroundGrayD,
        },
    });
    return (React.createElement("div", { onClick: onClick, className: css(styles.container, classObject) },
        React.createElement(ThumbsUpIcon, null)));
};
