var _a;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { EquipSection } from '../organisms';
import { routes } from '@lean-body/src/route';
import { LessonPlaylistBox, SHADOW_WIDTH } from './lesson_playlist_box';
import { LessonMetaTrainers } from '../atoms/lesson_meta_trainers';
import { LessonMetaUserAction } from './lesson_meta_user_action';
import { LessonMetaTitleWithInfo } from '../atoms/lesson_meta_title_with_info';
import { useRefHooks } from '@lean-body/src/util';
import { PlaylistMetaAboveLessonName } from '../atoms/playlist_meta_above_lesson_name';
export var LessonMeta = function (_a) {
    var lesson = _a.lesson, currentLessonIndex = _a.currentLessonIndex, program = _a.program, playlist = _a.playlist, nextLesson = _a.nextLesson, programLessons = _a.programLessons, isFavorite = _a.isFavorite, onClickFavLesson = _a.onClickFavLesson, onClickTwitterShareIfAuthed = _a.onClickTwitterShareIfAuthed, addFavoriteLesson = _a.addFavoriteLesson, unFavoriteLesson = _a.unFavoriteLesson, twitterService = _a.twitterService, onClickLesson = _a.onClickLesson, onClickShowCourseDaySkipDialog = _a.onClickShowCourseDaySkipDialog, displayLessonPlaylistBox = _a.displayLessonPlaylistBox, courseDay = _a.courseDay;
    var _b = React.useState(false), isShowOtherLessons = _b[0], setIsShowOtherLessons = _b[1];
    var _c = useRefHooks(), ref = _c.ref, element = _c.element;
    var onClickShowOtherLessonsIcon = function () {
        element.scrollTo(0, 0); // container がスクロールされた状態で LessonPlaylistBox を開くと位置がバグるので、上に移動
        setIsShowOtherLessons(!isShowOtherLessons);
    };
    return (React.createElement("div", { className: css(styles.container, isShowOtherLessons && styles.showOtherLessons), ref: ref },
        React.createElement("div", { className: css(styles.info, styles.section) },
            React.createElement(LessonMetaTitleWithInfo, { link: routes.PROGRAM.compile({ slug: program.slug }), linkText: program.name, title: lesson.name, favoriteCount: lesson.lessonAggregate.totalFavoriteCount, difficulty: lesson.difficulty, minCal: lesson.minCal, maxCal: lesson.maxCal, pace: lesson.pace, tags: lesson.tags, style: lesson.style }),
            React.createElement(LessonMetaUserAction, { classObject: styles.userActionRow, lesson: lesson, isFavorite: isFavorite, onClickFav: onClickFavLesson, twitterService: twitterService, onClickTwitterShareIfAuthed: onClickTwitterShareIfAuthed })),
        React.createElement(LessonMetaTrainers, { classObject: styles.section, trainers: lesson.trainers }),
        React.createElement("div", { className: css(styles.descriptionSection, styles.section) },
            React.createElement("div", { className: css(styles.description) }, lesson.description)),
        React.createElement(EquipSection, { classObject: styles.equips, title: "\u4F7F\u7528\u3059\u308B\u30A2\u30A4\u30C6\u30E0", equips: lesson.equipments, noUnderline: true }),
        displayLessonPlaylistBox && (React.createElement(LessonPlaylistBox, { program: program, playlist: playlist, currentLessonIndex: currentLessonIndex, nextLesson: nextLesson, programLessons: programLessons, addFavoriteLesson: addFavoriteLesson, unFavoriteLesson: unFavoriteLesson, isShowOtherLessons: isShowOtherLessons, onClickLesson: onClickLesson, onClickShowOtherLessonsIcon: onClickShowOtherLessonsIcon, showTopImg: true, courseDay: courseDay, isSkippableCourseDay: lesson.isSkippableCourseDay, onClickShowCourseDaySkipDialog: onClickShowCourseDaySkipDialog, aboveLessonName: function (index) {
                return !!playlist &&
                    playlist.isChallenge && (React.createElement(PlaylistMetaAboveLessonName, { classObject: styles.aboveLessonName, index: index + 1, isNext: playlist.isChallenging && index === playlist.currentOrderIndex, isDone: playlist.isChallenging && programLessons[index].isCompleted }));
            } }))));
};
var styles = StyleSheet.create({
    container: (_a = {
            position: 'relative',
            background: Colors.White,
            padding: '14px 16px 180px',
            flex: 1
        },
        _a[MediaBreakPointUp.MD] = {
            padding: "14px ".concat(SHADOW_WIDTH, "px 100px"),
        },
        _a[MediaBreakPointUp.XL] = {
            padding: '14px 0 0',
        },
        _a),
    showOtherLessons: {
        overflow: 'hidden',
    },
    info: {
        paddingBottom: 14,
    },
    userActionRow: {
        marginTop: 8,
    },
    section: {
        borderBottom: "1px solid ".concat(Colors.BorderLightGray),
    },
    descriptionSection: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    description: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'flex-start',
    },
    equips: {
        marginBottom: 0,
    },
    aboveLessonName: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 2,
    },
});
