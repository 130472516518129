export var BadgeType = {
    normal: 'normal',
    challenge: 'challenge',
};
var Badge = /** @class */ (function () {
    function Badge(badgeData, config) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "completeImageSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "incompleteImageSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "badgeConditions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isReceived", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = badgeData.id;
        this.name = badgeData.name;
        this.description = badgeData.description;
        this.type = BadgeType[badgeData.type];
        this.completeImageSrc = badgeData.completeImageSrc;
        this.incompleteImageSrc = badgeData.incompleteImageSrc;
        this.isReceived = badgeData.isReceived;
        this.config = config;
        if (badgeData.badgeConditions) {
            this.badgeConditions = badgeData.badgeConditions.map(function (badgeCondition) {
                return new BadgeCondition(badgeCondition);
            });
        }
    }
    Object.defineProperty(Badge.prototype, "completeImageURL", {
        get: function () {
            return this.config.cdnBaseURL + this.completeImageSrc;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Badge.prototype, "incompleteImageURL", {
        get: function () {
            return this.config.cdnBaseURL + this.incompleteImageSrc;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Badge.prototype, "isChallenge", {
        get: function () {
            return this.type === BadgeType.challenge;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Badge.prototype, "renderImageURL", {
        // バッジ獲得済みの場合は獲得済みバッジ画像を返却し、獲得していない場合は未獲得バッジ画像を返却
        get: function () {
            if (this.isReceived) {
                return this.config.cdnBaseURL + this.completeImageSrc;
            }
            return this.config.cdnBaseURL + this.incompleteImageSrc;
        },
        enumerable: false,
        configurable: true
    });
    return Badge;
}());
export { Badge };
export var BadgeConditionType = {
    MINUTE: 'minute',
    TIME: 'time',
    CHALLENGE: 'challenge',
};
var BadgeCondition = /** @class */ (function () {
    function BadgeCondition(data) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "conditionType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "condition", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "challengeID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        }); // conditionTypeがchallengeの場合、conditionにはChallengeプレイリストのIDがセットされているので、わかりやすい命名のパラメータを用意している
        Object.defineProperty(this, "badgeID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.conditionType = data.conditionType;
        this.condition = data.condition;
        this.challengeID = data.condition;
        this.badgeID = data.badgeID;
    }
    Object.defineProperty(BadgeCondition.prototype, "isCertificate", {
        get: function () {
            return this.conditionType === BadgeConditionType.CHALLENGE;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BadgeCondition.prototype, "isMinuteBadge", {
        get: function () {
            return this.conditionType === BadgeConditionType.MINUTE;
        },
        enumerable: false,
        configurable: true
    });
    return BadgeCondition;
}());
export { BadgeCondition };
