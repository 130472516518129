var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import * as React from 'react';
import { LIVE_LESSON_WATCHING_RECORD_SYNC_INTERVAL, } from '@lean-body/src/domain';
import { LiveArchiveVideo } from './live_archive_video';
import { Vimeo } from './vimeo';
import { LiveLessonThumbnail } from '../molecules';
import { APISequences } from '@lean-body/src/interfaces';
import dayjs from 'dayjs';
import { css, StyleSheet } from 'aphrodite';
import { MediaBreakPointUp } from '../styles';
export var LiveVideo = function (_a) {
    var liveLesson = _a.liveLesson, apiClient = _a.apiClient, signedCookieService = _a.signedCookieService, tracker = _a.tracker, me = _a.me;
    var liveLessonWatchingTime = React.useRef(0); // 視聴時間のカウントアップ
    var _b = React.useState(new Map()), liveLessonWatchingRecordMap = _b[0], setLiveLessonWatchingRecordMap = _b[1]; // 重複排除視聴時間計測用map
    // Videojs, Vimeoのイベントハンドラーの中だと useState は更新されてなかったので useRef を使っています
    var previousPlaybackPosition = React.useRef(0);
    var useLessonSessionId = React.useRef(0);
    var src = liveLesson.isVimeoExpired ? liveLesson.videoSrc : liveLesson.playerUrl;
    var isBeingHeld = liveLesson.isBeingHeld();
    var _c = React.useState(false), isExited = _c[0], setIsExited = _c[1];
    var checkDuringLesson = function (currentPlaybackPosition) {
        if (liveLesson.isFinished) {
            // アーカイブ再生中は現在の再生位置(currentPlaybackPosition)を計算に使用して、レッスンが行われてない時間はアクティビティに反映させない仕様
            var lessonStartSecond = liveLesson.startsAt - liveLesson.liveStartAt; // レッスンがライブ配信の何秒目に始まるか
            var lessonEndSecond = liveLesson.lessonEndAt - liveLesson.liveStartAt; // レッスンがライブ配信の何秒目に終わるか
            return lessonStartSecond < currentPlaybackPosition && currentPlaybackPosition < lessonEndSecond;
        }
        else {
            // ライブ中は現在時刻から、レッスンが行われてない時間はアクティビティに反映させない仕様
            return isBeingHeld;
        }
    };
    var onInit = function () {
        apiClient.startLiveLessonWatchingRecord(liveLesson.id).then(function (v) {
            useLessonSessionId.current = v.id;
        });
    };
    var onPlay = function (seconds) {
        // Vimeoで登録されるレッスンの名前形式でtrackerに記録する
        var title = dayjs.unix(liveLesson.startsAt).format('MM/DD H:mm') + '-' + liveLesson.trainer.name;
        tracker.trackPlayLiveVideo(src, title, seconds);
        tracker.trackStartLiveLesson(me.id, liveLesson.id, src);
    };
    var onTimeupdate = function (seconds) {
        var currentPlaybackPosition = Math.floor(seconds); // 現在再生している位置（ex: 5秒目）
        var isDuringLesson = checkDuringLesson(currentPlaybackPosition);
        if (isDuringLesson) {
            var session = currentPlaybackPosition - previousPlaybackPosition.current;
            // 250ms毎に実行されるので、視聴時間のカウントアップを1秒ごとに行いたいため、前回の再生位置から1秒経過した時のみカウントアップする
            if (session === 1) {
                liveLessonWatchingTime.current += session;
                liveLessonWatchingRecordMap.set(currentPlaybackPosition, true); // 再生された箇所を記録したいだけなので、mapのvalueはtrueでなくても構わない
                setLiveLessonWatchingRecordMap(liveLessonWatchingRecordMap);
                if (liveLessonWatchingTime.current % LIVE_LESSON_WATCHING_RECORD_SYNC_INTERVAL === 0) {
                    apiClient.syncLiveLessonWatchingRecord(liveLesson.id, liveLessonWatchingTime.current, liveLessonWatchingRecordMap.size, useLessonSessionId.current);
                }
            }
        }
        previousPlaybackPosition.current = currentPlaybackPosition;
    };
    // リブレッスン視聴履歴をライブレスん終了時に保存する
    var logLessonRecord = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!liveLesson.isShowVideo) {
                return [2 /*return*/];
            }
            if (liveLessonWatchingRecordMap.size !== 0) {
                return [2 /*return*/, apiClient
                        .finishLiveLessonWatchingRecord(liveLesson.id, liveLessonWatchingTime.current, liveLessonWatchingRecordMap.size, useLessonSessionId.current)
                        .then(function (session) {
                        tracker.trackQuitLiveLesson(me.id, liveLesson.id, src, session);
                    })
                        .finally(function () {
                        // Web, iOS側で画面遷移・離脱した時、必ず window.finishLiveLessonWatchingRecord を呼び出しているので、
                        // そのタイミングにプレーヤを破棄する
                        setIsExited(true);
                    })];
            }
            return [2 /*return*/];
        });
    }); };
    // コンポーネント離脱時のライブレッスン視聴履歴保存
    var cleanup = function () {
        apiClient.appendToSequence(APISequences.LESSON_FINISH_AND_PATCH_PROFILE).then(function (next) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, logLessonRecord()];
                    case 1:
                        _a.sent();
                        next();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    React.useEffect(function () {
        return cleanup;
    }, []);
    // ブラウザ閉じる時のライブレッスン視聴履歴保存
    window.onbeforeunload = function () {
        logLessonRecord();
    };
    // iOSがonbeforeunloadやReactのunmountを検知できないので、globalにmethodを定義しています
    window.finishLiveLessonWatchingRecord = function () {
        logLessonRecord();
    };
    return (!isExited && (React.createElement("div", { className: css(styles.container) }, liveLesson.isShowVideo ? (liveLesson.isVimeoExpired ? (React.createElement(LiveArchiveVideo, { liveLesson: liveLesson, onInit: onInit, onPlay: onPlay, onTimeupdate: onTimeupdate, signedCookieService: signedCookieService })) : (React.createElement(Vimeo, { liveLesson: liveLesson, onInit: onInit, onPlay: onPlay, onTimeupdate: onTimeupdate }))) : (React.createElement(LiveLessonThumbnail, { src: liveLesson.bannerPcURL, isWatchable: liveLesson.isWatchable, isArchiveTranscodingComplete: liveLesson.isArchiveTranscodingComplete })))));
};
var styles = StyleSheet.create({
    container: (_a = {},
        _a[MediaBreakPointUp.XL] = {
            marginBottom: 24,
        },
        _a),
});
