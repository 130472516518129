import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '@lean-body/components/styles';
export var CameraBorderIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 33,
            height: 32,
        },
    });
    var color = props.color || Colors.PrimaryDark;
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), fill: color, viewBox: "0 0 33 32", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M16.7563 26.2978C13.6311 26.2978 10.506 26.2978 7.38082 26.2978C5.50307 26.2978 4.17871 24.9783 4.17871 23.1062C4.17871 19.0806 4.17871 15.0558 4.17871 11.0309C4.17871 9.19026 5.50797 7.85891 7.34514 7.85751C8.43652 7.85681 9.52721 7.85331 10.6186 7.86101C10.7879 7.86241 10.8621 7.81063 10.911 7.64832C11.0265 7.26494 11.1566 6.88505 11.2951 6.50866C11.7401 5.30534 12.849 4.51408 14.125 4.50709C15.8706 4.49729 17.6168 4.49799 19.363 4.50709C20.6461 4.51338 21.7536 5.29554 22.2006 6.49677C22.3405 6.87246 22.4686 7.25305 22.5833 7.63643C22.6344 7.80784 22.712 7.8631 22.8946 7.86171C23.986 7.85191 25.0767 7.85541 26.1681 7.85681C27.9892 7.85891 29.3198 9.19166 29.3205 11.0183C29.3219 15.0607 29.3219 19.1037 29.3205 23.146C29.3198 24.9664 27.9836 26.2971 26.1576 26.2978C23.024 26.2992 19.8898 26.2978 16.7563 26.2978ZM16.75 24.6215C19.8744 24.6215 22.9989 24.6215 26.1233 24.6215C27.0727 24.6215 27.645 24.0548 27.645 23.1132C27.6457 19.089 27.6457 15.0649 27.645 11.0407C27.645 10.1088 27.0741 9.53447 26.1492 9.53377C24.8136 9.53237 23.4788 9.53447 22.1432 9.53237C21.664 9.53237 21.3989 9.34278 21.2428 8.88733C21.0505 8.32694 20.8672 7.76306 20.6769 7.20198C20.4481 6.52755 19.9703 6.18125 19.263 6.18055C17.5874 6.17915 15.9118 6.17915 14.2363 6.18055C13.5283 6.18055 13.0511 6.52755 12.8231 7.20267C12.6391 7.74767 12.4614 8.29476 12.2774 8.83976C12.1046 9.35117 11.8485 9.53307 11.3049 9.53307C10.0043 9.53377 8.70447 9.53307 7.4039 9.53307C6.40696 9.53307 5.85567 10.0886 5.85567 11.0925C5.85567 15.0817 5.85567 19.0708 5.85567 23.06C5.85567 24.0744 6.40696 24.6208 7.42909 24.6208C10.536 24.6208 13.643 24.6208 16.75 24.6208V24.6215Z", fill: color }),
        React.createElement("path", { d: "M16.7566 22.9445C13.2838 22.948 10.4637 20.1278 10.4644 16.6522C10.4644 13.2017 13.2754 10.3781 16.7154 10.3711C20.2078 10.3641 23.0321 13.1765 23.0356 16.6641C23.0384 20.1201 20.219 22.941 16.7573 22.9445H16.7566ZM16.751 21.2675C19.2626 21.2738 21.3454 19.21 21.358 16.7012C21.3712 14.1455 19.3137 12.0516 16.7867 12.0481C14.2129 12.0446 12.149 14.0909 12.1413 16.6529C12.1336 19.1911 14.201 21.2612 16.751 21.2675Z", fill: color })));
};
