import * as React from 'react';
import SearchTitle from './search_title';
import { SearchSuggestionInstructor } from '../search_suggestion_instructor';
var SearchInstructor = function (_a) {
    var className = _a.className, trainers = _a.trainers;
    return (React.createElement("div", { className: className },
        React.createElement(SearchTitle, null, "\u30A4\u30F3\u30B9\u30C8\u30E9\u30AF\u30BF\u30FC"),
        React.createElement(SearchSuggestionInstructor, { instructors: trainers })));
};
export default SearchInstructor;
