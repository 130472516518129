import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
var DEFAULT_AMIMATION_SPEED = 5;
export var CircleProgress = function (_a) {
    var onClick = _a.onClick, progressValue = _a.progressValue, progressMax = _a.progressMax, color = _a.color, trackColor = _a.trackColor, circleBorder = _a.circleBorder, circleDiameter = _a.circleDiameter, roundedEdges = _a.roundedEdges, isAnimated = _a.isAnimated, amimationSpeed = _a.amimationSpeed, children = _a.children;
    var _b = React.useState(0), progressPercentage = _b[0], setProgressPercentage = _b[1];
    var _c = React.useState(false), showRoundedEdges = _c[0], setShowRoundedEdges = _c[1];
    React.useEffect(function () {
        if (!isAnimated) {
            var value = (progressValue / progressMax) * 100;
            setProgressPercentage(value);
            setShowRoundedEdges(roundedEdges && value > 0 && value < 100);
            return;
        }
        var step = 0;
        var target = (progressValue / progressMax) * 100;
        var increment = target / 100;
        var updateProgress = function () {
            step += increment;
            if (step <= target) {
                setProgressPercentage(step);
                setShowRoundedEdges(roundedEdges && step > 0 && step < 100);
            }
            else {
                clearInterval(intervalId);
            }
        };
        var intervalId = setInterval(updateProgress, amimationSpeed ? amimationSpeed : DEFAULT_AMIMATION_SPEED);
        return function () { return clearInterval(intervalId); };
    }, [progressValue, progressMax]);
    var progressStyles = createProgressStyles(progressPercentage, color, trackColor, circleBorder, circleDiameter, showRoundedEdges);
    return (React.createElement("div", { className: css(progressStyles.circle), onClick: onClick },
        showRoundedEdges && React.createElement("div", { className: css(progressStyles.startEdge) }),
        showRoundedEdges && React.createElement("div", { className: css(progressStyles.endEdge) }),
        children ? children : React.createElement("span", { className: css(progressStyles.text) }, progressValue)));
};
var createProgressStyles = function (progressPercentage, color, trackColorProps, circleBorderProps, circleDiameterProps, roundedEdges) {
    var trackColor = trackColorProps ? trackColorProps : '#EFEFEF';
    var circleBorder = circleBorderProps ? circleBorderProps : 12;
    var circleDiameter = circleDiameterProps ? circleDiameterProps : 75;
    // 円グラフのエッジに丸みを効かせていることにより
    // 進捗が実際よりも進んでしまっているように見えるので開始位置をずらす
    var startPercentage = roundedEdges ? 1 : 0;
    // 終了位置も必要であればずらす
    var nearlyFinidhedPercentage = 98;
    // 進捗率が0より大きくstartPercentage未満であれば、startPercentageの値に合わせる
    if (0 < progressPercentage && progressPercentage < startPercentage) {
        progressPercentage = startPercentage;
        // 進捗率がnearlyFinidhedPercentage以上100%未満であれば、nearlyFinidhedPercentageの値に合わせる
    }
    else if (nearlyFinidhedPercentage < progressPercentage && progressPercentage < 100) {
        progressPercentage = nearlyFinidhedPercentage;
        // 進捗率が100%であれば、グラフを埋めるためにstartPercentageの値を0に変更する
    }
    else if (progressPercentage == 100) {
        startPercentage = 0;
    }
    return StyleSheet.create({
        circle: {
            width: circleDiameter,
            height: circleDiameter,
            aspectRatio: 1,
            position: 'relative',
            display: 'inline-grid',
            placeContent: 'center',
            justifyItems: 'center',
            fontWeight: 'bold',
            fontFamily: 'Noto Sans JP',
            '::before': {
                content: "''",
                position: 'absolute',
                borderRadius: '50%',
                inset: 0,
                background: "conic-gradient(".concat(trackColor, " 0% ").concat(startPercentage, "%, ").concat(color, " ").concat(startPercentage, "% calc(").concat(progressPercentage, "%), ").concat(trackColor, " 0)"),
                mask: "radial-gradient(farthest-side,#0000 calc(99% - ".concat(circleBorder, "px), #000 calc(100% - ").concat(circleBorder, "px))"),
                '-webkit-mask': "radial-gradient(farthest-side,#0000 calc(99% - ".concat(circleBorder, "px), #000 calc(100% - ").concat(circleBorder, "px))"),
            },
        },
        startEdge: {
            position: 'absolute',
            borderRadius: '50%',
            inset: "calc(50% - ".concat(circleBorder, "px/2)"),
            background: color,
            transform: "rotate(calc(".concat(startPercentage, "*3.6deg)) translateY(calc(50% - ").concat(circleDiameter, "px/2))"),
        },
        endEdge: {
            position: 'absolute',
            borderRadius: '50%',
            inset: "calc(50% - ".concat(circleBorder, "px/2)"),
            background: color,
            transform: "rotate(calc(".concat(progressPercentage, "*3.6deg)) translateY(calc(50% - ").concat(circleDiameter, "px/2))"),
        },
        text: {
            color: color,
            size: '16px',
            fontWeight: 'bold',
        },
    });
};
