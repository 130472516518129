import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var PlayButtonIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 12,
            height: 16,
        },
    });
    var color = props.color || Colors.White;
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 12 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: color, d: "M0 1.11073V14.8896C0 15.1533 0.291749 15.3126 0.513586 15.17L11.2305 8.28057C11.4346 8.14936 11.4346 7.851 11.2305 7.71979L0.513586 0.830341C0.291749 0.687732 0 0.847012 0 1.11073Z" }),
        React.createElement("path", { fill: "url(#paint0_linear_18352_1658)", d: "M0 1.11073V14.8896C0 15.1533 0.291749 15.3126 0.513586 15.17L11.2305 8.28057C11.4346 8.14936 11.4346 7.851 11.2305 7.71979L0.513586 0.830341C0.291749 0.687732 0 0.847012 0 1.11073Z" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "paint0_linear_18352_1658", x1: "5.69179", y1: "0.776855", x2: "5.69179", y2: "15.2235", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: color }),
                React.createElement("stop", { offset: "1", stopColor: color, stopOpacity: "0" })))));
};
