import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var MegalosLiveCalendarWarn = function (props) {
    var styles = StyleSheet.create({
        root: {},
        description: {
            textAlign: 'center',
        },
        warnHeader: {
            color: Colors.White,
            fontSize: 19,
            fontWeight: 500,
            margin: '0',
        },
        warnHeaderBox: {
            textAlign: 'center',
            backgroundColor: Colors.Text,
            width: '90%',
            margin: '0 auto',
            padding: '1% 0',
        },
        descriptionList: {
            width: '90%',
            margin: '0 auto',
        },
        descriptionHeader: {
            fontSize: 19,
            fontWeight: 500,
            color: Colors.Black,
            marginBottom: '1%',
        },
        descriptionContent: {
            fontSize: 13,
            fontWeight: 400,
            color: Colors.Black,
            marginBottom: 16,
            lineHeight: '1.75',
        },
        descriptionBox: {
            margin: '5% 0',
        },
        bold: {
            fontWeight: 'bold',
        },
    });
    var rootClass = [css(styles.root), props.className || ''].join(' ');
    return (React.createElement("div", { className: rootClass },
        React.createElement("div", { className: css(styles.warnHeaderBox) },
            React.createElement("div", { className: css(styles.warnHeader) }, "LIVE\u30EC\u30C3\u30B9\u30F3\u306E\u53D7\u8B1B\u306B\u3064\u3044\u3066")),
        React.createElement("div", { className: css(styles.descriptionList) },
            React.createElement("div", { className: css(styles.descriptionBox) },
                React.createElement("div", { className: css(styles.descriptionHeader) }, "LIVE\u30EC\u30C3\u30B9\u30F3\u53D7\u8B1B\u65B9\u6CD5"),
                React.createElement("div", { className: css(styles.descriptionContent) },
                    "1. Google\u30AB\u30EC\u30F3\u30C0\u30FC\u304B\u3089\u53D7\u3051\u305F\u3044\u30EC\u30C3\u30B9\u30F3\u3092\u78BA\u8A8D",
                    React.createElement("br", null),
                    "2. Zoom\u30A2\u30D7\u30EA\u3092\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9(\u767B\u9332\u6599\u7121\u6599)",
                    React.createElement("br", null),
                    "3. \u6642\u9593\u306B\u306A\u3063\u305F\u3089Google\u30AB\u30EC\u30F3\u30C0\u30FC\u3088\u308A\u3001\u30EC\u30C3\u30B9\u30F3\u5C02\u7528ZoomURL\u3078\u30A2\u30AF\u30BB\u30B9\u3057\u3001\u30EC\u30C3\u30B9\u30F3\u958B\u59CB",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "\u203B\u30EC\u30C3\u30B9\u30F3\u958B\u59CB10\u5206\u524D\u304B\u3089\u30A2\u30AF\u30BB\u30B9\u53EF\u80FD\u3067\u3059\u3002 ",
                    React.createElement("br", null),
                    "\u203BLIVE\u914D\u4FE1\u3068\u306A\u308A\u307E\u3059\u306E\u3067\u3001\u5F8C\u65E5\u914D\u4FE1\u306E\u4E88\u5B9A\u306F\u3054\u3056\u3044\u307E\u305B\u3093\u3002")),
            React.createElement("div", { className: css(styles.descriptionBox) },
                React.createElement("div", { className: css(styles.descriptionHeader) }, "LIVE\u30EC\u30C3\u30B9\u30F3\u5B9A\u54E1"),
                React.createElement("div", { className: css(styles.descriptionContent) }, "300\u540D")),
            React.createElement("div", { className: css(styles.descriptionBox) },
                React.createElement("div", { className: css(styles.descriptionHeader) }, "\u6CE8\u610F\u4E8B\u9805"),
                React.createElement("div", { className: css(styles.descriptionContent) }, "\u30A4\u30F3\u30B9\u30C8\u30E9\u30AF\u30BF\u30FC\u306E\u90FD\u5408\u306B\u3088\u308A\u4F11\u8B1B\u3001\u4EE3\u884C\u306B\u306A\u308B\u5834\u5408\u304C\u3054\u3056\u3044\u307E\u3059\u3002\u5F53\u65E5\u306E\u6848\u5185\u306B\u3064\u304D\u307E\u3057\u3066\u306FGoogle\u30AB\u30EC\u30F3\u30C0\u30FC\u4E0A\u306B\u3066\u3054\u78BA\u8A8D\u304F\u3060\u3055\u3044\u3002")),
            React.createElement("div", { className: css(styles.descriptionBox) },
                React.createElement("div", { className: css(styles.descriptionHeader) }, "LIVE\u30EC\u30C3\u30B9\u30F3\u306B\u95A2\u3059\u308B\u304A\u554F\u3044\u5408\u308F\u305B"),
                React.createElement("div", { className: css(styles.descriptionContent) },
                    "\u30E1\u30AC\u30ED\u30B9LIVE\u30EC\u30C3\u30B9\u30F3\u306B\u95A2\u3059\u308B\u304A\u554F\u3044\u5408\u308F\u305B\u306F\u3001\u4E0B\u8A18\u30E1\u30AC\u30ED\u30B9\u672C\u793E\u307E\u3067\u304A\u9858\u3044\u3057\u307E\u3059\u3002",
                    React.createElement("br", null),
                    React.createElement("a", { href: "mailto:megalos.web@nomura-ls.jp?subject=LEAN BODY \u30E1\u30AC\u30ED\u30B9 \u30E9\u30A4\u30D6\u914D\u4FE1\u306B\u3064\u3044\u3066" }, "\u304A\u554F\u3044\u5408\u308F\u305B"))))));
};
