import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CourseDayItem } from '.';
export var CourseDays = function (_a) {
    var days = _a.days, apiClient = _a.apiClient, classObject = _a.classObject, onClickLesson = _a.onClickLesson;
    return (React.createElement("div", { className: css(styles.container, classObject) }, days.map(function (day, i) { return (React.createElement(CourseDayItem, { key: i, day: day, apiClient: apiClient, classObject: styles.day, onClick: onClickLesson })); })));
};
var styles = StyleSheet.create({
    container: {},
    day: {
        marginTop: 16,
    },
});
