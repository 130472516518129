import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var ProgressBar = function (props) {
    var classObject = props.classObject, max = props.max, current = props.current, threshold = props.threshold, height = props.height, color = props.color, overThresholdColors = props.overThresholdColors, trackColor = props.trackColor, _a = props.blurEffect, blurEffect = _a === void 0 ? false : _a;
    var borderRadius = 10;
    var thresholdPosition = (threshold / max) * 100;
    var progressPosition = (current / max) * 100;
    var currentIsOverThreshold = false;
    if (threshold && progressPosition > thresholdPosition) {
        progressPosition = thresholdPosition;
        currentIsOverThreshold = true;
    }
    var styles = StyleSheet.create({
        background: {
            filter: blurEffect ? 'blur(3px)' : null,
            width: '100%',
            height: height || 4,
            borderRadius: borderRadius,
            backgroundColor: trackColor !== null && trackColor !== void 0 ? trackColor : Colors.White,
            position: 'relative',
            overflow: 'hidden',
        },
        progress: {
            background: color !== null && color !== void 0 ? color : Colors.GradationPrimary,
            position: 'absolute',
            borderRadius: currentIsOverThreshold ? "".concat(borderRadius, "px 0 0 ").concat(borderRadius, "px") : borderRadius,
            top: 0,
            bottom: 0,
            width: "".concat(progressPosition, "%"),
            transition: 'width .2s',
        },
        threshold: {
            background: Colors.White,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: "".concat(thresholdPosition, "%"),
            width: 3,
        },
        progressOverThreshold: {
            background: overThresholdColors !== null && overThresholdColors !== void 0 ? overThresholdColors : Colors.Orange,
            width: "".concat((current / max) * 100, "%"),
            borderRadius: borderRadius,
        },
    });
    return (React.createElement("div", { className: css(styles.background, classObject) },
        threshold && React.createElement("div", { className: css(styles.progress, styles.progressOverThreshold) }),
        React.createElement("div", { className: css(styles.progress) }),
        threshold && React.createElement("div", { className: css(styles.threshold) })));
};
