var _a, _b, _c;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { MediaBreakPointUp } from '@lean-body/components/styles';
import { Button, ButtonType } from '../atoms';
export var GoalMatchingAssessmentFormFooter = function (_a) {
    var isKindWeightGoal = _a.isKindWeightGoal, isEmptyValues = _a.isEmptyValues, onClickNext = _a.onClickNext, onClickCopyCurrentWeight = _a.onClickCopyCurrentWeight;
    return (React.createElement("div", { className: css(styles.container) },
        isKindWeightGoal && (React.createElement(Button, { className: css(styles.nextButton, styles.nextHalfButton), onClick: onClickCopyCurrentWeight, buttonType: ButtonType.secondary }, "\u73FE\u72B6\u7DAD\u6301")),
        React.createElement(Button, { className: css(styles.nextButton, isKindWeightGoal && styles.nextHalfButton), onClick: onClickNext, buttonType: isEmptyValues ? ButtonType.transparentPrimary : ButtonType.primary, disabled: isEmptyValues }, "\u6B21\u3078")));
};
var styles = StyleSheet.create({
    container: (_a = {
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            bottom: 16,
            width: 'calc(100% - 32px)',
            gap: 16
        },
        _a[MediaBreakPointUp.MD] = {
            width: '100%',
        },
        _a),
    nextButton: (_b = {
            width: '100%',
            height: 60,
            fontSize: 20,
            borderRadius: 30
        },
        _b[MediaBreakPointUp.MD] = {
            width: 343,
        },
        _b),
    nextHalfButton: (_c = {},
        _c[MediaBreakPointUp.MD] = {
            width: 163,
        },
        _c),
});
