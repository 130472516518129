import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { LessonCardForVertical } from '@lean-body/components/molecules';
import { VerticalListArrows } from '@lean-body/components/molecules/vertical_list_arrows';
import { separateArrayBySpecifiedNumber } from '@lean-body/src/util';
import { LIST_ITEM_MARGIN_RIGHT } from '../pages/main_home';
var VERTICAL_COUNT = 3;
export var LessonComponentCarouselSeparate = function (_a) {
    var lessons = _a.lessons, classObject = _a.classObject, verticalListArrowsClassObject = _a.verticalListArrowsClassObject, itemsPadding = _a.itemsPadding, addFavoriteLesson = _a.addFavoriteLesson, unFavoriteLesson = _a.unFavoriteLesson, itemSize = _a.itemSize, isBig = _a.isBig;
    return (React.createElement(VerticalListArrows, { itemMarginRight: LIST_ITEM_MARGIN_RIGHT, classObject: [verticalListArrowsClassObject, classObject, styles.container], itemsPadding: itemsPadding, itemSize: itemSize, items: separateArrayBySpecifiedNumber(lessons, VERTICAL_COUNT).map(function (lessons) { return (React.createElement("div", { className: css(styles.itemContainer) }, lessons.map(function (lesson, i) {
            return (React.createElement(LessonCardForVertical, { lesson: lesson, classObject: [styles.container, classObject], addFavoriteLesson: addFavoriteLesson, unFavoriteLesson: unFavoriteLesson, isBig: isBig, key: i }));
        }))); }) }));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'scroll',
        '::-webkit-scrollbar': {
            display: 'none',
        },
    },
});
