var _a;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Colors, MediaBreakPointUp, MediaBreakPointDown, transition, utils, drawerWidth, } from '@lean-body/components/styles';
import { LocalStorage } from '@lean-body/src/infra';
import { routes } from '@lean-body/src/route';
import { useLocation } from 'react-router';
import { observer } from 'mobx-react';
import { useXlMediaQuery } from '@lean-body/src/util/media_query_hooks';
import { IsCoachingCampaignTerm } from './coaching_lp';
import { useWindowSize } from '@lean-body/src/util';
import { ScrollIcon } from '../atoms/icons/scroll_icon';
import { ZIndex } from '../constants';
var SIDE_MENU_BOTTOM_PADDING = 20;
/**
 *
 * ```
 * - Menu
 *   - MenuHeader
 *   - MenuOverlay
 *   - MenuItemList
 *     - MenuItem[]
 * ```
 */
export var Menu = function (_a) {
    var className = _a.className, isMegalosUser = _a.isMegalosUser, userInvitationService = _a.userInvitationService, payment = _a.payment, unreadAnnouncementCount = _a.unreadAnnouncementCount, footer = _a.footer, onClickCoaching = _a.onClickCoaching;
    var windowSize = useWindowSize();
    var menuRef = React.useRef(null);
    var bottomRef = React.useRef(null);
    var displayedScrollBar = windowSize.height < 620;
    var menuContainerClass = [className, css(styles.menu)].join(' ');
    var _b = React.useState(false), isScrollBottom = _b[0], setIsScrollBottom = _b[1];
    React.useEffect(function () {
        if (!menuRef)
            return;
        var ref = menuRef.current;
        ref.addEventListener('scroll', toggleScroll);
        return function () {
            ref.removeEventListener('scroll', toggleScroll);
        };
    }, [menuRef]);
    var toggleScroll = function () {
        setIsScrollBottom(menuRef.current.scrollHeight - menuRef.current.clientHeight < menuRef.current.scrollTop + SIDE_MENU_BOTTOM_PADDING);
    };
    var onClickScrollIcon = function () {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.container) },
            React.createElement("div", { className: menuContainerClass, ref: menuRef },
                React.createElement(MenuHeader, null),
                React.createElement(MenuItemList, { isMegalosUser: isMegalosUser, unreadAnnouncementCount: unreadAnnouncementCount, userInvitationService: userInvitationService, payment: payment, footer: footer, onClickCoaching: onClickCoaching }),
                React.createElement("div", { ref: bottomRef }))),
        displayedScrollBar && !isScrollBottom && (React.createElement("div", { className: css(styles.scrollIconContainer) },
            React.createElement(ScrollIcon, { classObject: styles.scrollIcon, onClick: onClickScrollIcon })))));
};
var styles = StyleSheet.create({
    menu: {
        backgroundColor: Colors.White,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'fixed',
        minHeight: 400,
        borderRight: "1px solid ".concat(Colors.BorderLightGray),
        overflow: 'auto',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
            display: 'none',
        },
    },
    container: {
        position: 'relative',
    },
    scrollIconContainer: (_a = {
            position: 'fixed',
            left: "calc((".concat(drawerWidth.md, "px - 40px) / 2)"),
            bottom: 16,
            zIndex: ZIndex.MainMenuScrollIcon
        },
        _a[MediaBreakPointUp.XL] = {
            left: "calc((".concat(drawerWidth.xl, "px - 40px) / 2)"),
        },
        _a),
    scrollIcon: {
        cursor: 'pointer',
    },
});
/*=============================================
= Menu Header =
=============================================*/
var MenuHeader = function (props) {
    var _a, _b;
    var styles = StyleSheet.create({
        container: (_a = {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            _a[MediaBreakPointUp.MD] = {
                padding: '35px 27px',
            },
            _a[MediaBreakPointUp.XL] = {
                height: 'auto',
                paddingTop: '2rem',
                paddingBottom: '1.5rem',
                marginBottom: 0,
            },
            _a),
        logo: (_b = {},
            _b[MediaBreakPointUp.MD] = {
                width: 17,
                height: 30,
            },
            _b[MediaBreakPointUp.XL] = {
                maxWidth: 170,
                width: '100%',
                height: '100%',
            },
            _b),
        link: {
            display: 'block',
            width: '100%',
            textAlign: 'center',
        },
    });
    var containerClass = [props.className || '', css(styles.container)].join(' ');
    var logoClass = css(utils.imgFluid, styles.logo, styles.logo);
    var isXL = useXlMediaQuery();
    var logoSrc = isXL ? '/assets/images/logo-black.svg' : '/assets/images/logo.svg';
    return (React.createElement("div", { className: containerClass },
        React.createElement(Link, { to: "/", className: css(styles.link) },
            React.createElement("img", { src: logoSrc, className: logoClass }))));
};
/*=============================================
= MenuItemList =
=============================================*/
var MenuItemList = observer(function (props) {
    var styles = StyleSheet.create({
        menuItemList: {
            height: 'calc(100% - 60px)',
            display: 'flex',
            alignItems: 'flex-start',
            alignContent: 'space-between',
            flexWrap: 'wrap',
            position: 'relative',
            marginBottom: 25,
        },
        menuSection: {
            width: '100%',
        },
    });
    var footer = props.footer;
    var localStorage = new LocalStorage();
    var location = useLocation();
    var containerClass = [props.className || '', css(styles.menuItemList)].join(' ');
    var unreadAnnouncementCount = props.unreadAnnouncementCount && props.unreadAnnouncementCount.count;
    var isTopPage = routes.TOP.matchPath(location.pathname);
    var isLiveLessonPage = routes.LIVE_GROUP.matchPath(location.pathname);
    var isMyLessonPage = routes.MY_LESSON.matchPath(location.pathname);
    var isSearchPage = routes.SEARCH_GROUP.matchPath(location.pathname);
    var isActivityPage = routes.ACTIVITY.matchPath(location.pathname);
    var isMegalosLiveCalendarPage = routes.MEGALOS_LIVE_CALENDAR.matchPath(location.pathname);
    var isAnnouncementPage = routes.ANNOUNCEMENT_GROUP.matchPath(location.pathname);
    var isInvitationPage = routes.INVITATION.matchPath(location.pathname);
    var isSettingPage = routes.SETTING.matchPath(location.pathname);
    var coachingBadgeMessage = IsCoachingCampaignTerm() ? 'おトク!' : 'おすすめ!';
    var hasLiveLessonNotification = !isLiveLessonPage && !!(footer === null || footer === void 0 ? void 0 : footer.hasLiveLessonNotification);
    if (isLiveLessonPage && (footer === null || footer === void 0 ? void 0 : footer.hasLiveLessonNotification)) {
        localStorage.saveLiveScheduleTabNoticed();
    }
    return (React.createElement("div", { className: containerClass },
        React.createElement("div", { className: css(styles.menuSection) },
            React.createElement(MenuItem, { to: "/", text: "\u30DB\u30FC\u30E0", imgSrc: "/assets/images/icons/home.svg", imgSrcActive: "/assets/images/icons/home_light_blue.svg", isActive: isTopPage }),
            React.createElement(MenuItem, { to: routes.SEARCH.compile(), text: "\u898B\u3064\u3051\u308B", imgSrc: "/assets/images/icons/search.svg", imgSrcActive: "/assets/images/icons/search_light_blue.svg", isActive: isSearchPage }),
            React.createElement(MenuItem, { to: "/live_schedule", text: "LEAN BODY LIVE", imgSrc: "/assets/images/icons/live_stream.svg", imgSrcActive: "/assets/images/icons/live_stream_light_blue.svg", hasNotification: hasLiveLessonNotification, isActive: isLiveLessonPage }),
            React.createElement(MenuItem, { to: routes.MY_LESSON.compile(), text: "MY\u30EC\u30C3\u30B9\u30F3", imgSrc: "/assets/images/icons/my_lesson.svg", imgSrcActive: "/assets/images/icons/my_lesson_light_blue.svg", isActive: isMyLessonPage }),
            React.createElement(MenuItem, { to: routes.ACTIVITY.compile(), text: "\u30A2\u30AF\u30C6\u30A3\u30D3\u30C6\u30A3", imgSrc: "/assets/images/icons/activity.svg", imgSrcActive: "/assets/images/icons/activity_light_blue.svg", isActive: isActivityPage }),
            props.isMegalosUser && (React.createElement(MenuItem, { to: "/megalos_live_calendar", text: "\u30E1\u30AC\u30ED\u30B9 LIVE\u30EC\u30C3\u30B9\u30F3", imgSrc: "/assets/images/icons/megalos_live.svg", imgSrcActive: "/assets/images/icons/megalos_live_light_blue.svg", isActive: isMegalosLiveCalendarPage }))),
        React.createElement("div", { className: css(styles.menuSection) },
            props.payment.coachingPaymentAvailable && (React.createElement(MenuItem, { onClick: props.onClickCoaching, text: "\u30B3\u30FC\u30C1\u30F3\u30B0", imgSrc: "/assets/images/icons/chat.svg", isActive: false, message: coachingBadgeMessage })),
            React.createElement(MenuItem, { to: "/announcements", text: "\u304A\u77E5\u3089\u305B", imgSrc: "/assets/images/icons/bell.svg", imgSrcActive: "/assets/images/icons/bell_light_blue.svg", badgeCount: unreadAnnouncementCount, isActive: isAnnouncementPage }),
            props.userInvitationService.invitationAvailable(props.payment) && (React.createElement(MenuItem, { to: "/invitation", dataTest: "invitationTab", text: "\u304A\u53CB\u3060\u3061\u7D39\u4ECB", imgSrc: "/assets/images/icons/invitation.svg", imgSrcActive: "/assets/images/icons/invitation_light_blue.svg", isActive: isInvitationPage })),
            React.createElement(MenuItem, { to: routes.SETTING.compile(), text: "\u8A2D\u5B9A", imgSrc: "/assets/images/icons/setting.svg", imgSrcActive: "/assets/images/icons/setting_light_blue.svg", isActive: isSettingPage }))));
});
var MenuItem = function (props) {
    var _a, _b, _c, _d, _e;
    var styles = StyleSheet.create({
        menuItem: (_a = {
                height: 50,
                width: '100%',
                position: 'relative',
                marginBottom: 10
            },
            _a[MediaBreakPointUp.MD] = {
                padding: 0,
                marginRight: 0,
            },
            _a[MediaBreakPointUp.XL] = {
                padding: '0 20px',
                marginRight: 6,
            },
            _a),
        link: (_b = {
                display: 'flex',
                alignItems: 'center',
                fontSize: 14,
                borderRadius: 25,
                lineHeight: 1,
                textDecoration: 'none',
                cursor: 'pointer',
                transition: transition(),
                ':hover': {
                    backgroundColor: Colors.BackgroundLightGray,
                }
            },
            _b[MediaBreakPointUp.MD] = {
                width: 43,
                height: 43,
                padding: 6,
                margin: '3px 14px',
            },
            _b[MediaBreakPointUp.XL] = {
                width: '100%',
                height: '100%',
                padding: '10px 0 10px 20px',
                margin: 'auto',
            },
            _b),
        text: {
            fontSize: 14,
            fontWeight: 500,
            color: Colors.Black,
            letterSpacing: '0.04em',
            transition: transition('color'),
        },
        activeText: {
            color: Colors.PrimaryDarkB,
        },
        badge: (_c = {
                fontSize: 12,
                fontWeight: 'bold',
                textAlign: 'center',
                width: 16,
                height: 16,
                backgroundColor: Colors.Accent,
                color: Colors.White,
                borderRadius: 100,
                margin: '-1px 0 0 8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            _c[MediaBreakPointDown.XL] = {
                fontSize: 7.5,
                width: 15.25,
                height: 15.25,
                top: 5,
                right: 23,
                paddingBottom: 2,
                position: 'absolute',
            },
            _c),
        imgSrcIcon: (_d = {
                width: 28,
                height: 28,
                marginLeft: 1,
                transition: transition('color')
            },
            _d[MediaBreakPointUp.MD] = {
                marginRight: 0,
            },
            _d[MediaBreakPointUp.XL] = {
                marginRight: 12,
            },
            _d),
        notification: {
            backgroundColor: Colors.Primary,
            width: 4.5,
            height: 4.5,
            position: 'absolute',
            top: 13,
            left: 60,
            borderRadius: 100,
        },
        message: (_e = {
                fontSize: 12,
                fontWeight: 'bold',
                color: Colors.White,
                backgroundColor: Colors.Accent,
                padding: '2px 4px',
                borderRadius: 100,
                marginLeft: 14
            },
            _e[MediaBreakPointDown.XL] = {
                fontSize: 7.5,
                padding: '2px 3px',
                position: 'absolute',
                top: 7,
                right: 11,
            },
            _e),
    });
    var dataTest = props.dataTest, to = props.to, onClick = props.onClick, imgSrc = props.imgSrc, imgSrcActive = props.imgSrcActive, text = props.text, badgeCount = props.badgeCount, hasNotification = props.hasNotification, isActive = props.isActive, message = props.message;
    var isXL = useXlMediaQuery();
    return (React.createElement("div", { className: css(styles.menuItem), "data-test": dataTest }, to ? (React.createElement(Link, { to: to, className: css(styles.link) },
        imgSrc && React.createElement("img", { src: isActive ? imgSrcActive : imgSrc, className: css(styles.imgSrcIcon) }),
        isXL && React.createElement("div", { className: css(styles.text, isActive && styles.activeText) }, text),
        badgeCount ? React.createElement("div", { className: css(styles.badge) }, badgeCount) : '',
        hasNotification && React.createElement("div", { className: css(styles.notification) }),
        !!message && React.createElement("div", { className: css(styles.message) }, message))) : (React.createElement("div", { className: css(styles.link), onClick: onClick },
        imgSrc && React.createElement("img", { src: isActive ? imgSrcActive : imgSrc, className: css(styles.imgSrcIcon) }),
        isXL && React.createElement("div", { className: css(styles.text, isActive && styles.activeText) }, text),
        badgeCount ? React.createElement("div", { className: css(styles.badge) }, badgeCount) : '',
        hasNotification && React.createElement("div", { className: css(styles.notification) }),
        !!message && React.createElement("div", { className: css(styles.message) }, message)))));
};
