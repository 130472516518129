var _a, _b, _c;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { Link } from 'react-router-dom';
import { InitialGuideHowToDetail } from '../molecules';
import { InitialGuideQA } from '../molecules/initial_guide_qa';
var ChallengePlaylistHelpLink = 'https://wondernuts.zendesk.com/hc/ja/articles/900005201543--%E3%83%81%E3%83%A3%E3%83%AC%E3%83%B3%E3%82%B8%E3%83%97%E3%83%AC%E3%82%A4%E3%83%AA%E3%82%B9%E3%83%88-%E5%85%A8%E3%81%A6%E7%B5%82%E3%82%8F%E3%81%A3%E3%81%A6%E3%82%82%E4%BF%AE%E4%BA%86%E8%A8%BC%E3%81%8C%E7%8D%B2%E5%BE%97%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%9B%E3%82%93-%E3%81%A9%E3%81%86%E3%81%99%E3%82%8C%E3%81%B0%E7%8D%B2%E5%BE%97%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B-';
export var InitialGuideChallengePlaylistDetail = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u30C1\u30E3\u30EC\u30F3\u30B8\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3068\u306F\uFF1F"),
            React.createElement("div", { className: css(styles.sectionTitleBorder) }),
            React.createElement("div", { className: css(styles.topImgBox) },
                React.createElement("img", { className: css(styles.topImg), src: "/assets/images/initial_guide/challenge_playlist/challenge_playlist_top.png" })),
            React.createElement("div", { className: css(styles.description) },
                "\u76EE\u6A19\u3068\u671F\u9593\u3092\u6C7A\u3081\u3066\u3044\u3056\u6311\u6226\uFF01\u9806\u756A\u306B\u30EC\u30C3\u30B9\u30F3\u3092\u53D7\u3051\u3066\u3001\u30B3\u30F3\u30D7\u30EA\u30FC\u30C8\u3092\u76EE\u6307\u3057\u307E\u3057\u3087\u3046\u3002",
                React.createElement("br", null),
                "\u3044\u308D\u3044\u308D\u306A\u30EC\u30C3\u30B9\u30F3\u304C\u5165\u3063\u3066\u3044\u308B\u306E\u3067\u3001\u5927\u597D\u304D\u306A\u30EC\u30C3\u30B9\u30F3\u306B\u51FA\u4F1A\u3048\u308B\u304B\u3082\uFF01")),
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.howToSection) },
                React.createElement("div", { className: css(styles.sectionTitle) }, "\u30C1\u30E3\u30EC\u30F3\u30B8\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u306E\u4F7F\u3044\u65B9"),
                React.createElement("div", { className: css(styles.sectionTitleBorder) }),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/challenge_playlist/search_challenge_playlist.png", imgClassObject: styles.searchChallengePlaylistImg, stepNumber: 1, title: "\u597D\u304D\u306A\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u63A2\u305D\u3046" },
                    "\u671F\u9593\u3001\u30C6\u30FC\u30DE\u306E\u5408\u3046\u30C1\u30E3\u30EC\u30F3\u30B8\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u9078\u3073\u307E\u3057\u3087\u3046\uFF01",
                    React.createElement("br", null),
                    "\u898B\u3064\u3051\u308B\u30DA\u30FC\u30B8\u304B\u3089\u63A2\u3059\u3053\u3068\u304C\u3067\u304D\u307E\u3059"),
                React.createElement("div", { className: css(styles.triangleBox) },
                    React.createElement("div", { className: css(styles.triangle) })),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/challenge_playlist/take_challenge_playlist.png", imgClassObject: styles.takeChallengePlaylistImg, stepNumber: 2, title: "\u201C\u30C1\u30E3\u30EC\u30F3\u30B8\u3059\u308B\u201D\u304B\u3089\u30EC\u30C3\u30B9\u30F3\u3092\u53D7\u3051\u3088\u3046" }, "\u30EC\u30C3\u30B9\u30F3\u306E\u9032\u6357\u3092\u78BA\u8A8D\u3057\u306A\u304C\u3089\u9032\u3081\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059"),
                React.createElement("div", { className: css(styles.triangleBox) },
                    React.createElement("div", { className: css(styles.triangle) })),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/challenge_playlist/take_challenge_playlist_lesson.png", imgClassObject: styles.takeChallengePlaylistLessonImg, stepNumber: 3, title: "\u30C1\u30E3\u30EC\u30F3\u30B8\u4E2D\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u304B\u3089\u7D9A\u304D\u306E\u30EC\u30C3\u30B9\u30F3\u3092\u53D7\u3051\u3088\u3046" }, "\u30C1\u30E3\u30EC\u30F3\u30B8\u4E2D\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u306F\u3001MY\u30EC\u30C3\u30B9\u30F3\u306B\u4FDD\u5B58\u3055\u308C\u3066\u4FBF\u5229\uFF01"),
                React.createElement("div", { className: css(styles.triangleBox) },
                    React.createElement("div", { className: css(styles.triangle) })),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/challenge_playlist/get_certificate.png", imgClassObject: styles.getCertificateImg, stepNumber: 4, title: "\u30C1\u30E3\u30EC\u30F3\u30B8\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u3092\u6700\u5F8C\u307E\u3067\u9054\u6210\u3057\u3066\u4FEE\u4E86\u8A3C\u3092GET\uFF01" },
                    "\u30A2\u30AF\u30C6\u30A3\u30D3\u30C6\u30A3\u304B\u3089\u632F\u308A\u8FD4\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
                    React.createElement("br", null),
                    "\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3\u3092\u697D\u3057\u307F\u307E\u3057\u3087\u3046\uFF01"))),
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u3088\u304F\u3042\u308B\u8CEA\u554F"),
            React.createElement("div", { className: css(styles.sectionTitleBorder) }),
            React.createElement(InitialGuideQA, { question: "\u9014\u4E2D\u307E\u3067\u3084\u3063\u305F\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u304C\u3001\u201C\u30C1\u30E3\u30EC\u30F3\u30B8\u4E2D\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u201D\u306B\u898B\u5F53\u305F\u308A\u307E\u305B\u3093" }, "\u201C\u30C1\u30E3\u30EC\u30F3\u30B8\u3059\u308B\u201D\u30DC\u30BF\u30F3\u304B\u3089\u518D\u751F\u3057\u305F\u30C1\u30E3\u30EC\u30F3\u30B8\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u306E\u307F\u3001MY\u30EC\u30C3\u30B9\u30F3\u306E\u201C\u30C1\u30E3\u30EC\u30F3\u30B8\u4E2D\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u201D\u306B\u5165\u308A\u307E\u3059\u3002\u30C1\u30E3\u30EC\u30F3\u30B8\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u5185\u306E\u30EC\u30C3\u30B9\u30F3\u3092\u76F4\u63A5\u518D\u751F\u3057\u3066\u3082\u201C\u30C1\u30E3\u30EC\u30F3\u30B8\u4E2D\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u201D\u306B\u306F\u5165\u308A\u307E\u305B\u3093\u3002"),
            React.createElement(InitialGuideQA, { question: "\u30C1\u30E3\u30EC\u30F3\u30B8\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u304C\u5168\u3066\u7D42\u308F\u3063\u3066\u3082\u4FEE\u4E86\u8A3C\u304C\u7372\u5F97\u3067\u304D\u307E\u305B\u3093" },
                "\u65E9\u9001\u308A\u3057\u3066\u3082\u3001\u6700\u5F8C\u307E\u3067\u518D\u751F\u3059\u308B\u3068\u8996\u8074\u5B8C\u4E86\u3068\u3057\u3066\u53CD\u6620\u3055\u308C\u308B\u306E\u3067\u3001\u4E00\u5EA6\u65E9\u9001\u308A\u3067\u8996\u8074\u5B8C\u4E86\u3055\u308C\u308B\u304B\u304A\u8A66\u3057\u304F\u3060\u3055\u3044\u3002\u203B\u65E9\u9001\u308A\u306E\u5834\u5408\u3001\u203B\u30A2\u30AF\u30C6\u30A3\u30D3\u30C6\u30A3\u306E\u30EC\u30C3\u30B9\u30F3\u6642\u9593\u306F\u52A0\u7B97\u3055\u308C\u307E\u305B\u3093\u3002\u8A73\u3057\u304F\u306F",
                React.createElement(Link, { to: ChallengePlaylistHelpLink, className: css(styles.link), target: "_blank" }, "\u3053\u3061\u3089"),
                "\u3092\u3054\u89A7\u304F\u3060\u3055\u3044\u3002"))));
};
var styles = StyleSheet.create({
    section: (_a = {
            marginBottom: 40,
            paddingTop: 30,
            paddingLeft: 24,
            paddingRight: 24
        },
        _a[MediaBreakPointUp.MD] = {
            marginBottom: 0,
            paddingLeft: 40,
            paddingRight: 40,
        },
        _a),
    sectionTitle: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: '1.44px',
        height: 25,
    },
    sectionTitleBorder: {
        border: "3px solid ".concat(Colors.PrimaryDark),
        borderRadius: 3,
        width: 60,
        margin: '12px auto 12px',
    },
    topImgBox: {
        textAlign: 'center',
        marginTop: 35,
        marginBottom: 35,
    },
    topImg: {
        height: 161,
    },
    description: (_b = {
            backgroundColor: Colors.CyanLightest,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '160%',
            letterSpacing: '0.56px',
            padding: 24,
            marginBottom: 40,
            borderRadius: 10
        },
        _b[MediaBreakPointUp.MD] = {
            marginBottom: 10,
        },
        _b),
    howToSection: (_c = {},
        _c[MediaBreakPointUp.MD] = {
            marginLeft: 60,
            marginRight: 60,
        },
        _c),
    triangleBox: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 12,
        paddingBottom: 12,
    },
    triangle: {
        borderStyle: 'solid',
        borderWidth: '20px 15px 0',
        borderColor: "".concat(Colors.BackgroundGrayD, " transparent transparent transparent"),
        width: 31,
    },
    searchChallengePlaylistImg: {
        height: 205,
    },
    takeChallengePlaylistImg: {
        height: 234,
    },
    takeChallengePlaylistLessonImg: {
        height: 242,
    },
    getCertificateImg: {
        height: 235,
    },
    link: {
        color: Colors.PrimaryDark,
        fontSize: 14,
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
});
