var _a, _b, _c;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@lean-body/src/util';
import { InitialGuideCourseDetail, InitialGuideLiveDetail, InitialGuideSeriesDetail, InitialGuideSearchDetail, InitialGuideChallengePlaylistDetail, InitialGuidePlaylistDetail, InitialGuideMyLessonDetail, } from '../organisms';
import { ReplayIcon } from '../atoms';
import { routes } from '@lean-body/src/route';
export var INITIAL_GUIDE_TYPE_QUERY_PARAMS = 'type';
export var InitialGuideDetailTypes = {
    course: 'course',
    live: 'live',
    series: 'series',
    search: 'search',
    challengePlaylist: 'challengePlaylist',
    playlist: 'playlist',
    myLesson: 'myLesson',
    activity: 'activity',
};
var helpCenterLink = 'https://wondernuts.zendesk.com/hc/ja';
export var MainInitialGuideDetail = function () {
    var navigate = useNavigate();
    var query = useQuery();
    var type = query.get(INITIAL_GUIDE_TYPE_QUERY_PARAMS) || '';
    var onClickBack = function () {
        navigate(routes.INITIAL_GUIDE.compile());
    };
    return (React.createElement("div", { className: css(styles.container) },
        type === InitialGuideDetailTypes.course ? (React.createElement(InitialGuideCourseDetail, null)) : // LIVE
            type === InitialGuideDetailTypes.live ? (React.createElement(InitialGuideLiveDetail, null)) : // シリーズ
                type === InitialGuideDetailTypes.series ? (React.createElement(InitialGuideSeriesDetail, null)) : // 見つける
                    type === InitialGuideDetailTypes.search ? (React.createElement(InitialGuideSearchDetail, null)) : // チャレンジプレイリスト
                        type === InitialGuideDetailTypes.challengePlaylist ? (React.createElement(InitialGuideChallengePlaylistDetail, null)) : // プレイリスト
                            type === InitialGuideDetailTypes.playlist ? (React.createElement(InitialGuidePlaylistDetail, null)) : (
                            // MYレッスン
                            type === InitialGuideDetailTypes.myLesson && React.createElement(InitialGuideMyLessonDetail, null)),
        React.createElement("div", { className: css(styles.helpCenterText) },
            "\u4E0A\u8A18\u306B\u8A18\u8F09\u3055\u308C\u3066\u3044\u306A\u3044\u5185\u5BB9\u3067\u304A\u56F0\u308A\u306E\u65B9\u3084\u3001\u3055\u3089\u306B\u77E5\u308A\u305F\u3044\u65B9\u306F",
            React.createElement(Link, { to: helpCenterLink, className: css(styles.link), target: "_blank" }, "\u30D8\u30EB\u30D7\u30BB\u30F3\u30BF\u30FC"),
            "\u3092\u3054\u89A7\u304F\u3060\u3055\u3044\u3002"),
        React.createElement("div", { className: css(styles.backButtonBox) },
            React.createElement("div", { className: css(styles.backButton), onClick: onClickBack },
                React.createElement(ReplayIcon, { classObject: styles.backButtonIcon, color: Colors.PrimaryDark }),
                React.createElement("div", { className: css(styles.backButtonText) }, "\u306F\u3058\u3081\u3066\u30AC\u30A4\u30C9TOP\u306B\u3082\u3069\u308B")))));
};
var styles = StyleSheet.create({
    container: (_a = {
            marginBottom: 20
        },
        _a[MediaBreakPointUp.MD] = {
            marginBottom: 40,
        },
        _a[MediaBreakPointUp.XL] = {
            width: 696,
            margin: '0 auto 40px',
        },
        _a),
    link: {
        color: Colors.PrimaryDark,
        fontSize: 14,
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
    helpCenterText: (_b = {
            fontSize: 14,
            fontWeight: 400,
            padding: '30px 24px',
            lineHeight: '160%',
            letterSpacing: '0.56px'
        },
        _b[MediaBreakPointUp.MD] = {
            padding: '30px 40px',
        },
        _b),
    backButtonBox: (_c = {
            padding: '20px 24px 50px'
        },
        _c[MediaBreakPointUp.MD] = {
            paddingBottom: 30,
        },
        _c),
    backButton: {
        color: Colors.PrimaryDark,
        display: 'flex',
        flexDirection: 'row',
        gap: 6,
        margin: '0 auto',
        padding: '8px 13px',
        width: 208,
        height: 34,
        border: "1px solid ".concat(Colors.PrimaryDark),
        borderRadius: 17,
        cursor: 'pointer',
    },
    backButtonIcon: {
        width: 16,
        height: 16,
    },
    backButtonText: {
        fontSize: 11,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: '0.88px',
    },
});
