import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
export var FormGroup = function (props) {
    var containerClass = [props.className || '', css(styles.container)].join(' ');
    return React.createElement("div", { className: containerClass }, props.children);
};
var styles = StyleSheet.create({
    container: {
        marginBottom: '0.75rem',
    },
});
