var _a;
import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Input } from '../atoms';
import { Colors, MediaBreakPointUp } from '../styles';
import { SearchIcon } from '../atoms/icons/search_icon';
import { MealMenuList } from './meal_menu_list';
import { MealMenuSearchEmptyState } from '../molecules/meal_menu_search_empty_state';
import { APISequences } from '@lean-body/src/interfaces';
import { CalomealErrorMessage } from '../atoms/calomeal_error_message';
export var SEARCH_DEBOUNCE_MS = 300;
export var RecordMealMenuSearch = function (_a) {
    var apiClient = _a.apiClient, config = _a.config, selectedMenuMap = _a.selectedMenuMap, onClickSelectMenu = _a.onClickSelectMenu, onClickDeselectMenu = _a.onClickDeselectMenu, onClickMenu = _a.onClickMenu, setIsInputSearch = _a.setIsInputSearch, classObject = _a.classObject;
    var _b = React.useState(''), calomealErrMessage = _b[0], setCalomealErrMessage = _b[1];
    var _c = React.useState([]), mealMenus = _c[0], setMealMenus = _c[1];
    var _d = React.useState(''), searchValue = _d[0], setSearchValue = _d[1];
    var onSearchInputChange = function (event) {
        setSearchValue(event.target.value);
        if (event.target.value === '') {
            setIsInputSearch(false);
        }
        else {
            setIsInputSearch(true);
        }
    };
    var onSearchInputChangeDebounce = function (event) {
        var inputValue = event.target.value;
        onSearch(inputValue);
    };
    var onSearch = function (value) {
        if (value) {
            apiClient.appendToSequence(APISequences.MEAL_MENUS).then(function (next) {
                apiClient
                    .fetchMealMenus(value)
                    .then(function (v) {
                    setMealMenus(v.mealMenu);
                    setCalomealErrMessage(v.error);
                })
                    .finally(next);
            });
        }
    };
    var onSearchInputClear = function () {
        setSearchValue('');
        setIsInputSearch(false);
    };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(CalomealErrorMessage, { config: config, message: calomealErrMessage }),
        React.createElement("div", { className: css(styles.searchInputGroup) },
            React.createElement(Input, { value: searchValue, placeholder: "\u4F8B\uFF09\u3054\u306F\u3093", hideSpinner: true, classObject: styles.searchInput, closeIconContainerClassObject: styles.searchClearBtn, onChange: onSearchInputChange, onClickClearBtn: onSearchInputClear, debounceMs: SEARCH_DEBOUNCE_MS, onChangeDebounce: onSearchInputChangeDebounce }),
            React.createElement(SearchIcon, { classObject: styles.searchIcon, color: Colors.Black })),
        searchValue && (React.createElement(React.Fragment, null, mealMenus.length > 0 ? (React.createElement(MealMenuList, { mealMenus: mealMenus, selectedMenuMap: selectedMenuMap, onClickMenu: onClickMenu, onClickSelectMenu: onClickSelectMenu, onClickDeselectMenu: onClickDeselectMenu })) : (React.createElement(MealMenuSearchEmptyState, { classObject: styles.mealMenuEmptyState }))))));
};
var styles = StyleSheet.create({
    container: (_a = {
            marginTop: 8
        },
        _a[MediaBreakPointUp.MD] = {
            marginTop: 0,
        },
        _a),
    searchInputGroup: {
        position: 'relative',
    },
    searchIcon: {
        width: 20,
        height: 20,
        position: 'absolute',
        top: 15,
        left: 12,
    },
    searchInput: {
        paddingLeft: 32,
    },
    searchClearBtn: {
        width: 16,
        height: 16,
        top: 17,
        right: 17,
    },
    mealMenuEmptyState: {
        marginTop: 70,
    },
});
