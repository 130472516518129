import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useRefHooks } from '@lean-body/src/util/custom_hooks';
var MARGIN_RIGHT = 30;
var GRADIENT_SIZE = 9;
export var HorizontalScrollAnimation = function (_a) {
    var children = _a.children;
    var refHooksForChildren = useRefHooks();
    var childrenRef = refHooksForChildren.ref;
    var childrenElement = refHooksForChildren.element;
    var childrenWidth = (childrenElement === null || childrenElement === void 0 ? void 0 : childrenElement.offsetWidth) || 0;
    var refHooksForContainer = useRefHooks();
    var containerRef = refHooksForContainer.ref;
    var containerElement = refHooksForContainer.element;
    var containerWidth = (containerElement === null || containerElement === void 0 ? void 0 : containerElement.offsetWidth) || 0;
    var shouldScroll = childrenWidth >= containerWidth;
    var horizontalScrollAnimation = {
        '0%': {
            transform: 'translateX(0)',
        },
        '20%': {
            transform: 'translateX(0)',
        },
        '100%': {
            transform: "translateX(-".concat(childrenWidth + MARGIN_RIGHT, "px)"),
        },
    };
    var styles = StyleSheet.create({
        container: {
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
        },
        containerScrollable: {
            transform: "translateX(-".concat(GRADIENT_SIZE, "px)"),
        },
        verticalAnimation: {
            paddingLeft: GRADIENT_SIZE,
            display: 'flex',
            animationName: [horizontalScrollAnimation],
            animationDuration: '10s',
            animationIterationCount: 'infinite',
            'animation-timing-function': 'linear',
        },
        first: {
            marginRight: MARGIN_RIGHT,
        },
        whiteGradient: {
            content: '""',
            position: 'absolute',
            width: GRADIENT_SIZE,
            height: '100%',
            top: 0,
            left: 0,
            background: "linear-gradient(to left, rgba(255, 255, 255, 0), rgb(255, 255, 255))",
        },
        whiteGradientRight: {
            left: 'unset',
            right: -1,
            background: "linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255))",
        },
    });
    return (React.createElement("div", { className: css(styles.container, shouldScroll && styles.containerScrollable), ref: containerRef }, shouldScroll ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.verticalAnimation) },
            React.createElement("div", { className: css(styles.first), ref: childrenRef }, children),
            React.createElement("div", null, children)),
        React.createElement("div", { className: css(styles.whiteGradient) }),
        React.createElement("div", { className: css(styles.whiteGradient, styles.whiteGradientRight) }))) : (React.createElement(React.Fragment, null, children))));
};
