var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { ButtonFavorite } from '@lean-body/components/atoms/button_favorite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles/variables';
export var FavoriteWithCount = function (props) {
    var favCount = props.favCount, isStoppedFav = props.isStoppedFav, onClick = props.onClick, classObject = props.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(ButtonFavorite, { onClick: onClick, isStopped: isStoppedFav }),
        React.createElement("span", { className: css(styles.countText) }, favCount)));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
    },
    countText: (_a = {
            fontSize: 12,
            fontWeight: 'bold',
            color: Colors.Black
        },
        _a[MediaBreakPointUp.MD] = {
            fontSize: 14,
            margin: 0,
        },
        _a),
});
