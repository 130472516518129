var WorkoutCalories = /** @class */ (function () {
    function WorkoutCalories(data) {
        Object.defineProperty(this, "calories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "date", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.calories = data.calories;
        this.date = new Date(data.date * 1000);
    }
    return WorkoutCalories;
}());
export { WorkoutCalories };
