import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { ThreePointLeader } from '@lean-body/components/atoms';
import { Colors } from '@lean-body/components/styles';
import { useSmMediaQuery } from '@lean-body/src/util';
export var CourseHeaderDetail = function (_a) {
    var course = _a.course, classObject = _a.classObject, onClickSetting = _a.onClickSetting;
    var isSM = useSmMediaQuery();
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.name) }, isSM ? course.nameWithNewLine : course.name),
        React.createElement(ThreePointLeader, { onClick: onClickSetting, classObject: styles.threePointLeader })));
};
var styles = StyleSheet.create({
    container: {
        margin: 'auto 0',
    },
    name: {
        color: Colors.TextDark,
        letterSpacing: '0.04em',
        fontWeight: 'bold',
        fontSize: 24,
        marginBottom: 10,
        whiteSpace: 'pre-wrap',
    },
    threePointLeader: {
        cursor: 'pointer',
    },
});
