import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors } from '@lean-body/components/styles';
import { CircleCheckIcon } from './icons/circle_check_icon';
import { DonutsIcon } from './icons/donuts_icon';
export var PlaylistMetaAboveLessonName = function (_a) {
    var classObject = _a.classObject, index = _a.index, isDone = _a.isDone, isNext = _a.isNext;
    return (React.createElement("div", { className: css(classObject) },
        React.createElement("p", { className: css(styles.lessonIndex) },
            "Lesson",
            index),
        isDone && (React.createElement(React.Fragment, null,
            React.createElement("p", { className: css(styles.isDone) }, "Done"),
            React.createElement(CircleCheckIcon, { filled: true, color: Colors.PrimaryDark, classObject: styles.checkicon }))),
        isNext && (React.createElement(React.Fragment, null,
            React.createElement("p", { className: css(styles.isNext) }, "Next"),
            React.createElement(DonutsIcon, null)))));
};
var styles = StyleSheet.create({
    lessonIndex: {
        fontSize: 12,
        fontWeight: 'bold',
        marginRight: 4,
    },
    isDone: {
        fontSize: 10,
        fontWeight: 'bold',
        color: Colors.PrimaryDark,
        marginRight: 2,
    },
    isNext: {
        fontSize: 10,
        fontWeight: 'bold',
        color: Colors.Accent,
        marginRight: 2,
    },
    checkicon: {
        width: 10,
        height: 10,
    },
});
