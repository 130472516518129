var Maintenance = /** @class */ (function () {
    function Maintenance(data) {
        Object.defineProperty(this, "startAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "endAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "text", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.startAt = data.startAt;
        this.endAt = data.endAt;
        this.text = data.text;
    }
    return Maintenance;
}());
export { Maintenance };
