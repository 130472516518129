import * as React from 'react';
import { useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MediaBreakPointUp, Colors, MediaBreakPointDown } from '@lean-body/components/styles';
import { isToday, getLastDayOfMonth } from '@lean-body/src/util';
import { ClockIcon } from '../atoms';
export var ActivityCalendarBlock = function (props) {
    var _a, _b, _c, _d;
    if (!props.calendar.activities) {
        return null;
    }
    var isWatched = function (n) {
        var isWatched = false;
        if (!Array.isArray(props.calendar.activities)) {
            return false;
        }
        props.calendar.activities.map(function (date) {
            var splicedData = parseInt(date.date.slice(-2));
            if (splicedData == n) {
                isWatched = true;
            }
        });
        return isWatched;
    };
    var lastDate = getLastDayOfMonth(props.year, props.month);
    var calenderDates = new Array(lastDate).fill(0).map(function (_, i) { return 1 + i; });
    var blankCount = new Date(props.year, props.month - 1, 1).getDay();
    var blankPercentage = (100 / 7) * blankCount;
    var styles = StyleSheet.create({
        calendarSection: (_a = {
                display: 'inline-block',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% auto',
                width: '100%',
                padding: 15
            },
            _a[MediaBreakPointUp.MD] = {
                padding: 0,
            },
            _a),
        calendarSection1: {
            backgroundColor: '#C6E2E4',
            backgroundImage: 'url("../assets/images/activity/activity_bg_1.svg")',
        },
        calendarSection2: {
            backgroundColor: '#EDBAC9',
            backgroundImage: 'url("../assets/images/activity/activity_bg_2.png")',
        },
        calendarSection3: {
            backgroundColor: '#fee396',
            backgroundImage: 'url("../assets/images/activity/activity_bg_3.png")',
        },
        calendarSection4: {
            backgroundColor: '#fed1d0',
            backgroundImage: 'url("../assets/images/activity/activity_bg_4.png")',
        },
        calendarSection5: {
            backgroundColor: '#d2eeba',
            backgroundImage: 'url("../assets/images/activity/activity_bg_5.png")',
        },
        calendarSection6: {
            backgroundColor: '#e1cfee',
            backgroundImage: 'url("../assets/images/activity/activity_bg_6.png")',
        },
        calendarSection7: {
            backgroundColor: '#f1daa6',
            backgroundImage: 'url("../assets/images/activity/activity_bg_7.png")',
        },
        calendarSection8: {
            backgroundColor: '#b5dfeb',
            backgroundImage: 'url("../assets/images/activity/activity_bg_8.png")',
        },
        calendarSection9: {
            backgroundColor: '#dfe4b2',
            backgroundImage: 'url("../assets/images/activity/activity_bg_9.png")',
        },
        calendarSection10: {
            backgroundColor: '#fdc699',
            backgroundImage: 'url("../assets/images/activity/activity_bg_10.png")',
        },
        calendarSection11: {
            backgroundColor: '#ddc7b5',
            backgroundImage: 'url("../assets/images/activity/activity_bg_11.png")',
        },
        calendarSection12: {
            backgroundColor: '#766964',
            backgroundImage: 'url("../assets/images/activity/activity_bg_12.png")',
        },
        header: {
            padding: '8px 0 12px',
            textAlign: 'center',
        },
        headerImg: (_b = {
                height: 38
            },
            _b[MediaBreakPointUp.SM] = {
                height: 65,
            },
            _b),
        year: {
            color: Colors.White,
            display: 'block',
            fontSize: '0.768em',
            letterSpacing: 1,
        },
        innerContainer: (_c = {
                backgroundColor: '#fff',
                borderRadius: 8,
                boxShadow: '0 2px 10px 0 rgba(0,0,0,0.05)',
                margin: '0 auto 18px',
                position: 'relative',
                width: '90%'
            },
            _c[MediaBreakPointUp.XL] = {
                maxWidth: 500,
            },
            _c),
        calendarContainer: (_d = {
                padding: '14px 10px 16px'
            },
            _d[MediaBreakPointDown.XS] = {
                padding: '14px 7px 16px',
            },
            _d[MediaBreakPointUp.SM] = {
                marginTop: 20,
                marginBottom: 20,
                paddingTop: 24,
                paddingBottom: 24,
            },
            _d),
        bodyContainer: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        blank: {
            flex: "0 0 ".concat(blankPercentage, "%"),
        },
        weeks: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 8,
        },
        caption: {
            color: Colors.Gray,
            flex: '0 0 14.281%',
            fontWeight: 700,
            textAlign: 'center',
        },
        clockIcon: {
            width: 11,
            height: 13,
        },
        totalWatchMinutesContainer: {
            padding: 10,
        },
        totalWatchMinutesTitle: {
            fontSize: 10,
            fontWeight: 500,
            letteSpacing: 0.5,
            marginBottom: 10,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
        },
        totalWatchMinutes: {
            fontSize: 14,
            fontWeight: 700,
            letterSpacing: 0.56,
        },
        totalWatchMinutesUnit: {
            fontSize: 12,
            letterSpacing: 0.48,
        },
    });
    return (React.createElement("div", { className: css(styles.calendarSection, styles["calendarSection".concat(props.month)], props.classObject) },
        React.createElement("div", { className: css(styles.header) },
            React.createElement("img", { src: "/assets/images/activity/calendar_header_".concat(props.month, ".png"), className: css(styles.headerImg) }),
            React.createElement("span", { className: css(styles.year) }, props.year)),
        React.createElement("div", { className: css(styles.innerContainer, styles.calendarContainer), "data-test": "calender" },
            React.createElement(PaginateButton, { isNext: false, onClick: props.onClickPrev }),
            React.createElement(PaginateButton, { isNext: true, onClick: props.onClickNext }),
            React.createElement("ul", { className: css(styles.weeks) },
                React.createElement("li", { className: css(styles.caption) }, "S"),
                React.createElement("li", { className: css(styles.caption) }, "M"),
                React.createElement("li", { className: css(styles.caption) }, "T"),
                React.createElement("li", { className: css(styles.caption) }, "W"),
                React.createElement("li", { className: css(styles.caption) }, "T"),
                React.createElement("li", { className: css(styles.caption) }, "F"),
                React.createElement("li", { className: css(styles.caption) }, "S")),
            React.createElement("div", { className: css(styles.bodyContainer) },
                React.createElement("div", { className: css(styles.blank) }),
                calenderDates.map(function (date) {
                    return (React.createElement(ActivityCalendarDate, { key: date, number: date, year: props.year, month: props.month, isWatched: isWatched(date), isToday: isToday(props.year, props.month, date), fetchDailyActivity: props.fetchDailyActivity }));
                }))),
        React.createElement("div", { className: css(styles.innerContainer, styles.totalWatchMinutesContainer) },
            React.createElement("div", { className: css(styles.totalWatchMinutesTitle) },
                React.createElement(ClockIcon, { classObject: styles.clockIcon, color: Colors.Black }),
                "\u5408\u8A08\u30EC\u30C3\u30B9\u30F3\u6642\u9593"),
            React.createElement("div", { className: css(styles.totalWatchMinutes) },
                props.calendar.totalWatchMinutes.toLocaleString(),
                React.createElement("span", { className: css(styles.totalWatchMinutesUnit) }, "min")))));
};
export var ActivityCalendarDate = function (props) {
    var _a;
    var styles = StyleSheet.create({
        container: (_a = {
                position: 'relative',
                display: 'flex',
                fontSize: '0.9em',
                fontWeight: props.isToday ? 'bold' : 400,
                flex: '0 0 14.28%',
                justifyContent: 'center',
                padding: '4px 0',
                textAlign: 'center'
            },
            _a[MediaBreakPointUp.SM] = {
                paddingTop: 4,
                paddingBottom: 4,
            },
            _a),
        number: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            height: 30,
            width: 30,
        },
        watchedNumber: {
            backgroundColor: Colors.Primary,
            border: "4px solid ".concat(Colors.White),
            color: Colors.White,
            cursor: 'pointer',
        },
    });
    var useSpeechBubble = function () {
        var _a = useState(false), isShowing = _a[0], setIsShowing = _a[1];
        var _b = useState(), summary = _b[0], setSummary = _b[1];
        var toggle = function () {
            props
                .fetchDailyActivity(props.year, props.month, props.number)
                .then(function (summary) {
                setSummary(summary);
                setIsShowing(!isShowing);
            })
                .catch(function () {
                return;
            });
        };
        document.body.addEventListener('click', function () {
            setIsShowing(false);
            document.body.removeEventListener('click', toggle);
        });
        return { isShowing: isShowing, toggle: toggle, summary: summary };
    };
    var _b = useSpeechBubble(), isShowing = _b.isShowing, toggle = _b.toggle, summary = _b.summary;
    var classNames = [css(styles.number)];
    if (props.isWatched) {
        classNames.push(css(styles.watchedNumber));
    }
    var numberClassName = classNames.join(' ');
    var day = function () {
        var dayNumber = new Date(props.year, props.month - 1, props.number).getDay();
        switch (dayNumber) {
            case 0:
                return 'Sun';
            case 1:
                return 'Mon';
            case 2:
                return 'Tue';
            case 3:
                return 'Wed';
            case 4:
                return 'Thu';
            case 5:
                return 'Fri';
            case 6:
                return 'Sat';
        }
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(SpeechBubble, { isShowing: isShowing, isWatched: props.isWatched, month: props.month, date: props.number, day: day(), summary: summary }),
        React.createElement("span", { className: numberClassName, onClick: toggle }, props.number)));
};
var SpeechBubble = function (props) {
    var _a;
    var styles = StyleSheet.create({
        balloon: (_a = {
                position: 'absolute',
                boxShadow: '0 2px 10px 0 rgba(0,0,0,0.15)',
                width: '180px',
                height: '65px',
                padding: '5px',
                top: '-4.2rem',
                borderRadius: '10px 10px 10px 10px',
                backgroundColor: Colors.White
            },
            _a[MediaBreakPointUp.MD] = {
                top: '-4.5rem',
            },
            _a),
        header: {
            color: Colors.Gray,
            margin: '0.4rem',
        },
        content: {
            fontWeight: 600,
        },
    });
    var summary = props.summary;
    if (!props.isWatched || !props.isShowing || summary == undefined) {
        return null;
    }
    return (React.createElement("div", { className: css(styles.balloon) },
        React.createElement("div", { className: css(styles.header) },
            props.month,
            ".",
            props.date,
            " ",
            props.day),
        React.createElement("div", { className: css(styles.content) },
            summary.watchDuration,
            "min / ",
            summary.lessonCalories,
            "kcal")));
};
var PaginateButton = function (props) {
    var _a;
    var styles = StyleSheet.create({
        btn: (_a = {
                border: 'none',
                background: 'none',
                cursor: 'pointer',
                position: 'absolute',
                top: '46%',
                padding: 0,
                width: 'calc((100vw - 100% - 65px) / 2)',
                height: 30,
                backgroundColor: '#444444',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            _a[MediaBreakPointUp.MD] = {
                width: 25,
            },
            _a[MediaBreakPointUp.XL] = {
                width: 32,
                height: 32,
            },
            _a),
        icon: {
            color: Colors.White,
            height: 14,
        },
        prev: {
            left: 0,
            transform: 'translateX(-100%)',
        },
        next: {
            right: 0,
            transform: 'translateX(100%)',
        },
    });
    var btnClassName = [css(styles.btn), props.isNext ? css(styles.next) : css(styles.prev)].join(' ');
    return (React.createElement("button", { onClick: props.onClick, className: btnClassName }, props.isNext ? (React.createElement("img", { src: "/assets/images/icons/right_plane_arrow.svg", className: css(styles.icon) })) : (React.createElement("img", { src: "/assets/images/icons/left_plane_arrow.svg", className: css(styles.icon) }))));
};
