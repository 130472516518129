import { CourseDaysLesson, StepActivity, Lesson, LiveLesson } from '.';
var TASKS_COUNT = 4;
var TASKS_COUNT_WITHOUT_STEPS = 3;
export var MEAL_TASK_REMIND_AT_DEFAULT = '19:50';
export var TASK_DATE_QUERY = 'date';
var Task = /** @class */ (function () {
    function Task(data) {
        Object.defineProperty(this, "goal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "current", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isComplete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.goal = data.goal;
        this.current = data.current;
        this.isComplete = data.isComplete;
    }
    Object.defineProperty(Task, "sampleWorkout", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return new Task({ goal: 200, current: 200, isComplete: true });
        }
    });
    Object.defineProperty(Task, "sampleSteps", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return new Task({ goal: 5000, current: 1235, isComplete: false });
        }
    });
    Object.defineProperty(Task, "sampleWeight", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return new Task({ goal: 0, current: 55, isComplete: false });
        }
    });
    Object.defineProperty(Task, "roundTaskCalories", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: Math.round
    });
    return Task;
}());
export { Task };
var MealTask = /** @class */ (function () {
    function MealTask(data) {
        Object.defineProperty(this, "currentTotalCal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "referenceCalMin", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isComplete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hasEatenBreakfast", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hasEatenLunch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hasEatenDinner", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hasEatenSnacking", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.currentTotalCal = data.currentTotalCal;
        this.referenceCalMin = data.referenceCalMin;
        this.isComplete = data.isComplete;
        this.hasEatenBreakfast = data.hasEatenBreakfast;
        this.hasEatenLunch = data.hasEatenLunch;
        this.hasEatenDinner = data.hasEatenDinner;
        this.hasEatenSnacking = data.hasEatenSnacking;
    }
    Object.defineProperty(MealTask, "sample", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return new MealTask({
                currentTotalCal: 485,
                referenceCalMin: 1609,
                isComplete: false,
                hasEatenBreakfast: true,
                hasEatenLunch: true,
                hasEatenDinner: false,
                hasEatenSnacking: false,
            });
        }
    });
    Object.defineProperty(MealTask.prototype, "eatenMealsCount", {
        get: function () {
            return +this.hasEatenBreakfast + +this.hasEatenLunch + +this.hasEatenDinner + +this.hasEatenSnacking;
        },
        enumerable: false,
        configurable: true
    });
    return MealTask;
}());
export { MealTask };
var TaskLesson = /** @class */ (function () {
    function TaskLesson(data, config) {
        Object.defineProperty(this, "spentCalories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "durationSeconds", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "liveLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.spentCalories = data.spentCalories;
        this.durationSeconds = data.durationSeconds;
        this.lesson = data.lesson ? new Lesson(data.lesson, config) : null;
        this.liveLesson = data.liveLesson ? new LiveLesson(data.liveLesson, config) : null;
    }
    Object.defineProperty(TaskLesson.prototype, "durationMinutes", {
        get: function () {
            return Math.floor(this.durationSeconds / 60);
        },
        enumerable: false,
        configurable: true
    });
    return TaskLesson;
}());
export { TaskLesson };
var DailyWorkoutTask = /** @class */ (function () {
    function DailyWorkoutTask(data, config) {
        Object.defineProperty(this, "workout", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userCustomWorkouts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "courseDaysLessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "completedLessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "stepActivity", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.workout = new Task(data.workout);
        this.userCustomWorkouts = data.userCustomWorkouts;
        this.courseDaysLessons = data.courseDaysLessons.map(function (v) { return new CourseDaysLesson(v, config); });
        this.completedLessons = data.completedLessons.map(function (v) { return new TaskLesson(v, config); });
        this.stepActivity = data.stepActivity ? new StepActivity(data.stepActivity) : null;
    }
    return DailyWorkoutTask;
}());
export { DailyWorkoutTask };
var DailyStepsTask = /** @class */ (function () {
    function DailyStepsTask(data) {
        Object.defineProperty(this, "steps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "stepActivity", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.steps = new Task(data.steps);
        this.stepActivity = data.stepActivity ? new StepActivity(data.stepActivity) : null;
    }
    return DailyStepsTask;
}());
export { DailyStepsTask };
var DailyTasks = /** @class */ (function () {
    function DailyTasks(data) {
        Object.defineProperty(this, "meal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "workout", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "steps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "weight", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "stepsSynced", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isGoalsSet", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.meal = new MealTask(data.meal);
        this.workout = new Task(data.workout);
        this.steps = new Task(data.steps);
        this.weight = new Task(data.weight);
        this.stepsSynced = data.stepsSynced;
        this.isGoalsSet = data.isGoalsSet;
    }
    Object.defineProperty(DailyTasks.prototype, "completedTasksCount", {
        get: function () {
            return +this.meal.isComplete + +this.workout.isComplete + +this.steps.isComplete + +this.weight.isComplete;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DailyTasks.prototype, "totalTasksCount", {
        get: function () {
            return this.stepsSynced ? TASKS_COUNT : TASKS_COUNT_WITHOUT_STEPS;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DailyTasks, "sampleDailyTasks", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return new DailyTasks({
                meal: MealTask.sample(),
                workout: Task.sampleWorkout(),
                steps: Task.sampleSteps(),
                weight: Task.sampleWeight(),
                stepsSynced: true,
            });
        }
    });
    return DailyTasks;
}());
export { DailyTasks };
