import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles/variables';
import { FireIcon } from '@lean-body/components/atoms/icons/fire_icon';
export var PlaylistLabelInfo = function (_a) {
    var numOfLessons = _a.numOfLessons, programTotalMinCal = _a.programTotalMinCal, programTotalMaxCal = _a.programTotalMaxCal, totalDuration = _a.totalDuration, updatedDiff = _a.updatedDiff, className = _a.className;
    var styles = StyleSheet.create({
        labelInfo: {
            fontSize: 11,
            display: 'flex',
            lineHeight: 1,
        },
        calInfo: {
            marginRight: 24,
            marginLeft: 3,
            display: 'flex',
            alignItems: 'center',
        },
        fireIcon: {
            marginRight: 5,
        },
    });
    return (React.createElement("div", { className: [className, css(styles.labelInfo)].join(' ') },
        React.createElement("span", { className: css(styles.calInfo) },
            React.createElement(FireIcon, { color: Colors.Black, classObject: styles.fireIcon }),
            programTotalMinCal,
            " - ",
            programTotalMaxCal,
            " kcal"),
        numOfLessons,
        "\u30EC\u30C3\u30B9\u30F3",
        totalDuration && "\u30FB".concat(totalDuration),
        updatedDiff && "\u30FB".concat(updatedDiff, "\u66F4\u65B0")));
};
