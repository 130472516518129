var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { css, StyleSheet } from 'aphrodite';
import bind from 'bind-decorator';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import validator from 'validator';
import { FormError } from '../../errors';
import * as msg from '@lean-body/src/messages';
import { routes } from '@lean-body/src/route';
import { FooterComponent } from '@lean-body/components/organisms/footer';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Button, ButtonType, Input, Logo } from '../atoms';
import { useSmMediaQuery } from '@lean-body/src/util';
import { FORM_PASSWORD_PLACEHOLDER } from '@lean-body/components/molecules/input_form_group/password_vm';
export var PasswordResetPage = observer(function (_a) {
    var vm = _a.vm;
    var isSM = useSmMediaQuery();
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(Logo, { forHeader: isSM, classObject: styles.header }),
        React.createElement("div", { className: css(styles.contentWithFooter) },
            React.createElement("div", { className: css(styles.content) },
                React.createElement("div", { className: css(styles.title) }, "\u30D1\u30B9\u30EF\u30FC\u30C9\u30EA\u30BB\u30C3\u30C8"),
                React.createElement("div", { className: css(styles.message) }, "\u65B0\u3057\u3044\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044\u3002"),
                React.createElement(Input, { type: "password", label: "\u65B0\u3057\u3044\u30D1\u30B9\u30EF\u30FC\u30C9", value: vm.password, onChange: vm.onPasswordChanged, labelClassObject: styles.inputLabel, placeholder: FORM_PASSWORD_PLACEHOLDER, enablePwVisible: true }),
                React.createElement(Input, { type: "password", label: "\u65B0\u3057\u3044\u30D1\u30B9\u30EF\u30FC\u30C9(\u78BA\u8A8D)", value: vm.passwordConfirm, onChange: vm.onPasswordConfirmChanged, labelClassObject: styles.inputLabel, placeholder: "\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u518D\u5165\u529B", enablePwVisible: true }),
                React.createElement(Button, { buttonType: vm.password && vm.passwordConfirm ? ButtonType.primary : ButtonType.transparentPrimary, className: css(styles.button), onClick: vm.submit, loading: vm.isSubmitting, disabled: vm.isSubmitting, fullWidth: true, isRadius: true }, "\u78BA\u5B9A")),
            React.createElement(FooterComponent, null))));
});
var PasswordResetPageVM = /** @class */ (function () {
    function PasswordResetPageVM(apiClient, router, alertPresenter) {
        Object.defineProperty(this, "router", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "password", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "passwordConfirm", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "isSubmitting", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        makeObservable(this, {
            password: observable,
            passwordConfirm: observable,
            isSubmitting: observable,
        });
        this.apiClient = apiClient;
        this.alertPresenter = alertPresenter;
        this.router = router;
    }
    Object.defineProperty(PasswordResetPageVM.prototype, "onPasswordChanged", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            this.password = event.target.value;
        }
    });
    Object.defineProperty(PasswordResetPageVM.prototype, "onPasswordConfirmChanged", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            this.passwordConfirm = event.target.value;
        }
    });
    Object.defineProperty(PasswordResetPageVM.prototype, "token", {
        get: function () {
            return this.router.getQueryParams().get('token');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PasswordResetPageVM.prototype, "validate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var errors = [];
            if (validator.isEmpty(this.password)) {
                errors.push(msg.NEW_PASSWORD_EMPTY);
            }
            else if (validator.isEmpty(this.passwordConfirm)) {
                errors.push(msg.NEW_PASSWORD_CONFIRM_EMPTY);
            }
            else if (!validator.equals(this.password, this.passwordConfirm)) {
                errors.push(msg.NEW_PASSWORD_DOESNT_MATCH);
            }
            if (errors.length > 0) {
                this.alertPresenter.showError(new FormError('', errors.join('\n')), msg.INVALID_INPUT);
                return false;
            }
            return true;
        }
    });
    Object.defineProperty(PasswordResetPageVM.prototype, "submit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            var _this = this;
            event.preventDefault();
            if (!this.validate()) {
                return;
            }
            this.isSubmitting = true;
            this.apiClient
                .resetPassword({ token: this.token, password: this.password })
                .then(function () {
                _this.password = '';
                _this.passwordConfirm = '';
                _this.isSubmitting = false;
                _this.alertPresenter.showMessage('パスワードをリセットしました');
                _this.router.push(routes.SIGNIN);
            })
                .catch(function (err) {
                _this.isSubmitting = false;
                _this.alertPresenter.showError(err, 'パスワードのリセットに失敗しました');
            });
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PasswordResetPageVM.prototype, "onPasswordChanged", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PasswordResetPageVM.prototype, "onPasswordConfirmChanged", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PasswordResetPageVM.prototype, "submit", null);
    return PasswordResetPageVM;
}());
export { PasswordResetPageVM };
var styles = StyleSheet.create({
    container: (_a = {
            background: Colors.BackgroundLightGray
        },
        _a[MediaBreakPointUp.MD] = {
            paddingTop: 80,
        },
        _a),
    header: (_b = {},
        _b[MediaBreakPointUp.MD] = {
            width: 271,
            margin: '0 auto 18px',
        },
        _b),
    contentWithFooter: (_c = {
            padding: '40px 24px 0',
            backgroundColor: Colors.White,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 'calc(100vh - 50px)'
        },
        _c[MediaBreakPointUp.MD] = {
            backgroundColor: Colors.BackgroundLightGray,
            padding: 0,
            minHeight: 'calc(100vh - 146px)',
        },
        _c),
    content: (_d = {
            width: '100%'
        },
        _d[MediaBreakPointUp.MD] = {
            backgroundColor: Colors.White,
            borderRadius: 10,
            maxWidth: 375,
            margin: '0 auto',
            padding: '40px 24px',
        },
        _d),
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 14,
    },
    message: {
        color: Colors.GrayDarkest,
        fontSize: 14,
        fontWeight: 500,
        textAlign: 'center',
    },
    inputLabel: {
        color: Colors.Text,
        fontWeight: 'bold',
        marginTop: 14,
    },
    button: {
        fontSize: 16,
        marginTop: 30,
    },
});
