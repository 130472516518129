import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { SearchConditionsButton, SearchSortDialog } from '../molecules';
export var SelectOption = {
    NewArrival: 'new_arrival',
    Favorite: 'favorite',
    LengthLonger: 'length_longer',
    LengthShorter: 'length_shorter',
    Easy: 'easy',
    Difficult: 'difficult',
};
export var selectOptions = [
    {
        value: SelectOption.Favorite,
        name: '人気順',
    },
    {
        value: SelectOption.NewArrival,
        name: '新着順',
    },
    {
        value: SelectOption.LengthLonger,
        name: '時間が長い順',
    },
    {
        value: SelectOption.LengthShorter,
        name: '時間が短い順',
    },
    {
        value: SelectOption.Easy,
        name: '簡単順',
    },
    {
        value: SelectOption.Difficult,
        name: '難しい順',
    },
];
export var SearchConditionsButtonWithDialog = function (_a) {
    var label = _a.label, currentValue = _a.currentValue, onChangeSort = _a.onChangeSort;
    var _b = React.useState(false), showSortDialog = _b[0], setShowSortDialog = _b[1];
    var _c = React.useState(currentValue), sortBy = _c[0], setSortBy = _c[1];
    var onClickButton = function () {
        setShowSortDialog(true);
    };
    var submitSortDialogHandler = function () {
        onChangeSort(sortBy);
        setShowSortDialog(false);
    };
    var cancelSortDialogHandler = function () {
        setShowSortDialog(false);
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.searchConditionsContainer) },
            React.createElement(SearchConditionsButton, { label: label, enteredForButtonColor: false, onClick: onClickButton })),
        showSortDialog && (React.createElement(SearchSortDialog, { currentValue: sortBy, setCurrentValue: setSortBy, onClickSubmit: submitSortDialogHandler, onClickCancel: cancelSortDialogHandler }))));
};
var styles = StyleSheet.create({
    container: {},
    searchConditionsContainer: {
        display: 'flex',
        maxWidth: 800,
    },
});
