import { underscore } from 'inflected';
import { isPlainObject } from 'lodash-es';
export var underscoreKeys = function (object) {
    // Arrayは再帰実行
    if (Array.isArray(object)) {
        return object.map(function (v) { return underscoreKeys(v); });
    }
    // Object以外はそのまま返却
    if (!(object instanceof Object)) {
        return object;
    }
    if (isPlainObject(object) || Object.getPrototypeOf(object) === Object.prototype) {
        var underscored_1 = {};
        Object.keys(object).forEach(function (key, _) {
            underscored_1[underscore(key)] = underscoreKeys(object[key]);
        });
        return underscored_1;
    }
    // ------- 以降は Object や Array ではなく、Class判定 --------
    // jsonに変換できないClassはそのまま返却する
    if (object instanceof FormData) {
        return object;
    }
    // Classは一度jsonに変換してから、Objectとして、underscoreKeys()を実行する
    return underscoreKeys(JSON.parse(JSON.stringify(object)));
};
