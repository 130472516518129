import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { NutrientItem } from '../molecules/nutrient_item';
import { Colors } from '../styles';
export var NutrientItems = function (_a) {
    var nutrients = _a.nutrients, isSnackingType = _a.isSnackingType, isEntered = _a.isEntered, isShowMessage = _a.isShowMessage;
    var calorie;
    var items = nutrients.reduce(function (result, v) {
        if (v.isCalorie) {
            calorie = v;
        }
        else {
            result.push(v);
        }
        return result;
    }, []);
    return (React.createElement("div", { className: css(styles.container) },
        isSnackingType && React.createElement("div", { className: css(styles.annotation) }, "\u203B\u9593\u98DF\u306F\u76EE\u5B89\u304C\u8A2D\u5B9A\u3055\u308C\u307E\u305B\u3093"),
        calorie && (React.createElement(NutrientItem, { nutrient: calorie, classObject: styles.calorie, nameWithValueClassObject: styles.calorieLabel, isSnackingType: isSnackingType, isEntered: isEntered, isShowMessage: isShowMessage })),
        React.createElement("div", { className: css(styles.items) }, items.map(function (v, i) { return (React.createElement(NutrientItem, { nutrient: v, key: i, classObject: styles.item, isSnackingType: isSnackingType, isEntered: isEntered, isShowMessage: isShowMessage })); }))));
};
var styles = StyleSheet.create({
    container: {},
    annotation: {
        color: Colors.GrayDarkest,
        textAlign: 'center',
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '140%',
        margin: '24px 0 6px',
    },
    calorie: {
        marginBottom: 16,
    },
    calorieLabel: {
        display: 'flex',
        flexDirection: 'row',
        height: 22,
    },
    items: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 16,
    },
    item: {
        width: 'calc(50% - 8px)',
    },
});
