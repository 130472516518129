var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { ArrowDirection, ArrowIcon } from '../atoms';
import { Link } from 'react-router-dom';
import { routes } from '@lean-body/src/route';
import { DETAILS_QUERY_PARAMS, MyLessonDetails } from './my_lessons';
export var MyLessonHeaderSection = function (_a) {
    var subscribedLiveLessonCount = _a.subscribedLiveLessonCount, challengingPlaylistCount = _a.challengingPlaylistCount;
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(Link, { to: "".concat(routes.MY_LESSON.compile(), "?").concat(DETAILS_QUERY_PARAMS, "=").concat(MyLessonDetails.subscribedLiveLesson) },
            React.createElement("div", { className: css(styles.box) },
                React.createElement("div", { className: css(styles.boxSection) },
                    React.createElement("img", { src: '/assets/images/icons/live_stream_light_blue_outline.png', className: css(styles.icon) }),
                    React.createElement("div", { className: css(styles.title) }, "\u53C2\u52A0\u4E88\u5B9A\u306ELIVE\u30EC\u30C3\u30B9\u30F3")),
                React.createElement("div", { className: css(styles.boxSection) },
                    React.createElement("div", { className: css(styles.count) }, subscribedLiveLessonCount),
                    React.createElement(ArrowIcon, { classObject: styles.arrowIcon, color: Colors.GrayDarkest, direction: ArrowDirection.right })))),
        React.createElement(Link, { to: "".concat(routes.MY_LESSON.compile(), "?").concat(DETAILS_QUERY_PARAMS, "=").concat(MyLessonDetails.challengingPlaylist) },
            React.createElement("div", { className: css(styles.box) },
                React.createElement("div", { className: css(styles.boxSection) },
                    React.createElement("img", { src: '/assets/images/icons/list_with_check_light_blue.png', className: css(styles.icon) }),
                    React.createElement("div", { className: css(styles.title) }, "\u30C1\u30E3\u30EC\u30F3\u30B8\u4E2D\u306E\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8")),
                React.createElement("div", { className: css(styles.boxSection) },
                    React.createElement("div", { className: css(styles.count) }, challengingPlaylistCount),
                    React.createElement(ArrowIcon, { classObject: styles.arrowIcon, color: Colors.GrayDarkest, direction: ArrowDirection.right }))))));
};
var styles = StyleSheet.create({
    container: {
        marginBottom: 10,
    },
    box: (_a = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 52,
            marginRight: 20,
            marginBottom: 8,
            padding: '10px 12px',
            border: "1.5px solid ".concat(Colors.BorderLightGray),
            borderRadius: 4
        },
        _a[MediaBreakPointUp.MD] = {
            marginRight: 0,
        },
        _a),
    boxSection: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        color: Colors.Black,
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '0.04em',
    },
    count: {
        color: Colors.GrayDarkest,
        fontSize: 14,
        fontWeight: 500,
        marginRight: 10,
    },
    icon: {
        marginRight: 6,
    },
    arrowIcon: {
        width: 11,
    },
});
