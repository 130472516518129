import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { CheckIcon } from './icons/check_icon';
export var CheckBox = function (props) {
    var _a = React.useState(false), value = _a[0], setValue = _a[1];
    var onClick = function () {
        setValue(!value);
        props.onChange();
    };
    return (React.createElement("label", { className: css(styles.container, props.classObject), "data-test": props.dataTest },
        value ? (React.createElement(CheckIcon, { color: Colors.White, classObject: [styles.checkIcon, styles.checked] })) : (React.createElement("div", { className: css(styles.checkIcon, styles.noChecked) })),
        React.createElement("input", { className: css(styles.checkbox), type: "checkbox", onChange: onClick }),
        React.createElement("span", { className: css(styles.label, props.labelClassObject) }, props.children)));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    checkIcon: {
        width: 16,
        height: 16,
        marginRight: 8,
        borderRadius: 2,
        cursor: 'pointer',
    },
    checked: {
        backgroundColor: Colors.PrimaryDark,
    },
    noChecked: {
        border: "1px solid ".concat(Colors.Gray),
    },
    label: {
        color: Colors.GrayDarkest,
        fontSize: 12,
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    checkbox: {
        display: 'none',
    },
});
