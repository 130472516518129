/* eslint-disable @typescript-eslint/camelcase */
export var FBPixelEventName = {
    EVENT_EF1: 'AddToCart',
    EVENT_EF2: 'AddPaymentInfo',
    EVENT_EF3: 'InitiateCheckout',
    COMPLET_EREGISTRATION: 'CompleteRegistration',
    PAGE_VIEW: 'PageView',
};
var FBPixelClient = /** @class */ (function () {
    function FBPixelClient(pixelId) {
        Object.defineProperty(this, "pixelId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.pixelId = pixelId;
        this.loadScript();
    }
    Object.defineProperty(FBPixelClient.prototype, "loadScript", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var script = document.createElement('script');
            script.innerHTML = "\n        !function(f,b,e,v,n,t,s)\n        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n        n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n        n.queue=[];t=b.createElement(e);t.async=!0;\n        t.src=v;s=b.getElementsByTagName(e)[0];\n        s.parentNode.insertBefore(t,s)}(window, document,'script',\n        'https://connect.facebook.net/en_US/fbevents.js');\n        fbq('init', '".concat(this.pixelId, "');\n        ");
            var noscript = document.createElement('noscript');
            noscript.innerHTML = "\n        <img height=\"1\" width=\"1\" style=\"display:none\"\n          src=\"https://www.facebook.com/tr?id=534332050284527&ev=PageView&noscript=1\"\n        />\n        ";
            document.getElementsByTagName('head')[0].appendChild(script);
            document.getElementsByTagName('head')[0].appendChild(noscript);
        }
    });
    // https://www.facebook.com/business/help/1549524211985335?helpref=faq_content
    Object.defineProperty(FBPixelClient.prototype, "trackEvent", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (eventName, eventId) {
            fbq('track', eventName, {}, { event_id: eventId });
        }
    });
    return FBPixelClient;
}());
export { FBPixelClient };
