var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
export var VideoInfoRow = function (props) {
    if (!props.value) {
        return null;
    }
    return (React.createElement("div", { className: css(styles.row) },
        React.createElement("div", { className: css(styles.name) },
            props.name,
            "\uFF1A"),
        React.createElement("div", { className: css(styles.value) }, props.value)));
};
var styles = StyleSheet.create({
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    name: (_a = {
            fontSize: 11,
            fontWeight: 300,
            lineHeight: '1.5',
            color: Colors.GrayDarkest
        },
        _a[MediaBreakPointUp.SM] = {
            fontSize: 12,
        },
        _a),
    value: (_b = {
            fontSize: 11,
            fontWeight: 300,
            lineHeight: '1.5',
            color: Colors.GrayDarkest
        },
        _b[MediaBreakPointUp.SM] = {
            fontSize: 12,
        },
        _b),
});
