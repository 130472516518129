import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles/variables';
import { FireIcon } from '@lean-body/components/atoms/icons/fire_icon';
export var TodaysWorkout = function (_a) {
    var courseDaysLesson = _a.courseDaysLesson, classObject = _a.classObject;
    var styles = StyleSheet.create({
        container: {
            display: 'flex',
            alignItems: 'center',
            gap: 12,
            color: Colors.Black,
        },
        thumbnail: {
            borderRadius: '10px',
            width: '88px',
            height: '88px',
            flexShrink: 0,
            backgroundImage: "url(".concat(courseDaysLesson.lesson.thumbnailURL, ")"),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        },
        lessonInfo: {
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
        },
        lessonName: {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '140%',
            letterSpacing: '0.56px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': '3',
        },
        fireIcon: {
            width: '16px',
            height: '16px',
        },
        caloriesContainer: {
            display: 'flex',
        },
        calories: {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '160%',
            letterSpacing: '0.48px',
        },
    });
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.thumbnail) }),
        React.createElement("div", { className: css(styles.lessonInfo) },
            React.createElement("div", { className: css(styles.lessonName) }, courseDaysLesson.lesson.name),
            React.createElement("div", { className: css(styles.caloriesContainer) },
                React.createElement(FireIcon, { color: Colors.Black, classObject: styles.fireIcon }),
                React.createElement("span", { className: css(styles.calories) },
                    courseDaysLesson.lesson.minCal,
                    "-",
                    courseDaysLesson.lesson.maxCal,
                    "kcal")))));
};
