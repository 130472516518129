import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var SearchLoading = function (props) {
    var styles = StyleSheet.create({
        caption: {
            display: 'block',
            textAlign: 'center',
            fontSize: '0.9em',
            letterSpacing: 1,
            color: Colors.Gray,
            marginTop: 100,
            marginBottom: 250,
        },
    });
    return React.createElement("span", { className: css(styles.caption) }, "\u8AAD\u307F\u8FBC\u307F\u4E2D");
};
