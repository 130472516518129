var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { MediaBreakPointUp } from '@lean-body/components/styles';
export var SearchSuggestionSection = function (props) {
    if (!Array.isArray(props.items)) {
        return React.createElement("div", { className: css(styles.empty) }, "\u30A2\u30A4\u30C6\u30E0\u304C\u3042\u308A\u307E\u305B\u3093\u3002");
    }
    return (React.createElement("div", { className: css(styles.itemsContainer) }, props.items.map(function (item, idx) { return (React.createElement("div", { className: css(styles.item), onClick: function () { return props.onClickItem(item.id); }, key: idx },
        React.createElement("div", { className: css(styles.imgContainer) },
            React.createElement("img", { className: css(styles.img), src: item.thumbnailURL(props.config) })),
        React.createElement("div", { className: css(styles.slug) }, item.name))); })));
};
var styles = StyleSheet.create({
    itemsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: 8,
    },
    empty: {
        color: Colors.GrayDarkest,
        fontSize: 16,
        marginBottom: 10,
    },
    item: (_a = {
            width: 'calc(50% - 4px)',
            overflow: 'hidden',
            cursor: 'pointer'
        },
        _a[MediaBreakPointUp.LG] = {
            width: 'calc(25% - 6px)',
        },
        _a),
    imgContainer: {
        height: 80,
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 4,
    },
    slug: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1,
        margin: '8px 0',
        textAlign: 'center',
    },
});
