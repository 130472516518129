import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
var DEFAULT_HEIGHT = 200;
export var ImageBlurCircle = function (_a) {
    var src = _a.src, height = _a.height;
    var styles = StyleSheet.create({
        box: {
            position: 'absolute',
            minHeight: height || DEFAULT_HEIGHT,
            top: 0,
            right: 0,
            left: 0,
            borderBottomRightRadius: '800px 300px',
            borderBottomLeftRadius: '800px 300px',
            marginLeft: -200,
            marginRight: -200,
            paddingLeft: 200,
            paddingRight: 200,
            overflow: 'hidden',
        },
        blur: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            filter: 'blur(100px)',
            ':before': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                backgroundImage: "url(".concat(src, ")"),
                backgroundPosition: 'center',
                backgroundSize: '50%',
            },
        },
    });
    return (React.createElement("div", { className: css(styles.box) },
        React.createElement("div", { className: css(styles.blur) })));
};
