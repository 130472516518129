var _a;
import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Button, ButtonType } from '../atoms';
import { MealLabel, MealType } from '@lean-body/src/domain/meal';
import { Colors, MediaBreakPointUp } from '../styles';
export var MealSelectButtons = function (_a) {
    var selected = _a.selected, onClick = _a.onClick, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) }, Object.values(MealType).map(function (mealType) { return (React.createElement(Button, { key: mealType, buttonType: selected === mealType && ButtonType.secondary, isRadius: true, onClick: function () { return onClick(mealType); }, className: css(styles.button, selected === mealType && styles.selected) }, MealLabel[mealType])); })));
};
var styles = StyleSheet.create({
    container: (_a = {
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: 12,
            gap: 12
        },
        _a[MediaBreakPointUp.MD] = {
            gap: 20,
        },
        _a),
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        height: 30,
        padding: '0 12px',
        fontSize: 12,
        fontWeight: 500,
        letterSpacing: 0.48,
    },
    selected: {
        backgroundColor: Colors.PrimaryLighter,
    },
});
