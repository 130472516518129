var _a, _b, _c, _d;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, icon, MediaBreakPointUp, utils } from '@lean-body/components/styles';
export var Equip = function (props) {
    var containerClass = [props.className || '', css(styles.container)].join(' ');
    var e = props.equip;
    var iconClass = css(icon.anticon, icon.anticonExternalLink, styles.icon);
    return (React.createElement("div", { className: containerClass },
        React.createElement("a", { href: e.linkTo, className: css(styles.imgContainer), target: "_blank" },
            React.createElement("img", { src: e.thumbnailURL, className: css(utils.imgFluid) })),
        React.createElement("div", { className: css(styles.name) },
            e.name,
            " ",
            React.createElement("i", { className: iconClass }))));
};
var borderRadiusSize = 4;
var imgSize = {
    xs: 80,
    lg: 100,
};
var styles = StyleSheet.create({
    container: (_a = {
            width: imgSize.xs
        },
        _a[MediaBreakPointUp.LG] = {
            width: imgSize.lg,
        },
        _a),
    icon: {
        fontSize: '.875rem',
        verticalAlign: 'bottom',
    },
    imgContainer: (_b = {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Colors.White,
            border: "1px solid ".concat(Colors.Gray),
            borderRadius: borderRadiusSize,
            overflow: 'hidden',
            marginBottom: '.5rem',
            width: imgSize.xs,
            height: imgSize.xs
        },
        _b[MediaBreakPointUp.LG] = {
            width: imgSize.lg,
            height: imgSize.lg,
        },
        _b[':before'] = (_c = {
                content: '""',
                display: 'block',
                position: 'absolute',
                zIndex: 1,
                backgroundColor: Colors.Black,
                opacity: 0,
                transition: 'opacity .6s ease',
                borderRadius: borderRadiusSize,
                width: imgSize.xs,
                height: imgSize.xs
            },
            _c[MediaBreakPointUp.LG] = {
                width: imgSize.lg,
                height: imgSize.lg,
            },
            _c),
        _b[':hover:before'] = {
            opacity: 0.7,
        },
        _b[':after'] = {
            content: '"詳細"',
            display: 'inline-block',
            position: 'absolute',
            zIndex: 2,
            top: 0,
            bottom: 0,
            margin: 'auto',
            color: Colors.White,
            opacity: 0,
            transition: 'opacity .6s ease',
            fontSize: '.875rem',
            height: '1em',
            lineHeight: 1,
        },
        _b[':hover:after'] = {
            opacity: 1,
        },
        _b),
    name: (_d = {
            color: Colors.GrayDarkest,
            lineHeight: 1.2,
            textAlign: 'center',
            fontSize: '10px'
        },
        _d[MediaBreakPointUp.LG] = {
            fontSize: '12px',
        },
        _d),
});
