var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { routes } from '@lean-body/src/route';
import { useNavigate } from 'react-router';
import { dateToYearMonthDayString, isWithinDaysFromToday, ONE_DAY_IN_MS } from '@lean-body/src/util';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { ArrowDirection, ArrowIconWithMargin, CloseIcon } from '../atoms';
import { LocalStorage } from '@lean-body/src/infra';
import { SettingItemKeys } from '../organisms/setting_menu';
import { ZIndex } from '../constants';
var MONTHLY_PLAN_DISPLAY_DAYS = 7;
var ANNUAL_PLAN_DISPLAY_DAYS = 30;
export var CancelUnsubscribeBanner = function (_a) {
    var me = _a.me, tracker = _a.tracker;
    var navigate = useNavigate();
    var localStorage = new LocalStorage();
    var displayDays = (me === null || me === void 0 ? void 0 : me.payment.currentPlan.isAnnualPlan()) ? ANNUAL_PLAN_DISPLAY_DAYS : MONTHLY_PLAN_DISPLAY_DAYS;
    // 退会予定日が30日(年間プラン)or7日(月額プラン)以内である、かつ前回表示から24時間以上経っている場合にバナーを表示
    var initIsDisplay = (me === null || me === void 0 ? void 0 : me.unsubscribeScheduledAt) &&
        isWithinDaysFromToday(me.payment.curPeriodEndDate, displayDays) &&
        localStorage.loadDeletedAtCancelUnsubscribeBanner < Date.now() - ONE_DAY_IN_MS;
    var _b = React.useState(initIsDisplay), isDisplay = _b[0], setIsDisplay = _b[1];
    var onClick = function () {
        tracker.trackCancelUnsubscribeBannerClick();
        navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.plan));
    };
    var onClickCloseIcon = function () {
        setIsDisplay(false);
        localStorage.setDeletedAtCancelUnsubscribeBanner(Date.now());
    };
    return (React.createElement(React.Fragment, null, isDisplay && (React.createElement("div", { className: css(styles.container) },
        React.createElement(CloseIcon, { color: Colors.White, size: 32, onClick: onClickCloseIcon, classObject: styles.closeIcon }),
        React.createElement("div", { className: css(styles.bannerText) },
            React.createElement("div", { className: css(styles.bannerTitle) }, "\u672C\u5F53\u306B\u9000\u4F1A\u3057\u307E\u3059\u304B\uFF1F\uD83D\uDE22"),
            React.createElement("div", { className: css(styles.bannerDiscription) },
                dateToYearMonthDayString(me.payment.curPeriodEndDate),
                "\u4EE5\u964D\u30B5\u30FC\u30D3\u30B9\u3092",
                React.createElement("br", null),
                "\u3054\u5229\u7528\u3067\u304D\u306A\u304F\u306A\u308A\u307E\u3059")),
        React.createElement("div", { className: css(styles.bannerButton), onClick: onClick },
            React.createElement("div", { className: css(styles.buttonText) }, "\u9000\u4F1A\u3092\u53D6\u308A\u6D88\u3059"),
            React.createElement(ArrowIconWithMargin, { direction: ArrowDirection.right, color: Colors.Accent, classObject: styles.arrowIcon }))))));
};
var styles = StyleSheet.create({
    container: (_a = {
            display: 'flex',
            alignItems: 'center',
            color: Colors.White,
            backgroundColor: Colors.Accent,
            gap: 4,
            fontWeight: 'bold',
            width: '100%',
            height: 68,
            padding: '10px 10px 10px 4px'
        },
        _a[MediaBreakPointUp.XL] = {
            position: 'fixed',
            width: 383,
            bottom: 42,
            right: 26,
            borderRadius: 10,
            boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.20)',
            zIndex: ZIndex.HomePositionFixed,
        },
        _a),
    closeIcon: (_b = {
            padding: 11
        },
        _b[MediaBreakPointUp.XL] = {
            top: -17,
        },
        _b),
    bannerText: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 6,
    },
    bannerTitle: {
        fontSize: 14,
        lineHeight: '100%',
    },
    bannerDiscription: {
        fontSize: 10,
        lineHeight: '140%',
        letterSpacing: 0.3,
    },
    bannerButton: {
        color: Colors.Accent,
        backgroundColor: Colors.White,
        display: 'flex',
        alignItems: 'center',
        width: 118,
        height: 35,
        padding: '7px 4px 8px 10px',
        borderRadius: 17,
        cursor: 'pointer',
    },
    buttonText: {
        fontSize: 11,
        lineHeight: '160%',
        letterSpacing: 0.88,
        paddingTop: 2,
    },
    arrowIcon: {
        width: 20,
        height: 20,
    },
});
