import { Program } from './program';
import { LiveLesson } from './live_lesson';
import { Announcement, Lesson } from '.';
import { LessonType, routes } from '../route';
var Banner = /** @class */ (function () {
    function Banner(data, config) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "publishAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "spImageSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pcImageSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "programId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "program", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "liveLessonId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "liveLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "announcementId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "announcement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "linkPath", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "linkUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.spImageSrc = config.cdnBaseURL + data.spImageSrc;
        this.pcImageSrc = config.cdnBaseURL + data.pcImageSrc;
        this.lessonId = data.lessonId;
        this.lesson = data.lesson ? new Lesson(data.lesson, config) : null;
        this.programId = data.programId;
        this.program = data.program ? new Program(data.program, config) : null;
        this.liveLessonId = data.liveLessonId;
        this.liveLesson = data.liveLesson ? new LiveLesson(data.liveLesson, config) : null;
        this.announcementId = data.announcementId;
        this.announcement = data.announcement ? new Announcement(data.announcement, config) : null;
        this.linkPath = data.linkPath;
        this.linkUrl = data.linkUrl;
    }
    Object.defineProperty(Banner.prototype, "makeLinkPath", {
        get: function () {
            if (this.lesson) {
                return routes.LESSON.compile({ slug: this.lesson.slug, type: LessonType.normal });
            }
            else if (this.program) {
                return routes.PROGRAM.compile({ slug: this.program.slug });
            }
            else if (this.liveLesson) {
                return routes.LIVE_LESSON.compile({ id: this.liveLesson.id.toString() });
            }
            else if (this.announcement) {
                return routes.ANNOUNCEMENT_DETAIL.compile({ id: this.announcement.id.toString() });
            }
            else if (this.linkPath) {
                return this.convertedLinkPath;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Banner.prototype, "convertedLinkPath", {
        get: function () {
            switch (this.linkPath) {
                case 'matching_assessment':
                    return routes.MATCHING_ASSESSMENT.compile();
                case 'invitation':
                    return routes.INVITATION.compile();
                case 'initial_guide':
                    return routes.INITIAL_GUIDE.compile();
                case 'billys_studio':
                    return routes.BILLYS_STUDIO.compile();
                case 'coaching_lp':
                    return routes.COACHING.compile();
                default:
                    return '';
            }
        },
        enumerable: false,
        configurable: true
    });
    return Banner;
}());
export { Banner };
