/* eslint-disable */
import { matchPath } from 'react-router';
// tslint:disable-next-line:class-name
var _Route = /** @class */ (function () {
    function _Route(path) {
        Object.defineProperty(this, "path", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.path = path;
    }
    Object.defineProperty(_Route.prototype, "compile", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            var compiled = this.path;
            if (!params) {
                return compiled;
            }
            Object.keys(params).forEach(function (key, _) {
                compiled = compiled.replace(":".concat(key), params[key]);
            });
            return compiled;
        }
    });
    Object.defineProperty(_Route.prototype, "matchPath", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (path) {
            return !!matchPath(this, path);
        }
    });
    Object.defineProperty(_Route.prototype, "matchPathWithParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (path, params) {
            var compiled = this.compile(params);
            return !!matchPath(compiled, path);
        }
    });
    Object.defineProperty(_Route.prototype, "with", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this;
        }
    });
    return _Route;
}());
export { _Route };
export function Route(path) {
    return new _Route(path);
}
// tslint:disable-next-line:class-name
var _RouteGroup = /** @class */ (function () {
    function _RouteGroup(routes) {
        Object.defineProperty(this, "routes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.routes = routes;
    }
    Object.defineProperty(_RouteGroup.prototype, "matchPath", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (path) {
            return !!this.routes.find(function (r) { return r.matchPath(path); });
        }
    });
    return _RouteGroup;
}());
export { _RouteGroup };
export function RouteGroup(routes) {
    return new _RouteGroup(routes);
}
