import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { MypageDetailRow } from '@lean-body/components/molecules';
import { SettingItemKeys } from '@lean-body/components/organisms/setting_menu';
import { Button, ButtonType } from '@lean-body/components/atoms';
import { Avatar } from '@mui/material';
import CopyText from 'react-copy-text';
export var ProfileDetailSubsection = function (_a) {
    var classObject = _a.classObject, setItemKey = _a.setItemKey, profile = _a.profile;
    var userId = profile.userId;
    var _b = React.useState(''), copyText = _b[0], setCopyText = _b[1];
    var _c = React.useState(false), copied = _c[0], setCopied = _c[1];
    var onClick = function () {
        setItemKey(SettingItemKeys.editMypage);
        window.scrollTo(0, 0);
    };
    var onClickCopyButton = function () {
        setCopied(true);
        setCopyText(userId.toString());
    };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(Avatar, { src: profile.pictureSrc, className: css(styles.profilePicture, profile.profilePictureSrc && styles.avatarBorder) }),
        React.createElement("div", { className: css(styles.nameText) }, profile.initialName),
        React.createElement("div", { className: css(styles.userId) },
            React.createElement("p", null,
                "\u30E6\u30FC\u30B6\u30FCID : ",
                userId),
            React.createElement(Button, { buttonType: ButtonType.whiteWithBlack, isRadius: true, className: css(styles.userIdButton, copied && styles.userIdcopied), onClick: onClickCopyButton },
                React.createElement(CopyText, { text: copyText }),
                copied ? 'コピー完了' : 'コピー')),
        React.createElement(Button, { onClick: onClick, buttonType: ButtonType.whiteWithBlack, fullWidth: true }, "\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB\u3092\u7DE8\u96C6"),
        React.createElement("div", { className: css(styles.descriptionText) },
            "\u203B\u3053\u306E\u30DA\u30FC\u30B8\u306F\u3042\u306A\u305F\u3060\u3051\u304C\u898B\u3048\u307E\u3059\u3002",
            React.createElement("br", null),
            "\u5165\u529B\u3057\u305F\u60C5\u5831\u306F\u3001\u30B5\u30FC\u30D3\u30B9\u3092\u901A\u3057\u3066\u540D\u524D\u3068\u30A2\u30A4\u30B3\u30F3\u4EE5\u5916\u3001\u4ED6\u306E\u30E6\u30FC\u30B6\u30FC\u306B\u77E5\u3089\u308C\u308B\u3053\u3068\u306F\u3042\u308A\u307E\u305B\u3093"),
        React.createElement("div", { className: css(styles.detailRowLabel) }, "\u3042\u306A\u305F\u306B\u3064\u3044\u3066"),
        React.createElement("div", { className: css(styles.section) },
            React.createElement(MypageDetailRow, { label: "\u8A95\u751F\u65E5", content: profile.birthday }),
            React.createElement(MypageDetailRow, { label: "\u6027\u5225", content: profile.genderName }),
            React.createElement(MypageDetailRow, { label: "\u5C45\u4F4F\u5730", content: profile.prefecture })),
        React.createElement("div", { className: css(styles.section) },
            React.createElement(MypageDetailRow, { label: "\u8EAB\u9577", content: profile.height ? "".concat(profile.height, "cm") : '' }),
            React.createElement(MypageDetailRow, { label: "\u4F53\u91CD", content: profile.weight ? "".concat(profile.weight, "kg") : '' })),
        React.createElement("div", { className: css(styles.section) },
            React.createElement(MypageDetailRow, { label: "\u666E\u6BB5\u306E\u904B\u52D5\u91CF", content: profile.activityLevel.name }),
            React.createElement(MypageDetailRow, { label: "\u76EE\u7684", content: profile.worriesName }),
            React.createElement(MypageDetailRow, { label: "\u8208\u5473", content: profile.interestsName }))));
};
var styles = StyleSheet.create({
    container: {
        width: 315,
    },
    profilePicture: {
        margin: '0 auto 20px',
        width: 100,
        height: 100,
    },
    avatarBorder: {
        border: "0.5px solid ".concat(Colors.Gray),
    },
    nameText: {
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 20,
    },
    userId: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: Colors.GrayDarkest,
        fontSize: 12,
        margin: '20px 0',
    },
    userIdButton: {
        padding: '8px 12px',
        margin: '0 20px',
        fontSize: 12,
    },
    userIdcopied: {
        marginRight: 0,
        color: Colors.GrayDarkest,
    },
    descriptionText: {
        color: Colors.GrayDarkest,
        fontSize: 12,
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 37,
        paddingLeft: 10,
        paddingRight: 10,
    },
    detailRowLabel: {
        color: Colors.GrayDarkest,
        fontSize: 12,
        fontWeight: 500,
        marginBottom: 9,
    },
    section: {
        marginBottom: 20,
    },
});
