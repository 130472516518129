import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CourseDayStatusIcon } from '@lean-body/components/atoms';
import { Colors } from '@lean-body/components/styles';
export var CourseDayHeader = function (_a) {
    var day = _a.day, classObject = _a.classObject;
    var completedCount = day.completedCount > 99 ? '99+' : day.completedCount;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", null,
            React.createElement("div", { className: css(styles.title) },
                "DAY",
                day.day),
            React.createElement("div", { className: css(styles.info) },
                day.courseDaysLessons.length,
                "\u30EC\u30C3\u30B9\u30F3 / ",
                day.totalDuration,
                "\u5206")),
        React.createElement("div", { className: css(styles.statusArea) },
            React.createElement("div", { className: css(styles.completed) },
                React.createElement(CourseDayStatusIcon, { classObject: styles.completedIcon, completed: day.isFirstCompleted }),
                React.createElement("div", { className: css(styles.completedCount) }, completedCount)),
            React.createElement("div", { className: css(styles.label) }, "\u53D7\u8B1B\u56DE\u6570"))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '160%',
        letterSpacing: '0.08em',
        color: Colors.TextDark,
    },
    info: {
        fontWeight: 500,
        fontSize: 12,
        color: Colors.GrayDarkest,
    },
    statusArea: {
        display: 'flex',
        flexDirection: 'column',
    },
    completed: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 2,
    },
    completedIcon: {
        marginRight: 10,
    },
    completedCount: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: '0.64px',
    },
    label: {
        textAlign: 'center',
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: '0.5px',
        color: Colors.GrayDarkest,
    },
});
