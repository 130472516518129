var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { RankingStatus } from '@lean-body/src/domain';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { VectorDirection, VectorIcon } from '../atoms/icons/vector_icon';
import { LessonCardForVertical } from './';
var vectorStyles = StyleSheet.create({
    higher: {
        marginBottom: 2,
    },
    same: {
        marginLeft: 2,
    },
    lower: {
        marginTop: 1,
    },
});
var VectorStatuses = [
    {
        rankingStatus: RankingStatus.higher,
        direction: VectorDirection.top,
        color: Colors.Accent,
        style: vectorStyles.higher,
    },
    {
        rankingStatus: RankingStatus.lower,
        direction: VectorDirection.bottom,
        color: Colors.BlueHepatica,
        style: vectorStyles.lower,
    },
    {
        rankingStatus: RankingStatus.same,
        direction: VectorDirection.right,
        color: Colors.Green,
        style: vectorStyles.same,
    },
];
export var LessonRankingCard = function (_a) {
    var ranking = _a.ranking, classObject = _a.classObject, imgClassObject = _a.imgClassObject, addFavoriteLesson = _a.addFavoriteLesson, unFavoriteLesson = _a.unFavoriteLesson, isBig = _a.isBig;
    var rankingNumber = ranking.ranking;
    var vectorStatus = VectorStatuses.find(function (v) { return v.rankingStatus === ranking.rankingStatus; });
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(LessonCardForVertical, { lesson: ranking.lesson, classObject: classObject, imgClassObject: imgClassObject, addFavoriteLesson: addFavoriteLesson, unFavoriteLesson: unFavoriteLesson, isBig: isBig },
            React.createElement("div", { className: css(styles.rankContainer) },
                React.createElement("div", { className: css(styles.rank) },
                    React.createElement("div", { className: css(styles.rankingNumber, rankingNumberStyle[rankingNumber]) }, rankingNumber),
                    ranking.rankingStatus !== RankingStatus.new ? (React.createElement("div", { className: css(styles.vectorContainer, vectorStatus.style) },
                        React.createElement(VectorIcon, { classObject: styles.vector, color: vectorStatus.color, direction: vectorStatus.direction }))) : (React.createElement("div", { className: css(styles.new) }, "NEW")))))));
};
var styles = StyleSheet.create({
    container: {
        position: 'relative',
    },
    rankContainer: {
        position: 'absolute',
        flex: 1,
        marginRight: 8,
    },
    rank: {
        display: 'flex',
        alignItems: 'center',
        padding: '3px 6px',
        borderRadius: 100,
        backgroundColor: Colors.White,
        position: 'absolute',
        top: 10,
        left: 10,
    },
    rankingNumber: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1,
        color: Colors.GrayDarkest,
        marginRight: 3,
        position: 'relative',
    },
    rankingNumberFirst: {
        color: '#EAC118',
        ':before': {
            backgroundColor: '#EAC118',
        },
    },
    rankingNumberSecond: {
        color: '#ACC6DF',
        ':before': {
            backgroundColor: '#ACC6DF',
        },
    },
    rankingNumberThird: {
        color: '#E28A56',
        ':before': {
            backgroundColor: '#E28A56',
        },
    },
    new: {
        fontSize: 9,
        fontWeight: 'bold',
        lineHeight: 1,
        color: Colors.Accent,
        letterSpacing: '0.04em',
    },
    vectorContainer: {
        width: 9,
        height: 9,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    vector: (_a = {},
        _a[MediaBreakPointUp.MD] = {
            width: 17,
            height: 21,
        },
        _a),
});
var rankingNumberStyle = {
    1: styles.rankingNumberFirst,
    2: styles.rankingNumberSecond,
    3: styles.rankingNumberThird,
};
