var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Button, ButtonType } from '../atoms';
import { Dialog } from '../molecules';
import { MediaBreakPointUp } from '../styles';
export var MealImageDeleteConfirmDialog = function (_a) {
    var onClickDelete = _a.onClickDelete, onClickCancel = _a.onClickCancel, classObject = _a.classObject;
    return (React.createElement(Dialog, { onClickCancel: onClickCancel, classObject: [styles.dialog, classObject], content: React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.description) }, "\u3053\u306E\u5199\u771F\u3092\u524A\u9664\u3057\u307E\u3059\u304B\uFF1F"),
            React.createElement("div", { className: css(styles.buttons) },
                React.createElement(Button, { buttonType: ButtonType.secondary, isRadius: true, className: css(styles.button), onClick: onClickCancel }, "\u524A\u9664\u3057\u306A\u3044"),
                React.createElement(Button, { buttonType: ButtonType.primary, isRadius: true, className: css(styles.button), onClick: onClickDelete }, "\u524A\u9664"))) }));
};
var styles = StyleSheet.create({
    dialog: (_a = {
            width: 327,
            padding: '25px 24px 20px',
            borderRadius: 14
        },
        _a[MediaBreakPointUp.MD] = {
            width: 375,
        },
        _a),
    description: {
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: 0.56,
        marginBottom: 20,
    },
    buttons: {
        display: 'flex',
        gap: 20,
    },
    button: {
        flex: 1,
        padding: 0,
        height: 48,
    },
});
