var _a;
import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Button, ButtonType } from '../atoms';
import { Sheet } from '../atoms/sheet';
import { Colors, MediaBreakPointUp } from '../styles';
import { RecordMealActionType } from '../organisms/record_meal';
import { PulseAnimation } from '../atoms/pulse_animation';
import { ImageAnalysisActionType } from '../organisms/record_meal_analysis';
export var MealRecordSheet = function (_a) {
    var action = _a.action, analysisAction = _a.analysisAction, selectedMenuMap = _a.selectedMenuMap, selectedAnalysisMenuMap = _a.selectedAnalysisMenuMap, checkedAnalysisMenuMap = _a.checkedAnalysisMenuMap, changeCheckedAnalysisMenuMap = _a.changeCheckedAnalysisMenuMap, imageMap = _a.imageMap, onClickSkipMeal = _a.onClickSkipMeal, onClickConfirm = _a.onClickConfirm, isDisabledSaveButton = _a.isDisabledSaveButton, classObject = _a.classObject;
    var selectedMenuSize = checkedAnalysisMenuMap
        ? selectedMenuMap.size + selectedAnalysisMenuMap.size + checkedAnalysisMenuMap.size
        : selectedMenuMap.size + selectedAnalysisMenuMap.size;
    var checkedAnalysisMenuSize = action === RecordMealActionType.analysis ? (checkedAnalysisMenuMap === null || checkedAnalysisMenuMap === void 0 ? void 0 : checkedAnalysisMenuMap.size) + (changeCheckedAnalysisMenuMap === null || changeCheckedAnalysisMenuMap === void 0 ? void 0 : changeCheckedAnalysisMenuMap.size) : 0;
    return (React.createElement(Sheet, { classObject: [styles.sheet, classObject] }, action === RecordMealActionType.select ? (React.createElement(React.Fragment, null,
        selectedMenuSize ? (React.createElement("div", { className: css(styles.countContainer) },
            React.createElement(PulseAnimation, { key: selectedMenuSize },
                React.createElement("span", { className: css(styles.count) }, selectedMenuSize)),
            "\u30E1\u30CB\u30E5\u30FC\u9078\u629E\u4E2D")) : (React.createElement(Button, { buttonType: ButtonType.secondary, isRadius: true, onClick: onClickSkipMeal, className: css(styles.skipButton) }, "\u98DF\u3079\u306A\u304B\u3063\u305F")),
        React.createElement(Button, { buttonType: selectedMenuSize ? ButtonType.primary : ButtonType.transparentPrimary, isRadius: true, className: css(styles.confirmButton), onClick: onClickConfirm }, "\u767B\u9332\u3092\u78BA\u8A8D"))) : action === RecordMealActionType.analysis ? (React.createElement(React.Fragment, null, analysisAction === ImageAnalysisActionType.result ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.countContainer) },
            React.createElement(PulseAnimation, { key: checkedAnalysisMenuSize },
                React.createElement("span", { className: css(styles.count) }, checkedAnalysisMenuSize)),
            "\u30E1\u30CB\u30E5\u30FC\u9078\u629E\u4E2D"),
        React.createElement(Button, { buttonType: checkedAnalysisMenuSize > 0 ? ButtonType.primary : ButtonType.secondary, isRadius: true, className: css(styles.confirmButton), onClick: onClickConfirm }, checkedAnalysisMenuSize > 0 ? '登録を確認' : '写真のみ登録'))) : (React.createElement(React.Fragment, null,
        analysisAction === ImageAnalysisActionType.add && (React.createElement("div", { className: css(styles.countContainer) },
            React.createElement(PulseAnimation, { key: checkedAnalysisMenuSize },
                React.createElement("span", { className: css(styles.count) }, checkedAnalysisMenuSize)),
            "\u30E1\u30CB\u30E5\u30FC\u9078\u629E\u4E2D")),
        React.createElement(Button, { buttonType: ButtonType.primary, isRadius: true, className: css(styles.confirmButton), onClick: onClickConfirm }, analysisAction === ImageAnalysisActionType.add ? '追加を確認' : '変更を確認'))))) : (React.createElement(Button, { buttonType: selectedMenuSize || imageMap.size ? ButtonType.primary : ButtonType.transparentPrimary, isRadius: true, className: css(styles.confirmButton), onClick: onClickConfirm, disabled: isDisabledSaveButton }, "\u767B\u9332\u3092\u5B8C\u4E86"))));
};
var styles = StyleSheet.create({
    sheet: {
        position: 'sticky',
        bottom: 0,
        width: '100%',
        maxWidth: 600,
        margin: '0 auto',
        display: 'flex',
        gap: 12,
        padding: 16,
        height: 80,
    },
    skipButton: (_a = {
            width: 135,
            padding: 0,
            fontSize: 16
        },
        _a[MediaBreakPointUp.MD] = {
            width: 200,
        },
        _a),
    confirmButton: {
        fontSize: 16,
        flex: 1,
    },
    countContainer: {
        color: Colors.PrimaryDarkB,
        fontSize: 10,
        fontWeight: 700,
        letterSpacing: 0.8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    count: {
        fontSize: 32,
        letterSpacing: 2.56,
    },
});
