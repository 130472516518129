import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors } from '@lean-body/components/styles';
import { Button, ButtonType } from '../atoms';
import { ProgressBar } from '../atoms/progress_bar';
import { PlayButtonIcon } from '../atoms/icons/play_button_icon';
import { StopIcon } from '../atoms/icons/stop_icon';
import { PlaylistStopChallengeDialog } from './playlist_stop_challenge_dialog';
export var PlaylistMetaChallengeBox = function (_a) {
    var isChallenging = _a.isChallenging, currentOrderIndex = _a.currentOrderIndex, totalChallengedCount = _a.totalChallengedCount, lessonLength = _a.lessonLength, isOpenStopChallengeDialog = _a.isOpenStopChallengeDialog, onClickChallenge = _a.onClickChallenge, onClickPlayCurrentVideo = _a.onClickPlayCurrentVideo, onClickStopChallenge = _a.onClickStopChallenge, onClickCancelStopChallenge = _a.onClickCancelStopChallenge;
    var buttonText = isChallenging ? '続きから再生' : 'チャレンジする';
    var onClickChallengeButton = function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (!isChallenging) {
            onClickChallenge();
        }
        onClickPlayCurrentVideo();
    };
    return (React.createElement(React.Fragment, null,
        isChallenging ? (React.createElement("div", { className: css(styles.section, styles.lessonInfo) },
            React.createElement(ProgressBar, { classObject: styles.progressBar, max: lessonLength, current: currentOrderIndex, height: 6 }),
            React.createElement("div", { className: css(styles.progressNumber) },
                currentOrderIndex,
                "/",
                lessonLength))) : (React.createElement("div", { className: css(styles.section, styles.lessonInfo) },
            lessonLength,
            "LESSONS")),
        React.createElement("div", { className: css(styles.buttonContainer) },
            React.createElement(Button, { className: css(styles.button), isRadius: true, buttonType: ButtonType.primary, onClick: onClickChallengeButton, dataTest: "challengeButton" },
                React.createElement("div", { className: css(styles.buttonText) },
                    React.createElement(PlayButtonIcon, { classObject: styles.playButtonIcon }),
                    buttonText)),
            isChallenging && (React.createElement("div", { className: css(styles.stopIconButton), onClick: onClickStopChallenge, "data-test": "stopChallengeIcon" },
                React.createElement(StopIcon, { color: Colors.Black, classObject: styles.stopChallengeIcon }),
                isOpenStopChallengeDialog && (React.createElement(PlaylistStopChallengeDialog, { isChallenging: isChallenging, onClickStopChallenge: onClickChallenge, onClickCancelStopChallenge: onClickCancelStopChallenge }))))),
        React.createElement("div", { className: css(styles.section, styles.challengedNumber) },
            totalChallengedCount,
            "\u4EBA\u304C\u30C1\u30E3\u30EC\u30F3\u30B8\u3057\u307E\u3057\u305F")));
};
var styles = StyleSheet.create({
    section: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    lessonInfo: {
        fontSize: 16,
        fontWeight: 'bold',
        height: 26,
    },
    progressBar: {
        backgroundColor: Colors.GrayLighter,
        width: 146,
        marginRight: 12,
    },
    progressNumber: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    buttonContainer: {
        display: 'flex',
        marginTop: 8,
        marginBottom: 8,
    },
    button: {
        display: 'block',
        padding: '11px 0',
        width: '100%',
        height: 48,
    },
    stopIconButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 8,
        padding: 16,
        width: 48,
        height: 48,
        border: "1px solid ".concat(Colors.BorderLightGray),
        borderRadius: 24,
        cursor: 'pointer',
    },
    buttonText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 26,
    },
    playButtonIcon: {
        marginRight: 6,
    },
    stopChallengeIcon: {
        width: 14,
        height: 14,
    },
    challengedNumber: {
        color: Colors.Gray,
        fontSize: 12,
        fontWeight: 'bold',
        height: 19,
    },
});
