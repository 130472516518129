var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Dialog } from '@lean-body/components/molecules';
import { Button, ButtonType, CameraWithBg } from '@lean-body/components/atoms';
import { AVATAR_DEFAULT_SRC } from '@lean-body/components/atoms/avatar_uploader';
import { canvasToFile } from '../../util/canvas';
import { CropperImage, CropperImageType } from '../atoms/cropper_image';
import { Avatar } from '@mui/material';
import { CloseButtonLabel } from '../atoms/dialog_close_button';
export var SelectProfilePicture = function (_a) {
    var submit = _a.submit, imgSrc = _a.imgSrc, clipFileSrc = _a.clipFileSrc, setClipFileSrc = _a.setClipFileSrc;
    var _b = React.useState(null), cropper = _b[0], setCropper = _b[1];
    var _c = React.useState(false), showImgDialog = _c[0], setShowImgDialog = _c[1];
    var onClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var submitFile;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!cropper)
                        return [2 /*return*/];
                    return [4 /*yield*/, canvasToFile(cropper.getCroppedCanvas(), "croppedImg_".concat(Date.now(), ".jpg"), 'image/jpg')];
                case 1:
                    submitFile = _a.sent();
                    submit(submitFile);
                    setShowImgDialog(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var onFileUpload = function (event) {
        var src = window.URL.createObjectURL(event.target.files[0]);
        setClipFileSrc(src);
        setShowImgDialog(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.pictureContainer) },
            React.createElement("label", { className: css(styles.label) },
                React.createElement(Avatar, { src: imgSrc, className: css(styles.profilePicture, imgSrc !== AVATAR_DEFAULT_SRC && styles.avatarBorder) }),
                imgSrc === AVATAR_DEFAULT_SRC && React.createElement("div", { className: css(styles.profilePicture, styles.noPicture) }),
                React.createElement(CameraWithBg, { classObject: styles.cameraIcon, svgWidth: "100%" }),
                React.createElement("input", { id: "avatar", accept: "image/*", style: { display: 'none' }, type: "file", onChange: onFileUpload, onClick: function (e) {
                        e.currentTarget.value = '';
                    } }))),
        showImgDialog && (React.createElement(Dialog, { closeButtonLabel: CloseButtonLabel.Cancel, classObject: styles.dialogContainer, isCloseButton: true, onClickCancel: function () {
                setShowImgDialog(false);
            }, content: React.createElement(React.Fragment, null,
                React.createElement(CropperImage, { setCropper: setCropper, src: clipFileSrc, type: CropperImageType.radius200, width: 300, height: 300 }),
                React.createElement("div", { className: css(styles.buttonContainer) },
                    React.createElement(Button, { buttonType: ButtonType.primary, isRadius: true, className: css(styles.button), onClick: onClick }, "\u78BA\u5B9A"))) }))));
};
var styles = StyleSheet.create({
    pictureContainer: {
        position: 'relative',
        marginBottom: 40,
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    profilePicture: {
        width: 100,
        height: 100,
        cursor: 'pointer',
    },
    avatarBorder: {
        border: "0.5px solid ".concat(Colors.Gray),
    },
    noPicture: {
        position: 'absolute',
        top: 0,
        backgroundColor: Colors.Black,
        opacity: 0.4,
        borderRadius: '50%',
    },
    cameraIcon: {
        position: 'absolute',
        width: 38,
        height: 34,
        background: 'none',
    },
    dialogContainer: (_a = {
            top: 48,
            transform: 'translateX(-50%)',
            maxWidth: 300,
            borderRadius: 10
        },
        _a[MediaBreakPointUp.MD] = {
            maxWidth: 300, // Dialog 側で設定されてる値を上書きするために指定
        },
        _a),
    buttonContainer: {
        padding: 24,
    },
    button: {
        fontSize: 16,
        width: '100%',
    },
    camera: {
        width: 32,
        display: 'block',
        margin: '0 auto 12px',
    },
});
