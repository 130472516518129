var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { MinusIcon } from '@lean-body/components/atoms/icons/minus_icon';
export var OriginalPlaylistLessonCard = function (_a) {
    var onClickMinus = _a.onClickMinus, lesson = _a.lesson;
    return (React.createElement("div", { className: css(styles.lesson) },
        React.createElement("img", { className: css(styles.lessonImg), src: lesson.thumbnailURL }),
        React.createElement("div", { className: css(styles.lessonName) }, lesson.name),
        React.createElement("div", { className: css(styles.minusIconContainer), onClick: onClickMinus },
            React.createElement(MinusIcon, { classObject: styles.minusIcon }))));
};
var styles = StyleSheet.create({
    lesson: {
        display: 'flex',
        alignItems: 'center',
        height: 56,
        backgroundColor: Colors.GrayLight,
        borderRadius: 4,
        overflow: 'hidden',
        marginBottom: 8,
        flex: 1,
    },
    clickable: {
        cursor: 'pointer',
    },
    lessonImg: {
        marginRight: 10,
        width: 90,
        height: 56,
        objectFit: 'cover',
        display: 'block',
    },
    lessonName: (_a = {
            flex: 1,
            fontSize: 12,
            fontWeight: 'bold',
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': '2',
            overflow: 'hidden'
        },
        _a[MediaBreakPointUp.XL] = {
            fontSize: 14,
        },
        _a),
    minusIconContainer: {
        margin: '0 13px',
        height: 20,
        width: 20,
        borderRadius: '100%',
        border: "1px solid ".concat(Colors.Primary),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        cursor: 'pointer',
    },
    minusIcon: {
        width: 14,
        height: 14,
    },
});
