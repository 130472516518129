import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Dialog } from './dialog';
import { Button, ButtonType } from '../atoms';
export var ExitConfirmationDialog = function (_a) {
    var description = _a.description, exitButtonMessage = _a.exitButtonMessage, cancelButtonMessage = _a.cancelButtonMessage, onClickExit = _a.onClickExit, onClickCancel = _a.onClickCancel, classObject = _a.classObject;
    return (React.createElement(Dialog, { classObject: [styles.dialog, classObject], onClickCancel: onClickCancel, content: React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.description) }, description),
            React.createElement("div", { className: css(styles.buttons) },
                React.createElement(Button, { buttonType: ButtonType.secondary, isRadius: true, className: css(styles.button), onClick: onClickExit }, exitButtonMessage),
                React.createElement(Button, { buttonType: ButtonType.primary, isRadius: true, className: css(styles.button), onClick: onClickCancel }, cancelButtonMessage))) }));
};
var styles = StyleSheet.create({
    dialog: {
        width: 327,
        padding: '25px 24px 20px',
        borderRadius: 14,
    },
    description: {
        fontSize: 14,
        lineHeight: '140%',
        letterSpacing: 0.56,
        marginBottom: 20,
        textAlign: 'center',
    },
    buttons: {
        display: 'flex',
        gap: 20,
        padding: 0,
    },
    button: {
        flex: 1,
        padding: 0,
        height: 48,
    },
});
