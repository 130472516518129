var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { useXlMediaQuery } from '@lean-body/src/util/media_query_hooks';
export var LiveEventItems = function (props) {
    var isXl = useXlMediaQuery();
    var displayLowerItems = props.displayLowerItems;
    return (React.createElement("div", { className: css(styles.presents) },
        React.createElement("div", { className: css(styles.present) },
            React.createElement("div", { className: css(styles.presentImgContainer) },
                React.createElement("img", { className: css(styles.presentImg), src: "/assets/images/live_event/style_boat_market_item.jpg" }),
                React.createElement("div", { className: css(styles.presentLogoContainer) },
                    React.createElement("img", { className: css(styles.presentLogo), src: "/assets/images/live_event/style_boat_market_logo.png" }))),
            React.createElement("div", { className: css(styles.presentName) },
                "\u30EA\u30AB\u30D0\u30EA\u30FC",
                React.createElement("br", null),
                React.createElement("span", { className: css(styles.presentNameBold) }, "\u30E8\u30AC\u30DE\u30C3\u30C8")),
            React.createElement("div", { className: css(styles.lottery) },
                "\u62BD\u9078 ",
                React.createElement("span", { className: css(styles.lotteryNumber) }, "\uFF12"),
                "\u540D\u69D8")),
        React.createElement("div", { className: css(styles.present) },
            React.createElement("div", { className: css(styles.presentImgContainer) },
                React.createElement("img", { className: css(styles.presentImg), src: "/assets/images/live_event/manduka_item.jpg" }),
                React.createElement("div", { className: css(styles.presentLogoContainer) },
                    React.createElement("img", { className: css(styles.presentLogo), src: "/assets/images/live_event/manduka_logo.svg" }),
                    React.createElement("img", { className: css(styles.presentLogo, styles.mandukaSvg), src: "/assets/images/live_event/manduka.svg" }))),
            React.createElement("div", { className: css(styles.presentName) },
                "BEGIN",
                React.createElement("br", null),
                React.createElement("span", { className: css(styles.presentNameBold) }, "\u30E8\u30AC\u30DE\u30C3\u30C8")),
            React.createElement("div", { className: css(styles.lottery) },
                "\u62BD\u9078 ",
                React.createElement("span", { className: css(styles.lotteryNumber) }, "\uFF13"),
                "\u540D\u69D8")),
        React.createElement("div", { className: css(styles.present) },
            React.createElement("div", { className: css(styles.presentImgContainer) },
                React.createElement("img", { className: css(styles.presentImg, styles.loopaItemPng), src: "/assets/images/live_event/loopa_item.png" }),
                React.createElement("div", { className: css(styles.presentLogoContainer) },
                    React.createElement("img", { className: css(styles.presentLogo), src: "/assets/images/live_event/loopa_logo.png" }))),
            React.createElement("div", { className: css(styles.presentName) },
                React.createElement("span", { className: css(styles.presentNameBold) }, "\u30E8\u30AC\u30A6\u30A7\u30A2"),
                React.createElement("br", null),
                "(\u30EC\u30AE\u30F3\u30B9\u30FB\u30C8\u30C3\u30D7\u30B9)"),
            React.createElement("div", { className: css(styles.lottery) },
                "\u62BD\u9078 \u8A08",
                React.createElement("span", { className: css(styles.lotteryNumber) }, "\uFF16"),
                "\u540D\u69D8")),
        displayLowerItems && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.present) },
                React.createElement("div", { className: css(styles.presentImgContainer) },
                    React.createElement("img", { className: css(styles.presentImgBook), src: "/assets/images/live_event/yamaguchi_erika_item.jpg" })),
                React.createElement("div", { className: css(styles.presentName, styles.presentNameMinText) },
                    React.createElement("span", { className: css(isXl && styles.presentNameBold) }, "\u7F8E\u30B3\u30A2\u98DF"),
                    React.createElement("br", null),
                    "-\u30BD\u30A4\u30D7\u30ED\u30C6\u30A4\u30F3-",
                    React.createElement("div", { className: css(styles.presentAuthor) },
                        React.createElement("span", { className: css(styles.presentAuthorName) }, "\u5C71\u53E3 \u7D75\u91CC\u52A0"),
                        "\u3055\u3093",
                        isXl ? ' ' : React.createElement("br", null),
                        "\u30D7\u30ED\u30C7\u30E5\u30FC\u30B9")),
                React.createElement("div", { className: css(styles.lottery) },
                    "\u62BD\u9078 ",
                    React.createElement("span", { className: css(styles.lotteryNumber) }, "\uFF11"),
                    "\u540D\u69D8")),
            React.createElement("div", { className: css(styles.present) },
                React.createElement("div", { className: css(styles.presentImgContainer) },
                    React.createElement("img", { className: css(styles.presentImgBook), src: "/assets/images/live_event/yamaguchi_erika_book.jpg" })),
                React.createElement("div", { className: css(styles.presentName, styles.presentNameMinText) },
                    React.createElement("span", { className: css(isXl && styles.presentNameBold) }, "\u7F8E\u30B3\u30A2"),
                    isXl ? React.createElement("br", null) : ' ',
                    "\u7D30\u304F\u3066\u5F37\u3044",
                    isXl ? null : React.createElement("br", null),
                    "\u7F8E\u30DC\u30C7\u30A3\u30E1\u30A4\u30AF",
                    React.createElement("div", { className: css(styles.presentAuthor) },
                        React.createElement("span", { className: css(styles.presentAuthorName) }, "\u5C71\u53E3 \u7D75\u91CC\u52A0"),
                        "\u3055\u3093",
                        isXl ? ' ' : React.createElement("br", null),
                        "\u8457\u66F8")),
                React.createElement("div", { className: css(styles.lottery) },
                    "\u62BD\u9078 ",
                    React.createElement("span", { className: css(styles.lotteryNumber) }, "\uFF11"),
                    "\u540D\u69D8")),
            React.createElement("div", { className: css(styles.present) },
                React.createElement("div", { className: css(styles.presentImgContainer) },
                    React.createElement("img", { className: css(styles.presentImgBook), src: "/assets/images/live_event/nanase_you_book.jpg" })),
                React.createElement("div", { className: css(styles.presentName, styles.presentPresetnNameNanaseYouBook) },
                    "\u00A0\u300C\u30C0\u30A4\u30A8\u30C3\u30C8\u3053\u3058\u3089\u305B\u3055\u3093\u300D\u304C\u4ECA\u5EA6\u3053\u305D\u3084\u305B\u308B\u672C",
                    React.createElement("div", { className: css(styles.presentAuthor, styles.nanaseYouBook) },
                        React.createElement("span", { className: css(styles.presentAuthorName) }, "\u4E03\u702C \u8449"),
                        "\u3055\u3093",
                        isXl ? ' ' : React.createElement("br", null),
                        "\u8457\u66F8")),
                React.createElement("div", { className: css(styles.lottery) },
                    "\u62BD\u9078 ",
                    React.createElement("span", { className: css(styles.lotteryNumber) }, "\uFF15"),
                    "\u540D\u69D8"))))));
};
var styles = StyleSheet.create({
    presents: (_a = {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            maxWidth: 350,
            margin: '14px auto 0'
        },
        _a[MediaBreakPointUp.MD] = {
            margin: '24px auto 0',
        },
        _a[MediaBreakPointUp.XL] = {
            maxWidth: 576,
            margin: '24px auto 0',
        },
        _a),
    present: {
        borderRadius: 14,
        position: 'relative',
        backgroundColor: Colors.White,
        ':nth-of-type(-n+3)': {
            marginBottom: 27,
        },
    },
    presentLogoContainer: (_b = {
            position: 'absolute',
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            height: 23,
            width: '100%'
        },
        _b[MediaBreakPointUp.XL] = {
            height: 26,
        },
        _b),
    presentLogo: (_c = {
            height: 15
        },
        _c[MediaBreakPointUp.XL] = {
            height: 19,
        },
        _c),
    presentImgContainer: (_d = {
            width: 106,
            height: 100,
            borderRadius: '14px 14px 0 0',
            overflow: 'hidden',
            position: 'relative'
        },
        _d[MediaBreakPointUp.XL] = {
            width: 184,
            height: 120,
        },
        _d),
    presentImg: (_e = {
            width: '100%'
        },
        _e[MediaBreakPointUp.XL] = {
            position: 'absolute',
            top: -34,
        },
        _e),
    loopaItemPng: (_f = {},
        _f[MediaBreakPointUp.XL] = {
            top: -10,
            right: 10,
            width: '80%',
        },
        _f),
    presentImgBook: (_g = {
            height: 90,
            margin: '10px auto 4px',
            display: 'block'
        },
        _g[MediaBreakPointUp.XL] = {
            height: 108,
        },
        _g),
    lottery: (_h = {
            lineHeight: 1,
            padding: '5px 0',
            color: Colors.White,
            backgroundColor: Colors.Orange,
            fontSize: 11,
            position: 'absolute',
            bottom: -11,
            right: 0,
            left: 0,
            width: 88,
            textAlign: 'center',
            borderRadius: 50,
            margin: '0 auto'
        },
        _h[MediaBreakPointUp.XL] = {
            bottom: 16,
            backgroundImage: Colors.GradationOrange,
        },
        _h),
    lotteryNumber: {
        fontSize: 14,
        lineHeight: 1,
        fontWeight: 'bold',
    },
    presentName: (_j = {
            margin: '6px auto 16px',
            textAlign: 'center',
            fontSize: 10,
            maxWidth: 99
        },
        _j[MediaBreakPointUp.XL] = {
            margin: '14px auto 48px',
            fontSize: 12,
            maxWidth: 160,
        },
        _j),
    presentPresetnNameNanaseYouBook: (_k = {
            lineHeight: '12px',
            marginTop: 8
        },
        _k[MediaBreakPointUp.XL] = {
            marginTop: 18,
            lineHeight: '14px',
        },
        _k),
    presentNameMinText: {
        margin: '12px auto 10px',
    },
    presentNameBold: (_l = {
            fontWeight: 'bold',
            fontSize: 12
        },
        _l[MediaBreakPointUp.XL] = {
            fontSize: 14,
        },
        _l),
    mandukaSvg: (_m = {
            height: 9,
            marginLeft: 4
        },
        _m[MediaBreakPointUp.XL] = {
            height: 13,
        },
        _m),
    presentAuthor: (_o = {
            fontSize: 10,
            color: Colors.Orange,
            lineHeight: 1,
            padding: '9px 0 10px'
        },
        _o[MediaBreakPointUp.XL] = {
            padding: '11px 0 0',
        },
        _o),
    presentAuthorName: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    nanaseYouBook: (_p = {
            padding: '8px 0 0'
        },
        _p[MediaBreakPointUp.XL] = {
            padding: '18px 0 0',
        },
        _p),
});
