var UserInvitationService = /** @class */ (function () {
    function UserInvitationService() {
    }
    Object.defineProperty(UserInvitationService.prototype, "invitationAvailable", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (payment) {
            if (payment === null)
                return false;
            if (payment.isMegalosUser)
                return false;
            if (payment.isEmployeeWelfareUser())
                return false;
            if (payment.isCollaborationUser())
                return false;
            return true;
        }
    });
    return UserInvitationService;
}());
export { UserInvitationService };
