import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { LiveLessonLabel as LiveLessonLabelComponent } from '@lean-body/components/atoms';
import { Colors } from '@lean-body/components/styles';
export var LiveLessonCardHeader = function (_a) {
    var label = _a.label, holdingTime = _a.holdingTime, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(LiveLessonLabelComponent, { labelText: label.name, textColor: label.textColor, backgroundColor: label.backgroundColor }),
        React.createElement("div", { className: css(styles.holdingTime) }, holdingTime)));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
    },
    holdingTime: {
        color: Colors.Black,
        fontSize: 14,
        fontWeight: 700,
        letterSpacing: 0.56,
    },
});
