import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors } from '@lean-body/components/styles';
import { Link } from 'react-router-dom';
import { TermsOfService, TermsOfServiceMode } from '@lean-body/components/atoms';
import { routes } from '@lean-body/src/route';
export var FooterComponent = function (props) {
    // メガロスユーザーには別の利用規約を見せるため
    var isMegalosUser = props.isMegalosUser || TermsOfServiceMode.megalos === props.termsOfServiceMode;
    var termsOfServiceMode = isMegalosUser ? TermsOfServiceMode.megalos : TermsOfServiceMode.normal;
    return (React.createElement("div", { className: css(styles.container, props.classObject), id: "footer" },
        React.createElement("ul", { className: css(styles.ulContainer) },
            props.authed && (React.createElement(React.Fragment, null,
                React.createElement("li", { className: css(styles.row) },
                    React.createElement(Link, { className: css(styles.link), to: routes.SETTING.compile() }, "\u30A2\u30AB\u30A6\u30F3\u30C8\u8A2D\u5B9A")),
                React.createElement("li", { className: css(styles.row) },
                    React.createElement(Link, { className: css(styles.link), to: routes.INITIAL_GUIDE.compile() }, "\u306F\u3058\u3081\u3066\u30AC\u30A4\u30C9")))),
            React.createElement("li", { className: css(styles.row) },
                React.createElement("a", { className: css(styles.link), href: "https://wondernuts.zendesk.com/hc/ja/sections/360000526334-%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F", target: "_blank", "data-test": "inquiryTab" }, "\u3088\u304F\u3042\u308B\u8CEA\u554F")),
            React.createElement("li", { className: css(styles.row) },
                React.createElement("a", { className: css(styles.link), href: "https://wondernuts.zendesk.com/hc/ja/articles/360002884473-%E5%80%8B%E4%BA%BA%E6%83%85%E5%A0%B1%E4%BF%9D%E8%AD%B7%E6%96%B9%E9%87%9D", target: "_blank" }, "\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC")),
            React.createElement("li", { className: css(styles.row) },
                React.createElement(TermsOfService, { className: css(styles.link), mode: termsOfServiceMode })),
            React.createElement("li", { className: css(styles.row) },
                React.createElement("a", { className: css(styles.link), href: "https://wondernuts.zendesk.com/hc/ja/articles/360002856414-%E7%89%B9%E5%AE%9A%E5%95%86%E5%8F%96%E5%BC%95%E6%B3%95%E3%81%AB%E5%9F%BA%E3%81%A5%E3%81%8F%E8%A1%A8%E8%A8%98", target: "_blank" }, "\u7279\u5B9A\u5546\u53D6\u5F15\u6CD5\u306B\u57FA\u3065\u304F\u8868\u8A18")),
            React.createElement("li", { className: css(styles.row) },
                React.createElement("a", { className: css(styles.link), href: "https://lean-body.co.jp/", target: "_blank" }, "\u904B\u55B6\u4F1A\u793E")),
            isMegalosUser && (React.createElement("li", { className: css(styles.row) },
                React.createElement(Link, { to: routes.MEGALOS_LIVE_CALENDAR.compile(), className: css(styles.link) }, "\u30E1\u30AC\u30ED\u30B9 LIVE\u30EC\u30C3\u30B9\u30F3")))),
        React.createElement("div", { className: css(styles.copyRight) }, "Copyright \u00A9 LEAN BODY Inc.")));
};
var styles = StyleSheet.create({
    container: {
        position: 'relative',
        paddingBottom: 20,
    },
    copyRight: {
        fontSize: 12,
        fontWeight: 'bold',
        color: Colors.GrayDarkest,
        textAlign: 'center',
        marginTop: 9,
    },
    ulContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    row: {
        padding: 9,
    },
    link: {
        fontSize: 12,
        color: Colors.GrayDarkest,
        lineHeight: 1,
    },
});
