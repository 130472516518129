var Goal = /** @class */ (function () {
    function Goal(data) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "thumbnailSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.name = data.name;
        this.thumbnailSrc = data.thumbnailSrc;
    }
    Object.defineProperty(Goal.prototype, "thumbnailURL", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (config) {
            return config.cdnBaseURL + this.thumbnailSrc;
        }
    });
    return Goal;
}());
export { Goal };
var DailyGoal = /** @class */ (function () {
    function DailyGoal(data) {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "icon", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "value", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "unit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "details", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.name = data.name;
        this.icon = data.icon;
        this.value = data.value;
        this.unit = data.unit;
        this.details = data.details;
    }
    Object.defineProperty(DailyGoal.prototype, "iconURL", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (config) {
            return config.cdnBaseURL + this.icon;
        }
    });
    return DailyGoal;
}());
export { DailyGoal };
var DailyGoalDetail = /** @class */ (function () {
    function DailyGoalDetail(data) {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "value", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "unit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.name = data.name;
        this.value = data.value;
        this.unit = data.unit;
    }
    return DailyGoalDetail;
}());
export { DailyGoalDetail };
