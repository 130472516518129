var _a, _b;
import * as React from 'react';
import { useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp, drawerWidth } from '../styles';
import { ArrowDirection, ArrowIconWithMargin, Button, ButtonType, CheckBox, Input, Select } from '../atoms';
export var EditGoal = function (_a) {
    var title = _a.title, unit = _a.unit, checkboxLabel = _a.checkboxLabel, defaultValue = _a.defaultValue, keepValue = _a.keepValue, isText = _a.isText, textInputType = _a.textInputType, options = _a.options, onClickBack = _a.onClickBack, onSave = _a.onSave;
    var _b = useState(false), checkbox = _b[0], setCheckbox = _b[1];
    var _c = useState(defaultValue), value = _c[0], setValue = _c[1];
    var _d = useState(true), isEqualDefaultValue = _d[0], setIsEqualDefaultValue = _d[1];
    React.useEffect(function () {
        setValue(defaultValue);
        setIsEqualDefaultValue(true);
    }, [defaultValue]);
    var onChange = function (event) {
        setValue(event.target.value.toString());
        setIsEqualDefaultValue(event.target.value.toString() === defaultValue);
    };
    var onChangeCheckBox = function () {
        setCheckbox(!checkbox);
        if (!checkbox)
            setValue(keepValue);
    };
    var onClickSave = function () {
        onSave(value);
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.header) },
            React.createElement(ArrowIconWithMargin, { classObject: styles.arrowIcon, direction: ArrowDirection.left, color: Colors.TextDark, onClick: onClickBack }),
            React.createElement("div", { className: css(styles.title) }, title)),
        React.createElement("div", { className: css(styles.body) },
            isText ? (React.createElement(React.Fragment, null,
                React.createElement(Input, { classObject: isEqualDefaultValue && styles.equalDefaultValue, type: textInputType, value: value, unitLabel: unit, onChange: onChange, hideSpinner: true }),
                React.createElement(CheckBox, { onChange: onChangeCheckBox, classObject: styles.checkbox, labelClassObject: styles.checkboxLabel }, checkboxLabel))) : (React.createElement(Select, { selectClassObject: [styles.select, isEqualDefaultValue && styles.equalDefaultValue], selectOptions: options, defaultValue: value, unitLabel: unit, onChange: onChange })),
            React.createElement(Button, { className: css(styles.button), buttonType: value ? ButtonType.primary : ButtonType.transparentPrimary, isRadius: true, disabled: !value, onClick: onClickSave }, "\u5B8C\u4E86"))));
};
var styles = StyleSheet.create({
    container: (_a = {
            paddingTop: 12
        },
        _a[MediaBreakPointUp.MD] = {
            width: 375,
            margin: '0 auto',
        },
        _a),
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 42,
    },
    arrowIcon: (_b = {
            position: 'absolute',
            left: 16
        },
        _b[MediaBreakPointUp.MD] = {
            left: "calc(".concat(drawerWidth.md, "px + 16px)"),
        },
        _b[MediaBreakPointUp.XL] = {
            left: "calc(".concat(drawerWidth.xl, "px + 16px)"),
        },
        _b),
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
    body: {
        padding: '50px 24px 0',
    },
    checkbox: {
        marginTop: 10,
    },
    checkboxLabel: {
        color: Colors.Black,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
    },
    select: {
        color: Colors.Black,
        height: 26,
        paddingRight: 56,
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
    equalDefaultValue: {
        color: Colors.GrayDarkest,
    },
    button: {
        width: '100%',
        height: 48,
        fontSize: 16,
        lineHeight: '160%',
        marginTop: 50,
        padding: '11px 0',
    },
});
