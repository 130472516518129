import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import Cropper from 'cropperjs/dist/cropper.esm';
import 'cropperjs/dist/cropper.css';
export var CropperImageType;
(function (CropperImageType) {
    CropperImageType[CropperImageType["radius16"] = 0] = "radius16";
    CropperImageType[CropperImageType["radius200"] = 1] = "radius200";
})(CropperImageType || (CropperImageType = {}));
var DEFAULT_WIDTH = 290;
var DEFAULT_HEIGHT = 290;
export var CropperImage = function (props) {
    var src = props.src, setCropper = props.setCropper, type = props.type;
    var width = props.width || DEFAULT_WIDTH;
    var height = props.height || DEFAULT_HEIGHT;
    var styles = StyleSheet.create({
        container: {
            maxWidth: width,
            maxHeight: height,
        },
    });
    var ref = React.useRef();
    var minCropBoxHeight = width;
    var minCropBoxWidth = height;
    // radius:16pxだと余白が小さいので、トリミングボックスを少し小さくして余白を入れる
    if (type === CropperImageType.radius16) {
        minCropBoxHeight -= 20;
        minCropBoxWidth -= 20;
    }
    React.useEffect(function () {
        setCropper(new Cropper(ref.current, {
            aspectRatio: 1, // トリミングボックスの縦横の比率(1:1なら1、16:9なら16/9)
            viewMode: 1, // 画像の外側を選択できないようにする
            background: false, // 横長の画像を切り取るときに背景色がデフォルトであるので消す
            dragMode: 'move', // 画像を動かせるようにする
            cropBoxResizable: false, // トリミングボックスを拡大/縮小できないようにする
            cropBoxMovable: false, // トリミングボックスを動かせないようにする
            guides: false, // トリミングボックス内の点線を消す
            center: false, // トリミングボックス内の十字を消す
            minCropBoxHeight: minCropBoxHeight, // トリミングサイズの高さ
            minCropBoxWidth: minCropBoxWidth, // トリミングサイズの横幅
            minContainerWidth: width, // トリミングボックスの最小横幅
            minContainerHeight: height, // トリミングボックスの最小高さ
        }));
    }, []);
    return (React.createElement(React.Fragment, null,
        type === CropperImageType.radius16 ? (React.createElement("link", { rel: "stylesheet", type: "text/css", href: "/assets/styles/cropper_radius_16px.css" })) : (React.createElement("link", { rel: "stylesheet", type: "text/css", href: "/assets/styles/cropper_radius_200px.css" })),
        React.createElement("div", { className: css(styles.container) },
            React.createElement("img", { src: src, ref: ref }))));
};
