import { Lesson } from '.';
var CourseDaysLesson = /** @class */ (function () {
    function CourseDaysLesson(data, config) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "courseDayID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isCompleted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.courseDayID = data.courseDayId;
        this.lessonID = data.lessonId;
        this.orderIndex = data.orderIndex;
        this.isCompleted = data.isCompleted;
        this.lesson = new Lesson(data.lesson, config);
    }
    return CourseDaysLesson;
}());
export { CourseDaysLesson };
