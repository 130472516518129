var _a;
import * as React from 'react';
import { routes } from '@lean-body/src/route';
import { css, StyleSheet } from 'aphrodite';
import { convertToSeconds, useXlMediaQuery } from '@lean-body/src/util';
import { Dialog } from '@lean-body/components/molecules';
import { SettingItemKeys } from '@lean-body/components/organisms/setting_menu';
import { MediaBreakPointUp, Colors } from '@lean-body/components/styles';
import { ButtonType, Button } from '../atoms';
import { useNavigate } from 'react-router';
var mypageInputPromotionImgPath = '/assets/images/dialog/mypage_input_promotion.png';
export var MypageInputPromotionDialog = function (_a) {
    var apiClient = _a.apiClient, close = _a.close;
    var navigate = useNavigate();
    var isXL = useXlMediaQuery();
    React.useEffect(function () {
        apiClient.updateDisplayMypageInputPromotion(convertToSeconds(Date.now()));
    }, []);
    var moveToLink = function () {
        close();
        navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.editMypage));
    };
    return (React.createElement(Dialog, { onClickCancel: close, isCloseButton: true, classObject: styles.dialogContent, content: React.createElement("div", { className: css(styles.container) },
            React.createElement("img", { className: css(styles.headImg), src: mypageInputPromotionImgPath }),
            React.createElement("div", { className: css(styles.content) },
                React.createElement("div", { className: css(styles.title) },
                    "\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB",
                    !isXL && React.createElement("br", null),
                    "\u5165\u529B\u3057\u307E\u305B\u3093\u304B\uFF1F\uD83D\uDCDD"),
                React.createElement("div", { className: css(styles.description) }, "\u8208\u5473\u306E\u3042\u308B\u30B8\u30E3\u30F3\u30EB\u3084\u304A\u60A9\u307F\u30FB\u4F53\u91CD\u30FB\u6027\u5225\u3092\u8A2D\u5B9A\u3059\u308B\u3053\u3068\u3067\u3001\u3042\u306A\u305F\u306B\u30DE\u30C3\u30C1\u3059\u308B\u30EC\u30C3\u30B9\u30F3\u3092\u304A\u5C4A\u3051\u3067\u304D\u308B\u3088\u3046\u306B\u3057\u305F\u3044\u3068\u601D\u3063\u3066\u3044\u307E\u3059\uD83D\uDE0E\u3055\u3063\u305D\u304F\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB\u3092\u57CB\u3081\u3066\u307F\u307E\u3057\u3087\u3046\uD83D\uDCAA\uD83E\uDDE1"),
                React.createElement(Button, { buttonType: ButtonType.pink, className: css(styles.button), onClick: moveToLink, isRadius: true }, "\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB\u3092\u5165\u529B\u3059\u308B"))) }));
};
var styles = StyleSheet.create({
    closeIconContainer: {
        position: 'fixed',
        width: 32,
        height: 32,
        top: 24,
        left: 24,
        backgroundColor: Colors.White,
        border: "1px solid ".concat(Colors.BorderLightGrayB),
        borderRadius: 16,
    },
    closeIcon: {
        width: '50%',
        height: '50%',
        top: 8,
        left: 8,
    },
    container: {
        borderRadius: 8,
        overflow: 'hidden',
        backgroundColor: Colors.White,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px 18px 20px',
    },
    dialogContent: {
        top: '50%',
    },
    headImg: {
        width: '100%',
    },
    title: {
        fontWeight: 'bold',
        fontSize: 20,
        lineHeight: '28px',
        letterSpacing: '1.6px',
        textAlign: 'center',
    },
    description: {
        fontSize: 13,
        margin: '8px 0 16px',
        lineHeight: '21px',
        letterSpacing: '0.52px',
        fontWeight: 400,
    },
    button: (_a = {
            display: 'block',
            width: '100%',
            minHeight: 48,
            fontSize: 14,
            paddingTop: 11,
            paddingBottom: 11
        },
        _a[MediaBreakPointUp.XL] = {
            width: 'auto',
            minWidth: 320,
        },
        _a),
});
