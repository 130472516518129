import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Button, ButtonType, CloseButtonLabel } from '../atoms';
import { Dialog } from '../molecules';
export var WeightGoalAchievedDialog = function (_a) {
    var onClickSetNewGoal = _a.onClickSetNewGoal, onClickCancel = _a.onClickCancel, classObject = _a.classObject;
    return (React.createElement(Dialog, { isCloseButton: true, onClickCancel: onClickCancel, closeButtonLabel: CloseButtonLabel.Close, classObject: [styles.achieveDialog, classObject], content: React.createElement(React.Fragment, null,
            React.createElement("img", { className: css(styles.achieveDialogImg), src: "/assets/images/dialog/weight_goal_achieved_dialog.png" }),
            React.createElement(Button, { className: css(styles.achiveDialogSetGoalBtn), isRadius: true, buttonType: ButtonType.pink, onClick: onClickSetNewGoal }, "\u65B0\u305F\u306A\u76EE\u6A19\u3092\u8A2D\u5B9A\u3059\u308B")) }));
};
var styles = StyleSheet.create({
    achieveDialog: {
        maxWidth: 327,
        textAlign: 'center',
    },
    achieveDialogImg: {
        width: '100%',
        height: 327,
        borderRadius: '8px 8px 0 0',
    },
    achiveDialogSetGoalBtn: {
        width: 290,
        margin: '13px 0 18px 0',
    },
});
