import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { Button, ButtonType, ReplayIcon } from '@lean-body/components/atoms';
export var CourseRetakeButton = function (_a) {
    var buttonType = _a.buttonType, onClick = _a.onClick, classObject = _a.classObject;
    var color = buttonType === ButtonType.transparentBackgroundPrimary ? Colors.Primary : Colors.White;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(Button, { isRadius: true, buttonType: buttonType, className: css(styles.button), onClick: onClick },
            React.createElement(ReplayIcon, { color: color }),
            "\u3082\u3046\u4E00\u5EA6\u53D7\u8B1B\u3059\u308B")));
};
var styles = StyleSheet.create({
    container: {},
    button: {
        margin: '0 auto',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 6,
    },
});
