import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { Tab, Tabs as MauTabs } from '@mui/material';
var TAB_HEIGHT = 30;
export var ToggleTabs = function (props) {
    var setTab = props.setTab, currentTab = props.currentTab, tabs = props.tabs, classObject = props.classObject, flexContainerClassObject = props.flexContainerClassObject, tabRootClassObject = props.tabRootClassObject;
    var onChangeTab = function (_, v) {
        setTab(v);
    };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(MauTabs, { value: currentTab, onChange: onChangeTab, classes: {
                root: css(styles.tabWrapper),
                indicator: css(styles.indicator),
                flexContainer: css(flexContainerClassObject),
            } }, Object.keys(tabs).map(function (key, i) { return (React.createElement(Tab, { label: tabs[key], classes: {
                root: css(styles.tabName, tabRootClassObject),
                selected: css(styles.selectedTabName),
            }, value: tabs[key], "data-test": key, key: i })); }))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    tabWrapper: {
        display: 'inline-block',
        minHeight: TAB_HEIGHT,
        height: TAB_HEIGHT,
        color: Colors.Black,
        backgroundColor: Colors.White,
    },
    indicator: {
        height: 0,
    },
    tabName: {
        position: 'relative',
        fontSize: 12,
        fontWeight: 'bold',
        padding: '0 12px',
        borderRadius: 100,
        border: "1px solid ".concat(Colors.Gray),
        minHeight: TAB_HEIGHT,
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms', // material-uiに合わせてる
        minWidth: 0, // material-uiのデフォルトのminWidthが大きすぎるので消す
        ':not(:last-of-type)': {
            marginRight: 10,
        },
    },
    selectedTabName: {
        color: Colors.White,
        backgroundColor: Colors.PrimaryDark,
        border: "1px solid ".concat(Colors.PrimaryDark),
    },
});
