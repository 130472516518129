import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Avatar } from '@mui/material';
import { CameraWithBg } from '@lean-body/components/atoms';
export var AVATAR_DEFAULT_SRC = '/assets/images/icons/no_profile.svg';
export var AvatarUploader = function (_a) {
    var src = _a.src, onClick = _a.onClick, className = _a.className;
    var imgError = function (e) {
        e.target.src = AVATAR_DEFAULT_SRC;
    };
    return (React.createElement("div", { className: className },
        React.createElement("input", { id: "avatar", accept: "image/*", style: { display: 'none' }, type: "file", onChange: onClick }),
        React.createElement("label", { htmlFor: "avatar", style: { cursor: 'pointer' } },
            React.createElement("div", { className: css(styles.wrapper), style: { cursor: 'pointer' } },
                React.createElement(Avatar, { src: src, className: css(styles.size), imgProps: { onError: imgError } }),
                React.createElement(CameraWithBg, { classObject: styles.cameraIcon, svgWidth: "100%" })))));
};
var styles = StyleSheet.create({
    size: {
        // 正円
        width: 100,
        height: 100,
        borderRadius: '50%',
    },
    wrapper: {
        width: '100%',
        position: 'relative',
    },
    cameraIcon: {
        position: 'absolute',
        top: 33,
        left: 31,
        width: 38,
        height: 34,
        background: 'none',
    },
});
