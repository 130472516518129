import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useIncrement } from '@lean-body/src/util';
import { Colors } from '../styles';
import { LifetimeActivityRankRate } from '../atoms/lifetime_activity_rank_rate';
import * as dayjs from 'dayjs';
import 'swiper/css';
import 'swiper/css/navigation';
var REMAIN_DAY_DIAPLAY_LIMIT = 6;
export var LifetimeActivityRank = function (props) {
    var aggregate = props.userSeasonAggregate;
    var classObject = props.classObject;
    var _a = React.useState(0), numerator = _a[0], setNumerator = _a[1];
    var playDurationMinute = aggregate ? aggregate.playDurationMinute : 0;
    var duration = 1000;
    useIncrement(duration, playDurationMinute, setNumerator);
    var rank = null;
    var nextUserSeasonAggregateRank = null;
    var remainDay = -1;
    if (aggregate) {
        rank = aggregate.userSeasonAggregateRank;
        remainDay = dayjs().isAfter(aggregate.seasonEndAtDayjs) ? -1 : aggregate.seasonEndAtDayjs.diff(dayjs(), 'day');
        if (rank)
            nextUserSeasonAggregateRank = rank.nextUserSeasonAggregateRank;
    }
    if (!aggregate)
        return null;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.leftContentsWrapper) },
            React.createElement("img", { src: rank === null || rank === void 0 ? void 0 : rank.thumbnailSrc, className: css(styles.rankImg) })),
        React.createElement("div", { className: css(styles.rightContentsWrapper) },
            React.createElement("div", { className: css(styles.seasonDuration) },
                "\u671F\u9593\uFF1A",
                REMAIN_DAY_DIAPLAY_LIMIT >= remainDay && 0 <= remainDay ? (React.createElement("span", { className: css(styles.seasonDurationPink) },
                    "\u3042\u3068",
                    remainDay + 1,
                    "\u65E5\uFF01")) : (React.createElement("span", { className: css(styles.seasonDurationBold) },
                    aggregate.seasonStartAtDayjs.format('YYYY/M/D'),
                    "~",
                    aggregate.seasonEndAtDayjs.format('M/D')))),
            React.createElement(LifetimeActivityRankRate, { numerator: Math.round(numerator), numeratorMax: aggregate.playDurationMinute, denominator: nextUserSeasonAggregateRank === null || nextUserSeasonAggregateRank === void 0 ? void 0 : nextUserSeasonAggregateRank.playDurationThresholdMinute }),
            React.createElement("div", { className: css(styles.playDurationText) },
                "\u6B21\u306E\u30E9\u30F3\u30AF\uFF1A",
                React.createElement("span", { className: css(styles.playDurationTextBold) }, nextUserSeasonAggregateRank === null || nextUserSeasonAggregateRank === void 0 ? void 0 : nextUserSeasonAggregateRank.name)))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        padding: '24px 20px 20px',
        minHeight: '86px',
    },
    leftContentsWrapper: {
        display: 'flex',
    },
    rightContentsWrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '220px',
    },
    seasonDuration: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontSize: 10,
        color: Colors.GrayDarkest,
        textAlign: 'center',
        letterSpacing: '0.04em',
        marginBottom: 16,
    },
    seasonDurationBold: {
        fontSize: 12,
        lineHeight: '12px',
        fontWeight: 'bold',
    },
    seasonDurationPink: {
        fontSize: 12,
        lineHeight: '12px',
        fontWeight: 'bold',
        color: Colors.Accent,
        marginLeft: 3,
    },
    rankImg: {
        height: 82,
    },
    playDurationText: {
        fontSize: 10,
        lineHeight: '18px',
        color: Colors.GrayDarkest,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginTop: '6px',
    },
    playDurationTextBold: {
        fontSize: 12,
        fontWeight: 'bold',
    },
});
