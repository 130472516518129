import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { ArrowIcon, ArrowDirection } from '@lean-body/components/atoms/icons/arrow_icon';
export var SearchConditionsButton = function (_a) {
    var label = _a.label, enteredForButtonColor = _a.enteredForButtonColor, onClick = _a.onClick;
    return (React.createElement("div", { className: css(styles.container, enteredForButtonColor && styles.buttonPrimary), onClick: onClick },
        label,
        React.createElement(ArrowIcon, { classObject: styles.arrowIcon, direction: ArrowDirection.bottom, color: enteredForButtonColor ? Colors.White : Colors.Black })));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 12px',
        fontSize: 12,
        backgroundColor: Colors.White,
        border: "1px solid ".concat(Colors.Gray),
        borderRadius: 100,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    buttonPrimary: {
        color: Colors.White,
        backgroundColor: Colors.PrimaryDark,
        border: "1px solid ".concat(Colors.PrimaryDark),
    },
    arrowIcon: {
        width: 10,
        height: 8,
        marginLeft: 3,
    },
});
