var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MediaBreakPointUp } from '@lean-body/components/styles';
import { LessonComponent } from './lesson_component';
export var CourseDayLessons = function (_a) {
    var courseLessons = _a.courseLessons, isVertical = _a.isVertical, isMin = _a.isMin, disableHistoryPush = _a.disableHistoryPush, apiClient = _a.apiClient, classObject = _a.classObject, onClick = _a.onClick;
    return (React.createElement("div", { className: css(styles.container, classObject) }, courseLessons.map(function (courseLesson, i) { return (React.createElement("div", { className: css(styles.lesson), key: i },
        React.createElement(LessonComponent, { lesson: courseLesson.lesson, courseDayLessonID: courseLesson.id, addFavoriteLesson: apiClient.addFavoriteLesson, unFavoriteLesson: apiClient.unFavoriteLesson, isMin: isMin, isVertical: isVertical, onClick: onClick, disableHistoryPush: disableHistoryPush }))); })));
};
var styles = StyleSheet.create({
    container: (_a = {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 12,
            width: '100%'
        },
        _a[MediaBreakPointUp.MD] = {
            gap: 20,
        },
        _a),
    lesson: {
        width: '100%',
    },
});
