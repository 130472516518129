import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../styles';
export var RequiredBadge = function (_a) {
    var classObject = _a.classObject;
    return React.createElement("span", { className: css(classObject, styles.container) }, "\u5FC5\u9808");
};
var styles = StyleSheet.create({
    container: {
        width: 33,
        height: 19,
        padding: '0 6px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        backgroundColor: Colors.CyanLightest,
        color: Colors.GrayDarkest,
        fontSize: 10,
        fontWeight: 700,
        lineHeight: '140%',
        letterSpacing: 0.3,
    },
});
