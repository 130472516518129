var _a;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { EquipSection } from '../organisms';
import { LessonPlaylistBox, SHADOW_WIDTH } from './lesson_playlist_box';
import { LessonMetaTrainers } from '../atoms/lesson_meta_trainers';
import { LessonMetaUserAction } from './lesson_meta_user_action';
import { LessonMetaTitleWithInfo } from '../atoms/lesson_meta_title_with_info';
import { useXlMediaQuery } from '@lean-body/src/util';
import { useNavigate } from 'react-router';
import { LessonType, routes } from '@lean-body/src/route';
import { LessonComponent } from './lesson_component';
import { Button, ButtonType } from '../atoms';
import { PlayButtonIcon } from '../atoms/icons/play_button_icon';
export var ProgramMeta = function (_a) {
    var lesson = _a.lesson, currentLessonIndex = _a.currentLessonIndex, program = _a.program, programLessons = _a.programLessons, isFavorite = _a.isFavorite, totalFavoriteCount = _a.totalFavoriteCount, onClickFav = _a.onClickFav, onClickTwitterShareIfAuthed = _a.onClickTwitterShareIfAuthed, addFavoriteLesson = _a.addFavoriteLesson, unFavoriteLesson = _a.unFavoriteLesson, twitterService = _a.twitterService, displayLessonPlaylistBox = _a.displayLessonPlaylistBox, onClickProgramPlayButton = _a.onClickProgramPlayButton;
    var navigate = useNavigate();
    var isXL = useXlMediaQuery();
    var onClickLessonPlaylistBoxButton = function () {
        navigate(routes.LESSON.compile({ slug: lesson.slug, type: LessonType.normal }));
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.info) },
            React.createElement(LessonMetaTitleWithInfo, { title: program.name, favoriteCount: totalFavoriteCount, difficulty: program.difficulty, tags: program.tags, lessonLength: program.lessonLength }),
            React.createElement(LessonMetaUserAction, { classObject: styles.userActionRow, program: program, isFavorite: isFavorite, onClickFav: onClickFav, twitterService: twitterService, onClickTwitterShareIfAuthed: onClickTwitterShareIfAuthed, onClickProgramPlayButton: onClickProgramPlayButton })),
        isXL && (React.createElement(Button, { className: css(styles.seriesPlayButton), buttonType: ButtonType.primary, onClick: onClickLessonPlaylistBoxButton },
            React.createElement(PlayButtonIcon, { classObject: styles.playButton }),
            "\u30B7\u30EA\u30FC\u30BA\u3092\u518D\u751F")),
        React.createElement(LessonMetaTrainers, { classObject: styles.section, trainers: program.trainers }),
        React.createElement("div", { className: css(styles.descriptionSection, styles.section) },
            React.createElement("div", { className: css(styles.description) }, program.description)),
        React.createElement(EquipSection, { classObject: [styles.equipmentsSection, styles.section], title: "\u4F7F\u7528\u3059\u308B\u30A2\u30A4\u30C6\u30E0", equips: program.equipments, noUnderline: true }),
        displayLessonPlaylistBox && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.section, styles.lessonSection) },
                React.createElement("div", { className: css(styles.lessonSectionTitle) },
                    "\u30EC\u30C3\u30B9\u30F3 (",
                    programLessons.length,
                    ")"),
                programLessons.map(function (v, i) {
                    return (React.createElement("div", { className: css(styles.lesson), key: i, "data-test": "lessonItem" },
                        React.createElement(LessonComponent, { lesson: v, addFavoriteLesson: addFavoriteLesson, unFavoriteLesson: unFavoriteLesson, isVertical: true, isMin: true })));
                })),
            React.createElement(LessonPlaylistBox, { program: program, currentLessonIndex: currentLessonIndex, programLessons: programLessons, addFavoriteLesson: addFavoriteLesson, unFavoriteLesson: unFavoriteLesson, isShowOtherLessons: false, buttonText: !isXL && 'シリーズを再生', buttonClick: onClickLessonPlaylistBoxButton })))));
};
var styles = StyleSheet.create({
    container: (_a = {
            position: 'relative',
            background: Colors.White,
            padding: '14px 16px 165px',
            flex: 1
        },
        _a[MediaBreakPointUp.MD] = {
            padding: "14px ".concat(SHADOW_WIDTH, "px 100px"),
        },
        _a[MediaBreakPointUp.XL] = {
            padding: '14px 0 0',
        },
        _a),
    info: {
        paddingBottom: 14,
    },
    userActionRow: {
        marginTop: 16,
    },
    seriesPlayButton: {
        width: '100%',
        height: 48,
        marginTop: 4,
        marginBottom: 24,
        paddingTop: 11,
        paddingBottom: 11,
        borderRadius: 24,
    },
    playButton: {
        marginRight: 10,
    },
    section: {
        borderTop: "1px solid ".concat(Colors.BorderLightGray),
    },
    descriptionSection: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    description: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'flex-start',
    },
    equipmentsSection: {
        marginBottom: 0,
        paddingBottom: 10,
    },
    lessonSection: {
        paddingTop: 10,
    },
    lessonSectionTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: '0.04em',
        marginBottom: 10,
    },
    lesson: {
        paddingTop: 6,
        paddingBottom: 6,
    },
});
