import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import PopularKeyword from '../../atoms/popular_keyword';
import SearchTitle from './search_title';
var SearchKeyword = function (_a) {
    var title = _a.title, keywords = _a.keywords, className = _a.className, onClickKeyword = _a.onClickKeyword, onClickCancel = _a.onClickCancel, keywordClassObject = _a.keywordClassObject;
    if (!keywords)
        return null;
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: css(styles.searchTitleContainer) },
            React.createElement(SearchTitle, { classObject: styles.searchTitle }, title),
            onClickCancel && (React.createElement("div", { className: css(styles.cancel), onClick: onClickCancel }, "\u30AD\u30E3\u30F3\u30BB\u30EB"))),
        React.createElement("div", { className: css(styles.box) }, keywords.map(function (keyword, index) { return (React.createElement(PopularKeyword, { key: index, keyword: keyword, onClick: onClickKeyword, className: css(keywordClassObject) })); }))));
};
var styles = StyleSheet.create({
    searchTitleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    searchTitle: {
        margin: '18px 0',
    },
    cancel: {
        fontSize: 12,
        fontWeight: 'bold',
        color: Colors.PrimaryDark,
        cursor: 'pointer',
    },
    box: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 8,
    },
});
export default SearchKeyword;
