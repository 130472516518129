var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { bind } from 'bind-decorator';
import { Colors } from '@lean-body/components/styles';
import { CloseRoundIcon } from '@lean-body/components/atoms/icons/close_round_icon';
import { debounce } from 'lodash-es';
import { Eye } from './icons/eye';
import { RequiredBadge } from './required_badge';
var Input = /** @class */ (function (_super) {
    __extends(Input, _super);
    function Input(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            pwShowChecked: false,
            onChangeDebounce: _this.props.onChangeDebounce
                ? debounce(_this.props.onChangeDebounce, _this.props.debounceMs)
                : null,
        };
        return _this;
    }
    Object.defineProperty(Input.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement("div", { className: css(this.styles.container, this.props.containerClassObject) },
                this.props.label && (React.createElement("div", { className: css(this.styles.label, this.props.labelClassObject) },
                    this.props.label,
                    this.props.required && React.createElement(RequiredBadge, null))),
                React.createElement("span", { className: css(this.styles.inputContainer) },
                    React.createElement("input", { type: this.inputType, value: this.props.value, onChange: this.handleOnChange, onKeyDown: this.props.onKeyDown, disabled: this.props.disabled, className: css(this.styles.input, this.props.hideSpinner && this.styles.spinnerHidden, this.props.hasValidationError && this.styles.validationError, this.props.classObject), placeholder: this.props.placeholder, id: this.props.id, step: this.props.step, name: this.props.name, "data-test": this.props.dataTest, autoFocus: this.props.autoFocus, onFocus: this.props.onFocus, required: this.props.required }),
                    this.props.unitLabel && (React.createElement("span", { className: css(this.styles.unitLabel, this.props.unitLabelClassObject) }, this.props.unitLabel)),
                    this.props.enablePwVisible && (React.createElement(Eye, { classObject: this.styles.visiblePwWrap, onClick: this.onClickPasswordVisible, close: this.state.pwShowChecked })),
                    this.props.validationErrorMessage && (React.createElement("div", { className: css(this.styles.validationErrorMessage) }, this.props.validationErrorMessage))),
                this.props.onClickClearBtn && this.props.value && (React.createElement(CloseRoundIcon, { size: 15, color: Colors.Gray, containerClassObject: [this.styles.closeIconContainer, this.props.closeIconContainerClassObject], onClick: this.props.onClickClearBtn }))));
        }
    });
    Object.defineProperty(Input.prototype, "onClickPasswordVisible", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.setState({
                pwShowChecked: !this.state.pwShowChecked,
            });
        }
    });
    Object.defineProperty(Input.prototype, "handleOnChange", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            this.props.onChange(event);
            if (this.state.onChangeDebounce) {
                this.state.onChangeDebounce(event);
            }
        }
    });
    Object.defineProperty(Input.prototype, "inputType", {
        get: function () {
            if (this.state.pwShowChecked) {
                return 'text';
            }
            return this.props.type;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Input.prototype, "styles", {
        get: function () {
            return StyleSheet.create({
                container: {},
                label: {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                    fontSize: 14,
                    color: Colors.GrayDarkest,
                    marginBottom: 8,
                },
                inputContainer: {
                    position: 'relative',
                },
                input: {
                    backgroundColor: Colors.White,
                    border: "1px solid ".concat(Colors.Gray),
                    '-webkit-appearance': 'none',
                    color: Colors.Black,
                    cursor: 'pointer',
                    height: this.props.height ? this.props.height : 50,
                    width: this.props.width ? this.props.width : '100%',
                    // fontSize は基本的に統一をしたいので、どうしてもデザイナーと相談の上、props.classObject に fontSize のcssを当ててください
                    fontSize: 16,
                    letterSpacing: '1px',
                    // borderRadius は統一が前提なので、props.borderRadius は作らないでください
                    // props.classObject に borderRadius のcssを当ててください
                    borderRadius: 4,
                    textIndent: '8px',
                    paddingRight: this.state.pwShowChecked || this.props.onClickClearBtn ? 32 : 8,
                    outline: 'none',
                    transition: 'all 0.2s',
                    ':focus': {
                        borderColor: Colors.Primary,
                        boxShadow: "0 0 10px -5px ".concat(Colors.Primary),
                        cursor: 'text',
                    },
                    ':disabled': {
                        backgroundColor: '#efefef',
                        cursor: 'default',
                    },
                    '::placeholder': {
                        color: Colors.GrayDarkest,
                        letterSpacing: 2,
                    },
                    '::-webkit-input-placeholder': {
                        color: Colors.GrayDarkest,
                        letterSpacing: 2,
                    },
                    '::-ms-input-placeholder': {
                        color: Colors.GrayDarkest,
                        letterSpacing: 2,
                    },
                },
                visiblePwWrap: {
                    cursor: 'pointer',
                    display: 'inline',
                    position: 'absolute',
                    right: '14px',
                    top: '51%',
                    transform: 'translate(0, -50%)',
                },
                unitLabel: {
                    position: 'absolute',
                    top: 0,
                    right: 12,
                    color: Colors.GrayDarkest,
                },
                closeIconContainer: {
                    position: 'absolute',
                    top: 8,
                    right: 8,
                },
                spinnerHidden: {
                    '::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
                        margin: 0,
                        '-webkit-appearance': 'none',
                        '-moz-appearance': 'textfield',
                    },
                },
                validationError: {
                    ':focus': {
                        border: "1px solid ".concat(Colors.Alert),
                    },
                    border: "1px solid ".concat(Colors.Alert),
                },
                validationErrorMessage: {
                    marginTop: 4,
                    color: Colors.Alert,
                    fontSize: 12,
                    fontWeight: 400,
                    lineHeight: '160%',
                    letterSpacing: 0.48,
                },
            });
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], Input.prototype, "onClickPasswordVisible", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], Input.prototype, "handleOnChange", null);
    return Input;
}(React.Component));
export { Input };
