import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
export var TrainerDetailSection = function (props) {
    var _a;
    var styles = StyleSheet.create({
        container: (_a = {
                fontSize: 14,
                marginBottom: 14
            },
            _a[MediaBreakPointUp.SM] = {
                paddingTop: 20,
            },
            _a),
    });
    var containerClass = [props.className || '', css(styles.container)].join(' ');
    return (React.createElement("div", { className: containerClass },
        React.createElement(TrainerRow, { key: props.trainer.id, trainer: props.trainer })));
};
var TrainerRow = function (props) {
    var _a, _b, _c, _d, _e;
    var iconSize = 100;
    var iconSizePC = 160;
    var spacing = 20;
    var styles = StyleSheet.create({
        container: (_a = {
                display: 'flex',
                flexFlow: 'column nowrap',
                justifyContent: 'center',
                alignItems: 'center'
            },
            _a[MediaBreakPointUp.MD] = {
                flexFlow: 'row nowrap',
                justifyContent: 'space-between',
            },
            _a),
        icon: (_b = {
                marginTop: '10px',
                width: iconSize,
                height: iconSize,
                borderRadius: '50%',
                border: "1px solid ".concat(Colors.Gray)
            },
            _b[MediaBreakPointUp.SM] = {
                width: iconSizePC,
                height: iconSizePC,
                marginRight: 18,
            },
            _b),
        title: (_c = {
                fontSize: 16,
                fontWeight: 'bold',
                textAlign: 'center',
                margin: '8px 0'
            },
            _c[MediaBreakPointUp.SM] = {
                margin: '18px 0',
                fontSize: 32,
                textAlign: 'left',
            },
            _c),
        textContainer: (_d = {},
            _d[MediaBreakPointUp.MD] = {
                width: "calc(100% - ".concat(iconSize, "px - ").concat(spacing, "px)"),
            },
            _d),
        description: (_e = {
                color: Colors.Black,
                fontWeight: 300,
                fontSize: 13,
                lineHeight: '1.75'
            },
            _e[MediaBreakPointUp.MD] = {
                marginBottom: 16,
            },
            _e[MediaBreakPointUp.XL] = {
                fontSize: 16,
            },
            _e),
    });
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("img", { className: css(styles.icon), src: props.trainer.thumbnailURL }),
        React.createElement("div", { className: css(styles.textContainer) },
            React.createElement("div", { className: css(styles.title) }, props.trainer.name),
            React.createElement("div", { className: css(styles.description) }, props.trainer.bio))));
};
