var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { PlaylistLessonCard } from '@lean-body/components/molecules/playlist_lesson_card';
import { MediaBreakPointUp } from '@lean-body/components/styles/variables';
export var PlaylistDetail = function (_a) {
    var lessons = _a.lessons, lessonViewCounts = _a.lessonViewCounts, lessonFavoriteStatusArray = _a.lessonFavoriteStatusArray, onClickLesson = _a.onClickLesson, onClickLessonFavoriteButton = _a.onClickLessonFavoriteButton, className = _a.className;
    var wrapperClass = [className || '', css(styles.wrapper)].join(' ');
    var getIsFavoriteByStatusArray = function (lesson) {
        var lessonFavoriteStatus = lessonFavoriteStatusArray.find(function (data) { return data.id === lesson.id; });
        return !!lessonFavoriteStatus && lessonFavoriteStatus.isFavorite;
    };
    return (React.createElement("div", { className: wrapperClass },
        React.createElement("div", { className: css(styles.cards) }, lessons.map(function (lesson, index) {
            var _a;
            return (React.createElement(PlaylistLessonCard, { key: index, lesson: lesson, viewCount: (_a = lessonViewCounts.find(function (v) { return v.lessonId === lesson.id; })) === null || _a === void 0 ? void 0 : _a.view, isFavorite: getIsFavoriteByStatusArray(lesson), onClickPlayButton: onClickLesson, onClickLessonFavoriteButton: onClickLessonFavoriteButton, classObject: styles.card }));
        }))));
};
var styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
    },
    card: (_a = {
            marginBottom: 12,
            ':last-child': {
                marginBottom: 0,
            }
        },
        _a[MediaBreakPointUp.MD] = {
            marginBottom: 24,
        },
        _a),
    cards: {
        flex: 1,
    },
});
