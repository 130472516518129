import * as React from 'react';
import { DailyTasksPageTaskName } from '@lean-body/src/route';
import { RecordMeal } from '../organisms/record_meal';
import { PageLoading } from '../organisms';
import dayjs from 'dayjs';
import { useQuery } from '@lean-body/src/util';
export var DailyTaskSubtaskPage = function (_a) {
    var taskName = _a.taskName, subtaskName = _a.subtaskName, apiClient = _a.apiClient, config = _a.config;
    var query = useQuery();
    var date = dayjs(query.get('date') || new Date());
    var _b = React.useState(), dailyTasks = _b[0], setDailyTasks = _b[1];
    React.useEffect(function () {
        apiClient.fetchDailyTasks(date.toDate()).then(setDailyTasks);
    }, []);
    if (!dailyTasks) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement(React.Fragment, null, taskName === DailyTasksPageTaskName.meal && (React.createElement(RecordMeal, { date: date.toDate(), name: subtaskName, mealTask: dailyTasks.meal, apiClient: apiClient, config: config }))));
};
