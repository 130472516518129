import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Dialog } from '@lean-body/components/molecules';
import { CloseButtonLabel } from '@lean-body/components/atoms/dialog_close_button';
import { Button, ButtonType } from './button';
export var DeleteDialog = function (_a) {
    var submit = _a.submit, close = _a.close, title = _a.title, description = _a.description;
    return (React.createElement(Dialog, { closeButtonLabel: CloseButtonLabel.Cancel, onClickCancel: close, isCloseButton: true, content: React.createElement("div", { className: css(styles.container) },
            React.createElement("img", { className: css(styles.img), src: "/assets/images/warning.png" }),
            React.createElement("div", null, title),
            React.createElement("div", { className: css(styles.description) }, description),
            React.createElement(Button, { className: css(styles.button), isRadius: true, buttonType: ButtonType.primary, onClick: submit }, "\u524A\u9664\u3059\u308B")) }));
};
var styles = StyleSheet.create({
    container: {
        padding: 24,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    img: {
        margin: '40px auto',
        width: 68,
    },
    description: {
        fontWeight: 'initial',
        margin: '16px 0 40px',
    },
    button: {
        width: '100%',
        maxWidth: 300,
        fontSize: 16,
    },
});
