/* eslint-disable @typescript-eslint/no-namespace */
export var LayoutConsts = {
    SectionSpacing: 40,
};
export var LessonCarouselConsts = {
    CardWidth: 220,
    Spacing: 12,
    AnimationDuration: 500,
    AnimationBlendExp: 5,
};
export var ZIndex;
(function (ZIndex) {
    ZIndex[ZIndex["VideoPlayer"] = 0] = "VideoPlayer";
    ZIndex[ZIndex["VideoChild"] = 1] = "VideoChild";
    ZIndex[ZIndex["VideoPlayButton"] = 2] = "VideoPlayButton";
    ZIndex[ZIndex["FavoriteCount"] = 3] = "FavoriteCount";
    ZIndex[ZIndex["FavoriteButton"] = 4] = "FavoriteButton";
    ZIndex[ZIndex["SearchBox"] = 5] = "SearchBox";
    ZIndex[ZIndex["PlaylistFooter"] = 6] = "PlaylistFooter";
    ZIndex[ZIndex["MypageFooter"] = 7] = "MypageFooter";
    ZIndex[ZIndex["MainMenu"] = 200] = "MainMenu";
    ZIndex[ZIndex["MainMenuScrollIcon"] = 201] = "MainMenuScrollIcon";
    ZIndex[ZIndex["Header"] = 202] = "Header";
    ZIndex[ZIndex["PlaylistHeader"] = 203] = "PlaylistHeader";
    ZIndex[ZIndex["NextLesson"] = 204] = "NextLesson";
    ZIndex[ZIndex["Footer"] = 205] = "Footer";
    ZIndex[ZIndex["HomePositionFixed"] = 206] = "HomePositionFixed";
    ZIndex[ZIndex["Overlay"] = 207] = "Overlay";
    ZIndex[ZIndex["SettingMenuModal"] = 208] = "SettingMenuModal";
    ZIndex[ZIndex["Alert"] = 209] = "Alert";
})(ZIndex || (ZIndex = {}));
export var HomePageLayoutConsts = {
    HContentInset: {
        Large: 88,
        Mid: 44,
        Small: 12,
    },
};
export var ScreenSize;
(function (ScreenSize) {
    ScreenSize[ScreenSize["XS"] = 0] = "XS";
    ScreenSize[ScreenSize["SM"] = 1] = "SM";
    ScreenSize[ScreenSize["MD"] = 2] = "MD";
    ScreenSize[ScreenSize["LG"] = 3] = "LG";
    ScreenSize[ScreenSize["XL"] = 4] = "XL";
})(ScreenSize || (ScreenSize = {}));
export var Screen;
(function (Screen) {
    Screen.MinSM = 450;
    Screen.MinMD = 768;
    Screen.MinLG = 992;
    Screen.MinXL = 1300;
    function getSize(width) {
        if (width < Screen.MinSM) {
            return ScreenSize.XS;
        }
        if (width < Screen.MinMD) {
            return ScreenSize.SM;
        }
        if (width < Screen.MinLG) {
            return ScreenSize.MD;
        }
        if (width < Screen.MinXL) {
            return ScreenSize.LG;
        }
        return ScreenSize.XL;
    }
    Screen.getSize = getSize;
    function isSMUp(width) {
        return width > Screen.MinSM;
    }
    Screen.isSMUp = isSMUp;
    function isMDUp(width) {
        return width > Screen.MinMD;
    }
    Screen.isMDUp = isMDUp;
    function isLGUp(width) {
        return width > Screen.MinLG;
    }
    Screen.isLGUp = isLGUp;
    function isXLUp(width) {
        return width > Screen.MinXL;
    }
    Screen.isXLUp = isXLUp;
})(Screen || (Screen = {}));
export var SITE_DOMAIN = 'lean-body.jp';
export var CORPORATE_LINK = 'https://lean-body.co.jp/';
export var ASSETS_BASE_PATH = '/assets/';
export var EF_INDICATOR_LABELS = ['ご登録', 'プラン登録', '完了'];
export var LIFESTYLE_PROGRAM_ID = 37;
// date
export var ONE_HOUR = 1;
export var ONE_DAY = 1;
export var ONE_WEEK_DAYS = 7;
export var ONE_HOUR_SECONDS = 3600;
export var ONE_DAY_HOURS = 24;
// 1回のページングで読み込むコンテンツの最大数
export var PROGRAMS_PAGING_PER_LENGTH = 20;
// Zendesk URL
export var ZENDESK_URL = 'https://wondernuts.zendesk.com/hc/ja';
export var ZENDESK_REQUEST_URL = 'https://wondernuts.zendesk.com/hc/ja/requests/new';
export var ZENDESK_FOR_LINE_USER_URL = 'https://wondernuts.zendesk.com/hc/ja/articles/360020279713-LINE%E3%81%A7%E7%99%BB%E9%8C%B2%E3%81%97%E3%81%9F%E3%81%8C-%E3%83%A1%E3%83%BC%E3%83%AB%E3%82%A2%E3%83%89%E3%83%AC%E3%82%B9%E3%81%8C%E7%95%B0%E3%81%AA%E3%82%8B';
export var ZENDESK_QA_LINK = 'https://wondernuts.zendesk.com/hc/ja/sections/360000526334-%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F';
export var ZENDESK_REQUESTS_LINK = 'https://wondernuts.zendesk.com/hc/ja/requests/new';
export var ZNEDESK_PRIVACY_POLICY_LINK = 'https://wondernuts.zendesk.com/hc/ja/articles/360002884473-%E5%80%8B%E4%BA%BA%E6%83%85%E5%A0%B1%E4%BF%9D%E8%AD%B7%E6%96%B9%E9%87%9D';
export var ZENDESK_SPECIFIED_COMMERCIAL_TRANSACTION_LAW_LINK = 'https://wondernuts.zendesk.com/hc/ja/articles/360002856414-%E7%89%B9%E5%AE%9A%E5%95%86%E5%8F%96%E5%BC%95%E6%B3%95%E3%81%AB%E5%9F%BA%E3%81%A5%E3%81%8F%E8%A1%A8%E8%A8%98';
// 栄養素の判定基準について
export var ZENDESK_NUTRIENTS_JUDGEMENT = 'https://wondernuts.zendesk.com/hc/ja/articles/30515293530265--%E6%A0%84%E9%A4%8A%E7%B4%A0%E3%81%AE%E5%88%A4%E5%AE%9A%E5%9F%BA%E6%BA%96%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6';
// Vimeo
export var VIMEO_URL = 'https://player.vimeo.com/video';
// システムで性別を扱う https://qiita.com/aoshirobo/items/32deb45cb8c8b87d65a4
export var Gender = {
    NotKnown: {
        value: 0,
        // 不明
        // material.uiのメニューから消えてしまうため全角スペースを入れる
        name: '　',
    },
    Male: {
        value: 1,
        name: '男性',
    },
    Female: {
        value: 2,
        name: '女性',
    },
    NotApplicable: {
        value: 9,
        // 適用不能
        name: 'その他',
    },
};
export var HOME_SECTION_MARGIN_LEFT_TAB = 48;
export var HOME_SECTION_MARGIN_BOTTOM = 24;
