var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointDown, MediaBreakPointUp } from '@lean-body/components/styles';
import { convertUnixToDate, getFormatedDate, toTimeFromUnix } from '@lean-body/src/util';
export var LiveLessonDescription = function (props) {
    var liveLesson = props.liveLesson;
    return (React.createElement("div", { className: css(styles.lessonDescriptionBox) },
        React.createElement("div", { className: css(styles.lessonDescriptionHeader) },
            React.createElement("div", { className: css(styles.lessonTimeContainer) },
                React.createElement("div", { className: css(styles.liveDate) }, getFormatedDate(convertUnixToDate(liveLesson.startsAt))),
                React.createElement("div", { className: css(styles.liveTime) }, liveLesson.isEvent
                    ? "9:00\u301C12:00"
                    : "".concat(toTimeFromUnix(liveLesson.startsAt), "\u301C").concat(toTimeFromUnix(liveLesson.lessonEndAt)))),
            React.createElement("div", { className: css(styles.lessonTitle) }, liveLesson.title),
            !liveLesson.isEvent && (React.createElement("div", { className: css(styles.supplement) },
                React.createElement("div", { className: css(styles.calorie) },
                    liveLesson.minCalorie,
                    "-",
                    liveLesson.maxCalorie,
                    "kcal"),
                React.createElement("div", { className: css(styles.slash) }, "/"),
                React.createElement("div", { className: css(styles.difficulty) }, liveLesson.difficulty.name)))),
        React.createElement("div", { className: css(styles.description) }, liveLesson.description)));
};
var styles = StyleSheet.create({
    lessonDescriptionBox: (_a = {},
        _a[MediaBreakPointDown.MD] = {
            marginBottom: 40,
        },
        _a[MediaBreakPointUp.XL] = {
            paddingBottom: 48,
        },
        _a),
    lessonDescriptionHeader: {
        marginBottom: 26,
    },
    lessonTimeContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 5,
    },
    liveDate: {
        color: Colors.Black,
        marginRight: 15,
        fontSize: 17,
    },
    liveTime: {
        color: Colors.Black,
    },
    lessonTitle: {
        fontSize: 20,
        fontWeight: 700,
        marginBottom: 6,
    },
    supplement: {
        display: 'flex',
        alignItems: 'center',
    },
    calorie: {
        fontSize: 12,
    },
    slash: {
        color: Colors.Gray,
        margin: '0 4px',
        fontSize: 12,
    },
    difficulty: {
        fontSize: 11,
    },
    description: {
        color: Colors.Black,
        fontSize: 14,
        fontWeight: 300,
    },
});
