var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as React from 'react';
import { Link } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import { bind } from 'bind-decorator';
import { Colors, icon, MediaBreakPointUp } from '@lean-body/components/styles';
import { Logo, Button } from '@lean-body/components/atoms';
import { routes } from '@lean-body/src/route';
import { FooterComponent } from '@lean-body/components/organisms';
export var MagazineUnsubscribePage = observer(/** @class */ (function (_super) {
    __extends(MagazineUnsubscribePage, _super);
    function MagazineUnsubscribePage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(MagazineUnsubscribePage.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(this.styles.container) },
                    React.createElement("div", { className: css(this.styles.card) },
                        React.createElement(Link, { to: "/" },
                            React.createElement(Logo, { classObject: this.styles.logo })),
                        this.renderIcon(),
                        React.createElement("span", { className: css(this.styles.notice) }, this.props.vm.pageStatus),
                        React.createElement(Button, { onClick: this.props.vm.onClickHomeBtn }, "HOME\u3078"))),
                React.createElement(FooterComponent, null)));
        }
    });
    Object.defineProperty(MagazineUnsubscribePage.prototype, "renderIcon", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var iconClass = [css(icon.anticon, icon.anticonCheck2, this.styles.checkIcon)].join(' ');
            return React.createElement("i", { className: iconClass });
        }
    });
    Object.defineProperty(MagazineUnsubscribePage.prototype, "styles", {
        get: function () {
            var _a, _b;
            return StyleSheet.create({
                container: {
                    backgroundColor: Colors.Gray,
                    alignContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: '90vh',
                    width: '100%',
                    textAlign: 'center',
                },
                card: (_a = {
                        backgroundColor: '#fff',
                        borderRadius: 4,
                        padding: '35px 22px',
                        marginTop: -20,
                        maxWidth: 420,
                        width: '92.5%'
                    },
                    _a[MediaBreakPointUp.SM] = {
                        padding: '25px 35px',
                        width: 'auto',
                    },
                    _a),
                notice: (_b = {
                        color: Colors.GrayDarkest,
                        display: 'block',
                        fontSize: '0.821em',
                        marginTop: 15,
                        marginBottom: 30,
                        letterSpacing: 1,
                        lineHeight: 1.75
                    },
                    _b[MediaBreakPointUp.SM] = {
                        fontSize: '0.9em',
                    },
                    _b),
                logo: {
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 25,
                    width: 180,
                },
                checkIcon: {
                    border: "1px solid ".concat(Colors.Primary),
                    fontSize: '1.8em',
                    padding: 10,
                    borderRadius: '50%',
                    color: Colors.Primary,
                },
            });
        },
        enumerable: false,
        configurable: true
    });
    return MagazineUnsubscribePage;
}(React.Component)));
var PageStatus;
(function (PageStatus) {
    PageStatus["Loading"] = "\u51E6\u7406\u4E2D";
    PageStatus["Failed"] = "\u8CFC\u8AAD\u89E3\u9664\u4E2D\u306B\u30A8\u30E9\u30FC\u304C\u767A\u751F\u3057\u307E\u3057\u305F\u3002";
    PageStatus["Success"] = "\u30E1\u30EB\u30DE\u30AC\u3092\u8CFC\u8AAD\u89E3\u9664\u3057\u307E\u3057\u305F\u3002\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u30FB\u30D1\u30B9\u30EF\u30FC\u30C9\u5909\u66F4\u306A\u3069\u306E\u91CD\u8981\u306A\u304A\u77E5\u3089\u305B\u306F\u5F15\u304D\u7D9A\u304D\u9001\u4FE1\u3055\u308C\u307E\u3059\u3002\u5F15\u304D\u7D9A\u304D\u3001LEAN BODY\u3092\u304A\u697D\u3057\u307F\u304F\u3060\u3055\u3044\u3002";
})(PageStatus || (PageStatus = {}));
var MagazineUnsubscribePageVM = /** @class */ (function () {
    function MagazineUnsubscribePageVM(token, apiClient, router) {
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "router", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "token", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pageStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: PageStatus.Loading
        });
        makeObservable(this, {
            pageStatus: observable,
        });
        this.token = token;
        this.apiClient = apiClient;
        this.router = router;
        this.sendToken();
    }
    Object.defineProperty(MagazineUnsubscribePageVM.prototype, "onClickHomeBtn", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.router.push(routes.TOP);
        }
    });
    Object.defineProperty(MagazineUnsubscribePageVM.prototype, "sendToken", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            this.pageStatus = PageStatus.Loading;
            this.apiClient.sendMagazineUnsubscribeToken({ token: this.token }).then(function () {
                _this.pageStatus = PageStatus.Success;
            }, function (err) {
                _this.pageStatus = PageStatus.Failed;
                console.error(err);
            });
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], MagazineUnsubscribePageVM.prototype, "onClickHomeBtn", null);
    return MagazineUnsubscribePageVM;
}());
export { MagazineUnsubscribePageVM };
