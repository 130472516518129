var _a, _b;
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MealNutrientTabs } from '../pages/nutrient_page';
import { GrayBoxTabs, TabTypes } from '../molecules/gray_box_tabs';
import { Colors, MediaBreakPointUp } from '../styles';
import { DatePagination, DatePaginationStep } from '../molecules/date_pagination';
import { MealScoreRow } from '../molecules/meal_score_row';
import { NutrientItems } from './nutrient_items';
import { PageLoading } from './page_loading';
import { ArrowIcon, ArrowDirection, Button, ButtonType, ArrowIconWithMargin } from '../atoms';
import { Link, useNavigate } from 'react-router-dom';
import { DailyTasksPageTaskName, MealSubtaskPageName, routes } from '@lean-body/src/route';
import { PlusIcon } from '../atoms/icons/plus_icon';
import { MealInputMenu } from '../molecules/meal_input_menu';
import { getFormattedToFormat, useQuery } from '@lean-body/src/util';
import dayjs from 'dayjs';
import { TASK_DATE_QUERY } from '@lean-body/src/domain';
export var MEAL_EMPTY_MESSAGE = '3食分入力すると\n評価されます';
export var MealTask = function (_a) {
    var apiClient = _a.apiClient, containerClassObject = _a.containerClassObject, headerClassObject = _a.headerClassObject, arrowIconClassObject = _a.arrowIconClassObject, homeDay = _a.homeDay, onClickBackHome = _a.onClickBackHome;
    var navigate = useNavigate();
    var query = useQuery();
    var today = new Date();
    var displayNutrientKeys = ['calorie', 'protein', 'lipid', 'carbohydrate', 'salt_amount'];
    var isFirstRender = useRef(true);
    var _b = useState(dayjs(query.get(TASK_DATE_QUERY) || today).toDate()), date = _b[0], setDate = _b[1];
    var _c = useState(), score = _c[0], setScore = _c[1];
    var _d = useState([]), nutrients = _d[0], setNutrients = _d[1];
    var _e = useState(false), isEntered = _e[0], setIsEntered = _e[1];
    var _f = React.useState(false), isModalOpen = _f[0], setIsModalOpen = _f[1];
    var _g = useState(MealNutrientTabs.all), tab = _g[0], setTab = _g[1];
    useEffect(function () {
        apiClient.fetchMealScores(date).then(function (fetchedScores) { return setScore(fetchedScores); });
        fetchMealNutrients();
    }, [date]);
    useEffect(function () {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        }
        else {
            fetchMealNutrients();
        }
    }, [tab]);
    var fetchMealNutrients = function () {
        var tabKey = Object.entries(MealNutrientTabs).find(function (_a) {
            var key = _a[0], value = _a[1];
            if (value === tab)
                return key;
        })[0];
        apiClient.fetchMealNutrients(date, tabKey).then(function (v) {
            setNutrients(v.nutrients.filter(function (v) { return displayNutrientKeys.includes(v.key); }));
            setIsEntered(v.isEntered);
        });
    };
    var onClickDatePaginationNext = function (value) {
        setDate(value);
        var taskName = DailyTasksPageTaskName.meal;
        navigate("".concat(routes.DAILY_TASKS.compile({ taskName: taskName }), "?date=").concat(getFormattedToFormat(value)));
    };
    var onClickDatePaginationPrev = function (value) {
        setDate(value);
        var taskName = DailyTasksPageTaskName.meal;
        navigate("".concat(routes.DAILY_TASKS.compile({ taskName: taskName }), "?date=").concat(getFormattedToFormat(value)));
    };
    var onClickScoreDetail = function (scoreKey) {
        navigate("".concat(routes.MEAL_SCORE.compile(), "?date=").concat(getFormattedToFormat(date), "&type=").concat(scoreKey));
    };
    var onClickInputModal = function () {
        setIsModalOpen(true);
    };
    var onClickBreakfast = function () {
        navigateDailySubtask(MealSubtaskPageName.breakfast);
    };
    var onClickLunch = function () {
        navigateDailySubtask(MealSubtaskPageName.lunch);
    };
    var onClickDinner = function () {
        navigateDailySubtask(MealSubtaskPageName.dinner);
    };
    var onClickSnacking = function () {
        navigateDailySubtask(MealSubtaskPageName.snacking);
    };
    var navigateDailySubtask = function (subtaskName) {
        var taskName = DailyTasksPageTaskName.meal;
        navigate("".concat(routes.DAILY_TASKS_SUBTASK.compile({ taskName: taskName, subtaskName: subtaskName }), "?date=").concat(getFormattedToFormat(date)));
    };
    var onClickCancel = function () {
        setIsModalOpen(false);
    };
    if (!score) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(containerClassObject) },
        React.createElement(ArrowIconWithMargin, { classObject: arrowIconClassObject, direction: ArrowDirection.left, color: Colors.TextDark, onClick: function () {
                onClickBackHome(homeDay);
            } }),
        React.createElement("div", { className: css(headerClassObject) }, "\u98DF\u4E8B\u8A18\u9332"),
        React.createElement(DatePagination, { value: date, max: today, step: DatePaginationStep.day, onClickNext: onClickDatePaginationNext, onClickPrev: onClickDatePaginationPrev }),
        React.createElement("div", { className: css(styles.sectionTitle) }, "\u98DF\u4E8B\u30B9\u30B3\u30A2"),
        React.createElement("div", { className: css(styles.score) },
            React.createElement("div", { className: css(styles.scoreBox) },
                !score.enteredThreeMeals && (React.createElement("div", { className: css(styles.emptyMessage, styles.basicScoreEmptyMessage) }, MEAL_EMPTY_MESSAGE)),
                React.createElement(MealScoreRow, { scoreDetail: score.basic, onClickDetail: onClickScoreDetail })),
            React.createElement("div", { className: css(styles.scoreBox) },
                !score.enteredThreeMeals && React.createElement("div", { className: css(styles.emptyMessage) }, MEAL_EMPTY_MESSAGE),
                React.createElement(MealScoreRow, { scoreDetail: score.diet, onClickDetail: onClickScoreDetail }),
                React.createElement(MealScoreRow, { scoreDetail: score.vitality, onClickDetail: onClickScoreDetail }),
                React.createElement(MealScoreRow, { scoreDetail: score.intestinalCleanliness, onClickDetail: onClickScoreDetail }),
                React.createElement(MealScoreRow, { scoreDetail: score.skinGlossiness, onClickDetail: onClickScoreDetail }))),
        React.createElement("div", { className: css(styles.sectionTitle) }, "\u6804\u990A\u30D0\u30E9\u30F3\u30B9"),
        React.createElement(GrayBoxTabs, { setTab: setTab, tab: tab, tabType: TabTypes.mealNutrient, classObject: styles.tabs }),
        React.createElement(NutrientItems, { nutrients: nutrients, isSnackingType: tab === MealNutrientTabs.snacking, isEntered: isEntered }),
        React.createElement("div", { className: css(styles.detailLink) },
            React.createElement(Link, { to: "".concat(routes.NUTRIENTS.compile(), "?date=").concat(getFormattedToFormat(date)), className: css(styles.link) },
                "\u8A73\u7D30\u3092\u898B\u308B",
                React.createElement(ArrowIcon, { direction: ArrowDirection.right, color: Colors.Primary }))),
        React.createElement(Button, { className: css(styles.inputButton), buttonType: ButtonType.secondary, isRadius: true, onClick: onClickInputModal },
            React.createElement(PlusIcon, { classObject: styles.plusIcon }),
            "\u98DF\u4E8B\u3092\u8A18\u9332\u3059\u308B"),
        isModalOpen && (React.createElement(MealInputMenu, { onClickBreakfast: onClickBreakfast, onClickLunch: onClickLunch, onClickDinner: onClickDinner, onClickSnacking: onClickSnacking, onClickCancel: onClickCancel }))));
};
var styles = StyleSheet.create({
    detailContainer: (_a = {
            marginTop: 0
        },
        _a[MediaBreakPointUp.MD] = {
            paddingTop: 0,
        },
        _a),
    tabs: (_b = {
            margin: '8px 0 24px'
        },
        _b[MediaBreakPointUp.XL] = {
            marginTop: 0,
        },
        _b),
    sectionTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 1.28,
        padding: '24px 0 8px',
    },
    scoreBox: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 20px',
        borderRadius: 14,
        border: "1.5px solid ".concat(Colors.BorderLightGray),
    },
    score: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    emptyMessage: {
        position: 'absolute',
        textAlign: 'center',
        top: 70,
        right: 13,
        height: 38,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
        whiteSpace: 'pre-wrap',
        pointerEvents: 'none',
    },
    basicScoreEmptyMessage: {
        top: 16,
        right: 36,
        width: 130,
        height: 34,
        lineHeight: '140%',
    },
    detailLink: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 0.48,
        marginTop: 14,
    },
    link: {
        color: Colors.Primary,
    },
    inputButton: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 11,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 0.88,
        width: 131,
        height: 33,
        margin: '24px auto 0',
        padding: '7px 13px 8px',
    },
    plusIcon: {
        width: 13,
        height: 13,
        marginRight: 6,
    },
});
