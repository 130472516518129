import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { PlaylistLandscapeImg } from './playlist_landscape_img';
var useState = React.useState, useEffect = React.useEffect;
export var HeroHeader = function (props) {
    var _a;
    var heroURL = props.heroURL, videoVisible = props.videoVisible, children = props.children, isPlaylist = props.isPlaylist;
    var _b = useState(window.innerWidth), width = _b[0], setWidth = _b[1];
    useEffect(function () {
        window.addEventListener('resize', function () {
            setWidth(window.innerWidth);
        });
    });
    var HeroHeight = {
        xs: (width * 270) / 480,
        md: (600 * 270) / 480,
    };
    var styles = StyleSheet.create({
        container: (_a = {
                position: 'relative',
                backgroundColor: Colors.StrictBlack,
                height: HeroHeight.xs
            },
            _a[MediaBreakPointUp.MD] = {
                height: HeroHeight.md,
            },
            _a),
        backgroundImg: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
        },
        thumbnail: {
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: "url(".concat(heroURL, ")"),
        },
    });
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.backgroundImg) }, !videoVisible && isPlaylist ? (React.createElement(PlaylistLandscapeImg, { thumbnailURL: heroURL })) : (React.createElement("div", { className: css(styles.thumbnail) }))),
        children));
};
