var _a, _b, _c;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { Link } from 'react-router-dom';
import { InitialGuideHowToDetail } from '../molecules';
import { InitialGuideQA } from '../molecules/initial_guide_qa';
var CourseResetLink = 'https://wondernuts.zendesk.com/hc/ja/articles/20039872512665--%E3%82%B3%E3%83%BC%E3%82%B9%E6%A9%9F%E8%83%BD-Day1%E3%81%8B%E3%82%89%E3%82%84%E3%82%8A%E7%9B%B4%E3%81%97%E3%81%9F%E3%81%84';
var CourseChangeLink = 'https://wondernuts.zendesk.com/hc/ja/articles/19282616853017--%E3%82%B3%E3%83%BC%E3%82%B9%E6%A9%9F%E8%83%BD%E3%82%92%E5%A4%89%E6%9B%B4%E3%81%97%E3%81%9F%E3%81%84';
export var InitialGuideCourseDetail = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u30B3\u30FC\u30B9\u3068\u306F\uFF1F"),
            React.createElement("div", { className: css(styles.sectionTitleBorder) }),
            React.createElement("div", { className: css(styles.topImgBox) },
                React.createElement("img", { className: css(styles.topImg), src: "/assets/images/initial_guide/course/course_top.png" })),
            React.createElement("div", { className: css(styles.description) },
                "\u3042\u306A\u305F\u306E\u304A\u60A9\u307F\u306B\u3042\u3063\u305F\u30B9\u30B1\u30B8\u30E5\u30FC\u30EB\u3092\u63D0\u6848\u3059\u308B\u6A5F\u80FD\u3067\u3059\u3002",
                React.createElement("br", null),
                "\u6700\u9069\u306A\u30B9\u30B1\u30B8\u30E5\u30FC\u30EB\u304C\u3044\u3064\u3082HOME\u306B\u30BB\u30C3\u30C8\u3055\u308C\u3066\u3044\u308B\u306E\u3067\u3001\u6BCE\u65E5\u306E\u30E1\u30CB\u30E5\u30FC\u306B\u60A9\u307E\u305A\u3001\u3059\u3050\u306B\u30EC\u30C3\u30B9\u30F3\u3092\u53D7\u3051\u3089\u308C\u307E\u3059\uFF01")),
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.howToSection) },
                React.createElement("div", { className: css(styles.sectionTitle) }, "\u30B3\u30FC\u30B9\u306E\u4F7F\u3044\u65B9"),
                React.createElement("div", { className: css(styles.sectionTitleBorder) }),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/course/take_course_lesson.png", imgClassObject: styles.takeCourseLessonImg, stepNumber: 1, title: "\u30EC\u30C3\u30B9\u30F3\u3092\u53D7\u8B1B" }, "\u30B9\u30B1\u30B8\u30E5\u30FC\u30EB\u901A\u308A\u306B\u53D7\u8B1B\u3059\u308B\u3060\u3051\uFF01"),
                React.createElement("div", { className: css(styles.triangleBox) },
                    React.createElement("div", { className: css(styles.triangle) })),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/course/get_medal.png", imgClassObject: styles.getMedalImg, stepNumber: 2, title: "\u30E1\u30C0\u30EB\u3092GET\uFF01" }, "1\u65E5\u5206\u306E\u30EC\u30C3\u30B9\u30F3\u3092\u7D42\u3048\u308B\u3068\u30E1\u30C0\u30EB\u304C\u624B\u306B\u5165\u308A\u307E\u3059"),
                React.createElement("div", { className: css(styles.triangleBox) },
                    React.createElement("div", { className: css(styles.triangle) })),
                React.createElement(InitialGuideHowToDetail, { imgSrc: "/assets/images/initial_guide/course/check_activity.png", imgClassObject: styles.checkActivityImg, stepNumber: 3, title: "\u8A18\u9332\u3092\u78BA\u8A8D" },
                    "\u8A18\u9332\u3067\u9811\u5F35\u308A\u304C\u898B\u3048\u308B\u304B\u3089\u30E2\u30C1\u30D9\u30FC\u30B7\u30E7\u30F3UP!",
                    React.createElement("br", null),
                    "\u6B21\u306E\u30B9\u30B1\u30B8\u30E5\u30FC\u30EB\u3082\u9811\u5F35\u308A\u307E\u3057\u3087\u3046"))),
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u3088\u304F\u3042\u308B\u8CEA\u554F"),
            React.createElement("div", { className: css(styles.sectionTitleBorder) }),
            React.createElement(InitialGuideQA, { question: "\u30B3\u30FC\u30B9\u3092\u3084\u308A\u76F4\u3059\u3053\u3068\u306F\u3067\u304D\u307E\u3059\u304B\uFF1F" },
                "\u30B3\u30FC\u30B9\u306FDay1\u304B\u3089\u3084\u308A\u76F4\u3059\u3053\u3068\u304C\u53EF\u80FD\u3067\u3059\u3002\u30EA\u30BB\u30C3\u30C8\u3059\u308B\u624B\u9806\u306F",
                React.createElement(Link, { to: CourseResetLink, className: css(styles.link), target: "_blank" }, "\u3053\u3061\u3089"),
                "\u3092\u3054\u89A7\u304F\u3060\u3055\u3044\u3002"),
            React.createElement(InitialGuideQA, { question: "\u30B3\u30FC\u30B9\u3092\u5909\u66F4\u3059\u308B\u3053\u3068\u306F\u3067\u304D\u307E\u3059\u304B\uFF1F" },
                "\u30B3\u30FC\u30B9\u306F\u5909\u66F4\u3059\u308B\u3053\u3068\u304C\u53EF\u80FD\u3067\u3059\u3002\u624B\u9806\u306F",
                React.createElement(Link, { to: CourseChangeLink, className: css(styles.link), target: "_blank" }, "\u3053\u3061\u3089"),
                "\u3092\u3054\u89A7\u304F\u3060\u3055\u3044\u3002"))));
};
var styles = StyleSheet.create({
    section: (_a = {
            marginBottom: 40,
            paddingTop: 30,
            paddingLeft: 24,
            paddingRight: 24
        },
        _a[MediaBreakPointUp.MD] = {
            marginBottom: 0,
            paddingLeft: 40,
            paddingRight: 40,
        },
        _a),
    sectionTitle: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: '1.44px',
        height: 25,
    },
    sectionTitleBorder: {
        border: "3px solid ".concat(Colors.PrimaryDark),
        borderRadius: 3,
        width: 60,
        margin: '12px auto 12px',
    },
    topImgBox: {
        textAlign: 'center',
        marginTop: 35,
        marginBottom: 35,
    },
    topImg: {
        height: 216,
    },
    description: (_b = {
            backgroundColor: Colors.CyanLightest,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '160%',
            letterSpacing: '0.56px',
            padding: 24,
            marginBottom: 40,
            borderRadius: 10
        },
        _b[MediaBreakPointUp.MD] = {
            marginBottom: 10,
        },
        _b),
    howToSection: (_c = {},
        _c[MediaBreakPointUp.MD] = {
            marginLeft: 60,
            marginRight: 60,
        },
        _c),
    triangleBox: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 12,
        paddingBottom: 12,
    },
    triangle: {
        borderStyle: 'solid',
        borderWidth: '20px 15px 0',
        borderColor: "".concat(Colors.BackgroundGrayD, " transparent transparent transparent"),
        width: 31,
    },
    takeCourseLessonImg: {
        height: 166,
    },
    getMedalImg: {
        height: 163,
    },
    checkActivityImg: {
        height: 209,
    },
    link: {
        color: Colors.PrimaryDark,
        fontSize: 14,
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
});
