import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var CourseHeaderThumbnail = function (_a) {
    var courseThumbnailSrc = _a.courseThumbnailSrc, classObject = _a.classObject, imgSize = _a.imgSize;
    var styles = StyleSheet.create({
        container: {},
        image: {
            maxHeight: imgSize,
        },
    });
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("img", { src: courseThumbnailSrc, alt: "course image", className: css(styles.image) })));
};
