import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { Select } from '@lean-body/components/atoms';
var useState = React.useState, useEffect = React.useEffect;
var TIME_SELECT_STEP_DEFAULT = 5;
export var TimeSelectFormat = {
    hmm: 'h:mm',
    hhmm: 'hh:mm',
};
export var TimeSelectForm = function (_a) {
    var containerClassObject = _a.containerClassObject, selectClassObject = _a.selectClassObject, listClassObject = _a.listClassObject, listItemClassObject = _a.listItemClassObject, currentValue = _a.currentValue, setCurrentValue = _a.setCurrentValue, label = _a.label, format = _a.format, step = _a.step, displayIcon = _a.displayIcon;
    var _b = useState(currentValue), time = _b[0], setTime = _b[1];
    useEffect(function () {
        setTime(currentValue);
    }, [currentValue]);
    var changeTime = function (event) {
        setTime(event.target.value);
        setCurrentValue(event.target.value);
    };
    var generateOptions = function () {
        var options = [{ value: '', name: '' }];
        var delta = step !== null && step !== void 0 ? step : TIME_SELECT_STEP_DEFAULT;
        var stepsInHour = 60 / delta;
        for (var i = 0; i < 24 * stepsInHour; i++) {
            var hour = Math.floor(i / stepsInHour);
            var minute = (i % stepsInHour) * delta;
            var hourString = format === TimeSelectFormat.hmm ? hour.toString() : hour.toString().padStart(2, '0');
            var minuteString = minute.toString().padStart(2, '0');
            var value = "".concat(hourString, ":").concat(minuteString);
            options.push({ value: value, name: value });
        }
        return options;
    };
    return (React.createElement("div", { className: css(containerClassObject) },
        label && React.createElement("div", { className: css(styles.label) }, label),
        React.createElement("div", { className: css(styles.selectContainer) },
            React.createElement(Select, { selectOptions: generateOptions(), defaultValue: time, containerClassObject: styles.select, selectClassObject: [styles.select, selectClassObject], listClassObject: listClassObject, listItemClassObject: listItemClassObject, onChange: changeTime, displayIcon: displayIcon !== null && displayIcon !== void 0 ? displayIcon : true, placeholder: "", placeholderValue: "" }))));
};
var styles = StyleSheet.create({
    selectContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    select: {
        width: '100%',
    },
    label: {
        fontSize: 14,
        color: Colors.GrayDarkest,
        marginBottom: 8,
    },
});
