var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
export var MealType = {
    all: 'all',
    breakfast: 'breakfast',
    lunch: 'lunch',
    dinner: 'dinner',
    snacking: 'snacking',
};
export var MealLabel = {
    all: '全て',
    breakfast: '朝食',
    lunch: '昼食',
    dinner: '夕食',
    snacking: '間食',
};
var MealMenu = /** @class */ (function () {
    function MealMenu(data) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "menuId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "menuName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "storeName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "imageUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "calorie", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "protein", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lipid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "carbohydrate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "availableCarbohydrate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "saltAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "calcium", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "iron", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "fiber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vitaminA", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vitaminB1", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vitaminB2", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vitaminB6", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vitaminB12", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vitaminC", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vitaminD", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vitaminE", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "n3FattyAcid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "n6FattyAcid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "saturatedFat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cholesterol", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "transFattyAcid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "kalium", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "magnesium", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "phosphorus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "zinc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "copper", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "selenium", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "molybdenum", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "folate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alcohol", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.menuId = data.menuId;
        this.menuName = data.menuName;
        this.storeName = data.storeName;
        this.description = data.description;
        this.imageUrl = data.imageUrl;
        this.amount = data.amount || 1;
        this.calorie = data.calorie;
        this.protein = data.protein;
        this.lipid = data.lipid;
        this.carbohydrate = data.carbohydrate;
        this.availableCarbohydrate = data.availableCarbohydrate;
        this.saltAmount = data.saltAmount;
        this.calcium = data.calcium;
        this.iron = data.iron;
        this.fiber = data.fiber;
        this.vitaminA = data.vitaminA;
        this.vitaminB1 = data.vitaminB1;
        this.vitaminB2 = data.vitaminB2;
        this.vitaminB6 = data.vitaminB6;
        this.vitaminB12 = data.vitaminB12;
        this.vitaminC = data.vitaminC;
        this.vitaminD = data.vitaminD;
        this.vitaminE = data.vitaminE;
        this.n3FattyAcid = data.n3FattyAcid;
        this.n6FattyAcid = data.n6FattyAcid;
        this.saturatedFat = data.saturatedFat;
        this.cholesterol = data.cholesterol;
        this.transFattyAcid = data.transFattyAcid;
        this.kalium = data.kalium;
        this.magnesium = data.magnesium;
        this.phosphorus = data.phosphorus;
        this.zinc = data.zinc;
        this.copper = data.copper;
        this.selenium = data.selenium;
        this.molybdenum = data.molybdenum;
        this.folate = data.folate;
        this.alcohol = data.alcohol;
    }
    Object.defineProperty(MealMenu.prototype, "toJSON", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                menu_id: this.menuId,
                menu_name: this.menuName,
                store_name: this.storeName,
                description: this.description,
                image_url: this.imageUrl,
                amount: this.amount,
                calorie: this.calorie,
                protein: this.protein,
                lipid: this.lipid,
                carbohydrate: this.carbohydrate,
                available_carbohydrate: this.availableCarbohydrate,
                salt_amount: this.saltAmount,
                calcium: this.calcium,
                iron: this.iron,
                fiber: this.fiber,
                vitamin_a: this.vitaminA,
                vitamin_b1: this.vitaminB1,
                vitamin_b2: this.vitaminB2,
                vitamin_b6: this.vitaminB6,
                vitamin_b12: this.vitaminB12,
                vitamin_c: this.vitaminC,
                vitamin_d: this.vitaminD,
                vitamin_e: this.vitaminE,
                n3_fatty_acid: this.n3FattyAcid,
                n6_fatty_acid: this.n6FattyAcid,
                saturated_fat: this.saturatedFat,
                cholesterol: this.cholesterol,
                trans_fatty_acid: this.transFattyAcid,
                kalium: this.kalium,
                magnesium: this.magnesium,
                phosphorus: this.phosphorus,
                zinc: this.zinc,
                copper: this.copper,
                selenium: this.selenium,
                molybdenum: this.molybdenum,
                folate: this.folate,
                alcohol: this.alcohol,
            };
        }
    });
    Object.defineProperty(MealMenu.prototype, "menuKeyForConfirmPage", {
        get: function () {
            return this.id.toString() || this.menuKey;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MealMenu.prototype, "menuKey", {
        get: function () {
            return this.menuId || this.menuName;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MealMenu.prototype, "amountPercentage", {
        get: function () {
            return this.amount * 100;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MealMenu.prototype, "nutrientSummary", {
        get: function () {
            return [
                { key: 'protein', name: 'たんぱく質', value: this.protein },
                { key: 'lipid', name: '脂質', value: this.lipid },
                { key: 'carbohydrate', name: '炭水化物', value: this.carbohydrate },
                { key: 'saltAmount', name: '塩分', value: this.saltAmount },
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MealMenu.prototype, "caloriesForAmount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (amountPercentage) {
            return (this.calorie * amountPercentage) / 100;
        }
    });
    return MealMenu;
}());
export { MealMenu };
var MealImage = /** @class */ (function () {
    function MealImage(data, config) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "src", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.src = data.src;
        this.config = config;
    }
    Object.defineProperty(MealImage.prototype, "imageSrc", {
        get: function () {
            return this.config.cdnUserBaseURL + '/' + this.src;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MealImage.prototype, "fileMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (i) {
            return __awaiter(this, void 0, void 0, function () {
                var response, blob;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetch(this.imageSrc, { mode: 'cors', credentials: 'include' })];
                        case 1:
                            response = _a.sent();
                            return [4 /*yield*/, response.blob()];
                        case 2:
                            blob = _a.sent();
                            return [2 /*return*/, { index: i, id: this.id, url: this.imageSrc, file: new File([blob], this.imageSrc, { type: 'image/jpg' }) }];
                    }
                });
            });
        }
    });
    return MealImage;
}());
export { MealImage };
var UserMeal = /** @class */ (function () {
    function UserMeal(data, config) {
        var _a, _b;
        Object.defineProperty(this, "mealType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "eatenAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isNotEating", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userMealMenus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userMealImages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.mealType = data.mealType;
        this.eatenAt = new Date(data.eatenAt);
        this.isNotEating = data.isNotEating;
        this.userMealMenus = ((_a = data.userMealMenus) === null || _a === void 0 ? void 0 : _a.map(function (mealMenuData) { return new MealMenu(mealMenuData); })) || [];
        this.userMealImages = ((_b = data.userMealImages) === null || _b === void 0 ? void 0 : _b.map(function (mealImageData) { return new MealImage(mealImageData, config); })) || [];
    }
    return UserMeal;
}());
export { UserMeal };
