import * as dayjs from 'dayjs';
var UserSeasonAggregate = /** @class */ (function () {
    function UserSeasonAggregate(data, config) {
        Object.defineProperty(this, "userId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playDuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "seasonStartAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "seasonEndAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userSeasonAggregateRank", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.userId = data.userId;
        this.playDuration = data.playDuration;
        this.seasonStartAt = data.seasonStartAt;
        this.seasonEndAt = data.seasonEndAt;
        this.userSeasonAggregateRank = new UserSeasonAggregateRank(data.userSeasonAggregateRank, config);
    }
    Object.defineProperty(UserSeasonAggregate.prototype, "playDurationMinute", {
        get: function () {
            return Math.floor(this.playDuration / 60);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserSeasonAggregate.prototype, "seasonStartAtDayjs", {
        get: function () {
            return dayjs.unix(this.seasonStartAt);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserSeasonAggregate.prototype, "seasonEndAtDayjs", {
        get: function () {
            return dayjs.unix(this.seasonEndAt);
        },
        enumerable: false,
        configurable: true
    });
    return UserSeasonAggregate;
}());
export { UserSeasonAggregate };
export var UserSeasonAggregateRankName = {
    bronze: 'BRONZE',
    silver: 'SILVER',
    gold: 'GOLD',
    ruby: 'RUBY',
    sapphire: 'SAPPHIRE',
    diamond: 'DIAMOND',
};
var UserSeasonAggregateRank = /** @class */ (function () {
    function UserSeasonAggregateRank(data, config) {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nameJp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playDurationThreshold", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "thumbnailSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nextUserSeasonAggregateRank", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.name = data.name;
        this.nameJp = data.nameJp;
        this.playDurationThreshold = data.playDurationThreshold;
        this.thumbnailSrc = config.cdnBaseURL + data.thumbnailSrc;
        if (data.nextUserSeasonAggregateRank) {
            this.nextUserSeasonAggregateRank = new UserSeasonAggregateRank(data.nextUserSeasonAggregateRank, config);
        }
    }
    Object.defineProperty(UserSeasonAggregateRank.prototype, "isBronze", {
        get: function () {
            return this.name === UserSeasonAggregateRankName.bronze;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserSeasonAggregateRank.prototype, "isSapphire", {
        get: function () {
            return this.name === UserSeasonAggregateRankName.sapphire;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserSeasonAggregateRank.prototype, "isDiamond", {
        get: function () {
            return this.name === UserSeasonAggregateRankName.diamond;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserSeasonAggregateRank.prototype, "playDurationThresholdMinute", {
        get: function () {
            return Math.floor(this.playDurationThreshold / 60);
        },
        enumerable: false,
        configurable: true
    });
    return UserSeasonAggregateRank;
}());
export { UserSeasonAggregateRank };
