import { Announcement, LiveLesson, Playlist, Program } from '.';
import { routes } from '../route';
export var DialogPageName = {
    activity: 'activity',
    friendInvitation: 'friendInvitation',
};
var Dialog = /** @class */ (function () {
    function Dialog(data, config) {
        Object.defineProperty(this, "title", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "thumbnailURL", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "startAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "endAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "program", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playlist", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "liveLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "announcement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "linkUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "buttonLabel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pageName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.title = data.title;
        this.description = data.description;
        this.thumbnailURL = config.cdnBaseURL + data.thumbnailSrc;
        this.startAt = data.startAt;
        this.endAt = data.endAt;
        this.program = data.program ? new Program(data.program, config) : null;
        this.playlist = data.playlist ? new Playlist(data.playlist, config) : null;
        this.liveLesson = data.liveLesson ? new LiveLesson(data.liveLesson, config) : null;
        this.announcement = data.announcement ? new Announcement(data.announcement, config) : null;
        this.linkUrl = data.linkUrl;
        this.buttonLabel = data.buttonLabel;
        this.pageName = DialogPageName[data.pageName];
    }
    Object.defineProperty(Dialog.prototype, "linkPath", {
        get: function () {
            if (this.pageName === DialogPageName.activity) {
                return routes.ACTIVITY.compile();
            }
            else if (this.pageName === DialogPageName.friendInvitation) {
                return routes.INVITATION.compile();
            }
            else if (this.program) {
                return routes.PROGRAM.compile({ slug: this.program.slug });
            }
            else if (this.playlist) {
                return routes.PLAYLIST.compile({ id: this.playlist.id.toString() });
            }
            else if (this.liveLesson) {
                return routes.LIVE_LESSON.compile({ id: this.liveLesson.id.toString() });
            }
            else if (this.announcement) {
                return routes.ANNOUNCEMENT_DETAIL.compile({ id: this.announcement.id.toString() });
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    return Dialog;
}());
export { Dialog };
