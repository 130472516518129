import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
export var ChallengeProgramSectionTitle = function (props) {
    return React.createElement("div", { className: css(styles.sectionTitle) }, props.title);
};
var styles = StyleSheet.create({
    sectionTitle: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: '1rem',
    },
});
