// レッスン・プログラムコンテンツのドメインサービス。レッスン・プログラム動画の取得・favなどを担う。
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LocalStorage } from '@lean-body/src/infra';
import { APISequences, } from '@lean-body/src/interfaces';
import { getCurrentUnixTimeInSeconds } from '@lean-body/src/util';
import { Equipment, LessonSession, Program, Trainer } from '.';
import { VideoSrc } from './video';
export var HISTORY_PAGE_INIT = 0;
export var HISTORY_LIMIT_PER_PAGE = 20;
export var FAVORITE_PAGE_INIT = 0;
export var FAVORITE_LIMIT_PER_PAGE = 20;
/*=============================================
= Service =
=============================================*/
var LessonService = /** @class */ (function () {
    function LessonService(apiClient, tracker, meRepo, config) {
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "meRepo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.apiClient = apiClient;
        this.tracker = tracker;
        this.meRepo = meRepo;
        this.config = config;
    }
    Object.defineProperty(LessonService.prototype, "startLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lesson, lessonParent, courseLessonID) {
            return __awaiter(this, void 0, void 0, function () {
                var params, isOriginalPlaylist, sessionData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = { lessonId: lesson.id, CourseDaysLessonId: courseLessonID };
                            if (lessonParent) {
                                isOriginalPlaylist = lessonParent.isOriginalPlaylist;
                                if (!isOriginalPlaylist) {
                                    params.programID = lessonParent.id;
                                }
                            }
                            return [4 /*yield*/, this.apiClient.startLessonSession(params)];
                        case 1:
                            sessionData = _a.sent();
                            this.tracker.trackStartLesson(lesson, lessonParent);
                            return [2 /*return*/, new LessonSessionContext(new LessonSession(sessionData, this.config), this.apiClient, this.meRepo, this.tracker)];
                    }
                });
            });
        }
    });
    return LessonService;
}());
export { LessonService };
/*=============================================
= DomainModels =
=============================================*/
var LessonSessionContext = /** @class */ (function () {
    function LessonSessionContext(session, apiClient, meRepo, tracker) {
        var _this = this;
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "meRepo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "session", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "videoPlayAnalyzer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "localStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.tracker = tracker;
        this.meRepo = meRepo;
        this.apiClient = apiClient;
        this.session = session;
        this.meRepo.get().then(function (user) {
            _this.userId = user.id;
        });
        this.localStorage = new LocalStorage();
    }
    Object.defineProperty(LessonSessionContext.prototype, "setVideoPlayAnalalyzor", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (analyzer) {
            this.videoPlayAnalyzer = analyzer;
        }
    });
    Object.defineProperty(LessonSessionContext.prototype, "videoPlayStats", {
        get: function () {
            if (this.videoPlayAnalyzer) {
                return this.videoPlayAnalyzer.stats;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LessonSessionContext.prototype, "lesson", {
        get: function () {
            return this.session.lesson;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LessonSessionContext.prototype, "program", {
        get: function () {
            return this.session.program;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LessonSessionContext.prototype, "susppend", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (time, lessonParent) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.tracker.trackSuspendLesson(this.userId, this.lesson, time, lessonParent);
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(LessonSessionContext.prototype, "sync", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var stats = this.videoPlayStats;
            this.apiClient.syncLessonSession({
                sessionId: this.session.id,
                dedupedPlayDuration: stats.dedupedPlayDuration,
                playDurationRatio: stats.playDurationRatio,
                playDuration: stats.playDuration,
            });
        }
    });
    // NOTE: lessonOrderIndex は isChallengeProgram が true の場合にのみサーバ側で使用されます
    Object.defineProperty(LessonSessionContext.prototype, "quit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (time, isAutoPlay, lessonOrderIndex, lessonParent, isChallengeProgram) {
            return __awaiter(this, void 0, void 0, function () {
                var stats, next, lessonSessionData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            stats = this.videoPlayStats;
                            if (!stats) return [3 /*break*/, 3];
                            this.tracker.trackQuitLesson(this.lesson, time, stats, this.userId, lessonParent, isAutoPlay);
                            return [4 /*yield*/, this.apiClient.appendToSequence(APISequences.LESSON_FINISH_AND_PATCH_PROFILE)];
                        case 1:
                            next = _a.sent();
                            return [4 /*yield*/, this.apiClient.finishLessonSession({
                                    sessionId: this.session.id,
                                    completed: false,
                                    dedupedPlayDuration: stats.dedupedPlayDuration,
                                    playDurationRatio: stats.playDurationRatio,
                                    playDuration: stats.playDuration,
                                    lessonOrderIndex: lessonOrderIndex || 0,
                                    isBelongToProgram: !!isChallengeProgram,
                                })];
                        case 2:
                            lessonSessionData = _a.sent();
                            next();
                            if (lessonSessionData.userSeasonAggregate) {
                                this.localStorage.saveUserSeasonAggregateRank(lessonSessionData.userSeasonAggregate);
                            }
                            return [2 /*return*/, lessonSessionData];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
    });
    // NOTE: lessonOrderIndex は isChallengeProgram が true の場合にのみサーバ側で使用されます
    Object.defineProperty(LessonSessionContext.prototype, "complete", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isAutoPlay, lessonOrderIndex, isChallengeProgram, lessonParent) {
            return __awaiter(this, void 0, void 0, function () {
                var stats, lessonSessionData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            stats = this.videoPlayStats;
                            if (!stats) return [3 /*break*/, 2];
                            this.tracker.trackCompleteLesson(this.lesson, stats, this.userId, lessonParent, isAutoPlay);
                            return [4 /*yield*/, this.apiClient.finishLessonSession({
                                    sessionId: this.session.id,
                                    completed: true,
                                    dedupedPlayDuration: stats.dedupedPlayDuration,
                                    playDurationRatio: stats.playDurationRatio,
                                    playDuration: stats.playDuration,
                                    lessonOrderIndex: lessonOrderIndex || 0,
                                    isBelongToProgram: !!isChallengeProgram,
                                })
                                // バッジ取得情報をBigQueryに送信
                            ];
                        case 1:
                            lessonSessionData = _a.sent();
                            // バッジ取得情報をBigQueryに送信
                            lessonSessionData.badges.forEach(function (badge) {
                                _this.tracker.trackReceivedBadge(badge.id, badge.name, badge.type);
                            });
                            if (lessonSessionData.userSeasonAggregate) {
                                this.localStorage.saveUserSeasonAggregateRank(lessonSessionData.userSeasonAggregate);
                            }
                            return [2 /*return*/, lessonSessionData];
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
    });
    return LessonSessionContext;
}());
export { LessonSessionContext };
export var StyleID;
(function (StyleID) {
    StyleID[StyleID["LifeStyle"] = 9] = "LifeStyle";
})(StyleID || (StyleID = {}));
/*=============================================
= Entities =
=============================================*/
var Lesson = /** @class */ (function () {
    function Lesson(lessonData, config) {
        Object.defineProperty(this, "minCal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "maxCal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "video", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "difficulty", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "duration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "equipments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "heroSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pace", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "programs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "programsLessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "slug", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "style", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "thumbnailSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "trainers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "videoSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "publishAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isComingSoon", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isNew", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isDayOff", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isCompleted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "tags", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "lessonAggregate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isChecked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isFavorite", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isSkippableCourseDay", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "courseLessonID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = lessonData.id;
        this.description = lessonData.description;
        this.difficulty = lessonData.difficulty;
        this.duration = lessonData.duration;
        this.equipments = (lessonData.equipments || []).map(function (d) {
            return new Equipment(d, config);
        });
        this.heroSrc = lessonData.heroSrc;
        this.name = lessonData.name;
        this.pace = lessonData.pace;
        this.minCal = lessonData.minCal;
        this.maxCal = lessonData.maxCal;
        this.programs = lessonData.programs && lessonData.programs.map(function (v) { return new Program(v, config); });
        this.programs &&
            this.programs.forEach(function (program) {
                program.lessons &&
                    program.lessons.forEach(function (lesson) {
                        lesson.video = new VideoSrc(lesson.videoSrc, config);
                    });
            });
        this.programsLessons = lessonData.programsLessons;
        this.slug = lessonData.slug;
        this.style = lessonData.style;
        this.thumbnailSrc = lessonData.thumbnailSrc;
        this.trainers = lessonData.trainers && lessonData.trainers.map(function (v) { return new Trainer(v, config); });
        this.videoSrc = lessonData.videoSrc;
        this.video = new VideoSrc(lessonData.videoSrc, config);
        this.publishAt = lessonData.publishAt;
        this.orderIndex = lessonData.orderIndex;
        this.isDayOff = lessonData.isDayOff;
        this.isCompleted = lessonData.isCompleted;
        this.courseLessonID = lessonData.courseLessonId;
        this.tags = lessonData.tags;
        this.lessonAggregate = lessonData.lessonAggregate;
        this.createdAt = new Date(lessonData.createdAt);
        var currentUnixTime = getCurrentUnixTimeInSeconds();
        this.isComingSoon = currentUnixTime < this.publishAt;
        this.isChecked = false;
        this.isFavorite = lessonData.isFavorited;
        this.isSkippableCourseDay = lessonData.isSkippableCourseDay;
        this.config = config;
    }
    Object.defineProperty(Lesson.prototype, "videoId", {
        get: function () {
            return this.video.id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Lesson.prototype, "thumbnailURL", {
        get: function () {
            return this.config.cdnBaseURL + this.thumbnailSrc;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Lesson.prototype, "heroURL", {
        get: function () {
            return this.config.cdnBaseURL + this.heroSrc;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Lesson.prototype, "tagNameList", {
        get: function () {
            return (this.tags || []).map(function (o) { return "#".concat(o.name); });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Lesson.prototype, "hasPrograms", {
        get: function () {
            return this.programs.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Lesson.prototype, "isFirstLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.orderIndex === 1;
        }
    });
    Object.defineProperty(Lesson.prototype, "setIsChecked", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isChecked) {
            this.isChecked = isChecked;
        }
    });
    return Lesson;
}());
export { Lesson };
