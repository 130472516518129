import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { CircleProgress } from '../atoms/circle_progress';
export var CollectionProgresses = function (props) {
    var onClickBadgeProgress = props.onClickBadgeProgress, onClickCertificateProgress = props.onClickCertificateProgress, badges = props.badges, certificates = props.certificates;
    var badgeMaxNum = badges.length;
    var badgeCurrentNum = badges.filter(function (badge) { return badge.isReceived; }).length;
    var certificateMaxNum = certificates.length;
    var certificateCurrentNum = certificates.filter(function (badge) { return badge.isReceived; }).length;
    return (React.createElement("div", { className: css(styles.containerRow) },
        React.createElement("div", { className: css(styles.container) },
            React.createElement("span", { className: css(styles.titleText) }, "\u30D0\u30C3\u30B8"),
            React.createElement("div", { className: css(styles.circleWrapper) },
                React.createElement(CircleProgress, { progressMax: badgeMaxNum, progressValue: badgeCurrentNum, color: Colors.Accent, onClick: onClickBadgeProgress }))),
        React.createElement("div", { className: css(styles.container) },
            React.createElement("span", { className: css(styles.titleText) }, "\u4FEE\u4E86\u8A3C"),
            React.createElement("div", { className: css(styles.circleWrapper) },
                React.createElement(CircleProgress, { progressMax: certificateMaxNum, progressValue: certificateCurrentNum, color: Colors.Yellow, onClick: onClickCertificateProgress })))));
};
var styles = StyleSheet.create({
    containerRow: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 8,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderRadius: 10,
        backgroundColor: Colors.White,
        padding: '24px 20px 20px',
        width: '100%',
        border: "1px solid ".concat(Colors.BorderLightGray),
    },
    titleText: {
        size: 12,
        fontWeight: 'bold',
        color: Colors.GrayDarkest,
        textAlign: 'center',
        marginBottom: 8,
    },
    circleWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
});
