var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import bind from 'bind-decorator';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { VideoVM, ReceivedBadgeOrUserSeasonRankDialog, TwitterFormDialog, Video } from '@lean-body/components/molecules';
import { FIXED_FOOTER_HEIGHT, PageLoading } from '@lean-body/components/organisms';
import { MediaBreakPointUp, Sizes } from '@lean-body/components/styles';
import { FavoriteStatus } from '@lean-body/src/interfaces';
import { useNavigate } from 'react-router';
import { HeroHeader } from '../atoms';
import { ProgramMeta } from '../molecules/program_meta';
import { useXlMediaQuery } from '@lean-body/src/util';
import { VIDEO_WIDTH_OVER_MD } from './main_lesson';
import { LessonPlaylistBox, SHADOW_WIDTH } from '../molecules/lesson_playlist_box';
var PROGRAM_META_ORDER_INDEX = 0; // 先頭を現在のindexとする
export var ProgramPage = observer(function (_a) {
    var vm = _a.vm;
    var isXL = useXlMediaQuery();
    var firstLesson = vm.lessons[PROGRAM_META_ORDER_INDEX];
    if (!vm.isFirstViewLoaded) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(styles.program) },
        React.createElement("div", { className: css(styles.pageTop) },
            React.createElement("div", { className: css(styles.header) },
                React.createElement(HeroHeader, { heroURL: vm.program.thumbnailURL, videoVisible: false }, vm.showVideo && React.createElement(Video, { vm: vm.videoVM, src: vm.srcUrl, playButtonVisible: vm.videoVM.playButtonVisible }))),
            React.createElement(ProgramMeta, { program: vm.program, currentLessonIndex: PROGRAM_META_ORDER_INDEX, lesson: firstLesson, programLessons: vm.lessons, isFavorite: vm.isFavorited, totalFavoriteCount: vm.totalFavoriteCount, onClickFav: vm.onClickFavProgram, twitterService: vm.twitterService, onClickTwitterShareIfAuthed: vm.onClickTwitterShareIfAuthed, addFavoriteLesson: vm.apiClient.addFavoriteLesson, unFavoriteLesson: vm.apiClient.unFavoriteLesson, displayLessonPlaylistBox: !isXL, onClickProgramPlayButton: vm.onClickProgramPlayButton })),
        isXL && (React.createElement(LessonPlaylistBox, { program: vm.program, currentLessonIndex: PROGRAM_META_ORDER_INDEX, programLessons: vm.lessons, addFavoriteLesson: vm.apiClient.addFavoriteLesson, unFavoriteLesson: vm.apiClient.unFavoriteLesson, isShowOtherLessons: true, title: "\u30EC\u30C3\u30B9\u30F3 (".concat(vm.lessons.length, ")") })),
        vm.isReceivedBadge && (React.createElement(ReceivedBadgeOrUserSeasonRankDialog, { badges: vm.badges, onClickCancel: vm.onCloseReceivedBadgeOrUserSeasonRankDialog, updateDisplayedUserBadges: vm.apiClient.updateDisplayedUserBadges, twitterService: vm.twitterService, onClickTwitterShareIfAuthed: vm.onClickTwitterShareIfAuthed })),
        vm.isShowTwitterFormDialog && (React.createElement(TwitterFormDialog, { imageURL: vm.twitterService.tweetImgSrc, centerImageWidth: vm.twitterService.tweetCenterImageWidth, defaultText: vm.twitterService.tweetDefaultText, onClickCancel: vm.onClickTwitterPostCancel, submit: vm.onClickTwitterPost }))));
});
var ProgramPageViewModel = /** @class */ (function () {
    function ProgramPageViewModel(slug, lessonService, signedCookieServie, tracker, apiClient, router, twitterService) {
        Object.defineProperty(this, "router", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "navigate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "slug", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "signedCookieServie", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // 表示しているチャレンジプログラム
        Object.defineProperty(this, "program", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        // 初期再生動画のURL
        Object.defineProperty(this, "srcUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // 動画プレイヤー
        Object.defineProperty(this, "videoVM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        // シリーズ動画のレッスンリスト
        Object.defineProperty(this, "lessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        // お気に入りしたか制御する
        Object.defineProperty(this, "isFavorited", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isFirstViewLoaded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        // 未表示のバッジ
        Object.defineProperty(this, "badges", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        // Videoコンポーネントのマウント/アンマウントを制御し初期処理を行うためのフラグ
        Object.defineProperty(this, "showVideo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        Object.defineProperty(this, "favoriteRequesting", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "twitterService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isShowTwitterFormDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "totalFavoriteCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            videoVM: observable.ref,
            lessons: observable.ref,
            program: observable,
            isFavorited: observable,
            isFirstViewLoaded: observable,
            totalFavoriteCount: observable.ref,
            badges: observable,
            showVideo: observable,
            isShowTwitterFormDialog: observable,
        });
        this.slug = slug;
        this.lessonService = lessonService;
        this.signedCookieServie = signedCookieServie;
        this.tracker = tracker;
        this.router = router;
        this.navigate = useNavigate();
        this.apiClient = apiClient;
        this.twitterService = twitterService;
        this.initialize();
    }
    /*---------- 初期処理  ----------*/
    // シリーズ動画ページの初期化
    Object.defineProperty(ProgramPageViewModel.prototype, "initialize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.load()];
                        case 1:
                            _a.sent();
                            if (this.twitterService.showFormDialog) {
                                this.isShowTwitterFormDialog = true;
                                this.twitterService.showFormDialog = false;
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // シリーズ動画ページの表示に必要なデータの取得
    Object.defineProperty(ProgramPageViewModel.prototype, "load", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, badges;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = this;
                            return [4 /*yield*/, this.apiClient.fetchProgramWithSlug(this.slug)
                                // 動画プレイヤーの初期化
                            ];
                        case 1:
                            _a.program = _b.sent();
                            // 動画プレイヤーの初期化
                            this.videoVM = new VideoVM(this.router, this.navigate, this.apiClient, this.lessonService, this.signedCookieServie, false, this.reload);
                            // ファーストビューに必要なコンテンツの初期処理が完了したタイミングでページをレンダリングする
                            this.isFirstViewLoaded = true;
                            return [4 /*yield*/, Promise.all([this.apiClient.fetchUndisplayedBadges()])];
                        case 2:
                            badges = (_b.sent())[0];
                            this.srcUrl = this.program.lessons[0].video.hlsURL;
                            this.lessons = this.program.lessons;
                            this.isFavorited = this.program.isFavorited;
                            this.totalFavoriteCount = this.program.programAggregate.totalFavoriteCount;
                            // ユーザが獲得した未表示のバッジを取得
                            this.badges = badges;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(ProgramPageViewModel.prototype, "isReceivedBadge", {
        get: function () {
            return this.badges.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    /*---------- イベントハンドラー ----------*/
    // 紹介動画を見るボタンのクリックイベントをハンドリング
    Object.defineProperty(ProgramPageViewModel.prototype, "onClickProgramPlayButton", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!this.program.videoSrc)
                                return [2 /*return*/];
                            return [4 /*yield*/, this.videoVM.playProgramIntroduceVideo(this.program)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(ProgramPageViewModel.prototype, "onClickFavProgram", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (this.favoriteRequesting)
                                return [2 /*return*/];
                            this.favoriteRequesting = true;
                            if (!this.isFavorited) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.apiClient.unFavoriteProgram(this.program.id)];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, this.apiClient.addFavoriteProgram(this.program.id)];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4:
                            this.isFavorited = !this.isFavorited;
                            if (this.isFavorited) {
                                this.totalFavoriteCount++;
                            }
                            else {
                                this.totalFavoriteCount--;
                            }
                            this.favoriteRequesting = false;
                            this.tracker.trackFavoriteProgram(this.program.id, this.isFavorited ? FavoriteStatus.Add : FavoriteStatus.Remove);
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // バッジの取得情報をリセットする
    Object.defineProperty(ProgramPageViewModel.prototype, "onCloseReceivedBadgeOrUserSeasonRankDialog", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.badges = [];
        }
    });
    // ページの表示データを再取得
    Object.defineProperty(ProgramPageViewModel.prototype, "reload", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // ビデオプレーヤーの状態を完全に破棄する
                            this.showVideo = false;
                            this.videoVM = new VideoVM(this.router, this.navigate, this.apiClient, this.lessonService, this.signedCookieServie, false, this.reload);
                            return [4 /*yield*/, this.load()];
                        case 1:
                            _a.sent();
                            this.showVideo = true;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(ProgramPageViewModel.prototype, "onClickTwitterShareIfAuthed", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.isShowTwitterFormDialog = true;
        }
    });
    Object.defineProperty(ProgramPageViewModel.prototype, "onClickTwitterPost", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (text, file) {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    this.twitterService.postTweet(text, file).finally(function () {
                        _this.isShowTwitterFormDialog = false;
                    });
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(ProgramPageViewModel.prototype, "onClickTwitterPostCancel", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.isShowTwitterFormDialog = false;
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], ProgramPageViewModel.prototype, "onClickProgramPlayButton", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], ProgramPageViewModel.prototype, "onClickFavProgram", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ProgramPageViewModel.prototype, "onCloseReceivedBadgeOrUserSeasonRankDialog", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], ProgramPageViewModel.prototype, "reload", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ProgramPageViewModel.prototype, "onClickTwitterShareIfAuthed", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, File]),
        __metadata("design:returntype", Promise)
    ], ProgramPageViewModel.prototype, "onClickTwitterPost", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ProgramPageViewModel.prototype, "onClickTwitterPostCancel", null);
    return ProgramPageViewModel;
}());
export { ProgramPageViewModel };
var styles = StyleSheet.create({
    program: (_a = {
            height: "calc(100vh - ".concat(FIXED_FOOTER_HEIGHT, "px - ").concat(Sizes.MobileHeaderHeight, "px)"),
            margin: '0 auto'
        },
        _a[MediaBreakPointUp.MD] = {
            maxWidth: VIDEO_WIDTH_OVER_MD + SHADOW_WIDTH * 2,
            minHeight: '100vh',
        },
        _a[MediaBreakPointUp.XL] = {
            height: 'unset',
            maxWidth: 1024,
            display: 'flex',
            marginTop: 32,
            paddingBottom: 120,
        },
        _a),
    pageTop: (_b = {
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
        },
        _b[MediaBreakPointUp.XL] = {
            maxWidth: VIDEO_WIDTH_OVER_MD,
            marginRight: 32,
        },
        _b),
    header: (_c = {},
        _c[MediaBreakPointUp.MD] = {
            padding: "0 ".concat(SHADOW_WIDTH, "px"),
        },
        _c[MediaBreakPointUp.XL] = {
            padding: 0,
        },
        _c),
});
