import * as React from 'react';
export var TwitterCallback = function (_a) {
    var vm = _a.vm;
    React.useEffect(function () {
        vm.twitterService.onTwitterAuthCallback();
    }, []);
    return React.createElement(React.Fragment, null);
};
var TwitterCallbackViewModel = /** @class */ (function () {
    function TwitterCallbackViewModel(twitterService) {
        Object.defineProperty(this, "twitterService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.twitterService = twitterService;
    }
    return TwitterCallbackViewModel;
}());
export { TwitterCallbackViewModel };
