import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '../../styles/variables';
export var PlusIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 16,
            height: 16,
        },
    });
    var color = props.color || Colors.Primary;
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 16 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: color, fillRule: "evenodd", clipRule: "evenodd", d: "M0.21365 7.95868L0.21365 9.03446C0.21365 9.26424 0.401649 9.45224 0.636649 9.45746L7.03911 9.45746L7.03912 15.7659C7.04434 16.0531 7.27411 16.2829 7.55611 16.2829L8.4439 16.2829C8.73112 16.2881 8.9609 16.0583 8.9609 15.7659L8.9609 9.45746L15.3634 9.45746C15.5931 9.45746 15.7863 9.26424 15.7864 9.03446L15.7863 7.95868C15.7863 7.7289 15.5931 7.53568 15.3634 7.53568L8.9609 7.53568L8.9609 1.21673C8.95568 0.929512 8.72589 0.699723 8.44389 0.699723L7.55611 0.699723C7.26888 0.704945 7.03911 0.934719 7.03911 1.21672L7.03911 7.53568L0.636649 7.53568C0.406871 7.53568 0.213651 7.7289 0.21365 7.95868Z" })));
};
