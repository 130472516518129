import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '../../styles/variables';
export var ShadowHeartIcon = function (_a) {
    var filled = _a.filled, classObject = _a.classObject, color = _a.color, onClick = _a.onClick;
    var styles = StyleSheet.create({
        container: {
            width: 30,
            height: 28,
            position: 'relative',
            display: 'block',
        },
        icon: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
        },
    });
    var iconColor = color || (filled ? Colors.Accent : Colors.GrayDarkest);
    return (React.createElement("span", { className: css(styles.container, classObject), onClick: onClick }, filled ? (React.createElement("svg", { className: css(styles.icon), width: "30", height: "28", viewBox: "0 0 30 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { filter: "url(#filter0_d_15809_3476)" },
            React.createElement("path", { fill: iconColor, d: "M16.6871 7.75873L15.0014 9.44446L13.3157 7.75873C11.6448 6.08785 8.93425 6.08785 7.25594 7.75873C5.57764 9.42961 5.58507 12.1401 7.25594 13.8184L8.94168 15.5042L15.0014 21.5639L21.0611 15.5042L22.7468 13.8184C24.4177 12.1476 24.4177 9.43703 22.7468 7.75873C21.076 6.08042 18.358 6.08042 16.6871 7.75873Z" }),
            React.createElement("path", { fill: "url(#paint0_linear_15809_3476)", d: "M16.6871 7.75873L15.0014 9.44446L13.3157 7.75873C11.6448 6.08785 8.93425 6.08785 7.25594 7.75873C5.57764 9.42961 5.58507 12.1401 7.25594 13.8184L8.94168 15.5042L15.0014 21.5639L21.0611 15.5042L22.7468 13.8184C24.4177 12.1476 24.4177 9.43703 22.7468 7.75873C21.076 6.08042 18.358 6.08042 16.6871 7.75873Z" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_15809_3476", x: "0", y: "0.5", width: "30", height: "27.0639", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" },
                React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", null),
                React.createElement("feGaussianBlur", { stdDeviation: "3" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.231373 0 0 0 0 0.262745 0 0 0 0 0.294118 0 0 0 0.35 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_15809_3476" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_15809_3476", result: "shape" })),
            React.createElement("linearGradient", { id: "paint0_linear_15809_3476", x1: "15", y1: "6.5", x2: "15", y2: "21.5639", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: iconColor }),
                React.createElement("stop", { offset: "1", stopColor: iconColor, stopOpacity: "0" }))))) : (React.createElement("svg", { className: css(styles.icon), width: "32", height: "30", viewBox: "0 0 32 30", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { filter: "url(#filter0_d_15809_3510)" },
            React.createElement("path", { fill: iconColor, fillRule: "evenodd", clipRule: "evenodd", d: "M16.9791 8.05252C19.0409 5.98227 22.394 5.98249 24.4555 8.05319C26.514 10.1208 26.5162 13.4633 24.4539 15.5256L16.7085 23.271C16.521 23.4585 16.2666 23.5639 16.0014 23.5639C15.7362 23.5639 15.4818 23.4585 15.2943 23.271L7.54884 15.5256L7.54727 15.524C5.49082 13.4584 5.47663 10.1147 7.55041 8.05006C9.61801 5.9916 12.9605 5.98936 15.0228 8.05162L16.0014 9.03025L16.9791 8.05252C16.9789 8.05274 16.9793 8.05229 16.9791 8.05252ZM23.0382 9.46427C21.7582 8.17858 19.6758 8.17858 18.3958 9.46427L18.3942 9.46584L16.7085 11.1516C16.318 11.5421 15.6848 11.5421 15.2943 11.1516L13.6086 9.46584C12.3291 8.18634 10.2505 8.1841 8.96148 9.4674C7.67891 10.7443 7.67932 12.8211 8.96384 14.1121C8.9641 14.1124 8.96436 14.1127 8.96462 14.1129L16.0014 21.1497L23.0397 14.1113C24.3192 12.8318 24.3215 10.7533 23.0382 9.46427Z" }),
            React.createElement("path", { fill: "url(#paint0_linear_15809_3510)", fillRule: "evenodd", clipRule: "evenodd", d: "M16.9791 8.05252C19.0409 5.98227 22.394 5.98249 24.4555 8.05319C26.514 10.1208 26.5162 13.4633 24.4539 15.5256L16.7085 23.271C16.521 23.4585 16.2666 23.5639 16.0014 23.5639C15.7362 23.5639 15.4818 23.4585 15.2943 23.271L7.54884 15.5256L7.54727 15.524C5.49082 13.4584 5.47663 10.1147 7.55041 8.05006C9.61801 5.9916 12.9605 5.98936 15.0228 8.05162L16.0014 9.03025L16.9791 8.05252C16.9789 8.05274 16.9793 8.05229 16.9791 8.05252ZM23.0382 9.46427C21.7582 8.17858 19.6758 8.17858 18.3958 9.46427L18.3942 9.46584L16.7085 11.1516C16.318 11.5421 15.6848 11.5421 15.2943 11.1516L13.6086 9.46584C12.3291 8.18634 10.2505 8.1841 8.96148 9.4674C7.67891 10.7443 7.67932 12.8211 8.96384 14.1121C8.9641 14.1124 8.96436 14.1127 8.96462 14.1129L16.0014 21.1497L23.0397 14.1113C24.3192 12.8318 24.3215 10.7533 23.0382 9.46427Z" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_15809_3510", x: "0", y: "0.5", width: "32", height: "29.0639", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" },
                React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", null),
                React.createElement("feGaussianBlur", { stdDeviation: "3" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.231373 0 0 0 0 0.262745 0 0 0 0 0.294118 0 0 0 0.35 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_15809_3510" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_15809_3510", result: "shape" })),
            React.createElement("linearGradient", { id: "paint0_linear_15809_3510", x1: "16", y1: "6.5", x2: "16", y2: "23.5639", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: iconColor }),
                React.createElement("stop", { offset: "1", stopColor: iconColor, stopOpacity: "0" })))))));
};
