import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { LessonComponent } from './lesson_component';
import { ProgramComponent } from './program_component';
import { SelectOption } from '../organisms';
import { PlaylistComponentLandscapeImg } from '.';
export var SearchResultAll = function (_a) {
    var classObject = _a.classObject, searchResults = _a.searchResults, page = _a.page, sortBy = _a.sortBy, isVertical = _a.isVertical, addFavoriteLesson = _a.addFavoriteLesson, unFavoriteLesson = _a.unFavoriteLesson, addFavoriteProgram = _a.addFavoriteProgram, unFavoriteProgram = _a.unFavoriteProgram, onClickLesson = _a.onClickLesson, onClickProgram = _a.onClickProgram, onClickPlaylist = _a.onClickPlaylist;
    var _b = React.useState([]), sortedResults = _b[0], setSortedResults = _b[1];
    var vm = new SearchResultAllVM();
    React.useEffect(function () {
        var results = vm.parseToSortedResultsObject(searchResults[page - 1]);
        vm.sortResults(results, sortBy);
        setSortedResults(sortedResults.concat(results));
    }, [searchResults]);
    return (React.createElement(React.Fragment, null, sortedResults.map(function (v, key) { return (React.createElement("div", { className: css(styles.container), key: key }, v.lesson ? (React.createElement(LessonComponent, { lesson: v.lesson, addFavoriteLesson: addFavoriteLesson, unFavoriteLesson: unFavoriteLesson, isVertical: isVertical, onClick: onClickLesson })) : v.program ? (React.createElement(ProgramComponent, { program: v.program, addFavoriteProgram: addFavoriteProgram, unFavoriteProgram: unFavoriteProgram, isVertical: isVertical, onClick: onClickProgram })) : (!!v.playlist && (React.createElement(PlaylistComponentLandscapeImg, { playlist: v.playlist, addFavoriteProgram: addFavoriteProgram, unFavoriteProgram: unFavoriteProgram, isVertical: isVertical, onClick: onClickPlaylist }))))); })));
};
var SearchResultAllVM = /** @class */ (function () {
    function SearchResultAllVM() {
    }
    Object.defineProperty(SearchResultAllVM.prototype, "parseToSortedResultsObject", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (searchResult) {
            var results = [];
            searchResult.lessons.forEach(function (v) {
                results.push({
                    lesson: v,
                    favoriteCount: v.lessonAggregate.totalFavoriteCount,
                    difficulty: v.difficulty,
                    duration: v.duration,
                    createdAt: v.createdAt,
                });
            });
            searchResult.programs.forEach(function (v) {
                results.push({
                    program: v,
                    favoriteCount: v.programAggregate.totalFavoriteCount,
                    difficulty: v.difficulty,
                    duration: v.lessonLengthAvg,
                    createdAt: v.createdAt,
                });
            });
            searchResult.playlists.forEach(function (v) {
                results.push({
                    playlist: v,
                    favoriteCount: v.playlistAggregate.totalFavoriteCount,
                    difficulty: v.difficulty,
                    duration: v.lessonLengthAvg,
                    createdAt: v.createdAt,
                });
            });
            return results;
        }
    });
    Object.defineProperty(SearchResultAllVM.prototype, "sortResults", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (results, sortBy) {
            results.sort(function (a, b) {
                switch (sortBy) {
                    case SelectOption.Favorite:
                        return a.favoriteCount < b.favoriteCount ? 1 : -1;
                    case SelectOption.Easy:
                        return a.difficulty.degree > b.difficulty.degree ? 1 : -1;
                    case SelectOption.Difficult:
                        return a.difficulty.degree < b.difficulty.degree ? 1 : -1;
                    case SelectOption.LengthLonger:
                        return a.duration < b.duration ? 1 : -1;
                    case SelectOption.LengthShorter:
                        return a.duration > b.duration ? 1 : -1;
                    case SelectOption.NewArrival:
                        return a.createdAt < b.createdAt ? 1 : -1;
                }
            });
        }
    });
    return SearchResultAllVM;
}());
var styles = StyleSheet.create({
    container: {
        width: '100%',
    },
});
