var _a, _b, _c;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Button, ButtonType } from '../atoms';
import { useSmMediaQuery } from '@lean-body/src/util';
import { StopIcon } from '../atoms/icons/stop_icon';
export var PlaylistStopChallengeDialog = function (_a) {
    var isChallenging = _a.isChallenging, onClickStopChallenge = _a.onClickStopChallenge, onClickCancelStopChallenge = _a.onClickCancelStopChallenge;
    if (!isChallenging)
        return null;
    var isSM = useSmMediaQuery();
    return (React.createElement(React.Fragment, null, isSM ? (React.createElement("div", { className: css(styles.container), onClick: onClickCancelStopChallenge },
        React.createElement("div", { className: css(styles.dialog) },
            React.createElement("div", { className: css(styles.section, styles.descriptionText) }, "\u30C1\u30E3\u30EC\u30F3\u30B8\u3092\u89E3\u9664\u3057\u307E\u3059\u3002\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u306E\u9032\u6357\u306F\u5931\u308F\u308C\u307E\u3059"),
            React.createElement(Button, { className: css(styles.stopChallengeButton), isRadius: true, buttonType: ButtonType.alert, onClick: onClickStopChallenge, dataTest: "stopChallenge" }, "\u30C1\u30E3\u30EC\u30F3\u30B8\u89E3\u9664"),
            React.createElement("div", { className: css(styles.cancelButton), onClick: onClickCancelStopChallenge }, "\u30AD\u30E3\u30F3\u30BB\u30EB")))) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.container), onClick: onClickCancelStopChallenge }),
        React.createElement("div", { className: css(styles.dialog) },
            React.createElement("div", { className: css(styles.stopChallengeBox), onClick: onClickStopChallenge, "data-test": "stopChallenge" },
                React.createElement(StopIcon, { classObject: styles.stopChallengeIcon }),
                React.createElement("div", { className: css(styles.stopChallengeText) }, "\u30C1\u30E3\u30EC\u30F3\u30B8\u89E3\u9664")),
            React.createElement("div", { className: css(styles.section, styles.descriptionText) }, "\u30C1\u30E3\u30EC\u30F3\u30B8\u3092\u89E3\u9664\u3057\u307E\u3059\u3002\u30D7\u30EC\u30A4\u30EA\u30B9\u30C8\u306E\u9032\u6357\u306F\u5931\u308F\u308C\u307E\u3059"))))));
};
var styles = StyleSheet.create({
    container: (_a = {
            position: 'fixed',
            top: 50,
            left: 0,
            width: '100%',
            height: 'calc(100% - 300px)',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            cursor: 'default'
        },
        _a[MediaBreakPointUp.MD] = {
            top: 0,
            height: '100%',
            backgroundColor: 'unset',
        },
        _a),
    dialog: (_b = {
            backgroundColor: Colors.White,
            position: 'fixed',
            bottom: 60,
            width: '100%',
            height: 207,
            padding: '25px 24px 20px',
            borderRadius: '14px 14px 0 0',
            cursor: 'default'
        },
        _b[MediaBreakPointUp.MD] = {
            position: 'absolute',
            width: 335,
            height: 112,
            right: 7,
            bottom: 94,
            borderRadius: 14,
            filter: 'drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1))',
            padding: '8px 16px',
        },
        _b[MediaBreakPointUp.XL] = {
            right: -30,
            bottom: 'unset',
            marginTop: 168,
        },
        _b),
    section: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    descriptionText: (_c = {
            color: Colors.GrayDarkest,
            height: 36,
            fontSize: 13,
            fontWeight: 400,
            lineHeight: '138%'
        },
        _c[MediaBreakPointUp.MD] = {
            fontSize: 12,
            marginTop: 10,
            lineHeight: '160%',
            letterSpacing: '0.04em',
        },
        _c),
    stopChallengeButton: {
        display: 'block',
        fontSize: 16,
        width: '100%',
        marginTop: 20,
        cursor: 'pointer',
    },
    stopChallengeBox: {
        color: Colors.Alert,
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        width: '100%',
        marginTop: 12,
        cursor: 'pointer',
    },
    stopChallengeIcon: {
        width: 16,
        height: 16,
        margin: 4,
    },
    stopChallengeText: {
        marginLeft: 14,
        fontSize: 14,
    },
    cancelButton: {
        color: Colors.GrayDarkest,
        marginTop: 20,
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'center',
        cursor: 'pointer',
    },
});
