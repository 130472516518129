var SnsShareContent = /** @class */ (function () {
    function SnsShareContent(data) {
        Object.defineProperty(this, "message", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "imageSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.message = data.message;
        this.imageSrc = data.imageSrc;
    }
    Object.defineProperty(SnsShareContent.prototype, "imageURL", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (config) {
            return config.cdnBaseURL + this.imageSrc;
        }
    });
    return SnsShareContent;
}());
export { SnsShareContent };
