import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { TodaysWorkout } from './todays_workout';
import { Colors } from '../styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { useCss } from '@lean-body/src/util';
export var TodaysWorkoutsSlider = function (_a) {
    var courseDaysLessons = _a.courseDaysLessons, onClickLesson = _a.onClickLesson, classObject = _a.classObject;
    useCss('/assets/styles/swiper-todays-workouts.css');
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(Swiper, { pagination: {
                clickable: true,
                type: 'bullets',
            }, modules: [Pagination], loop: true, spaceBetween: "14px" }, courseDaysLessons.map(function (courseDaysLesson) { return (React.createElement(SwiperSlide, { key: courseDaysLesson.id, onClick: function () { return onClickLesson(courseDaysLesson); } },
            React.createElement(TodaysWorkout, { courseDaysLesson: courseDaysLesson }))); }))));
};
var styles = StyleSheet.create({
    container: {
        position: 'relative',
        borderRadius: '14px',
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        backgroundColor: Colors.White,
        cursor: 'pointer',
    },
});
