var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { CoachingExplanation } from '../atoms/coaching_explanation';
import { FooterComponent } from './footer';
import { isNativeApp } from '@lean-body/src/util';
import { Sheet } from '../atoms/sheet';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import * as isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(duration);
dayjs.extend(isBetween);
var COACHING_CAMPAIGN_START = dayjs('2024-4-27');
var COACHING_CAMPAIGN_END = dayjs('2024-05-13');
export var IsCoachingCampaignTerm = function () {
    var now = dayjs();
    return now.isBetween(COACHING_CAMPAIGN_START, COACHING_CAMPAIGN_END);
};
export var CoachingLP = function (_a) {
    var onClickSubscription = _a.onClickSubscription, onClickRedirectLine = _a.onClickRedirectLine, subscribed = _a.subscribed, isCampaignPlan = _a.isCampaignPlan;
    var now = dayjs();
    var campaignCountdownStart = dayjs('2024-5-7');
    var _b = React.useState(false), countdown = _b[0], setTimeCountdown = _b[1];
    var isShowCampaignCountdown = isCampaignPlan && now.isBetween(campaignCountdownStart, COACHING_CAMPAIGN_END);
    var amazonGiftCountdownStart = dayjs('2024-04-10');
    var amazonGiftCountdownEnd = dayjs('2024-04-22');
    var _c = React.useState(''), amazonGiftCountdown = _c[0], setTimeAmazonGiftCountdown = _c[1];
    var presentedAmazonGift = now.isBetween(amazonGiftCountdownStart, amazonGiftCountdownEnd);
    var subscribeButtonImg;
    var floatSubscribeButtonImg;
    var headerImg;
    var comparisonChartImg;
    var planDescImg;
    var campaignCountdownImg;
    if (isCampaignPlan) {
        subscribeButtonImg = 'subscribe_button.png';
        floatSubscribeButtonImg = 'float_subscribe_button.png';
        headerImg = 'header.jpg';
        comparisonChartImg = 'comparison_chart_campaign.jpg';
        planDescImg = 'plan_desc_campaign.jpg';
        campaignCountdownImg = isShowCampaignCountdown ? 'amount_countdown.jpg' : 'amount.jpg';
    }
    else if (presentedAmazonGift) {
        subscribeButtonImg = 'subscribe_button_amazon_gift_500.png';
        floatSubscribeButtonImg = 'subscribe_button_amazon_gift_500.png';
        headerImg = 'header_amazon_gift_500.png';
        comparisonChartImg = 'comparison_chart.jpg';
        planDescImg = 'plan_desc.jpg';
    }
    else {
        subscribeButtonImg = 'subscribe_button_1980.png';
        floatSubscribeButtonImg = 'subscribe_button_1980.png';
        headerImg = 'header_large.jpg';
        comparisonChartImg = 'comparison_chart.jpg';
        planDescImg = 'plan_desc.jpg';
    }
    var getTimeDiff = function (t) {
        var diff = t.diff(dayjs(), 'second');
        if (diff < 0) {
            return false;
        }
        var date = Math.floor(diff / (24 * 60 * 60));
        var hour = Math.floor((diff % (24 * 60 * 60)) / (60 * 60));
        var minute = Math.floor((diff % (60 * 60)) / 60);
        var second = diff % 60;
        return [date, hour, minute, second];
    };
    var diffStringFrom = function (diff) {
        if (diff === false) {
            return '0秒';
        }
        var date = diff[0], hour = diff[1], minute = diff[2], second = diff[3];
        var result = '';
        if (date > 0) {
            result = "".concat(date, "\u65E5");
        }
        if (result !== '' || hour > 0) {
            result += "".concat(hour, "\u6642\u9593");
        }
        if (result !== '' || minute > 0) {
            result += "".concat(minute, "\u5206");
        }
        if (result !== '' || second > 0) {
            result += "".concat(second, "\u79D2");
        }
        return result;
    };
    React.useEffect(function () {
        var timeoutId = setInterval(function () {
            setTimeCountdown(getTimeDiff(COACHING_CAMPAIGN_END));
        }, 1000);
        return function () { return clearTimeout(timeoutId); };
    }, []);
    React.useEffect(function () {
        var amazonGiftTimeoutId = setInterval(function () {
            setTimeAmazonGiftCountdown(diffStringFrom(getTimeDiff(amazonGiftCountdownEnd)));
        }, 1000);
        return function () { return clearTimeout(amazonGiftTimeoutId); };
    }, []);
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("img", { className: css(styles.header), src: "/assets/images/coaching/".concat(headerImg, "?20240426") }),
        isCampaignPlan && (React.createElement("div", { className: css(styles.amountContainer) },
            React.createElement("img", { className: css(styles.amount), src: "/assets/images/coaching/".concat(campaignCountdownImg, "?20240426") }),
            isShowCampaignCountdown && countdown && (React.createElement("div", { className: css(styles.countdown) },
                countdown[0] ? (React.createElement(React.Fragment, null,
                    countdown[0],
                    React.createElement("span", { className: css(styles.countdownUnit) }, "\u65E5"))) : (''),
                countdown[1] ? (React.createElement(React.Fragment, null,
                    countdown[1],
                    React.createElement("span", { className: css(styles.countdownUnit) }, "\u6642\u9593"))) : (''),
                countdown[2] ? (React.createElement(React.Fragment, null,
                    countdown[2],
                    React.createElement("span", { className: css(styles.countdownUnit) }, "\u5206"))) : (''),
                countdown[3] ? (React.createElement(React.Fragment, null,
                    countdown[3],
                    React.createElement("span", { className: css(styles.countdownUnit) }, "\u79D2"))) : (''))))),
        React.createElement("section", { className: css(styles.reasonSection) },
            React.createElement("div", { className: css(styles.subscribeButtonContainer, isCampaignPlan && styles.subscribeButtonContainerForCampaing) }, subscribed ? (React.createElement(React.Fragment, null,
                React.createElement("img", { className: css(styles.subscribeButton), src: "/assets/images/coaching/line_button.png", onClick: onClickRedirectLine }),
                React.createElement("div", { className: css(styles.buttonBelowMessage) }, "LINE\u3092\u958B\u304D\u307E\u3059"),
                React.createElement("div", { className: css(styles.supplement) },
                    "\u3054\u767B\u9332\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u306B\u767B\u9332\u5B8C\u4E86\u306E\u30E1\u30FC\u30EB\u3092\u9001\u4FE1\u3057\u307E\u3057\u305F\u3002\u30E1\u30FC\u30EB\u304C\u5C4A\u3044\u3066\u3044\u306A\u3044\u5834\u5408\u306F",
                    React.createElement("a", { className: css(styles.link), href: "https://wondernuts.zendesk.com/hc/ja/requests/new", target: "_blank" }, "\u3053\u3061\u3089"),
                    "\u307E\u3067\u304A\u554F\u3044\u5408\u308F\u305B\u304F\u3060\u3055\u3044"))) : (React.createElement("div", { className: css(styles.buttonContainer) },
                presentedAmazonGift && (React.createElement("div", null,
                    React.createElement("div", { className: css(styles.amazonGiftCountdownText) }, "Amazon\u30AE\u30D5\u30C8\u30AB\u30FC\u30C9\u30D7\u30EC\u30BC\u30F3\u30C8\u30AD\u30E3\u30F3\u30DA\u30FC\u30F3\u7D42\u4E86\u307E\u3067"),
                    React.createElement("div", { className: css(styles.amazonGiftCountdown) },
                        "\uFF3C ",
                        amazonGiftCountdown,
                        " \uFF0F"))),
                React.createElement("img", { className: css(styles.subscribeButton), src: "/assets/images/coaching/".concat(subscribeButtonImg), onClick: onClickSubscription }),
                isNativeApp() && React.createElement("div", { className: css(styles.buttonBelowMessage) }, "web\u7248LEAN BODY\u3092\u958B\u304D\u307E\u3059"),
                presentedAmazonGift && (React.createElement("div", { className: css(styles.amazonGiftDescription) },
                    "\u30FB\u672C\u30AD\u30E3\u30F3\u30DA\u30FC\u30F3\u306F4/10 0:00 ~4/21 23:59\u306B\u30B3\u30FC\u30C1\u30F3\u30B0\u30D7\u30E9\u30F3\u306B\u767B\u9332\u3057\u305F\u65B9\u304C\u5BFE\u8C61\u3068\u306A\u308A\u307E\u3059\u3002",
                    React.createElement("br", null),
                    "\u30FB\u30B3\u30FC\u30C1\u30F3\u30B0\u30D7\u30E9\u30F3\u306B\u521D\u3081\u3066\u767B\u9332\u3057\u305F\u65B9\u306E\u307F\u5BFE\u8C61\u3068\u306A\u308A\u307E\u3059\u3002",
                    React.createElement("br", null),
                    "\u30FB\u30AD\u30E3\u30F3\u30DA\u30FC\u30F3\u7D42\u4E86\u5F8C\u306E4/22\u6642\u70B9\u3067\u3001\u30B3\u30FC\u30C1\u30F3\u30B0\u30D7\u30E9\u30F3\u3092\u7D99\u7D9A\u3057\u3066\u3044\u308B\u65B9\u306E\u307F\u304C\u5BFE\u8C61\u3068\u306A\u308A\u3001\u305D\u308C\u4EE5\u524D\u306B\u30AD\u30E3\u30F3\u30BB\u30EB\u624B\u7D9A\u304D\u3092\u3057\u305F\u65B9\u306F\u5BFE\u8C61\u5916\u3068\u306A\u308A\u307E\u3059\u3002"))))),
            React.createElement("img", { className: css(styles.satisfaction), src: "/assets/images/coaching/satisfaction.jpg" }),
            React.createElement("img", { className: css(styles.whyYouDetail), src: "/assets/images/coaching/why_you_detail.png?20240315" }),
            React.createElement("img", { className: css(styles.arrow), src: "/assets/images/coaching/arrow.png" }),
            React.createElement("img", { className: css(styles.reasonTitle), src: "/assets/images/coaching/reason_title.png?20240315" }),
            React.createElement("img", { className: css(styles.reason), src: "/assets/images/coaching/reason.png?20240315" }),
            !subscribed && (React.createElement("div", { className: css(styles.subscribeButtonMiddle) },
                presentedAmazonGift && (React.createElement("div", null,
                    React.createElement("div", { className: css(styles.amazonGiftCountdownText) }, "Amazon\u30AE\u30D5\u30C8\u30AB\u30FC\u30C9\u30D7\u30EC\u30BC\u30F3\u30C8\u30AD\u30E3\u30F3\u30DA\u30FC\u30F3\u7D42\u4E86\u307E\u3067"),
                    React.createElement("div", { className: css(styles.amazonGiftCountdown) },
                        "\uFF3C ",
                        amazonGiftCountdown,
                        " \uFF0F"))),
                isShowCampaignCountdown && countdown && (React.createElement("div", null,
                    React.createElement("div", { className: css(styles.subscribeButtonCountdownHeader) }, "\u30AD\u30E3\u30F3\u30DA\u30FC\u30F3\u7D42\u4E86\u307E\u3067"),
                    React.createElement("div", { className: css(styles.subscribeButtonCountdown) }, "\uFF3C  ".concat(diffStringFrom(countdown), "  \uFF0F")))),
                React.createElement("img", { className: css(styles.subscribeButton), src: "/assets/images/coaching/".concat(subscribeButtonImg), onClick: onClickSubscription }),
                isNativeApp() && React.createElement("div", { className: css(styles.buttonBelowMessage) }, "web\u7248LEAN BODY\u3092\u958B\u304D\u307E\u3059"),
                presentedAmazonGift && (React.createElement("div", { className: css(styles.amazonGiftDescription) },
                    "\u30FB\u672C\u30AD\u30E3\u30F3\u30DA\u30FC\u30F3\u306F4/10 0:00 ~4/21 23:59\u306B\u30B3\u30FC\u30C1\u30F3\u30B0\u30D7\u30E9\u30F3\u306B\u767B\u9332\u3057\u305F\u65B9\u304C\u5BFE\u8C61\u3068\u306A\u308A\u307E\u3059\u3002",
                    React.createElement("br", null),
                    "\u30FB\u30B3\u30FC\u30C1\u30F3\u30B0\u30D7\u30E9\u30F3\u306B\u521D\u3081\u3066\u767B\u9332\u3057\u305F\u65B9\u306E\u307F\u5BFE\u8C61\u3068\u306A\u308A\u307E\u3059\u3002",
                    React.createElement("br", null),
                    "\u30FB\u30AD\u30E3\u30F3\u30DA\u30FC\u30F3\u7D42\u4E86\u5F8C\u306E4/22\u6642\u70B9\u3067\u3001\u30B3\u30FC\u30C1\u30F3\u30B0\u30D7\u30E9\u30F3\u3092\u7D99\u7D9A\u3057\u3066\u3044\u308B\u65B9\u306E\u307F\u304C\u5BFE\u8C61\u3068\u306A\u308A\u3001\u305D\u308C\u4EE5\u524D\u306B\u30AD\u30E3\u30F3\u30BB\u30EB\u624B\u7D9A\u304D\u3092\u3057\u305F\u65B9\u306F\u5BFE\u8C61\u5916\u3068\u306A\u308A\u307E\u3059\u3002"))))),
        React.createElement("img", { className: css(styles.threeHurdle), src: "/assets/images/coaching/three_hurdle.png?20240315" }),
        React.createElement("img", { className: css(styles.coachIntroduce), src: "/assets/images/coaching/coach_introduce.jpg" }),
        React.createElement("img", { className: css(styles.customerReview), src: "/assets/images/coaching/customer_review.jpg" }),
        React.createElement("img", { className: css(styles.planDesc), src: "/assets/images/coaching/".concat(planDescImg) }),
        React.createElement("img", { className: css(styles.unsubscribeDesc), src: "/assets/images/coaching/unsubscribe_desc.jpg" }),
        React.createElement("img", { className: css(styles.comparisonChart), src: "/assets/images/coaching/".concat(comparisonChartImg, "?20240426") }),
        React.createElement("div", { className: css(styles.faq) },
            React.createElement("div", { className: css(styles.faqTitle) }, "\u3088\u304F\u3042\u308B\u8CEA\u554F"),
            React.createElement("img", { className: css(styles.faqImg), src: "/assets/images/coaching/faq1.png?20240315" }),
            React.createElement("img", { className: css(styles.faqImg), src: "/assets/images/coaching/faq2.png?20240315" }),
            React.createElement("img", { className: css(styles.faqImg), src: "/assets/images/coaching/faq3.png?20240315" }),
            React.createElement("img", { className: css(styles.faqImg), src: "/assets/images/coaching/faq4.png?20240315" }),
            React.createElement("img", { className: css(styles.faqImg), src: "/assets/images/coaching/faq5.png?20240315" })),
        React.createElement(CoachingExplanation, { title: "\u6CE8\u610F\u4E8B\u9805", titleCss: styles.explanationTitle }, presentedAmazonGift ? (React.createElement(React.Fragment, null,
            "\u30FB\u672C\u30AD\u30E3\u30F3\u30DA\u30FC\u30F3\u306F4/10 0:00 ~4/21 23:59\u306B\u30B3\u30FC\u30C1\u30F3\u30B0\u30D7\u30E9\u30F3\u306B\u767B\u9332\u3057\u305F\u65B9\u304C\u5BFE\u8C61\u3068\u306A\u308A\u307E\u3059\u3002",
            React.createElement("br", null),
            "\u30FB\u30B3\u30FC\u30C1\u30F3\u30B0\u30D7\u30E9\u30F3\u306B\u521D\u3081\u3066\u767B\u9332\u3057\u305F\u65B9\u306E\u307F\u5BFE\u8C61\u3068\u306A\u308A\u307E\u3059\u3002",
            React.createElement("br", null),
            "\u30FB\u30AD\u30E3\u30F3\u30DA\u30FC\u30F3\u7D42\u4E86\u5F8C\u306E4/22\u6642\u70B9\u3067\u3001\u30B3\u30FC\u30C1\u30F3\u30B0\u30D7\u30E9\u30F3\u3092\u7D99\u7D9A\u3057\u3066\u3044\u308B\u65B9\u306E\u307F\u304C\u5BFE\u8C61\u3068\u306A\u308A\u3001\u305D\u308C\u4EE5\u524D\u306B\u30AD\u30E3\u30F3\u30BB\u30EB\u624B\u7D9A\u304D\u3092\u3057\u305F\u65B9\u306F\u5BFE\u8C61\u5916\u3068\u306A\u308A\u307E\u3059\u3002\u30FB\u672C\u30B5\u30FC\u30D3\u30B9\u306E\u3054\u5229\u7528\u306B\u306FLINE\u30A2\u30D7\u30EA\u306E\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u304C\u5FC5\u8981\u3067\u3059\u3002\u65E5\u3005\u306E\u9032\u6357\u5831\u544A\u3084\u30B3\u30FC\u30C1\u304B\u3089\u306E\u30D5\u30A3\u30FC\u30C9\u30D0\u30C3\u30AF\u306F\u3059\u3079\u3066LINE\u3067\u884C\u3044\u307E\u3059\u306E\u3067\u3001\u4E8B\u524D\u306BLINE\u30A2\u30D7\u30EA\u3092\u3054\u6E96\u5099\u304F\u3060\u3055\u3044\u3002",
            React.createElement("br", null),
            "\u30FB24\u6642\u9593\u3044\u3064\u3067\u3082LINE\u3067\u3054\u9023\u7D61\u3044\u305F\u3060\u3051\u307E\u3059\u304C\u3001\u8FD4\u4FE1\u306F\u55B6\u696D\u65E5\u306E11\u6642\u301C18\u6642\u306B\u306A\u308A\u307E\u3059\u3002\u571F\u65E5\u30FB\u795D\u65E5\u306E\u3054\u9023\u7D61\u306F\u7FCC\u55B6\u696D\u65E5\u306B\u8FD4\u4FE1\u3057\u307E\u3059\u306E\u3067\u3001\u3054\u4E86\u627F\u304F\u3060\u3055\u3044\u3002",
            React.createElement("br", null),
            "\u30FB\u672C\u30B5\u30FC\u30D3\u30B9\u306F\u3001\u533B\u7642\u884C\u70BA\u3084\u6CBB\u7642\u3092\u76EE\u7684\u3068\u3057\u305F\u3082\u306E\u3067\u306F\u3042\u308A\u307E\u305B\u3093\u3002\u5065\u5EB7\u4E0A\u306E\u554F\u984C\u3084\u65E2\u5F80\u75C7\u304C\u3042\u308B\u5834\u5408\u306F\u3001\u904B\u52D5\u3092\u59CB\u3081\u308B\u524D\u306B\u533B\u5E2B\u306E\u8A3A\u65AD\u3092\u53D7\u3051\u3066\u304F\u3060\u3055\u3044\u3002",
            React.createElement("br", null),
            "\u30FB\u500B\u3005\u306B\u5408\u308F\u305B\u3066\u7FD2\u6163\u30B5\u30DD\u30FC\u30C8\u3092\u63D0\u4F9B\u3057\u307E\u3059\u304C\u3001\u904B\u52D5\u4E2D\u306E\u30B1\u30AC\u3084\u5065\u5EB7\u969C\u5BB3\u306B\u3064\u3044\u3066\u306F\u3001\u672C\u30B5\u30FC\u30D3\u30B9\u3067\u306F\u8CAC\u4EFB\u3092\u8CA0\u3044\u304B\u306D\u307E\u3059\u3002",
            React.createElement("br", null),
            "\u30FB\u63D0\u4F9B\u3059\u308B\u30A2\u30C9\u30D0\u30A4\u30B9\u306F\u3042\u304F\u307E\u3067\u4E00\u822C\u7684\u306A\u3082\u306E\u3067\u3042\u308A\u3001\u500B\u4EBA\u306E\u5177\u4F53\u7684\u306A\u7D50\u679C\u3092\u4FDD\u8A3C\u3059\u308B\u3082\u306E\u3067\u306F\u3042\u308A\u307E\u305B\u3093\u3002",
            React.createElement("br", null),
            "\u30FB\u30D7\u30E9\u30F3\u306F\u81EA\u52D5\u66F4\u65B0\u3068\u306A\u308B\u305F\u3081\u3001\u767B\u9332\u5F8C\u3001\u7D99\u7D9A\u3092\u3054\u5E0C\u671B\u3055\u308C\u306A\u3044\u5834\u5408\u306F\u8A2D\u5B9A>\u3054\u767B\u9332\u4E2D\u306E\u30D7\u30E9\u30F3\u304B\u3089\u89E3\u7D04\u624B\u7D9A\u304D\u3092\u304A\u9858\u3044\u3057\u307E\u3059\u3002",
            React.createElement("br", null),
            "\u30FBLEAN BODY\u3092\u9000\u4F1A\u3057\u305F\u5834\u5408\u306F\u3001\u30D7\u30E9\u30F3\u306E\u66F4\u65B0\u65E5\u306B\u95A2\u308F\u3089\u305A\u30B3\u30FC\u30C1\u30F3\u30B0\u6A5F\u80FD\u3082\u5229\u7528\u3067\u304D\u306A\u304F\u306A\u308A\u307E\u3059\u3002",
            React.createElement("br", null),
            "\u30FB\u81EA\u52D5\u66F4\u65B0\u65E5\u524D\u306B\u30D7\u30E9\u30F3\u505C\u6B62\u306E\u624B\u7D9A\u304D\u3092\u3055\u308C\u305F\u5834\u5408\u3001\u6B8B\u671F\u9593\u306E\u65E5\u5272\u308A\u3067\u306E\u8FD4\u91D1\u306F\u81F4\u3057\u304B\u306D\u307E\u3059\u3002")) : (React.createElement(React.Fragment, null,
            "\u30FB\u672C\u30B5\u30FC\u30D3\u30B9\u306E\u3054\u5229\u7528\u306B\u306FLINE\u30A2\u30D7\u30EA\u306E\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u304C\u5FC5\u8981\u3067\u3059\u3002\u65E5\u3005\u306E\u9032\u6357\u5831\u544A\u3084\u30B3\u30FC\u30C1\u304B\u3089\u306E\u30D5\u30A3\u30FC\u30C9\u30D0\u30C3\u30AF\u306F\u3059\u3079\u3066LINE\u3067\u884C\u3044\u307E\u3059\u306E\u3067\u3001\u4E8B\u524D\u306BLINE\u30A2\u30D7\u30EA\u3092\u3054\u6E96\u5099\u304F\u3060\u3055\u3044\u3002",
            React.createElement("br", null),
            "\u30FB24\u6642\u9593\u3044\u3064\u3067\u3082LINE\u3067\u3054\u9023\u7D61\u3044\u305F\u3060\u3051\u307E\u3059\u304C\u3001\u8FD4\u4FE1\u306F\u55B6\u696D\u65E5\u306E11\u6642\u301C18\u6642\u306B\u306A\u308A\u307E\u3059\u3002\u571F\u65E5\u30FB\u795D\u65E5\u306E\u3054\u9023\u7D61\u306F\u7FCC\u55B6\u696D\u65E5\u306B\u8FD4\u4FE1\u3057\u307E\u3059\u306E\u3067\u3001\u3054\u4E86\u627F\u304F\u3060\u3055\u3044\u3002",
            React.createElement("br", null),
            "\u30FB\u672C\u30B5\u30FC\u30D3\u30B9\u306F\u3001\u533B\u7642\u884C\u70BA\u3084\u6CBB\u7642\u3092\u76EE\u7684\u3068\u3057\u305F\u3082\u306E\u3067\u306F\u3042\u308A\u307E\u305B\u3093\u3002\u5065\u5EB7\u4E0A\u306E\u554F\u984C\u3084\u65E2\u5F80\u75C7\u304C\u3042\u308B\u5834\u5408\u306F\u3001\u904B\u52D5\u3092\u59CB\u3081\u308B\u524D\u306B\u533B\u5E2B\u306E\u8A3A\u65AD\u3092\u53D7\u3051\u3066\u304F\u3060\u3055\u3044\u3002",
            React.createElement("br", null),
            "\u30FB\u500B\u3005\u306B\u5408\u308F\u305B\u3066\u7FD2\u6163\u30B5\u30DD\u30FC\u30C8\u3092\u63D0\u4F9B\u3057\u307E\u3059\u304C\u3001\u904B\u52D5\u4E2D\u306E\u30B1\u30AC\u3084\u5065\u5EB7\u969C\u5BB3\u306B\u3064\u3044\u3066\u306F\u3001\u672C\u30B5\u30FC\u30D3\u30B9\u3067\u306F\u8CAC\u4EFB\u3092\u8CA0\u3044\u304B\u306D\u307E\u3059\u3002",
            React.createElement("br", null),
            "\u30FB\u63D0\u4F9B\u3059\u308B\u30A2\u30C9\u30D0\u30A4\u30B9\u306F\u3042\u304F\u307E\u3067\u4E00\u822C\u7684\u306A\u3082\u306E\u3067\u3042\u308A\u3001\u500B\u4EBA\u306E\u5177\u4F53\u7684\u306A\u7D50\u679C\u3092\u4FDD\u8A3C\u3059\u308B\u3082\u306E\u3067\u306F\u3042\u308A\u307E\u305B\u3093\u3002",
            React.createElement("br", null),
            "\u30FB\u30D7\u30E9\u30F3\u306F\u81EA\u52D5\u66F4\u65B0\u3068\u306A\u308B\u305F\u3081\u3001\u767B\u9332\u5F8C\u3001\u7D99\u7D9A\u3092\u3054\u5E0C\u671B\u3055\u308C\u306A\u3044\u5834\u5408\u306F\u8A2D\u5B9A>\u3054\u767B\u9332\u4E2D\u306E\u30D7\u30E9\u30F3\u304B\u3089\u89E3\u7D04\u624B\u7D9A\u304D\u3092\u304A\u9858\u3044\u3057\u307E\u3059\u3002",
            React.createElement("br", null),
            "\u30FBLEAN BODY\u3092\u9000\u4F1A\u3057\u305F\u5834\u5408\u306F\u3001\u30D7\u30E9\u30F3\u306E\u66F4\u65B0\u65E5\u306B\u95A2\u308F\u3089\u305A\u30B3\u30FC\u30C1\u30F3\u30B0\u6A5F\u80FD\u3082\u5229\u7528\u3067\u304D\u306A\u304F\u306A\u308A\u307E\u3059\u3002"))),
        React.createElement(FooterComponent, { classObject: styles.footerContainer }),
        React.createElement(Sheet, { classObject: styles.floatSubscribeButtonContainer }, subscribed ? (React.createElement(React.Fragment, null,
            React.createElement("img", { className: css(styles.floatSubscribeButton), src: "/assets/images/coaching/line_button.png", onClick: onClickRedirectLine }),
            React.createElement("div", { className: css(styles.buttonBelowMessage) }, "LINE\u3092\u958B\u304D\u307E\u3059"))) : (React.createElement(React.Fragment, null,
            React.createElement("img", { className: css(styles.floatSubscribeButton), src: "/assets/images/coaching/".concat(floatSubscribeButtonImg), onClick: onClickSubscription }),
            isNativeApp() && React.createElement("div", { className: css(styles.buttonBelowMessage) }, "web\u7248LEAN BODY\u3092\u958B\u304D\u307E\u3059"))))));
};
var styles = StyleSheet.create({
    container: (_a = {},
        _a[MediaBreakPointUp.MD] = {
            maxWidth: 375,
            margin: '0 auto',
        },
        _a),
    header: {
        display: 'block',
        width: '100%',
    },
    amountContainer: {
        position: 'relative',
    },
    amount: {
        display: 'block',
        width: '100%',
    },
    countdown: {
        position: 'absolute',
        width: '100%',
        height: '10%',
        bottom: '7%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#B4822A', // 他で使わない色なのでVariableに入れてない
        fontSize: 28,
        fontWeight: 'bold',
        letterSpacing: 0.84,
    },
    countdownUnit: {
        fontSize: 18,
        fontWeight: 700,
        letterSpacing: 1.44,
        paddingTop: 4, // 下に整列させるため
        margin: '0 8px 0 3px',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    amazonGiftCountdownText: {
        fontSize: 12.5,
        fontWeight: 600,
        lineHeight: '130%',
        marginBottom: 6,
    },
    amazonGiftCountdown: {
        color: Colors.Accent,
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '130%',
        letterSpacing: 0.9,
    },
    amazonGiftDescription: {
        color: Colors.GrayDarkest,
        textAlign: 'left',
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: -0.5,
    },
    reasonSection: {
        backgroundColor: Colors.BackgroundBlue,
        padding: '0 0 12px',
    },
    subscribeButtonContainer: {
        padding: '18px 24px 20px',
        textAlign: 'center',
        backgroundColor: Colors.White,
    },
    subscribeButtonCountdownHeader: {
        fontWeight: 600,
        fontSize: 12,
        lineHeight: '130%',
    },
    subscribeButtonCountdown: {
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '130%',
        letterSpacing: 0.9,
        color: Colors.Accent,
    },
    subscribeButtonContainerForCampaing: {
        backgroundColor: 'unset',
    },
    subscribeButton: {
        display: 'block',
        width: '100%',
    },
    satisfaction: {
        display: 'block',
        width: '100%',
    },
    whyYouDetail: {
        display: 'block',
        width: '100%',
        margin: '0 0 16px',
    },
    arrow: {
        display: 'block',
        width: 116,
        margin: '0 auto',
    },
    reasonTitle: {
        display: 'block',
        width: '100%',
        margin: '20px 0 0',
    },
    reason: {
        display: 'block',
        width: '100%',
    },
    subscribeButtonMiddle: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        gap: 12,
        padding: '0 24px 8px',
    },
    threeHurdle: {
        display: 'block',
        width: '100%',
    },
    coachIntroduce: {
        display: 'block',
        width: '100%',
    },
    customerReview: {
        display: 'block',
        width: '100%',
    },
    planDesc: {
        display: 'block',
        width: '100%',
    },
    unsubscribeDesc: {
        display: 'block',
        width: '100%',
    },
    comparisonChart: {
        display: 'block',
        width: '100%',
    },
    faq: {
        padding: 24,
    },
    faqTitle: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 30,
        textAlign: 'center',
    },
    faqImg: {
        display: 'block',
        width: '100%',
        ':not(:last-of-type)': {
            marginBottom: 30,
        },
    },
    explanationTitle: {
        fontSize: 16,
        marginBottom: 16,
        textAlign: 'center',
    },
    floatSubscribeButtonContainer: {
        position: 'sticky',
        bottom: 0,
    },
    floatSubscribeButton: {
        display: 'block',
        width: '100%',
    },
    footerContainer: {
        padding: '20px 0 30px',
    },
    buttonBelowMessage: {
        color: Colors.GrayDarkest,
        fontSize: 12,
        fontWeight: 500,
        marginTop: 4,
        textAlign: 'center',
        marginBottom: 6,
    },
    supplement: {
        color: Colors.GrayDarkest,
        fontSize: 14,
        lineHeight: '140%',
        letterSpacing: '0.04em',
        marginTop: 8,
    },
    link: {
        color: Colors.Primary,
        textDecoration: 'underline',
    },
});
