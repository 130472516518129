import { CourseDaysLesson, CourseVolume } from '.';
var CourseDay = /** @class */ (function () {
    function CourseDay(data, config) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "day", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalDuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isCompleted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isSkipped", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "completedCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isDisplayCourseReward", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "courseDaysLessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "volume", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.day = data.day;
        this.totalDuration = data.totalDuration;
        this.isCompleted = data.isCompleted;
        this.isSkipped = data.isSkipped;
        this.completedCount = data.completedCount;
        this.isDisplayCourseReward = data.isDisplayCourseReward;
        this.courseDaysLessons = data.courseDaysLessons
            ? data.courseDaysLessons.map(function (o) { return new CourseDaysLesson(o, config); })
            : [];
        this.volume = data.courseVolume ? new CourseVolume(data.courseVolume, config) : null;
    }
    Object.defineProperty(CourseDay.prototype, "currentLesson", {
        get: function () {
            var _a;
            return (_a = this.courseDaysLessons.find(function (lesson) { return !lesson.isCompleted; })) !== null && _a !== void 0 ? _a : this.courseDaysLessons[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CourseDay.prototype, "isFirstCompleted", {
        get: function () {
            return this.completedCount >= 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CourseDay, "sampleCourseDay", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (config) {
            return new CourseDay({
                courseVolume: { title: 'Vol.3 体をほぐそう', volumeNumber: 1, days: [] },
                courseDaysLessons: [
                    {
                        lesson: {
                            id: 1,
                            name: '脂肪燃焼 - やる気アクセルを踏もう',
                            description: 'ブートキャンプ入隊の一歩目のレッスン。身体をほぐし、キックやパンチの動きで全身の脂肪を燃焼していきます。後半のコンビネーションでは、楽しむことも忘れずに！30分間悩み事は忘れて、自分を変えることに集中だ！',
                            difficulty: { name: '中級 - 上級' },
                            minCal: 138,
                            maxCal: 245,
                            pace: { name: '速い' },
                            thumbnailSrc: '/lesson/415/L1.jpg',
                            tags: [{ name: '全身' }],
                        },
                    },
                ],
            }, config);
        }
    });
    return CourseDay;
}());
export { CourseDay };
