var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { ClockIcon } from '../atoms/icons/clock_icon';
import { RankListSection } from '@lean-body/components/organisms';
import { ActivitySectionTitle } from '@lean-body/components/atoms';
var RANKS = [
    {
        name: 'BRONZE',
        nameJp: 'ブロンズ',
        desc: 'まずはみなさん、ここからスタート！\nレッスンを受けて、ランクをアップしていきましょう',
        img: '/assets/images/user_season_aggregate/rank_bronze.png',
    },
    {
        name: 'SILVER',
        nameJp: 'シルバー',
        desc: 'まずは1レッスン！千里の道も一歩から。好きなレッスンにチャレンジしてみましょう。',
        playDuration: 30,
        img: '/assets/images/user_season_aggregate/rank_silver.png',
    },
    {
        name: 'GOLD',
        nameJp: 'ゴールド',
        desc: 'ちょっとだけハードだけど、始めたばかりの人はここを目指してみましょう！',
        playDuration: 100,
        img: '/assets/images/user_season_aggregate/rank_gold.png',
    },
    {
        name: 'RUBY',
        nameJp: 'ルビー',
        desc: '週に1時間ほどの運動量！段々、運動が習慣化してくるころかな？',
        playDuration: 300,
        img: '/assets/images/user_season_aggregate/rank_ruby.png',
    },
    {
        name: 'SAPPHIRE',
        nameJp: 'サファイア',
        desc: '週に2時間くらいの運動量！体に変化が出てくるはず！',
        playDuration: 500,
        img: '/assets/images/user_season_aggregate/rank_sapphire.png',
    },
    {
        name: '？',
        desc: '到達できるのはたったの0.5%の方だけ！どんなランクになるのか、達成してのお楽しみ',
        playDuration: 1000,
        img: '/assets/images/user_season_aggregate/rank_secret.png',
    },
];
export var MainUserSeasonRankHelpLp = function (_a) {
    var apiClient = _a.apiClient;
    var _b = React.useState([]), rank = _b[0], setRank = _b[1];
    React.useEffect(function () {
        apiClient.getUserSeasonAggregates().then(function (v) { return setRank(v); });
    }, []);
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.content) },
            React.createElement(ActivitySectionTitle, { title: "\u7372\u5F97\u30E9\u30F3\u30AF", classObject: styles.sectionTitle }),
            React.createElement(RankListSection, { rank: rank }),
            React.createElement("div", { className: css(styles.title) }, "\u30E9\u30F3\u30AF\u5236\u5EA6\u3068\u306F"),
            React.createElement("div", { className: css(styles.description) },
                "LEAN BODY\u3067\u306E\u904B\u52D5\u6642\u9593\u306B\u5408\u308F\u305B\u3066\u3001\u30E9\u30F3\u30AF\u3092\u8A8D\u5B9A\uFF01\u305F\u304F\u3055\u3093\u904B\u52D5\u3057\u3066\u30E1\u30C0\u30EB\u3092\u30B2\u30C3\u30C8\u3057\u3088\u3046\uFF01",
                React.createElement("strong", null, "\u76EE\u6307\u305B\u3001\u30C8\u30C3\u30D7\u30E9\u30F3\u30AB\u30FC\uFF01")),
            React.createElement("div", { className: css(styles.descriptionMin) }, "\u30E9\u30F3\u30AF\u306F\u6BCE\u67081\u65E5\u306B\u30EA\u30BB\u30C3\u30C8\u3055\u308C\u307E\u3059\u3002"),
            React.createElement("ul", { className: css(styles.ranks) }, RANKS.map(function (v, i) { return (React.createElement("li", { key: i, className: css(styles.rank) },
                React.createElement("img", { className: css(styles.rankImg), src: v.img }),
                React.createElement("div", { className: css(styles.rankText) },
                    React.createElement("div", { className: css(styles.rankTextTitle) },
                        React.createElement("span", { className: css(styles.rankTextTitleBold) }, v.name),
                        v.nameJp && "\uFF08".concat(v.nameJp, "\uFF09")),
                    React.createElement("div", { className: css(styles.rankTextDescription) }, v.desc),
                    v.playDuration && (React.createElement("div", { className: css(styles.playDurationContainer) },
                        React.createElement("div", { className: css(styles.playDuration) },
                            React.createElement(ClockIcon, { classObject: styles.clockIcon, color: Colors.Gray }),
                            "\u30E9\u30F3\u30AF\u30A2\u30C3\u30D7\u306E\u76EE\u5B89\u904B\u52D5\u91CF\uFF1A",
                            React.createElement("span", { className: css(styles.playDurationBold) },
                                v.playDuration,
                                "min"))))))); })))));
};
var styles = StyleSheet.create({
    container: {
        paddingBottom: 80,
    },
    header: {
        backgroundColor: Colors.BlueLighterB,
        padding: '25px 0 15px',
        position: 'relative',
    },
    headerBg: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        width: 'calc(50% - 20px)',
        backgroundColor: '#C1EBF0',
        ':before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: -40,
            borderTop: '100px solid transparent',
            borderRight: '20px solid #C1EBF0',
            borderBottom: '100px solid #C1EBF0',
            borderLeft: '20px solid transparent',
        },
    },
    headerImgsAbove: {
        position: 'relative',
        width: 375,
        margin: '0 auto',
        padding: '0 43px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    headerImgsBelow: {
        position: 'relative',
        width: 375,
        margin: '0 auto',
        padding: '0 43px',
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    headerImg: {
        height: 70,
        marginBottom: 10,
    },
    content: (_a = {
            margin: '0 24.5px'
        },
        _a[MediaBreakPointUp.MD] = {
            margin: '0 108px',
        },
        _a[MediaBreakPointUp.XL] = {
            margin: '0 180px',
        },
        _a),
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        margin: '24px 0 8px',
    },
    description: {
        fontSize: 14,
    },
    descriptionMin: {
        fontSize: 12,
        marginTop: 7,
    },
    ranks: {
        margin: '40px 8px 40px 0',
    },
    rank: {
        marginBottom: 16,
        display: 'flex',
    },
    rankImg: {
        width: 40,
        height: 40,
        marginRight: 12,
    },
    rankText: {
        flex: 1,
    },
    rankTextTitle: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    rankTextTitleBold: {
        fontSize: 16,
        marginRight: 4,
    },
    rankTextDescription: {
        fontSize: 13,
        whiteSpace: 'pre-wrap',
        margin: '8px 0 6px',
    },
    playDurationContainer: {
        display: 'inline-block',
    },
    playDuration: {
        fontSize: 10,
        color: Colors.GrayDarkest,
        padding: '10px 14px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Colors.White,
        borderRadius: 10,
    },
    playDurationBold: {
        fontSize: 14,
        fontWeight: 'bold',
        color: Colors.Text,
        marginLeft: 4,
        letterSpacing: '0.04em',
    },
    clockIcon: {
        width: 14,
        height: 14,
        marginRight: 4,
    },
    sectionTitle: {
        margin: '24px 0px 8px',
    },
});
