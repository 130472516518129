import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
import { RequiredBadge } from './required_badge';
export var InputButtonTypeRadioButton = function (props) {
    var classObject = props.classObject, items = props.items, currentValue = props.currentValue, label = props.label, setCurrentValue = props.setCurrentValue;
    var onClick = function (v) {
        var value = currentValue === v ? null : v;
        setCurrentValue(value);
    };
    return (React.createElement("div", { className: css(classObject) },
        label && (React.createElement("div", { className: css(styles.label) },
            label,
            props.required && React.createElement(RequiredBadge, null))),
        items.map(function (_a, index) {
            var name = _a.name, value = _a.value, description = _a.description;
            return (React.createElement("div", { className: css(styles.labelContainer), key: index },
                React.createElement("div", { className: css(styles.radioButton, value === currentValue ? styles.radioButtonOn : styles.radioButtonOff, props.hasValidationError && styles.validationError), onClick: function () { return onClick(value); } },
                    React.createElement("div", { className: css(styles.titleText) }, name),
                    description && React.createElement("div", { className: css(styles.descriptionText) }, description))));
        }),
        props.validationErrorMessage && (React.createElement("div", { className: css(styles.validationErrorMessage) }, props.validationErrorMessage))));
};
var styles = StyleSheet.create({
    labelContainer: {
        display: 'flex',
        cursor: 'pointer',
        marginBottom: 8,
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        fontSize: 14,
        color: Colors.GrayDarkest,
        marginBottom: 8,
    },
    radioButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
        width: '100%',
        borderRadius: 4,
    },
    radioButtonOn: {
        color: Colors.White,
        backgroundColor: Colors.PrimaryDark,
        border: "1px solid ".concat(Colors.PrimaryDark),
    },
    radioButtonOff: {
        color: Colors.GrayDarkest,
        border: "1px solid ".concat(Colors.Gray),
    },
    titleText: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    descriptionText: {
        fontSize: 10,
    },
    validationError: {
        border: "1px solid ".concat(Colors.Alert),
    },
    validationErrorMessage: {
        marginTop: 4,
        color: Colors.Alert,
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: 0.48,
    },
});
