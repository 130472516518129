var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { css, StyleSheet } from 'aphrodite/no-important';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ContentWidthLayout } from '../layouts';
import { InstructorContent } from '@lean-body/components/organisms/instructor_content';
import { PageLoading } from '@lean-body/components/organisms/page_loading';
import { MediaBreakPointUp } from '../styles';
export var MainTrainersTopPage = observer(/** @class */ (function (_super) {
    __extends(MainTrainersTopPage, _super);
    function MainTrainersTopPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(MainTrainersTopPage.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var i = this.props.vm.trainers;
            if (!i) {
                return React.createElement(PageLoading, null);
            }
            return (React.createElement("section", { className: css(this.styles.pageContainer) },
                React.createElement(ContentWidthLayout, null,
                    React.createElement("div", { className: css(this.styles.header) }, "\u30A4\u30F3\u30B9\u30C8\u30E9\u30AF\u30BF\u30FC\u3067\u9078\u3076"),
                    React.createElement("div", { className: css(this.styles.container) }, this.renderInstructorContent()))));
        }
    });
    Object.defineProperty(MainTrainersTopPage.prototype, "renderInstructorContent", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            return this.props.vm.trainers.map(function (item) {
                return React.createElement(InstructorContent, { key: item.id, trainer: item, router: _this.props.vm.router });
            });
        }
    });
    Object.defineProperty(MainTrainersTopPage.prototype, "styles", {
        get: function () {
            var _a;
            return StyleSheet.create({
                pageContainer: {
                    paddingTop: 20,
                    paddingBottom: 20,
                },
                container: {
                    display: 'flex',
                    flexWrap: 'wrap',
                },
                header: (_a = {
                        fontSize: 24,
                        fontWeight: 'bold',
                        lineHeight: '1.25',
                        marginBottom: 9
                    },
                    _a[MediaBreakPointUp.MD] = {
                        margin: '16px 0 24px',
                    },
                    _a[MediaBreakPointUp.XL] = {
                        fontSize: 28,
                    },
                    _a),
            });
        },
        enumerable: false,
        configurable: true
    });
    return MainTrainersTopPage;
}(React.Component)));
var MainTrainersTopPageVM = /** @class */ (function () {
    function MainTrainersTopPageVM(apiClient, router) {
        Object.defineProperty(this, "trainers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "router", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            trainers: observable.ref,
        });
        this.apiClient = apiClient;
        this.router = router;
        this.init();
    }
    Object.defineProperty(MainTrainersTopPageVM.prototype, "init", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = this;
                            return [4 /*yield*/, this.apiClient.fetchTrainers()];
                        case 1:
                            _a.trainers = _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    return MainTrainersTopPageVM;
}());
export { MainTrainersTopPageVM };
